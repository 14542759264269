import { z } from 'zod'

import noLeadingSpaces from '../../../../../utilities/noLeadingSpaces'

export const formSchema = z.object({
  reason: z
    .string({ invalid_type_error: 'Reason is required.' })
    .min(1, { message: 'Reason is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  name: z.string().optional().nullable(),
  unit_of_packing: z.string().optional().nullable(),
  quantity: z
    .string()
    .nullable()
    .transform((value) => (value === null ? '' : value))
    .refine(
      (value) => {
        return (
          (/^\d+(?:\.\d{0,11})?$/.test(value) &&
            value.length <= 5 &&
            value.length >= 0 &&
            parseFloat(value) !== 0) ||
          value === ''
        )
      },
      {
        message: 'Enter a Valid Quantity',
      }
    ),
})

export type CreateSchema = z.infer<typeof formSchema>
