import React, { useEffect, useRef, useState } from 'react'
import Icons from '../../../../../components/common/icons'
import { router_config } from '../../../../../configs/route.config'
import { getColumns } from '../../../../Doctor/patients/all/add_prescription/contactColumns'
import { statusClassGen } from '../../../../../utilities/generators'
import { calcWindowHeight } from '../../../../../utilities/calcHeight'
import { QbsTable } from 'qbs-react-grid'
import CustomisedheaderPrescription from '../../../../../components/common/ListingTiles/customisedheaderPrescription'
import { getSortedColumnName } from '../../../../../utilities/parsers'
import {
  createPrescription,
  usePatientListing,
  usePatients,
  pharmascistAutoDispatch,
} from '../../../../Doctor/patients/all/api'
import { useContactFilterStore } from '../../../../../store/filterSore/contactStore'
import { useNavigate, useParams } from 'react-router-dom'
import PrescriptionEdit from '../../../../Doctor/patients/all/edit'
import { Button, DialogModal } from '../../../../../components/common'
import PatientPrescriptionPrintView from '../../../../Doctor/patients/all/add_prescription/print_view'
import { useSnackbar } from 'notistack'
// import PrescriptionPring from '../print_view/index copy'
import { useReactToPrint } from 'react-to-print'
import PrescriptionPring from '../../../../Doctor/patients/all/add_prescription/print_view/index copy'
import MultiTabContainer from '../../../../../components/common/tab/MultipleTabContainer'
import CustomisedheaderPrescriptionOutside from '../../../../../components/common/ListingTiles/customisedheaderPrescriptionOutside'
import ListingHeader from '../../../../../components/common/ListingTiles'
import { v4 as uuidv4 } from 'uuid'

interface Medicine {
  id?: any
  medicine_id?: any
  name?: any
  quantity?: any
  dose?: any
  administration?: any
  remarks?: any
  dose_id?: any
  admin_id?: any
}
interface selectedMedicine {
  value?: any
  label?: string
  category?: any
}
const tabData: any[] = [
  {
    label: 'Current Stock',

    id: 'DOCTOR_PATIENTS_PRESCRIPTION_CURRENT',
  },
  {
    label: 'From Outside',

    id: 'DOCTOR_PATIENTS_PRESCRIPTION_OUTSIDE',
  },
]

const PharmacyPatientMainAddTable: React.FC = () => {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const [dialogModalActive, setDialogModalActive] = useState(false)
  const [successModalActive, setSuccessModalActive] = useState(false)
  const [customSearch, setCustomSearch] = useState('')
  const [customName, setCustomName] = useState<selectedMedicine>()
  const [customPhone, setCustomPhone] = useState('')
  const [customRemarks, setCustomRemarks] = useState('')
  const [customDose, setCustomDose] = useState<any>()
  const [customEnterName, setCustomEnterName] = useState('')
  const [examined, setExamined] = useState(false)
  const [medicine, setMedicine] = useState<Medicine[]>([])
  const { id2 } = useParams()
  const { data: patients } = usePatientListing()
  const componentRef = useRef<HTMLDivElement>(null)
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })
  console.log(componentRef?.current)
  // const [openPopups, setOpenPopups] = useState(false)
  const { pageParams, setPageParams, selectedRows, setSelectedRows } =
    useContactFilterStore()

  // const [customSearch, setCustomSearch] = useState('')
  const { page, page_size, ordering, search, filters } = pageParams

  // const navigate = useNavigate()
  // const { id, id2 } = useParams()
  // const { setActiveTab } = useOutletContext<any>()
  // const obj3 = {
  //   DOCTOR_PATIENTS_PRESCRIPTION_CURRENT: 'current',
  //   DOCTOR_PATIENTS_PRESCRIPTION_OUTSIDE: 'outside',
  // }
  const [activeTabTwo, setActiveTabTwo] = useState(
    'DOCTOR_PATIENTS_PRESCRIPTION_CURRENT'
  )
  // useEffect(
  //   () => {
  //     setActiveTab('DOCTOR_PATIENTS_PRESCRIPTION')
  //     // navigate(obj3.DOCTOR_PATIENTS_PRESCRIPTION_CURRENT)
  //   }, // eslint-disable-next-line react-hooks/exhaustive-deps
  //   []
  // )
  useEffect(() => {
    patients?.op_records?.map((item: any) => {
      if (item?.patient_id == id2) {
        if (item?.status === 'Examined') {
          setExamined(true)
        }
      }
    })
  }, [patients, id2])

  const onTabClick = (selection: any) => {
    setActiveTabTwo(selection.id)
    // navigate(
    //   `/patient/list/${id}/${id2}/${obj2[activeTab]}/${router_config[
    //     selection.id
    //   ]?.path}`
    // )
  }
  const searchParams = {
    page_number: page,
    page_size: page_size,
    name: search,
    ordering: ordering,
    ...filters,
  }
  const { refetch, isFetching } = usePatients(searchParams)
  useEffect(
    () => {
      refetch()
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, page_size, search, ordering, filters, refetch]
  )

  // const onChangeRowsPerPage = (count: any) => {
  //   setPageParams({
  //     ...pageParams,
  //     page_size: count,
  //     page: 1,
  //   })
  // }
  // const onChangePage = (row: any) => {
  //   setPageParams({
  //     ...pageParams,
  //     page: row,
  //   })
  // }

  // const onViewAction = (r: any) => {
  //   return r.id && setDialogModalActive(true)
  // }
  const onViewActionDelete = (r: any) => {
    const newMedicine = medicine.filter((item: Medicine) => item?.id !== r.id)
    setMedicine(newMedicine)
  }
  const handleSort = (orderColumn: any, orderDirection: any) => {
    setPageParams({
      ...pageParams,
      sortColumn: orderColumn,
      sortType: orderDirection,
      ordering: getSortedColumnName(orderColumn, orderDirection),
    })
  }
  const handleSeach = (key?: string) => {
    setPageParams({
      ...pageParams,
      search: key as string,
      page: 1,
    })
  }
  const handleOnSlect = (rows: any) => {
    setSelectedRows([...rows])
  }

  const [columns, setColumns] = useState<any>(() =>
    getColumns({
      statusClassGen: statusClassGen,
    })
  )

  const handleResetColums = () => {
    setColumns([
      ...getColumns({
        statusClassGen: statusClassGen,
      }),
    ])
  }

  const activity = {
    hideExport: true,
  }

  const basicDatas = {
    title: '',
    icon: '',
  }
  const handleClose = () => {
    setDialogModalActive(false)
  }

  // const handlePrint = () => {
  //   const printableArea = document.getElementById('printable-area')
  //   if (printableArea) {
  //     const content = printableArea.innerHTML
  //     const printWindow = window.open('', '_blank')
  //     if (printWindow) {
  //       printWindow.document.write(
  //         '<html><head><title>Print</title></head><body>' +
  //           content +
  //           '</body></html>'
  //       )
  //       printWindow.document.close()
  //       printWindow.print()
  //     }
  //     setSuccessModalActive(false)
  //   }
  // }
  const handleSubmit = () => {
    setDialogModalActive(false)
    navigate(`${router_config.DOCTOR_PATIENTS_PRESCRIPTION.path}`)
  }
  const handleOpenPrintView = () => {
    const prescriptionArray: any = []

    medicine.map((item: any) => {
      if (item?.medicine_id) {
        const singleData = {
          medicine_id: item?.medicine_id,
          quantity: item?.quantity,
          medicine_dose_id: item?.dose_id,
          medicine_administration_id: item?.admin_id,
          remarks: item?.remarks,
          dispensable: 'true',
        }
        prescriptionArray.push(singleData)
      } else {
        const singleData = {
          out_side_medicine_name: item?.name,
          quantity: item?.quantity,
          remarks: item?.remarks,
          dispensable: 'false',
        }
        prescriptionArray.push(singleData)
      }
    })
    const payload = {
      prescription: {
        op_record_id: id2,
        prescription_items_attributes: prescriptionArray,
      },
    }
    if (!examined) {
      createPrescription(payload)
        .then((res) => {
          console.log(res)
          enqueueSnackbar('Prescription Created successfully', {
            variant: 'success',
          })
          ///call dispatch api here
          const data = {
            records: [res?.data?.prescription_id],
          }
          pharmascistAutoDispatch(data)
          setSuccessModalActive(true)
          // navigate('/patient/all')
        })
        .catch((err: any) => {
          enqueueSnackbar(
            err?.response?.data?.message || 'Something Went Wrong',
            {
              variant: 'error',
            }
          )
        })
    } else {
      enqueueSnackbar('User is already examined', { variant: 'error' })
    }
  }
  const handleCloseModal = () => {
    // navigate(`${router_config.PHARMACIST.path}`)
    setSuccessModalActive(false)
    navigate('/patient/all')
  }

  const handleCreate = () => {
    console.log(
      customSearch,
      customName,
      customName,
      customName,
      'quantity',
      customPhone,
      'dose',
      customDose,
      'remarks:',
      customRemarks
    )
    const doseDetails = customDose ? JSON.parse(customDose) : {}
    const adminDetails = customSearch
      ? JSON.parse(customSearch)
      : { id: null, mode: '' }
    const data: Medicine = {
      id: uuidv4(),
      medicine_id: customName?.value,
      name: customName?.label,
      quantity: customPhone,
      dose: doseDetails?.dose,
      dose_id: doseDetails?.id,
      admin_id: adminDetails?.id,
      administration: adminDetails?.mode,
      remarks: customRemarks,
    }
    const allDetailsPresent = data.medicine_id && data.name && data.quantity

    console.log(
      data.medicine_id,
      data.name,
      data.quantity,
      data.dose,
      data.dose_id,
      data.admin_id,
      data.administration,
      data.remarks
    )
    if (allDetailsPresent) {
      setMedicine((medicine) => [...medicine, data])
      setCustomDose('')
      setCustomSearch('')
      setCustomRemarks('')
      setCustomPhone('')
    } else {
      enqueueSnackbar('Please fill all the details', {
        variant: 'error',
      })
    }
  }
  console.log(medicine)
  const basicData = {
    title: 'Add Prescription',
    icon: 'order',
  }

  const handleCreateOt = () => {
    console.log(customEnterName, customPhone, customRemarks)
    const med: Medicine = {
      id: uuidv4(),
      name: customEnterName,
      quantity: customPhone,
      remarks: customRemarks,
    }

    const allOptions = customEnterName && customPhone && customRemarks
    if (allOptions) {
      setMedicine((medicine) => [...medicine, med])
      setCustomEnterName('')
      setCustomPhone('')
      setCustomRemarks('')
    } else {
      enqueueSnackbar('Please fill all the details', {
        variant: 'error',
      })
    }
  }
  console.log(medicine)
  return (
    <>
      <ListingHeader
        data={basicData}
        setCustomBack={true}
        actionProps={activity}
      />{' '}
      <div className="px-[25px] py-[25px]">
        <MultiTabContainer
          data={tabData}
          activeTab={activeTabTwo}
          onClick={onTabClick}
        >
          {activeTabTwo === 'DOCTOR_PATIENTS_PRESCRIPTION_CURRENT' ? (
            <>
              <div className="">
                <CustomisedheaderPrescription
                  data={basicDatas}
                  onActionClick={handleCreate}
                  actionTitle="Add Medicine"
                  actionProps={activity}
                  setCustomSearch={setCustomSearch}
                  customSearch={customSearch}
                  setCustomName={setCustomName}
                  customName={customName}
                  setCustomPhone={setCustomPhone}
                  customPhone={customPhone}
                  setCustomRemarks={setCustomRemarks}
                  customRemarks={customRemarks}
                  customDose={customDose}
                  setCustomDose={setCustomDose}
                />
              </div>
            </>
          ) : (
            <div className="">
              <CustomisedheaderPrescriptionOutside
                data={basicDatas}
                onActionClick={handleCreateOt}
                setCustomPhone={setCustomPhone}
                customPhone={customPhone}
                actionTitle="Add Medicine"
                actionProps={activity}
                setCustomEnterName={setCustomEnterName}
                customEnterName={customEnterName}
                setCustomRemarks={setCustomRemarks}
                customRemarks={customRemarks}
              />
            </div>
          )}
        </MultiTabContainer>

        <div>
          <QbsTable
            columns={columns}
            dataRowKey="id"
            selectedRows={selectedRows}
            // selection={true}
            isLoading={isFetching}
            toolbar
            handleColumnSort={handleSort}
            height={calcWindowHeight(650)}
            data={medicine ?? []}
            handleResetColumns={() => handleResetColums()}
            // sortType={sortType}
            // sortColumn={sortColumn}
            // pagination
            // paginationProps={{
            //   total: medicine?.length,

            // }}
            actionProps={[
              // {
              //   title: 'View',
              //   action: (rowData) => navigate(onViewAction(rowData)),
              //   icon: <Icons name="edit" />,
              //   toolTip: 'Edit',
              // },
              {
                title: 'Delete',
                action: (rowData) => onViewActionDelete(rowData),
                icon: <Icons name="delete" />,
                toolTip: 'Delete',
              },
            ]}
            searchValue={pageParams?.search}
            onSearch={handleSeach}
            asyncSearch
            handleSearchValue={(key?: string) => handleSeach(key)}
            onSelect={handleOnSlect}
            columnToggle
          />
        </div>
        <div className="pt-[24px] flex justify-end">
          <Button
            onClick={handleOpenPrintView}
            disabled={medicine.length === 0}
            size="xs"
            label="Save"
          />
        </div>
        {dialogModalActive && (
          <DialogModal
            isOpen={true}
            body={<PrescriptionEdit handleSubmit={handleSubmit} />}
            onClose={handleClose}
            // title={'Add New Pharmacist'}
          />
        )}
        {successModalActive && (
          <DialogModal
            isOpen={true}
            body={
              <PatientPrescriptionPrintView
                handleCloseModal={handleCloseModal}
                handlePrint={handlePrint}
                data={medicine}
              />
            }
            onClose={handleCloseModal}
            className="w-[90vw]"
            // title={'Add New Pharmacist'}
          />
        )}
        <div className="hidden">
          {/* <button onClick={handlePrint}>Print</button> */}
          <div id="printable-area" ref={componentRef}>
            <PrescriptionPring data={medicine} />
          </div>
        </div>
      </div>
    </>
  )
}

export default PharmacyPatientMainAddTable
