const defaultColumnProps1 = {
  sortable: false,
  isVisible: true,
  resizable: true,
}

export const getColumns = ({}: any) => {
  const createRenderCell = (key: string, isCustom?: string) => (row: any) => {
    if (isCustom === 'status') {
      console.log(row)
      return {
        cell: (
          <>
            {row?.availability === 'Offline' ? (
              <div className="text-red-700">Offline</div>
            ) : (
              <div className="text-blue-500">Online </div>
            )}
          </>
        ),

        // toolTip: getNestedProperty(row, key) ?? '',
      }
    }
  }

  const column = [
    {
      title: 'Name',
      field: 'name',
      fixed: true,
      ...defaultColumnProps1,
    },
    {
      title: 'Stream',
      field: 'stream',
      ...defaultColumnProps1,
    },
    {
      title: 'Phone Number',
      field: 'phone_number',
      ...defaultColumnProps1,
    },
    {
      title: 'Email',
      field: 'email',
      ...defaultColumnProps1,
      sortKey: '',
      colWidth: 400,
    },
    {
      title: 'Status',
      renderCell: createRenderCell('default_contact.availability', 'status'),
      field: 'availability',
      ...defaultColumnProps1,
      customCell: true,
    },
  ]

  return column
}
