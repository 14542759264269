import { useCallback, useEffect, useState } from 'react'
import { Button } from '../../../../components/common'
// import { useNavigate } from 'react-router-dom'

import { DialogModal } from '../../../../components/common'
import { FormProvider, useForm } from 'react-hook-form'
import CommonForm from '../pharmacist_form'
import { ConfirmModal } from '../../../../components/common'
import { QbsTable } from 'qbs-react-grid'
import moment from 'moment'
import ListingHeader from '../../../../components/common/ListingTiles'
import { router_config } from '../../../../configs/route.config'

import { useContactFilterStore } from '../../../../store/filterSore/contactStore'
import { statusClassGen } from '../../../../utilities/generators'
import { getSortedColumnName } from '../../../../utilities/parsers'
import { useOrderList } from '../api'
import { getColumns } from '../pending orders/detail/contactColumnsDetail'
import { Outlet, useNavigate } from 'react-router-dom'
import Icons from '../../../../components/common/icons'

const OrderRequestDetailHospitalLoan = () => {
  const navigate = useNavigate()
  // const location = useLocation()
  // const [isDrawerOpen, setDrawerOpen] = useState(false)
  const [mergeIsActive, setMergeIsActve] = useState(false)
  // const [search, setSearch] = useState("")
  // const [openPopups, setOpenPopups] = useState(false)
  const { pageParams, setPageParams, selectedRows, setSelectedRows } =
    useContactFilterStore()
  // const { advanceFilter, setAdvanceFilter } = useContactFilterStore()
  // const [showDetail, setShowDetail] = useState(true)
  const [customSearch, setCustomSearch] = useState('')
  const [customType] = useState('')
  const [dialogModalActive, setDialogModalActive] = useState(false)
  const [successModalActive, setSuccessModalActive] = useState(false)
  const { page, page_size, ordering, filters, filterProps } = pageParams
  const methods = useForm<any>({
    // resolver: zodResolver(createSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    // defaultValues: { state: 'Kerala' },
  })
  const searchParams = {
    page_number: page,
    page_size: page_size,
    name: customSearch,
    ...(customType === 'select'
      ? { instution_type: '' }
      : { instution_type: customType }),
    ordering: ordering,
    ...filters,
  }
  const { data, refetch, isFetching } = useOrderList(searchParams)
  useEffect(() => {
    refetch()
  }, [page, page_size, ordering, filters, refetch, customSearch, customType])
  // console.log(customSearch)
  const onChangeRowsPerPage = (count: any) => {
    setPageParams({
      ...pageParams,
      page_size: count,
      page: 1,
    })
  }
  const onChangePage = (row: any) => {
    setPageParams({
      ...pageParams,
      page: row,
    })
  }

  const onViewAction = (r: any, from: string) => {
    console.log(r, from)
  }
  const handleSort = (orderColumn: any, orderDirection: any) => {
    setPageParams({
      ...pageParams,
      sortColumn: orderColumn,
      sortType: orderDirection,
      ordering: getSortedColumnName(orderColumn, orderDirection),
    })
  }
  const handleSeach = (key?: string) => {
    setPageParams({
      ...pageParams,
      search: key as string,
      page: 1,
    })
  }
  const handleOnSlect = (rows: any) => {
    setSelectedRows([...rows])
  }
  // const handleColumnToggle = (columns: any) => {
  //   // console.log(columns)
  // }
  // const handleTileClick = (id: string) => {
  //   // console.log(id)
  // }
  const basicData = {
    title: '',
    icon: '',
  }
  // const dashtiledata = [
  //   {
  //     id: 1,
  //     tiles: [
  //       {
  //         icon: 'user',
  //         label: 'Total Contacts',
  //         total: '$100',
  //         count: 10,
  //         id: 1,
  //       },
  //       {
  //         icon: 'calendar',
  //         label: 'Active Contacts',
  //         total: 100,
  //         count: 10,
  //         id: 2,
  //       },
  //       {
  //         icon: 'calendar',
  //         label: 'Inactive Contacts',
  //         total: 100,
  //         count: 10,
  //         id: 3,
  //       },
  //     ],
  //   },
  //   {
  //     id: 2,
  //     tiles: [
  //       {
  //         label: 'Total Agents',
  //         icon: 'calendar',
  //         total: 100,
  //         count: 10,
  //         id: 1,
  //       },
  //       {
  //         label: 'Active Agents',
  //         icon: 'badge-check',
  //         total: 100,
  //         count: 0,
  //         id: 2,
  //       },
  //       {
  //         label: 'Total Orders',
  //         icon: 'calendar',
  //         isCount: true,
  //         id: 3,
  //       },
  //     ],
  //   },
  // ]
  // const openDrawer = () => {
  //   setDrawerOpen(true)
  // }
  // const openPopup = () => {
  //   setOpenPopups(true)
  // }
  // const handleClose = () => {
  //   setDrawerOpen(false)
  //   setOpenPopups(false)
  // }
  // const handleRefresh = () => {
  //   refetch()
  // }
  // const filterDetails = GetFilterDetails()

  // const handleFilterChange = (dta: any, name: string, id: string) => {
  //   const currentParams = useContactFilterStore.getState()?.pageParams || {}

  //   const getDefaultFilters = () => ({
  //     ...currentParams.filters,
  //     [id]: dta?.id ?? undefined,
  //   })

  //   const getDefaultFilterProps = () => ({
  //     ...currentParams.filterProps,
  //     [name]: dta[name] ?? undefined,
  //   })
  //   const filters = getDefaultFilters()
  //   const filterProps = getDefaultFilterProps()

  //   setPageParams({
  //     ...currentParams,
  //     page: 1,
  //     filters,
  //     filterProps,
  //   })
  // }

  useEffect(() => {
    handleDatepicker()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterProps.endDate])

  const handleDatepicker = useCallback(() => {
    setPageParams({
      ...pageParams,
      filters: {
        ...filters,
        fromDate: filterProps?.startDate
          ? moment(filterProps?.startDate).format('DD-MM-YYYY')
          : '',
        toDate: filterProps?.endDate
          ? moment(filterProps.endDate).format('DD-MM-YYYY')
          : '',
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterProps.endDate])

  // const handleFilterDateChange = (event: any, desc: any, desc2: any) => {
  //   setPageParams({
  //     ...pageParams,
  //     filterProps: {
  //       ...filterProps,
  //       [desc]: event?.value[0],
  //       [desc2]: event?.value[1],
  //     },
  //   })
  // }

  // const handleSHowHide = () => {
  //   return advanceFilter?.filterParams?.find((item) => item.isChecked)
  //     ? true
  //     : false
  // }

  // const handlePath = () => {
  //   if (location.pathname === router_config.CONTACT_MERGE.path) {
  //     setMergeIsActve(true)
  //   } else {
  //     setMergeIsActve(false)
  //   }
  // }

  // useEffect(() => {
  //   handlePath()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [location.pathname])

  const [columns, setColumns] = useState<any>(() =>
    getColumns({
      onViewAction: onViewAction,
      statusClassGen: statusClassGen,
    })
  )

  const handleResetColums = () => {
    setColumns([
      ...getColumns({
        onViewAction: onViewAction,
        statusClassGen: statusClassGen,
      }),
    ])
  }
  if (mergeIsActive) {
    return <Outlet context={{ selectedRows, refetch }} />
  }
  const activity = {
    hideExport: true,
  }

  const handleCreate = () => {
    setDialogModalActive(true)
  }
  const handleClose = () => {
    setDialogModalActive(false)
  }

  const handleMerge = () => {
    navigate(`${router_config.CONTACT_MERGE.path}`)
    setMergeIsActve(true)
  }
  const handleSubmit = () => {
    handleClose()
    setSuccessModalActive(true)
  }
  const handleCloseModal = () => {
    setSuccessModalActive(false)
  }

  // console.log(methods.watch())
  return (
    <>
      {/* <CustomBreadCrumbs /> */}

      <div className="my-5 mx-8 border rounded-[1.25rem]">
        <div className="py-8 px-8">
          <div className="flex flex-col md:flex-row justify-between py-4  items-center">
            <div className="flex gap-4 justify-start">
              <div className="lg:text-m sm:text-xs pe-5 font-normal leading-normal align-middle h-[58px]	text-SidenavFontColor border-r-2  border-bgGreylite">
                <div>Manufacturer </div>
                <div className="font-medium">Oushadhi</div>
              </div>
              <div className="lg:text-m sm:text-xs px-5 font-normal leading-normal align-middle h-[58px]	text-SidenavFontColor border-r-2  border-bgGreylite">
                <div>Total items</div>
                <div className="font-medium">13 items</div>
              </div>
              <div className="lg:text-m sm:text-xs px-5 font-normal leading-normal align-middle h-[58px]	text-SidenavFontColor border-r-2  border-bgGreylite">
                <div>Total amount</div>
                <div className="font-medium">27000,00</div>
              </div>
              <div className="lg:text-m sm:text-xs px-5 font-normal leading-normal align-middle h-[58px]	text-SidenavFontColor border-r-2  border-bgGreylite">
                <div>Purchased On</div>
                <div className="font-medium">15 Oct, 2023</div>
              </div>
              <div className="lg:text-m sm:text-xs px-5 font-normal leading-normal align-middle h-[58px]	text-SidenavFontColor border-r-2  border-bgGreylite">
                <div>Fund Used</div>
                <div className="font-medium">Nam Fund</div>
              </div>
              <div className="lg:text-m sm:text-xs px-5 font-normal leading-normal align-middle h-[58px]	text-SidenavFontColor border-r-2  border-bgGreylite">
                <div>Request Status</div>
                <div className="font-medium text-ShortageStatus">Pending</div>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row gap-4 justify-around items-center">
              <div className="flex gap-4 justify-end h-full items-center">
                <Button
                  label={'Cancel request'}
                  primary={false}
                  outlined
                  type="reset"
                  className="h-[53px] min-w-fit bg-cancelTab/15 text-cancelTab"
                  onClick={handleCreate}
                />
                {dialogModalActive && (
                  <DialogModal
                    isOpen={true}
                    body={
                      <div className="my-8 mx-8">
                        <h2 className="flex text-center w-full mx-auto justify-center text-lime-500 text-2xl font-medium">
                          <u>Edit Pharmacist</u>
                        </h2>
                        <div className="grid md:grid-cols-2 md:gap-8 sm:grid-cols-1 sm:gap-[24px] p-4">
                          <FormProvider {...methods}>
                            <CommonForm edit={true} />
                          </FormProvider>
                        </div>
                        <div className="flex gap-8 justify-center my-3">
                          <Button
                            // onClick={methods.onSubmit}
                            label={'Clear'}
                            outlined={true}
                          />
                          <Button
                            // onClick={methods.handleSubmit}
                            onClick={handleSubmit}
                            label={'Submit'}
                          />
                        </div>
                      </div>
                    }
                    onClose={handleClose}
                    // title={'Add New Pharmacist'}
                  />
                )}
              </div>
            </div>
          </div>

          <ListingHeader
            data={basicData}
            // onHandleExport={openPopup}
            setCustomSearch={setCustomSearch}
            actionProps={activity}
          />
          {/* <PageTiles data={dashtiledata} handleTileClick={handleTileClick} /> */}
          <div className=" pt-8">
            <QbsTable
              data={data ?? []}
              dataRowKey="id"
              // toolbar={true}
              selectedRows={selectedRows}
              // selection={true}
              // searchValue={searchKey}
              // handleColumnToggle={handleColumnToggle}
              // search={true}
              isLoading={isFetching}
              sortType={pageParams.sortType}
              sortColumn={pageParams.sortColumn}
              handleColumnSort={handleSort}
              columns={columns}
              tableBodyHeight="calc(55vh)"
              pagination={true}
              handleResetColumns={() => handleResetColums()}
              paginationProps={{
                onPagination: onChangePage,
                total: data?.count,
                currentPage: pageParams?.page,
                rowsPerPage: Number(pageParams?.page_size ?? data?.page_size),
                onRowsPerPage: onChangeRowsPerPage,
                dropOptions: [10, 20, 30, 50, 100, 200],
              }}
              height={100}
              minHeight={100}
              // tableHeaderActions={}
              // toolbar={false}
              headerHeight={60}
              // classes={["font-size :19px"]}
              actionProps={[
                {
                  icon: <Icons name="eye" />,
                  action: (row) => onViewAction(row, ''),
                  title: 'view',
                },
              ]}
              // primaryFilter={
              //   <PrimaryFilterComponent
              //     filterParams={filterDetails}
              //     handleFilterChange={handleFilterChange}
              //     filterProps={filterProps}
              //     advanceFilter={advanceFilter}
              //     handleFilterDateChange={handleFilterDateChange}
              //     isPrimary={true}
              //     setAdvanceFilter={setAdvanceFilter}
              //   />
              // }
              // advancefilter={
              //   showDetail && (
              //     <PrimaryFilterComponent
              //       filterParams={filterDetails}
              //       handleFilterDateChange={handleFilterDateChange}
              //       handleFilterChange={handleFilterChange}
              //       filterProps={filterProps}
              //       isPrimary={false}
              //       advanceFilter={advanceFilter}
              //     />
              //   )
              // }
              selectedRowActions={[
                {
                  actionTitle: 'Merge',
                  action: () => handleMerge(),
                  customHide: '>2',
                },
              ]}
              searchValue={pageParams?.search}
              onSearch={handleSeach}
              asyncSearch
              handleSearchValue={(key?: string) => handleSeach(key)}
              onSelect={handleOnSlect}
              columnToggle
              // tableHeaderActions={
              //   <div className="flex gap-2">
              //     {handleSHowHide() && (
              //       <Button
              //         onClick={() => {
              //           setShowDetail(!showDetail)
              //         }}
              //         label={showDetail ? 'Hide' : 'Show'}
              //         className="se"
              //         primary
              //       />
              //     )}
              //   </div>
              // }
            />
          </div>
          {successModalActive && (
            <ConfirmModal
              isOpen={true}
              body={
                <div className="font-medium text-primary leading-8 text-xl">
                  Pharmacist Edited Successfully
                </div>
              }
              topIcon="check-circle"
              onClose={handleCloseModal}
              // title={'Add New Pharmacist'}
            />
          )}
        </div>
      </div>
    </>
  )
}
export default OrderRequestDetailHospitalLoan
