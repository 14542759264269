// import FormBuilder from '../../../components/app/formBuilder/index'

import FormBuilder from '../../../../../components/app/formBuilder'
type Props = {
  edit?: boolean
}
// const CommonForm = (data?: any) => {
const EditForm = ({ edit }: Props) => {
  const textField = (
    name: string,
    label: string,
    id: string,
    type: string,
    // placeholder: string,
    required = false,
    config: { data?: Array<{ name: string; id: string }> } = {}
  ) => ({
    name,
    label,
    id,
    type,
    // placeholder,
    ...(required ? { required: true } : {}),
    ...config,
    spacing: 4,
  })
  const formBuilderProps = [
    {
      ...textField('name', 'Medicine Name', 'name', 'text', false),
      disabled: true,
    },
    { ...textField('qty', 'Quantity', 'qty', 'number', false) },
    { ...textField('dose', 'Medicine dose (ml)', 'dose', 'text', false) },
    {
      ...textField(
        'mode',
        'Mode of Administration',
        'mode',
        'custom_select',
        false
      ),
    },
    {
      ...textField('remarks', 'Remarks', 'remarks', 'text', false),
    },
  ]

  // methods.reset({})

  return <FormBuilder data={formBuilderProps} edit={edit} />
}
export default EditForm
