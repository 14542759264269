import { useMutation } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import {
  getData,
  postFormData,
  // postJsonData,
  updateJsonData,
} from '../../../../apis/api.helpers'
import apiUrl from '../../../../apis/api.url'
import { getErrorMessage } from '../../../../utilities/parsers'
import { useQuery } from '@tanstack/react-query'
// import { QueryParams } from '../../../../common/types'
// import { useQuery } from '@tanstack/react-query'
// import { postJsonData } from '../../../../../apis/api.helpers'
// import apiUrl from '../../../../../apis/api.url'
// import { getErrorMessage } from '../../../../../utilities/parsers'

export const createFund = (input: any) => {
  return postFormData(apiUrl.MANUFACTURERS_FUNDS, input)
}

export const useCreateFund = (handleSubmission: (data: any) => void) => {
  const { enqueueSnackbar } = useSnackbar()
  return useMutation(createFund, {
    onSuccess: (res: any) => {
      handleSubmission(res.data)
    },

    onError: (error: any) => {
      {
        error?.response?.data?.message &&
          error?.response?.data?.message.map((item: any) => {
            enqueueSnackbar(getErrorMessage(item), {
              variant: 'error',
            })
          })
      }
    },
  })
}

const fetchData = async (id: any, edit: boolean) => {
  if (edit) {
    const url = `${apiUrl.MANUFACTURERS_FUNDS}/${id}`
    const response = await getData(url)
    // // console.log(response)
    return response
  }
}

export const useFundDetails = (id: any, edit: boolean) => {
  return useQuery(['fund_details'], () => fetchData(id, edit), {
    refetchOnWindowFocus: false,
    //staleTime: 50000,
  })
}

export const updateFunds = (data: { data: any; fund_id: string }) => {
  return updateJsonData(
    `${apiUrl.MANUFACTURERS_FUNDS}/${data?.fund_id}`,
    data?.data
  )
}

export const useUpdateFunds = (
  handleSubmission: (input: any, id: string) => void
) => {
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(updateFunds, {
    onSuccess: (res: any) => {
      handleSubmission(res.data, res.id)
    },

    onError: (error: any) => {
      enqueueSnackbar(
        getErrorMessage(
          error?.response?.data?.message || 'Something went wrong'
        ),
        {
          variant: 'error',
        }
      )
    },
  })
}
