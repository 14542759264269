import { QbsTable } from 'qbs-react-grid'
import { useEffect, useState } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { router_config } from '../../../../configs/route.config'
import { useContactFilterStore } from '../../../../store/filterSore/contactStore'
import { statusClassGen } from '../../../../utilities/generators'
import { getSortedColumnName } from '../../../../utilities/parsers'
import { useOrderRequest } from '.././api'
import { getColumns } from '.././contactColumns'
import { ConfirmModal } from '../../../../components/common'
import { calcWindowHeight } from '../../../../utilities/calcHeight'
import Icons from '../../../../components/common/icons'
// import Create from './create'
// import { GetFilterDetails } from './filterProperties'

const RevertedOrdersMain = () => {
  const navigate = useNavigate()
  const { setActiveTab } = useOutletContext<any>()

  //   const [dialogModalActive, setDialogModalActive] = useState(false)
  const [successModalActive, setSuccessModalActive] = useState(false)

  // const [openPopups, setOpenPopups] = useState(false)
  const { pageParams, setPageParams, selectedRows, setSelectedRows } =
    useContactFilterStore()

  const { page, page_size, search, filters } = pageParams

  const searchParams = {
    page_number: page,
    page_size: page_size,
    name: search,
    status: 'reverted',
    ...filters,
  }
  const { data, refetch, isFetching } = useOrderRequest(searchParams)
  useEffect(
    () => {
      refetch()
      setActiveTab('REVERTED_ORDER')
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, page_size, search, filters, refetch]
  )

  const onChangeRowsPerPage = (count: any) => {
    setPageParams({
      ...pageParams,
      page_size: count,
      page: 1,
    })
  }
  const onChangePage = (row: any) => {
    setPageParams({
      ...pageParams,
      page: row,
    })
  }

  const onViewAction = (r: any) => {
    console.log(r)
    return `${router_config.MANUFACTURER_ORDER_REQUEST.path}/${r.id}`
  }
  const handleSort = (orderColumn: any, orderDirection: any) => {
    setPageParams({
      ...pageParams,
      sortColumn: orderColumn,
      sortType: orderDirection,
      ordering: getSortedColumnName(orderColumn, orderDirection),
    })
  }
  const handleSeach = (key?: string) => {
    setPageParams({
      ...pageParams,
      search: key as string,
      page: 1,
    })
  }
  const handleOnSlect = (rows: any) => {
    setSelectedRows([...rows])
  }

  const [columns, setColumns] = useState<any>(() =>
    getColumns({
      onViewAction: onViewAction,
      statusClassGen: statusClassGen,
    })
  )

  const handleResetColums = () => {
    setColumns([
      ...getColumns({
        onViewAction: onViewAction,
        statusClassGen: statusClassGen,
      }),
    ])
  }

  // const handleCreate = () => {
  //   setDialogModalActive(true)
  // }
  //   const handleClose = () => {
  //     setDialogModalActive(false)
  //   }
  //   const handleSubmit = () => {
  //     setDialogModalActive(false)
  //     setSuccessModalActive(true)
  //   }
  const handleCloseModal = () => {
    // navigate(`${router_config.PHARMACIST.path}`)
    setSuccessModalActive(false)
  }
  return (
    <div className="">
      <div className=" pt-[24px]">
        <QbsTable
          columns={columns}
          dataRowKey="id"
          selectedRows={selectedRows}
          isLoading={isFetching}
          toolbar
          search
          handleColumnSort={handleSort}
          height={calcWindowHeight(439)}
          data={data?.order_requests ?? []}
          handleResetColumns={() => handleResetColums()}
          pagination
          paginationProps={{
            onPagination: onChangePage,
            total: data?.total_count,
            currentPage: pageParams?.page,
            rowsPerPage: Number(pageParams?.page_size ?? data?.page_size),
            onRowsPerPage: onChangeRowsPerPage,
            dropOptions: [10, 20, 30, 50, 100, 200],
          }}
          actionProps={[
            {
              title: 'View',
              action: (rowData) => navigate(onViewAction(rowData)),
              icon: <Icons name="eye" />,
              toolTip: 'View',
            },
          ]}
          searchValue={pageParams?.search}
          onSearch={handleSeach}
          asyncSearch
          handleSearchValue={(key?: string) => handleSeach(key)}
          onSelect={handleOnSlect}
          columnToggle
        />
      </div>
      {successModalActive && (
        <ConfirmModal
          isOpen={true}
          body={
            <div className="font-medium text-primary leading-8 text-xl">
              Stock Added Successfully
            </div>
          }
          topIcon="check-circle"
          onClose={handleCloseModal}
        />
      )}
    </div>
  )
}

export default RevertedOrdersMain
