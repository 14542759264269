import { QbsTable } from 'qbs-react-grid'
import { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import ListingHeader from '../../components/common/ListingTiles'
import { router_config } from '../../configs/route.config'
import { useContactFilterStore } from '../../store/filterSore/contactStore'
import { statusClassGen } from '../../utilities/generators'
import { getSortedColumnName } from '../../utilities/parsers'
import { useContact } from './api'
import { getColumns } from './contactColumns'
import { calcWindowHeight } from '../../utilities/calcHeight'
import Icons from '../../components/common/icons'
// import { PrimaryFilterComponent } from '../../components/app/filterComponents/primaryFilter'

const ManufacturerMain = () => {
  const navigate = useNavigate()
  const [mergeIsActive] = useState(false)
  const { pageParams, setPageParams, selectedRows, setSelectedRows } =
    useContactFilterStore()
  const [customSearch, setCustomSearch] = useState('')
  const location = useLocation()
  const { page, page_size, search, ordering, filters } = pageParams

  const searchParams = {
    page_number: page,
    page_size: page_size,
    name: search,

    ordering: ordering,
    ...filters,
  }
  const { data, refetch, isFetching } = useContact(searchParams)
  useEffect(() => {
    refetch()
  }, [page, page_size, search, customSearch, ordering, filters, refetch])

  const onChangeRowsPerPage = (count: any) => {
    setPageParams({
      ...pageParams,
      page_size: count,
      page: 1,
    })
  }
  const onChangePage = (row: any) => {
    setPageParams({
      ...pageParams,
      page: row,
    })
  }

  // const onViewAction = (r: any, from: string) => {
  //   // console.log(r, from)
  // }
  const handleSort = (orderColumn: any, orderDirection: any) => {
    setPageParams({
      ...pageParams,
      sortColumn: orderColumn,
      sortType: orderDirection,
      ordering: getSortedColumnName(orderColumn, orderDirection),
    })
  }
  const handleSeach = (key?: string) => {
    setPageParams({
      ...pageParams,
      search: key as string,
      page: 1,
    })
  }
  const handleOnSlect = (rows: any) => {
    setSelectedRows([...rows])
  }

  const basicData = {
    title: 'Manufacturer',
    icon: 'Manufacturer',
  }
  const basicDatas = {
    title: 'District Medical Store',
    icon: 'Manufacturer',
  }

  const [columns, setColumns] = useState<any>(() =>
    getColumns({
      // onViewAction: onViewAction,
      statusClassGen: statusClassGen,
    })
  )

  const handleResetColums = () => {
    setColumns([
      ...getColumns({
        // onViewAction: onViewAction,
        statusClassGen: statusClassGen,
      }),
    ])
  }
  if (mergeIsActive) {
    return <Outlet context={{ selectedRows, refetch }} />
  }
  const activity = {
    hideExport: true,
  }
  const handleCreate = () => {
    navigate(`${router_config.MANUFACTURER_CREATE.path}`)
  }
  const handleCreateMedicalStore = () => {
    navigate(`${router_config.DISTRICT_MEDICAL_CREATE.path}`)
  }

  const onViewAction = (r: any) => {
    return r.id && `${router_config.MANUFACTURER.path}/${r.id}`
  }
  return (
    <div className="">
      <div className="">
        {location.pathname === '/district-medical-store' ? (
          <ListingHeader
            data={basicDatas}
            onActionClick={handleCreateMedicalStore}
            actionTitle="Add Medical Store"
            actionProps={activity}
            setCustomSearch={setCustomSearch}
          />
        ) : (
          <ListingHeader
            data={basicData}
            onActionClick={handleCreate}
            actionTitle="Add Manufacturer"
            actionProps={activity}
            setCustomSearch={setCustomSearch}
          />
        )}

        <div className=" px-[24px] py-[24px]">
          <QbsTable
            columns={columns}
            dataRowKey="id"
            selectedRows={selectedRows}
            // selection={true}
            isLoading={isFetching}
            toolbar
            search
            handleColumnSort={handleSort}
            height={calcWindowHeight(382)}
            data={data?.manufacturers ?? []}
            handleResetColumns={() => handleResetColums()}
            // sortType={sortType}
            // sortColumn={sortColumn}
            pagination
            paginationProps={{
              onPagination: onChangePage,
              total: data?.total_count,
              currentPage: pageParams?.page,
              rowsPerPage: Number(pageParams?.page_size ?? data?.page_size),
              onRowsPerPage: onChangeRowsPerPage,
              dropOptions: [10, 20, 30, 50, 100, 200],
            }}
            actionProps={[
              {
                title: 'View',
                action: (rowData) => navigate(onViewAction(rowData)),
                icon: <Icons name="edit" />,
                toolTip: 'Edit',
              },
            ]}
            // selectedRowActions={[
            //   {
            //     actionTitle: 'Merge',
            //     action: () => handleMerge(),
            //     customHide: '>2',
            //   },
            // ]}
            searchValue={pageParams?.search}
            onSearch={handleSeach}
            asyncSearch
            handleSearchValue={(key?: string) => handleSeach(key)}
            onSelect={handleOnSlect}
            columnToggle
          />
        </div>
      </div>
    </div>
  )
}

export default ManufacturerMain
