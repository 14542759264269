import { useState } from 'react'
// import Icons from '../components/common/icons'

import Header from './header'
import SideNav from './sidenav'

export default function Layout({ children }: any) {
  const [sideBar, setSideBar] = useState(true)

  const handleSideBar = () => {
    setSideBar((prev) => !prev)
  }
  return (
    <div
      className={`flex h-screen bg-mainBgColor dark:bg-gray-700 dark:text-gray-100`}
    >
      <div className="flex h-full w-full overflow-auto flex-col">
        {/* <div>
          <Icons name="sidebar" />{' '}
        </div> */}
        <Header handleSideBar={handleSideBar} />
        <div className="flex-1 flex  overflow-hidden relative ">
          {sideBar && <SideNav />}
          <main
            className={` dark:bg-gray-700 dark:text-gray-100 bg-mainBgColor
             flex flex-col w-full h-full overflow-x-hidden overflow-y-auto`}
          >
            {/* <BreadCrumb /> */}
            {children}
          </main>
        </div>
      </div>
    </div>
  )
}
