// import FormBuilder from '../../../components/app/formBuilder/index'

import FormBuilder from '../../../components/app/formBuilder'
// import { districtData } from '../store'
import { useLocation } from '../../contacts/create/api'
type Props = {
  edit?: boolean
}
// const CommonForm = (data?: any) => {
const CommonForm = ({ edit }: Props) => {
  const { data } = useLocation()
  // console.log(data)
  const textField = (
    name: string,
    label: string,
    id: string,
    type: string,
    // placeholder: string,
    required = false,
    config: { data?: Array<{ name: string; id: string }> } = {}
  ) => ({
    name,
    label,
    id,
    type,
    // placeholder,
    ...(required ? { required: true } : {}),
    ...config,
    spacing: 4,
  })
  const formBuilderProps = [
    { ...textField('state', 'State', 'state', 'text', false), disabled: true },
    // { ...textField('name', 'Name', 'name', 'text', true)},
    {
      ...textField(
        'district_name',
        'District',
        'district_id',
        'custom_select',
        true,
        {
          data: data?.districts,
        }
      ),
      placeholder: 'Choose one',
      disabled: edit ? true : false,
    },

    {
      ...textField('email', 'Email address', 'address', 'email', true),
      disabled: edit ? true : false,
    },
    {
      ...textField(
        'land_phone_number',
        'Land phone number',
        'land_phone_number',
        'number',
        true
      ),
      disabled: edit ? false : false,
    },
    {
      ...textField('address', 'Full address', 'address', 'text', true),
      disabled: edit ? false : false,
    },
    {
      ...textField(
        'phone_number',
        'Mobile number',
        'phone_number',
        'number',
        true
      ),
      disabled: edit ? false : false,
    },
  ]

  // methods.reset({})

  return <FormBuilder data={formBuilderProps} edit={edit} />
}
export default CommonForm
