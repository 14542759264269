import { useMutation, useQuery } from '@tanstack/react-query'
// import { AxiosRequestConfig } from 'axios'
import { useSnackbar } from 'notistack'
import { getErrorMessage } from '../../../utilities/parsers'
// import { QueryParams } from '../../../common/types'
import apiUrl from '../../../apis/api.url'
import { getData, updateFromData } from '../../../apis/api.helpers'

// import {
//   getConfigData,
//   getData,
//   postData,
//   updateData,
// } from '../../apis/api.helpers'
// import apiUrl from '../../apis/api.url'
// import { QueryParams } from '../../common/types'
// import { getErrorMessage, parseQueryParams } from '../../utilities/parsers'

const buildUrlWithParams = (baseUrl: string, params: any) => {
  return `${baseUrl}/${params}`
}

const fetchData = async (id: any) => {
  const url = buildUrlWithParams(apiUrl.INSTITUTIONS_LIST_URL, id)
  const response = await getData(url)
  // console.log(response)
  return response
}

export const useInstitution = (id: any) => {
  return useQuery(['institution_detail'], () => fetchData(id), {
    refetchOnWindowFocus: false,
    //staleTime: 50000,
  })
}

// export const updateInstitution = (input: any, id:any) => {
//   // console.log(input , id)
//   return updateFromData(`${apiUrl.UPDATE_INSTITUTION}/${id}`, input)
// }

// export const useUpdateInstitution = (handleSubmission: (data: any) => void) => {
//   const { enqueueSnackbar } = useSnackbar()
//   // // console.log(data)
//   return useMutation(updateInstitution, {
//     onSuccess: (res: any) => {
//       handleSubmission(res.data)
//       // enqueueSnackbar('Contact Created successfully', { variant: 'success' })
//     },

//     onError: (error: any) => {
//       enqueueSnackbar(getErrorMessage(error?.response?.data?.message || "Something went wrong"), {
//         variant: 'error',
//       })
//     },
//   })
// }

export const updateInstitution = (data: {
  data: any
  institutionId: string
}) => {
  // console.log(data)
  return updateFromData(
    `${apiUrl.UPDATE_INSTITUTION}/${data?.institutionId}`,
    data?.data
  )
}

export const useUpdateInstitution = (
  handleSubmission: (input: any, id: string) => void
) => {
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(updateInstitution, {
    onSuccess: (res: any) => {
      handleSubmission(res.data, res.id)
      // enqueueSnackbar('Contact Created successfully', { variant: 'success' })
    },

    onError: (error: any) => {
      // console.log(error)
      {
        error.response.data.message &&
          error.response.data.message.map((item: any) => {
            console.log(item)
            enqueueSnackbar(getErrorMessage(item), {
              variant: 'error',
            })
          })
      }
    },
  })
}

// export const getContactMergeDetails = (data: any) => {
//   return postData(apiUrl.CONTACT_MULTIPLE_DETAILS, data)
// }

// export const mergeContacts = (data: any, id?: string) => {
//   return updateData(`${apiUrl.CONTACT_LIST_URL}${id}/merge/`, data)
// }
// export const getContactDetails = (id: any) => {
//   return getData(`${apiUrl.CONTACT_LIST_URL}${id}`)
// }

// export const setPrimaryAccount = (id: string, data: any) => {
//   return updateData(
//     `${apiUrl.CONTACT_LIST_URL}${id}/set-primary-account/`,
//     data
//   )
// }

// export const getContactAccountDetails = (id: any) => {
//   return getData(`${apiUrl.CONTACT_LIST_URL}${id}/accounts/?page=1`)
// }

// const accountsfetchData = async (id: string, input: QueryParams) => {
//   const url = buildUrlWithParams(`${apiUrl.CONTACT_LIST_URL}${id}/accounts/`, {

// const accountsfetchData = async (id: string, input: QueryParams) => {
//   const url = buildUrlWithParams(`${apiUrl.CONTACT_LIST_URL}${id}/accounts/`, {
//     ...input,
//   })
//   const response = await getData(url)
//   return response?.data
// }

// export const useContactAccountDetails = (id: string, input: QueryParams) => {
//   return useQuery(
//     ['assosiated_accout_list'],
//     () => accountsfetchData(id, input),
//     {
//       refetchOnWindowFocus: false,
//       //staleTime: 50000,
//     }
//   )
// }
// export const getContactDuplicate = (data: any, config: AxiosRequestConfig) =>
//   getConfigData(
//     `${apiUrl.CONTACT_LIST_URL}possible-contact-duplicates/${parseQueryParams(
//       data ?? {}
//     )}`,
//     config
//   )

// export const listContactDuplicate = (id?: string, data?: any) =>
//   getData(
//     `${apiUrl.CONTACT_LIST_URL}${id}/contact-duplicates/${parseQueryParams(
//       data
//     )}`
//   )

// export const updateContactCre = (id: string, data: any) => {
//   return postData(`${apiUrl.CONTACT_LIST_URL}${id}/allocate-cre`, data)
// }
