// import { Router } from "react-router-dom"

// import { Link } from 'react-router-dom'
// import { router_config } from '../../../configs/route.config'
// import Info from '../../../components/common/icons/Info'

// const defaultColumnProps = {
//   sortable: true,
//   resizable: true,
//   isVisible: true,
// }
const defaultColumnProps1 = {
  sortable: false,
  isVisible: true,
  resizable: true,
}

export const getColumns = ({}: any) => {
  const createRenderCell = (key: string, isCustom?: string) => (row: any) => {
    if (isCustom === 'total') {
      return {
        cell: <div>{row?.requested_quantity * row?.price_per_unit}</div>,
      }
    }
  }
  const column = [
    {
      title: 'Medicine name',
      field: 'name',
      fixed: true,
      ...defaultColumnProps1,
      // link: true,
      // sortKey: 'default_contact__first_name',
      // rowClick: (row: any) => onViewAction(row, 'contactName'),
    },
    {
      title: 'Unit of Packing',
      // renderCell: createRenderCell('cre_agent.name'),
      field: 'unit_of_packing',
      ...defaultColumnProps1,
      // customCell: true,
      // sortKey: 'cre_agent_user_first_name',
    },
    {
      title: 'Price/Unit',
      field: 'price_per_unit',
      ...defaultColumnProps1,
    },
    {
      title: 'Requested Quantity',
      field: 'requested_quantity',
      ...defaultColumnProps1,

      sortKey: '',
      // renderCell: createRenderCell('primary_company.business_name'),
      // rowClick: (row: any) => onViewAction(row, 'business_name'),
    },
    {
      title: 'Total Amount',
      ...defaultColumnProps1,
      customCell: true,
      renderCell: createRenderCell('primary_company.business_name', 'total'),
    },
    // {
    //   title: 'Action',
    //   renderCell: createRenderCell('default_contact.mobile', 'action'),
    //   field: 'mobile',
    //   ...defaultColumnProps1,
    //   customCell: true,
    //   link: true,
    //   getpath: () => '/dashboard',
    // },
  ]

  return column
}
