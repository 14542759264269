import { AutoComplete } from 'qbs-core'
import { useCallback, useEffect, useState } from 'react'
import {
  AdvanceFilter,
  FilterParams,
  FilterProps,
  PrimaryFilter,
} from '../../../common/types'
import { useClearFilter } from '../../../store/filterSore/clearStore'
import { debounce } from '../../../utilities/debounce'
// import Icons from '../../common/icons'
import DatePickerComponent from '../../common/inputs/DatePicker'
import TextField from '../../common/inputs/TextField'
import FilterDropDown from './filterDropDown'
type InputProps = {
  value: string
  desc: string
}
type Props = {
  filterParams: PrimaryFilter[]
  filterProps: FilterProps
  handleFilterChange: (value: any, desc: string, objectId: string) => void
  advanceFilter?: AdvanceFilter
  handleFilterDateChange: (
    value: any,
    desc: string,
    descSecondary?: string
  ) => void
  isPrimary?: boolean
  setAdvanceFilter?: (filter: AdvanceFilter) => void
  handleFilterTextChange?: ({ value, desc }: InputProps) => void
}

export const PrimaryFilterComponent = ({
  filterParams,
  filterProps,
  handleFilterChange,
  advanceFilter,
  isPrimary,
  setAdvanceFilter,
  handleFilterDateChange,
  handleFilterTextChange,
}: Props) => {
  const [inputValue, setInputValue] = useState({ value: '', desc: '' })
  const handleClearAll = useClearFilter('filter_only')
  const handleMultiSelect = (value: any, name: string, id: string) => {
    const ids = value ? value?.map((item: any) => item.id) : ''
    const idsString = ids.join(',')
    // console.log(filterProps[name])
    const result = {
      id: idsString,
      [name]: value ?? [],
    }
    handleFilterChange(result, name, id)
  }
  const renderAutoSuggestion = (item: PrimaryFilter) => (
    <AutoComplete
      placeholder={item.name}
      desc={item.desc}
      type={'auto_suggestion'}
      descId={item.descId as string}
      paginationEnabled={item.paginationEnabled}
      nextBlock={item.nextBlock ?? undefined}
      value={filterProps ? filterProps[item.desc] : ''}
      getData={item.getData}
      initialLoad={item.initialLoad}
      async={item.async}
      name={item.name}
      onChange={(value: any) =>
        handleFilterChange(value, item.desc, item.objectId as string)
      }
    />
  )
  const renderAutoSuggestionMultiSelect = (item: PrimaryFilter) => (
    <AutoComplete
      placeholder={item.name}
      desc={item.desc}
      type={'auto_suggestion'}
      isMultiple={true}
      selectedItems={filterProps ? filterProps[item.desc] : []}
      descId={item.descId as string}
      paginationEnabled={item.paginationEnabled}
      nextBlock={item.nextBlock ?? undefined}
      value={''}
      getData={item.getData}
      initialLoad={item.initialLoad}
      async={item.async}
      name={item.name}
      onChange={(value: any) =>
        handleMultiSelect(value, item.desc, item.objectId as string)
      }
    />
  )
  const renderCustomSelect = (item: PrimaryFilter) => (
    <AutoComplete
      placeholder={item.name}
      desc={item.desc}
      type={'custom_select'}
      descId={item.descId as string}
      value={filterProps ? filterProps[item.desc] : ''}
      data={item.data}
      name={item.name}
      onChange={(value: any) =>
        handleFilterChange(value, item.desc, item.objectId as string)
      }
    />
  )
  const renderCustomSearchSelect = (item: PrimaryFilter) => (
    <AutoComplete
      placeholder={item.name}
      desc={item.desc}
      type={'custom_search_select'}
      descId={item.descId as string}
      value={filterProps ? filterProps[item.desc] : ''}
      data={item.data}
      name={item.name}
      onChange={(value: any) =>
        handleFilterChange(value, item.desc, item.objectId as string)
      }
    />
  )
  const renderDatePicker = (item: PrimaryFilter) => (
    <DatePickerComponent
      placeholder={item.name}
      name={item.name}
      value={
        filterProps
          ? [filterProps[item.desc], filterProps[item.descSecondary as string]]
          : [null, null]
      }
      onChange={(value: any) =>
        handleFilterDateChange(value, item.desc, item.descSecondary)
      }
      selectRange={item?.selectRange}
    />
  )
  const renderTextField = (item: PrimaryFilter) => {
    return (
      <TextField
        placeholder={item.name}
        name={item.name}
        id={item.name}
        value={inputValue?.value ? inputValue?.value : filterProps[item.desc]}
        onChange={(e) => handleChange(e, item.desc)}
      />
    )
  }
  const handleChange = (e: any, desc: string) => {
    setInputValue({ value: e?.target?.value, desc: desc })
  }
  const updateFilter = debounce(() => {
    handleFilterTextChange?.(inputValue)
  }, 1000)

  useEffect(() => {
    updateFilter()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue.value])

  const renderFilterByType = (item: PrimaryFilter) => {
    switch (item.type) {
      case 'auto_suggestion':
        return renderAutoSuggestion(item)
      case 'custom_search_select':
        return renderCustomSearchSelect(item)
      case 'custom_select':
        return renderCustomSelect(item)
      case 'date_picker':
        return renderDatePicker(item)
      case 'text_field':
        return renderTextField(item)
      case 'multi_select':
        return renderAutoSuggestionMultiSelect(item)
      default:
        return null
    }
  }

  const shouldRenderAdvanceFilter = (item: PrimaryFilter) => {
    const detail = advanceFilter?.filterParams.find(
      (items) => items.slug === item.slug && items.isChecked
    )
    return detail ? <div> {renderFilterByType(item)}</div> : <></>
  }

  const handleClear = useCallback(
    (val?: FilterParams) => {
      const clearData = filterParams.find((item) => item.slug === val?.slug)
      if (
        clearData?.type === 'auto_suggestion' ||
        clearData?.type === 'auto_complete'
      ) {
        handleFilterChange({}, clearData.desc, clearData?.objectId as string)
      }
    },
    [handleFilterChange, filterParams]
  )

  return (
    <div className="flex items-center flex-wrap gap-3">
      {filterParams.map((item, index) => {
        if (isPrimary && item.isPrimary) {
          return (
            <div className=" w-56" key={index}>
              {renderFilterByType(item)}
            </div>
          )
        } else if (!isPrimary && !item.isPrimary) {
          return <>{shouldRenderAdvanceFilter(item)}</>
        }
        return null
      })}
      {advanceFilter?.isDetailed && isPrimary && (
        <FilterDropDown
          setAdvanceFilter={setAdvanceFilter}
          handleClear={handleClear}
          advanceFilter={advanceFilter}
        />
      )}

      {isPrimary &&
        Object.values(filterProps).some(
          (value) => value !== '' && value !== null && value !== undefined
        ) && (
          <button
            onClick={() => handleClearAll()}
            className="flex items-center justify-center gap-2 text-sm text-secondary"
          >
            {/* <Icons name="close" className="iconWidthSm" /> Clear filter */}
          </button>
        )}
    </div>
  )
}
