// import FormBuilder from '../../../components/app/formBuilder/index'

import { Button } from '../../../../../components/common'
import CommonForm from '../form'
import { FormProvider, useForm } from 'react-hook-form'
type ManagePhasrmacistProps = {
  handleSubmit: () => void
  handleClose: () => void
}
const PrimaryStockCreate: React.FC<ManagePhasrmacistProps> = ({
  handleSubmit,
  handleClose,
}) => {
  const methods = useForm<any>({
    // resolver: zodResolver(createSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  // console.log(methods.watch())
  // methods.reset({})

  return (
    <>
      <div className="my-5 mx-8">
        <h2 className="flex text-center w-full mx-auto justify-center text-lime-500 text-2xl font-medium">
          <u>Add Stock</u>
        </h2>
        <div className="grid grid-cols-3 gap-4">
          <div className="col-span-2">
            <FormProvider {...methods}>
              <CommonForm edit={true} />
            </FormProvider>
          </div>
          <div className="cursor-pointer text-m font-normal flex items-end pb-2.5	text-AvailabilityMark underline">
            Upload Excel Format
          </div>
        </div>
        <div className="flex gap-8 justify-center my-3">
          <Button onClick={handleClose} label={'Cancel'} outlined={true} />
          <Button
            // onClick={methods.handleSubmit}
            onClick={handleSubmit}
            label={'Submit'}
          />
        </div>
      </div>
    </>
  )
}
export default PrimaryStockCreate
