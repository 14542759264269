import { Suspense } from 'react'
import './App.css'
import MainRoutes from './routes/mainRoutes'
// import { useAuthStore } from "./store/authStore";
import { useThemeStore } from './store/themeStore'
import './styles/styles.scss'
// import WebSocketComponent from "./websocket";
function App() {
  const { isDark } = useThemeStore()
  return (
    <div className={`${isDark ? 'dark' : 'light'}`}>
      {/* {authenticated && <WebSocketComponent />} */}
      <Suspense
      // fallback={
      //   <div className="w-screeen h-screen flex text-[52px] bg-white dark:bg-black text-black dark:text-white">
      //     Loading...
      //   </div>
      // }
      >
        <MainRoutes />
      </Suspense>
    </div>
  )
}

export default App
