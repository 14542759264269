const defaultColumnProps1 = {
  sortable: false,
  isVisible: true,
  resizable: true,
}
// const statusInfo: 1 | 2 = 1

export const getColumns = ({}: any) => {
  const column = [
    {
      title: 'Order ID',
      field: 'order_id',
      // fixed: true,
      ...defaultColumnProps1,
      // link: true,
      // sortKey: 'default_contact__first_name',
      // rowClick: (row: any) => onViewAction(row, 'contactName'),

      customCell: true,
    },
    {
      title: 'Manufacturer Name',
      // renderCell: createRenderCell('cre_agent.name'),
      field: 'manufacturer_name',
      ...defaultColumnProps1,
      // customCell: true,
      // sortKey: 'cre_agent_user_first_name',

      customCell: true,
    },
    {
      title: 'Phone Number',
      field: 'phone_number',
      ...defaultColumnProps1,

      customCell: true,
    },
    {
      title: 'Email',
      field: 'email_id',
      ...defaultColumnProps1,

      sortKey: '',

      customCell: true,
    },
    // {
    //   title: 'Status',
    //   renderCell: createRenderCell('default_contact.mobile'),
    //   field: 'mobile',
    //   ...defaultColumnProps1,
    //   customCell: true,
    //   // link: true,
    //   // getpath: () => '/dashboard',
    // },

    // {
    //   title: 'Delivered On',
    //   // field: 'exp_date',
    //   ...defaultColumnProps1,
    //   // sortKey: 'company_code',
    //   renderCell: createRenderCell('primary_company.company_code', 'expDate'),

    //   customCell: true,
    // },
  ]

  return column
}
