import { useMutation } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import {
  getData,
  postFormData,
  postJsonData,
  updateJsonData,
} from '../../../../../apis/api.helpers'
import apiUrl from '../../../../../apis/api.url'
import {
  getErrorMessage,
  parseQueryParams,
} from '../../../../../utilities/parsers'
import { useQuery } from '@tanstack/react-query'

export const createPatient = (input: any) => {
  return postJsonData(apiUrl.PATIENTS, input)
}
export const updatePatient = (input: any) => {
  const data = input?.data
  const id = input?.pat_id
  return updateJsonData(`${apiUrl.PATIENTS}/${id}`, data)
}
export const updateOpPatient = (input: any) => {
  const data = input?.data
  const id = input?.id
  return postFormData(`${apiUrl.ADDEXISTINGOPPATIENT}?id=${id}`, data)
}
const buildUrlWithParams = (baseUrl: string, params: any) => {
  return `${baseUrl}${parseQueryParams(params)}`
}
export const useCreatePatient = (handleSubmission: (data: any) => void) => {
  const { enqueueSnackbar } = useSnackbar()
  return useMutation(createPatient, {
    onSuccess: (res: any) => {
      handleSubmission(res.data)
    },

    onError: (error: any) => {
      if (typeof error?.response?.data?.message === 'string') {
        enqueueSnackbar(getErrorMessage(error?.response?.data?.message), {
          variant: 'error',
        })
      } else {
        error?.response?.data?.message &&
          error?.response?.data?.message.map((item: any) => {
            enqueueSnackbar(getErrorMessage(item), {
              variant: 'error',
            })
          })
      }
    },
  })
}
export const useUpdatePatient = (handleSubmission: (data: any) => void) => {
  const { enqueueSnackbar } = useSnackbar()
  return useMutation(updatePatient, {
    onSuccess: (res: any) => {
      handleSubmission(res.data)
    },

    onError: (error: any) => {
      if (typeof error?.response?.data?.message === 'string') {
        enqueueSnackbar(getErrorMessage(error?.response?.data?.message), {
          variant: 'error',
        })
      } else {
        error?.response?.data?.message &&
          error?.response?.data?.message.map((item: any) => {
            enqueueSnackbar(getErrorMessage(item), {
              variant: 'error',
            })
          })
      }
    },
  })
}

export const useUpdateOpPatient = (handleSubmission: (data: any) => void) => {
  const { enqueueSnackbar } = useSnackbar()
  return useMutation(updateOpPatient, {
    onSuccess: (res: any) => {
      handleSubmission(res.data)
    },

    onError: (error: any) => {
      if (typeof error?.response?.data?.message === 'string') {
        enqueueSnackbar(getErrorMessage(error?.response?.data?.message), {
          variant: 'error',
        })
      } else {
        error?.response?.data?.message &&
          error?.response?.data?.message.map((item: any) => {
            enqueueSnackbar(getErrorMessage(item), {
              variant: 'error',
            })
          })
      }
    },
  })
}

const fetchData = async () => {
  const url = buildUrlWithParams(apiUrl.DOCTOR, {})
  const response = await getData(url)
  // console.log(response)
  return response
}

export const useDoctors = () => {
  return useQuery(['doctors_list'], () => fetchData(), {
    refetchOnWindowFocus: false,
    //staleTime: 50000,
  })
}
