import { useState } from 'react'
import { Button, ConfirmModal } from '../../../../components/common'
import ListingHeader from '../../../../components/common/ListingTiles'
import { useNavigate, useParams } from 'react-router-dom'
import {
  useLoanApprove,
  useLoanDetail,
  useLoanDetailResMessage,
  useLoanDetailResponse,
} from '../../request/all/api'
import moment from 'moment'
import { router_config } from '../../../../configs/route.config'

const DistrictAdminLoanResponseDetail = () => {
  const [setCustomBack] = useState(true)
  const { id2, id3 } = useParams()
  const navigate = useNavigate()
  const searchParam = {
    page_number: 1,
    page_size: 30,
  }
  const searchParams = {
    page_number: 1,
    page_size: 30,
    loan_request_id: id3,
  }

  const [successModalActive, setSuccessModalActive] = useState(false)
  const { data: data } = useLoanDetail(id2, searchParam)
  const { data: revertMessage } = useLoanDetailResMessage(searchParams)
  const { data: response } = useLoanDetailResponse(searchParams)

  console.log(response)
  console.log(location)

  const basicData = {
    title: 'Loan Between Institution Details',
    icon: 'institution',
  }

  const activity = {
    hideExport: true,
  }
  const onSuccess = () => {
    setSuccessModalActive(true)
  }
  const { mutate } = useLoanApprove(onSuccess)

  const handleCloseModal = () => {
    navigate(`${router_config.DISTRICT_ADMIN_LOAN_RESPOND.path}/all`)

    setSuccessModalActive(false)
  }
  const handleCreate = () => {
    const payload = {
      loan_request_id: id3,
      // status: 'confirmed',
      // message : methods.watch().message ? methods.watch().message : ""
    }
    mutate(payload)
  }

  return (
    <>
      <ListingHeader
        data={basicData}
        actionProps={activity}
        setCustomBack={setCustomBack}
      />
      <div className=" px-[24px] py-[24px]">
        <div className="">
          <div className="flex flex-col md:flex-row gap-4 justify-between  items-start">
            <div>
              <div className="flex gap-4 justify-start  h-[103px] items-start">
                <div className="w-[80px] h-[80px] rounded-full ">
                  <img
                    src={data?.institution_image_url}
                    alt=""
                    className="w-full h-full object-cover rounded-full "
                  />
                </div>
                <div className=" sm:text-xs  font-normal leading-normal">
                  <div className="text-2xl text-primary">
                    {data?.institution_name}
                  </div>
                  <div className="my-1">{data?.stream}</div>
                  <div className="text-AvailabilityMark">{data?.district}</div>
                </div>
              </div>
            </div>
            {response?.status === 'accepted' && (
              <div className="flex flex-col 2xl:flex-row justify-between xl:items-center 2xl:gap-2 gap-4">
                <div className="flex gap-4 justify-end h-full ">
                  <Button
                    label={'Approve'}
                    onClick={handleCreate}
                    className=""
                  />
                </div>
              </div>
            )}
          </div>
          <hr />
          <div className="w-100 py-[24px]">
            <div className="flex gap-4 justify-between w-100">
              <div className=" lg:text-xs text-[10px] px-1 2xl:px-2 font-normal leading-normal align-middle h-[40px]	text-SidenavFontColor">
                <div className="font-bold text-black">Responded Hospital</div>
                <div>
                  <div className="text-[16px] font-medium text-primary leading-normal">
                    {' '}
                    {response?.responded_hospital_name}
                  </div>
                </div>
              </div>
              <div className=" lg:text-xs text-[10px] px-5 2xl:px-8 font-normal leading-normal align-middle h-[40px]	text-SidenavFontColor">
                <div className="font-bold text-black">Response</div>
                <div className="text-[16px] font-medium text-primary leading-normal">
                  {response?.status &&
                    response.status.charAt(0).toUpperCase() +
                      response.status.slice(1)}
                </div>
              </div>
              <div className=" lg:text-xs text-[10px]  font-normal leading-normal align-middle h-[40px]	text-SidenavFontColor">
                <div className="font-bold text-black">Total Items</div>
                <div className="text-[16px] font-medium text-primary leading-normal">
                  {response?.total_items} items
                </div>
              </div>
              <div className=" lg:text-xs text-[10px]  font-normal leading-normal align-middle h-[40px]	text-SidenavFontColor">
                <div className="font-bold text-black">Responded On</div>
                <div className="text-[16px] font-medium text-primary leading-normal">
                  {response?.responded_on
                    ? moment(response?.responded_on).format('DD-MM-YYYY')
                    : 'Not Available'}
                </div>
              </div>
            </div>
          </div>
          <hr className="mb-[24px]" />
          {response?.status === 'reverted' && (
            <div className="py-[24px] border rounded-xl">
              <div className="px-[24px] h-10 border-b">
                {revertMessage?.messages}
              </div>
            </div>
          )}
          {successModalActive && (
            <ConfirmModal
              isOpen={true}
              body={
                <>
                  <div className="font-medium text-primary leading-8 text-xl">
                    Approved Successfully{' '}
                  </div>
                  {/* <div className="leading-normal">Order ID 234567</div> */}
                </>
              }
              topIcon="check-circle"
              onClose={handleCloseModal}
              // title={'Add New Pharmacist'}
            />
          )}
        </div>
      </div>
    </>
  )
}
export default DistrictAdminLoanResponseDetail
