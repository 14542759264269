import { lazy } from 'react'
import { Route, Routes } from 'react-router-dom'

import { router_config } from '../configs/route.config'
import ChildRoute from './components/childRoute'
import GuestRoute from './components/guestRoute'
import UserRoute from './components/userRoute'
import ContactCreate from '../pages/contacts/create'
import InstitutionDetail from '../pages/contacts/details'
import ManufacturerMain from '../pages/manufacturer'
import ManufacturerDetail from '../pages/manufacturer/details'
import ManufacturerCreate from '../pages/manufacturer/create'
import PharmacistMain from '../pages/Hospital_In_Charge/pharmacist'
import PharmacistDetail from '../pages/Hospital_In_Charge/pharmacist/details'
import StockMain from '../pages/Hospital_In_Charge/stock'
import PrimaryStockMain from '../pages/Hospital_In_Charge/stock/primary_stock'
import SecondaryStockMain from '../pages/Hospital_In_Charge/stock/secondary_stock'
import FundMappingMain from '../pages/Hospital_In_Charge/fund_mapping'
import MedicineStockMain from '../pages/Pharmacist/medicine'
import PrimaryMedicineStockMain from '../pages/Pharmacist/medicine/primary_stock'
import SecondaryMedicineStockMain from '../pages/Pharmacist/medicine/secondary_stock'
import ManufactureStockMain from '../pages/Manufacturer admin/stock'
import OrderRequestmain from '../pages/Manufacturer admin/order request'
import PendingOrdersMain from '../pages/Manufacturer admin/order request/pending orders'
import ConfirmedOrdersMain from '../pages/Manufacturer admin/order request/confirmed_orders'
import DeliveredOrdersMain from '../pages/Manufacturer admin/order request/delivered_order'
import RevertedOrdersMain from '../pages/Manufacturer admin/order request/reverted_order'
import RejectedOrdersMain from '../pages/Manufacturer admin/order request/rejected_order'
import OrderRequestDetail from '../pages/Manufacturer admin/order request/details'
import MyOrdersMain from '../pages/Hospital_In_Charge/my_orders'
import PendingOrdersMainHospital from '../pages/Hospital_In_Charge/my_orders/pending orders'
import ConfirmedOrdersMainHospital from '../pages/Hospital_In_Charge/my_orders/confirmed order'
import DeliveredOrdersMainHospital from '../pages/Hospital_In_Charge/my_orders/delivered_order'
import RevertedOrdersMainHospital from '../pages/Hospital_In_Charge/my_orders/reverted_order'
import OrderRequestDetailHospital from '../pages/Hospital_In_Charge/my_orders/details'
import OrderRequestDetailHospitalPending from '../pages/Hospital_In_Charge/my_orders/pending orders/detail/detailpending'
import OrderRequestDetailHospitalConfirmed from '../pages/Hospital_In_Charge/my_orders/confirmed order/detail/detailpending'
import OrderRequestDetailHospitalDelivered from '../pages/Hospital_In_Charge/my_orders/delivered_order/detail/detailpending'
import OrderRequestDetailHospitalReverted from '../pages/Hospital_In_Charge/my_orders/reverted_order/detail/detailpending'
import RejectedOrdersMainHospital from '../pages/Hospital_In_Charge/my_orders/rejected order'
import OrderRequestDetailHospitalRejected from '../pages/Hospital_In_Charge/my_orders/rejected order/detail/detailpending'
import ReportsPrimaryStock from '../pages/Hospital_In_Charge/reports/primary_stock'
import ReportListPrimary from '../pages/Hospital_In_Charge/reports/primary_stock/report_list'
import ReportsSecondaryStock from '../pages/Hospital_In_Charge/reports/secondary_stock'
import ReportListSecondary from '../pages/Hospital_In_Charge/reports/secondary_stock/report_list'
import ReportsFundUsage from '../pages/Hospital_In_Charge/reports/fund_usage'
import ReportListFund from '../pages/Hospital_In_Charge/reports/fund_usage/report_list'
import MedicineIssueMain from '../pages/Hospital_In_Charge/medicine_issue'
import MedicineSecondaryStock from '../pages/Hospital_In_Charge/medicine_issue/secondary_stock'
import MedicineRequest from '../pages/Hospital_In_Charge/medicine_issue/medicine_request_from_pharmacy'
import PurchaseProductMain from '../pages/Hospital_In_Charge/purchase_product'
import PruchaseMedicine from '../pages/Hospital_In_Charge/purchase_product/purchase_medicine'
import PatientsMain from '../pages/OP_In_Charge/patients'
import AllPatientsMain from '../pages/OP_In_Charge/patients/all_patients'
import AllPatientsAddNew from '../pages/OP_In_Charge/patients/all_patients/create'
import WaitingPatientsMain from '../pages/OP_In_Charge/patients/waiting_patients'
import ExaminedPatientsMain from '../pages/OP_In_Charge/patients/examined_patients'
import DistrictAdminMain from '../pages/District_admin'
import DistrictAdminCreate from '../pages/District_admin/create'
import DistrictAdminDetail from '../pages/District_admin/details'

import DoctorMain from '../pages/Hospital_In_Charge/doctor'
import DoctorDetail from '../pages/Hospital_In_Charge/doctor/details'
import OPInchargeMain from '../pages/Hospital_In_Charge/OP_Incharge'
import OPInchargeDetail from '../pages/Hospital_In_Charge/OP_Incharge/details'
import MedicineLoan from '../pages/Hospital_In_Charge/purchase_product/medcial_loan'
import MedicineLoanRequestMain from '../pages/Hospital_In_Charge/medicine_loan_request'
import MedicineLoanRequestNew from '../pages/Hospital_In_Charge/medicine_loan_request/new'
import MedicineLoanRequestAll from '../pages/Hospital_In_Charge/medicine_loan_request/all'
import MedicineLoanRequestAccepted from '../pages/Hospital_In_Charge/medicine_loan_request/accepted'
import MedicineLoanRequestReverted from '../pages/Hospital_In_Charge/medicine_loan_request/reverted'
import MedicineLoanRequestDetail from '../pages/Hospital_In_Charge/medicine_loan_request/details'
import ReportExpiringMedicines from '../pages/Hospital_In_Charge/reports/medicine_expiring'
import MedicineIntentReport from '../pages/Hospital_In_Charge/reports/medicine_indent_report'
import MedicineIntentReportList from '../pages/Hospital_In_Charge/reports/medicine_indent_report/report_list'
import MedicineIntentReportListDetail from '../pages/Hospital_In_Charge/reports/medicine_indent_report/report_detail'
import MedicineLoanReport from '../pages/Hospital_In_Charge/reports/medicine_loan_report'
import MedicineLoanReportListDetail from '../pages/Hospital_In_Charge/reports/medicine_loan_report/report_detail'
import MedicineLoanReportList from '../pages/Hospital_In_Charge/reports/medicine_loan_report/report_list'
import ReportsPrimaryStockAdmin from '../pages/Super_admin/reports/primary_stock'
import ReportsSecondaryStockAdmin from '../pages/Super_admin/reports/secondary_stock'
import ReportsFundUsageAdmin from '../pages/Super_admin/reports/fund_usage'
import MedicineIntentReportAdmin from '../pages/Super_admin/reports/medicine_indent_report'
import MedicineIntentReportListAdmin from '../pages/Super_admin/reports/medicine_indent_report/report_list'
import MedicineIntentReportListDetailAdmin from '../pages/Super_admin/reports/medicine_indent_report/report_detail'
import MedicineLoanReportAdmin from '../pages/Super_admin/reports/medicine_loan_report'
import MedicineLoanReportListAdmin from '../pages/Super_admin/reports/medicine_loan_report/report_list'
import ReportListPrimaryAdmin from '../pages/Super_admin/reports/primary_stock/report_list'
import ReportExpiringMedicinesListAdmin from '../pages/Super_admin/reports/medicine_expiring/report_list'
import ReportExpiringMedicinesAdmin from '../pages/Super_admin/reports/medicine_expiring'
import FundMappingMainAdmin from '../pages/Fund_mapping_admin'
import PharmacyPatientMain from '../pages/Pharmacist/patients'
import PharmacyPatientMainAll from '../pages/Pharmacist/patients/all'
import PharmacyPatientMaiDispensed from '../pages/Pharmacist/patients/all/dispensed'
import PharmacyPatientMainWaiting from '../pages/Pharmacist/patients/all/waiting'
import PharmacyPatientMainDetail from '../pages/Pharmacist/patients/all/details'
import FundMappingMainAdminList from '../pages/Fund_mapping_admin/previous_distribution'
import DoctorPatientMain from '../pages/Doctor/patients'
import DoctorPatientMainAll from '../pages/Doctor/patients/all'
import DoctorPatientMaiDispensed from '../pages/Doctor/patients/all/dispensed'
import DoctorPatientMainWaiting from '../pages/Doctor/patients/all/waiting'
import DoctorPatientListMain from '../pages/Doctor/patients/all/patient_list'
import PatientGeneralInfo from '../pages/Doctor/patients/all/general_info'
import PatientPreviousDiagnosisDetail from '../pages/Doctor/patients/all/previous_diagnosis/detail'
import DistrictAdminLoanMain from '../pages/District_admin_pages/request'
import DistrictAdminLoanRequestAll from '../pages/District_admin_pages/request/all'
import PatientPreviousDiagnosis from '../pages/Doctor/patients/all/previous_diagnosis'
import DistrictAdminLoanRequestNew from '../pages/District_admin_pages/request/new'
import DistrictAdminLoanRequestApproved from '../pages/District_admin_pages/request/approved'
import DistrictAdminLoanRequestForward from '../pages/District_admin_pages/request/forward'
import DistrictAdminLoanRequestAccepted from '../pages/District_admin_pages/request/accepted'
import DistrictAdminLoanRequestDetail from '../pages/District_admin_pages/request/detail'
import DistrictAdminLoanRequestHospitals from '../pages/District_admin_pages/request/detail/hospitals'
import DistrictAdminLoanResponseAll from '../pages/District_admin_pages/response/all'
import DistrictAdminLoanRespondMain from '../pages/District_admin_pages/response'
import DistrictAdminLoanResponseDetail from '../pages/District_admin_pages/response/detail'
import DistrictAdminLoanResponseAccepted from '../pages/District_admin_pages/response/accepted'
import DistrictAdminLoanResponseReverted from '../pages/District_admin_pages/response/reverted'
import MyOrdersPage from '../pages/Hospital_In_Charge/my_orders_main'
import MyOrdersMainDms from '../pages/Hospital_In_Charge/medicine_from_dms'
import PendingOrdersMainHospitalDms from '../pages/Hospital_In_Charge/medicine_from_dms/pending orders'
import ConfirmedOrdersMainHospitalDms from '../pages/Hospital_In_Charge/medicine_from_dms/confirmed order'
import DeliveredOrdersMainHospitalDms from '../pages/Hospital_In_Charge/medicine_from_dms/delivered_order'
import RevertedOrdersMainHospitalDms from '../pages/Hospital_In_Charge/medicine_from_dms/reverted_order'
import RejectedOrdersMainHospitalDms from '../pages/Hospital_In_Charge/medicine_from_dms/rejected order'
import OrderRequestDetailHospitalPendingDms from '../pages/Hospital_In_Charge/medicine_from_dms/pending orders/detail/detailpending'
import OrderRequestDetailHospitalConfirmedDms from '../pages/Hospital_In_Charge/medicine_from_dms/confirmed order/detail/detailpending'
import OrderRequestDetailHospitalDeliveredDms from '../pages/Hospital_In_Charge/medicine_from_dms/delivered_order/detail/detailpending'
import OrderRequestDetailHospitalRejectedDms from '../pages/Hospital_In_Charge/medicine_from_dms/rejected order/detail/detailpending'
import OrderRequestDetailHospitalDms from '../pages/Hospital_In_Charge/medicine_from_dms/details'
import OrderRequestDetailHospitalRevertedDms from '../pages/Hospital_In_Charge/medicine_from_dms/reverted_order/detail/detailpending'
import MyOrdersMainLoan from '../pages/Hospital_In_Charge/my_orders_medicine_loan'
import PendingOrdersMainHospitalLoan from '../pages/Hospital_In_Charge/my_orders_medicine_loan/pending orders'
import ConfirmedOrdersMainHospitalLoan from '../pages/Hospital_In_Charge/my_orders_medicine_loan/confirmed order'
import DeliveredOrdersMainHospitalLoan from '../pages/Hospital_In_Charge/my_orders_medicine_loan/delivered_order'
import RejectedOrdersMainHospitalLoan from '../pages/Hospital_In_Charge/my_orders_medicine_loan/rejected order'
import OrderRequestDetailHospitalPendingLoan from '../pages/Hospital_In_Charge/my_orders_medicine_loan/pending orders/detail/detailpending'
import OrderRequestDetailHospitalConfirmedLoan from '../pages/Hospital_In_Charge/my_orders_medicine_loan/confirmed order/detail/detailpending'
import OrderRequestDetailHospitalDeliveredLoan from '../pages/Hospital_In_Charge/my_orders_medicine_loan/delivered_order/detail/detailpending'
import OrderRequestDetailHospitalRejectedLoan from '../pages/Hospital_In_Charge/my_orders_medicine_loan/rejected order/detail/detailpending'
import OrderRequestDetailHospitalLoan from '../pages/Hospital_In_Charge/my_orders_medicine_loan/details'
import ReplacedOrdersMainHospital from '../pages/Hospital_In_Charge/my_orders/replaced_order'
import OrderRequestDetailHospitalReplaced from '../pages/Hospital_In_Charge/my_orders/replaced_order/detail/detailpending'
import ReplacedOrdersMainHospitalDms from '../pages/Hospital_In_Charge/medicine_from_dms/replaced_order'
import OrderRequestDetailHospitalReplacedDms from '../pages/Hospital_In_Charge/medicine_from_dms/replaced_order/detail/detailpending'
import ManufacturerMainDMS from '../pages/manufacturer/dms'
import ReportListSecondaryAdmin from '../pages/Super_admin/reports/secondary_stock/report_list'
import ReportListFundAdmin from '../pages/Super_admin/reports/fund_usage/report_list'
import ReplacedOrdersMain from '../pages/Manufacturer admin/order request/replaced_orders'
import MedicineLoanRequestApproved from '../pages/Hospital_In_Charge/medicine_loan_request/approved'
import TableMain from '../pages/Doctor/patients/all/add_prescription/table'
import PharmacyPatientMainAddTable from '../pages/Pharmacist/patients/all/add_prescription'
import DeliveredOrdersMainHospitalLoanDelivered from '../pages/Hospital_In_Charge/my_orders_medicine_loan/delivered_order copy'
import OrderRequestDetailHospitalDeliveredLoanDelivered from '../pages/Hospital_In_Charge/my_orders_medicine_loan/delivered_order copy/detail/detailpending'
import OrderRequestDetailReplaced from '../pages/Manufacturer admin/order request/details replaced'
// import PatientGeneralInfo from '../pages/Doctor/patients/all/general_info'

// import ContactDetails from '../pages/contacts/details'

// Login

const Login = lazy(() => import('../pages/userManagement/login'))

// Dashboard

const Dashboard = lazy(() => import('../pages/dashboard/dashboard'))

// Leads

// Contacts

const Contacts = lazy(() => import('../pages/contacts'))
// const Contacts = lazy(() => import('../pages/contacts'))
// const ContactDetails = lazy(() => import('../pages/contacts/details'))

const routes: any = [
  { slug: 'DASHBOARD', component: <Dashboard />, isPrimary: true },

  // {
  //   slug: 'CONTACTS',
  //   component: <Contacts />,
  //   isPrimary: true,
  // },
  {
    slug: 'INSTITUTIONS_CREATE',
    component: <ContactCreate />,
    isPrimary: true,
  },
  {
    slug: 'INSTITUTIONS_VIEW',
    component: <InstitutionDetail />,
    isPrimary: true,
  },
  {
    slug: 'INSTITUTIONS',
    component: <Contacts />,
    isPrimary: true,
    // children : [
    //   {
    //     slug : 'CONTACT_DETAILS',
    //     component : <ContactDetails />
    //   }
    // ]
  },
  {
    slug: 'MANUFACTURER',
    component: <ManufacturerMain />,
    isPrimary: true,
    // children : [
    //   {
    //     slug : 'CONTACT_DETAILS',
    //     component : <ContactDetails />
    //   }
    // ]
  },
  {
    slug: 'MANUFACTURER_CREATE',
    component: <ManufacturerCreate />,
    isPrimary: true,
  },
  {
    slug: 'MANUFACTURER_VIEW',
    component: <ManufacturerDetail />,
    isPrimary: true,
  },
  {
    slug: 'MANUFACTURER_ORDER_REQUEST',
    component: <OrderRequestmain />,
    isPrimary: true,
    children: [
      {
        slug: 'PENDING_ORDER',
        component: <PendingOrdersMain />,
        // isPrimary: true,
      },
      {
        slug: 'CONFIRMED_ORDER',
        component: <ConfirmedOrdersMain />,
        // isPrimary: true,
      },
      {
        slug: 'DELIVERED_ORDER',
        component: <DeliveredOrdersMain />,
        // isPrimary: true,
      },
      {
        slug: 'REVERTED_ORDER',
        component: <RevertedOrdersMain />,
        // isPrimary: true,
      },
      {
        slug: 'REJECTED_ORDER',
        component: <RejectedOrdersMain />,
        // isPrimary: true,
      },
      {
        slug: 'REPLACED_ORDER',
        component: <ReplacedOrdersMain />,
        // isPrimary: true,
      },
    ],
  },
  {
    slug: 'HOSPITAL_ORDERS_MAIN',
    component: <MyOrdersPage />,
    isPrimary: true,
  },
  {
    slug: 'HOSPITAL_ORDERS',
    component: <MyOrdersMain />,
    isPrimary: true,
    children: [
      {
        slug: 'HOSPITAL_PENDING_ORDER',
        component: <PendingOrdersMainHospital />,
        // isPrimary: true,
      },
      {
        slug: 'HOSPITAL_CONFIRMED_ORDER',
        component: <ConfirmedOrdersMainHospital />,
        // isPrimary: true,
      },
      {
        slug: 'HOSPITAL_REPLACED_ORDER',
        component: <ReplacedOrdersMainHospital />,
        // isPrimary: true,
      },
      {
        slug: 'HOSPITAL_DELIVERED_ORDER',
        component: <DeliveredOrdersMainHospital />,
        // isPrimary: true,
      },
      {
        slug: 'HOSPITAL_REVERTED_ORDER',
        component: <RevertedOrdersMainHospital />,
        // isPrimary: true,
      },
      {
        slug: 'HOSPITAL_REJECTED_ORDER',
        component: <RejectedOrdersMainHospital />,
        // isPrimary: true,
      },
    ],
  },
  {
    slug: 'HOSPITAL_ORDERS_DMO',
    component: <MyOrdersMainDms />,
    isPrimary: true,
    children: [
      {
        slug: 'HOSPITAL_PENDING_ORDER_DMO',
        component: <PendingOrdersMainHospitalDms />,
        // isPrimary: true,
      },
      {
        slug: 'HOSPITAL_CONFIRMED_ORDER_DMO',
        component: <ConfirmedOrdersMainHospitalDms />,
        // isPrimary: true,
      },
      {
        slug: 'HOSPITAL_REPLACED_ORDER_DMO',
        component: <ReplacedOrdersMainHospitalDms />,
        // isPrimary: true,
      },
      {
        slug: 'HOSPITAL_DELIVERED_ORDER_DMO',
        component: <DeliveredOrdersMainHospitalDms />,
        // isPrimary: true,
      },
      {
        slug: 'HOSPITAL_REVERTED_ORDER_DMO',
        component: <RevertedOrdersMainHospitalDms />,
        // isPrimary: true,
      },
      {
        slug: 'HOSPITAL_REJECTED_ORDER_DMO',
        component: <RejectedOrdersMainHospitalDms />,
        // isPrimary: true,
      },
    ],
  },
  {
    slug: 'HOSPITAL_ORDERS_LOAN',
    component: <MyOrdersMainLoan />,
    isPrimary: true,
    children: [
      {
        slug: 'HOSPITAL_PENDING_ORDER_LOAN',
        component: <PendingOrdersMainHospitalLoan />,
        // isPrimary: true,
      },
      {
        slug: 'HOSPITAL_CONFIRMED_ORDER_LOAN',
        component: <ConfirmedOrdersMainHospitalLoan />,
        // isPrimary: true,
      },
      {
        slug: 'HOSPITAL_DELIVERED_ORDER_LOAN',
        component: <DeliveredOrdersMainHospitalLoan />,
        // isPrimary: true,
      },
      {
        slug: 'HOSPITAL_REVERTED_ORDER_LOAN',
        component: <DeliveredOrdersMainHospitalLoanDelivered />,
        // isPrimary: true,
      },
      {
        slug: 'HOSPITAL_REJECTED_ORDER_LOAN',
        component: <RejectedOrdersMainHospitalLoan />,
        // isPrimary: true,
      },
    ],
  },
  {
    slug: 'MANUFACTURER_STOCK',
    component: <ManufactureStockMain />,
    isPrimary: true,
  },
  {
    slug: 'MANUFACTURER_ORDER_REQUEST_DETAIL',
    component: <OrderRequestDetail />,
    isPrimary: true,
  },
  {
    slug: 'MANUFACTURER_ORDER_REQUEST_DETAIL_REPLACED',
    component: <OrderRequestDetailReplaced />,
    isPrimary: true,
  },
  {
    slug: 'HOSPITAL_PENDING_ORDER_DETAIL',
    component: <OrderRequestDetailHospitalPending />,
    isPrimary: true,
  },
  {
    slug: 'HOSPITAL_REPLACED_ORDER_DETAIL',
    component: <OrderRequestDetailHospitalReplaced />,
    isPrimary: true,
  },
  {
    slug: 'HOSPITAL_CONFIRMED_ORDER_DETAIL',
    component: <OrderRequestDetailHospitalConfirmed />,
    isPrimary: true,
  },
  {
    slug: 'HOSPITAL_DELIVERED_ORDER_DETAIL',
    component: <OrderRequestDetailHospitalDelivered />,
    isPrimary: true,
  },
  {
    slug: 'HOSPITAL_REVERTED_ORDER_DETAIL',
    component: <OrderRequestDetailHospitalReverted />,
    isPrimary: true,
  },
  {
    slug: 'HOSPITAL_REJECTED_ORDER_DETAIL',
    component: <OrderRequestDetailHospitalRejected />,
    isPrimary: true,
  },
  {
    slug: 'HOSPITAL_ORDER_REQUEST_DETAIL',
    component: <OrderRequestDetailHospital />,
    isPrimary: true,
  },
  {
    slug: 'HOSPITAL_PENDING_ORDER_DETAIL_DMO',
    component: <OrderRequestDetailHospitalPendingDms />,
    isPrimary: true,
  },
  {
    slug: 'HOSPITAL_CONFIRMED_ORDER_DETAIL_DMO',
    component: <OrderRequestDetailHospitalConfirmedDms />,
    isPrimary: true,
  },
  {
    slug: 'HOSPITAL_REPLACED_ORDER_DETAIL_DMO',
    component: <OrderRequestDetailHospitalReplacedDms />,
    isPrimary: true,
  },
  {
    slug: 'HOSPITAL_DELIVERED_ORDER_DETAIL_DMO',
    component: <OrderRequestDetailHospitalDeliveredDms />,
    isPrimary: true,
  },
  {
    slug: 'HOSPITAL_REVERTED_ORDER_DETAIL_DMO',
    component: <OrderRequestDetailHospitalRevertedDms />,
    isPrimary: true,
  },
  {
    slug: 'HOSPITAL_REJECTED_ORDER_DETAIL_DMO',
    component: <OrderRequestDetailHospitalRejectedDms />,
    isPrimary: true,
  },
  {
    slug: 'HOSPITAL_ORDER_REQUEST_DETAIL_DMO',
    component: <OrderRequestDetailHospitalDms />,
    isPrimary: true,
  },
  {
    slug: 'HOSPITAL_PENDING_ORDER_DETAIL_LOAN',
    component: <OrderRequestDetailHospitalPendingLoan />,
    isPrimary: true,
  },
  {
    slug: 'HOSPITAL_CONFIRMED_ORDER_DETAIL_LOAN',
    component: <OrderRequestDetailHospitalConfirmedLoan />,
    isPrimary: true,
  },
  {
    slug: 'HOSPITAL_DELIVERED_ORDER_DETAIL_LOAN',
    component: <OrderRequestDetailHospitalDeliveredLoan />,
    isPrimary: true,
  },
  {
    slug: 'HOSPITAL_REVERTED_ORDER_DETAIL_LOAN',
    component: <OrderRequestDetailHospitalDeliveredLoanDelivered />,
    isPrimary: true,
  },
  {
    slug: 'HOSPITAL_REJECTED_ORDER_DETAIL_LOAN',
    component: <OrderRequestDetailHospitalRejectedLoan />,
    isPrimary: true,
  },
  {
    slug: 'HOSPITAL_ORDER_REQUEST_DETAIL_LOAN',
    component: <OrderRequestDetailHospitalLoan />,
    isPrimary: true,
  },
  {
    slug: 'PHARMACIST',
    component: <PharmacistMain />,
    isPrimary: true,
  },
  {
    slug: 'PHARMACIST_VIEW',
    component: <PharmacistDetail />,
    isPrimary: true,
  },
  {
    slug: 'DOCTOR',
    component: <DoctorMain />,
    isPrimary: true,
  },
  {
    slug: 'DOCTOR_VIEW',
    component: <DoctorDetail />,
    isPrimary: true,
  },
  {
    slug: 'OP_INCHARGE',
    component: <OPInchargeMain />,
    isPrimary: true,
  },
  {
    slug: 'OP_INCHARGE_VIEW',
    component: <OPInchargeDetail />,
    isPrimary: true,
  },
  {
    isAuthRoute: true,
    slug: 'LOGIN',
    component: <Login />,
  },
  {
    slug: 'STOCK',
    component: <StockMain />,
    isPrimary: true,
    children: [
      {
        slug: 'STOCK_PRIMARY',
        component: <PrimaryStockMain />,
        // isPrimary: true,
      },
      {
        slug: 'STOCK_SECONDARY',
        component: <SecondaryStockMain />,
        // isPrimary: true,
      },
    ],
  },
  {
    slug: 'FUND_MAPPING',
    component: <FundMappingMain />,
    isPrimary: true,
  },
  {
    slug: 'ADMIN_FUND_MAPPING',
    component: <FundMappingMainAdmin />,
    isPrimary: true,
  },
  {
    slug: 'ADMIN_FUND_MAPPING_PREVIOUS',
    component: <FundMappingMainAdminList />,
    isPrimary: true,
  },
  {
    slug: 'MEDICINE',
    component: <MedicineStockMain />,
    isPrimary: true,
    children: [
      {
        slug: 'MEDICINE_PRIMARY',
        component: <PrimaryMedicineStockMain />,
        // isPrimary: true,
      },
      {
        slug: 'MEDICINE_SECONDARY',
        component: <SecondaryMedicineStockMain />,
        // isPrimary: true,
      },
    ],
  },
  {
    slug: 'REPORTS',
    component: <ReportsPrimaryStock />,
    isPrimary: true,
  },
  {
    slug: 'REPORTS_PRIMARY',
    component: <ReportsPrimaryStock />,
    isPrimary: true,
  },
  {
    slug: 'REPORTS_PRIMARY_BREADCRUMB',
    component: <ReportsPrimaryStock />,
    isPrimary: true,
  },
  {
    slug: 'REPORTS_SECONDARY',
    component: <ReportsSecondaryStock />,
    isPrimary: true,
  },
  {
    slug: 'REPORTS_SECONDARY_BREADCRUMB',
    component: <ReportsSecondaryStock />,
    isPrimary: true,
  },
  {
    slug: 'REPORTS_FUND',
    component: <ReportsFundUsage />,
    isPrimary: true,
  },
  {
    slug: 'REPORTS_MEDICINE_INTENT',
    component: <MedicineIntentReport />,
    isPrimary: true,
  },
  {
    slug: 'REPORTS_MEDICINE_INTENT_LIST',
    component: <MedicineIntentReportList />,
    isPrimary: true,
  },
  {
    slug: 'REPORTS_MEDICINE_INTENT_DETAIL',
    component: <MedicineIntentReportListDetail />,
    isPrimary: true,
  },
  {
    slug: 'REPORTS_MEDICINE_LOAN',
    component: <MedicineLoanReport />,
    isPrimary: true,
  },
  {
    slug: 'REPORTS_MEDICINE_LOAN_LIST',
    component: <MedicineLoanReportList />,
    isPrimary: true,
  },
  {
    slug: 'REPORTS_MEDICINE_LOAN_DETAIL',
    component: <MedicineLoanReportListDetail />,
    isPrimary: true,
  },
  {
    slug: 'REPORTS_MEDICINE_EXPIRE',
    component: <ReportExpiringMedicines />,
    isPrimary: true,
  },
  {
    slug: 'REPORTS_FUND_BREADCRUMB',
    component: <ReportsFundUsage />,
    isPrimary: true,
  },
  {
    slug: 'REPORTS_PRIMARY_LIST',
    component: <ReportListPrimary />,
    isPrimary: true,
  },
  {
    slug: 'ADMIN_REPORTS',
    component: <ReportsPrimaryStockAdmin />,
    isPrimary: true,
  },
  {
    slug: 'ADMIN_REPORTS_PRIMARY',
    component: <ReportsPrimaryStockAdmin />,
    isPrimary: true,
  },
  {
    slug: 'ADMIN_REPORTS_SECONDARY',
    component: <ReportsSecondaryStockAdmin />,
    isPrimary: true,
  },
  {
    slug: 'ADMIN_REPORTS_FUND',
    component: <ReportsFundUsageAdmin />,
    isPrimary: true,
  },
  {
    slug: 'ADMIN_REPORTS_MEDICINE_INTENT',
    component: <MedicineIntentReportAdmin />,
    isPrimary: true,
  },
  {
    slug: 'ADMIN_REPORTS_MEDICINE_INTENT_LIST',
    component: <MedicineIntentReportListAdmin />,
    isPrimary: true,
  },
  {
    slug: 'ADMIN_REPORTS_MEDICINE_INTENT_DETAIL',
    component: <MedicineIntentReportListDetailAdmin />,
    isPrimary: true,
  },
  {
    slug: 'ADMIN_REPORTS_MEDICINE_LOAN',
    component: <MedicineLoanReportAdmin />,
    isPrimary: true,
  },
  {
    slug: 'ADMIN_REPORTS_MEDICINE_LOAN_LIST',
    component: <MedicineLoanReportListAdmin />,
    isPrimary: true,
  },
  {
    slug: 'ADMIN_REPORTS_MEDICINE_LOAN_DETAIL',
    component: <MedicineLoanReportListDetail />,
    isPrimary: true,
  },
  {
    slug: 'ADMIN_REPORTS_MEDICINE_EXPIRE',
    component: <ReportExpiringMedicinesAdmin />,
    isPrimary: true,
  },
  {
    slug: 'ADMIN_REPORTS_MEDICINE_EXPIRE_LIST',
    component: <ReportExpiringMedicinesListAdmin />,
    isPrimary: true,
  },
  {
    slug: 'ADMIN_REPORTS_PRIMARY_LIST',
    component: <ReportListPrimaryAdmin />,
    isPrimary: true,
  },
  {
    slug: 'ADMIN_REPORTS_SECONDARY_LIST',
    component: <ReportListSecondaryAdmin />,
    isPrimary: true,
  },
  {
    slug: 'HOSPITAL_MEDICINE_ISSUE',
    component: <MedicineIssueMain />,
    isPrimary: true,
    children: [
      {
        slug: 'HOSPITAL_MEDICINE_ISSUE_SECONDARY',
        component: <MedicineSecondaryStock />,
        // isPrimary: true,
      },
      {
        slug: 'HOSPITAL_MEDICINE_REQUEST',
        component: <MedicineRequest />,
        // isPrimary: true,
      },
    ],
  },
  {
    slug: 'REPORTS_SECONDARY_LIST',
    component: <ReportListSecondary />,
    isPrimary: true,
  },
  {
    slug: 'REPORTS_FUND_LIST',
    component: <ReportListFund />,
    isPrimary: true,
  },
  {
    slug: 'ADMIN_REPORTS_FUND_LIST',
    component: <ReportListFundAdmin />,
    isPrimary: true,
  },
  {
    slug: 'HOSPITAL_PURCHASE_PRODUCT',
    component: <PurchaseProductMain />,
    isPrimary: true,
    children: [
      {
        slug: 'HOSPITAL_PURCHASE_MEDICINE',
        component: <PruchaseMedicine />,
        // isPrimary: true,
      },
      {
        slug: 'HOSPITAL_MEDICINE_LOAN',
        component: <MedicineLoan />,
        // isPrimary: true,
      },
    ],
  },
  {
    slug: 'OP_PATIENTS',
    component: <PatientsMain />,
    isPrimary: true,
    children: [
      {
        slug: 'OP_PATIENTS_ALL',
        component: <AllPatientsMain />,
        // isPrimary: true,
      },
      {
        slug: 'OP_PATIENTS_WAITING',
        component: <WaitingPatientsMain />,
        // isPrimary: true,
      },
      {
        slug: 'OP_PATIENTS_EXAMINED',
        component: <ExaminedPatientsMain />,
        // isPrimary: true,
      },
    ],
  },
  {
    slug: 'OP_PATIENTS_ALL_ADD_NEW',
    component: <AllPatientsAddNew />,
    isPrimary: true,
  },
  {
    slug: 'DISTRICT_ADMIN',
    component: <DistrictAdminMain />,
    isPrimary: true,
  },
  {
    slug: 'DISTRICT_ADMIN_CREATE',
    component: <DistrictAdminCreate />,
    isPrimary: true,
  },
  {
    slug: 'DISTRICT_ADMIN_VIEW',
    component: <DistrictAdminDetail />,
    isPrimary: true,
  },
  {
    slug: 'DISTRICT_MEDICAL',
    component: <ManufacturerMainDMS />,
    // component: <DistrictMedicalMain />,
    isPrimary: true,
  },
  {
    slug: 'DISTRICT_MEDICAL_CREATE',
    component: <ManufacturerCreate />,
    // component: <DistrictMedicalCreate />,
    isPrimary: true,
  },
  {
    slug: 'DISTRICT_MEDICAL_VIEW',
    component: <ManufacturerDetail />,
    // component: <DistrictMedicalDetail />,
    isPrimary: true,
  },
  {
    slug: 'PHARMACY_PATIENTS',
    component: <PharmacyPatientMain />,
    isPrimary: true,
    children: [
      {
        slug: 'PHARMACY_PATIENTS_ALL',
        component: <PharmacyPatientMainAll />,
        // isPrimary: true,
      },
      {
        slug: 'PHARMACY_PATIENTS_DISPENSED',
        component: <PharmacyPatientMaiDispensed />,
        // isPrimary: true,
      },
      {
        slug: 'PHARMACY_PATIENTS_WAITING',
        component: <PharmacyPatientMainWaiting />,
        // isPrimary: true,
      },
    ],
  },
  {
    slug: 'PHARMACY_PATIENTS_DETAIL',
    component: <PharmacyPatientMainDetail />,
    isPrimary: true,
  },
  {
    slug: 'PHARMACY_PATIENTS_ADD',
    component: <PharmacyPatientMainAddTable />,
    isPrimary: true,
  },
  {
    slug: 'PHARMACY_PATIENTS_ADD_MAIN',
    component: <PharmacyPatientMainAddTable />,
    isPrimary: true,
  },

  {
    slug: 'HOSPITAL_LOAN_REQUEST',
    component: <MedicineLoanRequestMain />,
    isPrimary: true,
    children: [
      {
        slug: 'HOSPITAL_LOAN_REQUEST_ALL',
        component: <MedicineLoanRequestAll />,
        // isPrimary: true,
      },
      {
        slug: 'HOSPITAL_LOAN_REQUEST_NEW',
        component: <MedicineLoanRequestNew />,
        // isPrimary: true,
      },
      {
        slug: 'HOSPITAL_LOAN_REQUEST_ACCEPTED',
        component: <MedicineLoanRequestAccepted />,
        // isPrimary: true,
      },
      {
        slug: 'HOSPITAL_LOAN_REQUEST_APPROVED',
        component: <MedicineLoanRequestApproved />,
        // isPrimary: true,
      },
      {
        slug: 'HOSPITAL_LOAN_REQUEST_REVERTED',
        component: <MedicineLoanRequestReverted />,
        // isPrimary: true,
      },
      {
        slug: 'HOSPITAL_LOAN_REQUEST_FORWARDED',
        component: <MedicineLoanRequestReverted />,
        // isPrimary: true,
      },
    ],
  },
  {
    slug: 'HOSPITAL_LOAN_REQUEST_DETAIL',
    component: <MedicineLoanRequestDetail />,
    isPrimary: true,
  },
  {
    slug: 'DOCTOR_PATIENTS',
    component: <DoctorPatientMain />,
    isPrimary: true,
    children: [
      {
        slug: 'DOCTOR_PATIENTS_ALL',
        component: <DoctorPatientMainAll />,
        // isPrimary: true,
      },
      {
        slug: 'DOCTOR_PATIENTS_DISPENSED',
        component: <DoctorPatientMaiDispensed />,
        // isPrimary: true,
      },
      {
        slug: 'DOCTOR_PATIENTS_WAITING',
        component: <DoctorPatientMainWaiting />,
        // isPrimary: true,
      },
    ],
  },
  {
    slug: 'DOCTOR_PATIENTS_DETAIL_LIST',
    component: <DoctorPatientListMain />,
    isPrimary: true,
    children: [
      {
        slug: 'DOCTOR_PATIENTS_GENERAL',
        component: <PatientGeneralInfo />,
        // isPrimary: true,
      },
      {
        slug: 'DOCTOR_PATIENTS_PREVIOUS',
        component: <PatientPreviousDiagnosis />,
        // isPrimary: true,
      },
      {
        slug: 'DOCTOR_PATIENTS_PRESCRIPTION',
        component: <TableMain />,
        isPrimary: true,
        children: [
          // {
          //   slug: 'DOCTOR_PATIENTS_PRESCRIPTION_CURRENT',
          //   component: <PatientPreviousDiagnosisDetail />,
          //   // isPrimary: true,
          // },
        ],
      },
      // {
      //   slug: 'DOCTOR_PATIENTS_PRESCRIPTION_CURRENT',
      //   component: <DoctorPatientPrescriptionCurrent />,
      //   // isPrimary: true,
      // },
    ],
  },

  {
    slug: 'DOCTOR_PATIENTS_ADD',
    component: <DoctorPatientMainWaiting />,
    isPrimary: true,
  },
  {
    slug: 'DOCTOR_PATIENTS_PREVIOUS_DETAIL',
    component: <PatientPreviousDiagnosisDetail />,
    isPrimary: true,
  },
  // {
  //   slug: 'DOCTOR_PATIENTS_PRESCRIPTION_CURRENT',
  //   component: <DoctorPatientPrescriptionCurrent />,
  //   isPrimary: true,
  // },
  {
    slug: 'DISTRICT_ADMIN_LOAN',
    component: <DistrictAdminLoanMain />,
    isPrimary: true,
  },
  {
    slug: 'DISTRICT_ADMIN_LOAN_REQUEST',
    component: <DistrictAdminLoanMain />,
    isPrimary: true,
    children: [
      {
        slug: 'DISTRICT_ADMIN_LOAN_REQUEST_ALL',
        component: <DistrictAdminLoanRequestAll />,
        // isPrimary: true,
      },
      {
        slug: 'DISTRICT_ADMIN_LOAN_REQUEST_NEW',
        component: <DistrictAdminLoanRequestNew />,
        // isPrimary: true,
      },
      {
        slug: 'DISTRICT_ADMIN_LOAN_REQUEST_APPROVED',
        component: <DistrictAdminLoanRequestApproved />,
        // isPrimary: true,
      },
      {
        slug: 'DISTRICT_ADMIN_LOAN_REQUEST_FORWARD',
        component: <DistrictAdminLoanRequestForward />,
        // isPrimary: true,
      },
      {
        slug: 'DISTRICT_ADMIN_LOAN_REQUEST_ACCEPTED',
        component: <DistrictAdminLoanRequestAccepted />,
        // isPrimary: true,
      },
    ],
  },
  {
    slug: 'DISTRICT_ADMIN_LOAN_RESPOND',
    component: <DistrictAdminLoanRespondMain />,
    isPrimary: true,
    children: [
      {
        slug: 'DISTRICT_ADMIN_LOAN_RESPONSE_ALL',
        component: <DistrictAdminLoanResponseAll />,
        // isPrimary: true,
      },
      {
        slug: 'DISTRICT_ADMIN_LOAN_RESPONSE_ACCEPTED',
        component: <DistrictAdminLoanResponseAccepted />,
        // isPrimary: true,
      },
      {
        slug: 'DISTRICT_ADMIN_LOAN_RESPONSE_REVERTED',
        component: <DistrictAdminLoanResponseReverted />,
        // isPrimary: true,
      },
    ],
  },
  {
    slug: 'DISTRICT_ADMIN_LOAN_REQUEST_DETAIL',
    component: <DistrictAdminLoanRequestDetail />,
    isPrimary: true,
  },
  {
    slug: 'DISTRICT_ADMIN_LOAN_REQUEST_DETAIL_HOSPITAL',
    component: <DistrictAdminLoanRequestHospitals />,
    isPrimary: true,
  },
  {
    slug: 'DISTRICT_ADMIN_LOAN_RESPONSE_DETAIL',
    component: <DistrictAdminLoanResponseDetail />,
    isPrimary: true,
  },
]
export default function MainRoutes() {
  return (
    <>
      <Routes>
        {routes.map((route: any) => (
          <>
            {route.isAuthRoute ? (
              <Route
                element={<GuestRoute>{route.component}</GuestRoute>}
                path={router_config[route.slug].path}
                key={router_config[route.slug].path}
              />
            ) : (
              <Route
                element={
                  <UserRoute
                    slug_key={route.slug}
                    hasChild={
                      route?.isPrimary
                        ? false
                        : Boolean(route?.children?.length)
                    }
                  >
                    {route.component}
                  </UserRoute>
                }
                path={router_config[route.slug].path}
                key={router_config[route.slug].path}
              >
                {route.children?.length && (
                  <>
                    {route.children.map((child: any) => (
                      <Route
                        element={
                          <ChildRoute slug_key={child.slug}>
                            {child.component}
                            {child.children &&
                              child.children.map((sub: any) => (
                                <Routes key={sub.slug}>
                                  <Route
                                    element={
                                      <ChildRoute slug_key={sub.slug}>
                                        {sub.component}
                                      </ChildRoute>
                                    }
                                    path={router_config[sub.slug]?.path}
                                    key={router_config[sub.slug]?.path}
                                  ></Route>
                                </Routes>
                              ))}
                          </ChildRoute>
                        }
                        path={router_config[child.slug]?.path}
                        key={router_config[child.slug]?.path}
                      />
                    ))}
                  </>
                )}
              </Route>
            )}
          </>
        ))}

        <Route
          element={
            <UserRoute slug_key={'DASHBOARD'}>
              <Dashboard />
            </UserRoute>
          }
          key="*"
          path="*"
        />
      </Routes>
    </>
  )
}
