export const duplicateFields = {
  item1: { label: '', field: 'first_name' },
  item2: { label: '', field: 'last_name', newline: true, hideComma: true },
  item3: { label: '', field: 'mobile' },
  item4: { label: '', field: 'mobile_2', newline: true },
  item5: { label: '', field: 'email' },
  item6: { label: '', field: 'email_2', newline: true },
  item7: { label: 'PAN No', field: 'pan_no', newline: true },
  item8: { label: 'Primary Account', field: 'primary_company', newline: true },
}

export const districtData = [
  { name: 'Thiruvananthapuram', id: 'Thiruvananthapuram' },
  { name: 'Kollam', id: 'Kollam' },
  { name: 'Alappuzha', id: 'Alappuzha' },
  { name: 'Pathanamthitta', id: 'Pathanamthitta' },
  { name: 'Kottayam', id: 'Kottayam' },
  { name: 'Idukki', id: 'Idukki' },
  { name: 'Ernakulam', id: 'Ernakulam' },
  { name: 'Thrissur', id: 'Thrissur' },
  { name: 'Palakkad', id: 'Palakkad' },
  { name: 'Malappuram', id: 'Malappuram' },
  { name: 'Kozhikode', id: 'Kozhikode' },
  { name: 'Wayanad', id: 'Wayanad' },
  { name: 'Kannur', id: 'Kannur' },
  { name: 'Kasaragod', id: 'Kasaragod' },
]
export const institutionTypeData = [
  { name: 'Hospital', id: 'Hospital' },
  { name: 'Dispensary', id: 'Dispensary' },
]
export const streamData = [
  { name: 'Ayurveda', id: 'Ayurveda' },
  { name: 'Yoga & Naturopathy', id: 'Yoga & Naturopathy' },
  { name: 'Unnani', id: 'Unnani' },
  { name: 'Siddha', id: 'Siddha' },
  { name: 'Homeopathy', id: 'Homeopathy' },
]
export const lsgdTypeData = [
  {
    name: 'Municipal Corporation - Urban',
    id: ' Municipal Corporation - Urban',
  },
  { name: 'Municipality - Urban', id: 'Municipality - Urban' },
  { name: 'Panchayath – Rural', id: 'Panchayath – Rural' },
]
export const buildingTypeData = [
  { name: 'Rent', id: 'Rent' },
  { name: 'Owned', id: 'Owned' },
]
