// import FormBuilder from '../../../components/app/formBuilder/index'

import { Button } from '../../../../../components/common'
import CommonForm from '../form'
import { FormProvider, useForm } from 'react-hook-form'
type ManageStockProps = {
  handlSubmit: () => void
}
const StockSecondaryCreate: React.FC<ManageStockProps> = ({ handlSubmit }) => {
  const methods = useForm<any>({
    // resolver: zodResolver(createSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  // console.log(methods.watch())
  // methods.reset({})

  return (
    <>
      <div className="my-5 mx-8">
        <h2 className="flex text-center w-full mx-auto justify-center text-lime-500 text-2xl font-medium"></h2>
        <div className="grid md:grid-cols-1 sm:gap-[24px] p-4">
          <FormProvider {...methods}>
            <CommonForm />
          </FormProvider>
        </div>
        <div className="flex gap-8 justify-start my-3">
          <Button
            // onClick={methods.handleSubmit}
            onClick={handlSubmit}
            label={'Request Medicine'}
            className="mx-4 w-[184px]"
          />
        </div>
      </div>
    </>
  )
}
export default StockSecondaryCreate
