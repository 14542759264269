import { Outlet, useNavigate } from 'react-router-dom'
import { TabContainer } from '../../../components/common'
import { router_config } from '../../../configs/route.config'
import { useState } from 'react'
import ListingHeader from '../../../components/common/ListingTiles'

const tabData: any[] = [
  {
    label: 'All Patients',

    id: 'OP_PATIENTS_ALL',
  },
  // {
  //   label: 'Waiting Patients',

  //   id: 'OP_PATIENTS_WAITING',
  // },
  // {
  //   label: 'Examined Patients',

  //   id: 'OP_PATIENTS_EXAMINED',
  // },
]

const PatientsMain: React.FC<any> = () => {
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState('OP_PATIENTS_ALL')
  const onTabClick = (selection: any) => {
    setActiveTab(selection.id)
    navigate(`/patients/${router_config[selection.id]?.path}`)
  }
  const activity = {
    hideExport: true,
  }
  const basicData = {
    title: 'Patients',
    icon: 'institution',
  }
  return (
    <>
      <ListingHeader data={basicData} actionProps={activity} />
      <TabContainer
        data={tabData}
        activeTab={activeTab}
        // isContainer
        onClick={onTabClick}
        // tabBGClass="bg-tab-detail  px-[15px] py-1.5 rounded-sm"
      >
        <Outlet />
      </TabContainer>
    </>
  )
}

export default PatientsMain
