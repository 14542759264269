// import FormBuilder from '../../../components/app/formBuilder/index'

import { Button, ConfirmModal } from '../../../components/common'
import { useNavigate } from 'react-router-dom'
import { router_config } from '../../../configs/route.config'
import CommonForm from '../manufacturer_form'
import { FormProvider, useForm } from 'react-hook-form'
import { useState } from 'react'
import { CreateSchema, formSchema } from './schema'
import { zodResolver } from '@hookform/resolvers/zod'
import { useCreateDistrictAdmin } from './api'
import ListingHeader from '../../../components/common/ListingTiles'

const DistrictAdminCreate = () => {
  const navigate = useNavigate()
  const [setCustomBack] = useState(true)

  const onSuccess = () => {
    setSuccessModalActive(true)
  }
  const { mutate } = useCreateDistrictAdmin(onSuccess)
  const [successModalActive, setSuccessModalActive] = useState(false)
  const methods = useForm<CreateSchema>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: { state: 'Kerala' },
  })

  const handleCloseModal = () => {
    setSuccessModalActive(false)
    navigate(`${router_config.DISTRICT_ADMIN.path}`)
  }
  console.log(methods.watch(), methods.formState.errors)
  const onSubmit = (payload: CreateSchema) => {
    // console.log('hi')
    const data = {
      district_admin: {
        ...payload,
        ...(payload.email && { email: {} }),
        ...(payload.phone_number && { phone_number: {} }),
        user_attributes: {
          email: payload.email,
          phone_number: payload.phone_number,
        },
      },
    }
    mutate(data)
  }
  const basicData = {
    title: 'Add District Admin',
    icon: 'stock',
  }
  const activity = {
    hideExport: true,
  }
  return (
    <>
      <ListingHeader
        data={basicData}
        actionProps={activity}
        setCustomBack={setCustomBack}
      />
      <div className=" px-[24px] py-[24px]">
        <div className="">
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="grid md:grid-cols-3 md:gap-8 sm:grid-cols-1 sm:gap-[24px]">
              <FormProvider {...methods}>
                <CommonForm edit={false} />
              </FormProvider>
            </div>
            <div className="flex gap-8 mt-7 justify-center">
              <Button
                label={'Clear'}
                onClick={() => methods.reset({})}
                outlined={true}
              />
              <Button
                // onClick={methods.handleSubmit}
                type="submit"
                label={'Submit'}
              />
            </div>
          </form>

          {successModalActive && (
            <ConfirmModal
              isOpen={true}
              body={
                <div className="font-medium text-primary leading-8 text-xl">
                  Registration Completed Successfully
                </div>
              }
              topIcon="check-circle"
              onClose={handleCloseModal}
              // title={'Add New Pharmacist'}
            />
          )}
        </div>
      </div>
    </>
  )
}
export default DistrictAdminCreate
