const defaultColumnProps1 = {
  sortable: false,
  isVisible: true,
  resizable: true,
}

export const getColumns = ({}: any) => {
  // console.log(total)
  const createRenderCell = (key: string, isCustom?: string) => (row: any) => {
    if (isCustom === 'total') {
      return {
        cell: <div>{row?.requested_quantity * row?.price_per_unit}</div>,
      }
    }
  }
  const column = [
    {
      title: 'Medicine name',
      field: 'name',
      fixed: true,
      ...defaultColumnProps1,
      // link: true,
      // sortKey: 'default_contact__first_name',
      // rowClick: (row: any) => onViewAction(row, 'contactName'),
    },
    {
      title: 'Unit of Packing',
      // renderCell: createRenderCell('cre_agent.name'),
      field: 'unit_of_packing',
      ...defaultColumnProps1,
      // customCell: true,
      // sortKey: 'cre_agent_user_first_name',
    },
    {
      title: 'Price/Unit',
      field: 'price_per_unit',
      ...defaultColumnProps1,
    },
    {
      title: 'Requested Quantity',
      field: 'requested_quantity',
      ...defaultColumnProps1,

      sortKey: '',
      // renderCell: createRenderCell('primary_company.business_name'),
      // rowClick: (row: any) => onViewAction(row, 'business_name'),
    },
    {
      title: 'Total Amount',
      // field: 'email',
      ...defaultColumnProps1,

      customCell: true,
      sortKey: '',
      renderCell: createRenderCell('primary_company.business_name', 'total'),
      // rowClick: (row: any) => onViewAction(row, 'business_name'),
    },
  ]

  return column
}
