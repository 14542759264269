import React from 'react'
import Button from '../buttons/Button'
// import { Button, Icon } from '..'
import Icons from '../icons'
// import ManagedList from './ManagedList'
import Tooltip from '@mui/material/Tooltip'
import { useLocation } from '../../../pages/contacts/create/api'

type BasicDataProps = {
  title: string | number
  icon?: string
}

const stream: any[] = [
  { value: '0', label: 'Ayurveda' },
  { value: '1', label: 'Yoga & Naturopathy' },
  { value: '2', label: 'Unnani' },
  { value: '3', label: 'Siddha' },
  { value: '4', label: 'Homeopathy' },
]

const type: any[] = [
  { value: 'Hospital', label: 'Hospital' },
  { value: 'Dispensary', label: 'Dispensary' },
]

// type OptionsProps = {
//   value: string
//   label?: string
// }
// const options: OptionsProps[] = [
//   { value: 'Ayurveda', label: 'Ayurveda' },
//   { value: 'Homeo', label: 'Homeo' },
//   { value: 'Unani', label: 'Unani' },
//   { value: 'Yoga', label: 'Yoga' },
//   { value: 'Siddha', label: 'Siddha' },
// ]
// type ActionProps = {
//   id: number
//   label: string
//   icon?: string
//   isOutlined?: boolean
// }

type DetailTileProps = {
  data: BasicDataProps
  onActionClick?: () => void
  // onHandleExport?: () => void
  handleUploadFile?: (file: any) => void
  actionProps?: any
  actionTitle?: any
  // showManagedList?: boolean
  // groupData?: any
  // groupValue?: any
  // setGroupNameCode?: any
  setCustomType?: any
  setCustomSearch?: any
  setCustomName?: any
  manufactures?: any[]
  medicines?: any[]
  customName?: any
  customSearch?: any
  setCustomPhone?: any
  customPhone?: any
}

const CustomisedheaderFundMapping: React.FC<DetailTileProps> = ({
  data,
  onActionClick,
  // onHandleExport,
  actionProps,
  actionTitle,
  // showManagedList = false,
  // groupData = [],
  // groupValue,
  // setGroupNameCode,
  handleUploadFile,
  customName,
  setCustomSearch,
  customSearch,
  setCustomName,
  setCustomPhone,
  customPhone,
}) => {
  const { data: districts } = useLocation()
  const handleFileChange = (event: any) => {
    // Access the uploaded file using event.target.files[0]
    // console.log('1', event)
    const uploadedFile = event.target.files[0]
    // Perform any additional logic with the file
    // // console.log('Uploaded file:', uploadedFile)
    // console.log('2')
    handleUploadFile?.(uploadedFile)
    // console.log('3')
  }
  const handleButtonClick = () => {
    // Programmatically trigger the file input
    const fileInput = document.getElementById('file-upload')
    if (fileInput) {
      fileInput.click()
    }
  }
  const handleDownload = () => {
    const excelFilePath = '/Hospital In charge Excel sheet format .xlsx'
    const link = document.createElement('a')
    link.download = 'Hospital In charge Excel sheet format .xlsx'
    link.href = excelFilePath
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
  // console.log(onHandleExport)
  console.log(districts)
  return (
    <div className="flex justify-between flex-wrap gap-3 items-center border-formBorder">
      <div className="flex gap-6">
        {data.icon && data.title && (
          <div className="flex items-center flex-wrap gap-5 text-[#222] ">
            <div className="flex items-center gap-3">
              {data?.icon && (
                <div className="w-10 h-10 bg-primary flex  items-center justify-center rounded-[4px]">
                  <Icons className="iconWhite" name={data?.icon} />
                </div>
              )}
              <div className="">
                <div className="font-bold text-lg text-primaryText">
                  {data.title}
                </div>
              </div>
            </div>
          </div>
        )}
        {setCustomName && (
          <div className="border rounded-lg px-2  py-1.5 flex items-center h-auto w-[220px] table-header-select">
            <select
              name="institution"
              id="institutions"
              className="py-1 px-2 bg-white w-full h-full"
              onChange={(e) => setCustomName(e.target.value)}
              value={customName}
            >
              {' '}
              <option value="" className="">
                Select District
              </option>
              {districts?.districts?.map((item: any, id: any) => (
                <option value={item?.id} key={id} className="">
                  {item?.name}
                </option>
              ))}
            </select>
          </div>
        )}

        {setCustomSearch && (
          <div className="border rounded-lg px-2  py-1.5 flex items-center h-auto w-[220px] table-header-select">
            <select
              name="institution"
              id="institutions"
              className="py-1 px-2 bg-white w-full h-full"
              onChange={(e) => setCustomSearch(e.target.value)}
              value={customSearch}
            >
              {' '}
              <option value="" className="">
                Select Stream
              </option>
              {stream?.map((item: any, id: any) => (
                <option value={item?.label} key={id} className="">
                  {item?.label}
                </option>
              ))}
            </select>
          </div>
        )}
        {setCustomPhone && (
          <div className="border rounded-lg px-2  py-1.5 flex items-center h-auto w-[220px] table-header-select">
            <select
              name="institution"
              id="institutions"
              className="py-1 px-2 bg-white w-full h-full"
              onChange={(e) => setCustomPhone(e.target.value)}
              value={customPhone}
            >
              {' '}
              <option value="" className="">
                Select Institution Type
              </option>
              {type?.map((item: any, id: any) => (
                <option value={item?.label} key={id} className="">
                  {item?.label}
                </option>
              ))}
            </select>
          </div>
        )}

        {!actionProps?.hideExport && (
          <Tooltip
            title={
              <div
                className="cursor-pointer text-m font-normal flex items-end px-2 text-white underline"
                onClick={handleDownload}
              >
                Download Excel Format
              </div>
            }
            placement="top"
            className="bg-white"
          >
            <span data-testid="dfssd" className="text-center text-white">
              <div>
                <label htmlFor="file-upload">
                  <Button
                    label="Upload Excel"
                    icon="upload"
                    outlined={true}
                    // onClick={onHandleExport}
                    onClick={handleButtonClick}
                    className="w-[155px]"
                  />
                </label>
                <input
                  type="file"
                  id="file-upload"
                  style={{ display: 'none' }}
                  onChange={(e) => {
                    // console.log('this is the function');
                    handleFileChange(e)
                  }}
                />
              </div>
            </span>
          </Tooltip>
        )}
        {actionProps?.actionCancel && (
          <Button
            label="Cancel"
            outlined={true}
            primary={false}
            onClick={() => actionProps.actionCancel()}
          />
        )}
      </div>
      <div className="flex">
        {onActionClick && (
          <div>
            <Button
              label={actionTitle}
              icon={actionProps?.actionTitle ? '' : ''}
              onClick={onActionClick}
              disabled={actionProps?.disableSubmit}
              className="w-[177px]"
              outlined
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default CustomisedheaderFundMapping
