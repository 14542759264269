import { AutoComplete } from 'qbs-core'
import React, { useCallback } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { FormBuilderProps } from '../../../common/types'
import FileUpload from '../../common/fileUpload/index'
import Checkbox from '../../common/inputs/Checkbox'
import CustomDatePicker from '../../common/inputs/CustomDatePicker'
import Radio from '../../common/inputs/Radio'
import Textarea from '../../common/inputs/TextArea'
import TextField from '../../common/inputs/TextField'
import DuplicateListItem from './utils'

interface DuplicateField {
  field: string
  label?: string
  newline?: boolean
}
type Props = {
  data?: any
  edit?: boolean
  duplicates?: any
  getDuplicates?: (field: string, value?: string) => void
  clearDuplicates?: (fieldName: string) => void
  duplicateFields?: { [key: string]: DuplicateField }
  spacing?: boolean
}

const FormBuilder: React.FC<Props> = (props) => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext()
  const {
    data,
    edit,
    duplicates,
    getDuplicates,
    clearDuplicates,
    duplicateFields,
  } = props

  const handleChange = useCallback((e: any, field: FormBuilderProps) => {
    setValue(field?.id, e.id)
    setValue(field.name, e.name, { shouldValidate: true })
    // console.log(e.id)
    field.handleCallBack?.(e)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const handleMultiChange = useCallback((e: any, field: FormBuilderProps) => {
    setValue(field.name, e, { shouldValidate: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleTextChange = useCallback(
    (onChange: any, e: any, field: FormBuilderProps) => {
      const { value } = e.target
      const trimValue = value.trimStart()

      if (field.type === 'checkbox') {
        onChange(e.target.checked)
        return
      }
      if (trimValue) {
        if (field.type === 'number') {
          const num = parseFloat(trimValue)
          if (!isNaN(num)) {
            // console.log('valid')
            onChange(num)
          } else {
            // console.log('else')
          }
        } else {
          onChange(trimValue)
        }

        if (field.isDuplicateCheck) {
          getDuplicates?.(field.name, encodeURIComponent(trimValue))
        }
      } else {
        const empty = field.type === 'number' ? undefined : ''
        onChange(empty)
        clearDuplicates?.(field.name)
      }
    },
    [clearDuplicates, getDuplicates]
  )
  const handleBlurChange = (field: FormBuilderProps) => {
    clearDuplicates?.(field.name)
  }
  const handleStyle = (key: string, field: FormBuilderProps) => {
    const style = 'text-primaryText text-xxs leading-4 font-medium'
    return field.name === duplicateFields?.[key]?.field
      ? `${style} fieldNameStyle text-error-light`
      : style
  }

  const handleFileUpload = (value: any, field: FormBuilderProps) => {
    if (field.isMultiple) {
      setValue(field.name, value, {
        shouldValidate: true,
      })
    } else {
      setValue(field.name, value?.target?.files[0] ?? undefined, {
        shouldValidate: true,
      })
    }
  }

  const handleNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: FormBuilderProps
  ) => {
    const { value } = e.target

    setValue(field.name, value && value !== '' ? value : null, {
      shouldValidate: true,
    })
  }
  //fieldNameStyle
  const renderForm = (field: FormBuilderProps) => {
    switch (field.type) {
      case 'text':
      case 'email':
        // case 'number':
        return (
          <div className="relative">
            <Controller
              name={`${field.name}`}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  label={field.label}
                  placeholder={field.placeholder}
                  id={field.name}
                  onChange={(e) => handleTextChange(onChange, e, field)}
                  value={
                    value ? value : field.type === 'number' ? undefined : ''
                  }
                  name={field.name}
                  type={field.type}
                  onBlur={() => {
                    handleBlurChange(field)
                  }}
                  required={field.required}
                  errors={errors}
                  disabled={field.disabled ?? isEditable()}
                  hidden={field.hidden}
                  handleAction={field.handleAction}
                  actionLabel={field.actionLabel}
                />
              )}
            />
            {duplicates?.[field.name]?.length > 0 && (
              <ul className="duplicate-dropdown-list">
                <li className="p-1 text-xxs leading-4 font-semibold text-secondary bg-transparent hover:bg-transparent">
                  {'Possible Duplicates'}
                </li>

                {duplicates?.[field.name]?.map(
                  (duplicate: any, index: number) => (
                    <DuplicateListItem
                      key={index}
                      duplicate={duplicate}
                      handleStyle={handleStyle}
                      fields={duplicateFields}
                      field={field}
                    />
                  )
                )}
              </ul>
            )}
          </div>
        )
      case 'number':
        return (
          <Controller
            name={field.name}
            render={({ field: { value } }) => (
              <TextField
                label={field.label}
                placeholder={field.placeholder}
                id={field.name}
                name={field.name}
                value={value ?? ''}
                type="number"
                onChange={(e) => handleNumberChange(e, field)}
                required={field.required}
                errors={errors}
                maxLength={field.maxLength ?? 200}
                minLength={field.minLength}
                // disabled={isEditable() || field.disabled}
                disabled={field.disabled ?? isEditable()}
              />
            )}
          />
        )
      case 'textarea':
        return (
          <div className="relative">
            <Controller
              name={`${field.name}`}
              control={control}
              render={({ field: { onChange, value } }) => (
                <Textarea
                  label={field.label}
                  placeholder={field.placeholder}
                  id={field.name}
                  value={value}
                  name={field.name}
                  onChange={(e) => handleTextChange(onChange, e, field)}
                  required={field.required}
                  errors={errors}
                  disabled={isEditable()}
                />
              )}
            />
          </div>
        )
      case 'checkbox':
        return (
          <div className="relative">
            <Controller
              name={`${field.name}`}
              control={control}
              render={({ field: { onChange, value } }) => (
                <div className="flex gap-2">
                  <Checkbox
                    id={field.name}
                    checked={value}
                    handleChange={(e) => handleTextChange(onChange, e, field)}
                  />
                  <label htmlFor={field.name}>{field.label}</label>
                </div>
              )}
            />
          </div>
        )
      case 'radio':
        return (
          <div className="relative">
            <Controller
              name={`${field.name}`}
              control={control}
              render={({ field: { onChange, value } }) => (
                <div className="flex gap-2">
                  <Radio
                    name={field.name}
                    label={field.label}
                    data={field.data}
                    checked={value}
                    required={field.required}
                    disabled={field.disabled}
                    handleChange={onChange}
                    errors={errors}
                  />
                  {/* <label htmlFor={field.name}>{field.label}</label> */}
                </div>
              )}
            />
          </div>
        )
      case 'auto_complete':
        return (
          <div className={`${field.hidden ? 'hidden' : ''}`}>
            <Controller
              name={`${field.name}`}
              control={control}
              render={({ field: { value } }) => (
                <AutoComplete
                  key={field.name}
                  name={field.name}
                  type="auto_complete"
                  desc={field.desc as string}
                  descId={field.descId as string}
                  onChange={(e) => handleChange(e, field)}
                  value={value}
                  label={field.label}
                  errors={errors[field.name]}
                  async={field.async}
                  paginationEnabled={field.paginationEnabled}
                  nextBlock={field.nextBlock ?? undefined}
                  getData={field.getData}
                  initialLoad={field?.initialLoad}
                  placeholder={field.placeholder}
                  disabled={field.disabled ? true : isEditable()}
                  required={field.required}
                  actionLabel={field.actionLabel}
                  handleAction={field.handleAction}
                  // hidden={field.hidden}
                />
              )}
            />
          </div>
        )
      case 'multi_select':
        return (
          <Controller
            name={`${field.name}`}
            control={control}
            render={({}) => (
              <AutoComplete
                key={field.name}
                name={field.name}
                type="auto_complete"
                desc={field.desc as string}
                descId={field.descId as string}
                onChange={(e) => handleMultiChange(e, field)}
                // value={value}
                selectedItems={field.selectedItems}
                label={field.label}
                errors={errors[field.name]}
                async={field.async}
                paginationEnabled={field.paginationEnabled}
                nextBlock={field.nextBlock ?? undefined}
                getData={field.getData}
                initialLoad={field?.initialLoad}
                placeholder={field.placeholder}
                disabled={isEditable()}
                required={field.required}
                actionLabel={field.actionLabel}
                handleAction={field.handleAction}
                isMultiple={field.isMultiple}
              />
            )}
          />
        )
      case 'custom_search_select':
        return (
          <Controller
            name={`${field.name}`}
            control={control}
            render={({ field: { value } }) => (
              <AutoComplete
                key={field.name}
                name={field.name}
                type="custom_search_select"
                desc={field.desc as string}
                descId={field.descId as string}
                onChange={(e) => handleChange(e, field)}
                value={value}
                label={field.label}
                data={field?.data}
                errors={errors[field.name]}
                actionLabel={field.actionLabel}
                handleAction={field.handleAction}
                placeholder={field.placeholder}
                disabled={isEditable()}
                required={field.required}
              />
            )}
          />
        )
      case 'custom_select':
        return (
          <Controller
            name={`${field.name}`}
            control={control}
            render={({ field: { value } }) => (
              <AutoComplete
                key={field.name}
                name={field.name}
                type="custom_select"
                desc={field.desc as string}
                descId={field.descId as string}
                onChange={(e) => handleChange(e, field)}
                value={value}
                // value={value ? value.data.find(item  => item.id === value)?.name : ''}
                label={field.label}
                // data={field?.data}
                data={field?.data}
                errors={errors[field.name]}
                placeholder={field.placeholder}
                required={field.required}
                disabled={field.disabled ?? isEditable()}
              />
            )}
          />
        )

      case 'date':
        return (
          <Controller
            name={`${field.name}`}
            control={control}
            render={({ field: { value } }) => {
              return (
                <CustomDatePicker
                  onChange={(data) =>
                    setValue(field.name, data.value, { shouldValidate: true })
                  }
                  name={field.name}
                  value={value}
                  placeholder={'DD-MM-YYYY'}
                  label={field.label}
                  errors={errors}
                  // maxDate={field.maxDate}
                  maxDate={field.maxDate ?? new Date()}
                  minDate={field.minDate}
                  disabled={field?.disabled ?? isEditable()}
                  required={field.required}
                />
              )
            }}
          />
        )
      case 'time_only':
        return (
          <Controller
            name={`${field.name}`}
            control={control}
            render={({ field: { value } }) => {
              return (
                <CustomDatePicker
                  onChange={(data) =>
                    setValue(field.name, data.value, { shouldValidate: true })
                  }
                  name={field.name}
                  value={value}
                  placeholder={'HH:MM:SS'}
                  label={field.label}
                  errors={errors}
                  showTimeSelect
                  showTimeSelectOnly
                  maxDate={field.maxDate}
                  minDate={field.minDate}
                  disabled={field?.disabled ?? isEditable()}
                  required={field.required}
                />
              )
            }}
          />
        )
      case 'file_upload':
        return (
          <Controller
            name={`${field.name}`}
            control={control}
            render={({ field: {} }) => {
              return (
                <FileUpload
                  name={`${field.name}`}
                  required={field.required}
                  id={`${field.name}`}
                  onChange={(value) => handleFileUpload(value, field)}
                  label={field.label ?? ''}
                  value={field.selectedFiles}
                  isMultiple={field.isMultiple}
                  errors={errors}
                  handleDeleteFile={() => {
                    field.handleDeleteFile
                  }}
                  // supportedFiles={
                  //   field.acceptedFiles ?? 'SVG, PNG, PDF,JPG, EXCEL,DOC'
                  // }
                  // sizeLimit={field.fileSize ?? 5}
                  buttonLabel={field.fileUploadLabel ?? 'Browse'}
                  // iconName="cloud-upload"
                />
              )
            }}
          />
        )
      default:
        return null
    }
  }

  const isEditable = () => {
    return !edit
  }
  return (
    <>
      {props.spacing ? (
        <>
          {data.map((field: FormBuilderProps) => (
            <div
              className={`col-span-12 formD md:col-span-${field.spacing ?? 4} `}
              key={field.name}
            >
              {renderForm(field)}
            </div>
          ))}
        </>
      ) : (
        <>
          {data.map((field: FormBuilderProps) => (
            <>
              {!field.hidden && <div key={field.name}>{renderForm(field)}</div>}
            </>
          ))}
        </>
      )}
    </>
  )
}

export default FormBuilder
