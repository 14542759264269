import { useMutation, useQuery } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import apiUrl from '../../../../apis/api.url'
import { getData, updateFromData } from '../../../../apis/api.helpers'
import { getErrorMessage } from '../../../../utilities/parsers'
// import { getErrorMessage } from '../../../utilities/parsers'
// import apiUrl from '../../../apis/api.url'
// import { getData, updateFromData } from '../../../apis/api.helpers'

const buildUrlWithParams = (baseUrl: string, params: any) => {
  return `${baseUrl}/${params}`
}

const fetchData = async (id: any) => {
  const url = buildUrlWithParams(apiUrl.OP_INCHARGE, id)
  const response = await getData(url)
  // console.log(response)
  return response
}

export const useOpInchargeDetails = (id: any) => {
  return useQuery(['opincharge_detail'], () => fetchData(id), {
    refetchOnWindowFocus: false,
  })
}
export const updateOpIncharge = (data: { data: any; opInchargeId: string }) => {
  // console.log(data)
  return updateFromData(
    `${apiUrl.OP_INCHARGE}/${data?.opInchargeId}`,
    data?.data
  )
}

export const useUpdateOpIncharge = (
  handleSubmission: (input: any, id: string) => void
) => {
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(updateOpIncharge, {
    onSuccess: (res: any) => {
      handleSubmission(res.data, res.id)
    },

    onError: (error: any) => {
      enqueueSnackbar(
        getErrorMessage(
          error?.response?.data?.message || 'Something went wrong'
        ),
        {
          variant: 'error',
        }
      )
    },
  })
}
