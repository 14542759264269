import { useEffect, useState } from 'react'
import { Button } from '../../../../../../components/common'
// import { prescription } from '../store'
import { useParams } from 'react-router-dom'
import { usePatientListing } from '../../api'
import moment from 'moment'
import { useAuthStore } from '../../../../../../store/authStore'
// import { calcWindowHeight } from '../../../../../../utilities/calcHeight'
type ManagePhasrmacistProps = {
  handlePrint: () => void
  handleCloseModal: () => void
  data?: any
}
const PatientPrescriptionPrintView: React.FC<ManagePhasrmacistProps> = ({
  handlePrint,
  handleCloseModal,
  data,
}) => {
  const { userData } = useAuthStore()
  console.log(data)
  const { data: patients } = usePatientListing()
  const [patientData, setPatientData] = useState<any>()
  const { id2 } = useParams()
  // const date = date()
  // console.log(date)
  useEffect(() => {
    patients?.op_records.map((item: any) => {
      if (item?.id == id2) {
        setPatientData(item)
      }
    })
  }, [data, id2])
  console.log(patientData)
  console.log(data)
  // const handleSet = () => {
  //   window.print()
  //   handlePrint
  // }
  // const generateHeight = () => {
  //   return `h-[${calcWindowHeight(100)}]`
  // }
  console.log(userData)
  return (
    <div className={`max-h-screen h-[500px] overflow-y-auto `}>
      <div className="h-full ">
        <div className="flex justify-between">
          <div>
            <div className="text-[18px] leading-normal font-normal pb-2">
              Health Service Department
            </div>
            <div className="text-[18px] leading-normal font-normal">
              Government of Kerala
            </div>
          </div>
          <div>
            <div className="text-[18px] leading-normal font-normal pb-2">
              {userData?.institution_name}, {userData?.district_name}
            </div>
            <div className="text-[18px] leading-normal font-normal">
              PH : {userData?.institution_phone_number},{' '}
              {userData?.institution_land_number}
            </div>
          </div>
        </div>
        <div className="py-[40px]">
          <div className="flex justify-between">
            <div className="flex items-center">
              <div className="sm:text-xs  font-normal leading-normal">
                <div className="text-2xl font-semibold">
                  {patientData?.name}
                </div>
                <div className="my-1 py-2 font-semibold">OP Number : {id2}</div>
                <div className="font-semibold">
                  Unique ID : {patientData?.patient_id}
                </div>
              </div>
            </div>
            <div className="flex gap-4 justify-between w-100">
              <div className=" lg:text-xs text-[10px] px-1 2xl:px-2 font-normal leading-normal align-middle h-[40px]	text-SidenavFontColor">
                <div className="font-bold text-black">Age</div>
                <div>{patientData?.age}</div>
              </div>
              <div className=" lg:text-xs text-[10px] px-5 2xl:px-8 font-normal leading-normal align-middle h-[40px]	text-SidenavFontColor">
                <div className="font-bold text-black">Gender</div>
                <div>{patientData?.gender}</div>
              </div>
              <div className=" lg:text-xs text-[10px] px-5 2xl:px-8 font-normal leading-normal align-middle h-[40px]	text-SidenavFontColor">
                <div className="font-bold text-black">Date</div>
                <div>{moment(new Date()).format('DD-MM-YYYY')}</div>
              </div>
              <div className=" lg:text-xs text-[10px]  font-normal leading-normal align-middle h-[40px]	text-SidenavFontColor">
                <div className="font-bold text-black">Time</div>
                <div>{moment(new Date()).format('h:mm a')}</div>
              </div>
              <div className=" lg:text-xs text-[10px] px-5 2xl:px-8 font-normal leading-normal align-middle h-[40px]	text-SidenavFontColor">
                <div className="font-bold text-black">Doctor</div>
                <div>{patientData?.doctor_name}</div>
              </div>
              <div className=" lg:text-xs text-[10px] px-5 2xl:px-8 font-normal leading-normal align-middle h-[40px]	text-SidenavFontColor">
                <div className="font-bold text-black">Specialization</div>
                <div>{patientData?.specialization}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="border-y ">
          <div className="border-b grid grid-cols-5 py-3 text-[18px] font-bold leading-normal tracking-wider">
            <div>Medicine Name</div>
            <div>Quantity & Mode</div>
            <div>Dose & Administration</div>
            <div>Remarks</div>
            <div>From</div>
          </div>
          {data?.map((item: any, i: number) => (
            <div
              key={i}
              className="border-t grid grid-cols-5 py-3 text-[16px] leading-normal"
            >
              <div>{item.name}</div>
              <div>{item.quantity}</div>
              <div>
                {item.dose &&
                  item.administration &&
                  `${item.dose}, ${item.administration}`}
              </div>
              <div>{item.remarks}</div>
              <div>{item?.medicine_id ? 'Pharmacy' : 'Outside'}</div>
            </div>
          ))}
        </div>
        <div className="py-[24px]">
          <div className="flex justify-center gap-5">
            <Button
              onClick={handleCloseModal}
              size="xs"
              label="Cancel"
              outlined
            />
            <Button onClick={handlePrint} size="xs" label="Print" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PatientPrescriptionPrintView
