import { Outlet, useNavigate } from 'react-router-dom'
// import { Button } from '../../../components/common'
import { router_config } from '../../../configs/route.config'
import { useState } from 'react'
// import MultiTabContainer from '../../../components/common/tab/MultipleTabContainer'
import ListingHeader from '../../../components/common/ListingTiles'
import { TabContainer } from '../../../components/common'

const tabData: any[] = [
  {
    label: 'Pending',

    id: 'HOSPITAL_PENDING_ORDER_LOAN',
  },
  {
    label: 'Forwarded',

    id: 'HOSPITAL_CONFIRMED_ORDER_LOAN',
  },
  {
    label: 'Approved',

    id: 'HOSPITAL_DELIVERED_ORDER_LOAN',
  },
  {
    label: 'Delivered Orders',

    id: 'HOSPITAL_REVERTED_ORDER_LOAN',
  },
  // {
  //   label: 'Rejected Orders',

  //   id: 'HOSPITAL_REJECTED_ORDER_LOAN',
  // },
]

const MyOrdersMainLoan: React.FC<any> = () => {
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState('HOSPITAL_PENDING_ORDER_LOAN')
  const onTabClick = (selection: any) => {
    setActiveTab(selection.id)
    navigate(`/medicine-loans/${router_config[selection.id]?.path}`)
  }

  const context = { setActiveTab }

  const activity = {
    hideExport: true,
  }
  const basicData = {
    title: 'Medicine Orders Through Loan',
    icon: 'institution',
  }
  return (
    <>
      <ListingHeader
        data={basicData}
        actionTitle="Add Institution"
        actionProps={activity}
      />

      <TabContainer data={tabData} activeTab={activeTab} onClick={onTabClick}>
        <Outlet context={context} />
      </TabContainer>
    </>
  )
}

export default MyOrdersMainLoan
