import { QbsTable } from 'qbs-react-grid'
import { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import ListingHeader from '../../components/common/ListingTiles'
import { router_config } from '../../configs/route.config'
import { useContactFilterStore } from '../../store/filterSore/contactStore'
import { statusClassGen } from '../../utilities/generators'
import { getSortedColumnName } from '../../utilities/parsers'
import { useDistributeFund, useInstitution } from './api'
import { getColumns } from './contactColumns'
import { calcWindowHeight } from '../../utilities/calcHeight'
import CustomisedheaderFundMapping from '../../components/common/ListingTiles/customisedheaderFundMapping'
import { ConfirmModal, DialogModal } from '../../components/common'
import FundCreate from './create'
import { useSnackbar } from 'notistack'
// import { PrimaryFilterComponent } from '../../components/app/filterComponents/primaryFilter'

const FundMappingMainAdmin = () => {
  const navigate = useNavigate()
  const [customSearch, setCustomSearch] = useState('')
  const [customName, setCustomName] = useState('')
  const { enqueueSnackbar } = useSnackbar()
  const [customPhone, setCustomPhone] = useState('')
  const [dialogModalActive, setDialogModalActive] = useState(false)
  const [successModalActive, setSuccessModalActive] = useState(false)
  const onSuccess = () => {
    setDialogModalActive(false)
    setSuccessModalActive(true)
    setCustomSearch('')
    console.log('After customSearch:', customSearch)
    setCustomName('')
    setCustomPhone('')
  }
  const { mutate } = useDistributeFund(onSuccess)
  const [mergeIsActive] = useState(false)
  const { pageParams, setPageParams, selectedRows, setSelectedRows } =
    useContactFilterStore()

  const { page, page_size, search, ordering, filters } = pageParams
  // console.log(selectedRows)

  const searchParams = {
    page_number: page,
    page_size: page_size,
    // name: customName,
    district_id: customName,
    ...(customSearch === 'Yoga & Naturopathy'
      ? { stream: 'Yoga %26 Naturopathy' }
      : customSearch === 'select'
        ? { stream: undefined }
        : { stream: customSearch }),

    instution_type: customPhone,

    ordering: ordering,
    ...filters,
  }
  const { data, refetch, isFetching } = useInstitution(searchParams)
  useEffect(() => {
    refetch()
  }, [
    page,
    page_size,
    search,
    customSearch,
    customPhone,
    customName,
    ordering,
    filters,
    refetch,
  ])

  const onChangeRowsPerPage = (count: any) => {
    setPageParams({
      ...pageParams,
      page_size: count,
      page: 1,
    })
  }
  const onChangePage = (row: any) => {
    setPageParams({
      ...pageParams,
      page: row,
    })
  }

  // const onViewAction = (r: any, from: string) => {
  //   // console.log(r, from)
  // }
  const handleSort = (orderColumn: any, orderDirection: any) => {
    setPageParams({
      ...pageParams,
      sortColumn: orderColumn,
      sortType: orderDirection,
      ordering: getSortedColumnName(orderColumn, orderDirection),
    })
  }
  const handleSeach = (key?: string) => {
    setPageParams({
      ...pageParams,
      search: key as string,
      page: 1,
    })
  }
  const handleOnSlect = (rows: any) => {
    // const d = rows || []
    // setSelectedRows(d.map((item: any) => Number(item)))
    setSelectedRows(rows)
  }

  const basicData = {
    title: 'Fund Mapping',
    icon: 'Manufacturer',
  }
  const basicDatas = {
    title: '',
    icon: '',
  }

  const [columns, setColumns] = useState<any>(() =>
    getColumns({
      // onViewAction: onViewAction,
      statusClassGen: statusClassGen,
    })
  )

  const handleResetColums = () => {
    setColumns([
      ...getColumns({
        // onViewAction: onViewAction,
        statusClassGen: statusClassGen,
      }),
    ])
  }
  if (mergeIsActive) {
    return <Outlet context={{ selectedRows, refetch }} />
  }
  const activity = {
    hideExport: true,
  }
  const handleCreate = () => {
    navigate(`${router_config.ADMIN_FUND_MAPPING_PREVIOUS.path}`)
  }

  const handleClose = () => {
    setDialogModalActive(false)
  }
  const handleSubmit = (data: any) => {
    const newArray: any = []
    selectedRows?.map((item: any) => {
      const n = Number(item)
      newArray.push(n)
    })
    const sendData = {
      institution_ids: newArray,
      amount: data?.amount,
    }
    mutate(sendData)
  }
  const handleDistribute = () => {
    console.log(selectedRows)
    const insts = selectedRows ? selectedRows : []
    if (insts && insts?.length > 0) {
      setDialogModalActive(true)
    } else {
      enqueueSnackbar('Select at least one hospital', {
        variant: 'error',
      })
    }
  }
  const handleCloseModal = () => {
    setSuccessModalActive(false)
    refetch()
    setSelectedRows([0])
    handleOnSlect(0)
  }

  return (
    <div className="">
      <div className="">
        <ListingHeader
          data={basicData}
          onActionClick={handleCreate}
          actionTitle="Previous Distribution"
          actionProps={activity}
          setCustomSearch={setCustomSearch}
        />

        <div className=" px-[24px] py-[24px]">
          <CustomisedheaderFundMapping
            data={basicDatas}
            onActionClick={handleDistribute}
            actionTitle="Distribute Fund"
            actionProps={activity}
            setCustomSearch={setCustomSearch}
            customSearch={customSearch}
            setCustomName={setCustomName}
            customName={customName}
            setCustomPhone={setCustomPhone}
            customPhone={customPhone}
          />
          <QbsTable
            columns={columns}
            dataRowKey="id"
            selectedRows={selectedRows}
            selection={true}
            isLoading={isFetching}
            toolbar
            handleColumnSort={handleSort}
            height={calcWindowHeight(386)}
            // data={data?.institutions ?? []}
            data={
              data?.institutions.map((item: any) => ({
                ...item,
                id: item.id.toString(),
              })) || []
            }
            handleResetColumns={() => handleResetColums()}
            // sortType={sortType}
            // sortColumn={sortColumn}
            // selection
            pagination
            paginationProps={{
              onPagination: onChangePage,
              total: data?.total_count,

              currentPage: pageParams?.page,
              rowsPerPage: Number(pageParams?.page_size ?? data?.page_size),
              onRowsPerPage: onChangeRowsPerPage,
              dropOptions: [10, 20, 30, 50, 100, 200],
            }}
            searchValue={pageParams?.search}
            onSearch={handleSeach}
            asyncSearch
            handleSearchValue={(key?: string) => handleSeach(key)}
            onSelect={handleOnSlect}
            columnToggle
          />
        </div>
        {dialogModalActive && (
          <DialogModal
            isOpen={true}
            body={<FundCreate handleSubmit={handleSubmit} />}
            onClose={handleClose}
            // title={'Add New Pharmacist'}
          />
        )}
        {successModalActive && (
          <ConfirmModal
            isOpen={true}
            body={
              <div className="font-medium text-primary leading-8 text-xl">
                Fund Distributed Successfully
              </div>
            }
            topIcon="check-circle"
            onClose={handleCloseModal}
            // title={'Add New Pharmacist'}
          />
        )}
      </div>
    </div>
  )
}

export default FundMappingMainAdmin
