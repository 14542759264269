import { Outlet, useNavigate } from 'react-router-dom'
// import { Button } from '../../../components/common'
import { router_config } from '../../../configs/route.config'
import { useState } from 'react'
// import MultiTabContainer from '../../../components/common/tab/MultipleTabContainer'
import ListingHeader from '../../../components/common/ListingTiles'
import { TabContainer } from '../../../components/common'

const tabData: any[] = [
  {
    label: 'Pending Orders',

    id: 'HOSPITAL_PENDING_ORDER',
  },
  {
    label: 'Confirmed Orders',

    id: 'HOSPITAL_CONFIRMED_ORDER',
  },
  {
    label: 'Delivered Orders',

    id: 'HOSPITAL_DELIVERED_ORDER',
  },
  {
    label: 'Reverted Orders',

    id: 'HOSPITAL_REVERTED_ORDER',
  },
  {
    label: 'Rejected Orders',

    id: 'HOSPITAL_REJECTED_ORDER',
  },
  {
    label: 'Replaced Orders',

    id: 'HOSPITAL_REPLACED_ORDER',
  },
]

const MyOrdersMain: React.FC<any> = () => {
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState('HOSPITAL_PENDING_ORDER')
  const onTabClick = (selection: any) => {
    setActiveTab(selection.id)
    navigate(`/medicine/${router_config[selection.id]?.path}`)
  }

  const context = { setActiveTab }

  const activity = {
    hideExport: true,
  }
  const basicData = {
    title: 'Medicine Orders',
    icon: 'institution',
  }
  return (
    <>
      <ListingHeader
        data={basicData}
        actionTitle="Add Institution"
        actionProps={activity}
      />

      <TabContainer data={tabData} activeTab={activeTab} onClick={onTabClick}>
        <Outlet context={context} />
      </TabContainer>
    </>
  )
}

export default MyOrdersMain
