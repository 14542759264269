import { useCallback, useEffect, useState } from 'react'
import { Button } from '../../../../components/common'
// import { useNavigate } from 'react-router-dom'

import { DialogModal } from '../../../../components/common'
import { FormProvider, useForm } from 'react-hook-form'
import CommonForm from '../pharmacist_form'
import { ConfirmModal } from '../../../../components/common'
// import config from '../../../../config'
import { QbsTable } from 'qbs-react-grid'
import moment from 'moment'
import ListingHeader from '../../../../components/common/ListingTiles'
// import { router_config } from '../../../../configs/route.config'

import { useContactFilterStore } from '../../../../store/filterSore/contactStore'
import { statusClassGen } from '../../../../utilities/generators'
import { getSortedColumnName } from '../../../../utilities/parsers'
import { useOrderDetail, useOrderDetailReplace, useOrderStatus } from '../api'
// import { getColumns } from '../contactColumnsDetail'
import { getColumns } from '../replaced_orders/contactColumnsReplaced'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Icons from '../../../../components/common/icons'
import { calcWindowHeight } from '../../../../utilities/calcHeight'

const OrderRequestDetailReplaced = () => {
  const navigate = useNavigate()
  const [setCustomBack] = useState(true)

  const { pageParams, setPageParams, selectedRows, setSelectedRows } =
    useContactFilterStore()
  // const { advanceFilter, setAdvanceFilter } = useContactFilterStore()
  // const [showDetail, setShowDetail] = useState(true)
  const param = useParams()
  const id = param.id
  // console.log(id)
  const [customType] = useState('')
  const location = useLocation()
  console.log(location.pathname)
  const [type, setType] = useState(0)
  const [dialogModalActive, setDialogModalActive] = useState(false)
  const [successModalActive, setSuccessModalActive] = useState(false)
  const [rejectModalActive, setRejectModalActive] = useState(false)
  const [dialogRejectModalActive, setDialogRejectModalActive] = useState(false)
  const [dialogRejectConfirm, setDialogRejectConfirm] = useState(false)
  const [revertModalActive, setRevertModalActive] = useState(false)
  const { page, page_size, search, filters, filterProps } = pageParams
  const methods = useForm<any>({
    // resolver: zodResolver(createSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    // defaultValues: { state: 'Kerala' },
  })
  console.log(location)
  const searchParams = {
    page_number: page,
    page_size: page_size,
    name: search,
    ...(customType === 'select'
      ? { instution_type: '' }
      : { instution_type: customType }),

    ...filters,
  }
  const onSuccess = () => {
    if (type === 0) {
      setDialogModalActive(false)
      setSuccessModalActive(true)
    } else if (type === 1) {
      setDialogModalActive(false)
      setRevertModalActive(true)
    } else if (type === 2) {
      setDialogRejectConfirm(false)
      setRejectModalActive(true)
    }
    setType(0)
    refetch()
  }
  const { mutate } = useOrderStatus(onSuccess)
  const { data, refetch, isFetching } = useOrderDetail(id, searchParams)
  const {
    data: replaceData,
    refetch: refetchReplace,
    isFetching: isFetchingReplace,
  } = useOrderDetailReplace(id, searchParams)
  console.log(replaceData, refetchReplace, isFetchingReplace)
  useEffect(() => {
    refetch()
  }, [page, page_size, search, filters, refetch, customType])
  // console.log(customSearch)
  const onChangeRowsPerPage = (count: any) => {
    setPageParams({
      ...pageParams,
      page_size: count,
      page: 1,
    })
  }
  const onChangePage = (row: any) => {
    setPageParams({
      ...pageParams,
      page: row,
    })
  }

  const onViewAction = (r: any, from: string) => {
    console.log(r, from)
  }
  const handleSort = (orderColumn: any, orderDirection: any) => {
    setPageParams({
      ...pageParams,
      sortColumn: orderColumn,
      sortType: orderDirection,
      ordering: getSortedColumnName(orderColumn, orderDirection),
    })
  }
  const handleSeach = (key?: string) => {
    setPageParams({
      ...pageParams,
      search: key as string,
      page: 1,
    })
  }
  const handleOnSlect = (rows: any) => {
    setSelectedRows([...rows])
  }

  const basicData = {
    title: 'Order Details',
    icon: 'institution',
  }

  useEffect(() => {
    handleDatepicker()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterProps.endDate])

  const handleDatepicker = useCallback(() => {
    setPageParams({
      ...pageParams,
      filters: {
        ...filters,
        fromDate: filterProps?.startDate
          ? moment(filterProps?.startDate).format('DD-MM-YYYY')
          : '',
        toDate: filterProps?.endDate
          ? moment(filterProps.endDate).format('DD-MM-YYYY')
          : '',
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterProps.endDate])

  const [columns, setColumns] = useState<any>(() =>
    getColumns({
      onViewAction: onViewAction,
      statusClassGen: statusClassGen,
    })
  )

  const handleResetColums = () => {
    setColumns([
      ...getColumns({
        onViewAction: onViewAction,
        statusClassGen: statusClassGen,
      }),
    ])
  }

  const activity = {
    hideExport: true,
  }

  const handleCreate = () => {
    setType(1)
    setDialogModalActive(true)
    handleReject
  }
  const handleClose = () => {
    methods.reset()
    setType(0)
    setDialogModalActive(false)
  }
  const handleRevertSubmit = () => {
    setType(1)
    const payload = {
      id: param?.id,
      status: 'reverted',
      message: methods.watch().message ? methods.watch().message : '',
    }
    mutate(payload)
  }
  const handleReject = () => {
    setType(2)
    setDialogRejectModalActive(true)
    handleCreate
  }
  const handleRejectClose = () => {
    methods.reset()
    setType(0)
    setDialogRejectModalActive(false)
  }
  const handleRejectConfirmClose = () => {
    setDialogRejectConfirm(false)
  }
  const handleRejectPopUpClose = () => {
    setRejectModalActive(false)
  }
  const handleRejectPopUp = () => {
    setType(2)
    const payload = {
      id: param?.id,
      status: 'rejected',
      message: methods.watch().message ? methods.watch().message : '',
    }
    mutate(payload)
  }
  const handleConfirmReject = () => {
    setDialogRejectModalActive(false)
    setDialogRejectConfirm(true)
  }

  const handleSubmit = () => {
    setType(0)
    const payload = {
      id: param?.id,
      status: 'confirmed',
      // message : methods.watch().message ? methods.watch().message : ""
    }
    mutate(payload)
  }
  const handleCloseModal = () => {
    setSuccessModalActive(false)
  }

  console.log(data)
  const handleCloseRevertModal = () => {
    setRevertModalActive(false)
    navigate('/order/pending')
  }
  // console.log(methods.watch())
  return (
    <>
      <ListingHeader
        data={basicData}
        actionProps={activity}
        setCustomBack={setCustomBack}
      />
      <div className=" px-[24px] py-[24px]">
        <div className="">
          <div className="flex flex-col md:flex-row gap-4 justify-between  items-start">
            <div>
              <div className="flex gap-4 justify-start  h-[103px] items-start">
                <div className="w-[80px] h-[80px] rounded-full ">
                  <img
                    src={data?.institution_profile}
                    alt=""
                    className="w-full h-full object-cover rounded-full "
                  />
                </div>
                <div className=" sm:text-xs  font-normal leading-normal">
                  <div className="text-2xl text-primary">
                    {data?.institution_name}
                  </div>
                  <div className="my-1">{data?.stream}</div>
                  <div className="text-AvailabilityMark">
                    {data?.district_name}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col 2xl:flex-row justify-between  xl:items-center 2xl:gap-2 gap-4">
              {data?.status === 'delivered' && (
                <div className="flex gap-4 justify-end h-full">
                  <Button
                    label={'Confirm'}
                    onClick={handleSubmit}
                    className=""
                  />
                  {/* <Button
                    label={'Revert'}
                    primary={true}
                    outlined
                    className=""
                    onClick={handleCreate}
                  />
                  <div className="border-2 border-MandatoryRed/15 cursor-pointer rounded-[10px]  h-[42px] w-[40px]  flex justify-center items-center">
                    <Icons name="delete" onClick={handleReject} />
                  </div> */}
                  {dialogModalActive && (
                    <DialogModal
                      isOpen={true}
                      body={
                        <div className="my-8 mx-8">
                          <h2 className="flex text-center w-full mx-auto justify-start text-lime-500 text-2xl font-medium">
                            Enter Revert Message
                          </h2>
                          <div className="grid grid-cols-1">
                            <FormProvider {...methods}>
                              <CommonForm edit={true} />
                            </FormProvider>
                          </div>
                          <div className="flex gap-8 justify-center my-3">
                            <Button
                              onClick={handleRevertSubmit}
                              label={'Submit'}
                            />
                          </div>
                        </div>
                      }
                      onClose={handleClose}
                      // title={'Add New Pharmacist'}
                    />
                  )}
                  {dialogRejectModalActive && (
                    <DialogModal
                      isOpen={true}
                      body={
                        <div className="my-8 mx-8">
                          <h2 className="flex text-center w-full mx-auto justify-start text-lime-500 text-2xl font-medium">
                            Enter Reject Message
                          </h2>
                          <div className="grid grid-cols-1">
                            <FormProvider {...methods}>
                              <CommonForm edit={true} />
                            </FormProvider>
                          </div>
                          <div className="flex gap-8 justify-center my-3">
                            <Button
                              // onClick={methods.handleSubmit}
                              onClick={handleConfirmReject}
                              label={'Proceed'}
                            />
                          </div>
                        </div>
                      }
                      onClose={handleRejectClose}
                      // title={'Add New Pharmacist'}
                    />
                  )}
                  {dialogRejectConfirm && (
                    <DialogModal
                      isOpen={true}
                      body={
                        <>
                          <div className="p-10">
                            <div className="font-medium leading-8 text-xl flex justify-center ">
                              Do you really want to reject the order
                            </div>
                            <div className="flex justify-center my-5">
                              <Icons
                                name="trash"
                                className="w-[200px] h-[150px]"
                              />
                            </div>
                            <div className="flex justify-center gap-4 mt-10">
                              <Button
                                label={'Yes'}
                                primary={false}
                                type="reset"
                                className="bg-AvailabilityMark/40 text-AvailabilityMark"
                                onClick={handleRejectPopUp}
                              />
                              <Button
                                label={'No'}
                                primary={false}
                                type="reset"
                                className="bg-RemoveButton/40 text-RemoveButton"
                                onClick={handleRejectConfirmClose}
                              />
                            </div>
                          </div>
                        </>
                      }
                      onClose={handleRejectClose}
                      // title={'Add New Pharmacist'}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
          <hr />
          <div className="w-100 py-[24px]">
            <div className="flex gap-4 justify-between w-100">
              <div className=" lg:text-xs text-[10px] px-1 2xl:px-2 font-normal leading-normal align-middle h-[40px]	text-SidenavFontColor">
                <div className="font-bold text-black">Request Status</div>
                <div>
                  {' '}
                  <>
                    {data?.status === 'pending' ? (
                      <span className="inline-block  text-ShortageStatus  font-medium leading-normal">
                        Pending
                      </span>
                    ) : data?.status === 'confirmed' ? (
                      <span className="inline-block  text-primary  font-medium leading-normal">
                        Confirmed
                      </span>
                    ) : data?.status === 'delivered' ? (
                      <span className="inline-block  text-AvailabilityMark  font-medium leading-normal">
                        Replaced
                      </span>
                    ) : data?.status === 'reverted' ? (
                      <span className="inline-block  text-RevertedStatus  font-medium leading-normal">
                        Reverted
                      </span>
                    ) : (
                      <span className="inline-block  text-RemoveButton  font-medium leading-normal">
                        Rejected
                      </span>
                    )}
                  </>
                </div>
              </div>
              <div className=" lg:text-xs text-[10px] px-5 2xl:px-8 font-normal leading-normal align-middle h-[40px]	text-SidenavFontColor">
                <div className="font-bold text-black">Total Items</div>
                <div>{data?.order_items.length} items</div>
              </div>
              <div className=" lg:text-xs text-[10px] px-5 2xl:px-8 font-normal leading-normal align-middle h-[40px]	text-SidenavFontColor">
                <div className="font-bold text-black">Total Amount</div>
                <div>{data?.total_amount}</div>
              </div>
              <div className=" lg:text-xs text-[10px]  font-normal leading-normal align-middle h-[40px]	text-SidenavFontColor">
                <div className="font-bold text-black">Applied On</div>
                <div>
                  {data?.created_at
                    ? moment(data?.created_at).format('DD-MM-YYYY')
                    : 'NA'}
                </div>
              </div>
            </div>
          </div>
          <hr />
          {/* <PageTiles data={dashtiledata} handleTileClick={handleTileClick} /> */}
          <div className=" pt-[24px]">
            <QbsTable
              columns={columns}
              dataRowKey="id"
              selectedRows={selectedRows}
              // selection={true}
              isLoading={isFetching}
              toolbar
              search
              handleColumnSort={handleSort}
              height={calcWindowHeight(589)}
              data={replaceData?.replacements ?? []}
              handleResetColumns={() => handleResetColums()}
              // sortType={sortType}
              // sortColumn={sortColumn}
              pagination
              paginationProps={{
                onPagination: onChangePage,
                total: data?.total_count,
                currentPage: pageParams?.page,
                rowsPerPage: Number(pageParams?.page_size ?? data?.page_size),
                onRowsPerPage: onChangeRowsPerPage,
                dropOptions: [10, 20, 30, 50, 100, 200],
              }}
              // primaryFilter={
              //   <PrimaryFilterComponent
              //     filterParams={filterDetails}
              //     handleFilterChange={handleFilterChange}
              //     filterProps={filterProps}
              //     // advanceFilter={advanceFilter}
              //     handleFilterDateChange={setCustomType}
              //     isPrimary={true}
              //     // setAdvanceFilter={setAdvanceFilter}
              //   />
              // }
              // advancefilter={
              //   showDetail && (
              //     <PrimaryFilterComponent
              //       filterParams={filterDetails}
              //       handleFilterDateChange={handleFilterDateChange}
              //       handleFilterChange={handleFilterChange}
              //       filterProps={filterProps}
              //       isPrimary={false}
              //       advanceFilter={advanceFilter}
              //     />
              //   )
              // }
              actionProps={
                [
                  // {
                  //   title: 'View',
                  //   action: (rowData) => navigate(onViewAction(rowData)),
                  //   icon: <Icons name="eye" />,
                  //   toolTip: 'View',
                  // },
                ]
              }
              // selectedRowActions={[
              //   {
              //     actionTitle: 'Merge',
              //     action: () => handleMerge(),
              //     customHide: '>2',
              //   },
              // ]}
              searchValue={pageParams?.search}
              onSearch={handleSeach}
              asyncSearch
              handleSearchValue={(key?: string) => handleSeach(key)}
              onSelect={handleOnSlect}
              columnToggle
            />
          </div>
          {successModalActive && (
            <ConfirmModal
              isOpen={true}
              body={
                <>
                  <div className="font-medium text-primary leading-8 text-xl">
                    Order Confirmed
                  </div>
                  {/* <div className="leading-normal">Order ID 234567</div> */}
                </>
              }
              topIcon="check-circle"
              onClose={handleCloseModal}
              // title={'Add New Pharmacist'}
            />
          )}
          {revertModalActive && (
            <ConfirmModal
              isOpen={true}
              body={
                <>
                  <div className="font-medium text-primary leading-8 text-xl">
                    Order Reverted Successfully
                  </div>
                  {/* <div className="leading-normal">Order ID 234567</div> */}
                </>
              }
              topIcon="check-circle"
              onClose={handleCloseRevertModal}
              // title={'Add New Pharmacist'}
            />
          )}
          {rejectModalActive && (
            <ConfirmModal
              isOpen={true}
              body={
                <>
                  <div className="font-medium text-primary leading-8 text-xl">
                    The Order is Rejected.
                  </div>
                </>
              }
              topIcon="check-circle"
              onClose={handleRejectPopUpClose}
              // title={'Add New Pharmacist'}
            />
          )}
        </div>
      </div>
    </>
  )
}
export default OrderRequestDetailReplaced
