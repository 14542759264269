// import FormBuilder from '../../../components/app/formBuilder/index'
import FormBuilder from '../../../../../../components/app/formBuilder'
import { useLocation } from '../../../../../contacts/create/api'
// import { useDoctors } from '../api'
type Props = {
  edit?: boolean
  update?: boolean
  create?: boolean
}
// const CommonForm = (data?: any) => {
const EditForm = ({ edit, update, create }: Props) => {
  const { data } = useLocation()

  // const { data: doctors } = useDoctors()
  // console.log(doctors)
  // const { data: doctorsList } = useDoctors()
  // const doctorsList: any = [
  //   {
  //     id: 1,
  //     name: 'Shibu',
  //   },
  // ]
  // const specialization = [
  //   { id: 1, name: 'Medical Officer' },
  //   {
  //     id: 2,
  //     name: 'Specialist Medical Officer',
  //   },
  // ]
  const gender: any = [
    {
      id: 1,
      name: 'Male',
    },
    {
      id: 2,
      name: 'Female',
    },
    {
      id: 3,
      name: 'Genderqueer/Non-Binary',
    },
    {
      id: 4,
      name: 'Trans Male',
    },
    {
      id: 5,
      name: 'Trans Female',
    },
  ]
  const textField = (
    name: string,
    label: string,
    id: string,
    type: string,
    // placeholder: string,
    required = false,
    // disabled = update ,
    config: { data?: Array<{ name: string; id: string }> } = {}
  ) => ({
    name,
    label,
    id,
    type,
    // placeholder,
    ...(required ? { required: true } : {}),
    ...config,
    spacing: 4,
  })
  const formBuilderProps = [
    // {
    //   ...textField('id', 'Unique ID', 'id', 'text', false),
    // },
    {
      ...textField('name', 'Name', 'name', 'text', false),
      disabled: update,
    },
    // {
    //   ...textField(
    //     'date_of_birth',
    //     'Date of Birth',
    //     'date_of_birth',
    //     'date',
    //     false
    //   ),
    //   disabled: update,
    // },
    {
      ...textField('age', 'Age', 'age', 'text', false),
    },
    {
      ...textField('phone', 'Phone Number', 'phone', 'number', false),
      disabled: update,
    },
    {
      ...textField(
        'district_name',
        'District',
        'district_id',
        'custom_select',
        true,
        {
          data: data?.districts,
        }
      ),
      disabled: update,
      placeholder: 'Choose One',
    },

    {
      ...textField('gender', 'Gender', 'gender', 'custom_select', true, {
        data: gender,
      }),
      disabled: update,
      placeholder: 'Choose One',
    },
    {
      ...textField('address', 'Address', 'address', 'text', false),
      disabled: update,
    },
    {
      ...textField('aadhar_number', 'Aadhar Number', 'aadhar_number', 'text', false),
      disabled: update,
    },
    // {
    //   ...textField(
    //     'specialization',
    //     'Specialization',
    //     'specialization',
    //     'text',
    //     false
    //   ),
    // },
    // {
    //   ...textField('doctor', 'Doctor', 'doctor_id', 'custom_select', false, {
    //     data: doctors?.doctors,
    //   }),
    //   placeholder: 'Choose One',
    //   disabled: create,
    // },
    {
      ...textField('remarks', 'Remarks', 'remarks', 'text', false),
      disabled: create,
    },
  ]

  // methods.reset({})

  return <FormBuilder data={formBuilderProps} edit={edit} />
}
export default EditForm
