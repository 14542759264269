const defaultColumnProps1 = {
  sortable: false,
  isVisible: true,
  resizable: true,
}
// const statusInfo: 1 | 2 = 1

export const getColumns = ({}: any) => {
  const column = [
    // {
    //   title: 'Sl.No',
    //   field: 'si_no',
    //   ...defaultColumnProps1,
    //   // fixed: true,
    //   sortKey: 'id',
    //   // rowClick: (row: any) => onViewAction(row, 'contactName'),
    //   // link: true,

    //   customCell: true,
    // },

    {
      title: 'Medicine Name',
      // renderCell: createRenderCell('cre_agent.name'),
      field: 'medic_name',
      ...defaultColumnProps1,
      // customCell: true,
      // sortKey: 'cre_agent_user_first_name',

      customCell: true,
    },
    {
      title: 'Manufacturer Name',
      // renderCell: createRenderCell('cre_agent.name'),
      field: 'manufacturer_name',
      ...defaultColumnProps1,
      // customCell: true,
      // sortKey: 'cre_agent_user_first_name',

      customCell: true,
    },
    {
      title: 'Unit of Packing',
      field: 'unit_of_packing',
      ...defaultColumnProps1,

      customCell: true,
    },
    {
      title: 'Price/Unit',
      field: 'price',
      ...defaultColumnProps1,

      sortKey: '',

      customCell: true,
    },
    {
      title: 'Tax',
      field: 'tax',
      ...defaultColumnProps1,

      sortKey: '',

      customCell: true,
    },
    {
      title: 'Requested Quantity',
      field: 'quantity',
      ...defaultColumnProps1,

      sortKey: '',

      customCell: true,
    },
    {
      title: 'Total Amount',
      field: 'item_total_cost',
      ...defaultColumnProps1,
      sortKey: '',

      customCell: true,
    },
  ]

  return column
}
