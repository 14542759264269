import { Navigate, Outlet } from 'react-router-dom'
import { useAuthStore } from '../../store/authStore'

type Props = {
  children: React.ReactNode
}

const GuestRoute = ({ children }: Props) => {
  const authenticated = useAuthStore((state: any) => state.authenticated)

  return !authenticated ? (
    <>{children}</> || <Outlet />
  ) : (
    <Navigate to="/dashboard" replace />
  )
}

export default GuestRoute
