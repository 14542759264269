import React, { useState } from 'react'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'
import { Link } from 'react-router-dom'

import Icons from '../components/common/icons'
import { router_config, RouterMenuProps } from '../configs/route.config'
import { useAppStore } from '../store/appStore'
import { useClearFilter } from '../store/filterSore/clearStore'
import { useAuthStore } from '../store/authStore'

const generateArray = (m: {
  [key: string]: RouterMenuProps
}): RouterMenuProps[] => {
  return Object.keys(m).map((k) => {
    const obj = m[k]
    return { ...obj, slug: k }
  })
}

const SideNav = () => {
  const { activeRouteSlug } = useAppStore()
  const { role } = useAuthStore()
  const [sideMenu, setSideMenu] = useState<RouterMenuProps[]>(
    generateArray(router_config)
  )
  const handleClear = useClearFilter()
  const handleMangeExpansion = (e: any, menu: any) => {
    e.stopPropagation()
    console.log(menu)
    // setMenuOpened(true)
    setSideMenu(
      sideMenu.map((item) => {
        console.log(item)
        return item.id === menu.id
          ? { ...item, isExpanded: !item.isExpanded }
          : item
      })
    )
  }
  const generateClassNames = (l: number): string => {
    const mod = l % 5
    switch (mod) {
      case 4:
        return 'pl-5'
      case 3:
        return 'pl-4'
      case 2:
        return 'pl-3'
      case 1:
        return 'pl-2'
      default:
        return 'pl-2'
    }
  }
  const handleClearFilters = () => {
    handleClear()
  }
  const checkPermission = (item: any) => {
    return item.permission_slugs.some((pr: any) => pr === role)
  }
  const renderFuncs = (mitem: RouterMenuProps, level: number): any => {
    return (
      <>
        {sideMenu.map((item) => {
          return (
            <React.Fragment key={item.label}>
              {item.parent_id === mitem.id &&
                item.isSidebarMenu &&
                checkPermission(item) && (
                  <>
                    {item?.hasChild ? (
                      <li>
                        <div
                          title={item?.label}
                          key={level}
                          onClick={(e) => {
                            handleMangeExpansion(e, item)
                          }}
                          className={`  flex items-center justify-start px-4 py-4
                          text-sm transition-transform transform translate-x-0 gap-2 font-medium  rounded-md  text-icontextColor hover:bg-SidenavExpandedColor `}
                        >
                          {item?.icon ? (
                            <div
                              className={` transition-all ${
                                item?.isExpanded ? 'dropIconActive' : ''
                              }`}
                            >
                              <Icons
                                className="menuIconWidth"
                                name={`${item?.icon}`}
                              />
                            </div>
                          ) : (
                            <span
                              className={` iconBox ${
                                item?.isExpanded ? 'iconBox-active' : ''
                              }`}
                            >
                              {item?.label[0]}
                            </span>
                          )}
                          <h2
                            style={{
                              transitionDelay: `${level + 3}00ms`,
                            }}
                            className={`transition-all overflow-hidden text-ellipsis flex
                          ${item?.isExpanded ? 'text-primary' : ''}`}
                          >
                            {item?.label}
                          </h2>
                          <div
                            className={`absolute right-0 px-[5px]  flex cursor-pointer`}
                          >
                            {item?.isExpanded ? (
                              <BsChevronUp
                                className={`transition-all ${
                                  item?.isExpanded ? 'text-primary' : ''
                                }`}
                                size={16}
                              />
                            ) : (
                              <BsChevronDown
                                className={`transition-all ${
                                  item?.isExpanded ? 'text-primary' : ''
                                }`}
                                size={16}
                              />
                            )}
                          </div>
                        </div>
                        <>
                          {item.isExpanded && (
                            <ol
                              className={`${generateClassNames(
                                level
                              )}  rounded-lg flex flex-col gap-1 mt-1`}
                            >
                              {renderFuncs(item, level + 1)}
                            </ol>
                          )}
                        </>
                      </li>
                    ) : (
                      <>
                        <li>
                          <Link
                            title={item?.label}
                            to={item?.pathOverride ?? (item?.path as string)}
                            onClick={() => handleClearFilters()}
                            className={` flex items-center justify-start pl-8 py-4 h-12
                               text-sm  gap-2 font-normal  rounded-md  text-ellipsis text-black text-[16px] hover:text-primary ${
                                 (activeRouteSlug &&
                                   activeRouteSlug === item?.slug) ||
                                 item?.slugOptions?.some(
                                   (s) => s === activeRouteSlug
                                 )
                                   ? 'text-primary '
                                   : ''
                               } `}
                          >
                            {item?.icon ? (
                              <div
                                className={`${
                                  (activeRouteSlug &&
                                    activeRouteSlug === item.slug) ||
                                  item?.slugOptions?.some(
                                    (s) => s === activeRouteSlug
                                  )
                                    ? 'iconActive'
                                    : ''
                                }  text-19`}
                              >
                                <Icons
                                  className="menuIconWidth"
                                  name={`${item?.icon}`}
                                />
                              </div>
                            ) : (
                              <span
                                className={` iconBox ${
                                  (activeRouteSlug &&
                                    activeRouteSlug === item.slug) ||
                                  item?.slugOptions?.some(
                                    (s) => s === activeRouteSlug
                                  )
                                    ? 'iconBox_bg-active'
                                    : ''
                                }`}
                              >
                                {item?.label[0]}
                              </span>
                            )}
                            <h2
                              className={`overflow-hidden text-ellipsis flex`}
                            >
                              {item?.label}
                            </h2>
                          </Link>
                        </li>
                      </>
                    )}
                  </>
                )}
            </React.Fragment>
          )
        })}
      </>
    )
  }
  // const toggeleSideBar = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
  //   e.stopPropagation()
  //   setMenuOpened(!menuOpened)
  // }

  return (
    <section className="flex bg-bgSidenavColor relative border-r md:w-1/6 w-screen md:min-w-fit">
      <div
        className={`group min-h-full p-7 flex gap-5 items-start flex-col text-gray-100 md:w-full w-screen`}
      >
        {/* <div
          className={`flex items-center w-full h-[54px] ${
            'justify-start' : 'justify-center '
          }`}
        >
          <img
            alt="logo"
            src={'/logo_light.png' : '/logo_short_light.png'}
          />
        </div> */}
        {/* <div
          className="absolute cursor-pointer bg-white -right-3 top-16 w-6 h-6 rounded-[42px] border border-[#D6D8DC] z-50 flex items-center justify-center"
          onClick={(e) => toggeleSideBar(e)}
        >
          <Icons
            className={!'rotate-180' : ''}
            name={'sidebar-arrow'}
          />
        </div> */}
        <ol
          className={`flex flex-col gap-5 relative overflow-y-auto whitespace-nowrap overflow-ellipsis customScroll w-full `}
        >
          {sideMenu.map((menu_item, i) => {
            return (
              <React.Fragment key={i}>
                {menu_item.isSidebarMenu &&
                  checkPermission(menu_item) &&
                  !menu_item?.parent_id && (
                    <>
                      {menu_item?.hasChild ? (
                        <li className="">
                          <div
                            title={menu_item?.label}
                            onClick={(e) => {
                              handleMangeExpansion(e, menu_item)
                            }}
                            className={` flex items-center justify-start px-3 py-3 h-12
                              gap-4 font-normal  rounded-md  text-ellipsis text-black text-19  hover:bg-SidenavExpandedColor truncate`}
                          >
                            {menu_item?.icon ? (
                              <div
                                className={`transition-all  ${
                                  menu_item?.isExpanded ? 'iconActive ' : ''
                                }`}
                              >
                                <Icons
                                  className="text-19 text-black"
                                  // className="menuIconWidth iconWhite"
                                  name={`${menu_item?.icon}`}
                                />
                              </div>
                            ) : (
                              <span
                                className={` iconBox ${
                                  menu_item?.isExpanded ? 'iconBox-active' : ''
                                }`}
                              >
                                {menu_item?.label[0]}
                              </span>
                            )}
                            <h2
                              className={`transition-all overflow-hidden text-ellipsis 
                     flex items-center ${
                       menu_item?.isExpanded ? 'text-primary' : ''
                     }`}
                            >
                              {menu_item?.label}
                            </h2>
                            <div
                              className={`absolute right-1.5 px-[5px]  cursor-pointer  flex`}
                            >
                              {menu_item?.isExpanded ? (
                                <BsChevronUp
                                  className={`transition-all ${
                                    menu_item?.isExpanded ? 'text-primary' : ''
                                  }`}
                                  size={16}
                                />
                              ) : (
                                <BsChevronDown
                                  className={`transition-all ${
                                    menu_item?.isExpanded ? 'text-primary' : ''
                                  }`}
                                  size={16}
                                />
                              )}
                            </div>
                          </div>

                          {menu_item.isExpanded && (
                            <ol className={` flex flex-col gap-4 pl-6 pt-3`}>
                              {renderFuncs(menu_item, 1)}
                            </ol>
                          )}
                        </li>
                      ) : (
                        <li key={i}>
                          <Link
                            title={menu_item?.label}
                            onClick={() => handleClearFilters()}
                            to={
                              menu_item?.pathOverride ??
                              (menu_item?.path as string)
                            }
                            className={`h-12  flex items-center justify-start font-normal px-3 py-3 rounded-md  text-icontextColor ${
                              (activeRouteSlug &&
                                activeRouteSlug === menu_item.slug) ||
                              menu_item?.slugOptions?.some(
                                (s) => s === activeRouteSlug
                              )
                                ? 'bg-SidenavExpandedColor hover:bg-SidenavExpandedColor '
                                : 'hover:bg-SidenavExpandedColor '
                            }`}
                          >
                            {menu_item?.icon && (
                              <div
                                className={`w-[45px]  ${
                                  (activeRouteSlug &&
                                    activeRouteSlug === menu_item.slug) ||
                                  menu_item?.slugOptions?.some(
                                    (s) => s === activeRouteSlug
                                  )
                                    ? 'iconActive text-primary'
                                    : ''
                                }`}
                              >
                                <Icons
                                  className="text-19 text-black"
                                  name={`${menu_item?.icon}`}
                                />
                              </div>
                            )}
                            <h2
                              style={{
                                transitionDelay: `${i + 3}00ms`,
                              }}
                              className={`overflow-hidden text-ellipsis ${
                                (activeRouteSlug &&
                                  activeRouteSlug === menu_item.slug) ||
                                menu_item?.slugOptions?.some(
                                  (s) => s === activeRouteSlug
                                )
                                  ? 'text-authFormBorder'
                                  : 'text-black'
                              } text-19 flex`}
                            >
                              {menu_item?.label}
                            </h2>
                          </Link>
                        </li>
                      )}

                      {menu_item.hasDivider && (
                        <div className="border-t border-secondary w-full my-1" />
                      )}
                    </>
                  )}
              </React.Fragment>
            )
          })}
        </ol>
      </div>
    </section>
  )
}

export default SideNav
