import { z } from 'zod'

import noLeadingSpaces from '../../../utilities/noLeadingSpaces'
const MAX_FILE_SIZE = 5000000

const ACCEPTED_IMAGE_TYPES = ['image/jpeg', 'image/jpg', 'image/png']
export const formSchema = z.object({
  name: z
    .string({ invalid_type_error: 'Name is required.' })
    .min(1, { message: 'Name is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  address: z
    .string({ invalid_type_error: 'Address is required.' })
    .min(1, { message: 'Address is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  district_id: z.number(),
  district_name: z.string().refine(noLeadingSpaces, {
    message: 'Leading spaces are not allowed',
  }),

  code: z
    .string({ invalid_type_error: 'Code is required.' })
    .min(1, { message: 'Code is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  stream: z
    .string({ invalid_type_error: 'Stream is required.' })
    .min(1, { message: 'Stream is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  lok_sabha_constituency_name: z
    .string({ invalid_type_error: 'Lok Sabha constituency is required.' })
    .min(1, { message: 'Lok Sabha constituency is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  lok_sabha_constituency_id: z.number(),
  block: z
    .string({ invalid_type_error: 'Block is required.' })
    .min(1, { message: 'Block is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  taluk: z
    .string({ invalid_type_error: 'Taluk is required.' })
    .min(1, { message: 'Taluk is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  village: z
    .string({ invalid_type_error: 'Village is required.' })
    .min(1, { message: 'Village is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  lsgd: z
    .string({ invalid_type_error: 'Name of LSGD is required.' })
    .min(1, { message: 'Name of LSGD is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  lsgd_type: z
    .string({ invalid_type_error: 'LSGD type is required.' })
    .min(1, { message: 'LSGD type is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  building_type: z
    .string({ invalid_type_error: 'Building type is required.' })
    .min(1, { message: 'Building type is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  institution_type: z
    .string({ invalid_type_error: 'Institution type is required.' })
    .min(1, { message: 'Institution type is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  assembly_constituency_name: z
    .string({ invalid_type_error: 'Assembly constituency  type is required.' })
    .min(1, { message: 'Assembly constituency  type is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  assembly_constituency_id: z.number(),
  geographical_info: z
    .string({
      invalid_type_error: 'Geographical Information  type is required.',
    })
    .min(1, { message: 'Geographical Information type is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  ward_name_and_number: z
    .string({ invalid_type_error: 'Ward name and number  type is required.' })
    .min(1, { message: 'Ward name and number  type is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),

  phone_number: z
    .string()
    .nullable()
    .transform((value) => (value === null ? '' : value))
    .refine(
      (value) => {
        return (
          (/^\d+(?:\.\d{0,10})?$/.test(value) &&
            value.length <= 10 &&
            value.length >= 10 &&
            parseFloat(value) !== 0) ||
          value === ''
        )
      },
      {
        message: 'Enter a Valid Mobile Number',
      }
    ),
  land_number: z
    .string()
    .nullable()
    .transform((value) => (value === null ? '' : value))
    .refine(
      (value) => {
        return (
          (/^\d+(?:\.\d{0,11})?$/.test(value) &&
            value.length <= 11 &&
            value.length >= 11 &&
            parseFloat(value) !== 0) ||
          value === ''
        )
      },
      {
        message: 'Enter a Valid Mobile Number',
      }
    ),
  email: z
    .string({ required_error: 'Enter Email' })
    .min(5, 'Enter a valid Email')
    .superRefine((value: any, ctx: any) => {
      if (value && value !== '') {
        if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Invalid email format.',
          })
        }
      }
    })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  state: z.string().optional().nullable(),
  image: z
    .instanceof(File, { message: 'Attachment required' })
    .refine((files: File) => files.size <= MAX_FILE_SIZE, {
      message: 'Maximum size 5mb',
    })
    .refine((files: File) => ACCEPTED_IMAGE_TYPES.includes(files?.type), {
      message: 'File type not allowed',
    }),
})

export type CreateSchema = z.infer<typeof formSchema>

// assembly_constituency_id: z.string(),
// pan_no: z
//   .string()
//   .refine((value) => value === '' || /^[A-Z]{5}\d{4}[A-Z]{1}$/.test(value), {
//     message: 'Invalid PAN number format',
//   })
//   .refine((value) => value === '' || noLeadingSpaces(value), {
//     message: 'Leading spaces are not allowed',
//   })
//   .optional(),

// stateId: z.string().optional().nullable(),
// code: z.string().optional().nullable(),
// city: z.string().min(1, 'City is required.').refine(noLeadingSpaces, {
//   message: 'Leading spaces are not allowed',
// }),
// address_1: z.string().optional().nullable(),
// address_2: z.string().optional().nullable(),
// country: z.string().min(1, 'Country is required.'),
// countryId: z.string().optional().nullable(),

// pin_code: z.string().optional().nullable(),
// phone_number: z
// .string()
// .refine((value) => value === '' || /^(\+?\d{10,12})$/.test(value), {
//   message: 'Invalid mobile number.',
// })
// .optional(),
