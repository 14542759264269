import React from 'react'
import { IconProps } from '../../../common/types'

const BadgeCheckIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.54413 10.9653L9.21079 12.6319L12.5441 9.2986M6.57308 4.87948C7.17102 4.83176 7.73868 4.59663 8.19523 4.20756C9.26063 3.29963 10.8276 3.29963 11.893 4.20756C12.3496 4.59663 12.9172 4.83176 13.5152 4.87948C14.9105 4.99083 16.0186 6.09886 16.1299 7.49422C16.1776 8.09216 16.4128 8.65982 16.8018 9.11637C17.7098 10.1818 17.7098 11.7488 16.8018 12.8142C16.4128 13.2707 16.1776 13.8384 16.1299 14.4363C16.0186 15.8317 14.9105 16.9397 13.5152 17.0511C12.9172 17.0988 12.3496 17.3339 11.893 17.723C10.8276 18.6309 9.26063 18.6309 8.19523 17.723C7.73868 17.3339 7.17102 17.0988 6.57308 17.0511C5.17772 16.9397 4.06968 15.8317 3.95833 14.4363C3.91062 13.8384 3.67549 13.2707 3.28642 12.8142C2.37849 11.7488 2.37849 10.1818 3.28642 9.11637C3.67549 8.65982 3.91062 8.09216 3.95833 7.49422C4.06969 6.09886 5.17772 4.99083 6.57308 4.87948Z"
        stroke="#313131"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default BadgeCheckIcon
