import moment from 'moment'

const defaultColumnProps1 = {
  sortable: false,
  isVisible: true,
  resizable: true,
}
export const getColumns = ({}: any) => {
  const createRenderCell = (key: string, isCustom?: string) => (row: any) => {
    if (isCustom === 'status') {
      return {
        cell: <>{moment(row?.date).format('DD-MM-YYYY')}</>,
      }
    }
  }

  const column = [
    // {
    //   title: 'Sl.No',
    //   field: 'id',
    //   ...defaultColumnProps1,
    //   fixed: true,
    //   sortKey: 'id',
    //   // rowClick: (row: any) => onViewAction(row, 'contactName'),
    //   // link: true,
    // },
    {
      title: 'Transaction ID',
      field: 'transaction_id',
      fixed: true,
      ...defaultColumnProps1,
      // link: true,
      // sortKey: 'default_contact__first_name',
      // rowClick: (row: any) => onViewAction(row, 'contactName'),
    },
    {
      title: 'Fund Received From',
      // renderCell: createRenderCell('cre_agent.name'),
      field: 'contributor_name',
      ...defaultColumnProps1,
      // customCell: true,
      // sortKey: 'cre_agent_user_first_name',
    },
    {
      title: 'Date',

      ...defaultColumnProps1,
      customCell: true,
      renderCell: createRenderCell('default_contact.mobile', 'status'),
      sortKey: 'id',
    },
    {
      title: 'Amount Received',
      field: 'amount',
      ...defaultColumnProps1,
      // link: true,
      sortKey: '',
      // renderCell: createRenderCell('primary_company.business_name'),
      // rowClick: (row: any) => onViewAction(row, 'business_name'),
    },
    // {
    //   title: 'Status',
    //   renderCell: createRenderCell('default_contact.mobile'),
    //   field: 'mobile',
    //   ...defaultColumnProps1,
    //   customCell: true,
    //   // link: true,
    //   // getpath: () => '/dashboard',
    // },
    // {
    //   title: 'Action',
    //   renderCell: createRenderCell('default_contact.mobile', 'action'),
    //   // field: 'mobile',
    //   ...defaultColumnProps1,
    //   customCell: true,
    // },
  ]

  return column
}
