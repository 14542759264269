import { Button } from '../../../../components/common'
import { FormProvider, useForm } from 'react-hook-form'
import EditForm from './edit_form'
import { CreateSchema, formSchema } from './schema'
import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect } from 'react'
import { useUpdateStocks } from './api'
type ManageStockProps = {
  handlSubmitEdit: () => void
  handleEditClose: () => void
  data: any
}
const StockEdit: React.FC<ManageStockProps> = ({
  handlSubmitEdit,
  handleEditClose,
  data,
}) => {
  const stock_id = data.id
  const onSuccess = () => {
    handlSubmitEdit()
  }
  const { mutate } = useUpdateStocks(onSuccess)
  const methods = useForm<CreateSchema>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  useEffect(() => {
    if (data) {
      // console.log(data)
      methods.reset({
        ...data,
        batch_number: String(data?.batch_number),
        name: data?.medicine_name,
      })
    }
    // console.log(data)
  }, [data, methods])

  // console.log(methods.watch())
  // methods.reset({})
  const onSubmit = (payload: CreateSchema) => {
    // setSuccessModalActive(true)
    // handlSubmitEdit(payload)
    // // console.log(payload)
    const data = {
      medicine: {
        name: payload?.name,
        batch_number: payload?.batch_number,
        unit_of_packing: payload?.unit_of_packing,
        stock: payload?.stock,
      },
    }
    mutate({ data, stock_id })
  }
  return (
    <>
      <div className="my-5 mx-8">
        <h2 className="flex text-center w-full mx-auto justify-center text-lime-500 text-2xl font-medium">
          <u>Edit Stock</u>
        </h2>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="grid md:grid-cols-2 md:gap-8 sm:grid-cols-1 sm:gap-[24px] p-4">
            <FormProvider {...methods}>
              <EditForm edit={true} />
            </FormProvider>
          </div>
          <div className="flex gap-8 justify-center my-3">
            <Button
              onClick={handleEditClose}
              label={'Cancel'}
              outlined={true}
            />
            <Button
              // onClick={methods.handleSubmit}
              type="submit"
              label={'Submit'}
            />
          </div>
        </form>
      </div>
    </>
  )
}
export default StockEdit
