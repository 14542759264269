import { useMutation } from '@tanstack/react-query'
// import { AxiosRequestConfig } from 'axios'
import { useSnackbar } from 'notistack'
import {
  getErrorMessage,
  parseQueryParams,
} from '../../../../utilities/parsers'
import apiUrl from '../../../../apis/api.url'
import { getData, postFormData } from '../../../../apis/api.helpers'
import { QueryParams } from '../../../../common/types'
import { useQuery } from '@tanstack/react-query'
// import { usePharmacist } from '../api'

const buildUrlWithParams = (baseUrl: string, params: any) => {
  return `${baseUrl}${parseQueryParams(params)}`
}

const fetchData = async (input: QueryParams) => {
  const url = buildUrlWithParams(apiUrl.PHARMACIST_LIST, {
    ...input,
  })
  const response = await getData(url)
  // console.log(response)
  return response?.pharmacists
}

export const usePharmacist = (input: QueryParams) => {
  return useQuery(['pharmacist_list'], () => fetchData(input), {
    refetchOnWindowFocus: false,
  })
}

export const createPharmacist = (input: any) => {
  return postFormData(apiUrl.PHARMACIST_LIST, input)
}

export const useCreatePharmacist = (handleSubmission: (data: any) => void) => {
  const { enqueueSnackbar } = useSnackbar()
  return useMutation(createPharmacist, {
    onSuccess: (res: any) => {
      handleSubmission(res.data)

      // enqueueSnackbar('Institution Created successfully', { variant: 'success' })
    },

    onError: (error: any) => {
      enqueueSnackbar(
        getErrorMessage(
          error?.response?.data?.message || 'Something went wrong'
        ),
        {
          variant: 'error',
        }
      )
    },
  })
}
