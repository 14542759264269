import axios, {
  AxiosResponse,
  AxiosError,
  InternalAxiosRequestConfig,
} from 'axios'
import { useAuthStore } from '../store/authStore'

const serverApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
})

// Response interceptor
serverApi.interceptors.response.use(
  (res: AxiosResponse) => res,
  async (err: AxiosError) => {
    if (axios.isAxiosError(err) && err.response) {
      // ... handle the response error
      if (err.response.status === 401) {
        useAuthStore.getState().clearAuthenticated()
      }
      // console.log(err)
      return Promise.reject(err)
    }

    return Promise.reject(err)
  }
)

// Request interceptor
serverApi.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const token = useAuthStore.getState().token
    config.headers = config.headers || {}

    // if (config.data instanceof FormData) {
    //   config.headers['Content-Type'] = 'multipart/form-data'
    // } else {
    //   config.headers['Content-Type'] = 'application/json'
    // }
    config.headers['Accept'] = 'application/json'
    // config.headers['Content-Type'] = 'multipart/form-data'
    config.headers['Access-Control-Allow-Origin'] = '*'
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }

    return config
  },
  (error: any) => Promise.reject(error)
)

export default serverApi
