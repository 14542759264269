export const duplicateFields = {
  item1: { label: '', field: 'first_name' },
  item2: { label: '', field: 'last_name', newline: true, hideComma: true },
  item3: { label: '', field: 'mobile' },
  item4: { label: '', field: 'mobile_2', newline: true },
  item5: { label: '', field: 'email' },
  item6: { label: '', field: 'email_2', newline: true },
  item7: { label: 'PAN No', field: 'pan_no', newline: true },
  item8: { label: 'Primary Account', field: 'primary_company', newline: true },
}

export const reasons = [
  { label: 'Damage' },
  { label: 'Short shelf life' },
  { label: 'Incorrect product- received' },
  { label: 'Price Discrepancies' },
  { label: 'Others' },
]
