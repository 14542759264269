import React, { useEffect, useRef, useState } from 'react'

import { FileUploadProps } from '../../../common/types'
import Icons from '../icons'

const FileUpload: React.FC<FileUploadProps> = ({
  name,
  id,
  label,
  fullwidth = true,
  type = 'file',
  disabled = false,
  required = false,
  isMultiple = false,
  errors,
  value,
  onChange,
  // supportedFiles,
  // sizeLimit,
  buttonLabel,
  // iconName,
  handleDeleteFile,
}) => {
  const getErrors = (err: any) => {
    let errMsg = ''
    if (err.message) {
      errMsg = err?.message
    }
    return errMsg
  }
  const fileRef = useRef<HTMLInputElement>(null)
  const [file, setFile] = useState<any>(value)
  const [clear, setClear] = useState<any>(true)

  // setTimeout(() => {
  //   setShow(true)
  //   // console.log(value, file, 'file', show)
  // }, 3000)
  useEffect(() => {
    console.log(value)
    console.log(clear)
    setFile(value)
  }, [value])
  const handleClearFile = (indexToRemove?: number, item?: any) => {
    // setFile(null)
    if (fileRef.current) {
      fileRef.current.value = '' // This clears the value of the input
    }
    if (isMultiple) {
      const newFiles = file.filter(
        (_: any, ind: number) => indexToRemove !== ind
      )
      setFile(newFiles)
    } else if (item?.link) {
      handleDeleteFile?.(item)
      onChange?.('')
      setFile('')
    } else {
      onChange?.('')
      setFile('')
    }
    setClear(true)
  }
  const handleFileChange = (e: any) => {
    console.log(e.target.files[0])
    if (e.target.files.length) {
      if (isMultiple) {
        const files = e.target.files
        const existingFiles = file ?? []
        const filesArray = Array.from(files)

        setFile([...existingFiles, ...filesArray])
      } else {
        onChange?.(e)
        setFile(e?.target?.files[0])
      }
    }
  }
  useEffect(() => {
    if (isMultiple) {
      onChange?.(file)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file])
  // console.log(file)
  return (
    <div className={`customFileUpload ${fullwidth ? 'w-full' : 'w-auto'}`}>
      {label && (
        <div className="">
          <label className={`labels label-text`}>
            {label}
            {required ? <span className="text-error"> *</span> : <></>}
          </label>
        </div>
      )}
      <div className="customFileUpload-field relative flex flex-col items-center bg-bgGrey border-dashed border border-formBorder rounded-lg h-12 textfield">
        <input
          id={id}
          ref={fileRef}
          disabled={disabled}
          multiple={isMultiple}
          onInput={(e) => handleFileChange(e)}
          // value={value}
          type={type}
        />
        <label
          className="flex flex-col items-start justify-start gap-2 cursor-pointer w-full max-h-4 pl-2"
          htmlFor={id}
        >
          {buttonLabel}

          {/* <Icons name="question-circle" /> */}

          {/* <p className="text-[#999696] font-bold text-sm">Drag and Drop</p>
          <p className="text-secondary font-bold text-sm uppercase">Or</p> */}
          {/* <div className="relative  rounded-[4px] shadow-buttonShadow  h-7 btn text-xxs p-1 min-w-[46px] btn-primary ">
            <div className="flex items-center justify-center gap-1  m-auto ">
              <Icons className="iconWhite" name={`${iconName}`} />
              <div className="  font-semibold  text-bgWhite  ">
                {buttonLabel}
              </div>
            </div>
          </div> */}
        </label>
        {/* <p className=" text-primaryText text-[10px] leading-4">
          {supportedFiles}{' '}
          <span className="text-primary text-xxs leading-4">
            (Max {sizeLimit} MB)
          </span>
        </p> */}
      </div>
      {errors && errors[name] && (
        <div className="text-error text-error-label mt-[1px]">
          {getErrors(errors[name])}
        </div>
      )}

      <div className="flex flex-col gap-2 mt-4">
        {/* <div className=""> */}
        {Array.isArray(file) &&
          file?.map((item, index: number) => (
            <>
              {item.name && item.name !== null && item.name != undefined && (
                <div
                  key={item.id}
                  className="flex items-center justify-between gap-1.5 px-2.5 py-2 bg-bgGrey rounded-sm "
                >
                  <Icons name="paper-clip" className="iconWidthSm iconBlack" />

                  <p className="flex-1 text-primaryText text-sm font-medium break-all">
                    {item?.name ? item?.name : 'Undefined'}
                  </p>

                  <Icons
                    onClick={() => handleClearFile(index)}
                    name="close"
                    className="iconBlack iconWidthSm cursor-pointer"
                  />
                </div>
              )}
            </>
          ))}
        {typeof file === 'object' && file && file.name && !isMultiple && (
          <div className="flex items-center justify-between gap-1.5 px-2.5 py-3 bg-bgGrey rounded-sm border-dashed border border-formBorder">
            <Icons name="paper-clip" className="iconWidthSm iconBlack" />
            <a
              onClick={() => (file?.link ? window.open(file.link) : '')}
              className="flex-1 text-primaryText text-sm font-medium overflow-hidden cursor-pointer break-all  "
            >
              {file?.name}
            </a>
            <Icons
              name="close"
              onClick={() => handleClearFile(0, file)}
              className="iconBlack iconWidthSm cursor-pointer"
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default FileUpload
