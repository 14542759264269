import { useMutation } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import apiUrl from '../../../../apis/api.url'
import { deleteData, postFormData } from '../../../../apis/api.helpers'
import { getErrorMessage } from '../../../../utilities/parsers'
import { useAuthStore } from '../../../../store/authStore'

export const createStock = (input: any) => {
  const role = useAuthStore.getState().userData.role
  if (role === 'Manufacturer/Supplier') {
    return postFormData(apiUrl.MANUFACTURER_STOCK_IMPORT, input)
  } else {
    return postFormData(apiUrl.DMS_STOCK_IMPORT, input)
  }
}

export const useCreateStocks = (handleSubmission: (data: any) => void) => {
  const { enqueueSnackbar } = useSnackbar()
  return useMutation(createStock, {
    onSuccess: (res: any) => {
      handleSubmission(res.data)
    },

    onError: (error: any) => {
      enqueueSnackbar(
        getErrorMessage(
          error?.response?.data?.message || 'Something went wrong'
        ),
        {
          variant: 'error',
        }
      )
    },
  })
}

export const deleteFund = (id: any) => {
  const role = useAuthStore.getState().userData.role
  if (role === 'Manufacturer/Supplier') {
    return deleteData(`${apiUrl.MANUFACTURER_STOCK_LIST}/${id}`)
  } else {
    return deleteData(`${apiUrl.DMS_STOCK_LIST}/${id}`)
  }
}
export const useDeleteFund = (handleSubmission: (data: any) => void) => {
  const { enqueueSnackbar } = useSnackbar()

  return useMutation((id: any) => deleteFund(id), {
    onSuccess: (res: any) => {
      handleSubmission(res.data)
    },

    onError: (error: any) => {
      enqueueSnackbar(
        getErrorMessage(
          error?.response?.data?.message || 'Something went wrong'
        ),
        {
          variant: 'error',
        }
      )
    },
  })
}
