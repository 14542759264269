import { useEffect } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { router_config } from '../../configs/route.config'
import Layout from '../../layout/userLayout'
import { useAppStore } from '../../store/appStore'
import { useAuthStore } from '../../store/authStore'

type Props = {
  children: React.ReactNode
  slug_key: string
  hasChild?: boolean
}

const UserRoute = ({ children, slug_key, hasChild = false }: Props) => {
  const { authenticated, role } = useAuthStore()
  const { setActiveRouteSlug } = useAppStore()
  const location = useLocation()
  // const isAdmin = useAuthStore.getState().userData?.is_admin || false

  useEffect(() => {
    if (!hasChild) {
      setActiveRouteSlug(slug_key)
    }
    // setMenuOpened(false)
    // eslint-disable-next-line
  }, [slug_key, location?.pathname])
  console.log(children, 'chi')
  if (!authenticated) return <Navigate to="/login" replace />
  const hasPermission = () => {
    const currentRouteConfig = router_config[slug_key]
    if (currentRouteConfig && currentRouteConfig.permission_slugs.length > 0) {
      return currentRouteConfig.permission_slugs.some((item) => item === role)
    }
    return true
  }

  return (
    <Layout>{hasPermission() ? <>{children}</> : <> No Permission </>}</Layout>
  )
}

export default UserRoute
