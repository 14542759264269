const defaultColumnProps1 = {
  sortable: false,
  isVisible: true,
  resizable: true,
}

export const getColumns = ({}: any) => {
  const createRenderCell = (key: string, isCustom?: string) => (row: any) => {
    if (isCustom === 'status') {
      console.log(row)
      return {
        cell: (
          <>
            {row?.availability === 'Offline' ? (
              <div className="text-red-700">Offline</div>
            ) : (
              <div className="text-blue-500">Online </div>
            )}
          </>
        ),

        // toolTip: getNestedProperty(row, key) ?? '',
      }
    } else if (isCustom === 'stream') {
      return {
        cell: (
          <>
            {row?.stream === 0
              ? 'Ayurveda'
              : row?.stream === 1
                ? 'Yoga & Naturopathy'
                : row?.stream === 2
                  ? 'Unnani'
                  : row?.stream === 3
                    ? 'Siddha'
                    : row?.stream === 4
                      ? 'Homeopathy'
                      : ''}
          </>
        ),
      }
    }
  }

  const column = [
    {
      title: 'Name',
      field: 'name',
      fixed: true,
      ...defaultColumnProps1,
      // link: true,
      // sortKey: 'default_contact__first_name',
      // rowClick: (row: any) => onViewAction(row, 'contactName'),
    },
    {
      title: 'Stream',
      // renderCell: createRenderCell('cre_agent.name', 'stream'),
      field: 'stream',
      ...defaultColumnProps1,
      // customCell: true,
      // sortKey: 'cre_agent_user_first_name',
    },
    {
      title: 'Specialization',
      field: 'specialization',
      ...defaultColumnProps1,
    },
    {
      title: 'Phone Number',
      field: 'phone_number',
      ...defaultColumnProps1,
      // sortKey: 'company_code',
      // renderCell: createRenderCell('primary_company.company_code'),

      // rowClick: (row: any) => onViewAction(row, 'business_name'),
      // link: true,
    },
    {
      title: 'Email',
      field: 'email',
      ...defaultColumnProps1,
      // link: true,
      sortKey: '',
      // renderCell: createRenderCell('primary_company.business_name'),
      // rowClick: (row: any) => onViewAction(row, 'business_name'),
    },
    {
      title: 'Status',
      renderCell: createRenderCell('default_contact.availability', 'status'),
      field: 'availability',
      ...defaultColumnProps1,
      customCell: true,
      // link: true,
      // getpath: () => '/dashboard',
    },
  ]

  return column
}
