import Tooltip from '@mui/material/Tooltip'
import React, { JSX } from 'react'
import DashIcon from './DashboardIcon'
import { IconsProps } from '../../../common/types'
import ActivitiesIcon from './DashboardIcon'
import Collectables from './ActivityCollectables'
import Medicine from './Medicine'
import AllocatePayment from './Allocate-payments'
import BadgeCheckIcon from './BadgeCheck'
import BuildingIcon from './Building'
import CalendarIcon from './Calendar'
import CancelIcon from './Cancel'
import CardsIcon from './CardsIcon'
import CartIcon from './Cart'
import CheckboxCheck from './CheckboxCheck'
import CheckCircleIcon from './CheckCircle'
import Close from './Close'
import ClosePopupIcon from './ClosePopup'
import CloudUploadIcon from './CloudUpload'
import DangerIcon from './Danger'
import DashboardIcon from './Dashboard'
import DeleteIcon from './Delete'
import DownloadIcon from './Download'
import DropArrow from './DropArrow'
import EditIcon from './Edit'
import EditFabIcon from './EditFab'
import EmailIcon from './Email'
import ExclamationCircle from './ExclamationCircle'
import Explorer from './Explorer'
import ExplorerCloseIcon from './ExplorerClose'
import ExternalLink from './ExternalLink'
import EyeIcon from './Eye'
import EyeCloseIcon from './EyeClose'
import Favourite from './Favourite'
import Favourited from './Favourited'
import FavouriteColored from './FavoutiteColored'
import ForwardArrow from './ForwardArrow'
import HeaderDropArrow from './HeaderDropDown'
import Home from './Home'
import Invoice from './Invoice'
import LeadsIcon from './leads'
import LinkIcon from './Link'
import LiveFleet from './LiveFleet'
import LocationPinIcon from './LocationPin'
import MenuFavourite from './MenuFavourite'
import MenuIcon from './menuIcon'
import MenuListIcon from './MenuListIcon'
import MenuSectionIcon from './MenuSection'
import MenuUpArrow from './MenuUpArrow'
import MergeIcon from './Merge'
import MyApprovalsIcon from './MyApprovals'
import MyTaskIcon from './MyTask'
import NextArrow from './NextArrow'
import NextMost from './NextMost'
import PaperClip from './Paperclip'
import PaymentIcon from './Payment'
import PaymentApprovalsIcon from './PaymentApprovals'
import PhoneIcon from './Phone'
import PhoneCallingIcon from './PhoneCalling'
import PlusIcon from './Plus'
import PlusTabIcon from './PlusTab'
import PreviousArrow from './PreviousArrow'
import PreviousMost from './PreviousMost'
import QuestionCircleIcon from './QuestionCircle'
import QuoteIcon from './Quote'
import RaiseRequest from './RaiseRequest'
import ReceiptIcon from './Receipt'
import ReplyIcon from './ReplyIcon'
import RetryIcon from './Retry'
import ReviewRequest from './ReviewRequest'
import SaveIcon from './Save'
import Search from './Search'
import SendIcon from './SendIcon'
import ServicesIcon from './Services'
import SettingsIcon from './Settings'
import SettingsSection from './settingsSection'
import ShareIcon from './Share'
import SidebarArrowIcon from './SidebarArrow'
import SideMenuLogout from './SideMenuLogout'
import SortDesc from './SortDesc'
import SortIcon from './SortIcon'
import StarIcon from './StarIcon'
import SubMenuListIcon from './SubMenuListIcon'
import SuccessIcon from './Success'
import TableDelete from './TableDelete'
import TableEdit from './TableEdit'
import TextFieldCancel from './TextFieldCancel'
import TextFieldDone from './TextFieldDone'
import ThreeDotIcon from './ThreeDot'
import TileCartIcon from './TileCart'
import TopbarMenu from './TopbarMenu'
import UnAllocatePayment from './Un-Allocate-payments'
import UnLink from './UnLink'
import UploadIcon from './Upload'
import UserIcon from './User'
import PaymentReceipt from './PaymentReceipt'
import Dms from './dms'
import Institutions from './Institutions'
import Manufacturer from './Manufacturer'
import Pharmacist from './pharmacist'
import Stock from './Stock'
import Fund from './Fund'
import Info from './Info'
import OrderRequest from './OrderRequest'
import MessageBox from './MessageBox'
import Trash from './Trash'
import MyOrder from './MyOrder'
import Report from './Report'
import MedicineIssue from './MedicineIssue'
import Tick from './Tick'
import Logout from './Logout'
import Patient from './Patient'
import District from './District'
import MedicalOfficer from './MedicalOfficer'
import Doctor from './Doctor'
import OpIncharge from './OpIncharge'
import MedicineLoan from './MedicineLoan'
import Replace from './Replace'
import Profile from './Profile'
import Consult from './Consult'

const Icons: React.FC<IconsProps> = ({
  name,
  className = '',
  onClick = undefined,
  toolTip,
}) => {
  const getIcons = (): JSX.Element => {
    switch (name) {
      case 'plus':
        return <PlusIcon />
      case 'order':
        return <OrderRequest />
      case 'profile':
        return <Profile />
      case 'edit':
        return <EditIcon />
      case 'trash':
        return <Trash />
      case 'op':
        return <OpIncharge />
      case 'patient':
        return <Patient />
      case 'medicine-loan':
        return <MedicineLoan />
      case 'doctor':
        return <Doctor />
      case 'medicalOfficer':
        return <MedicalOfficer />
      case 'district':
        return <District />
      case 'textfield-done':
        return <TextFieldDone />
      case 'textfield-cancel':
        return <TextFieldCancel />
      case 'three_dot':
        return <ThreeDotIcon />
      case 'eye':
        return <EyeIcon />
      case 'tick':
        return <Tick />
      case 'eye-close':
        return <EyeCloseIcon />
      case 'home':
        return <Home />
      case 'favourite':
        return <Favourite />
      case 'livefleet':
        return <LiveFleet />
      case 'explorer':
        return <Explorer />
      case 'forward-arrow':
        return <ForwardArrow />
      case 'menu-list':
        return <MenuListIcon />
      case 'side-menu-list':
        return <SubMenuListIcon />
      case 'menu-section':
        return <MenuSectionIcon />
      case 'menu-up-arrow':
        return <MenuUpArrow />
      case 'danger':
        return <DangerIcon />
      case 'replace':
        return <Replace />
      case 'success':
        return <SuccessIcon />
      case 'close-popup':
        return <ClosePopupIcon />
      case 'favourited':
        return <Favourited />
      case 'menu-favourite':
        return <MenuFavourite />
      case 'previous-most':
        return <PreviousMost />
      case 'previous-arrow':
        return <PreviousArrow />
      case 'next-most':
        return <NextMost />
      case 'next-arrow':
        return <NextArrow />
      case 'drop-arrow':
        return <DropArrow />
      case 'close':
        return <Close />
      case 'close-explorer':
        return <ExplorerCloseIcon />
      case 'sidemenu-logout':
        return <SideMenuLogout />
      case 'topbar-menu':
        return <TopbarMenu />
      case 'header-drop-arrow':
        return <HeaderDropArrow />
      case 'fab-edit':
        return <EditFabIcon />
      case 'plus-tab':
        return <PlusTabIcon />
      case 'sort-icon':
        return <SortIcon />
      case 'sort-desc':
        return <SortDesc />
      case 'search':
        return <Search />
      case 'star-icon':
        return <StarIcon />
      case 'external-link':
        return <ExternalLink />
      case 'table-edit':
        return <TableEdit />
      case 'table-delete':
        return <TableDelete />
      case 'sidebar-arrow':
        return <SidebarArrowIcon />
      case 'cancel':
        return <CancelIcon />
      case 'save':
        return <SaveIcon />
      case 'menu':
        return <MenuIcon />
      case 'phone-calling':
        return <PhoneCallingIcon />
      case 'location-pin':
        return <LocationPinIcon />
      case 'user':
        return <UserIcon />
      case 'upload':
        return <UploadIcon />
      case 'myorder':
        return <MyOrder />
      case 'reports':
        return <Report />
      case 'medicine_issue':
        return <MedicineIssue />
      case 'exclamation-circle':
        return <ExclamationCircle />
      case 'dashboard-icon':
        return <DashboardIcon />
      case 'mytasks-icon':
        return <MyTaskIcon />
      case 'myaprrovals-icon':
        return <MyApprovalsIcon />
      case 'paymentapproval-icon':
        return <PaymentApprovalsIcon />
      case 'building-icon':
        return <BuildingIcon />
      case 'receipt-icon':
        return <ReceiptIcon />
      case 'invoice':
        return <Invoice />
      case 'message':
        return <MessageBox />
      case 'allocate-payment':
        return <AllocatePayment />
      case 'medicine':
        return <Medicine />
      case 'un-allocate-payment':
        return <UnAllocatePayment />
      case 'payment-icon':
        return <PaymentIcon />
      case 'cart-icon':
        return <CartIcon />
      case 'services-icon':
        return <ServicesIcon />
      case 'settings':
        return <SettingsIcon />
      case 'consult':
        return <Consult />
      case 'calendar':
        return <CalendarIcon />
      case 'badge-check':
        return <BadgeCheckIcon />
      case 'phone':
        return <PhoneIcon />
      case 'logout':
        return <Logout />
      case 'merge':
        return <MergeIcon />
      case 'check-circle':
        return <CheckCircleIcon />
      case 'leads-icon':
        return <LeadsIcon />
      case 'email':
        return <EmailIcon />
      case 'checkbox-check':
        return <CheckboxCheck />
      case 'share':
        return <ShareIcon />
      case 'download':
        return <DownloadIcon />
      case 'retry':
        return <RetryIcon />
      case 'tile-cart':
        return <TileCartIcon />
      case 'favourite-colored':
        return <FavouriteColored />
      case 'link':
        return <LinkIcon />
      case 'delete':
        return <DeleteIcon />
      case 'question-circle':
        return <QuestionCircleIcon />
      case 'cloud-upload':
        return <CloudUploadIcon />
      case 'paper-clip':
        return <PaperClip />
      case 'cards':
        return <CardsIcon />
      case 'send':
        return <SendIcon />
      case 'info':
        return <Info />
      case 'reply':
        return <ReplyIcon />
      case 'quote':
        return <QuoteIcon />
      case 'activities':
        return <ActivitiesIcon />
      case 'settings-secion':
        return <SettingsSection />
      case 'collectable-secion':
        return <Collectables />
      case 'review':
        return <ReviewRequest />
      case 'un-link':
        return <UnLink />
      case 'stock':
        return <Stock />
      case 'raise-request':
        return <RaiseRequest />

      case 'payment-receipt':
        return <PaymentReceipt />
      case 'dash-icon':
        return <DashIcon />
      case 'Manufacturer':
        return <Manufacturer />
      case 'institution':
        return <Institutions />
      case 'pharmacist':
        return <Pharmacist />
      case 'dms':
        return <Dms />
      case 'fund':
        return <Fund />
      default:
        return <></>
    }
  }
  return (
    <Tooltip title={toolTip}>
      <span
        data-testid={name}
        onClick={onClick}
        className={`${className ?? ' text-center text-white'}`}
      >
        {getIcons()}
      </span>
    </Tooltip>
  )
}

export default Icons
