import { useMutation } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { getErrorMessage } from '../../../utilities/parsers'
import { postFormData } from '../../../apis/api.helpers'
import apiUrl from '../../../apis/api.url'

export const createManufacturer = (input: any) => {
  return postFormData(apiUrl.CREATE_MANUFACTURER, input)
}

export const useCreateManufacturer = (
  handleSubmission: (data: any) => void
) => {
  const { enqueueSnackbar } = useSnackbar()
  return useMutation(createManufacturer, {
    onSuccess: (res: any) => {
      handleSubmission(res.data)
    },

    onError: (error: any) => {
      enqueueSnackbar(
        getErrorMessage(
          error?.response?.data?.message || 'Something went wrong'
        ),
        {
          variant: 'error',
        }
      )
    },
  })
}
