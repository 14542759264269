// import FormBuilder from '../../../components/app/formBuilder/index'

import { Button } from '../../../../../components/common'
import EditForm from '../edit_form'
import { FormProvider, useForm } from 'react-hook-form'
type ManagePhasrmacistProps = {
  handleSubmit: () => void
}
const PrescriptionEdit: React.FC<ManagePhasrmacistProps> = ({
  handleSubmit,
}) => {
  const methods = useForm<any>({
    // resolver: zodResolver(createSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  // console.log(methods.watch())
  // methods.reset({})

  return (
    <>
      <div className="my-3 mx-8">
        <h2 className="flex text-center w-full mx-auto justify-center text-lime-500 text-2xl font-medium mb-6">
          <u>Edit Medicine</u>
        </h2>{' '}
        <div className="grid md:grid-cols-3 md:gap-8 sm:grid-cols-1 sm:gap-[24px]">
          <FormProvider {...methods}>
            <EditForm edit={true} />
          </FormProvider>
        </div>
        <div className="flex gap-8 justify-center mt-5">
          <Button
            // onClick={methods.handleSubmit}
            onClick={handleSubmit}
            label={'Submit'}
          />
        </div>
      </div>
    </>
  )
}
export default PrescriptionEdit
