import moment from 'moment'
import { QbsTable } from 'qbs-react-grid'
import { useCallback, useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

// import Export from '../../components/app/export'
// import { PrimaryFilterComponent } from '../../components/app/filterComponents/primaryFilter'
// import Button from '../../components/common/buttons/Button'
// import Icons from '../../components/common/icons/index'
// import ListingHeader from '../../../../components/common/ListingTiles'
import { router_config } from '../../../../configs/route.config'
import { useContactFilterStore } from '../../../../store/filterSore/contactStore'
import { statusClassGen } from '../../../../utilities/generators'
import { getSortedColumnName } from '../../../../utilities/parsers'
// import { useContact } from './api'
import { getColumns } from './contactColumns'
import { calcWindowHeight } from '../../../../utilities/calcHeight'
import { usePatients } from './api'

// import Create from './create'
// import { GetFilterDetails } from './filterProperties'

const ExaminedPatientsMain = () => {
  const navigate = useNavigate()
  const date = moment()
  const todate = moment(date).format('YYYY-MM-DD')
  const [mergeIsActive, setMergeIsActve] = useState(false)

  const { pageParams, setPageParams, selectedRows, setSelectedRows } =
    useContactFilterStore()

  const { page, page_size, search, ordering, filters, filterProps } = pageParams

  const searchParams = {
    page_number: page,
    page_size: page_size,
    search_key: search,
    visit_date: todate,
    ordering: ordering,
    status: 'Examined',
    ...filters,
  }
  const { data, refetch, isFetching } = usePatients(searchParams)
  useEffect(() => {
    refetch()
  }, [page, page_size, search, ordering, filters, refetch])
  // console.log(customSearch)
  const onChangeRowsPerPage = (count: any) => {
    setPageParams({
      ...pageParams,
      page_size: count,
      page: 1,
    })
  }
  const onChangePage = (row: any) => {
    setPageParams({
      ...pageParams,
      page: row,
    })
  }

  const onViewAction = (r: any, from: string) => {
    console.log(r, from)
  }
  const handleSort = (orderColumn: any, orderDirection: any) => {
    setPageParams({
      ...pageParams,
      sortColumn: orderColumn,
      sortType: orderDirection,
      ordering: getSortedColumnName(orderColumn, orderDirection),
    })
  }
  const handleSeach = (key?: string) => {
    setPageParams({
      ...pageParams,
      search: key as string,
      page: 1,
    })
  }
  const handleOnSlect = (rows: any) => {
    setSelectedRows([...rows])
  }

  useEffect(() => {
    handleDatepicker()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterProps.endDate])

  const handleDatepicker = useCallback(() => {
    setPageParams({
      ...pageParams,
      filters: {
        ...filters,
        fromDate: filterProps?.startDate
          ? moment(filterProps?.startDate).format('DD-MM-YYYY')
          : '',
        toDate: filterProps?.endDate
          ? moment(filterProps.endDate).format('DD-MM-YYYY')
          : '',
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterProps.endDate])

  const handleMerge = () => {
    navigate(`${router_config.CONTACT_MERGE.path}`)
    setMergeIsActve(true)
  }

  const [columns, setColumns] = useState<any>(() =>
    getColumns({
      onViewAction: onViewAction,
      statusClassGen: statusClassGen,
    })
  )

  const handleResetColums = () => {
    setColumns([
      ...getColumns({
        onViewAction: onViewAction,
        statusClassGen: statusClassGen,
      }),
    ])
  }
  if (mergeIsActive) {
    return <Outlet context={{ selectedRows, refetch }} />
  }

  return (
    <div className="">
      {/* <PageTiles data={dashtiledata} handleTileClick={handleTileClick} /> */}
      <div className=" py-[24px]">
        <QbsTable
          columns={columns}
          dataRowKey="id"
          selectedRows={selectedRows}
          // selection={true}
          isLoading={isFetching}
          toolbar
          search
          handleColumnSort={handleSort}
          height={calcWindowHeight(452)}
          data={data?.op_records ?? []}
          handleResetColumns={() => handleResetColums()}
          // sortType={sortType}
          // sortColumn={sortColumn}
          searchPlaceholder="Search OP Number or Name"
          pagination
          paginationProps={{
            onPagination: onChangePage,
            total: data?.total_count,
            currentPage: pageParams?.page,
            rowsPerPage: Number(pageParams?.page_size ?? data?.page_size),
            onRowsPerPage: onChangeRowsPerPage,
            dropOptions: [10, 20, 30, 50, 100, 200],
          }}
          selectedRowActions={[
            {
              actionTitle: 'Merge',
              action: () => handleMerge(),
              customHide: '>2',
            },
          ]}
          searchValue={pageParams?.search}
          onSearch={handleSeach}
          asyncSearch
          handleSearchValue={(key?: string) => handleSeach(key)}
          onSelect={handleOnSlect}
          columnToggle
        />
      </div>
    </div>
  )
}

export default ExaminedPatientsMain
