import { z } from 'zod'

import noLeadingSpaces from '../../../utilities/noLeadingSpaces'

export const formSchema = z.object({
  // name: z
  //   .string({ invalid_type_error: 'Name is required.' })
  //   .min(1, { message: 'Name is required.' })
  //   .refine(noLeadingSpaces, {
  //     message: 'Leading spaces are not allowed',
  //   }),
  address: z
    .string({ invalid_type_error: 'Address is required.' })
    .min(1, { message: 'Address is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  district_id: z.number(),
  district_name: z.string().refine(noLeadingSpaces, {
    message: 'Leading spaces are not allowed',
  }),
  phone_number: z
    .string()
    .nullable()
    .transform((value) => (value === null ? '' : value))
    .refine(
      (value) => {
        return (
          (/^\d+(?:\.\d{0,10})?$/.test(value) &&
            value.length <= 10 &&
            value.length >= 10 &&
            parseFloat(value) !== 0) ||
          value === ''
        )
      },
      {
        message: 'Enter a valid Mobile number',
      }
    ),
  land_phone_number: z
    .string()
    .nullable()
    .transform((value) => (value === null ? '' : value))
    .refine(
      (value) => {
        return (
          (/^\d+(?:\.\d{0,11})?$/.test(value) &&
            value.length <= 11 &&
            value.length >= 11 &&
            parseFloat(value) !== 0) ||
          value === ''
        )
      },
      {
        message: 'Enter a valid Mobile number',
      }
    ),
  email: z
    .string({ required_error: 'Enter Email' })
    .min(5, 'Enter a valid Email')
    .superRefine((value: any, ctx: any) => {
      if (value && value !== '') {
        if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Invalid email format.',
          })
        }
      }
    })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  state: z.string().optional().nullable(),
})
export type CreateSchema = z.infer<typeof formSchema>
