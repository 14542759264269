// import FormBuilder from '../../../components/app/formBuilder/index'

import { Button } from '../../../../../components/common'
import CommonForm from '../form'
import { FormProvider, useForm } from 'react-hook-form'
type ManagePhasrmacistProps = {
  handleSubmit: () => void
}
const PrimaryStockCreate: React.FC<ManagePhasrmacistProps> = ({
  handleSubmit,
}) => {
  const methods = useForm<any>({
    // resolver: zodResolver(createSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  // console.log(methods.watch())
  // methods.reset({})

  return (
    <>
      <div className="my-5 mx-8">
        <div className="grid grid-cols-3 gap-4">
          <div className="col-span-3">
            <FormProvider {...methods}>
              <CommonForm edit={true} />
            </FormProvider>
          </div>
        </div>
        <div className="flex gap-8 justify-center my-3">
          <Button
            // onClick={methods.handleSubmit}
            onClick={handleSubmit}
            label={'Add Prescription'}
            className="w-[175px]"
          />
        </div>
      </div>
    </>
  )
}
export default PrimaryStockCreate
