import { create } from 'zustand'
// import { persist } from 'zustand/middleware'
import Cookies from 'js-cookie'

const setCookie = (name: string, data: any, expires = 1) => {
  Cookies.set(name, data, { expires, sameSite: 'strict' })
}

const removeCookie = (name: string) => {
  Cookies.remove(name)
}
const getParsedData = (dat: any) => {
  if (dat) {
    return JSON.parse(dat)
  }
  return {}
}

const stringData = (dat: any) => {
  if (dat) {
    return JSON.stringify(dat)
  }
  return
}
export const useAuthStore = create<AuthStoreType>(
  (set) => ({
    authenticated: Boolean(Cookies.get('authenticated')),
    token: Cookies.get('token'),
    role: Cookies.get('role'),
    // setAuthenticated: (is_authenticated) =>
    //   set(() => ({ authenticated: is_authenticated })),
    setAuthenticated: (is_authenticated) => {
      setCookie('authenticated', is_authenticated)
      set({
        authenticated: is_authenticated,
      })
    },
    userData: getParsedData(Cookies.get('userData')) || {},
    roleData: getParsedData(Cookies.get('roleData')) || {},
    // permissionData: [],
    setRole: (role_name) => {
      setCookie('role', role_name)
      set({ role: role_name })
    },
    setToken: (data) => {
      setCookie('token', data)
      set({ token: data })
    },
    setUserData: (data) => {
      setCookie('userData', stringData(data))
      set({ userData: data })
    },
    setRoleData: (data) => {
      setCookie('roleData', stringData(data))
      set({ roleData: data })
    },
    // setPermissionData: (data) => set(() => ({ permissionData: data })),
    clearAuthenticated: () => {
      const cookieName = [
        'authenticated',
        'token',
        'role',
        'userData',
        'roleData',
      ]
      cookieName.forEach(removeCookie)
      set({
        authenticated: undefined,
        userData: undefined,
        roleData: undefined,
        role: undefined,
        token: undefined,
      })
    },
  })
  // { name: 'authenticated' }
)

type AuthStoreType = {
  authenticated: boolean | undefined
  token: string | undefined
  role: string | undefined
  setAuthenticated: (authenticated: boolean | undefined) => void
  clearAuthenticated: () => void
  userData?: any
  roleData?: any
  // permissionData: PermissionDataProps[]
  setUserData: (data: any) => void
  setToken: (data: string) => void
  setRole: (data: string) => void
  setRoleData: (data: any) => void
  // setPermissionData: (data: PermissionDataProps[]) => void
}
export interface UserDataProps {
  id?: number | null
  name?: string | null
  email?: string | null
  role?: string | null
  role_id?: null | number
  phone_number?: null | number
  district?: string | null
  district_id?: number | null
  stream?: string | null
  image?: string | null
  primary_stock_threshold?: number | null
  institution_name?: string | null
  institution_phone_number?: any | null
  institution_land_number?: any | null
  district_name?: any | null
}
export interface RoleDataProps {
  id?: string | null
  name?: string | null
}
export interface PermissionDataProps {
  acl?: string
  codename?: string
  id?: number
}
