// import FormBuilder from '../../../components/app/formBuilder/index'

import { useEffect, useState } from 'react'
import FormBuilder from '../../../../components/app/formBuilder'
import { useInstitution } from '../primary_stock/api'
type Props = {
  edit?: boolean
}
// const CommonForm = (data?: any) => {
const CommonForm = ({ edit }: Props) => {
  const { data } = useInstitution()
  const [newArray, seatNewArray] = useState<any>([])
  console.log(data)
  useEffect(() => {
    const newArray: any = []
    data?.institutions?.forEach((item: any) => {
      const newKey = `${item?.institution_name}, ${item?.district_name} (${item?.stream})`
      item['new_name'] = newKey
      const obj = {
        id: item?.id,
        name: newKey,
      }

      newArray.push(obj)
    })
    seatNewArray(newArray)
  }, [data])
  console.log(newArray)
  const textField = (
    name: string,
    label: string,
    id: string,
    type: string,
    // placeholder: string,
    required = false,
    config: { data?: Array<{ name: string; id: string }> } = {}
  ) => ({
    name,
    label,
    id,
    type,
    // placeholder,
    ...(required ? { required: true } : {}),
    ...config,
    spacing: 4,
  })
  const formBuilderProps = [
    {
      ...textField('name', 'Institution Name ', 'id', 'custom_select', true, {
        data: newArray,
      }),
      placeholder: 'Choose one',
    },

    {
      ...textField('state', 'State', 'state', 'text', false),
      disabled: true,
    },
    // {
    //   ...textField(
    //     'district_name',
    //     'District ',
    //     'district_id',
    //     'custom_select',
    //     false
    //   ),
    // },

    // {
    //   ...textField('stream', 'Stream', 'stream', 'custom_select', false),
    // },
    textField('start_date', 'Start date', 'start_date', 'date', true),
    textField('end_date', 'End date', 'end_date', 'date', true),
  ]

  // methods.reset({})

  return <FormBuilder data={formBuilderProps} edit={edit} />
}
export default CommonForm
