import React from 'react'
import { IconProps } from '../../../common/types'

const DashboardIcon: React.FC<IconProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 33 33"
      fill="none"
    >
      <path
        d="M12.375 4.125H5.5C4.74061 4.125 4.125 4.74061 4.125 5.5V15.125C4.125 15.8844 4.74061 16.5 5.5 16.5H12.375C13.1344 16.5 13.75 15.8844 13.75 15.125V5.5C13.75 4.74061 13.1344 4.125 12.375 4.125Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.5 4.125H20.625C19.8656 4.125 19.25 4.74061 19.25 5.5V9.625C19.25 10.3844 19.8656 11 20.625 11H27.5C28.2594 11 28.875 10.3844 28.875 9.625V5.5C28.875 4.74061 28.2594 4.125 27.5 4.125Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.5 16.5H20.625C19.8656 16.5 19.25 17.1156 19.25 17.875V27.5C19.25 28.2594 19.8656 28.875 20.625 28.875H27.5C28.2594 28.875 28.875 28.2594 28.875 27.5V17.875C28.875 17.1156 28.2594 16.5 27.5 16.5Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.375 22H5.5C4.74061 22 4.125 22.6156 4.125 23.375V27.5C4.125 28.2594 4.74061 28.875 5.5 28.875H12.375C13.1344 28.875 13.75 28.2594 13.75 27.5V23.375C13.75 22.6156 13.1344 22 12.375 22Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default DashboardIcon
