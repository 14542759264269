import moment from 'moment'
import { QbsTable } from 'qbs-react-grid'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'

// import Export from '../../components/app/export'
// import { PrimaryFilterComponent } from '../../components/app/filterComponents/primaryFilter'
// import Button from '../../components/common/buttons/Button'
// import Icons from '../../components/common/icons/index'
// import ListingHeader from '../../../../components/common/ListingTiles'
import { useContactFilterStore } from '../../../../store/filterSore/contactStore'
import { statusClassGen } from '../../../../utilities/generators'
import { getSortedColumnName } from '../../../../utilities/parsers'
import { useOrderRequest } from '.././api'
import { getColumns } from '.././contactColumns'
import { ConfirmModal } from '../../../../components/common'
import { calcWindowHeight } from '../../../../utilities/calcHeight'
import Icons from '../../../../components/common/icons'
import { router_config } from '../../../../configs/route.config'
// import Create from './create'
// import { GetFilterDetails } from './filterProperties'

const PendingOrdersMain = () => {
  const { setActiveTab } = useOutletContext<any>()
  const [successModalActive, setSuccessModalActive] = useState(false)

  // const [openPopups, setOpenPopups] = useState(false)
  const { pageParams, setPageParams, selectedRows, setSelectedRows } =
    useContactFilterStore()
  const navigate = useNavigate()
  // const [customSearch, setCustomSearch] = useState('')

  const { page, page_size, search, ordering, filters, filterProps } = pageParams

  const searchParams = {
    page_number: page,
    page_size: page_size,
    status: 'pending',
    name: search,
    ordering: ordering,
    ...filters,
  }
  const { data, refetch, isFetching } = useOrderRequest(searchParams)
  useEffect(
    () => {
      refetch()
      setActiveTab('PENDING_ORDER')
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, page_size, search, ordering, filters, refetch]
  )

  const onChangeRowsPerPage = (count: any) => {
    setPageParams({
      ...pageParams,
      page_size: count,
      page: 1,
    })
  }
  const onChangePage = (row: any) => {
    setPageParams({
      ...pageParams,
      page: row,
    })
  }

  const onViewAction = (r: any) => {
    return r.id && `${router_config.MANUFACTURER_ORDER_REQUEST.path}/${r.id}`
  }
  const handleSort = (orderColumn: any, orderDirection: any) => {
    setPageParams({
      ...pageParams,
      sortColumn: orderColumn,
      sortType: orderDirection,
      ordering: getSortedColumnName(orderColumn, orderDirection),
    })
  }
  const handleSeach = (key?: string) => {
    setPageParams({
      ...pageParams,
      search: key as string,
      page: 1,
    })
  }
  const handleOnSlect = (rows: any) => {
    setSelectedRows([...rows])
  }

  useEffect(() => {
    handleDatepicker()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterProps.endDate])

  const handleDatepicker = useCallback(() => {
    setPageParams({
      ...pageParams,
      filters: {
        ...filters,
        fromDate: filterProps?.startDate
          ? moment(filterProps?.startDate).format('DD-MM-YYYY')
          : '',
        toDate: filterProps?.endDate
          ? moment(filterProps.endDate).format('DD-MM-YYYY')
          : '',
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterProps.endDate])

  // const handleFilterDateChange = (event: any, desc: any, desc2: any) => {
  //   setPageParams({
  //     ...pageParams,
  //     filterProps: {
  //       ...filterProps,
  //       [desc]: event?.value[0],
  //       [desc2]: event?.value[1],
  //     },
  //   })
  // }

  // const handleSHowHide = () => {
  //   return advanceFilter?.filterParams?.find((item) => item.isChecked)
  //     ? true
  //     : false
  // }

  // const handlePath = () => {
  //   if (location.pathname === router_config.CONTACT_MERGE.path) {
  //     setMergeIsActve(true)
  //   } else {
  //     setMergeIsActve(false)
  //   }
  // }

  // useEffect(() => {
  //   handlePath()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [location.pathname])

  const [columns, setColumns] = useState<any>(() =>
    getColumns({
      onViewAction: onViewAction,
      statusClassGen: statusClassGen,
    })
  )

  const handleResetColums = () => {
    setColumns([
      ...getColumns({
        onViewAction: onViewAction,
        statusClassGen: statusClassGen,
      }),
    ])
  }
  // if (mergeIsActive) {
  //   return <Outlet context={{ selectedRows, refetch }} />
  // }
  // const activity = {
  //   hideExport: true,
  // }
  // const handleCreate = () => {
  //   setDialogModalActive(true)
  // }
  //   const handleClose = () => {
  //     setDialogModalActive(false)
  //   }
  //   const handleSubmit = () => {
  //     setDialogModalActive(false)
  //     setSuccessModalActive(true)
  //   }
  const handleCloseModal = () => {
    // navigate(`${router_config.PHARMACIST.path}`)
    setSuccessModalActive(false)
  }
  return (
    <div className="">
      {/* <PageTiles data={dashtiledata} handleTileClick={handleTileClick} /> */}
      {/* <div className=" ">
        <QbsTable
          data={data?.order_requests ?? []}
          dataRowKey="id"
          // toolbar={true}
          selectedRows={selectedRows}
          bordered={false}
          // bordered=false

          // selection={true}
          // searchValue={searchKey}
          // handleColumnToggle={handleColumnToggle}
          // search={true}
          isLoading={isFetching}
          sortType={pageParams.sortType}
          sortColumn={pageParams.sortColumn}
          handleColumnSort={handleSort}
          columns={columns}
          tableBodyHeight="calc(55vh)"
          pagination={true}
          handleResetColumns={() => handleResetColums()}
          paginationProps={{
            onPagination: onChangePage,
            total: data?.count,
            currentPage: pageParams?.page,
            rowsPerPage: Number(pageParams?.page_size ?? data?.page_size),
            onRowsPerPage: onChangeRowsPerPage,
            dropOptions: [10, 20, 30, 50, 100, 200],
          }}
          height={100}
          minHeight={100}
          // tableHeaderActions={}
          // toolbar={false}
          headerHeight={60}
          // classes={["font-size :19px"]}
          actionProps={
            [
              // {
              //   icon: <Icons name="eye" />,
              //   action: (row) => onViewAction(row, ''),
              //   title: 'view',
              // },
            ]
          }
          // primaryFilter={
          //   <PrimaryFilterComponent
          //     filterParams={filterDetails}
          //     handleFilterChange={handleFilterChange}
          //     filterProps={filterProps}
          //     advanceFilter={advanceFilter}
          //     handleFilterDateChange={handleFilterDateChange}
          //     isPrimary={true}
          //     setAdvanceFilter={setAdvanceFilter}
          //   />
          // }
          // advancefilter={
          //   showDetail && (
          //     <PrimaryFilterComponent
          //       filterParams={filterDetails}
          //       handleFilterDateChange={handleFilterDateChange}
          //       handleFilterChange={handleFilterChange}
          //       filterProps={filterProps}
          //       isPrimary={false}
          //       advanceFilter={advanceFilter}
          //     />
          //   )
          // }

          searchValue={pageParams?.search}
          onSearch={handleSeach}
          asyncSearch
          handleSearchValue={(key?: string) => handleSeach(key)}
          onSelect={handleOnSlect}
          columnToggle
          // tableHeaderActions={
          //   <div className="flex gap-2">
          //     {handleSHowHide() && (
          //       <Button
          //         onClick={() => {
          //           setShowDetail(!showDetail)
          //         }}
          //         label={showDetail ? 'Hide' : 'Show'}
          //         className="se"
          //         primary
          //       />
          //     )}
          //   </div>
          // }
        />
      </div> */}
      <div className=" pt-[24px]">
        <QbsTable
          columns={columns}
          dataRowKey="id"
          selectedRows={selectedRows}
          // selection={true}
          isLoading={isFetching}
          toolbar
          search
          handleColumnSort={handleSort}
          height={calcWindowHeight(439)}
          data={data?.order_requests ?? []}
          handleResetColumns={() => handleResetColums()}
          // sortType={sortType}
          // sortColumn={sortColumn}
          pagination
          paginationProps={{
            onPagination: onChangePage,
            total: data?.total_count,
            currentPage: pageParams?.page,
            rowsPerPage: Number(pageParams?.page_size ?? data?.page_size),
            onRowsPerPage: onChangeRowsPerPage,
            dropOptions: [10, 20, 30, 50, 100, 200],
          }}
          actionProps={[
            {
              title: 'View',
              action: (rowData) => navigate(onViewAction(rowData)),
              icon: <Icons name="eye" />,
              toolTip: 'View',
            },
          ]}
          searchValue={pageParams?.search}
          onSearch={handleSeach}
          asyncSearch
          handleSearchValue={(key?: string) => handleSeach(key)}
          onSelect={handleOnSlect}
          columnToggle
        />
      </div>

      {successModalActive && (
        <ConfirmModal
          isOpen={true}
          body={
            <div className="font-medium text-primary leading-8 text-xl">
              Stock Added Successfully
            </div>
          }
          topIcon="check-circle"
          onClose={handleCloseModal}
          // title={'Add New Pharmacist'}
        />
      )}
      {/* <Create
          isDrawerOpen={isDrawerOpen}
          handleClose={handleClose}
          handleRefresh={handleRefresh}
        />
        <Export
          isOpen={openPopups}
          handleClose={handleClose}
          slug={'EXPORT_CONTACT'}
          pageParams={searchParams}
        /> */}
    </div>
  )
}

export default PendingOrdersMain
