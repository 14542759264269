export const duplicateFields = {
  item1: { label: '', field: 'first_name' },
  item2: { label: '', field: 'last_name', newline: true, hideComma: true },
  item3: { label: '', field: 'mobile' },
  item4: { label: '', field: 'mobile_2', newline: true },
  item5: { label: '', field: 'email' },
  item6: { label: '', field: 'email_2', newline: true },
  item7: { label: 'PAN No', field: 'pan_no', newline: true },
  item8: { label: 'Primary Account', field: 'primary_company', newline: true },
}

export const genderData = [
  { name: 'Male', id: 'MAle' },
  { name: 'Female', id: 'Female' },
  { name: 'Transgender', id: 'Transgender' },
]
export const streamData = [
  { name: 'Ayurveda', id: 'Ayurveda' },
  { name: 'Yoga & Naturopathy', id: 'Yoga & Naturopathy' },
  { name: 'Unnani', id: 'Unnani' },
  { name: 'Siddha', id: 'Siddha' },
  { name: 'Homoeopathy', id: 'Homoeopathy' },
]
export const availabilityData = [
  { name: 'Online', id: 'Online' },
  { name: 'Offline', id: 'Offline' },
]
