import moment from 'moment'
import { QbsTable } from 'qbs-react-grid'
import { useCallback, useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

// import Export from '../../components/app/export'
// import { PrimaryFilterComponent } from '../../components/app/filterComponents/primaryFilter'
// import Button from '../../components/common/buttons/Button'
// import Icons from '../../components/common/icons/index'
// import ListingHeader from '../../../../components/common/ListingTiles'
import { router_config } from '../../../../configs/route.config'
import { useContactFilterStore } from '../../../../store/filterSore/contactStore'
import { statusClassGen } from '../../../../utilities/generators'
import { getSortedColumnName } from '../../../../utilities/parsers'
import { usePatients } from './api'
import { getColumns } from './contactColumns'
import {
  Button,
  ConfirmModal,
  DialogModal,
} from '../../../../components/common'
import StockEdit from './details'
import { calcWindowHeight } from '../../../../utilities/calcHeight'
import Icons from '../../../../components/common/icons'

// import Create from './create'
// import { GetFilterDetails } from './filterProperties'

const AllPatientsMain = () => {
  const navigate = useNavigate()
  const date = moment()
  const todate = moment(date).format('YYYY-MM-DD')
  console.log(todate)
  const [editModalActive, setEditModalActive] = useState(false)
  const [successModalActive, setSuccessModalActive] = useState(false)
  const [mergeIsActive, setMergeIsActve] = useState(false)
  // const [customSearch, setCustomSearch] = useState('')
  // const [customType] = useState('')

  // const [openPopups, setOpenPopups] = useState(false)
  const { pageParams, setPageParams, selectedRows, setSelectedRows } =
    useContactFilterStore()
  // const { advanceFilter, setAdvanceFilter } = useContactFilterStore()
  // const [showDetail, setShowDetail] = useState(true)
  const [createData, setCreateData] = useState({})

  const { page, page_size, search, ordering, filters, filterProps } = pageParams

  const searchParams = {
    page_number: page,
    page_size: page_size,
    search_key: search,
    visit_date: todate,
    ordering: ordering,
    ...filters,
  }
  const { data, refetch, isFetching } = usePatients(searchParams)
  useEffect(() => {
    refetch()
  }, [page, page_size, search, ordering, filters, refetch])
  // console.log(customSearch)
  const onChangeRowsPerPage = (count: any) => {
    setPageParams({
      ...pageParams,
      page_size: count,
      page: 1,
    })
  }
  const onChangePage = (row: any) => {
    setPageParams({
      ...pageParams,
      page: row,
    })
  }

  const onViewAction = (r: any, from: string) => {
    console.log(r, from)
  }
  const handleSort = (orderColumn: any, orderDirection: any) => {
    setPageParams({
      ...pageParams,
      sortColumn: orderColumn,
      sortType: orderDirection,
      ordering: getSortedColumnName(orderColumn, orderDirection),
    })
  }
  const handleSeach = (key?: string) => {
    setPageParams({
      ...pageParams,
      search: key as string,
      page: 1,
    })
  }
  const handleOnSlect = (rows: any) => {
    setSelectedRows([...rows])
  }
  // const handleColumnToggle = (columns: any) => {
  //   // console.log(columns)
  // }
  // const handleTileClick = (id: string) => {
  //   // console.log(id)
  // }
  // const basicData = {
  //   title: '',
  //   icon: '',
  // }

  useEffect(() => {
    handleDatepicker()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterProps.endDate])

  const handleDatepicker = useCallback(() => {
    setPageParams({
      ...pageParams,
      filters: {
        ...filters,
        fromDate: filterProps?.startDate
          ? moment(filterProps?.startDate).format('DD-MM-YYYY')
          : '',
        toDate: filterProps?.endDate
          ? moment(filterProps.endDate).format('DD-MM-YYYY')
          : '',
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterProps.endDate])

  // const handleFilterDateChange = (event: any, desc: any, desc2: any) => {
  //   setPageParams({
  //     ...pageParams,
  //     filterProps: {
  //       ...filterProps,
  //       [desc]: event?.value[0],
  //       [desc2]: event?.value[1],
  //     },
  //   })
  // }

  // const handleSHowHide = () => {
  //   return advanceFilter?.filterParams?.find((item) => item.isChecked)
  //     ? true
  //     : false
  // }
  const handleMerge = () => {
    navigate(`${router_config.CONTACT_MERGE.path}`)
    setMergeIsActve(true)
  }
  // const handlePath = () => {
  //   if (location.pathname === router_config.CONTACT_MERGE.path) {
  //     setMergeIsActve(true)
  //   } else {
  //     setMergeIsActve(false)
  //   }
  // }

  // useEffect(() => {
  //   handlePath()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [location.pathname])
  const onAction = (r: any, from: string) => {
    console.log(r, from)
    // switch (from) {
    //   case 'edit':
    //     handleEditAction(r)
    // }
    if (from === 'edit' && r?.status === 'Waiting') {
      handleEditAction(r)
    }
  }

  const handleEditAction = (r: any) => {
    setCreateData(r)
    setEditModalActive(true)
  }
  const handleEditClose = () => {
    setEditModalActive(false)
  }
  const handlSubmitEdit = () => {
    refetch()
    setEditModalActive(false)
    setSuccessModalActive(true)
  }
  const handleCloseModal = () => {
    // navigate(`${router_config.PHARMACIST.path}`)
    setSuccessModalActive(false)
  }
  const [columns, setColumns] = useState<any>(() =>
    getColumns({
      onViewAction: onViewAction,
      statusClassGen: statusClassGen,
      onAction: onAction,
    })
  )

  const handleResetColums = () => {
    setColumns([
      ...getColumns({
        onViewAction: onViewAction,
        statusClassGen: statusClassGen,
      }),
    ])
  }
  if (mergeIsActive) {
    return <Outlet context={{ selectedRows, refetch }} />
  }
  // const activity = {
  //   hideExport: true,
  // }
  const handleCreate = () => {
    navigate(`${router_config.OP_PATIENTS_ALL_ADD_NEW.path}`)
  }
  return (
    <div className="">
      {editModalActive && (
        <DialogModal
          isOpen={true}
          body={
            <StockEdit
              handlSubmitEdit={handlSubmitEdit}
              data={createData}
              edit={true}
              update={false}
              create={true}
            />
          }
          onClose={handleEditClose}
          // title={'Add New Pharmacist'}
        />
      )}
      {successModalActive && (
        <>
          <ConfirmModal
            isOpen={true}
            body={
              <div className="font-medium text-primary leading-8 text-xl">
                Patient Updated Successfully{' '}
              </div>
            }
            topIcon="check-circle"
            onClose={handleCloseModal}
            // title={'Add New Pharmacist'}
          />
        </>
      )}
      {/* <PageTiles data={dashtiledata} handleTileClick={handleTileClick} /> */}
      <div className=" py-[24px]">
        <QbsTable
          columns={columns}
          dataRowKey="id"
          selectedRows={selectedRows}
          // selection={true}
          isLoading={isFetching}
          toolbar
          search
          searchPlaceholder="Search OP Number or Name"
          handleColumnSort={handleSort}
          height={calcWindowHeight(452)}
          data={data?.op_records ?? []}
          handleResetColumns={() => handleResetColums()}
          // sortType={sortType}
          // sortColumn={sortColumn}
          pagination
          paginationProps={{
            onPagination: onChangePage,
            total: data?.total_count,
            currentPage: pageParams?.page,
            rowsPerPage: Number(pageParams?.page_size ?? data?.page_size),
            onRowsPerPage: onChangeRowsPerPage,
            dropOptions: [10, 20, 30, 50, 100, 200],
          }}
          tableHeaderActions={
            <>
              <Button
                onClick={handleCreate}
                label={'Add New Patient'}
                icon="plus"
                className="w-[185px]"
              />
            </>
          }
          actionProps={[
            {
              title: 'View',
              action: (rowData) => onAction(rowData, 'edit'),
              icon: <Icons name="edit" />,
              toolTip: 'Edit',
            },
          ]}
          selectedRowActions={[
            {
              actionTitle: 'Merge',
              action: () => handleMerge(),
              customHide: '>2',
            },
          ]}
          searchValue={pageParams?.search}
          onSearch={handleSeach}
          asyncSearch
          handleSearchValue={(key?: string) => handleSeach(key)}
          onSelect={handleOnSlect}
          columnToggle
        />
      </div>
    </div>
  )
}

export default AllPatientsMain
