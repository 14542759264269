import moment from 'moment'
import { QbsTable } from 'qbs-react-grid'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ListingHeader from '../../../components/common/ListingTiles'
import { router_config } from '../../../configs/route.config'
import { useContactFilterStore } from '../../../store/filterSore/contactStore'
import { statusClassGen } from '../../../utilities/generators'
import { getSortedColumnName } from '../../../utilities/parsers'
import { useDoctors } from './api'
import { getColumns } from './contactColumns'
import { ConfirmModal, DialogModal } from '../../../components/common'
import PharmacistCreate from './create'
import { useCreateDoctor } from './create/api'
import { calcWindowHeight } from '../../../utilities/calcHeight'
import Icons from '../../../components/common/icons'
import { PrimaryFilterComponent } from '../../../components/app/filterComponents/primaryFilter'
import { GetFilterDetails } from './filterProperties'

const DoctorMain = () => {
  const navigate = useNavigate()
  const [dialogModalActive, setDialogModalActive] = useState(false)
  const [successModalActive, setSuccessModalActive] = useState(false)
  // const [setCustomType] = useState(any)
  const filterDetails = GetFilterDetails()
  const { pageParams, setPageParams, selectedRows, setSelectedRows } =
    useContactFilterStore()
  const onSuccess = () => {
    setDialogModalActive(false)
    setSuccessModalActive(true)
    refetch()
    // setCustomType(undefined)
    // setSearchParams({
    //   page_number: page,
    //   page_size: page_size,
    //   name: search,
    //   stream: customType,
    // })
  }
  const { mutate } = useCreateDoctor(onSuccess)

  const { page, page_size, search, filters, filterProps } = pageParams
  const [customType, setCustomType] = useState(pageParams?.stream ?? undefined)
  const [searchParams, setSearchParams] = useState<any>({
    page_number: page,
    page_size: page_size,
    name: search,
    stream: customType,
  })
  const { data, refetch, isFetching } = useDoctors({
    ...searchParams,
    ...(searchParams?.stream
      ? searchParams.stream === 'Yoga & Naturopathy'
        ? { stream: 'Yoga %26 Naturopathy' }
        : { stream: searchParams.stream }
      : {}),
    // ...(searchParams?.stream && { stream: null }),
  })
  // console.log(data)
  useEffect(() => {
    setSearchParams({
      page_number: pageParams?.page,
      page_size: pageParams?.page_size,
      name: pageParams?.search,
      stream: pageParams?.stream,
    })
  }, [pageParams])
  console.log(pageParams)

  const onChangeRowsPerPage = (count: any) => {
    setPageParams({
      ...pageParams,
      page_size: count,
      page: 1,
    })
  }
  const onChangePage = (row: any) => {
    setPageParams({
      ...pageParams,
      page: row,
    })
  }

  const onViewAction = (r: any) => {
    return r.id && `${router_config.DOCTOR.path}/${r.id}`
  }
  const handleSort = (orderColumn: any, orderDirection: any) => {
    setPageParams({
      ...pageParams,
      sortColumn: orderColumn,
      sortType: orderDirection,
      ordering: getSortedColumnName(orderColumn, orderDirection),
    })
  }
  const handleSeach = (key?: string) => {
    setPageParams({
      ...pageParams,
      search: key as string,
      page: 1,
    })
  }
  const handleOnSlect = (rows: any) => {
    setSelectedRows([...rows])
  }
  const basicData = {
    title: 'Doctors',
    icon: 'pharmacist',
  }

  const [columns, setColumns] = useState<any>(() =>
    getColumns({
      onViewAction: onViewAction,
      statusClassGen: statusClassGen,
    })
  )

  const handleResetColums = () => {
    setColumns([
      ...getColumns({
        onViewAction: onViewAction,
        statusClassGen: statusClassGen,
      }),
    ])
  }

  const activity = {
    hideExport: true,
  }
  const handleCreate = () => {
    setDialogModalActive(true)
  }
  const handleClose = () => {
    setDialogModalActive(false)
  }
  const handlSubmit = (payload: any) => {
    // // console.log(payload, "this is the payload")
    const data = {
      doctor: {
        ...payload,
        user_attributes: {
          email: payload.email,
          phone_number: payload.phone_number,
        },
        ...(payload.email && { email: {} }),
        ...(payload.phone_number && { phone_number: {} }),
        ...(payload.dob
          ? { dob: moment(payload?.dob).format('YYYY-MM-DD') }
          : { dob: {} }),
        ...(payload.joining_date
          ? { joining_date: moment(payload?.joining_date).format('YYYY-MM-DD') }
          : { joining_date: {} }),
      },
    }
    mutate(data)
    // handleClose()
    // setSuccessModalActive(true)
  }
  const handleCloseModal = () => {
    navigate(`${router_config.DOCTOR.path}`)
    setSuccessModalActive(false)
    handleClose()
  }
  // const handleFilterChange = () => {}
  const handleFilterChange = (dta: any, name: string, id: string) => {
    console.log(dta, name, id)

    setCustomType(dta?.value)
    setPageParams({
      ...pageParams,
      stream: dta?.value,
    })
    setSearchParams({ ...searchParams, stream: dta?.value })

    const currentParams = useContactFilterStore.getState()?.pageParams || {}

    const getDefaultFilters = () => ({
      ...currentParams.filters,
      [id]: dta?.id ?? undefined,
    })

    const getDefaultFilterProps = () => ({
      ...currentParams.filterProps,
      [name]: dta[name],
    })
    const filters = getDefaultFilters()
    const filterProps = getDefaultFilterProps()
    // console.log(filterProps?.value, dta?.value)
    setPageParams({
      ...currentParams,
      page: 1,
      filters,
      filterProps,
    })
    // console.log(
    //   searchParams,
    //   customType,
    //   'hwgvfhjwefjwdfhbjwhfjwehfjwehgwehwejh'
    // )
  }
  console.log(filters)
  return (
    <div className="">
      <div className="">
        <ListingHeader
          data={basicData}
          onActionClick={handleCreate}
          actionTitle="Add Doctor"
          actionProps={activity}
        />
        {dialogModalActive && (
          <DialogModal
            isOpen={true}
            body={
              <PharmacistCreate
                handlSubmit={(payload) => handlSubmit(payload)}
              />
            }
            // success={setSuccessModalActive}
            onClose={handleClose}
          />
        )}
        {successModalActive && (
          <ConfirmModal
            isOpen={true}
            body={
              <div className="font-medium text-primary leading-8 text-xl">
                Registration Completed Successfully
              </div>
            }
            topIcon="check-circle"
            onClose={handleCloseModal}
          />
        )}
        <div className=" px-[24px] py-[24px]">
          <QbsTable
            columns={columns}
            dataRowKey="id"
            selectedRows={selectedRows}
            // selection={true}
            isLoading={isFetching}
            toolbar
            search
            // setCustomType={setCustomType}
            handleColumnSort={handleSort}
            height={calcWindowHeight(382)}
            data={data?.doctors ?? []}
            handleResetColumns={() => handleResetColums()}
            // sortType={sortType}
            // sortColumn={sortColumn}
            pagination
            paginationProps={{
              onPagination: onChangePage,
              total: data?.total_count,
              currentPage: pageParams?.page,
              rowsPerPage: Number(pageParams?.page_size ?? data?.page_size),
              onRowsPerPage: onChangeRowsPerPage,
              dropOptions: [10, 20, 30, 50, 100, 200],
            }}
            primaryFilter={
              <PrimaryFilterComponent
                filterParams={filterDetails}
                handleFilterChange={handleFilterChange}
                filterProps={filterProps}
                handleFilterDateChange={setCustomType}
                isPrimary={true}
              />
            }
            actionProps={[
              {
                title: 'View',
                action: (rowData) => navigate(onViewAction(rowData)),
                icon: <Icons name="eye" />,
                toolTip: 'View',
              },
            ]}
            searchValue={pageParams?.search}
            onSearch={handleSeach}
            asyncSearch
            handleSearchValue={(key?: string) => handleSeach(key)}
            onSelect={handleOnSlect}
            columnToggle
          />
        </div>
      </div>
    </div>
  )
}

export default DoctorMain
