import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosRequestConfig } from 'axios'
import { useSnackbar } from 'notistack'

import {
  getConfigData,
  getData,
  postData,
  updateData,
} from '../../apis/api.helpers'
import apiUrl from '../../apis/api.url'
import { QueryParams } from '../../common/types'
import { getErrorMessage, parseQueryParams } from '../../utilities/parsers'

const buildUrlWithParams = (baseUrl: string, params: any) => {
  return `${baseUrl}${parseQueryParams(params)}`
}

const fetchData = async (input: QueryParams) => {
  const url = buildUrlWithParams(apiUrl.DISTRICT_ADMIN, {
    ...input,
  })
  const response = await getData(url)
  // console.log(response)
  return response
}

export const useDistrictAdmin = (input: QueryParams) => {
  return useQuery(['district_admin_list'], () => fetchData(input), {
    refetchOnWindowFocus: false,
    //staleTime: 50000,
  })
}

export const createContact = (input: any) => {
  return postData(apiUrl.CONTACT_CREATE, input)
}

export const useCreateContact = (handleSubmission: (data: any) => void) => {
  const { enqueueSnackbar } = useSnackbar()
  return useMutation(createContact, {
    onSuccess: (res: any) => {
      handleSubmission(res.data)
      enqueueSnackbar('Contact Created successfully', { variant: 'success' })
    },

    onError: (error: any) => {
      enqueueSnackbar(
        getErrorMessage(
          error?.response?.data?.message || 'Something went wrong'
        ),
        {
          variant: 'error',
        }
      )
    },
  })
}

export const editContact = (data: { input: any; id: string }) => {
  return updateData(`${apiUrl.CONTACT_CREATE}${data?.id}/`, data?.input)
}

export const useEditContact = (
  handleSubmission: (input: any, id: string) => void
) => {
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(editContact, {
    onSuccess: (res: any) => {
      handleSubmission(res.data, res.id)
      enqueueSnackbar('Contact Created successfully', { variant: 'success' })
    },

    onError: (error: any) => {
      enqueueSnackbar(
        getErrorMessage(
          error?.response?.data?.message || 'Something went wrong'
        ),
        {
          variant: 'error',
        }
      )
    },
  })
}

export const getContactMergeDetails = (data: any) => {
  return postData(apiUrl.CONTACT_MULTIPLE_DETAILS, data)
}

export const mergeContacts = (data: any, id?: string) => {
  return updateData(`${apiUrl.CONTACT_LIST_URL}${id}/merge/`, data)
}
export const getContactDetails = (id: any) => {
  return getData(`${apiUrl.CONTACT_LIST_URL}${id}`)
}

export const setPrimaryAccount = (id: string, data: any) => {
  return updateData(
    `${apiUrl.CONTACT_LIST_URL}${id}/set-primary-account/`,
    data
  )
}

// export const getContactAccountDetails = (id: any) => {
//   return getData(`${apiUrl.CONTACT_LIST_URL}${id}/accounts/?page=1`)
// }

// const accountsfetchData = async (id: string, input: QueryParams) => {
//   const url = buildUrlWithParams(`${apiUrl.CONTACT_LIST_URL}${id}/accounts/`, {

const accountsfetchData = async (id: string, input: QueryParams) => {
  const url = buildUrlWithParams(`${apiUrl.CONTACT_LIST_URL}${id}/accounts/`, {
    ...input,
  })
  const response = await getData(url)
  return response?.data
}

export const useContactAccountDetails = (id: string, input: QueryParams) => {
  return useQuery(
    ['assosiated_accout_list'],
    () => accountsfetchData(id, input),
    {
      refetchOnWindowFocus: false,
      //staleTime: 50000,
    }
  )
}
export const getContactDuplicate = (data: any, config: AxiosRequestConfig) =>
  getConfigData(
    `${apiUrl.CONTACT_LIST_URL}possible-contact-duplicates/${parseQueryParams(
      data ?? {}
    )}`,
    config
  )

export const listContactDuplicate = (id?: string, data?: any) =>
  getData(
    `${apiUrl.CONTACT_LIST_URL}${id}/contact-duplicates/${parseQueryParams(
      data
    )}`
  )

export const updateContactCre = (id: string, data: any) => {
  return postData(`${apiUrl.CONTACT_LIST_URL}${id}/allocate-cre`, data)
}
