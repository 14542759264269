import React from 'react'
import { IconProps } from '../../../common/types'

const MedicalOfficer: React.FC<IconProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
    >
      <path
        d="M27.5 11H5.5C4.36091 11 3.4375 11.9234 3.4375 13.0625V26.8125C3.4375 27.9516 4.36091 28.875 5.5 28.875H27.5C28.6391 28.875 29.5625 27.9516 29.5625 26.8125V13.0625C29.5625 11.9234 28.6391 11 27.5 11Z"
        stroke="black"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M13.0625 5.5H19.9375V2.75H13.0625V5.5ZM20.625 6.1875V11H23.375V6.1875H20.625ZM12.375 11V6.1875H9.625V11H12.375ZM19.9375 5.5C20.1198 5.5 20.2947 5.57243 20.4236 5.70136C20.5526 5.8303 20.625 6.00516 20.625 6.1875H23.375C23.375 5.27582 23.0128 4.40148 22.3682 3.75682C21.7235 3.11216 20.8492 2.75 19.9375 2.75V5.5ZM13.0625 2.75C12.1508 2.75 11.2765 3.11216 10.6318 3.75682C9.98716 4.40148 9.625 5.27582 9.625 6.1875H12.375C12.375 6.00516 12.4474 5.8303 12.5764 5.70136C12.7053 5.57243 12.8802 5.5 13.0625 5.5V2.75Z"
        fill="black"
      />
      <path
        d="M12.375 19.9375H20.625M16.5 15.8125V24.0625"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default MedicalOfficer
