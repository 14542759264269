import React from 'react'
import { IconProps } from '../../../common/types'

const MedicineIssue: React.FC<IconProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 35 35"
      fill="none"
    >
      <path
        d="M12.3958 17.5H22.6042L18.9583 13.8542M22.6042 21.875H12.3958L16.0417 25.5208M6.5625 13.125V30.625H28.4375V13.125L17.5 4.375L6.5625 13.125Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default MedicineIssue
