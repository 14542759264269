// import { getNestedProperty, useCurrencyFormat } from '../../utilities/parsers'
// import { useNavigate } from "react-router-dom"

// import EditIcon from '../../../../components/common/icons/Edit'

// import Icons from '../../../../components/common/icons'
// import { useNavigate } from "react-router-dom"

// import { Router } from "react-router-dom"

// const defaultColumnProps = {
//   sortable: true,
//   resizable: true,
//   isVisible: true,
// }
const defaultColumnProps1 = {
  sortable: false,
  isVisible: true,
  resizable: true,
}
// const defaultColumnProps2 = {
//   sortable: true,
//   isVisible: false,
//   resizable: true,
// }
// const defaultColumnProps3 = {
//   sortable: false,
//   isVisible: true,
//   resizable: true,
// }

// const navigate = useNavigate()

export const getColumns = ({}: any) => {
  const createRenderCell = (key: string, isCustom?: string) => (row: any) => {
    // const format = useCurrencyFormat

    // if (isCustom === 'amount') {
    //   const propertyValue = getNestedProperty(row, key)
    //   const numbers =
    //     typeof propertyValue === 'string' ? parseFloat(propertyValue) : 0

    //   return {
    //     cell: format(numbers),
    //     toolTip: getNestedProperty(row, key) ?? '',
    //   }
    // } else {
    //   return {
    //     cell: getNestedProperty(row, key),
    //     toolTip: getNestedProperty(row, key) ?? '',
    //   }
    // }

    // if (isCustom === 'action') {
    //   return {
    //     cell: (
    //       <div className="flex justify-start gap-4">
    //         <div
    //           className="cursor-pointer"
    //           onClick={() => onAction(row, 'edit')}
    //         >
    //           {/* <Link
    //             to={`${router_config.PHARMACIST.path}/${row.id}`}
    //             className="text-capitalize"
    //           > */}
    //           <EditIcon />
    //           {/* </Link> */}
    //         </div>
    //         <div>
    //           {/* <div className="border-2 border-MandatoryRed/15 rounded-[10px] p-1"> */}
    //           <Icons name="delete" onClick={() => onAction(row, 'delete')} />
    //           {/* </div> */}
    //         </div>
    //       </div>
    //     ),
    //     // toolTip: getNestedProperty(row, key) ?? '',
    //   }
    // }
    if (isCustom === 'status') {
      // console.log(row)
      return {
        cell: (
          <>
            {row?.status === 'Shortage' ? (
              // <div className="w-[127px] h-[40px] rounded-[20px] bg-ShortageStatus/15 flex justify-center items-center">
              <span className="text-amber-500">Shortage</span>
            ) : // </div>
            row?.status === 'Available' ? (
              // <div className="w-[127px] h-[40px] rounded-[20px] bg-AvailabilityMark/15 flex items-center justify-center">
              <span className="text-green-500">Available</span>
            ) : (
              // </div>
              // <div className="w-[127px] h-[40px] rounded-[20px] bg-RemoveButton/15 flex items-center justify-center">
              <span className="text-red-500">Out of Stock</span>
              // </div>
            )}
          </>
        ),
        // toolTip: getNestedProperty(row, key) ?? '',
      }
    }
  }

  const column = [
    // {
    //   title: 'Sl.No',
    //   field: 'id',
    //   ...defaultColumnProps1,
    //   fixed: true,
    //   sortKey: 'id',
    //   // rowClick: (row: any) => onViewAction(row, 'contactName'),
    //   // link: true,
    // },
    {
      title: 'Medicine Name',
      // renderCell: createRenderCell('cre_agent.name'),
      fixed: true,
      field: 'medicine_name',
      ...defaultColumnProps1,
      // customCell: true,
      // sortKey: 'cre_agent_user_first_name',
    },
    {
      title: 'Batch.No',
      field: 'batch_number',
      // fixed: true,
      ...defaultColumnProps1,
      // link: true,
      // sortKey: 'default_contact__first_name',
      // rowClick: (row: any) => onViewAction(row, 'contactName'),
    },

    {
      title: 'Unit of Packing',
      field: 'unit_of_packing',
      ...defaultColumnProps1,
      // sortKey: 'company_code',
      // renderCell: createRenderCell('primary_company.company_code'),

      // rowClick: (row: any) => onViewAction(row, 'business_name'),
    },
    {
      title: 'Available Stock',
      field: 'primary_stock',
      ...defaultColumnProps1,
      sortKey: '',
      // renderCell: createRenderCell('primary_company.business_name'),
      // rowClick: (row: any) => onViewAction(row, 'business_name'),
    },
    {
      title: 'Status',
      renderCell: createRenderCell('default_contact.mobile', 'status'),
      field: 'status',
      ...defaultColumnProps1,
      customCell: true,
      // link: true,
      // getpath: () => '/dashboard',
    },
    // {
    //   title: 'Action',
    //   renderCell: createRenderCell('default_contact.mobile', 'action'),
    //   field: 'mobile',
    //   ...defaultColumnProps1,
    //   customCell: true,
    //   // link: true,
    //   // getpath: () => '/dashboard',
    // },
  ]

  return column
}
