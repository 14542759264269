import moment from 'moment'

const defaultColumnProps1 = {
  sortable: false,
  isVisible: true,
  resizable: true,
}

export const getColumns = ({}: any) => {
  const createRenderCell = (key: string, isCustom?: string) => (row: any) => {
    if (isCustom === 'status') {
      // console.log(row)
      return {
        cell: <>{moment(row?.date).format('DD-MM-YYYY')}</>,
      }
    }
  }
  const column = [
    {
      title: 'Date',

      ...defaultColumnProps1,
      fixed: true,
      customCell: true,
      renderCell: createRenderCell('default_contact.mobile', 'status'),
      sortKey: 'id',
    },
    {
      title: 'Hospital Name',
      field: 'institution_name',
      fixed: true,
      ...defaultColumnProps1,
      // link: true,
      // sortKey: 'default_contact__first_name',
      // rowClick: (row: any) => onViewAction(row, 'contactName'),
    },

    {
      title: 'District',
      field: 'district_name',
      ...defaultColumnProps1,
    },
    {
      title: 'Doctor Name',
      field: 'doctor_name',
      ...defaultColumnProps1,

      sortKey: '',
    },
    {
      title: 'Specialization',
      field: 'specialization',
      ...defaultColumnProps1,

      sortKey: '',
    },
  ]

  return column
}
