// import FormBuilder from '../../../components/app/formBuilder/index'

import { Button } from '../../../../../components/common'
import { usePatients } from '../api'
import CommonForm from '../form'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
type ManagePhasrmacistProps = {
  handleSubmit: () => void
  setOp: any
}
const PrimaryStockCreate: React.FC<ManagePhasrmacistProps> = ({
  handleSubmit,
  setOp,
}) => {
  const methods = useForm<any>({
    // resolver: zodResolver(createSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const searchInput = {
    page_number: 1,
    page_size: 99999999999,
  }
  const { enqueueSnackbar } = useSnackbar()
  const { id } = useParams()
  console.log(id)
  const { data } = usePatients(searchInput)

  console.log(methods.watch())
  setOp(methods.watch().op)
  // methods.reset({})
  const handleOp = () => {
    data.map((item: any) => {
      if (item?.op_number == methods.watch().op) {
        console.log(item?.op_number, methods.watch().op)
        handleSubmit()
      } else {
        enqueueSnackbar('Patient does not exist', {
          variant: 'error',
        })
      }
    })
  }
  return (
    <>
      <div className="my-5 mx-8">
        <div className="grid grid-cols-3 gap-4">
          <div className="col-span-3">
            <FormProvider {...methods}>
              <CommonForm edit={true} />
            </FormProvider>
          </div>
        </div>
        <div className="flex gap-8 justify-center my-3">
          <Button
            // onClick={methods.handleSubmit}
            onClick={handleOp}
            label={'Add Prescription'}
            className="w-[175px]"
          />
        </div>
      </div>
    </>
  )
}
export default PrimaryStockCreate
