import React from 'react'
import { TabItemProps, TabProps } from '../../../common/types'

interface WrapperProps {
  children: React.ReactNode
  activeTab: string | number
}
interface ActiveTabProps {
  activeTab: string | number
}
const Wrapper: React.FC<WrapperProps> = ({ children, activeTab }) => {
  return (
    <div className="wrapper">
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            activeTab: activeTab,
          } as Partial<ActiveTabProps>)
        }
        return child
      })}
    </div>
  )
}

const MultiTabContainer: React.FC<TabProps> = ({
  data,
  onClick,
  children,
  activeTab,
}) => {
  const generateClassName = (tab: TabItemProps): string => {
    let generatedClassName =
      'transition-all duration-100 py-2.5 px-5 w-max text-sm leading-6 font-medium '
    if (tab.id === activeTab) {
      generatedClassName +=
        'text-white bg-primary border-2 rounded-lg cursor-default leading-normal'
    } else {
      generatedClassName += tab.disabled
        ? 'text-primaryText cursor-not-allowed border-transparent leading-normal border-2 rounded-lg'
        : 'text-primaryText cursor-pointer border-transparent leading-normal '
    }
    return generatedClassName
  }

  const handleClick = (item: TabItemProps) => {
    if (!item.disabled) {
      onClick(item)
    }
  }

  return (
    <div className="mt-[24px]">
      <div className="w-full">
        <div className="w-fit overflow-auto tab-scroll flex border-2 rounded-lg">
          {data.map((tab: TabItemProps) => (
            <div
              key={tab.id}
              className={generateClassName(tab)}
              onClick={() => handleClick(tab)}
            >
              <p className="w-max">{tab.label}</p>
            </div>
          ))}
        </div>
        <div className="tab-section bg-white dark:bg-[#394150] pt-[24px] rounded">
          <Wrapper activeTab={activeTab}>{children}</Wrapper>
        </div>
      </div>
    </div>
  )
}

export default MultiTabContainer
