import React from 'react'
import { IconProps } from '../../../common/types'

const MyOrder: React.FC<IconProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 28 28"
      fill="none"
    >
      <path
        d="M26.48 11.5918C26.4337 9.95305 26.2787 8.90805 25.7537 8.0168C25.0062 6.74805 23.6612 6.0418 20.9725 4.6318L18.4725 3.3193C16.2775 2.16805 15.18 1.5918 14 1.5918C12.82 1.5918 11.7225 2.1668 9.5275 3.3193L7.0275 4.6318C4.33875 6.0418 2.99375 6.74805 2.24625 8.0168C1.5 9.2843 1.5 10.863 1.5 14.0168V14.163C1.5 17.3193 1.5 18.898 2.24625 20.1655C2.99375 21.4343 4.33875 22.1405 7.0275 23.5518L9.5275 24.863C11.7225 26.0155 12.82 26.5918 14 26.5918C15.18 26.5918 16.2775 26.0168 18.4725 24.8643L20.9725 23.5518C23.6612 22.1405 25.0062 21.4355 25.7537 20.1668C26.2787 19.2755 26.4337 18.2305 26.48 16.5918M25.25 8.4668L20.25 10.9668M20.25 10.9668L19.625 11.2793L14 14.0918M20.25 10.9668V15.3418M20.25 10.9668L8.375 4.7168M14 14.0918L2.75 8.4668M14 14.0918V25.9668"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default MyOrder
