// import FormBuilder from '../../../components/app/formBuilder/index'

import FormBuilder from '../../../../components/app/formBuilder'
import { reasons } from '../store'
type Props = {
  edit?: boolean
}
// const CommonForm = (data?: any) => {
const CommonForm = ({ edit }: Props) => {
  const textField = (
    name: string,
    label: string,
    id: string,
    type: string,
    // placeholder: string,
    required = false,
    config: { data?: Array<{ name: string; id: string }> } = {}
  ) => ({
    name,
    label,
    id,
    type,
    // placeholder,
    ...(required ? { required: true } : {}),
    ...config,
    spacing: 4,
  })
  const formBuilderProps = [
    {
      ...textField('name', 'Medicine Name', 'name', 'text', false),
      disabled: true,
    },
    {
      ...textField(
        'unit_of_packing',
        'Unit of Packing (ml)',
        'unit_of_packing',
        'text',
        false
      ),
      disabled: true,
    },
    {
      ...textField(
        'quantity',
        'Replacing quantity',
        'quantity',
        'number',
        false
      ),
    },
    {
      ...textField(
        'reason',
        'Replacing reason',
        'reason',
        'custom_select',
        false,
        {
          data: reasons.map((reason) => ({
            name: reason.label,
            id: reason.label,
          })),
        }
      ),
      placeholder: 'Choose one',
    },
  ]

  // methods.reset({})

  return <FormBuilder data={formBuilderProps} edit={edit} />
}
export default CommonForm
