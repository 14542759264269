// import { getNestedProperty, useCurrencyFormat } from '../../utilities/parsers'
// import { useNavigate } from "react-router-dom"

import moment from 'moment'

// import { Link } from 'react-router-dom'
// import { router_config } from '../../configs/route.config'
// import { useNavigate } from "react-router-dom"

// import { Router } from "react-router-dom"

// const defaultColumnProps = {
//   sortable: true,
//   resizable: true,
//   isVisible: true,
// }
const defaultColumnProps1 = {
  sortable: false,
  isVisible: true,
  resizable: true,
}
// const defaultColumnProps2 = {
//   sortable: true,
//   isVisible: false,
//   resizable: true,
// }
// const defaultColumnProps3 = {
//   sortable: false,
//   isVisible: true,
//   resizable: true,
// }

// const navigate = useNavigate()

export const getColumns = ({}: any) => {
  const createRenderCell = (key: string, isCustom?: string) => (row: any) => {
    if (isCustom === 'status') {
      // console.log(row)
      return {
        cell: <>{moment(row?.date).format('DD-MM-YYYY')}</>,
      }
    }
  }
  const column = [
    {
      title: 'Name',
      field: 'name',
      fixed: true,
      ...defaultColumnProps1,
      // link: true,
      // sortKey: 'default_contact__first_name',
      // rowClick: (row: any) => onViewAction(row, 'contactName'),
    },
    {
      title: 'District',
      // renderCell: createRenderCell('cre_agent.name'),
      field: 'district_name',
      ...defaultColumnProps1,
      // customCell: true,
      // sortKey: 'cre_agent_user_first_name',
    },
    {
      title: 'Phone Number',
      field: 'phone_number',
      ...defaultColumnProps1,
      // sortKey: 'company_code',
      // renderCell: createRenderCell('primary_company.company_code'),

      // rowClick: (row: any) => onViewAction(row, 'business_name'),
      // link: true,
    },
    {
      title: 'Email',
      field: 'email',
      ...defaultColumnProps1,
    },
    {
      title: 'Amount',
      field: 'amount',
      ...defaultColumnProps1,
    },
    {
      title: 'Transaction Date',
      ...defaultColumnProps1,
      customCell: true,
      renderCell: createRenderCell('default_contact.mobile', 'status'),
      sortKey: 'id',
    },
  ]

  return column
}
