// import { Link } from 'react-router-dom'
// import { router_config } from '../../../configs/route.config'
// import EditIcon from '../../../components/common/icons/Edit'
// import DeleteIcon from '../../../components/common/icons/Delete'

import moment from 'moment'

// }
const defaultColumnProps1 = {
  sortable: false,
  isVisible: true,
  resizable: true,
}
// const statusInfo: 1 | 2 = 1

export const getColumns = ({}: any) => {
  const createRenderCell = (key: string, isCustom?: string) => (row: any) => {
    if (isCustom === 'expDate') {
      // console.log(row)
      return {
        cell: (
          <>
            {row.delivered_on && moment(row.delivered_on).format('DD-MM-YYYY')}
          </>
        ),
      }
    }
  }

  const column = [
    {
      title: 'Hospital Name',
      // renderCell: createRenderCell('cre_agent.name'),
      field: 'hospital_name',
      ...defaultColumnProps1,
      // customCell: true,
      // sortKey: 'cre_agent_user_first_name',

      customCell: true,
    },
    {
      title: 'Phone Number',
      field: 'phone_number',
      ...defaultColumnProps1,
      customCell: true,
    },
    {
      title: 'Email',
      field: 'email_id',
      ...defaultColumnProps1,

      sortKey: '',

      customCell: true,
    },
    // {
    //   title: 'Status',
    //   renderCell: createRenderCell('default_contact.mobile'),
    //   field: 'mobile',
    //   ...defaultColumnProps1,
    //   customCell: true,
    //   // link: true,
    //   // getpath: () => '/dashboard',
    // },

    {
      title: 'Delivered On',
      // field: 'exp_date',
      ...defaultColumnProps1,
      // sortKey: 'company_code',
      renderCell: createRenderCell('primary_company.company_code', 'expDate'),

      customCell: true,
    },
  ]

  return column
}
