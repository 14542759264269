// import { Router } from "react-router-dom"

// import { Link } from 'react-router-dom'
// import { router_config } from '../../../../configs/route.config'
// import Info from '../../../../components/common/icons/Info'
// import DeleteIcon from '../../../../components/common/icons/Delete'
// import { Button } from '../../../../components/common'

// const defaultColumnProps = {
//   sortable: true,
//   resizable: true,
//   isVisible: true,
// }
const defaultColumnProps1 = {
  sortable: false,
  isVisible: true,
  resizable: true,
}

export const getColumns = ({}: any) => {
  const createRenderCell = (key: string, isCustom?: string) => (row: any) => {
    // if (isCustom === 'action') {
    //   // console.log(row)
    //   return {
    //     cell: (
    //       <div className="flex justify-start gap-4">
    //         <div className="cursor-pointer">
    //           {/* <Link
    //             to={`${router_config.PHARMACIST.path}/${row.id}`}
    //             className="text-capitalize"
    //           > */}
    //           <div
    //             // className="border-2 border-MandatoryRed/15 rounded-[10px] p-1"
    //             onClick={() => onAction(row, 'delete')}
    //           >
    //             <DeleteIcon />
    //           </div>
    //           {/* </Link> */}
    //         </div>
    //         <div className="cursor-pointer">
    //           <Link
    //             to={`${router_config.HOSPITAL_ORDERS.path}/${router_config.HOSPITAL_PENDING_ORDER.path}/${row.id}`}
    //             className="text-capitalize"
    //           >
    //             <Info />
    //           </Link>
    //         </div>
    //       </div>
    //     ),
    //   }
    // }
    if (isCustom === 'status') {
      console.log(row)
      return {
        cell: (
          // <div className="w-[127px] h-[40px] rounded-[10px] bg-ShortageStatus/15 flex justify-center items-center">
          <span className="inline-block align-middle text-ShortageStatus items-center font-medium leading-normal">
            Pending
          </span>
          // </div>
        ),
      }
    }
  }
  const column = [
    // {
    //   title: 'Sl.No',
    //   field: 'id',
    //   ...defaultColumnProps1,
    //   // fixed: true,
    //   sortKey: 'id',
    //   // rowClick: (row: any) => onViewAction(row, 'contactName'),
    //   // link: true,
    // },

    {
      title: 'Manufacturer Name',
      // renderCell: createRenderCell('cre_agent.name'),
      field: 'name',
      ...defaultColumnProps1,
      // customCell: true,
      // sortKey: 'cre_agent_user_first_name',
    },

    {
      title: 'Phone Number',
      field: 'manufacturer_phone_number',
      ...defaultColumnProps1,
      // sortKey: 'company_code',
      // renderCell: createRenderCell('primary_company.company_code'),

      // rowClick: (row: any) => onViewAction(row, 'business_name'),
    },
    {
      title: 'Email',
      field: 'maunufacturer_email',
      ...defaultColumnProps1,
      sortKey: '',
      // renderCell: createRenderCell('primary_company.business_name'),
      // rowClick: (row: any) => onViewAction(row, 'business_name'),
    },

    // {
    //   title: 'Total Amount',
    //   field: 'email',
    //   ...defaultColumnProps1,
    //   link: true,
    //   sortKey: '',
    //   // renderCell: createRenderCell('primary_company.business_name'),
    //   // rowClick: (row: any) => onViewAction(row, 'business_name'),
    // },
    {
      title: 'Status',
      renderCell: createRenderCell('default_contact.mobile', 'status'),
      field: 'mobile',
      ...defaultColumnProps1,
      customCell: true,
    },
    // {
    //   title: 'Action',
    //   renderCell: createRenderCell('default_contact.mobile', 'action'),
    //   field: 'mobile',
    //   ...defaultColumnProps1,
    //   customCell: true,
    //   link: true,
    //   getpath: () => '/dashboard',
    // },
  ]

  return column
}
