// import { getNestedProperty, useCurrencyFormat } from '../../utilities/parsers'
// import { useNavigate } from "react-router-dom"

// import { useNavigate } from "react-router-dom"

// import { Router } from "react-router-dom"

// const defaultColumnProps = {
//   sortable: true,
//   resizable: true,
//   isVisible: true,
// }
const defaultColumnProps1 = {
  sortable: false,
  isVisible: true,
  resizable: true,
}
// const defaultColumnProps2 = {
//   sortable: true,
//   isVisible: false,
//   resizable: true,
// }
// const defaultColumnProps3 = {
//   sortable: false,
//   isVisible: true,
//   resizable: true,
// }

// const navigate = useNavigate()
// const statusInfo: 1 | 2 = 1
export const getColumns = ({}: any) => {
  const createRenderCell = (key: string, isCustom?: string) => (row: any) => {
    if (isCustom === 'status') {
      // console.log(row)
      return {
        cell: (
          <>
            {row.status === 'Waiting' ? (
              // <div className="w-[127px] h-[40px] rounded-[20px] bg-ShortageStatus/15 flex justify-center">
              <span className="inline-block align-middle text-ShortageStatus pt-1.5 font-medium leading-normal">
                In Examination
              </span>
            ) : row.status === 'Examined' ? (
              // <div className="w-[127px] h-[40px] rounded-[20px] bg-RemoveButton/15 flex justify-center">
              <span className="inline-block align-middle text-primary pt-1.5 font-medium leading-normal">
                Waiting
              </span>
            ) : (
              // </div>
              <span className="inline-block align-middle text-blue-500 pt-1.5 font-medium leading-normal">
                Dispensed
              </span>
            )}
          </>
        ),
      }
    }
  }

  const column = [
    {
      title: 'OP Number',
      field: 'op_number',
      ...defaultColumnProps1,
      fixed: true,
      sortKey: 'id',
      colWidth: 150,
      // rowClick: (row: any) => onViewAction(row, 'contactName'),
      // link: true,
    },
    {
      title: 'Unique ID',
      field: 'id',
      fixed: true,
      ...defaultColumnProps1,
      colWidth: 150,
      // link: true,
      // sortKey: 'default_contact__first_name',
      // rowClick: (row: any) => onViewAction(row, 'contactName'),
    },
    {
      title: 'Patient Name',
      // renderCell: createRenderCell('cre_agent.name'),
      field: 'name',
      ...defaultColumnProps1,
    },
    {
      title: 'Age',
      field: 'age',
      ...defaultColumnProps1,
    },
    // {
    //   title: 'Available Stock',
    //   field: 'primary_stock',
    //   ...defaultColumnProps1,

    //   sortKey: '',
    // },
    // {
    //   title: 'Status',
    //   renderCell: createRenderCell('default_contact.mobile'),
    //   field: 'mobile',
    //   ...defaultColumnProps1,
    //   customCell: true,
    //   // link: true,
    //   // getpath: () => '/dashboard',
    // },
    {
      title: 'Status',
      renderCell: createRenderCell('default_contact.mobile', 'status'),
      field: 'status',
      ...defaultColumnProps1,
      customCell: true,
    },
  ]

  return column
}
