const defaultColumnProps1 = {
  sortable: false,
  isVisible: true,
  resizable: true,
}

export const getColumns = ({}: any) => {
  const column = [
    {
      title: 'Medicine name',
      field: 'name',
      fixed: true,
      ...defaultColumnProps1,
    },
    {
      title: 'Manufacturer',
      field: 'manufacturer_name',
      ...defaultColumnProps1,
    },
    {
      title: 'Unit of Packing',
      field: 'unit_of_packing',
      ...defaultColumnProps1,
    },
    {
      title: 'Requested Quantity',
      field: 'quantity',
      ...defaultColumnProps1,
    },
  ]

  return column
}
