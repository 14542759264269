import { useMutation } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { updateJsonData } from '../../../../../apis/api.helpers'
import apiUrl from '../../../../../apis/api.url'
import { getErrorMessage } from '../../../../../utilities/parsers'

export const updateSecondaryStocks = (data: {
  data: any
  stock_id: string
}) => {
  // console.log(data)
  return updateJsonData(`${apiUrl.UPDATE_STOCKS}/${data?.stock_id}`, data?.data)
}

export const useUpdateSecondaryStocks = (
  handleSubmission: (input: any, id: string) => void
) => {
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(updateSecondaryStocks, {
    onSuccess: (res: any) => {
      handleSubmission(res.data, res.id)
    },

    onError: (error: any) => {
      console.log(error)
      console.log(typeof error?.response?.data?.message)
      if (typeof error?.response?.data?.message === 'string') {
        enqueueSnackbar(getErrorMessage(error?.response?.data?.message), {
          variant: 'error',
        })
      } else {
        error?.response?.data?.message &&
          error?.response?.data?.message.map((item: any) => {
            enqueueSnackbar(getErrorMessage(item), {
              variant: 'error',
            })
          })
      }
    },
  })
}
