import moment from 'moment'
import { QbsTable } from 'qbs-react-grid'
import { useCallback, useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

// import Export from '../../components/app/export'
// import { PrimaryFilterComponent } from '../../components/app/filterComponents/primaryFilter'
// import Button from '../../components/common/buttons/Button'
// import Icons from '../../components/common/icons/index'
// import ListingHeader from '../../../../components/common/ListingTiles'
import { router_config } from '../../../../../configs/route.config'
import { useContactFilterStore } from '../../../../../store/filterSore/contactStore'
import { statusClassGen } from '../../../../../utilities/generators'
import { getSortedColumnName } from '../../../../../utilities/parsers'
import { useOpPatients } from '../api'
import { getColumns } from './createPatientsColumns'
import { ConfirmModal, DialogModal } from '../../../../../components/common'
import { calcWindowHeight } from '../../../../../utilities/calcHeight'
import Icons from '../../../../../components/common/icons'
import ListingHeader from '../../../../../components/common/ListingTiles'
import PatientsForm from '../details'

// import Create from './create'
// import { GetFilterDetails } from './filterProperties'

const AllPatientsAddNew = () => {
  const navigate = useNavigate()
  const [editModalActive, setEditModalActive] = useState(false)
  const [successModalActive, setSuccessModalActive] = useState(false)
  const [mergeIsActive, setMergeIsActve] = useState(false)
  const [visitedHospital, setVisitedHospital] = useState(null)
  const [update, setUpdate] = useState<boolean>(false)
  const { pageParams, setPageParams, selectedRows, setSelectedRows } =
    useContactFilterStore()
  // const { advanceFilter, setAdvanceFilter } = useContactFilterStore()
  // const [showDetail, setShowDetail] = useState(true)
  const [createData, setCreateData] = useState({})

  const { page, page_size, search, ordering, filters, filterProps } = pageParams

  const searchParams = {
    page_number: page,
    page_size: page_size,
    name: search,
    ordering: ordering,
    ...filters,
  }
  const { data, refetch, isFetching } = useOpPatients(searchParams)
  useEffect(() => {
    refetch()
  }, [page, page_size, search, ordering, filters, refetch])
  // console.log(customSearch)
  const onChangeRowsPerPage = (count: any) => {
    setPageParams({
      ...pageParams,
      page_size: count,
      page: 1,
    })
  }
  const onChangePage = (row: any) => {
    setPageParams({
      ...pageParams,
      page: row,
    })
  }

  const onViewAction = (r: any, from: string) => {
    console.log(r, from)
  }
  const handleSort = (orderColumn: any, orderDirection: any) => {
    setPageParams({
      ...pageParams,
      sortColumn: orderColumn,
      sortType: orderDirection,
      ordering: getSortedColumnName(orderColumn, orderDirection),
    })
  }
  const handleSeach = (key?: string) => {
    setPageParams({
      ...pageParams,
      search: key as string,
      page: 1,
    })
  }
  const handleOnSlect = (rows: any) => {
    setSelectedRows([...rows])
  }
  // const handleColumnToggle = (columns: any) => {
  //   // console.log(columns)
  // }
  // const handleTileClick = (id: string) => {
  //   // console.log(id)
  // }
  // const basicData = {
  //   title: '',
  //   icon: '',
  // }

  useEffect(() => {
    handleDatepicker()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterProps.endDate])

  const handleDatepicker = useCallback(() => {
    setPageParams({
      ...pageParams,
      filters: {
        ...filters,
        fromDate: filterProps?.startDate
          ? moment(filterProps?.startDate).format('DD-MM-YYYY')
          : '',
        toDate: filterProps?.endDate
          ? moment(filterProps.endDate).format('DD-MM-YYYY')
          : '',
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterProps.endDate])

  const handleMerge = () => {
    navigate(`${router_config.CONTACT_MERGE.path}`)
    setMergeIsActve(true)
  }

  const onAction = (r: any, from: string) => {
    console.log(r, from)
    switch (from) {
      case 'add':
        handleEditAction(r)
        setUpdate(true)
    }
  }

  const handleEditAction = (r: any) => {
    setCreateData(r)
    setEditModalActive(true)
  }
  const handleEditClose = () => {
    setEditModalActive(false)
  }
  const handlSubmitEdit = () => {
    refetch()
    setEditModalActive(false)
    setSuccessModalActive(true)
  }
  const handleCloseModal = () => {
    // navigate(`${router_config.PHARMACIST.path}`)
    setSuccessModalActive(false)
  }
  const [columns, setColumns] = useState<any>(() =>
    getColumns({
      onViewAction: onViewAction,
      statusClassGen: statusClassGen,
      onAction: onAction,
    })
  )

  const handleResetColums = () => {
    setColumns([
      ...getColumns({
        onViewAction: onViewAction,
        statusClassGen: statusClassGen,
      }),
    ])
  }
  if (mergeIsActive) {
    return <Outlet context={{ selectedRows, refetch }} />
  }
  const activity = {
    hideExport: true,
  }
  const basicData = {
    title: 'Patients',
    icon: 'nnn',
  }

  const handleRadioChange = (value: any) => {
    if (value === 'no') {
      setEditModalActive(true)
      setUpdate(false)
    } else {
      setVisitedHospital(value)
      setUpdate(true)
    }
  }

  return (
    <>
      <ListingHeader data={basicData} actionProps={activity} />
      <div className="py-[10px] px-[24px]">
        <div className="text-medium text-s leading-normal">
          Has the patient visited any AYUSH hospitals previously?
        </div>
        <div className="flex my-2">
          <div className="text-medium text-s leading-normal me-4 text-primary">
            Yes
          </div>
          <input
            type="radio"
            value="yes"
            checked={visitedHospital === 'yes'}
            onChange={() => handleRadioChange('yes')}
          />
          <div className="text-medium text-s leading-normal mx-4 text-primary">
            No
          </div>
          <input
            type="radio"
            value="no"
            checked={visitedHospital === 'no'}
            onChange={() => handleRadioChange('no')}
          />
        </div>
      </div>
      <hr></hr>
      {visitedHospital === 'yes' && (
        <div className="">
          {/* <PageTiles data={dashtiledata} handleTileClick={handleTileClick} /> */}
          <div className=" py-[24px] px-[24px]">
            <QbsTable
              columns={columns}
              dataRowKey="id"
              selectedRows={selectedRows}
              search
              isLoading={isFetching}
              toolbar
              handleColumnSort={handleSort}
              height={calcWindowHeight(452)}
              data={data?.patients ?? []}
              handleResetColumns={() => handleResetColums()}
              // sortType={sortType}
              // sortColumn={sortColumn}
              pagination
              paginationProps={{
                onPagination: onChangePage,
                total: data?.total_count,
                currentPage: pageParams?.page,
                rowsPerPage: Number(pageParams?.page_size ?? data?.page_size),
                onRowsPerPage: onChangeRowsPerPage,
                dropOptions: [10, 20, 30, 50, 100, 200],
              }}
              actionProps={[
                {
                  title: 'Add to OP',
                  action: (rowData) => onAction(rowData, 'add'),
                  icon: <Icons name="plus" />,
                  toolTip: 'Add to OP',
                },
              ]}
              selectedRowActions={[
                {
                  actionTitle: 'Merge',
                  action: () => handleMerge(),
                  customHide: '>2',
                },
              ]}
              searchValue={pageParams?.search}
              onSearch={handleSeach}
              asyncSearch
              handleSearchValue={(key?: string) => handleSeach(key)}
              onSelect={handleOnSlect}
              columnToggle
            />
          </div>
        </div>
      )}
      {editModalActive && (
        <DialogModal
          isOpen={true}
          body={
            <PatientsForm
              handlSubmitEdit={handlSubmitEdit}
              data={createData}
              update={update}
              edit={false}
            />
          }
          onClose={handleEditClose}
          // title={'Add New Pharmacist'}
        />
      )}
      {successModalActive && (
        <>
          <ConfirmModal
            isOpen={true}
            body={
              <div className="font-medium text-primary leading-8 text-xl">
                Patient Added Successfully{' '}
              </div>
            }
            topIcon="check-circle"
            onClose={handleCloseModal}
            // title={'Add New Pharmacist'}
          />
        </>
      )}
    </>
  )
}

export default AllPatientsAddNew
