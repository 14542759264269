import { z } from 'zod'

// import noLeadingSpaces from '../../../../utilities/noLeadingSpaces'

export const formSchema = z.object({
  name: z.string(),
  id: z.number(),
  state: z.string(),
})

export type CreateSchema = z.infer<typeof formSchema>
