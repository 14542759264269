import { Button, ConfirmModal } from '../../../components/common'
import { useNavigate, useParams } from 'react-router-dom'
import { router_config } from '../../../configs/route.config'
import CommonForm from '../institution_form'
import { FormProvider, useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import { CreateSchema, formSchema } from './schema'
import { zodResolver } from '@hookform/resolvers/zod'
import { useInstitution, useUpdateInstitution } from './api'
import ListingHeader from '../../../components/common/ListingTiles'
const InstitutionDetail = () => {
  const navigate = useNavigate()
  const [edit, setEdit] = useState(true)
  const [setCustomBack] = useState(true)

  const [successModalActive, setSuccessModalActive] = useState(false)
  const param = useParams()
  // console.log(param.id)
  const institutionId = param.id
  const { data, refetch } = useInstitution(institutionId)
  useEffect(() => {
    refetch()
  }, [refetch, institutionId])
  // console.log(data)
  const onSuccess = () => {
    setSuccessModalActive(true)
  }

  const { mutate } = useUpdateInstitution(onSuccess)
  const methods = useForm<CreateSchema>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: { state: 'kerala' },
  })
  useEffect(() => {
    if (data) {
      methods.reset({
        ...data,
        district_name: data?.district,
        district_id: data?.district_id,
        lok_sabha_constituency_name: data?.lok_sabha_constituency,
        assembly_constituency_name: data?.assembly_consistency,
        lok_sabha_constituency_id: data?.lok_sabha_constituency_id,
        assembly_constituency_id: data?.assembly_consistency_id,
        image: data?.image_url,
        image_name: data?.image_name,
      })
    }
  }, [data, methods])

  const handleEdit = () => {
    setEdit(true)
  }
  // const handleSubmit = () => {
  //   setSuccessModalActive(true)
  // }
  const handleCloseModal = () => {
    navigate(`${router_config.INSTITUTIONS.path}`)
    setSuccessModalActive(false)
  }
  const onSubmit = (payload: CreateSchema) => {
    // console.log('hi')
    // setSuccessModalActive(true)
    // console.log(payload)
    const data = {
      institution: {
        ...payload,
        user_attributes: {
          email: payload.email,
          phone_number: payload.phone_number,
        },
        ...(typeof payload.image === 'string'
          ? { image: {} }
          : { image: payload.image }),
      },
    }
    mutate({ data, institutionId })
  }
  const basicData = {
    title: 'Edit Institution',
    icon: 'stock',
  }
  const activity = {
    hideExport: true,
  }
  return (
    <>
      <ListingHeader
        data={basicData}
        actionProps={activity}
        setCustomBack={setCustomBack}
      />
      <div className=" px-[24px] py-[24px]">
        <div className="">
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="grid md:grid-cols-3 md:gap-8 sm:grid-cols-1 sm:gap-4">
              <FormProvider {...methods}>
                <CommonForm edit={true} />
              </FormProvider>
            </div>
            <div className="flex gap-4 justify-center">
              {!edit ? (
                <Button label={'Edit'} onClick={handleEdit} />
              ) : (
                <Button type="submit" label={'Submit'} />
              )}
            </div>
          </form>
          {successModalActive && (
            <ConfirmModal
              isOpen={true}
              body={
                <div className="font-medium text-primary leading-8 text-xl">
                  Institution Edited Successfully
                </div>
              }
              topIcon="check-circle"
              onClose={handleCloseModal}
              // title={'Add New Pharmacist'}
            />
          )}
        </div>
      </div>
    </>
  )
}
export default InstitutionDetail
