import React, { useEffect, useState } from 'react'
import Button from '../buttons/Button'
// import { Button, Icon } from '..'
import Icons from '../icons'
// import ManagedList from './ManagedList'
import Tooltip from '@mui/material/Tooltip'
import Select from 'react-select'
import {
  fetchMode,
  useMedicineListing,
} from '../../../pages/Doctor/patients/all/api'

type BasicDataProps = {
  title: string | number
  icon?: string
}

// type OptionsProps = {
//   value: string
//   label?: string
// }
// const options: OptionsProps[] = [
//   { value: 'Ayurveda', label: 'Ayurveda' },
//   { value: 'Homeo', label: 'Homeo' },
//   { value: 'Unani', label: 'Unani' },
//   { value: 'Yoga', label: 'Yoga' },
//   { value: 'Siddha', label: 'Siddha' },
// ]
// type ActionProps = {
//   id: number
//   label: string
//   icon?: string
//   isOutlined?: boolean
// }

type DetailTileProps = {
  data: BasicDataProps
  onActionClick?: () => void
  // onHandleExport?: () => void
  handleUploadFile?: (file: any) => void
  actionProps?: any
  actionTitle?: any
  // showManagedList?: boolean
  // groupData?: any
  // groupValue?: any
  // setGroupNameCode?: any
  setCustomType?: any
  setCustomSearch?: any
  setCustomRemarks?: any
  setCustomName?: any
  manufactures?: any[]
  medicines?: any[]
  customName?: any
  customSearch?: any
  setCustomPhone?: any
  customPhone?: any
  customRemarks?: any
  customDose?: any
  setCustomDose?: any
  setCustomEnterName?: any
  customEnterName?: any
}

const CustomisedheaderPrescription: React.FC<DetailTileProps> = ({
  data,
  onActionClick,
  // onHandleExport,
  actionProps,
  actionTitle,
  // showManagedList = false,
  // groupData = [],
  // groupValue,
  // setGroupNameCode,
  handleUploadFile,
  setCustomSearch,
  customSearch,
  setCustomName,
  setCustomPhone,
  customName,
  customPhone,
  customRemarks,
  setCustomRemarks,
  setCustomDose,
  customDose,
  setCustomEnterName,
  customEnterName,
}) => {
  const [selectedOption, setSelectedOption] = useState<any>(null)
  const [tValue, setTValue] = useState<any>('')
  const [options, setOptions] = useState<any>()
  // const [category, setCategory] = useState<number>(2)
  const [dose, setDose] = useState<any>([])
  const [administration, setAdministration] = useState<any>([])
  const searchInput = {
    name: tValue,
  }
  // console.log(searchInput, tValue)
  const { data: medicine } = useMedicineListing(searchInput)
  // const setTypedValue = (e: any) => {
  //   setTValue(e.target.value)
  //   console.log(tValue)
  // }
  // const category = 2
  // useEffect(() => {
  //   refetch()
  //   // fetchModeData()
  // }, [searchInput])

  useEffect(() => {
    // if (options) {
    fetchModeData(selectedOption?.category)
    // }
  }, [selectedOption])

  const fetchModeData = (r: any) => {
    setCustomName(selectedOption)
    if (r) {
      fetchMode({ category_id: r }).then((res) => {
        setDose(res?.doses)
        setAdministration(res?.administrations)
      })
    }
  }
  console.log(medicine, options)
  useEffect(() => {
    const data: any = []
    medicine?.map((item: any) => {
      const a = {
        value: item?.id,
        label: item?.name,
        category: item?.category_id,
      }
      data.push(a)
    })
    setOptions(data)
  }, [medicine])
  const handleFileChange = (event: any) => {
    // Access the uploaded file using event.target.files[0]
    // console.log('1', event)
    const uploadedFile = event.target.files[0]

    handleUploadFile?.(uploadedFile)
    // console.log('3')
  }
  console.log(medicine, selectedOption)
  const handleButtonClick = () => {
    // Programmatically trigger the file input
    const fileInput = document.getElementById('file-upload')
    if (fileInput) {
      fileInput.click()
    }
  }

  const handleDownload = () => {
    const excelFilePath = '/Hospital In charge Excel sheet format .xlsx'
    const link = document.createElement('a')
    link.download = 'Hospital In charge Excel sheet format .xlsx'
    link.href = excelFilePath
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  console.log(
    customName,
    selectedOption,
    customDose,
    customSearch,
    customPhone,
    customRemarks
  )

  return (
    <>
      <div className="flex justify-between flex-wrap gap-3 items-center border-formBorder">
        <div className="flex gap-6">
          {data.icon && data.title && (
            <div className="flex items-center flex-wrap gap-5 text-[#222] ">
              <div className="flex items-center gap-3">
                {data?.icon && (
                  <div className="w-10 h-10 bg-primary flex  items-center justify-center rounded-[4px]">
                    <Icons className="iconWhite" name={data?.icon} />
                  </div>
                )}
                <div className="">
                  <div className="font-bold text-lg text-primaryText">
                    {data.title}
                  </div>
                </div>
              </div>
            </div>
          )}
          {setCustomName && (
            <div>
              {/* <input
                type="text"
                placeholder="Search Medicine Name"
                name="search"
                className="py-1 px-2 bg-white w-full h-full border-none"
                value={customName}
                onChange={(e) => setCustomName(e.target.value)}
              /> */}
              <Select
                defaultValue={selectedOption}
                onChange={setSelectedOption}
                onInputChange={setTValue}
                options={options}
                className="rounded-xl  w-100 h-auto w-[220px]"
                noOptionsMessage={() => 'No Medicines Found'}
                placeholder="Select Medicine"
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,

                    borderColor:
                      state.isFocused || state?.menuIsOpen
                        ? '#e5e7eb'
                        : '#e5e7eb',
                    borderRadius: '.5rem',
                  }),
                }}
              />
            </div>
          )}
          {setCustomEnterName && (
            <div className="border rounded-lg px-2  py-1.5 flex items-center h-auto w-[220px] table-header-input">
              <input
                type="text"
                placeholder="Enter Medicine Name"
                name="search"
                className="py-1 px-2 bg-white w-full h-full border-none"
                value={customEnterName}
                onChange={(e) => setCustomEnterName(e.target.name)}
                // onChange={(e)=> setDosage(e)}
              />
            </div>
          )}
          {setCustomDose && (
            <div className="table-header-select border rounded-lg h-auto">
              {/* <input
                type="text"
                placeholder="Medicine Dose (ml)"
                name="search"
                className="py-1 px-2 bg-white w-full h-full border-none"
                value={customDose}
                onChange={(e) => setCustomDose(e.target.value)}
              /> */}
              <select
                name="institution"
                id="institutions"
                // disabled = {disabled}
                className="py-1 px-2 bg-white w-full h-full"
                value={customDose}
                onChange={(e) => setCustomDose(e.target.value)}
                // onChange={(e) => setDosage(e)}
              >
                <option value="select">Select a Dosage </option>
                {dose?.map((item: any, i: number) => (
                  <>
                    <option
                      key={i}
                      value={JSON.stringify({ id: item?.id, dose: item?.dose })}
                    >
                      {item.dose}
                    </option>
                  </>
                ))}
              </select>
            </div>
          )}

          {setCustomSearch && (
            <div className="table-header-select border rounded-lg h-auto">
              <select
                name="institution"
                id="institutions"
                value={customSearch}
                className="py-1 px-2 bg-white w-full h-full"
                onChange={(e) => setCustomSearch(e.target.value)}
              >
                <option value="select" className="">
                  Mode of Administration
                </option>
                {administration?.map((item: any, i: number) => (
                  <>
                    <option
                      key={i}
                      value={JSON.stringify({ id: item?.id, mode: item?.mode })}
                    >
                      {item.mode}
                    </option>
                  </>
                ))}
              </select>
            </div>
          )}
          {setCustomPhone && (
            <div className="border rounded-lg px-3 py-1.5 flex items-center h-auto table-header-input">
              <input
                type="number"
                placeholder="Enter Medicine Quantity"
                name="search"
                className=" px-2 h-full w-[150px] 2xl:w-[200px]"
                value={customPhone}
                onChange={(e) => setCustomPhone(e.target.value)}
              />
            </div>
          )}

          {!actionProps?.hideExport && (
            <Tooltip
              title={
                <div
                  className="cursor-pointer text-m font-normal flex items-end px-2 text-white underline"
                  onClick={handleDownload}
                >
                  Download Excel Format
                </div>
              }
              placement="top"
              className="bg-white"
            >
              <span data-testid="dfssd" className="text-center text-white">
                <div>
                  <label htmlFor="file-upload">
                    <Button
                      label="Upload Excel"
                      icon="upload"
                      outlined={true}
                      // onClick={onHandleExport}
                      onClick={handleButtonClick}
                      className="w-[155px]"
                    />
                  </label>
                  <input
                    type="file"
                    id="file-upload"
                    style={{ display: 'none' }}
                    onChange={(e) => {
                      // console.log('this is the function');
                      handleFileChange(e)
                    }}
                  />
                </div>
              </span>
            </Tooltip>
          )}
          {actionProps?.actionCancel && (
            <Button
              label="Cancel"
              outlined={true}
              primary={false}
              onClick={() => actionProps.actionCancel()}
            />
          )}
        </div>
        <div className="flex">
          {onActionClick && (
            <div>
              <Button
                label={actionTitle}
                icon={actionProps?.actionTitle ? '' : ''}
                onClick={onActionClick}
                disabled={actionProps?.disableSubmit}
                className="w-[150px]"
              />
            </div>
          )}
        </div>
      </div>
      <div className="mt-[24px]">
        {setCustomRemarks && (
          <div className="border rounded-lg px-3 py-1.5 flex items-center h-auto table-header-input">
            <input
              type="text"
              placeholder="Remarks"
              name="remark"
              className=" px-2 h-[50px] w-full "
              value={customRemarks}
              onChange={(e) => setCustomRemarks(e.target.value)}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default CustomisedheaderPrescription
