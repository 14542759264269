import React from 'react'
import { TabItemProps, TabProps } from '../../../common/types'

interface WrapperProps {
  children: React.ReactNode
  activeTab: string | number
}
interface ActiveTabProps {
  activeTab: string | number
}
const Wrapper: React.FC<WrapperProps> = ({ children, activeTab }) => {
  return (
    <div className="wrapper">
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            activeTab: activeTab,
          } as Partial<ActiveTabProps>)
        }
        return child
      })}
    </div>
  )
}

const TabContainer: React.FC<TabProps> = ({
  data,
  onClick,
  children,

  activeTab,
}) => {
  const generateClassName = (tab: TabItemProps): string => {
    let generatedClassName = 'w-max text-sm leading-6  font-medium '
    if (tab.id === activeTab) {
      generatedClassName +=
        ' text-primary dark:text-white font-medium text-m cursor-default leading-normal border-[#45B755]'
    } else {
      if (tab.disabled) {
        generatedClassName +=
          ' text-primaryText font-medium text-m cursor-not-allowed border-transparent leading-normal'
      } else {
        generatedClassName +=
          ' text-primaryText font-medium text-m cursor-pointer border-transparent leading-normal'
      }
    }
    return generatedClassName
  }
  const handleClick = (item: TabItemProps) => {
    if (!item.disabled) {
      onClick(item)
    }
  }
  return (
    <div className="px-[24px] py-[24px]">
      <div className="">
        <div className="w-full ">
          <div className="w-full overflow-auto  tab-scroll flex gap-11 border-b border-grey-light ">
            {data.map((tab: TabItemProps) => (
              <div
                key={tab.id}
                className={` transition-all duration-100 pb-2.5 ${generateClassName(
                  tab
                )}  border-b-2`}
                onClick={() => {
                  handleClick(tab)
                }}
              >
                <p className="w-max ">{tab.label}</p>
              </div>
            ))}
          </div>
          <div className="">
            <div className="tab-section bg-white dark:bg-[#394150] ">
              <Wrapper activeTab={activeTab}>{children}</Wrapper>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TabContainer
