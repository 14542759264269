import React from 'react'
import { IconProps } from '../../../common/types'

const LeadsIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00085 6V5.2C8.00085 3.43 8.00085 2 11.2009 2H12.8009C16.0009 2 16.0009 3.43 16.0009 5.2V6M14.0009 14.02C14.0009 14.01 14.0009 14.01 14.0009 14V13C14.0009 12 14.0009 12 13.0009 12H11.0009C10.0009 12 10.0009 12 10.0009 13V14.03M14.0009 14.02C14.0009 15.11 13.9909 16 12.0009 16C10.0209 16 10.0009 15.12 10.0009 14.03M14.0009 14.02C16.7009 13.68 19.3409 12.68 21.6509 11M10.0009 14.03C7.41085 13.74 4.87085 12.81 2.62085 11.27M16.0008 22C20.0208 22 20.7408 20.39 20.9508 18.43L21.7008 10.43C21.9708 7.99 21.2708 6 17.0008 6H7.0008C2.7308 6 2.0308 7.99 2.3008 10.43L3.0508 18.43C3.2608 20.39 3.9808 22 8.0008 22H16.0008Z"
        stroke="#999696"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default LeadsIcon
