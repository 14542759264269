import { useMutation } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { postJsonData } from '../../../../../apis/api.helpers'
// import apiUrl from '../../../../../apis/api.url'
import { getErrorMessage } from '../../../../../utilities/parsers'

export const forwardLoan = (input: any) => {
  const id = input?.id2
  const data = input?.data
  return postJsonData(`loans/${id}/forward_requests`, data)
}

export const useForwardRequest = (handleSubmission: (data: any) => void) => {
  const { enqueueSnackbar } = useSnackbar()
  return useMutation(forwardLoan, {
    onSuccess: (res: any) => {
      handleSubmission(res.data)
      // enqueueSnackbar('Fund Distributed Successfully', { variant: 'success' })
    },

    onError: (error: any) => {
      enqueueSnackbar(
        getErrorMessage(
          error?.response?.data?.message || 'Something went wrong'
        ),
        {
          variant: 'error',
        }
      )
    },
  })
}
