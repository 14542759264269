import moment from 'moment'
import { QbsTable } from 'qbs-react-grid'
import { useCallback, useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

// import Export from '../../components/app/export'
// import { PrimaryFilterComponent } from '../../components/app/filterComponents/primaryFilter'
// import Button from '../../components/common/buttons/Button'
// import Icons from '../../components/common/icons/index'
import ListingHeader from '../../../../components/common/ListingTiles'
import { router_config } from '../../../../configs/route.config'
import { useContactFilterStore } from '../../../../store/filterSore/contactStore'
import { statusClassGen } from '../../../../utilities/generators'
import { getSortedColumnName } from '../../../../utilities/parsers'
import { useContact } from './api'
import { getColumns } from './contactColumns'
import { ConfirmModal, DialogModal } from '../../../../components/common'
import StockEdit from './details'

// import Create from './create'
// import { GetFilterDetails } from './filterProperties'

const MedicineRequest = () => {
  const navigate = useNavigate()
  const [editModalActive, setEditModalActive] = useState(false)
  const [successModalActive, setSuccessModalActive] = useState(false)
  const [mergeIsActive, setMergeIsActve] = useState(false)
  const [customSearch, setCustomSearch] = useState('')
  // const [customType] = useState('')

  // const [openPopups, setOpenPopups] = useState(false)
  const { pageParams, setPageParams, selectedRows, setSelectedRows } =
    useContactFilterStore()
  // const { advanceFilter, setAdvanceFilter } = useContactFilterStore()
  // const [showDetail, setShowDetail] = useState(true)

  const { page, page_size, ordering, filters, filterProps } = pageParams

  const searchParams = {
    page_number: page,
    page_size: page_size,
    name: customSearch,
    ordering: ordering,
    ...filters,
  }
  const { data, refetch, isFetching } = useContact(searchParams)
  useEffect(() => {
    refetch()
  }, [page, page_size, ordering, filters, refetch, customSearch])
  // console.log(customSearch)
  const onChangeRowsPerPage = (count: any) => {
    setPageParams({
      ...pageParams,
      page_size: count,
      page: 1,
    })
  }
  const onChangePage = (row: any) => {
    setPageParams({
      ...pageParams,
      page: row,
    })
  }

  const onViewAction = (r: any, from: string) => {
    console.log(r, from)
  }
  const handleSort = (orderColumn: any, orderDirection: any) => {
    setPageParams({
      ...pageParams,
      sortColumn: orderColumn,
      sortType: orderDirection,
      ordering: getSortedColumnName(orderColumn, orderDirection),
    })
  }
  const handleSeach = (key?: string) => {
    setPageParams({
      ...pageParams,
      search: key as string,
      page: 1,
    })
  }
  const handleOnSlect = (rows: any) => {
    setSelectedRows([...rows])
  }
  // const handleColumnToggle = (columns: any) => {
  //   // console.log(columns)
  // }
  // const handleTileClick = (id: string) => {
  //   // console.log(id)
  // }
  const basicData = {
    title: '',
    icon: '',
  }
  // const dashtiledata = [
  //   {
  //     id: 1,
  //     tiles: [
  //       {
  //         icon: 'user',
  //         label: 'Total Contacts',
  //         total: '$100',
  //         count: 10,
  //         id: 1,
  //       },
  //       {
  //         icon: 'calendar',
  //         label: 'Active Contacts',
  //         total: 100,
  //         count: 10,
  //         id: 2,
  //       },
  //       {
  //         icon: 'calendar',
  //         label: 'Inactive Contacts',
  //         total: 100,
  //         count: 10,
  //         id: 3,
  //       },
  //     ],
  //   },
  //   {
  //     id: 2,
  //     tiles: [
  //       {
  //         label: 'Total Agents',
  //         icon: 'calendar',
  //         total: 100,
  //         count: 10,
  //         id: 1,
  //       },
  //       {
  //         label: 'Active Agents',
  //         icon: 'badge-check',
  //         total: 100,
  //         count: 0,
  //         id: 2,
  //       },
  //       {
  //         label: 'Total Orders',
  //         icon: 'calendar',
  //         isCount: true,
  //         id: 3,
  //       },
  //     ],
  //   },
  // ]
  // const openDrawer = () => {
  //   setDrawerOpen(true)
  // }
  // const openPopup = () => {
  //   setOpenPopups(true)
  // }
  // const handleClose = () => {
  //   setDrawerOpen(false)
  //   setOpenPopups(false)
  // }
  // const handleRefresh = () => {
  //   refetch()
  // }
  // const filterDetails = GetFilterDetails()

  // const handleFilterChange = (dta: any, name: string, id: string) => {
  //   const currentParams = useContactFilterStore.getState()?.pageParams || {}

  //   const getDefaultFilters = () => ({
  //     ...currentParams.filters,
  //     [id]: dta?.id ?? undefined,
  //   })

  //   const getDefaultFilterProps = () => ({
  //     ...currentParams.filterProps,
  //     [name]: dta[name] ?? undefined,
  //   })
  //   const filters = getDefaultFilters()
  //   const filterProps = getDefaultFilterProps()

  //   setPageParams({
  //     ...currentParams,
  //     page: 1,
  //     filters,
  //     filterProps,
  //   })
  // }

  useEffect(() => {
    handleDatepicker()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterProps.endDate])

  const handleDatepicker = useCallback(() => {
    setPageParams({
      ...pageParams,
      filters: {
        ...filters,
        fromDate: filterProps?.startDate
          ? moment(filterProps?.startDate).format('DD-MM-YYYY')
          : '',
        toDate: filterProps?.endDate
          ? moment(filterProps.endDate).format('DD-MM-YYYY')
          : '',
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterProps.endDate])

  // const handleFilterDateChange = (event: any, desc: any, desc2: any) => {
  //   setPageParams({
  //     ...pageParams,
  //     filterProps: {
  //       ...filterProps,
  //       [desc]: event?.value[0],
  //       [desc2]: event?.value[1],
  //     },
  //   })
  // }

  // const handleSHowHide = () => {
  //   return advanceFilter?.filterParams?.find((item) => item.isChecked)
  //     ? true
  //     : false
  // }
  const handleMerge = () => {
    navigate(`${router_config.CONTACT_MERGE.path}`)
    setMergeIsActve(true)
  }
  // const handlePath = () => {
  //   if (location.pathname === router_config.CONTACT_MERGE.path) {
  //     setMergeIsActve(true)
  //   } else {
  //     setMergeIsActve(false)
  //   }
  // }

  // useEffect(() => {
  //   handlePath()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [location.pathname])
  const onAction = (r: any, from: string) => {
    console.log(r, from)
    switch (from) {
      case 'edit':
        handlSubmitEdit()
    }
  }

  const handleEditAction = () => {
    setEditModalActive(true)
  }
  const handleEditClose = () => {
    setEditModalActive(false)
    handleEditAction()
  }
  const handlSubmitEdit = () => {
    setEditModalActive(false)
    setSuccessModalActive(true)
  }
  const handleCloseModal = () => {
    // navigate(`${router_config.PHARMACIST.path}`)
    setSuccessModalActive(false)
  }
  const [columns, setColumns] = useState<any>(() =>
    getColumns({
      onViewAction: onViewAction,
      statusClassGen: statusClassGen,
      onAction: onAction,
    })
  )

  const handleResetColums = () => {
    setColumns([
      ...getColumns({
        onViewAction: onViewAction,
        statusClassGen: statusClassGen,
      }),
    ])
  }
  if (mergeIsActive) {
    return <Outlet context={{ selectedRows, refetch }} />
  }
  const activity = {
    hideExport: true,
  }

  return (
    <div className="">
      <ListingHeader
        data={basicData}
        // onHandleExport={openPopup}
        actionProps={activity}
        setCustomSearch={setCustomSearch}
      />
      {editModalActive && (
        <DialogModal
          isOpen={true}
          body={
            <StockEdit
              handlSubmitEdit={handlSubmitEdit}
              handleEditClose={handleEditClose}
            />
          }
          onClose={handleEditClose}
          // title={'Add New Pharmacist'}
        />
      )}
      {successModalActive && (
        <>
          <ConfirmModal
            isOpen={true}
            body={
              <div className="font-medium text-primary leading-8 text-xl">
                Request accepted
              </div>
            }
            topIcon="check-circle"
            onClose={handleCloseModal}
            // title={'Add New Pharmacist'}
          />
        </>
      )}
      {/* <PageTiles data={dashtiledata} handleTileClick={handleTileClick} /> */}
      <div className=" pt-8">
        <QbsTable
          data={data ?? []}
          dataRowKey="id"
          // toolbar={true}
          selectedRows={selectedRows}
          // selection={true}
          // searchValue={searchKey}
          // handleColumnToggle={handleColumnToggle}
          // search={true}
          isLoading={isFetching}
          sortType={pageParams.sortType}
          sortColumn={pageParams.sortColumn}
          handleColumnSort={handleSort}
          columns={columns}
          tableBodyHeight="calc(55vh)"
          pagination={true}
          handleResetColumns={() => handleResetColums()}
          paginationProps={{
            onPagination: onChangePage,
            total: data?.count,
            currentPage: pageParams?.page,
            rowsPerPage: Number(pageParams?.page_size ?? data?.page_size),
            onRowsPerPage: onChangeRowsPerPage,
            dropOptions: [10, 20, 30, 50, 100, 200],
          }}
          height={100}
          minHeight={100}
          // tableHeaderActions={}
          // toolbar={false}
          headerHeight={60}
          // classes={["font-size :19px"]}
          actionProps={
            [
              // {
              //   icon: <Icons name="eye" />,
              //   action: (row) => onViewAction(row, ''),
              //   title: 'view',
              // },
            ]
          }
          // primaryFilter={
          //   <PrimaryFilterComponent
          //     filterParams={filterDetails}
          //     handleFilterChange={handleFilterChange}
          //     filterProps={filterProps}
          //     advanceFilter={advanceFilter}
          //     handleFilterDateChange={handleFilterDateChange}
          //     isPrimary={true}
          //     setAdvanceFilter={setAdvanceFilter}
          //   />
          // }
          // advancefilter={
          //   showDetail && (
          //     <PrimaryFilterComponent
          //       filterParams={filterDetails}
          //       handleFilterDateChange={handleFilterDateChange}
          //       handleFilterChange={handleFilterChange}
          //       filterProps={filterProps}
          //       isPrimary={false}
          //       advanceFilter={advanceFilter}
          //     />
          //   )
          // }
          selectedRowActions={[
            {
              actionTitle: 'Merge',
              action: () => handleMerge(),
              customHide: '>2',
            },
          ]}
          searchValue={pageParams?.search}
          onSearch={handleSeach}
          asyncSearch
          handleSearchValue={(key?: string) => handleSeach(key)}
          onSelect={handleOnSlect}
          columnToggle
          // tableHeaderActions={
          //   <div className="flex gap-2">
          //     {handleSHowHide() && (
          //       <Button
          //         onClick={() => {
          //           setShowDetail(!showDetail)
          //         }}
          //         label={showDetail ? 'Hide' : 'Show'}
          //         className="se"
          //         primary
          //       />
          //     )}
          //   </div>
          // }
        />
      </div>
      {/* <Create
          isDrawerOpen={isDrawerOpen}
          handleClose={handleClose}
          handleRefresh={handleRefresh}
        />
        <Export
          isOpen={openPopups}
          handleClose={handleClose}
          slug={'EXPORT_CONTACT'}
          pageParams={searchParams}
        /> */}
    </div>
  )
}

export default MedicineRequest
