// import FormBuilder from '../../../components/app/formBuilder/index'
import FormBuilder from '../../../../../../components/app/formBuilder'
type Props = {
  edit?: boolean
}
// const CommonForm = (data?: any) => {
const EditForm = ({ edit }: Props) => {
  const textField = (
    name: string,
    label: string,
    id: string,
    type: string,
    // placeholder: string,
    required = false,
    config: { data?: Array<{ name: string; id: string }> } = {}
  ) => ({
    name,
    label,
    id,
    type,
    // placeholder,
    ...(required ? { required: true } : {}),
    ...config,
    spacing: 4,
  })
  const formBuilderProps = [
    {
      ...textField('batch', 'Batch.No', 'batch', 'text', false),
      disabled: true,
    },
    {
      ...textField('name', 'Medicine Name', 'name', 'text', false),
      disabled: true,
    },
    { ...textField('unit', 'Unit of Packing (ml)', 'unit', 'number', false) },
    { ...textField('stock', 'Available Stock', 'stock', 'number', false) },
  ]

  // methods.reset({})

  return <FormBuilder data={formBuilderProps} edit={edit} />
}
export default EditForm
