import { useMutation, useQuery } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import apiUrl from '../../../../apis/api.url'
import { getData, updateFromData } from '../../../../apis/api.helpers'
import { getErrorMessage } from '../../../../utilities/parsers'
// import { getErrorMessage } from '../../../utilities/parsers'
// import apiUrl from '../../../apis/api.url'
// import { getData, updateFromData } from '../../../apis/api.helpers'

const buildUrlWithParams = (baseUrl: string, params: any) => {
  return `${baseUrl}/${params}`
}

const fetchData = async (id: any) => {
  const url = buildUrlWithParams(apiUrl.DOCTOR, id)
  const response = await getData(url)
  // console.log(response)
  return response
}

export const useDoctorDetail = (id: any) => {
  return useQuery(['doctor_detail'], () => fetchData(id), {
    refetchOnWindowFocus: false,
  })
}
export const updatePharmacist = (data: { data: any; pharmacistId: string }) => {
  // console.log(data)
  return updateFromData(`${apiUrl.DOCTOR}/${data?.pharmacistId}`, data?.data)
}

export const useUpdateDoctor = (
  handleSubmission: (input: any, id: string) => void
) => {
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(updatePharmacist, {
    onSuccess: (res: any) => {
      handleSubmission(res.data, res.id)
    },

    onError: (error: any) => {
      enqueueSnackbar(
        getErrorMessage(
          error?.response?.data?.message || 'Something went wrong'
        ),
        {
          variant: 'error',
        }
      )
    },
  })
}
