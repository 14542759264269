import moment from 'moment'

const defaultColumnProps1 = {
  sortable: false,
  isVisible: true,
  resizable: true,
}
// const statusInfo: 1 | 2 = 1

export const getColumnsAdmin = ({}: any) => {
  const createRenderCell = (key: string, isCustom?: string) => (row: any) => {
    if (isCustom === 'status') {
      // console.log(row)
      return {
        cell: <>{moment(row?.delivered_on).format('DD-MM-YYYY')}</>,
      }
    }
  }
  const column = [
    // {
    //   title: 'Order ID',
    //   field: 'id',
    //   // fixed: true,
    //   ...defaultColumnProps1,
    //   // link: true,
    //   // sortKey: 'default_contact__first_name',
    //   // rowClick: (row: any) => onViewAction(row, 'contactName'),

    //   customCell: true,
    // },
    {
      title: 'Medicine Name',
      // renderCell: createRenderCell('cre_agent.name'),
      field: 'medic_name',
      ...defaultColumnProps1,
      // customCell: true,
      // sortKey: 'cre_agent_user_first_name',

      customCell: true,
    },
    {
      title: 'Manufacturer Name',
      field: 'manufacturer_name',
      ...defaultColumnProps1,

      customCell: true,
    },
    {
      title: 'Unit of Packing',
      field: 'unit_of_packing',
      ...defaultColumnProps1,

      customCell: true,
    },
    {
      title: 'Requested Quantity',
      field: 'quantity',
      ...defaultColumnProps1,

      customCell: true,
    },
    {
      title: 'Price/Unit',
      field: 'price',
      ...defaultColumnProps1,
      sortKey: '',

      customCell: true,
    },
    // {
    //   title: 'Delivered On',
    //   field: 'delivered_on',
    //   ...defaultColumnProps1,
    //   sortKey: '',

    //   customCell: true,
    // },
    {
      title: 'Delivered On',

      ...defaultColumnProps1,
      customCell: true,
      renderCell: createRenderCell('default_contact.mobile', 'status'),
      sortKey: 'id',
    },
  ]

  return column
}
