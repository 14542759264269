// import FormBuilder from '../../../components/app/formBuilder/index'

import FormBuilder from '../../../../components/app/formBuilder'
type Props = {
  edit?: boolean
}
// const CommonForm = (data?: any) => {
const CommonForm = ({ edit }: Props) => {
  const textField = (
    name: string,
    label: string,
    id: string,
    type: string,
    // placeholder: string,
    required = false,
    config: { data?: Array<{ name: string; id: string }> } = {}
  ) => ({
    name,
    label,
    id,
    type,
    // placeholder,
    ...(required ? { required: true } : {}),
    ...config,
    spacing: 4,
  })
  const formBuilderProps = [
    { ...textField('name', 'Name ', 'name', 'text', false), disabled: true },

    {
      ...textField('state', 'State', 'state', 'text', false),
      disabled: true,
    },
    {
      ...textField('district_name', 'District ', 'district_id', 'text', false),
      disabled: true,
    },
    {
      ...textField('code', 'Institution code', 'code', 'text', false),
      disabled: true,
    },

    {
      ...textField('stream', 'Stream', 'stream', 'text', false),
      disabled: true,
    },
    textField('start_date', 'Start date', 'start_date', 'date', true),
    textField('end_date', 'End date', 'end_date', 'date', true),
  ]

  // methods.reset({})

  return <FormBuilder data={formBuilderProps} edit={edit} />
}
export default CommonForm
