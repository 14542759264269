// import FormBuilder from '../../../components/app/formBuilder/index'

import { useNavigate } from 'react-router-dom'
import { Button } from '../../../../components/common'
import CommonForm from '../form'
import { FormProvider, useForm } from 'react-hook-form'
import { router_config } from '../../../../configs/route.config'
import { CreateSchema, formSchema } from '../form/schema'
import { zodResolver } from '@hookform/resolvers/zod'
import ListingHeader from '../../../../components/common/ListingTiles'
import { useInstitution } from '../primary_stock/api'

// type ManagePhasrmacistProps = {
//     handlSubmit: () => void
// }
// const ReportsPrimaryStock: React.FC<ManagePhasrmacistProps> = (
//   {
//     //   handlSubmit,
//   }
// ) => {
const MedicineLoanReportAdmin = () => {
  const navigate = useNavigate()

  const { data: InstitutionData } = useInstitution()
  const methods = useForm<CreateSchema>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      state: 'Kerala',
    },
  })

  const basicData = {
    title: 'Medicine Loan Report',
    icon: 'stock',
  }
  const activity = {
    hideExport: true,
  }
  const handleSubmit = (data: CreateSchema) => {
    // console.log('hi')
    const newData: any = []

    InstitutionData?.institutions?.map((item: any) => {
      if (item?.id === data?.id) {
        if (item?.stream === 'Yoga & Naturopathy') {
          item = { ...item, stream: 'Yoga %26 Naturopathy' }
          newData.push(item)
        } else {
          newData.push(item)
        }
      }
    })
    const fullData = { data, details: newData[0] }
    navigate(`${router_config.ADMIN_REPORTS_MEDICINE_LOAN_LIST.path}`, {
      state: fullData,
    })
  }
  // // console.log(methods.formState.errors)
  const onSubmit = (payload: CreateSchema) => {
    // // console.log(payload)
    // // console.log('hi')
    // methods.handleSubmit((data) => handleSubmit(data))
    handleSubmit(payload)
  }
  return (
    <>
      <ListingHeader data={basicData} actionProps={activity} />
      <div className=" px-[24px] py-[24px]">
        <div className="">
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="grid md:grid-cols-2 md:gap-8 sm:grid-cols-1 sm:gap-[24px]">
              <FormProvider {...methods}>
                <CommonForm edit={true} />
              </FormProvider>
            </div>
            <div className="flex gap-8 justify-center mt-5">
              <Button onClick={methods.reset} label={'Clear'} outlined={true} />
              <Button type="submit" label={'Generate'} />
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
export default MedicineLoanReportAdmin
