import { Outlet, useNavigate } from 'react-router-dom'
import { TabContainer } from '../../../components/common'
import { router_config } from '../../../configs/route.config'
import { useState } from 'react'
import ListingHeader from '../../../components/common/ListingTiles'

const tabData: any[] = [
  {
    label: 'Primary Stock',

    id: 'MEDICINE_PRIMARY',
  },
  {
    label: 'Secondary Stock',

    id: 'MEDICINE_SECONDARY',
  },
]

const MedicineStockMain: React.FC<any> = () => {
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState('MEDICINE_PRIMARY')
  const onTabClick = (selection: any) => {
    setActiveTab(selection.id)
    navigate(`/medicine/${router_config[selection.id]?.path}`)
  }
  const context = { setActiveTab }
  const activity = {
    hideExport: true,
  }
  const basicData = {
    title: 'Medicine',
    icon: 'institution',
  }
  return (
    <>
      <ListingHeader data={basicData} actionProps={activity} />
      <TabContainer data={tabData} activeTab={activeTab} onClick={onTabClick}>
        <Outlet context={context} />
      </TabContainer>
    </>
  )
}

export default MedicineStockMain
