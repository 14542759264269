// import { Router } from "react-router-dom"

// import { Link } from 'react-router-dom'
// import { router_config } from '../../../configs/route.config'
// import Info from '../../../components/common/icons/Info'

// const defaultColumnProps = {
//   sortable: true,
//   resizable: true,
//   isVisible: true,
// }
const defaultColumnProps1 = {
  sortable: false,
  isVisible: true,
  resizable: true,
}

export const getColumns = ({}: any) => {
  const createRenderCell = (key: string, isCustom?: string) => (row: any) => {
    if (isCustom === 'taxCalc') {
      console.log(row)
      return {
        cell: <div>{row.price * row.quantity}</div>,
      }
    }
  }
  const column = [
    // {
    //   title: 'Sl.No',
    //   field: 'id',
    //   ...defaultColumnProps1,
    //   // fixed: true,
    //   // sortKey: 'id',
    //   // rowClick: (row: any) => onViewAction(row, 'contactName'),
    //   // link: true,
    // },
    {
      title: 'Medicine name',
      field: 'name',
      // fixed: true,
      ...defaultColumnProps1,
      // link: true,
      // sortKey: 'default_contact__first_name',
      // rowClick: (row: any) => onViewAction(row, 'contactName'),
    },
    {
      title: 'Unit of Packing',
      // renderCell: createRenderCell('cre_agent.name'),
      field: 'unit_of_packing',
      ...defaultColumnProps1,
      // customCell: true,
      // sortKey: 'cre_agent_user_first_name',
    },
    {
      title: 'Price/Unit',
      field: 'price',
      ...defaultColumnProps1,
      // align: 'right',
      // sortKey: 'company_code',
      // renderCell: createRenderCell('primary_company.company_code'),

      // rowClick: (row: any) => onViewAction(row, 'business_name'),
      // link: true,
    },
    {
      title: 'Requested Quantity',
      field: 'quantity',
      ...defaultColumnProps1,
      // link: true,
      sortKey: '',
      // renderCell: createRenderCell('primary_company.business_name'),
      // rowClick: (row: any) => onViewAction(row, 'business_name'),
    },
    {
      title: 'Total Amount',
      // field: 'email',
      ...defaultColumnProps1,
      customCell: true,
      // align: 'right',
      // link: true,
      // sortKey: '',
      renderCell: createRenderCell('primary_company.business_name', 'taxCalc'),
      // rowClick: (row: any) => onViewAction(row, 'business_name'),
    },
    // {
    //   title: 'Action',
    //   renderCell: createRenderCell('default_contact.mobile', 'action'),
    //   field: 'mobile',
    //   ...defaultColumnProps1,
    //   customCell: true,
    //   link: true,
    //   getpath: () => '/dashboard',
    // },
  ]

  return column
}
