import React from 'react'
import { IconProps } from '../../../common/types'

const District: React.FC<IconProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 36 36"
      fill="none"
    >
      <g clipPath="url(#clip0_2313_7259)">
        <path
          d="M18 17C19.3845 17 20.7378 16.5895 21.889 15.8203C23.0401 15.0511 23.9373 13.9579 24.4672 12.6788C24.997 11.3997 25.1356 9.99224 24.8655 8.63437C24.5954 7.2765 23.9287 6.02922 22.9497 5.05026C21.9708 4.07129 20.7235 3.4046 19.3656 3.13451C18.0078 2.86441 16.6003 3.00303 15.3212 3.53285C14.0421 4.06266 12.9489 4.95987 12.1797 6.11101C11.4105 7.26216 11 8.61553 11 10C11 11.8565 11.7375 13.637 13.0503 14.9497C14.363 16.2625 16.1435 17 18 17ZM18 5C18.9889 5 19.9556 5.29325 20.7779 5.84265C21.6001 6.39206 22.241 7.17296 22.6194 8.08659C22.9978 9.00022 23.0969 10.0055 22.9039 10.9755C22.711 11.9454 22.2348 12.8363 21.5355 13.5355C20.8363 14.2348 19.9454 14.711 18.9755 14.9039C18.0055 15.0969 17.0002 14.9978 16.0866 14.6194C15.173 14.241 14.3921 13.6001 13.8427 12.7779C13.2932 11.9556 13 10.9889 13 10C13 8.67392 13.5268 7.40215 14.4645 6.46447C15.4021 5.52679 16.6739 5 18 5Z"
          fill="black"
          // stroke="black"
        />
        <path
          d="M30.47 24.3698C28.8663 22.6748 26.9338 21.3247 24.7905 20.402C22.6473 19.4793 20.3384 19.0034 18.005 19.0034C15.6716 19.0034 13.3627 19.4793 11.2195 20.402C9.07623 21.3247 7.14369 22.6748 5.54 24.3698C5.19231 24.7412 4.99921 25.2311 5 25.7398V30.9998C5 31.5302 5.21072 32.0389 5.58579 32.414C5.96086 32.7891 6.46957 32.9998 7 32.9998H29C29.5304 32.9998 30.0391 32.7891 30.4142 32.414C30.7893 32.0389 31 31.5302 31 30.9998V25.7398C31.0035 25.2324 30.814 24.7427 30.47 24.3698ZM29 30.9998H7V25.7298C8.41723 24.2375 10.1233 23.0491 12.0144 22.2371C13.9054 21.425 15.942 21.0063 18 21.0063C20.0581 21.0063 22.0946 21.425 23.9856 22.2371C25.8767 23.0491 27.5828 24.2375 29 25.7298V30.9998Z"
          fill="black"
          // stroke="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_2313_7259">
          <rect width="36" height="36" fill="white" color="black" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default District
