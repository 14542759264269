import React from 'react'
import { IconProps } from '../../../common/types'

const CancelIcon: React.FC<IconProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
    >
      <path
        d="M21.6654 7.48648L18.5129 4.33398L12.9987 9.84815L7.48453 4.33398L4.33203 7.48648L9.8462 13.0007L4.33203 18.5148L7.48453 21.6673L12.9987 16.1532L18.5129 21.6673L21.6654 18.5148L16.1512 13.0007L21.6654 7.48648Z"
        fill="#FF0404"
      />
    </svg>
  )
}

export default CancelIcon
