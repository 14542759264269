import { z } from 'zod'

import noLeadingSpaces from '../../../../utilities/noLeadingSpaces'

export const formSchema = z.object({
  date: z.date(),
  contributor_id: z.number(),
  speciality: z
    .string({ invalid_type_error: 'Speciality  type is required.' })
    .min(1, { message: 'Speciality  type is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  contributor_name: z
    .string({ invalid_type_error: 'Speciality  type is required.' })
    .min(1, { message: 'Speciality  type is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  transaction_id: z
    .string({ invalid_type_error: 'Transaction ID  type is required.' })
    .min(1, { message: 'Transaction ID  type is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),

  amount: z
    .string()
    .nullable()
    .transform((value) => (value === null ? '' : value))
    .refine(
      (value) => {
        return (
          (/^\d+(?:\.\d{0,10})?$/.test(value) &&
            value.length <= 20 &&
            parseFloat(value) !== 0) ||
          value === ''
        )
      },
      {
        message: 'Invalid Amount',
      }
    ),

  // email: z
  //   .string({ required_error: 'Enter Email' })
  //   .min(5, 'Enter a valid Email')
  //   .superRefine((value: any, ctx: any) => {
  //     if (value && value !== '') {
  //       if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
  //         ctx.addIssue({
  //           code: z.ZodIssueCode.custom,
  //           message: 'Invalid email format.',
  //         })
  //       }
  //     }
  //   })
  //   .refine(noLeadingSpaces, {
  //     message: 'Leading spaces are not allowed',
  //   }),
  state: z.string().optional().nullable(),
  district: z.string().optional().nullable(),
  name: z.string().optional().nullable(),
})

export type CreateSchema = z.infer<typeof formSchema>

// assembly_constituency_id: z.string(),
// pan_no: z
//   .string()
//   .refine((value) => value === '' || /^[A-Z]{5}\d{4}[A-Z]{1}$/.test(value), {
//     message: 'Invalid PAN number format',
//   })
//   .refine((value) => value === '' || noLeadingSpaces(value), {
//     message: 'Leading spaces are not allowed',
//   })
//   .optional(),

// stateId: z.string().optional().nullable(),
// code: z.string().optional().nullable(),
// city: z.string().min(1, 'City is required.').refine(noLeadingSpaces, {
//   message: 'Leading spaces are not allowed',
// }),
// address_1: z.string().optional().nullable(),
// address_2: z.string().optional().nullable(),
// country: z.string().min(1, 'Country is required.'),
// countryId: z.string().optional().nullable(),

// pin_code: z.string().optional().nullable(),
// phone_number: z
// .string()
// .refine((value) => value === '' || /^(\+?\d{10,12})$/.test(value), {
//   message: 'Invalid mobile number.',
// })
// .optional(),
