// import { useEffect, useState } from 'react'
// import { generatePath, Outlet, useNavigate, useParams } from 'react-router-dom'

// import { TabItemProps } from '../../../common/types'
// import { TabContainer } from '../../../components/common'
// import DetailHeader from '../../../components/common/DetailTiles'
// import { router_config } from '../../../configs/route.config'
// import { getContactDetails, updateContactCre } from '../api'
// import { useSnackbar } from 'notistack'
// import { getAllEmployeeList } from '../../../apis/common.apis'
// let updateKey = 1
// const ContactDetails = () => {
//   const navigate = useNavigate()
//   const [edit] = useState(false)
//   const [data, setData] = useState<any>()
//   const [drpdownItems, setDrpdownItems] = useState<any>()

//   const params = useParams()
//   const tabData = [
//     {
//       label: router_config.CONTACT_DETAILS_SUMMARY.label,
//       id: 'CONTACT_DETAILS_SUMMARY',
//     },
//     {
//       label: router_config.CONTACT_DETAILS_ACCOUNTS.label,
//       id: 'CONTACT_DETAILS_ACCOUNTS',
//     },
//     {
//       label: router_config.CONTACT_DETAILS_POSSIBLE_DUPLICATES.label,
//       id: 'CONTACT_DETAILS_POSSIBLE_DUPLICATES',
//     },
//   ]
//   const [activeTab, setActiveTab] = useState<string>('CONTACT_DETAILS_SUMMARY')

//   const haandleTabChange = (selected: TabItemProps) => {
//     navigate(
//       generatePath(router_config[selected.id]?.path as string, {
//         id: params.id,
//       })
//     )
//   }
//   const getEmployee = async () => {
//     const data = await getAllEmployeeList()
//     const empDetails = data?.reduce(
//       (acc: any, current: any) => acc.concat(current),
//       []
//     )
//     const drpdownItems = empDetails?.map((item: any) => ({
//       label: item.name,
//       value: item.name,
//       id: item.id,
//     }))
//     setDrpdownItems(drpdownItems)
//   }

//   useEffect(() => {
//     getData()
//     getEmployee()

//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [])

//   const getData = async () => {
//     const { data } = await getContactDetails(params.id)
//     setData(data)
//   }

//   const tiledata = [
//     {
//       id: 1,
//       tiles: [
//         {
//           label: 'CRE',
//           value: data?.cre_agent?.name,
//           id: 1,
//         },
//         {
//           icon: 'phone',
//           value: data?.default_contact?.mobile,
//           id: 2,
//         },
//         {
//           icon: 'email',
//           value: data?.default_contact?.email,
//           id: 3,
//         },
//       ],
//     },
//     {
//       id: 2,
//       tiles: [
//         {
//           id: 1,
//           icon: 'home',
//           value: ` ${data?.addresses[0]?.city ?? ''}, ${data?.addresses[0]
//             ?.state} `,
//         },
//       ],
//     },
//   ]
//   const chipdata = [
//     { id: 1, status: 'Normal' },
//     { id: 2, status: 'Normal' },
//     { id: 3, status: 'Normal' },
//   ]
//   const basicData = {
//     title: `${data?.default_contact?.first_name ?? '--'} ${
//       data?.default_contact?.last_name ?? '--'
//     } (${data?.customer_number ?? '--'})`,
//     subtitle: `${
//       data?.primary_company?.business_name
//         ? `${data?.primary_company?.business_name} (${data?.primary_company?.company_code})`
//         : '--'
//     } + ${data?.no_of_accounts ?? '--'}`,
//     subLink: `${router_config.ACCOUNTS.path}/${data?.primary_company?.id}/summary`,
//     image: data?.profile_image,
//   }

//   const handleCallback = () => {
//     getData()
//   }
//   const contextData = {
//     setActiveTab: (id: string) => setActiveTab(id),
//     edit: edit,
//     handleParentCallback: handleCallback,
//   }
//   const { enqueueSnackbar } = useSnackbar()
//   const updateCRE = (id?: string) => {
//     const details = { assigned_cre_id: id ?? null }
//     updateContactCre(data?.id as string, details).then(() => {
//       enqueueSnackbar('CRE updated successfully', { variant: 'success' })
//       getData()
//       updateKey = updateKey + 1
//     })

//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }
//   const handleUpdateCre = (id?: string) => {
//     updateCRE(id)
//   }
//   return (
//     <div>
//       <div>
//         <DetailHeader
//           tiledata={tiledata}
//           chipdata={chipdata}
//           data={basicData}
//           menuItems={drpdownItems}
//           setUpdateCREId={handleUpdateCre}
//         />
//         {/* <PageTiles data={dashtiledata} handleTileClick={handleTileClick} /> */}
//       </div>
//       <div className="flex gap-4">
//         <TabContainer
//           data={tabData}
//           activeTab={activeTab}
//           onClick={haandleTabChange}
//         >
//           <Outlet context={contextData} />
//         </TabContainer>
//       </div>
//     </div>
//   )
// }

// export default ContactDetails

// import FormBuilder from '../../../components/app/formBuilder/index'

import { Button } from '../../../../../components/common'
import { FormProvider, useForm } from 'react-hook-form'
import EditForm from './edit_form'
import { zodResolver } from '@hookform/resolvers/zod'
import { CreateSchema, formSchema } from './schema'
import { useEffect } from 'react'
import { useUpdateSecondaryStocks } from './api'
type ManageStockProps = {
  handlSubmitEdit: () => void
  handleEditClose: () => void
  data: any
}
const StockEdit: React.FC<ManageStockProps> = ({
  handlSubmitEdit,
  handleEditClose,
  data,
}) => {
  const onSuccess = () => {
    handlSubmitEdit()
  }
  const { mutate } = useUpdateSecondaryStocks(onSuccess)
  const stock_id = data.id
  const methods = useForm<CreateSchema>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  useEffect(() => {
    if (data) {
      methods.reset({
        ...data,
        batch_number: data?.batch_number,
      })
    }
    // console.log(data)
  }, [data, methods])

  // console.log(methods.watch())
  const onSubmit = (payload: CreateSchema) => {
    // setSuccessModalActive(true)
    // handlSubmitEdit()
    // // console.log(payload)
    const data = {
      institutional_stock: {
        secondary_stock: Number(payload.secondary_stock),
      },
    }
    mutate({ data, stock_id })
  }
  return (
    <>
      <div className="my-5 mx-8">
        <h2 className="flex text-center w-full mx-auto justify-center text-lime-500 text-2xl font-medium">
          <u>Edit Stock</u>
        </h2>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="grid md:grid-cols-2 md:gap-8 sm:grid-cols-1 sm:gap-[24px] p-4">
            <FormProvider {...methods}>
              <EditForm edit={true} />
            </FormProvider>
          </div>
          <div className="flex gap-8 justify-center my-3">
            <Button
              onClick={handleEditClose}
              label={'Cancel'}
              outlined={true}
            />
            <Button type="submit" label={'Submit'} />
          </div>
        </form>
      </div>
    </>
  )
}
export default StockEdit
