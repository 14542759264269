import React from 'react'
import { IconProps } from '../../../common/types'

const CartIcon: React.FC<IconProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 24 24"
    >
      <path
        stroke="black"
        strokeWidth={0.5}
        d="M12 9.5q-.252 0-.434-.182q-.181-.181-.181-.433t.181-.434q.182-.182.434-.182t.434.182q.181.182.181.434t-.181.433Q12.252 9.5 12 9.5m-.5-3v-5h1v5zM7.308 21.115q-.633 0-1.067-.433q-.433-.434-.433-1.067q0-.632.433-1.066q.434-.434 1.067-.434q.632 0 1.066.434q.434.434.434 1.066q0 .633-.434 1.067q-.434.433-1.066.433m9.384 0q-.632 0-1.066-.433q-.434-.434-.434-1.067q0-.632.434-1.066q.434-.434 1.066-.434q.633 0 1.067.434q.433.434.433 1.066q0 .633-.433 1.067q-.434.433-1.067.433M2 3.5v-1h2.448l4.096 8.615h6.635q.173 0 .308-.086q.134-.087.23-.24L19.213 4.5h1.14l-3.784 6.835q-.217.365-.564.573q-.347.207-.763.207H8.1l-1.215 2.231q-.154.23-.01.5t.433.27h10.884v1H7.308q-.875 0-1.309-.735q-.434-.735-.018-1.485l1.504-2.68L3.808 3.5z"
      />
    </svg>
  )
}

export default CartIcon
