// import FormBuilder from '../../../components/app/formBuilder/index'

import { zodResolver } from '@hookform/resolvers/zod'
import { Button } from '../../../../components/common'
import CommonForm from '../pharmacist_form'
import { FormProvider, useForm } from 'react-hook-form'
import { CreateSchema, formSchema } from './schema'
import { useAuthStore } from '../../../../store/authStore'
import { useCreateFund, useFundDetails, useUpdateFunds } from './api'
import moment from 'moment'
import { useEffect } from 'react'
// import { fetchFunds } from '../api'
type ManagePhasrmacistProps = {
  handlSubmit: () => void
  edit: boolean
  data: any
}
const PharmacistCreate: React.FC<ManagePhasrmacistProps> = ({
  handlSubmit,
  edit,
  data,
}) => {
  const { userData } = useAuthStore()
  // const [funds, setFunds] = useState([])
  const fund_id = data.id

  const { data: details, refetch } = useFundDetails(fund_id, edit)

  useEffect(() => {
    if (edit) {
      refetch()
    }
  }, [refetch, fund_id, edit])

  // console.log(details)

  useEffect(() => {
    // console.log(details)
    if (details && edit) {
      // console.log(details)
      methods.reset({
        ...details,
        date: moment(details.date, 'DD-MM-YYYY').toDate(),
      })
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details, edit])
  const onSuccess = () => {
    handlSubmit()
  }
  const onUpdateSuccess = () => {
    handlSubmit()
  }
  const { mutate } = useCreateFund(onSuccess)
  const { mutateA } = useUpdateFunds(onUpdateSuccess)
  const methods = useForm<CreateSchema>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      state: 'Kerala',
      district: userData.district,
      name: userData.name,
    },
  })
  const onSubmit = (payload: CreateSchema) => {
    const data = {
      fund: {
        ...payload,
        ...(payload.state && { state: {} }),
        ...(payload.district && { district: {} }),
        ...(payload.name && { name: {} }),
        ...(payload.contributor_name && { contributor_name: {} }),
        ...(payload.contributor_id && {
          contributor_id: payload.contributor_id,
        }),
        ...(payload.date
          ? { date: moment(payload.date).format('DD/MM/YYYY') }
          : { date: {} }),
      },
    }
    console.log(data)
    if (!edit) {
      mutate(data)
    } else {
      mutateA({ data, fund_id })
    }
  }
  // console.log(methods.watch())
  console.log(methods.formState.errors)
  // methods.reset({})

  return (
    <>
      <div className="my-5 mx-8">
        <h2 className="flex text-center w-full mx-auto justify-center text-lime-500 text-2xl font-medium">
          {!edit ? <u>Add Fund</u> : <u>Edit Fund</u>}
        </h2>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="grid md:grid-cols-2 md:gap-8 sm:grid-cols-1 sm:gap-[24px] p-4">
            <FormProvider {...methods}>
              <CommonForm edit={true} />
            </FormProvider>
          </div>
          <div className="flex gap-8 justify-center my-3">
            <Button onClick={methods.reset} label={'Clear'} outlined={true} />
            <Button
              // onClick={methods.handleSubmit}
              type="submit"
              label={'Submit'}
            />
          </div>
        </form>
      </div>
    </>
  )
}
export default PharmacistCreate
