import { QbsTable } from 'qbs-react-grid'
import { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import ListingHeader from '../../components/common/ListingTiles'
import { router_config } from '../../configs/route.config'
import { useContactFilterStore } from '../../store/filterSore/contactStore'
import { statusClassGen } from '../../utilities/generators'
import { getSortedColumnName } from '../../utilities/parsers'
import { useDistrictAdmin } from './api'
import { getColumns } from './contactColumns'
import { calcWindowHeight } from '../../utilities/calcHeight'
import Icons from '../../components/common/icons'
// import { PrimaryFilterComponent } from '../../components/app/filterComponents/primaryFilter'

const DistrictAdminMain = () => {
  const navigate = useNavigate()
  const [mergeIsActive] = useState(false)
  const { pageParams, setPageParams, selectedRows, setSelectedRows } =
    useContactFilterStore()
  const [customSearch, setCustomSearch] = useState('')

  const { page, page_size, search, ordering, filters } = pageParams

  const searchParams = {
    page_number: page,
    page_size: page_size,
    district: search,
    ordering: ordering,
    ...filters,
  }
  const { data, refetch, isFetching } = useDistrictAdmin(searchParams)
  useEffect(() => {
    refetch()
  }, [page, page_size, search, customSearch, ordering, filters, refetch])

  const onChangeRowsPerPage = (count: any) => {
    setPageParams({
      ...pageParams,
      page_size: count,
      page: 1,
    })
  }
  const onChangePage = (row: any) => {
    setPageParams({
      ...pageParams,
      page: row,
    })
  }

  // const onViewAction = (r: any, from: string) => {
  //   // console.log(r, from)
  // }
  const handleSort = (orderColumn: any, orderDirection: any) => {
    setPageParams({
      ...pageParams,
      sortColumn: orderColumn,
      sortType: orderDirection,
      ordering: getSortedColumnName(orderColumn, orderDirection),
    })
  }
  const handleSeach = (key?: string) => {
    setPageParams({
      ...pageParams,
      search: key as string,
      page: 1,
    })
  }
  const handleOnSlect = (rows: any) => {
    setSelectedRows([...rows])
  }

  const basicData = {
    title: 'District Admin',
    icon: 'Manufacturer',
  }

  const [columns, setColumns] = useState<any>(() =>
    getColumns({
      // onViewAction: onViewAction,
      statusClassGen: statusClassGen,
    })
  )

  const handleResetColums = () => {
    setColumns([
      ...getColumns({
        // onViewAction: onViewAction,
        statusClassGen: statusClassGen,
      }),
    ])
  }
  if (mergeIsActive) {
    return <Outlet context={{ selectedRows, refetch }} />
  }
  const activity = {
    hideExport: true,
  }
  const handleCreate = () => {
    navigate(`${router_config.DISTRICT_ADMIN_CREATE.path}`)
  }
  const onViewAction = (r: any) => {
    return r.id && `${router_config.DISTRICT_ADMIN.path}/${r.id}`
  }
  return (
    <div className="">
      <div className="">
        <ListingHeader
          data={basicData}
          onActionClick={handleCreate}
          actionTitle="Add District Admin"
          actionProps={activity}
          setCustomSearch={setCustomSearch}
        />

        <div className=" px-[24px] py-[24px]">
          <QbsTable
            columns={columns}
            dataRowKey="id"
            selectedRows={selectedRows}
            // selection={true}
            isLoading={isFetching}
            toolbar
            search
            handleColumnSort={handleSort}
            height={calcWindowHeight(382)}
            data={data?.district_admins ?? []}
            handleResetColumns={() => handleResetColums()}
            // sortType={sortType}
            // sortColumn={sortColumn}
            pagination
            paginationProps={{
              onPagination: onChangePage,
              total: data?.total_count,
              currentPage: pageParams?.page,
              rowsPerPage: Number(pageParams?.page_size ?? data?.page_size),
              onRowsPerPage: onChangeRowsPerPage,
              dropOptions: [10, 20, 30, 50, 100, 200],
            }}
            actionProps={[
              {
                title: 'View',
                action: (rowData) => navigate(onViewAction(rowData)),
                icon: <Icons name="edit" />,
                toolTip: 'Edit',
              },
            ]}
            searchValue={pageParams?.search}
            onSearch={handleSeach}
            asyncSearch
            handleSearchValue={(key?: string) => handleSeach(key)}
            onSelect={handleOnSlect}
            columnToggle
          />
        </div>
      </div>
    </div>
  )
}

export default DistrictAdminMain
