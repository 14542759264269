import { useEffect, useState } from 'react'
// import { Button } from '../../../../../../components/common'
// import { prescription } from '../store'
import { useParams } from 'react-router-dom'
import { usePatientListing } from '../../api'
import moment from 'moment'
import { useAuthStore } from '../../../../../../store/authStore'
// import { calcWindowHeight } from '../../../../../../utilities/calcHeight'
type ManagePhasrmacistProps = {
  data?: any
}
const PrescriptionPring: React.FC<ManagePhasrmacistProps> = ({ data }) => {
  console.log(data)
  const { data: patients } = usePatientListing()
  const [patientData, setPatientData] = useState<any>()
  const { id2 } = useParams()
  const { userData } = useAuthStore()
  // const date = date()
  // console.log(date)
  useEffect(() => {
    patients?.op_records.map((item: any) => {
      if (item?.id == id2) {
        setPatientData(item)
      }
    })
  }, [data, id2])
  console.log(patientData)

  // const generateHeight = () => {
  //   return `h-[${calcWindowHeight(100)}]`
  // }
  return (
    <div className="printable-content">
      <div className="p-5">
        <div className="flex justify-between">
          <div>
            <div className="text-[18px] leading-normal font-normal pb-2">
              Health Service Department
            </div>
            <div className="text-[18px] leading-normal font-normal">
              Government of Kerala
            </div>
          </div>
          <div>
            <div className="text-[18px] leading-normal font-normal pb-2">
              {userData?.institution_name}, {userData?.district_name}
            </div>
            <div className="text-[18px] leading-normal font-normal">
              PH : {userData?.institution_phone_number},{' '}
              {userData?.institution_land_number}
            </div>
          </div>
        </div>
        <div className="py-[40px]">
          <div className="flex justify-between">
            <div className="flex items-center">
              <div className="sm:text-xs  font-normal leading-normal">
                <div className="text-2xl font-semibold">
                  {patientData?.name}
                </div>
                <div className="my-1 py-2 font-semibold">OP Number : {id2}</div>
                <div className="font-semibold">
                  Unique ID : {patientData?.patient_id}
                </div>
              </div>
            </div>
            <div className="flex gap-4 justify-between w-100">
              <div className=" lg:text-xs text-[10px] px-1 2xl:px-2 font-normal leading-normal align-middle h-[40px]	text-SidenavFontColor">
                <div className="font-bold text-black">Age</div>
                <div>{patientData?.age}</div>
              </div>
              <div className=" lg:text-xs text-[10px] px-5 2xl:px-8 font-normal leading-normal align-middle h-[40px]	text-SidenavFontColor">
                <div className="font-bold text-black">Gender</div>
                <div>{patientData?.gender}</div>
              </div>
              <div className=" lg:text-xs text-[10px] px-5 2xl:px-8 font-normal leading-normal align-middle h-[40px]	text-SidenavFontColor">
                <div className="font-bold text-black">Date</div>
                <div>{moment(new Date()).format('DD-MM-YYYY')}</div>
              </div>
              <div className=" lg:text-xs text-[10px]  font-normal leading-normal align-middle h-[40px]	text-SidenavFontColor">
                <div className="font-bold text-black">Time</div>
                <div>{moment(new Date()).format('h:mm a')}</div>
              </div>
              <div className=" lg:text-xs text-[10px] px-5 2xl:px-8 font-normal leading-normal align-middle h-[40px]	text-SidenavFontColor">
                <div className="font-bold text-black">Doctor</div>
                <div>{patientData?.doctor_name}</div>
              </div>
              <div className=" lg:text-xs text-[10px] px-5 2xl:px-8 font-normal leading-normal align-middle h-[40px]	text-SidenavFontColor">
                <div className="font-bold text-black">Specialization</div>
                <div>{patientData?.specialization}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="border-y ">
          <div className="border-b grid grid-cols-5 py-3 text-[18px] font-bold leading-normal tracking-wider">
            <div>Medicine Name</div>
            <div>Quantity</div>
            <div>Dose</div>
            <div>Mode of Administration</div>
            <div>Remarks</div>
          </div>
          {data?.map((item: any, i: number) => (
            <div
              key={i}
              className="border-t grid grid-cols-5 py-3 text-[16px] leading-normal"
            >
              <div>{item.name}</div>
              <div>{item.quantity}</div>
              <div>{item.dose}</div>
              <div>{item.administration}</div>
              <div>{item.remarks}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default PrescriptionPring
