import { useMutation } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { postFormData } from '../../../../../apis/api.helpers'
import apiUrl from '../../../../../apis/api.url'
import { getErrorMessage } from '../../../../../utilities/parsers'
// import { getErrorMessage } from '../../../utilities/parsers'
// import { postFormData } from '../../../apis/api.helpers'
// import apiUrl from '../../../apis/api.url'

export const createStock = (input: any) => {
  return postFormData(apiUrl.INSTITUTION_CREATE_STOCKS, input)
}

export const useCreateStock = (handleSubmission: (data: any) => void) => {
  const { enqueueSnackbar } = useSnackbar()
  return useMutation(createStock, {
    onSuccess: (res: any) => {
      handleSubmission(res.data)
    },

    onError: (error: any) => {
      enqueueSnackbar(getErrorMessage(error.response.data.message), {
        variant: 'error',
      })
    },
  })
}
