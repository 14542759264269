const LOGIN_URL = 'sessions/login'
const PROFILE_URL = 'users/profile'
const LOCATION_URL = 'locations'
const INSTITUTIONS_LIST_URL = 'institutions'
const SUPPLIER_LIST_URL = 'manufacturers?category=non-dms&'
const MEDICAL_STORE_LIST_URL = 'manufacturers?category=dms&'
const FUND_LIST_URL = 'funds'
const PRIMARY_LIST_URL = 'pharmacists/primary_stocks'
const LOAN_REQUEST_FROM_HOSPITAL = 'loans/request_from_hospitals'
const LOAN_RESPONSE_FROM_HOSPITAL = 'loans/response_from_hospitals'
const PRIMARY_LIST_URL_DOCTOR = 'doctors/primary_stocks'
const HOSPITAL_INCHARGE_PRIMARY_LIST_URL = 'institutional_stocks/primary_stocks'
const HOSTPITAL_INCHARGE_SECONDARY_LIST_URL =
  'institutional_stocks/secondary_stocks'
const CREATE_INSTITUTION = 'institutions'
const UPDATE_INSTITUTION = 'institutions'
const CREATE_MANUFACTURER = 'manufacturers'
const PHARMACIST_LIST = 'pharmacists'
const INSTITUTION_CREATE_STOCKS = 'institutional_stocks/import_stocks'
const UPDATE_STOCKS = 'institutional_stocks'
const SECONDARY_LIST_URL = 'pharmacists/secondary_stocks'
const SECONDARY_LIST_URL_DOCTOR = 'doctors/secondary_stocks'
const MANUFACTURER_STOCK_LIST = 'manufacturer_stocks'
const DMS_STOCK_LIST = 'manufacturer_stocks?category=dms&'
const MANUFACTURER_STOCK_IMPORT = 'manufacturer_stocks/import_stocks'
const DMS_STOCK_IMPORT = 'manufacturer_stocks/import_stocks?category=dms&'
const MANUFACTURER_ORDERREQUEST_LIST = 'order_requests'
const DMS_ORDERREQUEST_LIST = 'order_requests?category=dms&'
const HOSPITAL_LOAN_REQUEST_LIST = 'loans/loan_lending_requests'
const MANUFACTURER_ISSUE_MEDICINE = 'institutional_stocks/issue_medicines'
const MANUFACTURERS_FUNDS = 'funds'
const INSTITUTION_ORDER_LIST = 'orders'
const INSTITUTION_REPORTS = 'institutions/report'
const INSTITUTION_DOWNLOAD_REPORTS = 'institutions/report.csv'
const MANUFACTURER_ORDER_STATUS = 'orders/update_order_status'
const DMO_ORDER_STATUS = 'orders/update_order_status?category=dms&'
const PURCHASE_MANUFACTURER_LIST = 'orders/fetch_manufacturers'
const PURCHASE_MEDICINE_LIST = 'orders/fetch_medicines'
const PURCHASE_FUND_LIST = 'orders/fetch_funds'
const PURCHASE_BY_EXCEL = 'orders/fetch_medicines_from_excel'
const FETCH_CONTRIBUTORS = 'funds/list_contributors'
const OP_INCHARGE = 'op_incharges'
const DISTRICT_ADMIN = 'district_admins'
const DOCTOR = 'doctors'
const PATIENTS = 'patients'
const OPPATIENTS = 'patients/op_records'
const GENERATEOTP = 'sessions/generate_otp'
const OTPLOGIN = 'sessions/login_with_otp'
const HOSPITAL_MY_LOANS = 'loans'
const HOSPITAL_MEDIICNE_FROM_DMS = 'orders?category=dms&'
const DISTRIBUTED_FUND = 'funds/distributed_funds'
const DISTRIBUTED_FUND_LIST = 'funds/distributed_funds_list'
const LOAN = 'loans'
const REPLACEMENTS = 'replacements'
const DASH = 'dashboards'
const MEDICINELISTING = 'prescriptions/fetch_medicine'
const MODE = 'prescriptions/fetch_dose_and_mode'
const PRESCRIPTIONCREATE = 'prescriptions'
const PREVIOUSPRESCRIPTION = 'prescriptions/history'
const PREVIOUSPRESCRIPTIONDETAIL = 'prescriptions'
const PHARMAPATIENTS = 'pharmacists/patients'
const DISPENSE = 'prescriptions/dispense_medicine'
const ADDEXISTINGOPPATIENT = 'patients/create_op'

const apiUrl: { [key: string]: string } = {
  LOGIN_URL,
  PROFILE_URL,
  LOCATION_URL,
  INSTITUTIONS_LIST_URL,
  SUPPLIER_LIST_URL,
  MEDICAL_STORE_LIST_URL,
  FUND_LIST_URL,
  LOAN_REQUEST_FROM_HOSPITAL,
  LOAN_RESPONSE_FROM_HOSPITAL,
  PRIMARY_LIST_URL,
  PRIMARY_LIST_URL_DOCTOR,
  HOSPITAL_INCHARGE_PRIMARY_LIST_URL,
  HOSTPITAL_INCHARGE_SECONDARY_LIST_URL,
  CREATE_INSTITUTION,
  UPDATE_INSTITUTION,
  CREATE_MANUFACTURER,
  PHARMACIST_LIST,
  INSTITUTION_CREATE_STOCKS,
  UPDATE_STOCKS,
  SECONDARY_LIST_URL,
  MANUFACTURER_STOCK_LIST,
  MANUFACTURER_ORDERREQUEST_LIST,
  DMS_ORDERREQUEST_LIST,
  HOSPITAL_LOAN_REQUEST_LIST,
  MANUFACTURER_ISSUE_MEDICINE,
  MANUFACTURERS_FUNDS,
  INSTITUTION_ORDER_LIST,
  INSTITUTION_REPORTS,
  INSTITUTION_DOWNLOAD_REPORTS,
  MANUFACTURER_STOCK_IMPORT,
  MANUFACTURER_ORDER_STATUS,
  DMO_ORDER_STATUS,
  PURCHASE_MANUFACTURER_LIST,
  PURCHASE_MEDICINE_LIST,
  SECONDARY_LIST_URL_DOCTOR,
  PURCHASE_FUND_LIST,
  PURCHASE_BY_EXCEL,
  FETCH_CONTRIBUTORS,
  OP_INCHARGE,
  DISTRICT_ADMIN,
  DOCTOR,
  PATIENTS,
  OPPATIENTS,
  GENERATEOTP,
  OTPLOGIN,
  HOSPITAL_MY_LOANS,
  HOSPITAL_MEDIICNE_FROM_DMS,
  DMS_STOCK_IMPORT,
  DMS_STOCK_LIST,
  DISTRIBUTED_FUND,
  DISTRIBUTED_FUND_LIST,
  LOAN,
  REPLACEMENTS,
  DASH,
  MEDICINELISTING,
  MODE,
  PRESCRIPTIONCREATE,
  PREVIOUSPRESCRIPTION,
  PREVIOUSPRESCRIPTIONDETAIL,
  PHARMAPATIENTS,
  DISPENSE,
  ADDEXISTINGOPPATIENT,
}

export default apiUrl
