const defaultColumnProps1 = {
  sortable: false,
  isVisible: true,
  resizable: true,
}

export const getColumns = ({}: any) => {
  // const createRenderCell = (key: string, isCustom?: string) => (row: any) => {
  //   if (isCustom === 'status') {
  //     // console.log(row)
  //     return {
  //       cell: (
  //         <div>
  //           {row.status === 'Available' ? (
  //             <div className="text-green-500">Available</div>
  //           ) : row.status === 'Out of Stock' ? (
  //             <div className="text-red-500 ">Out of Stock</div>
  //           ) : (
  //             <div className="text-amber-500">Shortage</div>
  //           )}
  //         </div>
  //       ),
  //       // toolTip: getNestedProperty(row, key) ?? '',
  //     }
  //   }
  // }
  const column = [
    // {
    //   title: 'Sl.No',
    //   field: 'id',
    //   ...defaultColumnProps1,
    //   fixed: true,
    //   sortKey: 'id',
    //   // rowClick: (row: any) => onViewAction(row, 'contactName'),
    //   // link: true,
    // },
    {
      title: 'Unique ID',
      field: 'id',
      fixed: true,
      colWidth: 150,
      ...defaultColumnProps1,
      // link: true,
      // sortKey: 'default_contact__first_name',
      // rowClick: (row: any) => onViewAction(row, 'contactName'),
    },
    {
      title: 'Patient Name',
      // renderCell: createRenderCell('cre_agent.name'),
      field: 'name',
      ...defaultColumnProps1,
      // customCell: true,
      // sortKey: 'cre_agent_user_first_name',
    },
    {
      title: 'Age',
      field: 'age',
      ...defaultColumnProps1,
      colWidth: 100,
    },
    // {
    //   title: 'Specialization',
    //   field: 'secondary_stock',
    //   ...defaultColumnProps1,
    //   customCell: true,
    //   renderCell: createRenderCell('cre_agent.name', 'specialization'),
    // },
    {
      title: 'District',
      field: 'district',
      ...defaultColumnProps1,
      sortKey: '',
    },
    {
      title: 'Phone Number',
      field: 'phone_number',
      ...defaultColumnProps1,
      sortKey: '',
    },
  ]

  return column
}
