import { QbsTable } from 'qbs-react-grid'
import { useEffect, useState } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'
// import ListingHeader from '../../../../components/common/ListingTiles'
import { router_config } from '../../../../configs/route.config'
import { useContactFilterStore } from '../../../../store/filterSore/contactStore'
import { statusClassGen } from '../../../../utilities/generators'
import {
  getErrorMessage,
  getSortedColumnName,
} from '../../../../utilities/parsers'
import { getColumns } from './contactColumns'
import { ConfirmModal } from '../../../../components/common'
import { deleteOrder, useOrderList } from '../api'
import { useSnackbar } from 'notistack'
import { calcWindowHeight } from '../../../../utilities/calcHeight'
import Icons from '../../../../components/common/icons'

const PendingOrdersMainHospitalDms = () => {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { type } = useOutletContext<any>()
  const [activeSection, setActiveSection] = useState(type)
  useEffect(() => {
    setActiveSection(type)
  }, [type])
  console.log(activeSection)
  // const [mergeIsActive, setMergeIsActve] = useState(false)
  const [successModalActive, setSuccessModalActive] = useState(false)
  // const [customSearch, setCustomSearch] = useState('')
  const { pageParams, setPageParams, selectedRows, setSelectedRows } =
    useContactFilterStore()

  const { page, page_size, search, ordering, filters } = pageParams

  const searchParams = {
    page_number: page,
    page_size: page_size,
    name: search,
    status: 'pending',
    ordering: ordering,
    ...filters,
  }
  const { data, refetch, isFetching } = useOrderList(searchParams)
  useEffect(() => {
    refetch()
  }, [page, page_size, search, ordering, filters, refetch])

  const onChangeRowsPerPage = (count: any) => {
    setPageParams({
      ...pageParams,
      page_size: count,
      page: 1,
    })
  }
  const onChangePage = (row: any) => {
    setPageParams({
      ...pageParams,
      page: row,
    })
  }

  const onViewAction = (r: any, from: string) => {
    console.log(r, from)
  }
  const handleSort = (orderColumn: any, orderDirection: any) => {
    setPageParams({
      ...pageParams,
      sortColumn: orderColumn,
      sortType: orderDirection,
      ordering: getSortedColumnName(orderColumn, orderDirection),
    })
  }
  const handleSeach = (key?: string) => {
    setPageParams({
      ...pageParams,
      search: key as string,
      page: 1,
    })
  }
  const handleOnSlect = (rows: any) => {
    setSelectedRows([...rows])
  }

  const onAction = (r: any, from: string) => {
    if (from === 'view' && r.id) {
      navigate(
        `${router_config.HOSPITAL_ORDERS_DMO.path}/${router_config.HOSPITAL_PENDING_ORDER_DMO.path}/${r.id}`
      )
    } else if (from === 'delete' && r.id) {
      handleDeleteAction(r)
    }
  }
  const [columns, setColumns] = useState<any>(() =>
    getColumns({
      onViewAction: onViewAction,
      statusClassGen: statusClassGen,
      onAction: onAction,
    })
  )

  const handleResetColums = () => {
    setColumns([
      ...getColumns({
        onViewAction: onViewAction,
        statusClassGen: statusClassGen,
      }),
    ])
  }
  // if (mergeIsActive) {
  //   return <Outlet context={{ selectedRows, refetch }} />
  // }
  // const activity = {
  //   hideExport: true,
  // }
  const handleCloseModal = () => {
    setSuccessModalActive(false)
  }
  const handleDeleteAction = (r: any) => {
    deleteOrder(r.id)
      .then(() => {
        setSuccessModalActive(true)
        refetch()
      })
      .catch((err: any) => {
        console.log(err)
        enqueueSnackbar(getErrorMessage(err?.response?.data?.message), {
          variant: 'error',
        })
      })
  }

  return (
    <div className="">
      <div className=" pt-[12px]">
        <QbsTable
          columns={columns}
          dataRowKey="id"
          selectedRows={selectedRows}
          // selection={true}
          isLoading={isFetching}
          toolbar
          handleColumnSort={handleSort}
          height={calcWindowHeight(400)}
          data={data?.orders ?? []}
          handleResetColumns={() => handleResetColums()}
          // sortType={sortType}
          // sortColumn={sortColumn}
          pagination
          paginationProps={{
            onPagination: onChangePage,
            total: data?.total_count,
            currentPage: pageParams?.page,
            rowsPerPage: Number(pageParams?.page_size ?? data?.page_size),
            onRowsPerPage: onChangeRowsPerPage,
            dropOptions: [10, 20, 30, 50, 100, 200],
          }}
          actionProps={[
            {
              title: 'View',
              action: (rowData) => onAction(rowData, 'view'),
              icon: <Icons name="eye" />,
              toolTip: 'View',
            },
            {
              title: 'Delete',
              action: (rowData) => onAction(rowData, 'delete'),
              icon: <Icons name="delete" />,
              toolTip: 'Delete',
            },
          ]}
          searchValue={pageParams?.search}
          onSearch={handleSeach}
          asyncSearch
          handleSearchValue={(key?: string) => handleSeach(key)}
          onSelect={handleOnSlect}
          columnToggle
        />
      </div>

      {successModalActive && (
        <ConfirmModal
          isOpen={true}
          body={
            <div className="font-medium text-primary leading-8 text-xl">
              Order Deleted Successfully
            </div>
          }
          topIcon="check-circle"
          onClose={handleCloseModal}
        />
      )}
    </div>
  )
}

export default PendingOrdersMainHospitalDms
