import { Outlet, useNavigate } from 'react-router-dom'
// import { Button } from '../../../components/common'
import { router_config } from '../../../../configs/route.config'
import { useState } from 'react'
// import MultiTabContainer from '../../../components/common/tab/MultipleTabContainer'
import ListingHeader from '../../../../components/common/ListingTiles'
import MultiTabContainer from '../../../../components/common/tab/MultipleTabContainer'

const tabData: any[] = [
  {
    label: 'Medicine',

    id: 'HOSPITAL_ORDERS',
  },
  {
    label: 'Medicine Loan',

    id: 'HOSPITAL_CONFIRMED_ORDER',
  },
]

const MyOrdersMainTabs: React.FC<any> = () => {
  const navigate = useNavigate()
  const [activeTabMain, setActiveTabMain] = useState('HOSPITAL_ORDERS')

  const onTabClick = (selection: any) => {
    setActiveTabMain(selection.id)
    navigate(`/hospital/${router_config[selection.id]?.path}`)
  }

  const context = { setActiveTabMain }

  const activity = {
    hideExport: true,
  }
  const basicData = {
    title: 'Medicine Orders',
    icon: 'institution',
  }
  return (
    <>
      <ListingHeader
        data={basicData}
        actionTitle="Add Institution"
        actionProps={activity}
      />

      <MultiTabContainer
        data={tabData}
        activeTab={activeTabMain}
        onClick={onTabClick}
      >
        <Outlet context={context} />
      </MultiTabContainer>
    </>
  )
}

export default MyOrdersMainTabs
