import { QbsTable } from 'qbs-react-grid'
import { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import ListingHeader from '../../../components/common/ListingTiles'
import { useContactFilterStore } from '../../../store/filterSore/contactStore'
import { statusClassGen } from '../../../utilities/generators'
import { getSortedColumnName } from '../../../utilities/parsers'
import { useFund } from '../api'
import { getColumns } from './contactColumns'
import { calcWindowHeight } from '../../../utilities/calcHeight'
import CustomisedheaderFundMapping from '../../../components/common/ListingTiles/customisedheaderFundMapping'
// import { PrimaryFilterComponent } from '../../components/app/filterComponents/primaryFilter'

const FundMappingMainAdminList = () => {
  const [mergeIsActive] = useState(false)
  const [setCustomBack] = useState(true)
  const { pageParams, setPageParams, selectedRows, setSelectedRows } =
    useContactFilterStore()
  const [customSearch, setCustomSearch] = useState('')
  const [customName, setCustomName] = useState('')
  const [customPhone, setCustomPhone] = useState('')
  console.log(customName, customPhone, customSearch)
  const { page, page_size, search, ordering, filters } = pageParams

  const searchParams = {
    page_number: page,
    page_size: page_size,
    name: search,
    district_id: customName,
    // institution_id: customName,
    ...(customSearch === 'Yoga & Naturopathy'
      ? { stream: 'Yoga %26 Naturopathy' }
      : { stream: customSearch }),

    institution_type: customPhone,

    ordering: ordering,
    ...filters,
  }
  const { data, refetch, isFetching } = useFund(searchParams)
  useEffect(() => {
    refetch()
  }, [
    page,
    page_size,
    customName,
    customPhone,
    customSearch,
    search,
    customSearch,
    ordering,
    filters,
    refetch,
  ])

  const onChangeRowsPerPage = (count: any) => {
    setPageParams({
      ...pageParams,
      page_size: count,
      page: 1,
    })
  }
  const onChangePage = (row: any) => {
    setPageParams({
      ...pageParams,
      page: row,
    })
  }

  // const onViewAction = (r: any, from: string) => {
  //   // console.log(r, from)
  // }
  const handleSort = (orderColumn: any, orderDirection: any) => {
    setPageParams({
      ...pageParams,
      sortColumn: orderColumn,
      sortType: orderDirection,
      ordering: getSortedColumnName(orderColumn, orderDirection),
    })
  }
  const handleSeach = (key?: string) => {
    setPageParams({
      ...pageParams,
      search: key as string,
      page: 1,
    })
  }
  const handleOnSlect = (rows: any) => {
    setSelectedRows([...rows])
  }

  const basicData = {
    title: 'Previous Distribution',
    icon: 'Manufacturer',
  }
  const basicDatas = {
    title: '',
    icon: '',
  }

  const [columns, setColumns] = useState<any>(() =>
    getColumns({
      // onViewAction: onViewAction,
      statusClassGen: statusClassGen,
    })
  )

  const handleResetColums = () => {
    setColumns([
      ...getColumns({
        // onViewAction: onViewAction,
        statusClassGen: statusClassGen,
      }),
    ])
  }
  if (mergeIsActive) {
    return <Outlet context={{ selectedRows, refetch }} />
  }
  const activity = {
    hideExport: true,
  }
  console.log(customSearch, customName)
  return (
    <div className="">
      <div className="">
        <ListingHeader
          data={basicData}
          actionProps={activity}
          setCustomSearch={setCustomSearch}
          setCustomBack={setCustomBack}
        />

        <div className=" px-[24px] py-[24px]">
          <CustomisedheaderFundMapping
            data={basicDatas}
            actionProps={activity}
            setCustomSearch={setCustomSearch}
            customSearch={customSearch}
            setCustomName={setCustomName}
            customName={customName}
            setCustomPhone={setCustomPhone}
            customPhone={customPhone}
          />
          <QbsTable
            columns={columns}
            dataRowKey="id"
            selectedRows={selectedRows}
            // selection={true}
            isLoading={isFetching}
            toolbar
            handleColumnSort={handleSort}
            height={calcWindowHeight(382)}
            data={data?.distributed_funds_list ?? []}
            handleResetColumns={() => handleResetColums()}
            // sortType={sortType}
            // sortColumn={sortColumn}
            pagination
            paginationProps={{
              onPagination: onChangePage,
              total: data?.total_count,
              currentPage: pageParams?.page,
              rowsPerPage: Number(pageParams?.page_size ?? data?.page_size),
              onRowsPerPage: onChangeRowsPerPage,
              dropOptions: [10, 20, 30, 50, 100, 200],
            }}
            // selectedRowActions={[
            //   {
            //     actionTitle: 'Merge',
            //     action: () => handleMerge(),
            //     customHide: '>2',
            //   },
            // ]}
            searchValue={pageParams?.search}
            onSearch={handleSeach}
            asyncSearch
            handleSearchValue={(key?: string) => handleSeach(key)}
            onSelect={handleOnSlect}
            columnToggle
          />
        </div>
      </div>
    </div>
  )
}

export default FundMappingMainAdminList
