import { Outlet, useNavigate } from 'react-router-dom'
import { TabContainer } from '../../../components/common'
import { router_config } from '../../../configs/route.config'
import { useState } from 'react'
import ListingHeader from '../../../components/common/ListingTiles'

const tabData: any[] = [
  {
    label: 'All Patients',

    id: 'DOCTOR_PATIENTS_ALL',
  },
  {
    label: 'Waiting Patients',

    id: 'DOCTOR_PATIENTS_WAITING',
  },
  {
    label: 'Examined Patients',

    id: 'DOCTOR_PATIENTS_DISPENSED',
  },
]

const DoctorPatientMain: React.FC<any> = () => {
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState('DOCTOR_PATIENTS_ALL')
  const obj = {
    DOCTOR_PATIENTS_ALL: 'all',
    DOCTOR_PATIENTS_WAITING: 'waiting',
    DOCTOR_PATIENTS_DISPENSED: 'dispensed',
  }
  const onTabClick = (selection: any) => {
    setActiveTab(selection.id)
    navigate(`/patient/list/${router_config[selection.id]?.path}`)
  }
  const context = { setActiveTab, activeTab, obj }

  const activity = {
    hideExport: true,
  }
  const basicData = {
    title: 'Consultation',
    icon: 'institution',
  }
  return (
    <>
      <ListingHeader data={basicData} actionProps={activity} />
      <TabContainer data={tabData} activeTab={activeTab} onClick={onTabClick}>
        <Outlet context={context} />
      </TabContainer>
    </>
  )
}

export default DoctorPatientMain
