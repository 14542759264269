import React from 'react'
import { IconProps } from '../../../common/types'

const DeleteIcon: React.FC<IconProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 20 20"
      fill="none"
      className="items-center"
    >
      <g clipPath="url(#clip0_1846_2931)">
        <path
          d="M8.84676 -0.0234375C7.3406 -0.0234375 6.1306 1.2281 6.1306 2.74118V3.07733H3.07752C2.65368 3.07733 2.30829 3.42272 2.30829 3.84656V4.61579H1.53906V6.15425H3.07752V17.6927C3.07752 18.9581 4.11983 20.0004 5.38522 20.0004H14.616C15.8814 20.0004 16.9237 18.9581 16.9237 17.6927V6.15425H18.4621V4.61579H17.6929V3.84656C17.6929 3.42272 17.3475 3.07733 16.9237 3.07733H13.8706V2.74041C13.8706 1.2281 12.6606 -0.0234375 11.1544 -0.0234375H8.84676ZM8.84676 1.56272H11.1544C11.7729 1.56272 12.2844 2.06733 12.2844 2.74041V3.07733H7.71599V2.74041C7.71599 2.06733 8.22752 1.56349 8.84599 1.56349L8.84676 1.56272ZM4.61599 6.15425H8.55829C8.65368 6.16425 8.74829 6.1781 8.84676 6.1781H11.1544C11.2529 6.1781 11.3468 6.16425 11.4429 6.15425H15.3852V17.6927C15.3852 18.1258 15.0491 18.4619 14.616 18.4619H5.38522C4.95214 18.4619 4.61599 18.1258 4.61599 17.6927V6.15425ZM6.15445 7.69272V16.9235H7.69291V7.69272H6.15445ZM9.23137 7.69272V16.9235H10.7698V7.69272H9.23137ZM12.3083 7.69272V16.9235H13.8468V7.69272H12.3083Z"
          fill="#FF0101"
        />
      </g>
      <defs>
        <clipPath id="clip0_1846_2931">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default DeleteIcon
