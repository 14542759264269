import { Button, ConfirmModal } from '../../../components/common'
import { useNavigate, useParams } from 'react-router-dom'
import { router_config } from '../../../configs/route.config'
import CommonForm from '../manufacturer_form'
import { FormProvider, useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import { useDistrictAdmin, useUpdateDistrictAdmin } from './api'
import { CreateSchema, formSchema } from '../create/schema'
import { zodResolver } from '@hookform/resolvers/zod'
import ListingHeader from '../../../components/common/ListingTiles'

const DistrictAdminDetail = () => {
  const navigate = useNavigate()
  const [edit, setEdit] = useState(true)
  // const [setCustomBack] = useState(true)

  const [successModalActive, setSuccessModalActive] = useState(false)
  const param = useParams()
  const districtAdminId = param.id
  const { data, refetch } = useDistrictAdmin(districtAdminId)

  useEffect(() => {
    refetch()
  }, [refetch, districtAdminId])

  const methods = useForm<CreateSchema>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: { state: 'kerala' },
  })
  useEffect(() => {
    if (data) {
      methods.reset({
        ...data,
        district_name: data?.district_name,
        district_id: data?.district_id,
      })
    }
  }, [data, methods])

  const onSuccess = () => {
    setSuccessModalActive(true)
  }
  const { mutate } = useUpdateDistrictAdmin(onSuccess)
  console.log(methods.formState.errors)
  console.log(methods.watch())
  const onSubmit = (payload: CreateSchema) => {
    // const formData = new FormData()
    // formData.append('manufacturer[district_id]', String(payload.district_id))
    // formData.append('manufacturer[address]', String(payload.address))
    // formData.append('manufacturer[land_number]', String(payload.land_number))
    // formData.append(
    //   'manufacturer[user_attributes][email]',
    //   String(payload.email)
    // )
    // formData.append(
    //   'manufacturer[user_attributes][phone_number]',
    //   String(payload.phone_number)
    // )
    // formData.append('manufacturer[name]', String(payload.name))
    const data = {
      district_admin: {
        ...payload,
        ...(payload.email && { email: {} }),
        ...(payload.phone_number && { phone_number: {} }),
        user_attributes: {
          email: payload.email,
          phone_number: payload.phone_number,
        },
      },
    }
    mutate({ data, districtAdminId })
  }

  const handleEdit = () => {
    setEdit(true)
  }
  // succes modal
  // const handleSubmit = () => {
  //   setSuccessModalActive(true)
  // }
  const handleCloseModal = () => {
    setSuccessModalActive(false)
    navigate(`${router_config.DISTRICT_ADMIN.path}`)
  }
  const basicData = {
    title: 'Edit District Admin',
    icon: 'stock',
  }
  const activity = {
    hideExport: true,
  }
  return (
    <>
      <ListingHeader
        data={basicData}
        actionProps={activity}
        // setCustomBack={setCustomBack}
      />
      <div className=" px-[24px] py-[24px]">
        <div className="">
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="grid md:grid-cols-3 md:gap-8 sm:grid-cols-1 sm:gap-4">
              <FormProvider {...methods}>
                <CommonForm edit={edit} />
              </FormProvider>
            </div>
            <div className="flex gap-4 justify-center my-6">
              {!edit ? (
                <Button label={'Edit'} onClick={handleEdit} />
              ) : (
                <Button type="submit" label={'Submit'} />
              )}
            </div>
          </form>
          {successModalActive && (
            <ConfirmModal
              isOpen={true}
              body={
                <div className="font-medium text-primary leading-8 text-xl">
                  District Admin Edited Successfully
                </div>
              }
              topIcon="check-circle"
              onClose={handleCloseModal}
              // title={'Add New Pharmacist'}
            />
          )}
        </div>
      </div>
    </>
  )
}
export default DistrictAdminDetail
