// import FormBuilder from '../../../components/app/formBuilder/index'

import { useEffect, useState } from 'react'
import FormBuilder from '../../../../components/app/formBuilder'
// import { fundData } from '../store'
import { fetchFunds } from '../api'
type Props = {
  edit?: boolean
}
// const CommonForm = (data?: any) => {
const CommonForm = ({ edit }: Props) => {
  const [funds, setFunds] = useState([])
  useEffect(() => {
    handleFetchFunds()
  }, [])
  const handleFetchFunds = () => {
    fetchFunds().then((res: any) => {
      setFunds(res?.contributors)
    })
    // setManufacturers(data)
  }
  const textField = (
    name: string,
    label: string,
    id: string,
    type: string,
    // placeholder: string,
    required = false,
    config: { data?: Array<{ name: string; id: string }> } = {}
  ) => ({
    name,
    label,
    id,
    type,
    // placeholder,
    ...(required ? { required: true } : {}),
    ...config,
    spacing: 4,
  })
  const formBuilderProps = [
    { ...textField('state', 'State', 'state', 'text', false), disabled: true },
    {
      ...textField('district', 'District', 'district', 'text', false),
      disabled: true,
    },
    {
      ...textField('name', 'Hospital Name', 'name', 'text', false),
      disabled: true,
    },
    { ...textField('speciality', 'Speciality', 'speciality', 'text', false) },
    {
      ...textField(
        'transaction_id',
        'Transaction ID',
        'transaction_id',
        'text',
        false
      ),
    },
    {
      ...textField(
        'contributor_name',
        'Fund Received From',
        'contributor_id',
        'custom_select',
        false,
        {
          data: funds,
        }
      ),
      placeholder: 'Select One',
    },

    textField('date', ' Date', 'date', 'date', false),
    textField('amount', 'Amount Received', 'amount', 'text', false),
  ]

  // methods.reset({})

  return <FormBuilder data={formBuilderProps} edit={edit} />
}
export default CommonForm
