// import FormBuilder from '../../../components/app/formBuilder/index'

import { Button } from '../../../components/common'
import CommonForm from '../manufacturer_form'
import { FormProvider, useForm } from 'react-hook-form'
import { CreateSchema, formSchema } from './schema'
import { zodResolver } from '@hookform/resolvers/zod'
type ManagePhasrmacistProps = {
  handleSubmit: (data: CreateSchema) => void
}
const FundCreate: React.FC<ManagePhasrmacistProps> = ({ handleSubmit }) => {
  const methods = useForm<CreateSchema>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const onSubmit = (payload: CreateSchema) => {
    handleSubmit(payload)
  }

  return (
    <>
      <div className="my-5 mx-6">
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="grid md:grid-cols-1">
            <FormProvider {...methods}>
              <CommonForm edit={true} />
            </FormProvider>
          </div>
          <div className="flex gap-8 mt-3 justify-center">
            <Button type="submit" label={'Distribute Fund'} />
          </div>
        </form>
      </div>
    </>
  )
}
export default FundCreate
