import { useMutation } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { postJsonData } from '../../../../../apis/api.helpers'
import apiUrl from '../../../../../apis/api.url'
import { getErrorMessage } from '../../../../../utilities/parsers'

export const createMedicineIssue = (input: any) => {
  return postJsonData(apiUrl.MANUFACTURER_ISSUE_MEDICINE, input)
}

export const useMedicineIssue = (handleSubmission: (data: any) => void) => {
  const { enqueueSnackbar } = useSnackbar()
  return useMutation(createMedicineIssue, {
    onSuccess: (res: any) => {
      handleSubmission(res.data)
    },

    onError: (error: any) => {
      if (typeof error?.response?.data?.message === 'string') {
        enqueueSnackbar(getErrorMessage(error?.response?.data?.message), {
          variant: 'error',
        })
      } else {
        error?.response?.data?.message &&
          error?.response?.data?.message.map((item: any) => {
            enqueueSnackbar(getErrorMessage(item), {
              variant: 'error',
            })
          })
      }
    },
  })
}
