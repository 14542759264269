import React from 'react'
import Button from '../buttons/Button'
// import { Button, Icon } from '..'
// import Icons from '../icons'
import ManagedList from './ManagedList'
import Tooltip from '@mui/material/Tooltip'
import { useNavigate } from 'react-router-dom'

type BasicDataProps = {
  title: string | number
  icon?: string
}

// type OptionsProps = {
//   value: string
//   label?: string
// }
// const options: OptionsProps[] = [
//   { value: 'Hospital', label: 'Hospital' },
//   { value: 'Dispensary', label: 'Dispensary' },
// ]
// type ActionProps = {
//   id: number
//   label: string
//   icon?: string
//   isOutlined?: boolean
// }

type DetailTileProps = {
  data: BasicDataProps
  onActionClick?: () => void
  onHandleExport?: () => void
  actionProps?: any
  actionTitle?: any
  showManagedList?: boolean
  groupData?: any
  groupValue?: any
  setGroupNameCode?: any
  setCustomType?: any
  setCustomSearch?: any
  setCustomQuantity?: any
  setCustomBack?: any
}

const ListingHeader: React.FC<DetailTileProps> = ({
  data,
  onActionClick,
  onHandleExport,
  actionProps,
  actionTitle,
  showManagedList = false,
  groupData = [],
  groupValue,
  setGroupNameCode,
  setCustomSearch,
  setCustomBack,
  // setCustomType,
  setCustomQuantity,
}) => {
  const handleFileChange = (event: any) => {
    // Access the uploaded file using event.target.files[0]
    const uploadedFile = event.target.files[0]
    // Perform any additional logic with the file
    console.log('Uploaded file:', uploadedFile)
  }
  const navigate = useNavigate()

  const goBack = () => {
    navigate(-1)
  }
  const handleButtonClick = () => {
    // Programmatically trigger the file input
    const fileInput = document.getElementById('file-upload')
    if (fileInput) {
      fileInput.click()
    }
  }
  const handleDownload = () => {
    const excelFilePath = '/Hospital In charge Excel sheet format .xlsx'
    const link = document.createElement('a')
    link.download = 'Hospital In charge Excel sheet format .xlsx'
    link.href = excelFilePath
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
  console.log(onHandleExport)

  return (
    <>
      <div className="flex justify-between flex-wrap gap-3 items-center border-formBorder py-[26px] px-[23px] ">
        <div className="flex gap-6 h-full">
          {data.icon && data.title && (
            <div className="flex items-center flex-wrap gap-5 text-[#222] ">
              <div className="flex items-center gap-3">
                {/* {data?.icon && (
                  <div className="w-10 h-10  flex  items-center justify-center rounded-[4px]">
                    <Icons className=" " name={data?.icon} />
                  </div>
                )} */}
                <div className="">
                  <div className=" text-[20px] text-primaryText font-bold">
                    {data.title}
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* <div className="border rounded-lg px-3 py-1.5 flex items-center h-full table-header-input">
          <img
            src="/search.png"
            alt="search here"
            className="w-auto h-[15px]"
          />
          <input
            type="text"
            placeholder="search"
            name="search"
            className=" px-2 h-full w-[150px] 2xl:w-[200px]"
            onChange={(e) => setCustomSearch(e.target.value)}
          />
        </div> */}
          {showManagedList && (
            <div className="flex items-center justify-start">
              <ManagedList
                groupData={groupData}
                selection={groupData?.findIndex(
                  (item: any) => item.code === groupValue
                )}
                setGroupNameCode={setGroupNameCode}
              />
            </div>
          )}
          {setCustomQuantity && (
            <div className="border rounded-lg px-3 py-1 flex items-center h-auto table-header-input w-[200px] 2xl:w-[250px]">
              <img
                src="/search.png"
                alt="search here"
                className="w-auto h-full"
              />
              <input
                type="text"
                placeholder="Enter Medicine Quantity"
                name="search"
                className=" px-2"
                onChange={(e) => setCustomSearch(e.target.value)}
              />
            </div>
          )}
        </div>

        <div className="flex gap-2">
          {/* {setCustomType && (
          <div className="table-header-select border rounded-lg h-auto w-[200px] 2xl:w-[250px]">
            <select
              name="institution"
              id="institutions"
              className="py-1 px-2 bg-white w-full h-full text-[14px]"
              onChange={(e) => setCustomType(e.target.value)}
            >
              {' '}
              {actionTitle == 'Add Manufacturer' ? (
                <option value="select">Select Manufacturer Type</option>
              ) : (
                <option value="select">Select Institution Type</option>
              )}
              {options.map((item: any, i: number) => (
                <option key={i} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
          </div>
        )} */}
          <div>
            {setCustomBack && (
              <Button
                onClick={goBack}
                label={'Go Back'}
                outlined={true}
                size="xs"
              />
            )}

            {!actionProps?.hideExport && (
              <Tooltip
                title={
                  <div
                    className="cursor-pointer text-m font-normal flex items-end px-2 text-white underline"
                    onClick={handleDownload}
                  >
                    Download Excel Format
                  </div>
                }
                placement="top"
                className="bg-white"
              >
                <span data-testid="dfssd" className="text-center text-white">
                  <div>
                    <label htmlFor="file-upload">
                      <Button
                        label="Upload Excel"
                        icon="upload"
                        outlined={true}
                        // onClick={onHandleExport}
                        onClick={handleButtonClick}
                        className="w-[155px]"
                      />
                    </label>
                    <input
                      type="file"
                      id="file-upload"
                      style={{ display: 'none' }}
                      onChange={handleFileChange}
                    />
                  </div>
                </span>
              </Tooltip>
            )}
            {actionProps?.actionCancel && (
              <Button
                label="Cancel"
                outlined={true}
                primary={false}
                onClick={() => actionProps.actionCancel()}
              />
            )}
          </div>
          {onActionClick && (
            <div>
              <Button
                label={actionTitle}
                icon={actionProps?.actionTitle ? '' : ''}
                onClick={onActionClick}
                disabled={actionProps?.disableSubmit}
                className="w-[177px] bd-primary"
              />
            </div>
          )}
        </div>
      </div>
      <hr />
    </>
  )
}

export default ListingHeader
