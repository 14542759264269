import { useCallback, useEffect, useState } from 'react'
import { Button } from '../../../../../components/common'
// import { useNavigate } from 'react-router-dom'

import { DialogModal } from '../../../../../components/common'
import { FormProvider, useForm } from 'react-hook-form'
import CommonForm from '../../pharmacist_form'
import { ConfirmModal } from '../../../../../components/common'
import { QbsTable } from 'qbs-react-grid'
import moment from 'moment'
import ListingHeader from '../../../../../components/common/ListingTiles'
import { router_config } from '../../../../../configs/route.config'

import { useContactFilterStore } from '../../../../../store/filterSore/contactStore'
import { statusClassGen } from '../../../../../utilities/generators'
import { getSortedColumnName } from '../../../../../utilities/parsers'
import { useOrderDetailList, useOrderDetails } from '../../api'
import { getColumns } from './contactColumnsDetail'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { calcWindowHeight } from '../../../../../utilities/calcHeight'
import { useStatusChangeLoan } from '../../../my_orders/api'

const OrderRequestDetailHospitalDeliveredLoan = () => {
  const navigate = useNavigate()
  const param = useParams()
  // const [isDrawerOpen, setDrawerOpen] = useState(false)
  const [mergeIsActive, setMergeIsActve] = useState(false)
  // const [search, setSearch] = useState("")
  // const [openPopups, setOpenPopups] = useState(false)
  const { pageParams, setPageParams, selectedRows, setSelectedRows } =
    useContactFilterStore()
  const [setCustomBack] = useState(true)

  const [customSearch] = useState('')
  const [customType] = useState('')
  // const param = useParams()
  const [dialogModalActive, setDialogModalActive] = useState(false)
  const [successModalActive, setSuccessModalActive] = useState(false)

  const { page, page_size, ordering, filters, filterProps } = pageParams
  const methods = useForm<any>({
    // resolver: zodResolver(createSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    // defaultValues: { state: 'Kerala' },
  })
  const searchParams = {
    page_number: page,
    page_size: page_size,
    name: customSearch,
    ...(customType === 'select'
      ? { instution_type: '' }
      : { instution_type: customType }),
    ordering: ordering,
    ...filters,
  }
  const onSuccess = () => {
    setSuccessModalActive(true)
    // refetch()
  }
  const { mutate } = useStatusChangeLoan(onSuccess)
  const { data: list, refetch: fetchList } = useOrderDetailList(
    param.id,
    searchParams
  )
  const { data, refetch, isFetching } = useOrderDetails(param.id, searchParams)
  useEffect(() => {
    refetch()
    fetchList()
  }, [
    page,
    page_size,
    ordering,
    filters,
    refetch,
    customSearch,
    customType,
    fetchList,
  ])
  // console.log(customSearch)
  const onChangeRowsPerPage = (count: any) => {
    setPageParams({
      ...pageParams,
      page_size: count,
      page: 1,
    })
  }
  const onChangePage = (row: any) => {
    setPageParams({
      ...pageParams,
      page: row,
    })
  }

  const onViewAction = (r: any, from: string) => {
    console.log(r, from)
  }
  const handleSort = (orderColumn: any, orderDirection: any) => {
    setPageParams({
      ...pageParams,
      sortColumn: orderColumn,
      sortType: orderDirection,
      ordering: getSortedColumnName(orderColumn, orderDirection),
    })
  }
  const handleSeach = (key?: string) => {
    setPageParams({
      ...pageParams,
      search: key as string,
      page: 1,
    })
  }
  const handleOnSlect = (rows: any) => {
    setSelectedRows([...rows])
  }

  const basicData = {
    title: 'Order Details',
    icon: 'order',
  }
  useEffect(() => {
    handleDatepicker()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterProps.endDate])

  const handleDatepicker = useCallback(() => {
    setPageParams({
      ...pageParams,
      filters: {
        ...filters,
        fromDate: filterProps?.startDate
          ? moment(filterProps?.startDate).format('DD-MM-YYYY')
          : '',
        toDate: filterProps?.endDate
          ? moment(filterProps.endDate).format('DD-MM-YYYY')
          : '',
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterProps.endDate])

  const [columns, setColumns] = useState<any>(() =>
    getColumns({
      onViewAction: onViewAction,
      statusClassGen: statusClassGen,
    })
  )

  const handleResetColums = () => {
    setColumns([
      ...getColumns({
        onViewAction: onViewAction,
        statusClassGen: statusClassGen,
      }),
    ])
  }
  if (mergeIsActive) {
    return <Outlet context={{ selectedRows, refetch }} />
  }
  const activity = {
    hideExport: true,
  }

  const handleCreate = () => {
    setDialogModalActive(true)
  }
  const handleClose = () => {
    setDialogModalActive(false)
    handleCreate()
  }

  const handleMerge = () => {
    navigate(`${router_config.CONTACT_MERGE.path}`)
    setMergeIsActve(true)
  }

  const handleSubmit = () => {
    const id = param.id
    const input = {
      order: {
        status: 'delivered',
      },
    }
    mutate({ id, input })
  }
  const handleCloseModal = () => {
    setSuccessModalActive(false)
    navigate('/medicine-loans/accepted')
  }
  const formattedDate = moment(list?.approved_time).format('DD/MM/YYYY')

  return (
    <>
      <ListingHeader
        data={basicData}
        setCustomBack={setCustomBack}
        actionProps={activity}
      />

      <div className=" px-[24px] py-[24px]">
        <div className="">
          <div className="w-100 pb-[24px]">
            <div className="flex gap-4 justify-between w-100">
              {list?.lender_name && (
                <div className=" lg:text-xs text-[10px] px-1 2xl:px-2 font-normal leading-normal align-middle h-[40px]	text-SidenavFontColor">
                  <div className="font-bold text-black">Lending Hospital</div>
                  <div>{list?.lender_name}</div>
                </div>
              )}
              <div className=" lg:text-xs text-[10px] px-5 2xl:px-8 font-normal leading-normal align-middle h-[40px]	text-SidenavFontColor">
                <div className="font-bold text-black">
                  Lending Hospital Email
                </div>
                <div> {list?.lender_email}</div>
              </div>
              <div className=" lg:text-xs text-[10px] px-5 2xl:px-8 font-normal leading-normal align-middle h-[40px]	text-SidenavFontColor">
                <div className="font-bold text-black">
                  Lending Hospital Contact Number
                </div>
                <div> {list?.lender_phone_number}</div>
              </div>
              <div className=" lg:text-xs text-[10px] px-5 2xl:px-8 font-normal leading-normal align-middle h-[40px]	text-SidenavFontColor">
                <div className="font-bold text-black">Total Items</div>
                <div> {data?.total_count} items</div>
              </div>
              <div className=" lg:text-xs text-[10px] px-5 2xl:px-8 font-normal leading-normal align-middle h-[40px]	text-SidenavFontColor">
                <div className="font-bold text-black">Approved Date</div>
                <div>{formattedDate}</div>
              </div>
              <div className=" lg:text-xs text-[10px] px-5 2xl:px-8 font-normal leading-normal align-middle h-[40px]	text-SidenavFontColor">
                <div className="font-bold text-black">Request Status</div>
                <div className="text-AvailabilityMark">
                  {list?.status === 'approved' && <div>Approved</div>}
                  {list?.status === 'delivered' && <div>Delivered</div>}
                </div>
              </div>
            </div>
          </div>
          <hr />
          {dialogModalActive && (
            <DialogModal
              isOpen={true}
              body={
                <div className="my-8 mx-8">
                  <h2 className="flex text-center w-full mx-auto justify-center text-lime-500 text-2xl font-medium">
                    <u>Edit Pharmacist</u>
                  </h2>
                  <div className="grid md:grid-cols-2 md:gap-8 sm:grid-cols-1 sm:gap-[24px] p-4">
                    <FormProvider {...methods}>
                      <CommonForm edit={true} />
                    </FormProvider>
                  </div>
                  <div className="flex gap-8 justify-center my-3">
                    <Button
                      // onClick={methods.onSubmit}
                      label={'Clear'}
                      outlined={true}
                    />
                    <Button
                      // onClick={methods.handleSubmit}
                      onClick={handleSubmit}
                      label={'Submit'}
                    />
                  </div>
                </div>
              }
              onClose={handleClose}
              // title={'Add New Pharmacist'}
            />
          )}

          {/* <PageTiles data={dashtiledata} handleTileClick={handleTileClick} /> */}
          {list?.status === 'accepted' && (
            <div className=" pt-[12px]">
              <QbsTable
                columns={columns}
                dataRowKey="id"
                selectedRows={selectedRows}
                // selection={true}
                isLoading={isFetching}
                toolbar
                handleColumnSort={handleSort}
                height={calcWindowHeight(440)}
                data={data?.medicines ?? []}
                handleResetColumns={() => handleResetColums()}
                // sortType={sortType}
                // sortColumn={sortColumn}
                pagination
                paginationProps={{
                  onPagination: onChangePage,
                  total: data?.total_count,
                  currentPage: pageParams?.page,
                  rowsPerPage: Number(pageParams?.page_size ?? data?.page_size),
                  onRowsPerPage: onChangeRowsPerPage,
                  dropOptions: [10, 20, 30, 50, 100, 200],
                }}
                tableHeaderActions={
                  <>
                    <Button
                      onClick={handleSubmit}
                      label={'Mark as Delivered'}
                      primary={true}
                      className="w-[180px] mt-2"
                    />
                  </>
                }
                selectedRowActions={[
                  {
                    actionTitle: 'Merge',
                    action: () => handleMerge(),
                    customHide: '>2',
                  },
                ]}
                // searchValue={pageParams?.search}
                // onSearch={handleSeach}
                asyncSearch
                handleSearchValue={(key?: string) => handleSeach(key)}
                onSelect={handleOnSlect}
                columnToggle
              />
            </div>
          )}
          {list?.status === 'approved' && (
            <div className=" pt-[12px]">
              <QbsTable
                columns={columns}
                dataRowKey="id"
                selectedRows={selectedRows}
                // selection={true}
                isLoading={isFetching}
                toolbar
                handleColumnSort={handleSort}
                height={calcWindowHeight(440)}
                data={data?.medicines ?? []}
                handleResetColumns={() => handleResetColums()}
                // sortType={sortType}
                // sortColumn={sortColumn}
                pagination
                paginationProps={{
                  onPagination: onChangePage,
                  total: data?.total_count,
                  currentPage: pageParams?.page,
                  rowsPerPage: Number(pageParams?.page_size ?? data?.page_size),
                  onRowsPerPage: onChangeRowsPerPage,
                  dropOptions: [10, 20, 30, 50, 100, 200],
                }}
                tableHeaderActions={
                  <>
                    <Button
                      onClick={handleSubmit}
                      label={'Mark as Delivered'}
                      primary={true}
                      className="w-[180px] mt-2"
                    />
                  </>
                }
                selectedRowActions={[
                  {
                    actionTitle: 'Merge',
                    action: () => handleMerge(),
                    customHide: '>2',
                  },
                ]}
                // searchValue={pageParams?.search}
                // onSearch={handleSeach}
                asyncSearch
                handleSearchValue={(key?: string) => handleSeach(key)}
                onSelect={handleOnSlect}
                columnToggle
              />
            </div>
          )}
          {list?.status === 'delivered' && (
            <div className=" pt-[12px]">
              <QbsTable
                columns={columns}
                dataRowKey="id"
                selectedRows={selectedRows}
                // selection={true}
                isLoading={isFetching}
                toolbar
                handleColumnSort={handleSort}
                height={calcWindowHeight(440)}
                data={data?.medicines ?? []}
                handleResetColumns={() => handleResetColums()}
                // sortType={sortType}
                // sortColumn={sortColumn}
                pagination
                paginationProps={{
                  onPagination: onChangePage,
                  total: data?.total_count,
                  currentPage: pageParams?.page,
                  rowsPerPage: Number(pageParams?.page_size ?? data?.page_size),
                  onRowsPerPage: onChangeRowsPerPage,
                  dropOptions: [10, 20, 30, 50, 100, 200],
                }}
                selectedRowActions={[
                  {
                    actionTitle: 'Merge',
                    action: () => handleMerge(),
                    customHide: '>2',
                  },
                ]}
                // searchValue={pageParams?.search}
                // onSearch={handleSeach}
                asyncSearch
                handleSearchValue={(key?: string) => handleSeach(key)}
                onSelect={handleOnSlect}
                columnToggle
              />
            </div>
          )}
          {successModalActive && (
            <ConfirmModal
              isOpen={true}
              body={
                <div className="font-medium text-primary leading-8 text-xl">
                  Loan Request Order Marked as Delivered
                </div>
              }
              topIcon="check-circle"
              onClose={handleCloseModal}
              // title={'Add New Pharmacist'}
            />
          )}
        </div>
      </div>
    </>
  )
}
export default OrderRequestDetailHospitalDeliveredLoan
