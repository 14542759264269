import MyOrdersMain from '../my_orders'
import MyOrdersMainTabs from './main'

const MyOrdersPage: React.FC = () => {
  return (
    <MyOrdersMainTabs>
      <MyOrdersMain />
    </MyOrdersMainTabs>
  )
}
export default MyOrdersPage
