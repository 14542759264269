import moment from 'moment'
import { QbsTable } from 'qbs-react-grid'
import { useCallback, useEffect, useState } from 'react'
import { Outlet, useNavigate, useOutletContext } from 'react-router-dom'

// import Export from '../../components/app/export'
// import { PrimaryFilterComponent } from '../../components/app/filterComponents/primaryFilter'
// import Button from '../../components/common/buttons/Button'
// import Icons from '../../components/common/icons/index'
// import ListingHeader from '../../../../components/common/ListingTiles'
import { router_config } from '../../../../configs/route.config'
import { useContactFilterStore } from '../../../../store/filterSore/contactStore'
import { statusClassGen } from '../../../../utilities/generators'
import { getSortedColumnName } from '../../../../utilities/parsers'
import { useContact } from './api'
import { getColumns } from './contactColumns'
import { ConfirmModal } from '../../../../components/common'
import { calcWindowHeight } from '../../../../utilities/calcHeight'
import Icons from '../../../../components/common/icons'
// import Create from './create'
// import { GetFilterDetails } from './filterProperties'

const DistrictAdminLoanResponseAll = () => {
  const navigate = useNavigate()
  const { setActiveTab, activeTab, obj } = useOutletContext<any>()

  const [mergeIsActive, setMergeIsActve] = useState(false)
  const [successModalActive, setSuccessModalActive] = useState(false)

  // const [openPopups, setOpenPopups] = useState(false)
  const { pageParams, setPageParams, selectedRows, setSelectedRows } =
    useContactFilterStore()

  // const [customSearch, setCustomSearch] = useState('')
  const { page, page_size, ordering, search, filters, filterProps } = pageParams

  const searchParams = {
    page_number: page,
    page_size: page_size,
    name: search,
    ordering: ordering,
    ...filters,
  }
  const { data, refetch, isFetching } = useContact(searchParams)
  useEffect(
    () => {
      refetch()
      setActiveTab('DISTRICT_ADMIN_LOAN_RESPONSE_ALL')
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, page_size, search, ordering, filters, refetch]
  )

  const onChangeRowsPerPage = (count: any) => {
    setPageParams({
      ...pageParams,
      page_size: count,
      page: 1,
    })
  }
  const onChangePage = (row: any) => {
    setPageParams({
      ...pageParams,
      page: row,
    })
  }

  const onViewAction = (r: any) => {
    return (
      r.request_id &&
      `${router_config.DISTRICT_ADMIN_LOAN_RESPOND.path}/${obj[activeTab]}/${r.loan_id}/${r.request_id}`
    )
  }
  const handleSort = (orderColumn: any, orderDirection: any) => {
    setPageParams({
      ...pageParams,
      sortColumn: orderColumn,
      sortType: orderDirection,
      ordering: getSortedColumnName(orderColumn, orderDirection),
    })
  }
  const handleSeach = (key?: string) => {
    setPageParams({
      ...pageParams,
      search: key as string,
      page: 1,
    })
  }
  const handleOnSlect = (rows: any) => {
    setSelectedRows([...rows])
  }

  // const basicData = {
  //   title: '',
  //   icon: '',
  // }

  useEffect(() => {
    handleDatepicker()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterProps.endDate])

  const handleDatepicker = useCallback(() => {
    setPageParams({
      ...pageParams,
      filters: {
        ...filters,
        fromDate: filterProps?.startDate
          ? moment(filterProps?.startDate).format('DD-MM-YYYY')
          : '',
        toDate: filterProps?.endDate
          ? moment(filterProps.endDate).format('DD-MM-YYYY')
          : '',
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterProps.endDate])

  const handleMerge = () => {
    navigate(`${router_config.CONTACT_MERGE.path}`)
    setMergeIsActve(true)
  }
  // const handlePath = () => {
  //   if (location.pathname === router_config.CONTACT_MERGE.path) {
  //     setMergeIsActve(true)
  //   } else {
  //     setMergeIsActve(false)
  //   }
  // }

  // useEffect(() => {
  //   handlePath()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [location.pathname])

  const [columns, setColumns] = useState<any>(() =>
    getColumns({
      statusClassGen: statusClassGen,
    })
  )

  const handleResetColums = () => {
    setColumns([
      ...getColumns({
        statusClassGen: statusClassGen,
      }),
    ])
  }
  if (mergeIsActive) {
    return <Outlet context={{ selectedRows, refetch }} />
  }
  // const activity = {
  //   hideExport: true,
  // }
  const handleCloseModal = () => {
    // navigate(`${router_config.PHARMACIST.path}`)
    setSuccessModalActive(false)
  }
  return (
    <div className="">
      {/* <ListingHeader
        data={basicData}
        // onHandleExport={openPopup}
        actionProps={activity}
        setCustomSearch={setCustomSearch}
      /> */}

      {/* <PageTiles data={dashtiledata} handleTileClick={handleTileClick} /> */}
      <div className=" py-[24px]">
        <QbsTable
          columns={columns}
          dataRowKey="id"
          selectedRows={selectedRows}
          // selection={true}
          isLoading={isFetching}
          toolbar
          search
          handleColumnSort={handleSort}
          height={calcWindowHeight(452)}
          data={data?.loans ?? []}
          handleResetColumns={() => handleResetColums()}
          // sortType={sortType}
          // sortColumn={sortColumn}
          pagination
          paginationProps={{
            onPagination: onChangePage,
            total: data?.total_count,
            currentPage: pageParams?.page,
            rowsPerPage: Number(pageParams?.page_size ?? data?.page_size),
            onRowsPerPage: onChangeRowsPerPage,
            dropOptions: [10, 20, 30, 50, 100, 200],
          }}
          // primaryFilter={
          //   <PrimaryFilterComponent
          //     filterParams={filterDetails}
          //     handleFilterChange={handleFilterChange}
          //     filterProps={filterProps}
          //     // advanceFilter={advanceFilter}
          //     handleFilterDateChange={setCustomType}
          //     isPrimary={true}
          //     // setAdvanceFilter={setAdvanceFilter}
          //   />
          // }
          // advancefilter={
          //   showDetail && (
          //     <PrimaryFilterComponent
          //       filterParams={filterDetails}
          //       handleFilterDateChange={handleFilterDateChange}
          //       handleFilterChange={handleFilterChange}
          //       filterProps={filterProps}
          //       isPrimary={false}
          //       advanceFilter={advanceFilter}
          //     />
          //   )
          // }
          actionProps={[
            {
              title: 'View',
              action: (rowData) => navigate(onViewAction(rowData)),
              icon: <Icons name="eye" />,
              toolTip: 'View',
            },
          ]}
          selectedRowActions={[
            {
              actionTitle: 'Merge',
              action: () => handleMerge(),
              customHide: '>2',
            },
          ]}
          searchValue={pageParams?.search}
          onSearch={handleSeach}
          asyncSearch
          handleSearchValue={(key?: string) => handleSeach(key)}
          onSelect={handleOnSlect}
          columnToggle
        />
      </div>

      {successModalActive && (
        <ConfirmModal
          isOpen={true}
          body={
            <div className="font-medium text-primary leading-8 text-xl">
              Stock Added Successfully
            </div>
          }
          topIcon="check-circle"
          onClose={handleCloseModal}
          // title={'Add New Pharmacist'}
        />
      )}
      {/* <Create
          isDrawerOpen={isDrawerOpen}
          handleClose={handleClose}
          handleRefresh={handleRefresh}
        />
        <Export
          isOpen={openPopups}
          handleClose={handleClose}
          slug={'EXPORT_CONTACT'}
          pageParams={searchParams}
        /> */}
    </div>
  )
}

export default DistrictAdminLoanResponseAll
