// import FormBuilder from '../../../components/app/formBuilder/index'

import { zodResolver } from '@hookform/resolvers/zod'
import { Button } from '../../../../components/common'
import CommonForm from '../pharmacist_form'
import { FormProvider, useForm } from 'react-hook-form'
import { CreateSchema, formSchema } from './schema'
// import { useCreatePharmacist } from './api'

type ManagePhasrmacistProps = {
  handlSubmit: (value: any) => void
}

const PharmacistCreate: React.FC<ManagePhasrmacistProps> = ({
  handlSubmit,
}) => {
  const methods = useForm<CreateSchema>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const onSubmit = (payload: CreateSchema) => {
    handlSubmit(payload)
  }
  return (
    <>
      <div className="my-5 mx-8">
        <h2 className="flex text-center w-full mx-auto justify-center text-lime-500 text-2xl font-medium">
          <u>Add New Pharmacist</u>
        </h2>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="grid md:grid-cols-2 md:gap-8 sm:grid-cols-1 sm:gap-[24px] p-4">
            <FormProvider {...methods}>
              <CommonForm edit={true} />
            </FormProvider>
          </div>
          <div className="flex gap-8 justify-center my-3">
            <Button onClick={methods.reset} label={'Clear'} outlined={true} />
            <Button type="submit" label={'Submit'} />
          </div>
        </form>
      </div>
    </>
  )
}
export default PharmacistCreate
