import { useMutation } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { updateJsonData } from '../../../../../apis/api.helpers'
import apiUrl from '../../../../../apis/api.url'
import { getErrorMessage } from '../../../../../utilities/parsers'

export const updatePrimaryStocks = (data: { data: any; stock_id: string }) => {
  // console.log(data)
  return updateJsonData(`${apiUrl.UPDATE_STOCKS}/${data?.stock_id}`, data?.data)
}

export const useUpdatePrimaryStocks = (
  handleSubmission: (input: any, id: string) => void
) => {
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(updatePrimaryStocks, {
    onSuccess: (res: any) => {
      handleSubmission(res.data, res.id)
    },

    onError: (error: any) => {
      enqueueSnackbar(
        getErrorMessage(
          error?.response?.data?.message || 'Something went wrong'
        ),
        {
          variant: 'error',
        }
      )
    },
  })
}
