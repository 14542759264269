import { useContactFilterStore } from './contactStore'
export function useClearFilter(type?: string) {
  const institutionFilterStore = useContactFilterStore()

  const stores = [
    {
      store: institutionFilterStore,
      clearFilter: 'clearContactFilter',
      clearRows: 'clearSelectedRows',
      clearAdvacneFilter: 'clearAdvanceFilter',
    },
  ]
  if (type == 'xyz') {
    return () => {
      stores.forEach(({ store, clearFilter, clearAdvacneFilter }) => {
        ;(store as any)[clearFilter]('from_filter')
        ;(store as any)[clearAdvacneFilter]()
      })
    }
  } else {
    return () => {
      stores.forEach(
        ({ store, clearFilter, clearRows, clearAdvacneFilter }) => {
          ;(store as any)[clearFilter]()
          ;(store as any)[clearRows]()
          ;(store as any)[clearAdvacneFilter]()
        }
      )
    }
  }
}
export {}
