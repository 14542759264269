// import FormBuilder from '../../../components/app/formBuilder/index'

import { useNavigate } from 'react-router-dom'
import { Button } from '../../../../components/common'
import CommonForm from '../form'
import { FormProvider, useForm } from 'react-hook-form'
import { router_config } from '../../../../configs/route.config'
import { CreateSchema, formSchema } from '../form/schema'
import { zodResolver } from '@hookform/resolvers/zod'
import { useAuthStore } from '../../../../store/authStore'
import ListingHeader from '../../../../components/common/ListingTiles'
// type ManagePhasrmacistProps = {
//     handlSubmit: () => void
// }
// const ReportsPrimaryStock: React.FC<ManagePhasrmacistProps> = (
//   {
//     //   handlSubmit,
//   }
// ) => {
const ReportsSecondaryStock = () => {
  const { userData } = useAuthStore()
  const navigate = useNavigate()
  const methods = useForm<CreateSchema>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      state: 'Kerala',
      district_id: userData?.district_id || undefined,
      district_name: userData?.district || undefined,
      name: userData?.name || undefined,
      code: userData?.id || undefined,
      stream: userData?.stream || undefined,
    },
  })

  // console.log(methods.watch())
  // methods.reset({})
  const handleSubmit = (data: CreateSchema) => {
    navigate(`${router_config.REPORTS_SECONDARY_LIST.path}`, { state: data })
  }
  const onSubmit = (payload: CreateSchema) => {
    handleSubmit(payload)
  }
  const basicData = {
    title: 'Secondary Stock Report',
    icon: 'stock',
  }
  const activity = {
    hideExport: true,
  }
  return (
    <>
      <ListingHeader data={basicData} actionProps={activity} />
      <div className=" px-[24px] py-[24px]">
        <div className="">
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="grid md:grid-cols-2 md:gap-8 sm:grid-cols-1 sm:gap-[24px]">
              <FormProvider {...methods}>
                <CommonForm edit={true} />
              </FormProvider>
            </div>
            <div className="flex gap-8 justify-center mt-5">
              <Button onClick={methods.reset} label={'Clear'} outlined={true} />
              <Button type="submit" label={'Generate'} />
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
export default ReportsSecondaryStock
