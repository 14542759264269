import { useCallback, useEffect, useState } from 'react'
import { Button } from '../../../../../components/common'
// import { useNavigate } from 'react-router-dom'

import { ConfirmModal } from '../../../../../components/common'
import { QbsTable } from 'qbs-react-grid'
import moment from 'moment'
import { router_config } from '../../../../../configs/route.config'

import { useContactFilterStore } from '../../../../../store/filterSore/contactStore'
import { statusClassGen } from '../../../../../utilities/generators'
import { getSortedColumnName } from '../../../../../utilities/parsers'
// import { useContact } from '../../api'
import { getColumns } from './contactColumnsDetail'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import Icons from '../../../../../components/common/icons'
import { useOrderDetailList, useOrderDetails } from '../../api'
import { calcWindowHeight } from '../../../../../utilities/calcHeight'
import ListingHeader from '../../../../../components/common/ListingTiles'
// import { useOrderList } from '../../api'

const OrderRequestDetailHospitalRejected = () => {
  const navigate = useNavigate()
  // const location = useLocation()
  // const [isDrawerOpen, setDrawerOpen] = useState(false)
  const [mergeIsActive, setMergeIsActve] = useState(false)
  const [setCustomBack] = useState(true)
  // const [search, setSearch] = useState("")
  // const [openPopups, setOpenPopups] = useState(false)
  const { pageParams, setPageParams, selectedRows, setSelectedRows } =
    useContactFilterStore()
  // const { advanceFilter, setAdvanceFilter } = useContactFilterStore()
  // const [showDetail, setShowDetail] = useState(true)
  const [customSearch] = useState('')
  const [customType] = useState('')
  const [dialogModalActive, setDialogModalActive] = useState(false)
  const [successModalActive, setSuccessModalActive] = useState(false)
  const [messageModalActive, setMessageModalActive] = useState(false)
  const { page, page_size, ordering, filters, filterProps } = pageParams

  const searchParams = {
    page_number: page,
    page_size: page_size,
    name: customSearch,
    ...(customType === 'select'
      ? { instution_type: '' }
      : { instution_type: customType }),
    ordering: ordering,
    ...filters,
  }
  const param = useParams()
  const { data, refetch, isFetching } = useOrderDetails(param.id, searchParams)
  const { data: list, refetch: fetchList } = useOrderDetailList(
    param.id,
    searchParams
  )
  useEffect(() => {
    refetch()
    fetchList()
  }, [
    page,
    page_size,
    ordering,
    filters,
    refetch,
    customSearch,
    customType,
    fetchList,
  ])
  // console.log(customSearch)
  const onChangeRowsPerPage = (count: any) => {
    setPageParams({
      ...pageParams,
      page_size: count,
      page: 1,
    })
  }
  const onChangePage = (row: any) => {
    setPageParams({
      ...pageParams,
      page: row,
    })
  }

  const onViewAction = (r: any, from: string) => {
    console.log(r, from)
  }
  const handleSort = (orderColumn: any, orderDirection: any) => {
    setPageParams({
      ...pageParams,
      sortColumn: orderColumn,
      sortType: orderDirection,
      ordering: getSortedColumnName(orderColumn, orderDirection),
    })
  }
  const handleSeach = (key?: string) => {
    setPageParams({
      ...pageParams,
      search: key as string,
      page: 1,
    })
  }
  const handleOnSlect = (rows: any) => {
    setSelectedRows([...rows])
  }
  // const handleColumnToggle = (columns: any) => {
  //   // console.log(columns)
  // }
  // const handleTileClick = (id: string) => {
  //   // console.log(id)
  // }
  const basicData = {
    title: 'Order Details',
    icon: 'order',
  }

  useEffect(() => {
    handleDatepicker()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterProps.endDate])

  const handleDatepicker = useCallback(() => {
    setPageParams({
      ...pageParams,
      filters: {
        ...filters,
        fromDate: filterProps?.startDate
          ? moment(filterProps?.startDate).format('DD-MM-YYYY')
          : '',
        toDate: filterProps?.endDate
          ? moment(filterProps.endDate).format('DD-MM-YYYY')
          : '',
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterProps.endDate])

  const [columns, setColumns] = useState<any>(() =>
    getColumns({
      onViewAction: onViewAction,
      statusClassGen: statusClassGen,
    })
  )

  const handleResetColums = () => {
    setColumns([
      ...getColumns({
        onViewAction: onViewAction,
        statusClassGen: statusClassGen,
      }),
    ])
  }
  if (mergeIsActive) {
    return <Outlet context={{ selectedRows, refetch }} />
  }

  const handleCreate = () => {
    setDialogModalActive(true)
  }
  const handleClose = () => {
    setDialogModalActive(false)
    handleCreate()
  }
  const handleMessageOpen = () => {
    setMessageModalActive(true)
  }
  const handleMessageClose = () => {
    setMessageModalActive(false)
  }
  const handleMerge = () => {
    navigate(`${router_config.CONTACT_MERGE.path}`)
    setMergeIsActve(true)
  }
  const handleSubmit = () => {
    handleClose()
    setSuccessModalActive(true)
  }
  const handleCloseModal = () => {
    setSuccessModalActive(false)
  }
  const activity = {
    hideExport: true,
  }

  return (
    <>
      <ListingHeader
        data={basicData}
        setCustomBack={setCustomBack}
        actionProps={activity}
      />

      <div className=" px-[24px] py-[24px]">
        <div className="">
          <div className="w-100 pb-[24px]">
            <div className="flex gap-4 justify-between w-100">
              <div className=" lg:text-xs text-[10px] px-1 2xl:px-2 font-normal leading-normal align-middle h-[40px]	text-SidenavFontColor">
                <div className="font-bold text-black">Manufacturer</div>
                <div>{data?.manufacturer_name}</div>
              </div>
              <div className=" lg:text-xs text-[10px] px-5 2xl:px-8 font-normal leading-normal align-middle h-[40px]	text-SidenavFontColor">
                <div className="font-bold text-black">Total Items</div>
                <div> {list?.order_items.length} items</div>
              </div>
              <div className=" lg:text-xs text-[10px] px-5 2xl:px-8 font-normal leading-normal align-middle h-[40px]	text-SidenavFontColor">
                <div className="font-bold text-black">Total Amount</div>
                <div>{data?.total_amount}</div>
              </div>
              <div className=" lg:text-xs text-[10px] px-5 2xl:px-8 font-normal leading-normal align-middle h-[40px]	text-SidenavFontColor">
                <div className="font-bold text-black">Purchased On</div>
                <div>
                  {' '}
                  {data?.created_at
                    ? moment(data?.created_at).format('DD-MM-YYYY')
                    : 'Not Available'}
                </div>
              </div>
              <div className=" lg:text-xs text-[10px] px-5 2xl:px-8 font-normal leading-normal align-middle h-[40px]	text-SidenavFontColor">
                <div className="font-bold text-black">Fund Used</div>
                <div>{data?.fund}</div>
              </div>
              <div className=" lg:text-xs text-[10px] px-5 2xl:px-8 font-normal leading-normal align-middle h-[40px]	text-SidenavFontColor">
                <div className="font-bold text-black">Request Status</div>
                <div className="text-RemoveButton">
                  {data?.status === 'rejected' && <div>Rejected</div>}
                </div>
              </div>
            </div>
          </div>
          <hr />
          {dialogModalActive && (
            <ConfirmModal
              isOpen={true}
              body={
                <>
                  <div className="p-10">
                    <div className="font-medium leading-8 text-xl ">
                      Are you sure you want to remove the out-of-stock item and
                      continue with the adjusted order?{' '}
                    </div>
                    <div className="flex justify-center my-5">
                      <Icons name="trash" className="w-[200px] h-[150px]" />
                    </div>
                    <div className="flex justify-center gap-4 mt-10">
                      <Button
                        label={'Yes'}
                        primary={false}
                        type="reset"
                        className="bg-AvailabilityMark/40 text-AvailabilityMark"
                        onClick={handleSubmit}
                      />
                      <Button
                        label={'No'}
                        primary={false}
                        type="reset"
                        className="bg-RemoveButton/40 text-RemoveButton"
                        onClick={handleClose}
                      />
                    </div>
                  </div>
                </>
              }
              onClose={handleClose}
              // title={'Add New Pharmacist'}
            />
          )}
          {messageModalActive && (
            <ConfirmModal
              isOpen={true}
              body={
                <>
                  <div className="2xl:p-5 p-2">
                    <div className="font-medium leading-8 text-xs border rounded-lg border-RemoveButton min-h-40 text-center p-3 ">
                      {data?.message}
                    </div>
                  </div>
                  <div className="flex justify-center 2xl:mt-5 mt-5">
                    <Button
                      label={'OK'}
                      primary={true}
                      type="button"
                      className=""
                      onClick={handleMessageClose}
                    />
                  </div>
                </>
              }
              onClose={handleMessageClose}
              // title={'Add New Pharmacist'}
            />
          )}

          {/* <PageTiles data={dashtiledata} handleTileClick={handleTileClick} /> */}
          <div className=" pt-[12px]">
            <QbsTable
              columns={columns}
              dataRowKey="id"
              selectedRows={selectedRows}
              // selection={true}
              isLoading={isFetching}
              toolbar
              handleColumnSort={handleSort}
              height={calcWindowHeight(458)}
              data={list?.order_items ?? []}
              handleResetColumns={() => handleResetColums()}
              // sortType={sortType}
              // sortColumn={sortColumn}
              pagination
              paginationProps={{
                onPagination: onChangePage,
                total: data?.total_count,
                currentPage: pageParams?.page,
                rowsPerPage: Number(pageParams?.page_size ?? data?.page_size),
                onRowsPerPage: onChangeRowsPerPage,
                dropOptions: [10, 20, 30, 50, 100, 200],
              }}
              tableHeaderActions={
                <Button
                  label={'Reject Message'}
                  primary={true}
                  type="reset"
                  onClick={handleMessageOpen}
                  className="mt-2"
                />
              }
              selectedRowActions={[
                {
                  actionTitle: 'Merge',
                  action: () => handleMerge(),
                  customHide: '>2',
                },
              ]}
              // searchValue={pageParams?.search}
              // onSearch={handleSeach}
              asyncSearch
              handleSearchValue={(key?: string) => handleSeach(key)}
              onSelect={handleOnSlect}
              columnToggle
            />
          </div>
          {successModalActive && (
            <ConfirmModal
              isOpen={true}
              body={
                <>
                  <div className="font-medium text-primary leading-8 text-xl">
                    Order has been Successfully Placed{' '}
                  </div>
                  <div className="leading-normal font-normal">
                    {' '}
                    Please check your mail for details
                  </div>
                </>
              }
              topIcon="check-circle"
              onClose={handleCloseModal}
              // title={'Add New Pharmacist'}
            />
          )}
        </div>
      </div>
    </>
  )
}
export default OrderRequestDetailHospitalRejected
