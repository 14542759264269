import moment from 'moment'
import { QbsTable } from 'qbs-react-grid'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { router_config } from '../../../../../configs/route.config'
// import { useContactFilterStore } from '../../../../../store/filterSore/contactStore'
import { statusClassGen } from '../../../../../utilities/generators'
// import { useContact } from '../api'
import { getColumns } from './contactColumns'
import {
  Button,
  ConfirmModal,
  DialogModal,
} from '../../../../../components/common'
import PrimaryStockCreate from '../create'
import { calcWindowHeight } from '../../../../../utilities/calcHeight'
import ListingHeader from '../../../../../components/common/ListingTiles'
import { usePreviousPrescription } from '../../../../Doctor/patients/all/api'
import { useDispensed } from '../api'
// import { usePatientListing } from '../../../../Doctor/patients/all/api'
const PharmacyPatientMainDetail = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  console.log(id)
  const [dialogModalActive, setDialogModalActive] = useState(false)
  const onSuccess = () => {
    navigate('/patient/dispensed')
  }
  const { mutate } = useDispensed(onSuccess)

  const [successModalActive, setSuccessModalActive] = useState(false)
  const [op, setOp] = useState<any>()
  const { data: patients } = usePreviousPrescription(id)

  console.log(patients)

  // console.log(patientData)

  //

  const [columns, setColumns] = useState<any>(() =>
    getColumns({
      statusClassGen: statusClassGen,
    })
  )

  const handleResetColums = () => {
    setColumns([
      ...getColumns({
        statusClassGen: statusClassGen,
      }),
    ])
  }

  // const activity = {
  //   hideExport: true,
  // }
  const handleBack = () => {
    navigate('/patient/dispensed')
  }
  const handleSave = () => {
    const data = {
      records: [id],
    }
    mutate(data)
  }
  const handleClose = () => {
    setDialogModalActive(false)
  }
  const handleSubmit = () => {
    setDialogModalActive(false)
    if (op) {
      navigate(`${router_config.PHARMACY_PATIENTS_ADD.path}/${op}`)
    }
  }
  const handleCloseModal = () => {
    // navigate(`${router_config.PHARMACIST.path}`)
    setSuccessModalActive(false)
  }
  const activity = {
    hideExport: true,
  }
  const basicData = {
    title: 'Prescription List',
    icon: 'institution',
  }
  return (
    <div className="">
      <ListingHeader
        data={basicData}
        actionProps={activity}
        setCustomBack={true}
      />
      <div className="w-100 py-[24px] px-[24px]">
        <div className="flex justify-between">
          <div className="flex items-center">
            <div className="sm:text-xs  font-normal leading-normal">
              <div className="text-2xl text-primary font-medium capitalize">
                {patients?.patient_name ?? '--'}
              </div>
              <div className="my-1">OP Number : {patients?.op_record_id}</div>
              <div className="">Unique ID : {patients?.patient_id}</div>
            </div>
          </div>
          <div className="flex gap-4 justify-between w-100 items-center">
            <div className=" lg:text-xs text-[10px] px-1 2xl:px-2 font-normal leading-normal align-middle h-[40px]	text-SidenavFontColor">
              <div className="font-bold text-black">Age</div>
              <div>{patients?.patient_age}</div>
            </div>
            <div className=" lg:text-xs text-[10px] px-5 2xl:px-8 font-normal leading-normal align-middle h-[40px]	text-SidenavFontColor">
              <div className="font-bold text-black">Gender</div>
              <div>{patients?.patient_genter}</div>
            </div>
            <div className=" lg:text-xs text-[10px] px-5 2xl:px-8 font-normal leading-normal align-middle h-[40px]	text-SidenavFontColor">
              <div className="font-bold text-black">Date & Time</div>
              <div>
                {patients?.prescription_created_at
                  ? moment(patients?.prescription_created_at).format(
                      'DD-MM-YYYY , hh:mm a'
                    )
                  : '--'}
              </div>
            </div>

            <div className=" lg:text-xs text-[10px] px-5 2xl:px-8 font-normal leading-normal align-middle h-[40px]	text-SidenavFontColor">
              <div className="font-bold text-black">Doctor</div>
              <div>{patients?.doctor_name}</div>
            </div>
            <div className=" lg:text-xs text-[10px] px-5 2xl:px-8 font-normal leading-normal align-middle h-[40px]	text-SidenavFontColor">
              <div className="font-bold text-black">Specialization</div>
              <div>{patients?.specialization}</div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="px-[24px] py-[24px]">
        <QbsTable
          columns={columns}
          dataRowKey="id"
          toolbar
          height={calcWindowHeight(540)}
          data={patients?.perscription_items ?? []}
          handleResetColumns={() => handleResetColums()}
          // sortType={sortType}
          // sortColumn={sortColumn}
          pagination
          paginationProps={{
            total: patients?.total,
          }}
        />
      </div>
      <div className="flex justify-center gap-5">
        <Button onClick={handleBack} label={'Cancel'} outlined />
        <Button onClick={handleSave} label={'Issue'} />
      </div>
      {dialogModalActive && (
        <DialogModal
          isOpen={true}
          body={
            <PrimaryStockCreate handleSubmit={handleSubmit} setOp={setOp} />
          }
          onClose={handleClose}
          // title={'Add New Pharmacist'}
        />
      )}
      {successModalActive && (
        <ConfirmModal
          isOpen={true}
          body={
            <div className="font-medium text-primary leading-8 text-xl">
              Stock Added Successfully
            </div>
          }
          topIcon="check-circle"
          onClose={handleCloseModal}
          // title={'Add New Pharmacist'}
        />
      )}
      {/* <Create
          isDrawerOpen={isDrawerOpen}
          handleClose={handleClose}
          handleRefresh={handleRefresh}
        />
        <Export
          isOpen={openPopups}
          handleClose={handleClose}
          slug={'EXPORT_CONTACT'}
          pageParams={searchParams}
        /> */}
    </div>
  )
}

export default PharmacyPatientMainDetail
