import { z } from 'zod'

import noLeadingSpaces from '../../../utilities/noLeadingSpaces'

export const formSchema = z.object({
  amount: z
    .string({ invalid_type_error: 'Amount is required.' })
    .min(1, { message: 'Amount is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
})
export type CreateSchema = z.infer<typeof formSchema>
