import DatePicker from 'react-datepicker'
import { FieldErrors } from 'react-hook-form'
import Icons from '../icons/index'
import {
  formatTime24Hour,
  createTimeDateFromString,
} from '../../../utilities/parsers'
import 'react-datepicker/dist/react-datepicker.css'

type Props = {
  label?: string
  onChange: (data: any) => void
  value?: any
  minDate?: Date
  maxDate?: Date
  disabled?: boolean
  required?: boolean
  errors?: FieldErrors
  placeholder?: string
  selectRange?: boolean
  name: string
  showTimeSelectOnly?: boolean
  showTimeSelect?: boolean
}

const CustomDatePicker = (props: Props) => {
  const {
    label,
    errors,
    onChange,
    value,
    maxDate,
    minDate,
    disabled,
    required,
    placeholder,
    selectRange,
    name,
    showTimeSelectOnly = false,
    showTimeSelect = false,
  } = props

  const handleClear = () => {
    const value = selectRange ? [null, null] : undefined
    onChange({ value, name })
  }
  const handleDatePickerChange = (date: any) => {
    if (date) {
      if (showTimeSelectOnly) {
        onChange({ value: formatTime24Hour(date), name: name })
        return false
      }
      const selectedDate = new Date(date)
      selectedDate.setHours(12, 0, 0, 0)
      onChange({ value: selectedDate, name: name })
    } else {
      onChange({ value: undefined, name: name })
    }
  }
  const getErrors = (err: any) => {
    let errMsg = ''
    if (err.message) {
      if (err.message != 'Expected date, received null') {
        errMsg = err?.message
      } else {
        // errMsg = 'Date is required'
        errMsg = err?.message
      }
    }
    return errMsg
  }
  const handleSelected = () => {
    if (showTimeSelectOnly) {
      return value ? createTimeDateFromString(value) : undefined
    } else {
      return value ? new Date(value) : undefined
    }
  }

  return (
    <div className="flex flex-col">
      {label && (
        <div className=" ">
          <label className={`labels label-text`}>
            {label}
            {required ? <span className="text-error"> *</span> : <></>}
          </label>
        </div>
      )}
      <div className="relative">
        <DatePicker
          selected={handleSelected()}
          disabled={disabled}
          maxDate={maxDate}
          minDate={minDate}
          onChange={(date) => handleDatePickerChange(date)}
          placeholderText={placeholder}
          className={`w-full textfield ${errors && errors[name] ? 'textfield-error' : ''}`}
          dateFormat={showTimeSelectOnly ? 'h:mm aa' : 'dd-MM-yyyy'}
          showTimeSelect={showTimeSelect}
          showTimeSelectOnly={showTimeSelectOnly}
          timeIntervals={15}
          timeCaption="Time"
          selectsRange={selectRange}
          popperModifiers={[
            {
              name: 'preventOverflow',
              options: {
                rootBoundary: 'viewport',
                tether: true,
                altAxis: false,
              },
            },
          ]}
          customInput={<input value={value} />}
        />
        {!disabled && (
          <div className="absolute right-2 flex items-center justify-center gap-1 top-2  bg-white">
            {value && (
              <button
                className="   p-0  w-5 h-5 text-[#757575]"
                onClick={() => handleClear()}
              >
                <Icons name="close" className="w-4 h-4 fill-[#757575]" />
              </button>
            )}
            <span className="   text-[#757575] block">
              <svg
                className="w-4 h-4 fill-[#757575]"
                focusable="false"
                aria-hidden="true"
                viewBox="0 0 24 24"
                data-testid="CalendarIcon"
              >
                <path d="M17 12h-5v5h5v-5zM16 1v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-1V1h-2zm3 18H5V8h14v11z"></path>
              </svg>
            </span>
          </div>
        )}
      </div>
      {errors && errors[name] && (
        <div className="text-error text-error-label mt-[1px]">
          {getErrors(errors[name])}
        </div>
      )}{' '}
    </div>
  )
}

export default CustomDatePicker
