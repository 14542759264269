import React from 'react'
import { IconProps } from '../../../common/types'

const Report: React.FC<IconProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 33 33"
      fill="none"
    >
      <path
        d="M2.75 16.5C2.75 10.0182 2.75 6.77738 4.763 4.763C6.77875 2.75 10.0182 2.75 16.5 2.75C22.9818 2.75 26.2226 2.75 28.2356 4.763C30.25 6.77875 30.25 10.0182 30.25 16.5C30.25 22.9818 30.25 26.2226 28.2356 28.2356C26.224 30.25 22.9818 30.25 16.5 30.25C10.0182 30.25 6.77738 30.25 4.763 28.2356C2.75 26.224 2.75 22.9818 2.75 16.5Z"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M9.625 13.75L12.7779 16.9029C13.0357 17.1606 13.3854 17.3055 13.75 17.3055C14.1146 17.3055 14.4643 17.1606 14.7221 16.9029L16.9029 14.7221C17.1607 14.4644 17.5104 14.3195 17.875 14.3195C18.2396 14.3195 18.5893 14.4644 18.8471 14.7221L23.375 19.25M23.375 19.25V15.8125M23.375 19.25H19.9375"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Report
