import React from 'react'
import { IconProps } from '../../../common/types'

const UserIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.85999 20.62C5.73999 20.88 6.77998 21 7.99998 21H14C15.22 21 16.26 20.88 17.14 20.62M4.85999 20.62C5.07999 18.02 7.74998 15.97 11 15.97C14.25 15.97 16.92 18.02 17.14 20.62M4.85999 20.62C2.13999 19.85 1 17.78 1 14V8C1 3 3 1 8 1H14C19 1 21 3 21 8V14C21 17.78 19.86 19.85 17.14 20.62M11 13.17C9.02005 13.17 7.42004 11.56 7.42004 9.58002C7.42004 7.60002 9.02005 6 11 6C12.98 6 14.58 7.60002 14.58 9.58002C14.58 11.56 12.98 13.17 11 13.17Z"
        stroke="#999696"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default UserIcon
