import moment from 'moment'

// }
const defaultColumnProps1 = {
  sortable: false,
  isVisible: true,
  resizable: true,
}
// const statusInfo: 1 | 2 = 1

export const getColumns = ({}: any) => {
  const createRenderCell = (key: string, isCustom?: string) => (row: any) => {
    // if (isCustom === 'action') {
    //   // console.log(row)
    //   return {
    //     cell: (
    //       <div className="flex justify-start gap-4">
    //         <div
    //           className="cursor-pointer"
    //           onClick={() => onAction(row, 'edit')}
    //         >
    //           {/* <Link
    //             to={`${router_config.PHARMACIST.path}/${row.id}`}
    //             className="text-capitalize"
    //           > */}
    //           <EditIcon />
    //           {/* </Link> */}
    //         </div>
    //         <div>
    //           <Link
    //             to={`${router_config.PHARMACIST.path}/${row.id}`}
    //             className="text-capitalize"
    //           >
    //             <div className="border-2 border-MandatoryRed/15 rounded-[10px] p-1">
    //               <DeleteIcon />
    //             </div>
    //           </Link>
    //         </div>
    //       </div>
    //     ),
    //   }
    // }
    if (isCustom === 'date') {
      // console.log(row)
      return {
        cell: (
          <>
            <div>{row?.date ? moment(row?.date).format('DD-MM-YYYY') : ''}</div>
          </>
        ),
        // toolTip: getNestedProperty(row, key) ?? '',
      }
    }
  }

  const column = [
    // {
    //   title: 'Sl.No',
    //   field: 'si_no',
    //   ...defaultColumnProps1,
    //   // fixed: true,
    //   sortKey: 'id',
    //   // width: 10,
    //   // // maxWidth :10
    //   // outerWidth:10,
    //   // rowClick: (row: any) => onViewAction(row, 'contactName'),
    //   // link: true,

    //   // customCell: true,
    // },
    {
      title: 'Transaction ID',
      field: 'transaction_id',
      // fixed: true,
      ...defaultColumnProps1,
      // link: true,
      // sortKey: 'default_contact__first_name',
      // rowClick: (row: any) => onViewAction(row, 'contactName'),

      customCell: true,
    },
    {
      title: 'Fund Received From',
      // renderCell: createRenderCell('cre_agent.name'),
      field: 'contributor_name',
      ...defaultColumnProps1,
      // customCell: true,
      // sortKey: 'cre_agent_user_first_name',

      customCell: true,
    },
    {
      title: 'Date',
      field: 'date',
      ...defaultColumnProps1,
      // sortKey: 'company_code',
      renderCell: createRenderCell('primary_company.company_code', 'date'),

      customCell: true,
    },
    {
      title: 'Amount Received',
      field: 'amount_received',
      ...defaultColumnProps1,

      sortKey: '',

      customCell: true,
    },
    // {
    //   title: 'Status',
    //   renderCell: createRenderCell('default_contact.mobile'),
    //   field: 'mobile',
    //   ...defaultColumnProps1,
    //   customCell: true,
    //   // link: true,
    //   // getpath: () => '/dashboard',
    // },

    {
      title: 'Amount Used',
      field: 'amount_used',
      ...defaultColumnProps1,

      customCell: true,
    },
    {
      title: 'Balance',
      field: 'balance',
      ...defaultColumnProps1,
      customCell: true,
    },
  ]

  return column
}
