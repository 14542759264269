import { useQuery } from '@tanstack/react-query'

import { deleteData, getData, updateJsonData } from '../../../apis/api.helpers'
import apiUrl from '../../../apis/api.url'
import { QueryParams } from '../../../common/types'
import { getErrorMessage, parseQueryParams } from '../../../utilities/parsers'
import { useSnackbar } from 'notistack'
import { useMutation } from '@tanstack/react-query'

const buildUrlWithParams = (baseUrl: string, params: any) => {
  return `${baseUrl}${parseQueryParams(params)}`
}

const fetchData = async (input: QueryParams) => {
  const url = buildUrlWithParams(apiUrl.HOSPITAL_MY_LOANS, {
    ...input,
  })
  const response = await getData(url)
  console.log(response)
  return response
}

export const useOrderList = (input: QueryParams) => {
  return useQuery(['institution_loan_list'], () => fetchData(input), {
    refetchOnWindowFocus: false,
    //staleTime: 50000,
  })
}
const fetchDetailsList = async (id: any, input: QueryParams) => {
  const url = buildUrlWithParams(
    `${apiUrl.HOSPITAL_MY_LOANS}/${id}/loan_info`,
    {
      ...input,
    }
  )
  const response = await getData(url)
  // // console.log(response)
  return response
}

export const useOrderDetailList = (id: any, input: QueryParams) => {
  return useQuery(
    ['institution_Orders_Detail_list'],
    () => fetchDetailsList(id, input),
    {
      refetchOnWindowFocus: false,
      //staleTime: 50000,
    }
  )
}

const fetchDetails = async (id: any, input: QueryParams) => {
  const url = buildUrlWithParams(
    `${apiUrl.HOSPITAL_MY_LOANS}/${id}/medicines_list`,
    {
      ...input,
    }
  )
  const response = await getData(url)
  // // console.log(response)
  return response
}

export const useOrderDetails = (id: any, input: QueryParams) => {
  return useQuery(['iorder_detail'], () => fetchDetails(id, input), {
    refetchOnWindowFocus: false,
    //staleTime: 50000,
  })
}

export const deleteOrder = (id: any) => {
  return deleteData(`${apiUrl.LOAN}/${id}`)
}
export const deleteOrderItems = (id: any, item_id: any) => {
  return deleteData(`${apiUrl.HOSPITAL_MY_LOANS}/${id}/destroy_item/${item_id}`)
}
// export const createContact = (input: any) => {
//   return postData(apiUrl.CONTACT_CREATE, input)
// }
// export const useCreateContact = (handleSubmission: (data: any) => void) => {
//   const { enqueueSnackbar } = useSnackbar()
//   return useMutation(createContact, {
//     onSuccess: (res: any) => {
//       handleSubmission(res.data)
//       enqueueSnackbar('Contact Created successfully', { variant: 'success' })
//     },

//     onError: (error: any) => {
//       enqueueSnackbar(getErrorMessage(error?.response?.data?.message || "Something went wrong"), {
//         variant: 'error',
//       })
//     },
//   })
// }

export const MarkDelivered = (data: { input: any; id: string }) => {
  return updateJsonData(
    `${apiUrl.INSTITUTION_ORDER_LIST}/${data?.id}`,
    data?.input
  )
}

export const useStatusChange = (
  handleSubmission: (input: any, id: string) => void
) => {
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(MarkDelivered, {
    onSuccess: (res: any) => {
      handleSubmission(res.data, res.id)
    },

    onError: (error: any) => {
      enqueueSnackbar(getErrorMessage(error.response.data.message), {
        variant: 'error',
      })
    },
  })
}

// export const getContactMergeDetails = (data: any) => {
//   return postData(apiUrl.CONTACT_MULTIPLE_DETAILS, data)
// }

// export const mergeContacts = (data: any, id?: string) => {
//   return updateData(`${apiUrl.CONTACT_LIST_URL}${id}/merge/`, data)
// }
// export const getContactDetails = (id: any) => {
//   return getData(`${apiUrl.CONTACT_LIST_URL}${id}`)
// }

// export const setPrimaryAccount = (id: string, data: any) => {
//   return updateData(
//     `${apiUrl.CONTACT_LIST_URL}${id}/set-primary-account/`,
//     data
//   )
// }

// // export const getContactAccountDetails = (id: any) => {
// //   return getData(`${apiUrl.CONTACT_LIST_URL}${id}/accounts/?page=1`)
// // }

// // const accountsfetchData = async (id: string, input: QueryParams) => {
// //   const url = buildUrlWithParams(`${apiUrl.CONTACT_LIST_URL}${id}/accounts/`, {

// const accountsfetchData = async (id: string, input: QueryParams) => {
//   const url = buildUrlWithParams(`${apiUrl.CONTACT_LIST_URL}${id}/accounts/`, {
//     ...input,
//   })
//   const response = await getData(url)
//   return response?.data
// }

// export const useContactAccountDetails = (id: string, input: QueryParams) => {
//   return useQuery(
//     ['assosiated_accout_list'],
//     () => accountsfetchData(id, input),
//     {
//       refetchOnWindowFocus: false,
//       //staleTime: 50000,
//     }
//   )
// }
// export const getContactDuplicate = (data: any, config: AxiosRequestConfig) =>
//   getConfigData(
//     `${apiUrl.CONTACT_LIST_URL}possible-contact-duplicates/${parseQueryParams(
//       data ?? {}
//     )}`,
//     config
//   )

// export const listContactDuplicate = (id?: string, data?: any) =>
//   getData(
//     `${apiUrl.CONTACT_LIST_URL}${id}/contact-duplicates/${parseQueryParams(
//       data
//     )}`
//   )

// export const updateContactCre = (id: string, data: any) => {
//   return postData(`${apiUrl.CONTACT_LIST_URL}${id}/allocate-cre`, data)
// }
