// import { getNestedProperty, useCurrencyFormat } from '../../utilities/parsers'
// import { useNavigate } from "react-router-dom"

// import { Link } from 'react-router-dom'
// import { router_config } from '../../configs/route.config'
// import { useNavigate } from "react-router-dom"

// import { Router } from "react-router-dom"

// const defaultColumnProps = {
//   sortable: true,
//   resizable: true,
//   isVisible: true,
// }
const defaultColumnProps1 = {
  sortable: false,
  isVisible: true,
  resizable: true,
}
// const defaultColumnProps2 = {
//   sortable: true,
//   isVisible: false,
//   resizable: true,
// }
// const defaultColumnProps3 = {
//   sortable: false,
//   isVisible: true,
//   resizable: true,
// }

// const navigate = useNavigate()

export const getColumns = ({}: any) => {
  const column = [
    {
      title: 'District',
      // renderCell: createRenderCell('cre_agent.name'),
      field: 'district',
      ...defaultColumnProps1,
    },
    {
      title: 'Email',
      field: 'email',
      ...defaultColumnProps1,
      colWidth: 350,
    },
    {
      title: 'Land Phone Number',
      field: 'land_phone_number',
      ...defaultColumnProps1,
      colWidth: 200,
    },
    {
      title: 'Phone Number',
      field: 'phone_number',
      ...defaultColumnProps1,
      colWidth: 200,
    },
  ]

  return column
}
