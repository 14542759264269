const defaultColumnProps1 = {
  sortable: false,
  isVisible: true,
  resizable: true,
}

export const getColumns = ({}: any) => {
  const createRenderCell = (key: string, isCustom?: string) => (row: any) => {
    if (isCustom === 'status') {
      console.log(row)
      return {
        cell: (
          // <div className="w-[127px] h-[40px] rounded-[10px] bg-AvailabilityMark/15 flex justify-center items-center">
          <>
            {row?.status === 'approved' ? (
              <span className="inline-block align-middle text-AvailabilityMark items-center font-medium leading-normal">
                Approved
              </span>
            ) : (
              <span className="inline-block align-middle text-AvailabilityMark items-center font-medium leading-normal">
                Delivered{' '}
              </span>
            )}
          </>
          // </div>
          // <Button
          //   label={'Cancel request'}
          //   primary={false}
          //   disabled={true}
          //   outlined
          //   type="reset"
          //   className="h-[53px] min-w-fit bg-cancelTab/15 text-cancelTab"
          //   // onClick={handleCreate}
          // />
        ),
      }
    }
  }
  const column = [
    {
      title: 'Manufacturer Name',
      // renderCell: createRenderCell('cre_agent.name'),
      field: 'name',
      ...defaultColumnProps1,
      // customCell: true,
      // sortKey: 'cre_agent_user_first_name',
    },
    {
      title: 'Phone Number',
      field: 'manufacturer_phone_number',
      ...defaultColumnProps1,
    },
    {
      title: 'Email',
      field: 'maunufacturer_email',
      ...defaultColumnProps1,

      sortKey: '',
    },
    {
      title: 'Status',
      renderCell: createRenderCell('default_contact.mobile', 'status'),
      field: 'mobile',
      ...defaultColumnProps1,
      customCell: true,
    },
  ]

  return column
}
