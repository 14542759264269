import { Outlet, useNavigate } from 'react-router-dom'
import { TabContainer } from '../../../components/common'
import { router_config } from '../../../configs/route.config'
import { useState } from 'react'
// import ListingHeader from '../../../components/common/ListingTiles/index-new'
import ListingHeader from '../../../components/common/ListingTiles'

const tabData: any[] = [
  {
    label: 'All',

    id: 'HOSPITAL_LOAN_REQUEST_ALL',
  },
  {
    label: 'New',

    id: 'HOSPITAL_LOAN_REQUEST_NEW',
  },
  {
    label: 'Accepted',

    id: 'HOSPITAL_LOAN_REQUEST_ACCEPTED',
  },
  {
    label: 'Reverted',

    id: 'HOSPITAL_LOAN_REQUEST_REVERTED',
  },
  {
    label: 'Approved',

    id: 'HOSPITAL_LOAN_REQUEST_APPROVED',
  },
]

const MedicineLoanRequestMain: React.FC<any> = () => {
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState('HOSPITAL_LOAN_REQUEST_ALL')
  const onTabClick = (selection: any) => {
    setActiveTab(selection.id)
    navigate(`/loan/request/${router_config[selection.id]?.path}`)
  }
  const context = { setActiveTab }
  const activity = {
    hideExport: true,
  }
  const basicData = {
    title: 'Medicine Loan Requests',
    icon: 'institution',
  }
  return (
    <>
      <ListingHeader
        data={basicData}
        actionTitle="Add Institution"
        actionProps={activity}
      />
      <TabContainer data={tabData} activeTab={activeTab} onClick={onTabClick}>
        <Outlet context={context} />
      </TabContainer>
    </>
  )
}

export default MedicineLoanRequestMain
