import { useCallback, useEffect, useState } from 'react'
import { Button } from '../../../../../components/common'
// import { useNavigate } from 'react-router-dom'

import { DialogModal } from '../../../../../components/common'
import { FormProvider, useForm } from 'react-hook-form'
import CommonForm from '../../pharmacist_form'
import { ConfirmModal } from '../../../../../components/common'
import { QbsTable } from 'qbs-react-grid'
import moment from 'moment'
import ListingHeader from '../../../../../components/common/ListingTiles'
import { router_config } from '../../../../../configs/route.config'

import { useContactFilterStore } from '../../../../../store/filterSore/contactStore'
import { statusClassGen } from '../../../../../utilities/generators'
import { getSortedColumnName } from '../../../../../utilities/parsers'
import { useOrderDetailList, useOrderDetails, useReplacement } from '../../api'
import { getColumns } from './contactColumnsDetail'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { calcWindowHeight } from '../../../../../utilities/calcHeight'
import Icons from '../../../../../components/common/icons'
import { CreateSchema, formSchema } from './schema'
import { zodResolver } from '@hookform/resolvers/zod'

const OrderRequestDetailHospitalDelivered = () => {
  const navigate = useNavigate()
  // const location = useLocation()
  // const [isDrawerOpen, setDrawerOpen] = useState(false)
  const [mergeIsActive, setMergeIsActve] = useState(false)
  // const [search, setSearch] = useState("")
  // const [openPopups, setOpenPopups] = useState(false)
  const { pageParams, setPageParams, selectedRows, setSelectedRows } =
    useContactFilterStore()
  const [setCustomBack] = useState(true)

  const [customSearch] = useState('')
  const [customType] = useState('')
  // const param = useParams()
  const [dialogModalActive, setDialogModalActive] = useState(false)
  const [successModalActive, setSuccessModalActive] = useState(false)
  const [replaceId, setReplaceId] = useState<any>()

  const { page, page_size, ordering, filters, filterProps } = pageParams
  const onSuccess = () => {
    handleSubmit()
  }
  const { mutate } = useReplacement(onSuccess)

  const methods: any = useForm<CreateSchema>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  useEffect(() => {
    if (replaceId) {
      methods.reset({
        ...replaceId,
      })
    }
    // console.log(data)
  }, [replaceId, methods])

  const onSubmit = (payload: CreateSchema) => {
    // setSuccessModalActive(true)
    // console.log(payload)
    console.log(payload)
    const data = {
      records: [
        {
          order_item_id: replaceId?.id ? replaceId?.id : null,
          quantity: payload?.quantity,
          reason: payload?.reason,
        },
      ],
    }
    mutate(data)
  }
  const searchParams = {
    page_number: page,
    page_size: page_size,
    name: customSearch,
    ...(customType === 'select'
      ? { instution_type: '' }
      : { instution_type: customType }),
    ordering: ordering,
    ...filters,
  }
  const param = useParams()
  const { data: list, refetch: fetchList } = useOrderDetailList(
    param.id,
    searchParams
  )
  const { data, refetch, isFetching } = useOrderDetails(param.id, searchParams)
  useEffect(() => {
    refetch()
    fetchList()
  }, [
    page,
    page_size,
    ordering,
    filters,
    refetch,
    customSearch,
    customType,
    fetchList,
  ])
  // console.log(customSearch)
  const onChangeRowsPerPage = (count: any) => {
    setPageParams({
      ...pageParams,
      page_size: count,
      page: 1,
    })
  }
  const onChangePage = (row: any) => {
    setPageParams({
      ...pageParams,
      page: row,
    })
  }

  const onViewAction = (r: any, from: string) => {
    console.log(r, from)
  }
  const handleSort = (orderColumn: any, orderDirection: any) => {
    setPageParams({
      ...pageParams,
      sortColumn: orderColumn,
      sortType: orderDirection,
      ordering: getSortedColumnName(orderColumn, orderDirection),
    })
  }
  const handleSeach = (key?: string) => {
    setPageParams({
      ...pageParams,
      search: key as string,
      page: 1,
    })
  }
  const handleOnSlect = (rows: any) => {
    setSelectedRows([...rows])
  }

  const basicData = {
    title: 'Order Details',
    icon: 'order',
  }
  useEffect(() => {
    handleDatepicker()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterProps.endDate])

  const handleDatepicker = useCallback(() => {
    setPageParams({
      ...pageParams,
      filters: {
        ...filters,
        fromDate: filterProps?.startDate
          ? moment(filterProps?.startDate).format('DD-MM-YYYY')
          : '',
        toDate: filterProps?.endDate
          ? moment(filterProps.endDate).format('DD-MM-YYYY')
          : '',
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterProps.endDate])

  const [columns, setColumns] = useState<any>(() =>
    getColumns({
      onViewAction: onViewAction,
      statusClassGen: statusClassGen,
    })
  )

  const handleResetColums = () => {
    setColumns([
      ...getColumns({
        onViewAction: onViewAction,
        statusClassGen: statusClassGen,
      }),
    ])
  }
  if (mergeIsActive) {
    return <Outlet context={{ selectedRows, refetch }} />
  }
  const activity = {
    hideExport: true,
  }

  const handleCreate = (r: any) => {
    setDialogModalActive(true)
    setReplaceId(r)
  }
  const handleClose = () => {
    setDialogModalActive(false)
    methods.reset()
    setReplaceId(null)
  }

  const handleMerge = () => {
    navigate(`${router_config.CONTACT_MERGE.path}`)
    setMergeIsActve(true)
  }

  const handleSubmit = () => {
    handleClose()
    setSuccessModalActive(true)
  }
  const handleCloseModal = () => {
    setSuccessModalActive(false)
    methods.reset()
    setReplaceId(null)
  }
  const onAction = (r: any, from: string) => {
    if (from === 'replace' && r.id) {
      handleCreate(r)
    }
  }
  // console.log(methods.watch())
  return (
    <>
      <ListingHeader
        data={basicData}
        setCustomBack={setCustomBack}
        actionProps={activity}
      />

      <div className=" px-[24px] py-[24px]">
        <div className="">
          <div className="w-100 pb-[24px]">
            <div className="flex gap-4 justify-between w-100">
              <div className=" lg:text-xs text-[10px] px-1 2xl:px-2 font-normal leading-normal align-middle h-[40px]	text-SidenavFontColor">
                <div className="font-bold text-black">Manufacturer</div>
                <div>{data?.manufacturer_name}</div>
              </div>
              <div className=" lg:text-xs text-[10px] px-5 2xl:px-8 font-normal leading-normal align-middle h-[40px]	text-SidenavFontColor">
                <div className="font-bold text-black">Total Items</div>
                <div> {list?.total_count} items</div>
              </div>
              <div className=" lg:text-xs text-[10px] px-5 2xl:px-8 font-normal leading-normal align-middle h-[40px]	text-SidenavFontColor">
                <div className="font-bold text-black">Total Amount</div>
                <div>{data?.total_amount}</div>
              </div>
              <div className=" lg:text-xs text-[10px] px-5 2xl:px-8 font-normal leading-normal align-middle h-[40px]	text-SidenavFontColor">
                <div className="font-bold text-black">Purchased On</div>
                <div>
                  {' '}
                  {data?.created_at
                    ? moment(data?.created_at).format('DD-MM-YYYY')
                    : 'Not Available'}
                </div>
              </div>
              <div className=" lg:text-xs text-[10px] px-5 2xl:px-8 font-normal leading-normal align-middle h-[40px]	text-SidenavFontColor">
                <div className="font-bold text-black">Fund Used</div>
                <div>{data?.fund}</div>
              </div>
              <div className=" lg:text-xs text-[10px] px-5 2xl:px-8 font-normal leading-normal align-middle h-[40px]	text-SidenavFontColor">
                <div className="font-bold text-black">Request Status</div>
                <div className="text-AvailabilityMark">
                  {data?.status === 'delivered' && <div>Delivered</div>}
                </div>
              </div>
            </div>
          </div>
          <hr />
          {dialogModalActive && (
            <DialogModal
              isOpen={true}
              body={
                <div className="my-8 mx-8">
                  <h2 className="flex text-center w-full mx-auto justify-center text-lime-500 text-2xl font-medium"></h2>
                  <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <div className="grid md:grid-cols-2 md:gap-8 sm:grid-cols-1 sm:gap-[24px] p-4">
                      <FormProvider {...methods}>
                        <CommonForm edit={true} />
                      </FormProvider>
                    </div>
                    <div className="flex gap-8 justify-center my-3">
                      <Button
                        onClick={handleClose}
                        label={'Cancel'}
                        outlined={true}
                      />
                      <Button
                        // onClick={methods.handleSubmit}
                        type="submit"
                        label={'Replace Product'}
                        className="w-[155px]"
                      />
                    </div>
                  </form>
                </div>
              }
              onClose={handleClose}
              // title={'Add New Pharmacist'}
            />
          )}

          {/* <PageTiles data={dashtiledata} handleTileClick={handleTileClick} /> */}
          <div className=" pt-[12px]">
            <QbsTable
              columns={columns}
              dataRowKey="id"
              selectedRows={selectedRows}
              // selection={true}
              isLoading={isFetching}
              toolbar
              handleColumnSort={handleSort}
              height={calcWindowHeight(440)}
              data={list?.order_items ?? []}
              handleResetColumns={() => handleResetColums()}
              // sortType={sortType}
              // sortColumn={sortColumn}
              pagination
              paginationProps={{
                onPagination: onChangePage,
                total: data?.total_count,
                currentPage: pageParams?.page,
                rowsPerPage: Number(pageParams?.page_size ?? data?.page_size),
                onRowsPerPage: onChangeRowsPerPage,
                dropOptions: [10, 20, 30, 50, 100, 200],
              }}
              actionProps={[
                {
                  title: 'Replace',
                  action: (rowData) => onAction(rowData, 'replace'),
                  icon: <Icons name="replace" />,
                  toolTip: 'Replace Product',
                },
              ]}
              selectedRowActions={[
                {
                  actionTitle: 'Merge',
                  action: () => handleMerge(),
                  customHide: '>2',
                },
              ]}
              // searchValue={pageParams?.search}
              // onSearch={handleSeach}
              asyncSearch
              handleSearchValue={(key?: string) => handleSeach(key)}
              onSelect={handleOnSlect}
              columnToggle
            />
          </div>
          {successModalActive && (
            <ConfirmModal
              isOpen={true}
              body={
                <div className="font-medium text-primary leading-8 text-xl">
                  Product Replaced Successfully
                </div>
              }
              topIcon="check-circle"
              onClose={handleCloseModal}
              // title={'Add New Pharmacist'}
            />
          )}
        </div>
      </div>
    </>
  )
}
export default OrderRequestDetailHospitalDelivered
