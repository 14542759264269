import React from 'react'
import { IconProps } from '../../../common/types'

const Dms: React.FC<IconProps> = () => {
  return (
    <svg
      width="34"
      height="33"
      viewBox="0 0 34 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="icon-park-outline:medicine-chest">
        <g id="Group">
          <path
            id="Vector"
            d="M28 11H6C4.86091 11 3.9375 11.9234 3.9375 13.0625V26.8125C3.9375 27.9516 4.86091 28.875 6 28.875H28C29.1391 28.875 30.0625 27.9516 30.0625 26.8125V13.0625C30.0625 11.9234 29.1391 11 28 11Z"
            stroke="#636363"
            strokeWidth="2"
            strokeLinejoin="round"
          />
          <path
            id="Vector_2"
            d="M13.5625 5.5H20.4375V2.75H13.5625V5.5ZM21.125 6.1875V11H23.875V6.1875H21.125ZM12.875 11V6.1875H10.125V11H12.875ZM20.4375 5.5C20.6198 5.5 20.7947 5.57243 20.9236 5.70136C21.0526 5.8303 21.125 6.00516 21.125 6.1875H23.875C23.875 5.27582 23.5128 4.40148 22.8682 3.75682C22.2235 3.11216 21.3492 2.75 20.4375 2.75V5.5ZM13.5625 2.75C12.6508 2.75 11.7765 3.11216 11.1318 3.75682C10.4872 4.40148 10.125 5.27582 10.125 6.1875H12.875C12.875 6.00516 12.9474 5.8303 13.0764 5.70136C13.2053 5.57243 13.3802 5.5 13.5625 5.5V2.75Z"
            fill="#636363"
          />
          <path
            id="Vector_3"
            d="M12.875 19.9375H21.125M17 15.8125V24.0625"
            stroke="#636363"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  )
}

export default Dms
