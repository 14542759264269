import { z } from 'zod'

// import noLeadingSpaces from '../../../../utilities/noLeadingSpaces'

export const formSchema = z.object({
  name: z.string().optional().nullable(),

  district_id: z.number().optional().nullable(),
  district_name: z.string().optional().nullable(),

  code: z.number().optional().nullable(),
  state: z.string().optional().nullable(),
  stream: z.string().optional().nullable(),
  start_date: z.date({ required_error: 'Start Date is Required' }),
  end_date: z.date({ required_error: 'End Date is Required' }),
})

export type CreateSchema = z.infer<typeof formSchema>

// assembly_constituency_id: z.string(),
// pan_no: z
//   .string()
//   .refine((value) => value === '' || /^[A-Z]{5}\d{4}[A-Z]{1}$/.test(value), {
//     message: 'Invalid PAN number format',
//   })
//   .refine((value) => value === '' || noLeadingSpaces(value), {
//     message: 'Leading spaces are not allowed',
//   })
//   .optional(),

// stateId: z.string().optional().nullable(),
// code: z.string().optional().nullable(),
// city: z.string().min(1, 'City is required.').refine(noLeadingSpaces, {
//   message: 'Leading spaces are not allowed',
// }),
// address_1: z.string().optional().nullable(),
// address_2: z.string().optional().nullable(),
// country: z.string().min(1, 'Country is required.'),
// countryId: z.string().optional().nullable(),

// pin_code: z.string().optional().nullable(),
// phone_number: z
// .string()
// .refine((value) => value === '' || /^(\+?\d{10,12})$/.test(value), {
//   message: 'Invalid mobile number.',
// })
// .optional(),
