// import { Router } from "react-router-dom"

// import { Link } from 'react-router-dom'
// import { router_config } from '../../../configs/route.config'
// import Info from '../../../components/common/icons/Info'

// const defaultColumnProps = {
//   sortable: true,
//   resizable: true,
//   isVisible: true,
// }
const defaultColumnProps1 = {
  sortable: false,
  isVisible: true,
  resizable: true,
}

export const getColumns = ({}: any) => {
  const createRenderCell = (key: string, isCustom?: string) => (row: any) => {
    // if (isCustom === 'action') {
    //   // console.log(row)
    //   return {
    //     cell: (
    //       <div className="flex justify-start gap-4">
    //         <div className="cursor-pointer">
    //           <Link
    //             to={`${router_config.MANUFACTURER_ORDER_REQUEST.path}/${row.id}`}
    //             className="text-capitalize"
    //           >
    //             <Info />
    //           </Link>
    //         </div>
    //       </div>
    //     ),
    //   }
    // }
    if (isCustom === 'status') {
      console.log(row)
      return {
        cell: (
          <>
            {row.status === 'pending' ? (
              <span className="inline-block  text-ShortageStatus  font-medium leading-normal">
                Pending
              </span>
            ) : row.status === 'confirmed' ? (
              <span className="inline-block  text-primary  font-medium leading-normal">
                Confirmed
              </span>
            ) : row.status === 'delivered' ? (
              <span className="inline-block  text-AvailabilityMark  font-medium leading-normal">
                Delivered
              </span>
            ) : row.status === 'reverted' ? (
              <span className="inline-block  text-RevertedStatus  font-medium leading-normal">
                Reverted
              </span>
            ) : (
              <span className="inline-block  text-RemoveButton  font-medium leading-normal">
                Rejected
              </span>
            )}
          </>
        ),
      }
    }
  }
  const column = [
    // {
    //   title: 'Sl.No',
    //   field: 'id',
    //   ...defaultColumnProps1,
    //   // fixed: true,
    //   sortKey: 'id',
    //   // rowClick: (row: any) => onViewAction(row, 'contactName'),
    //   // link: true,
    // },
    {
      title: 'Institution Name',
      // renderCell: createRenderCell('cre_agent.name'),
      field: 'institution_name',
      fixed: true,
      ...defaultColumnProps1,
      // customCell: true,
      // sortKey: 'cre_agent_user_first_name',
    },
    {
      title: 'Order ID',
      field: 'id',
      // fixed: true,
      ...defaultColumnProps1,
      // link: true,
      // sortKey: 'default_contact__first_name',
      // rowClick: (row: any) => onViewAction(row, 'contactName'),
    },

    {
      title: 'Phone Number',
      field: 'phone_number',
      ...defaultColumnProps1,
      // sortKey: 'company_code',
      // renderCell: createRenderCell('primary_company.company_code'),

      // rowClick: (row: any) => onViewAction(row, 'business_name'),
      // link: true,
    },
    {
      title: 'Email',
      field: 'email',
      ...defaultColumnProps1,
      // link: true,
      // sortKey: '',
      // renderCell: createRenderCell('primary_company.business_name'),
      // rowClick: (row: any) => onViewAction(row, 'business_name'),
    },
    {
      title: 'Status',
      // field: 'status',
      ...defaultColumnProps1,
      customCell: true,
      sortKey: '',
      renderCell: createRenderCell('default_contact.mobile', 'status'),
      // rowClick: (row: any) => onViewAction(row, 'business_name'),
    },

    // {
    //   title: 'Action',
    //   renderCell: createRenderCell('default_contact.mobile', 'action'),
    //   field: 'mobile',
    //   ...defaultColumnProps1,
    //   customCell: true,
    //   // link: true,
    // },
  ]

  return column
}
