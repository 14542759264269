import React from 'react'
import { IconProps } from '../../../common/types'

const Doctor: React.FC<IconProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 48 48"
    >
      <g fill="black" fillRule="evenodd" clipRule="evenodd">
        <path d="m16.649 9.326l.055-.24c.105-.446.277-1.102.632-1.748c.362-.658.923-1.325 1.803-1.764c.877-.437 1.976-.6 3.335-.42c1.5.2 4.513.696 7.175 2.05c2.677 1.362 5.185 3.705 5.185 7.607c0 2.016-.78 4.179-1.536 5.589c-.363.678-.794 1.326-1.226 1.675c-.099.08-.24.18-.415.25a8.004 8.004 0 0 1-15.05.738l-.024.004l-.355-.431L17 22l-.772.636l-.001-.002l-.002-.002l-.005-.007l-.017-.02a6.849 6.849 0 0 1-.247-.327a13.446 13.446 0 0 1-.61-.924c-.47-.778-1.037-1.886-1.4-3.179c-.362-1.293-.528-2.809-.148-4.373c.37-1.522 1.243-3.02 2.824-4.358zm1.68 12.638a6.003 6.003 0 0 0 11.564-.833l.113.038a2.43 2.43 0 0 1-.006-.17c0-1.655-.23-2.81-.444-3.53a6.71 6.71 0 0 0-.139-.416l-.041.002h-.04a11.485 11.485 0 0 1-2.232-.17c-1.717-.29-4.042-1.014-6.78-2.691c-.06.134-.12.285-.18.453c-.196.555-.357 1.214-.496 1.91c-.123.613-.224 1.23-.32 1.808l-.037.228c-.103.62-.206 1.229-.326 1.67c-.21.766-.424 1.31-.636 1.7m-1.4-1.863a10.949 10.949 0 0 1-1.056-2.465c-.299-1.066-.409-2.22-.131-3.361c.27-1.11.923-2.277 2.266-3.383c.242-.164.352-.384.382-.443v-.001a1.91 1.91 0 0 0 .126-.337c.032-.114.067-.27.1-.41l.035-.157c.095-.403.218-.842.438-1.243c.214-.388.507-.72.943-.937c.438-.219 1.116-.369 2.178-.227c1.453.193 4.186.656 6.532 1.85c2.33 1.185 4.092 2.979 4.092 5.824c0 1.31-.44 2.8-.97 3.975c-.1-.766-.244-1.392-.392-1.888a7.656 7.656 0 0 0-.385-1.037a4.507 4.507 0 0 0-.19-.365l-.017-.027l-.006-.01l-.003-.005l-.002-.003l-.84.54l.84-.54l-.37-.574l-.662.133l-.014.003l-.097.013a5.093 5.093 0 0 1-.448.03a9.487 9.487 0 0 1-1.84-.144c-1.613-.272-3.983-1.013-6.862-2.93l-.7-.467l-.579.61c-.477.502-.801 1.187-1.038 1.854c-.242.685-.425 1.45-.572 2.184a53.34 53.34 0 0 0-.332 1.88l-.038.224c-.107.651-.194 1.148-.282 1.47a9.214 9.214 0 0 1-.106.364m13.881.422l.004-.002zm.004-.002l-.004.002z" />
        <path d="M17.914 28.855c-.212-.422-.473-.943-.914-.842c-5.404 1.23-11 4.781-11 8.557V42h36v-5.43c0-2.974-3.472-5.809-7.587-7.48l-.005-.01a1.461 1.461 0 0 0-.014-.027l-.033.016c-1.093-.44-2.231-.8-3.361-1.056c-.503-.115-1.023.577-1.25 1.01H18c-.028-.052-.056-.11-.086-.168m13.489 1.32c.437.121.872.257 1.301.407c.012.342-.014.746-.07 1.158a8.092 8.092 0 0 1-.272 1.26H31a1 1 0 0 0-.894.553l-1 2A1 1 0 0 0 29 36v2a1 1 0 0 0 1 1h2v-2h-1v-.764L31.618 35h2.764L35 36.236V37h-1v2h2a1 1 0 0 0 1-1v-2a1 1 0 0 0-.106-.447l-1-2A1 1 0 0 0 35 33h-.566a10.66 10.66 0 0 0 .248-1.609c.975.461 1.881.99 2.666 1.562C39.27 34.355 40 35.667 40 36.57V40H8v-3.43c0-.903.73-2.215 2.652-3.617c.966-.705 2.119-1.343 3.355-1.871a10.179 10.179 0 0 0 .381 2.354l.008.028a3 3 0 1 0 1.956-.444a8.092 8.092 0 0 1-.28-1.28a7.01 7.01 0 0 1-.069-1.171a3.99 3.99 0 0 1 .015-.224c.12-.037.24-.073.36-.107l.415.786h14.164zM16 37.016c.538 0 1-.44 1-1.015c0-.574-.462-1.015-1-1.015s-1 .44-1 1.015c0 .574.462 1.015 1 1.015" />
      </g>
    </svg>
  )
}

export default Doctor
