import { z } from 'zod'

const MAX_FILE_SIZE = 5000000

// const ACCEPTED_IMAGE_TYPES = ['xlsx']

// export const formSchema = z.object({
//   stocks_file: z
//   .instanceof(File, { message: 'Attachment required' })
//   .refine((files: File) => files.size <= MAX_FILE_SIZE, {
//     message: 'Maximum size 5mb',
//   })
//   .refine((files: File) => ACCEPTED_IMAGE_TYPES.includes(files?.type), {
//     message: 'File type not allowed',
//   }),
// })

const ACCEPTED_FILE_EXTENSIONS = ['xlsx']

export const formSchema = z.object({
  stocks_file: z
    .instanceof(File, { message: 'Attachment required' })
    .refine((files) => files.size <= MAX_FILE_SIZE, {
      message: 'Maximum size 5mb',
    })
    .refine(
      (files) => {
        const fileName = files?.name || ''
        const fileExtension = fileName.split('.').pop()?.toLowerCase() || ''

        return ACCEPTED_FILE_EXTENSIONS.includes(fileExtension)
      },
      {
        message: 'File type not allowed. Please upload an .xlsx file.',
      }
    ),
})

export type CreateSchema = z.infer<typeof formSchema>
