// import FormBuilder from '../../../components/app/formBuilder/index'

import { Button, ConfirmModal } from '../../../components/common'
import { useLocation, useNavigate } from 'react-router-dom'
import { router_config } from '../../../configs/route.config'
import CommonForm from '../manufacturer_form'
import { FormProvider, useForm } from 'react-hook-form'
import { useState } from 'react'
import { CreateSchema, formSchema } from './schema'
import { zodResolver } from '@hookform/resolvers/zod'
import { useCreateManufacturer } from './api'
import ListingHeader from '../../../components/common/ListingTiles'

const ManufacturerCreate = () => {
  const navigate = useNavigate()
  const [setCustomBack] = useState(true)
  const location = useLocation()

  const onSuccess = () => {
    setSuccessModalActive(true)
  }
  const { mutate } = useCreateManufacturer(onSuccess)
  const [successModalActive, setSuccessModalActive] = useState(false)
  const methods = useForm<CreateSchema>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: { state: 'Kerala' },
  })

  const handleCloseModal = () => {
    setSuccessModalActive(false)
    {
      location.pathname === '/manufacturer-create'
        ? navigate(`${router_config.MANUFACTURER.path}`)
        : navigate(`${router_config.DISTRICT_MEDICAL.path}`)
    }
  }
  const onSubmit = (payload: CreateSchema) => {
    const formData = new FormData()
    formData.append('manufacturer[district_id]', String(payload.district_id))
    formData.append('manufacturer[address]', String(payload.address))
    formData.append('manufacturer[land_number]', String(payload.land_number))
    location.pathname === '/district-medical-store/add'
      ? formData.append('manufacturer[category]', 'dms')
      : formData.append('manufacturer[category]', 'non-dms')
    formData.append(
      'manufacturer[user_attributes][email]',
      String(payload.email)
    )
    formData.append(
      'manufacturer[user_attributes][phone_number]',
      String(payload.phone_number)
    )
    formData.append('manufacturer[name]', String(payload.name))
    mutate(formData)
  }
  const basicData = {
    title: 'Add Manufacturer',
    icon: 'stock',
  }
  const basicDatas = {
    title: 'Add Medical Store',
    icon: 'stock',
  }
  const activity = {
    hideExport: true,
  }
  return (
    <>
      {location.pathname === '/manufacturer-create' ? (
        <ListingHeader
          data={basicData}
          actionProps={activity}
          setCustomBack={setCustomBack}
        />
      ) : (
        <ListingHeader
          data={basicDatas}
          actionProps={activity}
          setCustomBack={setCustomBack}
        />
      )}
      <div className=" px-[24px] py-[24px]">
        <div className="">
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="grid md:grid-cols-3 md:gap-8 sm:grid-cols-1 sm:gap-[24px]">
              <FormProvider {...methods}>
                <CommonForm edit={false} />
              </FormProvider>
            </div>
            <div className="flex gap-8 mt-3 justify-center">
              <Button
                label={'Clear'}
                onClick={() => methods.reset({})}
                outlined={true}
              />
              <Button
                // onClick={methods.handleSubmit}
                type="submit"
                label={'Submit'}
              />
            </div>
          </form>

          {successModalActive && (
            <ConfirmModal
              isOpen={true}
              body={
                <div className="font-medium text-primary leading-8 text-xl">
                  Registration Completed Successfully
                </div>
              }
              topIcon="check-circle"
              onClose={handleCloseModal}
              // title={'Add New Pharmacist'}
            />
          )}
        </div>
      </div>
    </>
  )
}
export default ManufacturerCreate
