import { useMutation } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { getErrorMessage, parseQueryParams } from '../../../utilities/parsers'
import { getData, postFormData } from '../../../apis/api.helpers'
import apiUrl from '../../../apis/api.url'
import { useQuery } from '@tanstack/react-query'

export const createDistrictAdmin = (input: any) => {
  return postFormData(apiUrl.DISTRICT_ADMIN, input)
}
const buildUrlWithParams = (baseUrl: string, params: any) => {
  return `${baseUrl}${parseQueryParams(params)}`
}

export const useCreateDistrictAdmin = (
  handleSubmission: (data: any) => void
) => {
  const { enqueueSnackbar } = useSnackbar()
  return useMutation(createDistrictAdmin, {
    onSuccess: (res: any) => {
      handleSubmission(res.data)
    },

    onError: (error: any) => {
      enqueueSnackbar(
        getErrorMessage(
          error?.response?.data?.message || 'Something went wrong'
        ),
        {
          variant: 'error',
        }
      )
    },
  })
}

const fetchData = async () => {
  const url = buildUrlWithParams(apiUrl.DOCTOR, {})
  const response = await getData(url)
  // console.log(response)
  return response
}

export const useDoctors = () => {
  return useQuery(['Doctors_list'], () => fetchData(), {
    refetchOnWindowFocus: false,
    //staleTime: 50000,
  })
}
