// import { getNestedProperty, useCurrencyFormat } from '../../utilities/parsers'
// import { useNavigate } from "react-router-dom"

// import { useNavigate } from "react-router-dom"

// import { Router } from "react-router-dom"

// const defaultColumnProps = {
//   sortable: true,
//   resizable: true,
//   isVisible: true,
// }
const defaultColumnProps1 = {
  sortable: false,
  isVisible: true,
  resizable: true,
}

export const getColumns = ({}: any) => {
  const createRenderCell = (key: string, isCustom?: string) => (row: any) => {
    if (isCustom === 'name') {
      console.log(row)
      return {
        cell: (
          <>
            {row.pharmacy_medicine_name
              ? row.pharmacy_medicine_name
              : row?.out_side_medicine_name}
          </>
        ),
      }
    }
    if (isCustom === 'dose') {
      console.log(row)
      return {
        cell: (
          <>{row.dosage && row?.mode ? `${row.dosage} ,${row?.mode}` : '--'}</>
        ),
      }
    }
  }
  const column = [
    {
      title: 'Medicine Name',
      renderCell: createRenderCell('cre_agent.name', 'name'),
      customCell: true,
      // field: 'medicine_name',
      ...defaultColumnProps1,
    },
    {
      title: 'Quantity',
      field: 'quantity',
      ...defaultColumnProps1,
    },
    {
      title: 'Dose & Mode of Administration',
      customCell: true,
      renderCell: createRenderCell('cre_agent.name', 'dose'),
      // field: 'primary_stock',
      ...defaultColumnProps1,

      sortKey: '',
    },
    {
      title: 'Remarks',
      field: 'remarks',
      ...defaultColumnProps1,
    },
  ]

  return column
}
