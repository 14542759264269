// import FormBuilder from '../../../components/app/formBuilder/index'

import { useFormContext } from 'react-hook-form'
import FormBuilder from '../../../../components/app/formBuilder'
import { streamData } from '../../../contacts/store'
import { availabilityData, genderData } from '../store'
type Props = {
  edit?: boolean
}

const specialization = [
  {
    id: 'Medical Officer',
    name: 'Medical Officer',
  },
  {
    id: 'Specialist Medical Officer',
    name: 'Specialist Medical Officer',
  },
]
// const CommonForm = (data?: any) => {
const CommonForm = ({ edit }: Props) => {
  const textField = (
    name: string,
    label: string,
    id: string,
    type: string,
    // placeholder: string,
    required = false,
    config: { data?: Array<{ name: string; id: string }> } = {}
  ) => ({
    name,
    label,
    id,
    type,
    // placeholder,
    ...(required ? { required: true } : {}),
    ...config,
    spacing: 4,
  })
  const { watch } = useFormContext()
  const { dob, joining_date } = watch()
  // console.log(joining_date)

  const calculateMinDate = () => {
    if (!dob) return null // If dob is not provided, return null
    const minDate = new Date(dob)
    minDate.setFullYear(minDate.getFullYear() + 18) // Add 18 years to dob
    console.log(minDate)

    return minDate
  }

  const calculateMaxDate = () => {
    console.log(joining_date)
    if (!dob) {
      const maxDate = new Date()
      maxDate.setFullYear(maxDate.getFullYear() - 18)
      console.log(maxDate)
      return new Date(maxDate)
    }
    // if (!joining_date) return null // If joiningDate is not provided, return null
    // const maxDate = new Date(joining_date)
    // maxDate.setFullYear(maxDate.getFullYear() - 18) // Subtract 18 years from joiningDate
    // return maxDate
  }

  const formBuilderProps = [
    { ...textField('name', 'Name of Doctor', 'name', 'text', true) },
    {
      ...textField('gender', 'Gender', 'gender', 'custom_select', true, {
        data: genderData,
      }),
      placeholder: 'Select One',
    },
    {
      ...textField('dob', 'Date of Birth', 'dob', 'date', true),
      maxDate: calculateMaxDate(),
    },
    textField('phone_number', 'Phone Number', 'phone_number', 'number', true),
    textField('email', 'Email', 'email', 'email', true),

    {
      ...textField('stream', 'Stream', 'stream', 'custom_select', true, {
        data: streamData,
      }),
      placeholder: 'Select One',
    },
    {
      ...textField(
        'specialization',
        'Specialization',
        'specialization',
        'custom_select',
        true,
        { data: specialization }
      ),
      placeholder: 'Select One',
    },
    {
      ...textField(
        'availability',
        'Availability',
        'availability',
        'custom_select',
        true,
        {
          data: availabilityData,
        }
      ),
      placeholder: 'Select One',
    },
    {
      ...textField(
        'joining_date',
        'Joining Date',
        'joining_date',
        'date',
        true
      ),
      minDate: calculateMinDate(),
      disabled: !dob,
    },
    textField('address', 'Full Address', 'address', 'text', true),
  ]

  // methods.reset({})

  return <FormBuilder data={formBuilderProps} edit={edit} />
}
export default CommonForm
