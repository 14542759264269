// import { SCOPES } from './scopes.config'

export interface RouterMenuProps {
  id: number
  path?: string
  parent_id: null | number
  icon?: string
  label: string
  key: string
  slug?: string
  permission_slugs: string[]
  slugOptions?: string[]
  breadcrumb?: string[]
  isDetails?: boolean
  isExpanded?: boolean
  isSidebarMenu?: boolean
  hasChild?: boolean
  hasDivider?: boolean
  pathOverride?: string
}

// dashboard

const LOGIN: RouterMenuProps = {
  id: 0,
  path: '/login',
  parent_id: null,
  permission_slugs: [],
  label: 'Login',
  key: 'login',
}

// dashboard

const DASHBOARD: RouterMenuProps = {
  id: 1,
  path: '/dashboard',
  parent_id: null,
  icon: 'dash-icon',
  label: 'Dashboard',
  key: 'dashboard',
  permission_slugs: [
    'Super Admin',
    'Hospital Incharge',
    'Pharmacist',
    'Manufacturer/Supplier',
    'Op Incharge',
    'Doctor',
    'District Admin',
    'Pharmacy Under DMO',
  ],
  // breadcrumb: ["DASHBOARD"],
  isSidebarMenu: true,
}
const INSTITUTIONS: RouterMenuProps = {
  id: 4,
  path: '/institutions',
  parent_id: null,
  icon: 'institution',
  label: 'Institutions',
  key: 'institutions',
  permission_slugs: ['Super Admin'],
  isSidebarMenu: true,
  slugOptions: ['INSTITUTIONS', 'INSTITUTIONS_CREATE', 'INSTITUTIONS_VIEW'],
  hasChild: false,
}
const INSTITUTIONS_CREATE: RouterMenuProps = {
  id: 6,
  path: '/institutions-create',
  parent_id: null,
  icon: 'institution',
  label: 'Institutions',
  key: 'institutions',
  permission_slugs: ['Super Admin'],
  isSidebarMenu: false,
  slugOptions: ['INSTITUTIONS', 'INSTITUTIONS_CREATE', 'INSTITUTIONS_VIEW'],
  hasChild: false,
}
const INSTITUTIONS_VIEW: RouterMenuProps = {
  id: 7,
  path: '/institutions/:id',
  parent_id: null,
  icon: 'institution',
  label: 'Institutions',
  key: 'institutions',
  permission_slugs: ['Super Admin'],
  isSidebarMenu: false,
  slugOptions: ['INSTITUTIONS', 'INSTITUTIONS_CREATE', 'INSTITUTIONS_VIEW'],
  hasChild: false,
}
// const CONTACTS: RouterMenuProps = {
//   id: 5,
//   path: '/contacts',
//   parent_id: null,
//   icon: 'user',
//   label: 'Contacts',
//   key: 'contacts',
//   permission_slugs: ['Super Admin'],
//   isSidebarMenu: true,
//   slugOptions: [],
//   hasChild: false,
// }
const MANUFACTURER: RouterMenuProps = {
  id: 8,
  path: '/manufacturer',
  parent_id: null,
  icon: 'Manufacturer',
  label: 'Manufacturer',
  key: 'manufacturer',
  permission_slugs: ['Super Admin'],
  isSidebarMenu: true,
  slugOptions: ['MANUFACTURER', 'MANUFACTURER_CREATE', 'MANUFACTURER_VIEW'],
  hasChild: false,
}
const MANUFACTURER_CREATE: RouterMenuProps = {
  id: 9,
  path: '/manufacturer-create',
  parent_id: null,
  icon: 'Manufacturer',
  label: 'Manufacturer/Supplier',
  key: 'manufacturer',
  permission_slugs: ['Super Admin'],
  isSidebarMenu: false,
  slugOptions: ['MANUFACTURER', 'MANUFACTURER_CREATE', 'MANUFACTURER_VIEW'],
  hasChild: false,
}
const MANUFACTURER_VIEW: RouterMenuProps = {
  id: 10,
  path: '/manufacturer/:id',
  parent_id: null,
  icon: 'Manufacturer',
  label: 'Manufacturer/Supplier',
  key: 'manufacturer',
  permission_slugs: ['Super Admin'],
  isSidebarMenu: false,
  slugOptions: ['MANUFACTURER', 'MANUFACTURER_CREATE', 'MANUFACTURER_VIEW'],
  hasChild: false,
}
const PHARMACIST: RouterMenuProps = {
  id: 11,
  path: '/pharmacist',
  parent_id: null,
  icon: 'pharmacist',
  label: 'Pharmacist',
  key: 'pharmacist',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: true,
  slugOptions: ['PHARMACIST', 'PHARMACIST_VIEW'],
  hasChild: false,
}
const PHARMACIST_VIEW: RouterMenuProps = {
  id: 12,
  path: '/pharmacist/:id',
  parent_id: null,
  icon: 'pharmacist',
  label: 'Details',
  key: 'pharmacist',
  permission_slugs: ['Hospital Incharge'],
  breadcrumb: ['PHARMACIST', 'PHARMACIST_VIEW'],
  isSidebarMenu: false,
  slugOptions: ['PHARMACIST', 'PHARMACIST_VIEW'],
  hasChild: false,
}
const STOCK: RouterMenuProps = {
  id: 13,
  path: '/stock',
  parent_id: null,
  icon: 'stock',
  label: 'Stock',
  pathOverride: '/stock/primary',
  key: 'Stock',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: true,
  slugOptions: ['STOCK', 'STOCK_PRIMARY', 'STOCK_SECONDARY'],
  hasChild: false,
}
const STOCK_PRIMARY: RouterMenuProps = {
  id: 131,
  path: 'primary',
  parent_id: 13,
  icon: 'stock',
  label: 'Primary Stock',
  key: 'Primary Stock',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: false,
  slugOptions: ['STOCK', 'STOCK_PRIMARY', 'STOCK_SECONDARY'],
  hasChild: false,
}
const STOCK_SECONDARY: RouterMenuProps = {
  id: 132,
  path: 'secondary',
  parent_id: 13,
  icon: 'stock',
  label: 'Secondary Stock',
  key: 'Secondary Stock',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: false,
  slugOptions: ['STOCK', 'STOCK_PRIMARY', 'STOCK_SECONDARY'],
  hasChild: false,
}

const FUND_MAPPING: RouterMenuProps = {
  id: 14,
  path: '/fund',
  parent_id: null,
  icon: 'fund',
  label: 'Fund Mapping',
  key: 'fund',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: true,
  slugOptions: ['FUND_MAPPING'],
  hasChild: false,
}
const ADMIN_FUND_MAPPING: RouterMenuProps = {
  id: 14,
  path: '/fund-mapping',
  parent_id: null,
  icon: 'fund',
  label: 'Fund Mapping',
  key: 'fund',
  permission_slugs: ['Super Admin'],
  isSidebarMenu: true,
  slugOptions: ['ADMIN_FUND_MAPPING', 'ADMIN_FUND_MAPPING_PREVIOUS'],
  hasChild: false,
}
const ADMIN_FUND_MAPPING_PREVIOUS: RouterMenuProps = {
  id: 14,
  path: '/previous/distribution',
  parent_id: null,
  icon: 'fund',
  label: 'Fund Mapping',
  key: 'fund',
  permission_slugs: ['Super Admin'],
  isSidebarMenu: false,
  slugOptions: ['ADMIN_FUND_MAPPING', 'ADMIN_FUND_MAPPING_PREVIOUS'],
  hasChild: false,
}
const MEDICINE: RouterMenuProps = {
  id: 15,
  path: '/medicine',
  parent_id: null,
  icon: 'medicine',
  label: 'Medicine',
  pathOverride: '/medicine/primary',
  key: 'Stock',
  permission_slugs: ['Pharmacist', 'Doctor'],
  isSidebarMenu: true,
  slugOptions: ['MEDICINE', 'MEDICINE_PRIMARY', 'MEDICINE_SECONDARY'],
  hasChild: false,
}
const MEDICINE_PRIMARY: RouterMenuProps = {
  id: 151,
  path: 'primary',
  parent_id: 13,
  icon: 'medicine',
  label: 'Primary Stock',
  key: 'Primary Stock',
  permission_slugs: ['Pharmacist', 'Doctor'],
  isSidebarMenu: false,
  slugOptions: ['MEDICINE', 'MEDICINE_PRIMARY', 'MEDICINE_SECONDARY'],
  hasChild: false,
}
const MEDICINE_SECONDARY: RouterMenuProps = {
  id: 152,
  path: 'secondary',
  parent_id: 13,
  icon: 'medicine',
  label: 'Secondary Stock',
  key: 'Secondary Stock',
  permission_slugs: ['Pharmacist', 'Doctor'],
  isSidebarMenu: false,
  slugOptions: ['MEDICINE', 'MEDICINE_PRIMARY', 'MEDICINE_SECONDARY'],
  hasChild: false,
}
const MANUFACTURER_STOCK: RouterMenuProps = {
  id: 16,
  path: '/manufacturer/stock',
  parent_id: null,
  icon: 'stock',
  label: 'Stock',
  key: 'Stock',
  permission_slugs: ['Manufacturer/Supplier', 'Pharmacy Under DMO'],
  isSidebarMenu: true,
  slugOptions: ['MANUFACTURER_STOCK'],
  hasChild: false,
}
const MANUFACTURER_ORDER_REQUEST: RouterMenuProps = {
  id: 17,
  path: '/order',
  parent_id: null,
  icon: 'order',
  label: 'Order Request',
  key: 'order_request',
  pathOverride: '/order/pending',
  permission_slugs: ['Manufacturer/Supplier', 'Pharmacy Under DMO'],
  isSidebarMenu: true,
  slugOptions: [
    'MANUFACTURER_ORDER_REQUEST',
    'PENDING_ORDER',
    'CONFIRMED_ORDER',
    'DELIVERED_ORDER',
    'REVERTED_ORDER',
    'REJECTED_ORDER',
    'REPLACED_ORDER',
    'MANUFACTURER_ORDER_REQUEST_DETAIL',
    'MANUFACTURER_ORDER_REQUEST_DETAIL_REPLACED',
  ],
  hasChild: false,
}
const PENDING_ORDER: RouterMenuProps = {
  id: 171,
  path: 'pending',
  parent_id: 17,
  icon: 'stock',
  label: 'Order Request',
  key: 'order_request',
  permission_slugs: ['Manufacturer/Supplier', 'Pharmacy Under DMO'],
  isSidebarMenu: false,
  slugOptions: [
    'MANUFACTURER_ORDER_REQUEST',
    'PENDING_ORDER',
    'CONFIRMED_ORDER',
    'DELIVERED_ORDER',
    'REVERTED_ORDER',
    'REJECTED_ORDER',
    'REPLACED_ORDER',
    'MANUFACTURER_ORDER_REQUEST_DETAIL',
  ],
  hasChild: false,
}
const CONFIRMED_ORDER: RouterMenuProps = {
  id: 172,
  path: 'confirmed',
  parent_id: 17,
  icon: 'stock',
  label: 'Order Request',
  key: 'order_request',
  permission_slugs: ['Manufacturer/Supplier', 'Pharmacy Under DMO'],
  isSidebarMenu: false,
  slugOptions: [
    'MANUFACTURER_ORDER_REQUEST',
    'PENDING_ORDER',
    'CONFIRMED_ORDER',
    'DELIVERED_ORDER',
    'REVERTED_ORDER',
    'REJECTED_ORDER',
    'REPLACED_ORDER',
    'MANUFACTURER_ORDER_REQUEST_DETAIL',
  ],
  hasChild: false,
}
const DELIVERED_ORDER: RouterMenuProps = {
  id: 173,
  path: 'delivered',
  parent_id: 17,
  icon: 'stock',
  label: 'Order Request',
  key: 'order_request',
  permission_slugs: ['Manufacturer/Supplier', 'Pharmacy Under DMO'],
  isSidebarMenu: false,
  slugOptions: [
    'MANUFACTURER_ORDER_REQUEST',
    'PENDING_ORDER',
    'CONFIRMED_ORDER',
    'DELIVERED_ORDER',
    'REVERTED_ORDER',
    'REJECTED_ORDER',
    'REPLACED_ORDER',
    'MANUFACTURER_ORDER_REQUEST_DETAIL',
  ],
  hasChild: false,
}
const REVERTED_ORDER: RouterMenuProps = {
  id: 174,
  path: 'reverted',
  parent_id: 17,
  icon: 'stock',
  label: 'Order Request',
  key: 'order_request',
  permission_slugs: ['Manufacturer/Supplier', 'Pharmacy Under DMO'],
  isSidebarMenu: false,
  slugOptions: [
    'MANUFACTURER_ORDER_REQUEST',
    'PENDING_ORDER',
    'CONFIRMED_ORDER',
    'DELIVERED_ORDER',
    'REVERTED_ORDER',
    'REJECTED_ORDER',
    'REPLACED_ORDER',
    'MANUFACTURER_ORDER_REQUEST_DETAIL',
  ],
  hasChild: false,
}
const REJECTED_ORDER: RouterMenuProps = {
  id: 175,
  path: 'rejected',
  parent_id: 17,
  icon: 'stock',
  label: 'Order Request',
  key: 'order_request',
  permission_slugs: ['Manufacturer/Supplier', 'Pharmacy Under DMO'],
  isSidebarMenu: false,
  slugOptions: [
    'MANUFACTURER_ORDER_REQUEST',
    'PENDING_ORDER',
    'CONFIRMED_ORDER',
    'DELIVERED_ORDER',
    'REVERTED_ORDER',
    'REJECTED_ORDER',
    'REPLACED_ORDER',
    'MANUFACTURER_ORDER_REQUEST_DETAIL',
  ],
  hasChild: false,
}
const REPLACED_ORDER: RouterMenuProps = {
  id: 175,
  path: 'replaced',
  parent_id: 17,
  icon: 'stock',
  label: 'Order Request',
  key: 'order_request',
  permission_slugs: ['Manufacturer/Supplier', 'Pharmacy Under DMO'],
  isSidebarMenu: false,
  slugOptions: [
    'MANUFACTURER_ORDER_REQUEST',
    'PENDING_ORDER',
    'CONFIRMED_ORDER',
    'DELIVERED_ORDER',
    'REVERTED_ORDER',
    'REJECTED_ORDER',
    'REPLACED_ORDER',
    'MANUFACTURER_ORDER_REQUEST_DETAIL',
  ],
  hasChild: false,
}
const MANUFACTURER_ORDER_REQUEST_DETAIL: RouterMenuProps = {
  id: 175,
  path: '/order/:id',
  parent_id: 17,
  icon: 'order',
  label: 'View List',
  key: 'order_request',
  // breadcrumb: [
  //   'MANUFACTURER_ORDER_REQUEST',
  //   'MANUFACTURER_ORDER_REQUEST_DETAIL',
  // ],
  permission_slugs: ['Manufacturer/Supplier', 'Pharmacy Under DMO'],
  isSidebarMenu: false,
  slugOptions: [
    'MANUFACTURER_ORDER_REQUEST',
    'PENDING_ORDER',
    'CONFIRMED_ORDER',
    'DELIVERED_ORDER',
    'REVERTED_ORDER',
    'REJECTED_ORDER',
    'MANUFACTURER_ORDER_REQUEST_DETAIL',
  ],
  hasChild: false,
}

const MANUFACTURER_ORDER_REQUEST_DETAIL_REPLACED: RouterMenuProps = {
  id: 175,
  path: '/order/replaced/:id',
  parent_id: 17,
  icon: 'order',
  label: 'View List',
  key: 'order_request',
  // breadcrumb: [
  //   'MANUFACTURER_ORDER_REQUEST',
  //   'MANUFACTURER_ORDER_REQUEST_DETAIL',
  // ],
  permission_slugs: ['Manufacturer/Supplier', 'Pharmacy Under DMO'],
  isSidebarMenu: false,
  slugOptions: [
    'MANUFACTURER_ORDER_REQUEST',
    'PENDING_ORDER',
    'CONFIRMED_ORDER',
    'DELIVERED_ORDER',
    'REVERTED_ORDER',
    'REJECTED_ORDER',
    'MANUFACTURER_ORDER_REQUEST_DETAIL',
    'MANUFACTURER_ORDER_REQUEST_DETAIL_REPLACED',
  ],
  hasChild: false,
}
const HOSPITAL_ORDERS_MAIN: RouterMenuProps = {
  id: 18,
  path: '/hospital',
  parent_id: null,
  icon: 'myorder',
  label: 'My Orders',
  key: 'order_request',
  pathOverride: '/hospital/myorders',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: true,
  slugOptions: [
    'HOSPITAL_ORDERS_DMO',
    'HOSPITAL_PENDING_ORDER_DMO',
    'HOSPITAL_CONFIRMED_ORDER_DMO',
    'HOSPITAL_DELIVERED_ORDER_DMO',
    'HOSPITAL_REVERTED_ORDER_DMO',
    'HOSPITAL_REJECTED_ORDER_DMO',
    'HOSPITAL_PENDING_ORDER_DETAIL_DMO',
    'HOSPITAL_CONFIRMED_ORDER_DETAIL_DMO',
    'HOSPITAL_DELIVERED_ORDER_DETAIL_DMO',
    'HOSPITAL_REVERTED_ORDER_DETAIL_DMO',
    'HOSPITAL_REJECTED_ORDER_DETAIL_DMO',
    'HOSPITAL_ORDER_REQUEST_DETAIL_DMO',
    'HOSPITAL_REPLACED_ORDER',
    'HOSPITAL_REPLACED_ORDER_DETAIL',
  ],
  hasChild: true,
}
const HOSPITAL_ORDERS: RouterMenuProps = {
  id: 19,
  path: '/medicine',
  parent_id: 18,
  icon: 'null',
  label: 'Medicine',
  key: 'order_request',
  pathOverride: '/medicine/pending',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: true,
  slugOptions: [
    'HOSPITAL_ORDERS',
    'HOSPITAL_PENDING_ORDER',
    'HOSPITAL_CONFIRMED_ORDER',
    'HOSPITAL_DELIVERED_ORDER',
    'HOSPITAL_REVERTED_ORDER',
    'HOSPITAL_REJECTED_ORDER',
    'HOSPITAL_PENDING_ORDER_DETAIL',
    'HOSPITAL_CONFIRMED_ORDER_DETAIL',
    'HOSPITAL_DELIVERED_ORDER_DETAIL',
    'HOSPITAL_REVERTED_ORDER_DETAIL',
    'HOSPITAL_REJECTED_ORDER_DETAIL',
    'HOSPITAL_ORDER_REQUEST_DETAIL',
    'HOSPITAL_REPLACED_ORDER',
    'HOSPITAL_REPLACED_ORDER_DETAIL',
  ],
  hasChild: false,
}

const HOSPITAL_PENDING_ORDER: RouterMenuProps = {
  id: 181,
  path: 'pending',
  parent_id: 17,
  icon: 'stock',
  label: 'Order Request',
  key: 'order_request',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: false,
  slugOptions: [
    'HOSPITAL_ORDERS',
    'HOSPITAL_PENDING_ORDER',
    'HOSPITAL_CONFIRMED_ORDER',
    'HOSPITAL_DELIVERED_ORDER',
    'HOSPITAL_REVERTED_ORDER',
    'HOSPITAL_REJECTED_ORDER',
    'HOSPITAL_PENDING_ORDER_DETAIL',
    'HOSPITAL_CONFIRMED_ORDER_DETAIL',
    'HOSPITAL_DELIVERED_ORDER_DETAIL',
    'HOSPITAL_REVERTED_ORDER_DETAIL',
    'HOSPITAL_REJECTED_ORDER_DETAIL',
    'HOSPITAL_ORDER_REQUEST_DETAIL',
  ],
  hasChild: false,
}
const HOSPITAL_PENDING_ORDER_DETAIL: RouterMenuProps = {
  id: 181,
  path: '/medicine/pending/:id',
  parent_id: 18,
  icon: 'stock',
  label: 'Pending Order',
  breadcrumb: ['HOSPITAL_ORDERS', 'HOSPITAL_PENDING_ORDER_DETAIL'],
  key: 'order_request',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: false,
  slugOptions: [
    'HOSPITAL_ORDERS',
    'HOSPITAL_PENDING_ORDER',
    'HOSPITAL_CONFIRMED_ORDER',
    'HOSPITAL_DELIVERED_ORDER',
    'HOSPITAL_REVERTED_ORDER',
    'HOSPITAL_REJECTED_ORDER',
    'HOSPITAL_PENDING_ORDER_DETAIL',
    'HOSPITAL_CONFIRMED_ORDER_DETAIL',
    'HOSPITAL_DELIVERED_ORDER_DETAIL',
    'HOSPITAL_REVERTED_ORDER_DETAIL',
    'HOSPITAL_REJECTED_ORDER_DETAIL',
    'HOSPITAL_ORDER_REQUEST_DETAIL',
  ],
  hasChild: false,
}
const HOSPITAL_CONFIRMED_ORDER: RouterMenuProps = {
  id: 182,
  path: 'confirmed',
  parent_id: 17,
  icon: 'stock',
  label: 'Order Request',
  key: 'order_request',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: false,
  slugOptions: [
    'HOSPITAL_ORDERS',
    'HOSPITAL_PENDING_ORDER',
    'HOSPITAL_CONFIRMED_ORDER',
    'HOSPITAL_DELIVERED_ORDER',
    'HOSPITAL_REVERTED_ORDER',
    'HOSPITAL_REJECTED_ORDER',
    'HOSPITAL_PENDING_ORDER_DETAIL',
    'HOSPITAL_CONFIRMED_ORDER_DETAIL',
    'HOSPITAL_DELIVERED_ORDER_DETAIL',
    'HOSPITAL_REVERTED_ORDER_DETAIL',
    'HOSPITAL_REJECTED_ORDER_DETAIL',
    'HOSPITAL_ORDER_REQUEST_DETAIL',
  ],
  hasChild: false,
}
const HOSPITAL_REPLACED_ORDER: RouterMenuProps = {
  id: 182,
  path: 'replaced',
  parent_id: 17,
  icon: 'stock',
  label: 'Order Request',
  key: 'order_request',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: false,
  slugOptions: [
    'HOSPITAL_ORDERS',
    'HOSPITAL_PENDING_ORDER',
    'HOSPITAL_CONFIRMED_ORDER',
    'HOSPITAL_DELIVERED_ORDER',
    'HOSPITAL_REVERTED_ORDER',
    'HOSPITAL_REJECTED_ORDER',
    'HOSPITAL_PENDING_ORDER_DETAIL',
    'HOSPITAL_CONFIRMED_ORDER_DETAIL',
    'HOSPITAL_DELIVERED_ORDER_DETAIL',
    'HOSPITAL_REVERTED_ORDER_DETAIL',
    'HOSPITAL_REJECTED_ORDER_DETAIL',
    'HOSPITAL_ORDER_REQUEST_DETAIL',
    'HOSPITAL_REPLACED_ORDER',
  ],
  hasChild: false,
}
const HOSPITAL_DELIVERED_ORDER: RouterMenuProps = {
  id: 183,
  path: 'delivered',
  parent_id: 17,
  icon: 'stock',
  label: 'Order Request',
  key: 'order_request',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: false,
  slugOptions: [
    'HOSPITAL_ORDERS',
    'HOSPITAL_PENDING_ORDER',
    'HOSPITAL_CONFIRMED_ORDER',
    'HOSPITAL_DELIVERED_ORDER',
    'HOSPITAL_REVERTED_ORDER',
    'HOSPITAL_REJECTED_ORDER',
    'HOSPITAL_PENDING_ORDER_DETAIL',
    'HOSPITAL_CONFIRMED_ORDER_DETAIL',
    'HOSPITAL_DELIVERED_ORDER_DETAIL',
    'HOSPITAL_REVERTED_ORDER_DETAIL',
    'HOSPITAL_REJECTED_ORDER_DETAIL',
    'HOSPITAL_ORDER_REQUEST_DETAIL',
  ],
  hasChild: false,
}
const HOSPITAL_REVERTED_ORDER: RouterMenuProps = {
  id: 184,
  path: 'reverted',
  parent_id: 17,
  icon: 'stock',
  label: 'Order Request',
  key: 'order_request',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: false,
  slugOptions: [
    'HOSPITAL_ORDERS',
    'HOSPITAL_PENDING_ORDER',
    'HOSPITAL_CONFIRMED_ORDER',
    'HOSPITAL_DELIVERED_ORDER',
    'HOSPITAL_REVERTED_ORDER',
    'HOSPITAL_REJECTED_ORDER',
    'HOSPITAL_PENDING_ORDER_DETAIL',
    'HOSPITAL_CONFIRMED_ORDER_DETAIL',
    'HOSPITAL_DELIVERED_ORDER_DETAIL',
    'HOSPITAL_REVERTED_ORDER_DETAIL',
    'HOSPITAL_REJECTED_ORDER_DETAIL',
    'HOSPITAL_ORDER_REQUEST_DETAIL',
  ],
  hasChild: false,
}
const HOSPITAL_REJECTED_ORDER: RouterMenuProps = {
  id: 185,
  path: 'rejected',
  parent_id: 17,
  icon: 'stock',
  label: 'Order Request',
  key: 'order_request',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: false,
  slugOptions: [
    'HOSPITAL_ORDERS',
    'HOSPITAL_PENDING_ORDER',
    'HOSPITAL_CONFIRMED_ORDER',
    'HOSPITAL_DELIVERED_ORDER',
    'HOSPITAL_REVERTED_ORDER',
    'HOSPITAL_REJECTED_ORDER',
    'HOSPITAL_PENDING_ORDER_DETAIL',
    'HOSPITAL_CONFIRMED_ORDER_DETAIL',
    'HOSPITAL_DELIVERED_ORDER_DETAIL',
    'HOSPITAL_REVERTED_ORDER_DETAIL',
    'HOSPITAL_REJECTED_ORDER_DETAIL',
    'HOSPITAL_ORDER_REQUEST_DETAIL',
  ],
  hasChild: false,
}
const HOSPITAL_CONFIRMED_ORDER_DETAIL: RouterMenuProps = {
  id: 1891,
  path: '/medicine/confirmed/:id',
  parent_id: 18,
  icon: 'stock',
  label: 'Confirmed Orders',
  key: 'order_request',
  breadcrumb: ['HOSPITAL_ORDERS', 'HOSPITAL_CONFIRMED_ORDER_DETAIL'],
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: false,
  slugOptions: [
    'HOSPITAL_ORDERS',
    'HOSPITAL_PENDING_ORDER',
    'HOSPITAL_CONFIRMED_ORDER',
    'HOSPITAL_DELIVERED_ORDER',
    'HOSPITAL_REVERTED_ORDER',
    'HOSPITAL_REJECTED_ORDER',
    'HOSPITAL_PENDING_ORDER_DETAIL',
    'HOSPITAL_CONFIRMED_ORDER_DETAIL',
    'HOSPITAL_DELIVERED_ORDER_DETAIL',
    'HOSPITAL_REVERTED_ORDER_DETAIL',
    'HOSPITAL_REJECTED_ORDER_DETAIL',
    'HOSPITAL_ORDER_REQUEST_DETAIL',
  ],
  hasChild: false,
}
const HOSPITAL_REPLACED_ORDER_DETAIL: RouterMenuProps = {
  id: 1891,
  path: '/medicine/replaced/:id',
  parent_id: 18,
  icon: 'stock',
  label: 'Replaced Orders',
  key: 'order_request',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: false,
  slugOptions: [
    'HOSPITAL_ORDERS',
    'HOSPITAL_PENDING_ORDER',
    'HOSPITAL_CONFIRMED_ORDER',
    'HOSPITAL_DELIVERED_ORDER',
    'HOSPITAL_REVERTED_ORDER',
    'HOSPITAL_REJECTED_ORDER',
    'HOSPITAL_PENDING_ORDER_DETAIL',
    'HOSPITAL_CONFIRMED_ORDER_DETAIL',
    'HOSPITAL_DELIVERED_ORDER_DETAIL',
    'HOSPITAL_REVERTED_ORDER_DETAIL',
    'HOSPITAL_REJECTED_ORDER_DETAIL',
    'HOSPITAL_ORDER_REQUEST_DETAIL',
    'HOSPITAL_REPLACED_ORDER_DETAIL',
  ],
  hasChild: false,
}
const HOSPITAL_DELIVERED_ORDER_DETAIL: RouterMenuProps = {
  id: 189,
  path: '/medicine/delivered/:id',
  parent_id: 18,
  icon: 'stock',
  label: 'Delivered Orders',
  breadcrumb: ['HOSPITAL_ORDERS', 'HOSPITAL_DELIVERED_ORDER_DETAIL'],
  key: 'order_request',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: false,
  slugOptions: [
    'HOSPITAL_ORDERS',
    'HOSPITAL_PENDING_ORDER',
    'HOSPITAL_CONFIRMED_ORDER',
    'HOSPITAL_DELIVERED_ORDER',
    'HOSPITAL_REVERTED_ORDER',
    'HOSPITAL_REJECTED_ORDER',
    'HOSPITAL_PENDING_ORDER_DETAIL',
    'HOSPITAL_CONFIRMED_ORDER_DETAIL',
    'HOSPITAL_DELIVERED_ORDER_DETAIL',
    'HOSPITAL_REVERTED_ORDER_DETAIL',
    'HOSPITAL_REJECTED_ORDER_DETAIL',
    'HOSPITAL_ORDER_REQUEST_DETAIL',
  ],
  hasChild: false,
}
const HOSPITAL_REVERTED_ORDER_DETAIL: RouterMenuProps = {
  id: 188,
  path: '/medicine/reverted/:id',
  parent_id: 17,
  icon: 'stock',
  label: 'Reverted Orders',
  key: 'order_request',
  breadcrumb: ['HOSPITAL_ORDERS', 'HOSPITAL_REVERTED_ORDER_DETAIL'],
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: false,
  slugOptions: [
    'HOSPITAL_ORDERS',
    'HOSPITAL_PENDING_ORDER',
    'HOSPITAL_CONFIRMED_ORDER',
    'HOSPITAL_DELIVERED_ORDER',
    'HOSPITAL_REVERTED_ORDER',
    'HOSPITAL_REJECTED_ORDER',
    'HOSPITAL_PENDING_ORDER_DETAIL',
    'HOSPITAL_CONFIRMED_ORDER_DETAIL',
    'HOSPITAL_DELIVERED_ORDER_DETAIL',
    'HOSPITAL_REVERTED_ORDER_DETAIL',
    'HOSPITAL_REJECTED_ORDER_DETAIL',
    'HOSPITAL_ORDER_REQUEST_DETAIL',
  ],
  hasChild: false,
}
const HOSPITAL_REJECTED_ORDER_DETAIL: RouterMenuProps = {
  id: 187,
  path: '/medicine/rejected/:id',
  parent_id: 18,
  icon: 'stock',
  label: 'Rejected Orders',
  key: 'rejected_order',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: false,
  breadcrumb: ['HOSPITAL_ORDERS', 'HOSPITAL_REJECTED_ORDER_DETAIL'],
  slugOptions: [
    'HOSPITAL_ORDERS',
    'HOSPITAL_PENDING_ORDER',
    'HOSPITAL_CONFIRMED_ORDER',
    'HOSPITAL_DELIVERED_ORDER',
    'HOSPITAL_REVERTED_ORDER',
    'HOSPITAL_REJECTED_ORDER',
    'HOSPITAL_PENDING_ORDER_DETAIL',
    'HOSPITAL_CONFIRMED_ORDER_DETAIL',
    'HOSPITAL_DELIVERED_ORDER_DETAIL',
    'HOSPITAL_REVERTED_ORDER_DETAIL',
    'HOSPITAL_REJECTED_ORDER_DETAIL',
    'HOSPITAL_ORDER_REQUEST_DETAIL',
  ],
  hasChild: false,
}
const HOSPITAL_ORDER_REQUEST_DETAIL: RouterMenuProps = {
  id: 186,
  // path: '/myorders/:id',
  parent_id: 18,
  icon: 'order',
  label: 'View List',
  key: 'order_request',
  breadcrumb: ['HOSPITAL_ORDERS', 'HOSPITAL_ORDER_REQUEST_DETAIL'],
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: false,
  slugOptions: [
    'HOSPITAL_ORDERS',
    'HOSPITAL_PENDING_ORDER',
    'HOSPITAL_CONFIRMED_ORDER',
    'HOSPITAL_DELIVERED_ORDER',
    'HOSPITAL_REVERTED_ORDER',
    'HOSPITAL_REJECTED_ORDER',
    'HOSPITAL_PENDING_ORDER_DETAIL',
    'HOSPITAL_CONFIRMED_ORDER_DETAIL',
    'HOSPITAL_DELIVERED_ORDER_DETAIL',
    'HOSPITAL_REVERTED_ORDER_DETAIL',
    'HOSPITAL_REJECTED_ORDER_DETAIL',
    'HOSPITAL_ORDER_REQUEST_DETAIL',
  ],
  hasChild: false,
}
const HOSPITAL_ORDERS_DMO: RouterMenuProps = {
  id: 19,
  path: '/medicine-dms',
  parent_id: 18,
  icon: 'null',
  label: 'Medicine From DMS',
  key: 'order_request',
  pathOverride: '/medicine-dms/pending',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: true,
  slugOptions: [
    'HOSPITAL_ORDERS_DMO',
    'HOSPITAL_PENDING_ORDER_DMO',
    'HOSPITAL_CONFIRMED_ORDER_DMO',
    'HOSPITAL_DELIVERED_ORDER_DMO',
    'HOSPITAL_REVERTED_ORDER_DMO',
    'HOSPITAL_REJECTED_ORDER_DMO',
    'HOSPITAL_PENDING_ORDER_DETAIL_DMO',
    'HOSPITAL_CONFIRMED_ORDER_DETAIL_DMO',
    'HOSPITAL_DELIVERED_ORDER_DETAIL_DMO',
    'HOSPITAL_REVERTED_ORDER_DETAIL_DMO',
    'HOSPITAL_REJECTED_ORDER_DETAIL_DMO',
    'HOSPITAL_ORDER_REQUEST_DETAIL_DMO',
    'HOSPITAL_REPLACED_ORDER_DMO',
    'HOSPITAL_REPLACED_ORDER_DETAIL_DMO',
  ],
  hasChild: false,
}
const HOSPITAL_PENDING_ORDER_DMO: RouterMenuProps = {
  id: 181,
  path: 'pending',
  parent_id: 17,
  icon: 'stock',
  label: 'Order Request',
  key: 'order_request',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: false,
  slugOptions: [
    'HOSPITAL_ORDERS_DMO',
    'HOSPITAL_PENDING_ORDER_DMO',
    'HOSPITAL_CONFIRMED_ORDER_DMO',
    'HOSPITAL_DELIVERED_ORDER_DMO',
    'HOSPITAL_REVERTED_ORDER_DMO',
    'HOSPITAL_REJECTED_ORDER_DMO',
    'HOSPITAL_PENDING_ORDER_DETAIL_DMO',
    'HOSPITAL_CONFIRMED_ORDER_DETAIL_DMO',
    'HOSPITAL_DELIVERED_ORDER_DETAIL_DMO',
    'HOSPITAL_REVERTED_ORDER_DETAIL_DMO',
    'HOSPITAL_REJECTED_ORDER_DETAIL_DMO',
    'HOSPITAL_ORDER_REQUEST_DETAIL_DMO',
  ],
  hasChild: false,
}
const HOSPITAL_PENDING_ORDER_DETAIL_DMO: RouterMenuProps = {
  id: 181,
  path: '/medicine-dms/pending/:id',
  parent_id: 18,
  icon: 'stock',
  label: 'Pending Order',
  breadcrumb: ['HOSPITAL_ORDERS', 'HOSPITAL_PENDING_ORDER_DETAIL'],
  key: 'order_request',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: false,
  slugOptions: [
    'HOSPITAL_ORDERS_DMO',
    'HOSPITAL_PENDING_ORDER_DMO',
    'HOSPITAL_CONFIRMED_ORDER_DMO',
    'HOSPITAL_DELIVERED_ORDER_DMO',
    'HOSPITAL_REVERTED_ORDER_DMO',
    'HOSPITAL_REJECTED_ORDER_DMO',
    'HOSPITAL_PENDING_ORDER_DETAIL_DMO',
    'HOSPITAL_CONFIRMED_ORDER_DETAIL_DMO',
    'HOSPITAL_DELIVERED_ORDER_DETAIL_DMO',
    'HOSPITAL_REVERTED_ORDER_DETAIL_DMO',
    'HOSPITAL_REJECTED_ORDER_DETAIL_DMO',
    'HOSPITAL_ORDER_REQUEST_DETAIL_DMO',
  ],
  hasChild: false,
}
const HOSPITAL_CONFIRMED_ORDER_DMO: RouterMenuProps = {
  id: 182,
  path: 'confirmed',
  parent_id: 17,
  icon: 'stock',
  label: 'Order Request',
  key: 'order_request',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: false,
  slugOptions: [
    'HOSPITAL_ORDERS_DMO',
    'HOSPITAL_PENDING_ORDER_DMO',
    'HOSPITAL_CONFIRMED_ORDER_DMO',
    'HOSPITAL_DELIVERED_ORDER_DMO',
    'HOSPITAL_REVERTED_ORDER_DMO',
    'HOSPITAL_REJECTED_ORDER_DMO',
    'HOSPITAL_PENDING_ORDER_DETAIL_DMO',
    'HOSPITAL_CONFIRMED_ORDER_DETAIL_DMO',
    'HOSPITAL_DELIVERED_ORDER_DETAIL_DMO',
    'HOSPITAL_REVERTED_ORDER_DETAIL_DMO',
    'HOSPITAL_REJECTED_ORDER_DETAIL_DMO',
    'HOSPITAL_ORDER_REQUEST_DETAIL_DMO',
  ],
  hasChild: false,
}
const HOSPITAL_REPLACED_ORDER_DMO: RouterMenuProps = {
  id: 182,
  path: 'replaced',
  parent_id: 17,
  icon: 'stock',
  label: 'Order Request',
  key: 'order_request',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: false,
  slugOptions: [
    'HOSPITAL_ORDERS_DMO',
    'HOSPITAL_PENDING_ORDER_DMO',
    'HOSPITAL_CONFIRMED_ORDER_DMO',
    'HOSPITAL_DELIVERED_ORDER_DMO',
    'HOSPITAL_REVERTED_ORDER_DMO',
    'HOSPITAL_REJECTED_ORDER_DMO',
    'HOSPITAL_PENDING_ORDER_DETAIL_DMO',
    'HOSPITAL_CONFIRMED_ORDER_DETAIL_DMO',
    'HOSPITAL_DELIVERED_ORDER_DETAIL_DMO',
    'HOSPITAL_REVERTED_ORDER_DETAIL_DMO',
    'HOSPITAL_REJECTED_ORDER_DETAIL_DMO',
    'HOSPITAL_ORDER_REQUEST_DETAIL_DMO',
    'HOSPITAL_REPLACED_ORDER_DMO',
    'HOSPITAL_REPLACED_ORDER_DETAIL_DMO',
  ],
  hasChild: false,
}
const HOSPITAL_DELIVERED_ORDER_DMO: RouterMenuProps = {
  id: 183,
  path: 'delivered',
  parent_id: 17,
  icon: 'stock',
  label: 'Order Request',
  key: 'order_request',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: false,
  slugOptions: [
    'HOSPITAL_ORDERS_DMO',
    'HOSPITAL_PENDING_ORDER_DMO',
    'HOSPITAL_CONFIRMED_ORDER_DMO',
    'HOSPITAL_DELIVERED_ORDER_DMO',
    'HOSPITAL_REVERTED_ORDER_DMO',
    'HOSPITAL_REJECTED_ORDER_DMO',
    'HOSPITAL_PENDING_ORDER_DETAIL_DMO',
    'HOSPITAL_CONFIRMED_ORDER_DETAIL_DMO',
    'HOSPITAL_DELIVERED_ORDER_DETAIL_DMO',
    'HOSPITAL_REVERTED_ORDER_DETAIL_DMO',
    'HOSPITAL_REJECTED_ORDER_DETAIL_DMO',
    'HOSPITAL_ORDER_REQUEST_DETAIL_DMO',
  ],
  hasChild: false,
}
const HOSPITAL_REVERTED_ORDER_DMO: RouterMenuProps = {
  id: 184,
  path: 'reverted',
  parent_id: 17,
  icon: 'stock',
  label: 'Order Request',
  key: 'order_request',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: false,
  slugOptions: [
    'HOSPITAL_ORDERS_DMO',
    'HOSPITAL_PENDING_ORDER_DMO',
    'HOSPITAL_CONFIRMED_ORDER_DMO',
    'HOSPITAL_DELIVERED_ORDER_DMO',
    'HOSPITAL_REVERTED_ORDER_DMO',
    'HOSPITAL_REJECTED_ORDER_DMO',
    'HOSPITAL_PENDING_ORDER_DETAIL_DMO',
    'HOSPITAL_CONFIRMED_ORDER_DETAIL_DMO',
    'HOSPITAL_DELIVERED_ORDER_DETAIL_DMO',
    'HOSPITAL_REVERTED_ORDER_DETAIL_DMO',
    'HOSPITAL_REJECTED_ORDER_DETAIL_DMO',
    'HOSPITAL_ORDER_REQUEST_DETAIL_DMO',
  ],
  hasChild: false,
}
const HOSPITAL_REJECTED_ORDER_DMO: RouterMenuProps = {
  id: 185,
  path: 'rejected',
  parent_id: 17,
  icon: 'stock',
  label: 'Order Request',
  key: 'order_request',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: false,
  slugOptions: [
    'HOSPITAL_ORDERS_DMO',
    'HOSPITAL_PENDING_ORDER_DMO',
    'HOSPITAL_CONFIRMED_ORDER_DMO',
    'HOSPITAL_DELIVERED_ORDER_DMO',
    'HOSPITAL_REVERTED_ORDER_DMO',
    'HOSPITAL_REJECTED_ORDER_DMO',
    'HOSPITAL_PENDING_ORDER_DETAIL_DMO',
    'HOSPITAL_CONFIRMED_ORDER_DETAIL_DMO',
    'HOSPITAL_DELIVERED_ORDER_DETAIL_DMO',
    'HOSPITAL_REVERTED_ORDER_DETAIL_DMO',
    'HOSPITAL_REJECTED_ORDER_DETAIL_DMO',
    'HOSPITAL_ORDER_REQUEST_DETAIL_DMO',
  ],
  hasChild: false,
}
const HOSPITAL_CONFIRMED_ORDER_DETAIL_DMO: RouterMenuProps = {
  id: 1891,
  path: '/medicine-dms/confirmed/:id',
  parent_id: 18,
  icon: 'stock',
  label: 'Confirmed Orders',
  key: 'order_request',
  breadcrumb: ['HOSPITAL_ORDERS', 'HOSPITAL_CONFIRMED_ORDER_DETAIL'],
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: false,
  slugOptions: [
    'HOSPITAL_ORDERS_DMO',
    'HOSPITAL_PENDING_ORDER_DMO',
    'HOSPITAL_CONFIRMED_ORDER_DMO',
    'HOSPITAL_DELIVERED_ORDER_DMO',
    'HOSPITAL_REVERTED_ORDER_DMO',
    'HOSPITAL_REJECTED_ORDER_DMO',
    'HOSPITAL_PENDING_ORDER_DETAIL_DMO',
    'HOSPITAL_CONFIRMED_ORDER_DETAIL_DMO',
    'HOSPITAL_DELIVERED_ORDER_DETAIL_DMO',
    'HOSPITAL_REVERTED_ORDER_DETAIL_DMO',
    'HOSPITAL_REJECTED_ORDER_DETAIL_DMO',
    'HOSPITAL_ORDER_REQUEST_DETAIL_DMO',
  ],
  hasChild: false,
}
const HOSPITAL_REPLACED_ORDER_DETAIL_DMO: RouterMenuProps = {
  id: 1891,
  path: '/medicine-dms/replaced/:id',
  parent_id: 18,
  icon: 'stock',
  label: 'Confirmed Orders',
  key: 'order_request',
  breadcrumb: ['HOSPITAL_ORDERS', 'HOSPITAL_CONFIRMED_ORDER_DETAIL'],
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: false,
  slugOptions: [
    'HOSPITAL_ORDERS_DMO',
    'HOSPITAL_PENDING_ORDER_DMO',
    'HOSPITAL_CONFIRMED_ORDER_DMO',
    'HOSPITAL_DELIVERED_ORDER_DMO',
    'HOSPITAL_REVERTED_ORDER_DMO',
    'HOSPITAL_REJECTED_ORDER_DMO',
    'HOSPITAL_PENDING_ORDER_DETAIL_DMO',
    'HOSPITAL_CONFIRMED_ORDER_DETAIL_DMO',
    'HOSPITAL_DELIVERED_ORDER_DETAIL_DMO',
    'HOSPITAL_REVERTED_ORDER_DETAIL_DMO',
    'HOSPITAL_REJECTED_ORDER_DETAIL_DMO',
    'HOSPITAL_ORDER_REQUEST_DETAIL_DMO',
  ],
  hasChild: false,
}
const HOSPITAL_DELIVERED_ORDER_DETAIL_DMO: RouterMenuProps = {
  id: 189,
  path: '/medicine-dms/delivered/:id',
  parent_id: 18,
  icon: 'stock',
  label: 'Delivered Orders',
  breadcrumb: ['HOSPITAL_ORDERS', 'HOSPITAL_DELIVERED_ORDER_DETAIL'],
  key: 'order_request',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: false,
  slugOptions: [
    'HOSPITAL_ORDERS_DMO',
    'HOSPITAL_PENDING_ORDER_DMO',
    'HOSPITAL_CONFIRMED_ORDER_DMO',
    'HOSPITAL_DELIVERED_ORDER_DMO',
    'HOSPITAL_REVERTED_ORDER_DMO',
    'HOSPITAL_REJECTED_ORDER_DMO',
    'HOSPITAL_PENDING_ORDER_DETAIL_DMO',
    'HOSPITAL_CONFIRMED_ORDER_DETAIL_DMO',
    'HOSPITAL_DELIVERED_ORDER_DETAIL_DMO',
    'HOSPITAL_REVERTED_ORDER_DETAIL_DMO',
    'HOSPITAL_REJECTED_ORDER_DETAIL_DMO',
    'HOSPITAL_ORDER_REQUEST_DETAIL_DMO',
  ],
  hasChild: false,
}
const HOSPITAL_REVERTED_ORDER_DETAIL_DMO: RouterMenuProps = {
  id: 188,
  path: '/medicine-dms/reverted/:id',
  parent_id: 17,
  icon: 'stock',
  label: 'Reverted Orders',
  key: 'order_request',
  breadcrumb: ['HOSPITAL_ORDERS', 'HOSPITAL_REVERTED_ORDER_DETAIL'],
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: false,
  slugOptions: [
    'HOSPITAL_ORDERS_DMO',
    'HOSPITAL_PENDING_ORDER_DMO',
    'HOSPITAL_CONFIRMED_ORDER_DMO',
    'HOSPITAL_DELIVERED_ORDER_DMO',
    'HOSPITAL_REVERTED_ORDER_DMO',
    'HOSPITAL_REJECTED_ORDER_DMO',
    'HOSPITAL_PENDING_ORDER_DETAIL_DMO',
    'HOSPITAL_CONFIRMED_ORDER_DETAIL_DMO',
    'HOSPITAL_DELIVERED_ORDER_DETAIL_DMO',
    'HOSPITAL_REVERTED_ORDER_DETAIL_DMO',
    'HOSPITAL_REJECTED_ORDER_DETAIL_DMO',
    'HOSPITAL_ORDER_REQUEST_DETAIL_DMO',
  ],
  hasChild: false,
}
const HOSPITAL_REJECTED_ORDER_DETAIL_DMO: RouterMenuProps = {
  id: 187,
  path: '/medicine-dms/rejected/:id',
  parent_id: 18,
  icon: 'stock',
  label: 'Rejected Orders',
  key: 'rejected_order',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: false,
  breadcrumb: ['HOSPITAL_ORDERS', 'HOSPITAL_REJECTED_ORDER_DETAIL'],
  slugOptions: [
    'HOSPITAL_ORDERS_DMO',
    'HOSPITAL_PENDING_ORDER_DMO',
    'HOSPITAL_CONFIRMED_ORDER_DMO',
    'HOSPITAL_DELIVERED_ORDER_DMO',
    'HOSPITAL_REVERTED_ORDER_DMO',
    'HOSPITAL_REJECTED_ORDER_DMO',
    'HOSPITAL_PENDING_ORDER_DETAIL_DMO',
    'HOSPITAL_CONFIRMED_ORDER_DETAIL_DMO',
    'HOSPITAL_DELIVERED_ORDER_DETAIL_DMO',
    'HOSPITAL_REVERTED_ORDER_DETAIL_DMO',
    'HOSPITAL_REJECTED_ORDER_DETAIL_DMO',
    'HOSPITAL_ORDER_REQUEST_DETAIL_DMO',
  ],
  hasChild: false,
}
const HOSPITAL_ORDER_REQUEST_DETAIL_DMO: RouterMenuProps = {
  id: 186,
  // path: '/myorders/:id',
  parent_id: 18,
  icon: 'order',
  label: 'View List',
  key: 'order_request',
  breadcrumb: ['HOSPITAL_ORDERS', 'HOSPITAL_ORDER_REQUEST_DETAIL'],
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: false,
  slugOptions: [
    'HOSPITAL_ORDERS_DMO',
    'HOSPITAL_PENDING_ORDER_DMO',
    'HOSPITAL_CONFIRMED_ORDER_DMO',
    'HOSPITAL_DELIVERED_ORDER_DMO',
    'HOSPITAL_REVERTED_ORDER_DMO',
    'HOSPITAL_REJECTED_ORDER_DMO',
    'HOSPITAL_PENDING_ORDER_DETAIL_DMO',
    'HOSPITAL_CONFIRMED_ORDER_DETAIL_DMO',
    'HOSPITAL_DELIVERED_ORDER_DETAIL_DMO',
    'HOSPITAL_REVERTED_ORDER_DETAIL_DMO',
    'HOSPITAL_REJECTED_ORDER_DETAIL_DMO',
    'HOSPITAL_ORDER_REQUEST_DETAIL_DMO',
  ],
  hasChild: false,
}
const HOSPITAL_ORDERS_LOAN: RouterMenuProps = {
  id: 19,
  path: '/medicine-loans',
  parent_id: 18,
  icon: 'null',
  label: 'Medicine Loan',
  key: 'order_request',
  pathOverride: '/medicine-loans/pending',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: true,
  slugOptions: [
    'HOSPITAL_ORDERS_LOAN',
    'HOSPITAL_PENDING_ORDER_LOAN',
    'HOSPITAL_CONFIRMED_ORDER_LOAN',
    'HOSPITAL_DELIVERED_ORDER_LOAN',
    'HOSPITAL_REVERTED_ORDER_LOAN',
    'HOSPITAL_REJECTED_ORDER_LOAN',
    'HOSPITAL_PENDING_ORDER_DETAIL_LOAN',
    'HOSPITAL_CONFIRMED_ORDER_DETAIL_LOAN',
    'HOSPITAL_DELIVERED_ORDER_DETAIL_LOAN',
    'HOSPITAL_REVERTED_ORDER_DETAIL_LOAN',
    'HOSPITAL_REJECTED_ORDER_DETAIL_LOAN',
    'HOSPITAL_ORDER_REQUEST_DETAIL_LOAN',
  ],
  hasChild: false,
}
const HOSPITAL_PENDING_ORDER_LOAN: RouterMenuProps = {
  id: 181,
  path: 'pending',
  parent_id: 17,
  icon: 'stock',
  label: 'Order Request',
  key: 'order_request',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: false,
  slugOptions: [
    'HOSPITAL_ORDERS_LOAN',
    'HOSPITAL_PENDING_ORDER_LOAN',
    'HOSPITAL_CONFIRMED_ORDER_LOAN',
    'HOSPITAL_DELIVERED_ORDER_LOAN',
    'HOSPITAL_REVERTED_ORDER_LOAN',
    'HOSPITAL_REJECTED_ORDER_LOAN',
    'HOSPITAL_PENDING_ORDER_DETAIL_LOAN',
    'HOSPITAL_CONFIRMED_ORDER_DETAIL_LOAN',
    'HOSPITAL_DELIVERED_ORDER_DETAIL_LOAN',
    'HOSPITAL_REVERTED_ORDER_DETAIL_LOAN',
    'HOSPITAL_REJECTED_ORDER_DETAIL_LOAN',
    'HOSPITAL_ORDER_REQUEST_DETAIL_LOAN',
  ],
  hasChild: false,
}
const HOSPITAL_PENDING_ORDER_DETAIL_LOAN: RouterMenuProps = {
  id: 181,
  path: '/medicine-loans/pending/:id',
  parent_id: 18,
  icon: 'stock',
  label: 'Pending Order',
  breadcrumb: ['HOSPITAL_ORDERS', 'HOSPITAL_PENDING_ORDER_DETAIL'],
  key: 'order_request',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: false,
  slugOptions: [
    'HOSPITAL_ORDERS_LOAN',
    'HOSPITAL_PENDING_ORDER_LOAN',
    'HOSPITAL_CONFIRMED_ORDER_LOAN',
    'HOSPITAL_DELIVERED_ORDER_LOAN',
    'HOSPITAL_REVERTED_ORDER_LOAN',
    'HOSPITAL_REJECTED_ORDER_LOAN',
    'HOSPITAL_PENDING_ORDER_DETAIL_LOAN',
    'HOSPITAL_CONFIRMED_ORDER_DETAIL_LOAN',
    'HOSPITAL_DELIVERED_ORDER_DETAIL_LOAN',
    'HOSPITAL_REVERTED_ORDER_DETAIL_LOAN',
    'HOSPITAL_REJECTED_ORDER_DETAIL_LOAN',
    'HOSPITAL_ORDER_REQUEST_DETAIL_LOAN',
  ],
  hasChild: false,
}
const HOSPITAL_CONFIRMED_ORDER_LOAN: RouterMenuProps = {
  id: 182,
  path: 'forwarded',
  parent_id: 17,
  icon: 'stock',
  label: 'Order Request',
  key: 'order_request',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: false,
  slugOptions: [
    'HOSPITAL_ORDERS_LOAN',
    'HOSPITAL_PENDING_ORDER_LOAN',
    'HOSPITAL_CONFIRMED_ORDER_LOAN',
    'HOSPITAL_DELIVERED_ORDER_LOAN',
    'HOSPITAL_REVERTED_ORDER_LOAN',
    'HOSPITAL_REJECTED_ORDER_LOAN',
    'HOSPITAL_PENDING_ORDER_DETAIL_LOAN',
    'HOSPITAL_CONFIRMED_ORDER_DETAIL_LOAN',
    'HOSPITAL_DELIVERED_ORDER_DETAIL_LOAN',
    'HOSPITAL_REVERTED_ORDER_DETAIL_LOAN',
    'HOSPITAL_REJECTED_ORDER_DETAIL_LOAN',
    'HOSPITAL_ORDER_REQUEST_DETAIL_LOAN',
  ],
  hasChild: false,
}
const HOSPITAL_DELIVERED_ORDER_LOAN: RouterMenuProps = {
  id: 183,
  path: 'accepted',
  parent_id: 17,
  icon: 'stock',
  label: 'Order Request',
  key: 'order_request',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: false,
  slugOptions: [
    'HOSPITAL_ORDERS_LOAN',
    'HOSPITAL_PENDING_ORDER_LOAN',
    'HOSPITAL_CONFIRMED_ORDER_LOAN',
    'HOSPITAL_DELIVERED_ORDER_LOAN',
    'HOSPITAL_REVERTED_ORDER_LOAN',
    'HOSPITAL_REJECTED_ORDER_LOAN',
    'HOSPITAL_PENDING_ORDER_DETAIL_LOAN',
    'HOSPITAL_CONFIRMED_ORDER_DETAIL_LOAN',
    'HOSPITAL_DELIVERED_ORDER_DETAIL_LOAN',
    'HOSPITAL_REVERTED_ORDER_DETAIL_LOAN',
    'HOSPITAL_REJECTED_ORDER_DETAIL_LOAN',
    'HOSPITAL_ORDER_REQUEST_DETAIL_LOAN',
  ],
  hasChild: false,
}
const HOSPITAL_REVERTED_ORDER_LOAN: RouterMenuProps = {
  id: 184,
  path: 'delivered',
  parent_id: 17,
  icon: 'stock',
  label: 'Order Request',
  key: 'order_request',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: false,
  slugOptions: [
    'HOSPITAL_ORDERS_LOAN',
    'HOSPITAL_PENDING_ORDER_LOAN',
    'HOSPITAL_CONFIRMED_ORDER_LOAN',
    'HOSPITAL_DELIVERED_ORDER_LOAN',
    'HOSPITAL_REVERTED_ORDER_LOAN',
    'HOSPITAL_REJECTED_ORDER_LOAN',
    'HOSPITAL_PENDING_ORDER_DETAIL_LOAN',
    'HOSPITAL_CONFIRMED_ORDER_DETAIL_LOAN',
    'HOSPITAL_DELIVERED_ORDER_DETAIL_LOAN',
    'HOSPITAL_REVERTED_ORDER_DETAIL_LOAN',
    'HOSPITAL_REJECTED_ORDER_DETAIL_LOAN',
    'HOSPITAL_ORDER_REQUEST_DETAIL_LOAN',
  ],
  hasChild: false,
}
const HOSPITAL_REJECTED_ORDER_LOAN: RouterMenuProps = {
  id: 185,
  path: 'rejected',
  parent_id: 17,
  icon: 'stock',
  label: 'Order Request',
  key: 'order_request',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: false,
  slugOptions: [
    'HOSPITAL_ORDERS_LOAN',
    'HOSPITAL_PENDING_ORDER_LOAN',
    'HOSPITAL_CONFIRMED_ORDER_LOAN',
    'HOSPITAL_DELIVERED_ORDER_LOAN',
    'HOSPITAL_REVERTED_ORDER_LOAN',
    'HOSPITAL_REJECTED_ORDER_LOAN',
    'HOSPITAL_PENDING_ORDER_DETAIL_LOAN',
    'HOSPITAL_CONFIRMED_ORDER_DETAIL_LOAN',
    'HOSPITAL_DELIVERED_ORDER_DETAIL_LOAN',
    'HOSPITAL_REVERTED_ORDER_DETAIL_LOAN',
    'HOSPITAL_REJECTED_ORDER_DETAIL_LOAN',
    'HOSPITAL_ORDER_REQUEST_DETAIL_LOAN',
  ],
  hasChild: false,
}
const HOSPITAL_CONFIRMED_ORDER_DETAIL_LOAN: RouterMenuProps = {
  id: 1891,
  path: '/medicine-loans/forwarded/:id',
  parent_id: 18,
  icon: 'stock',
  label: 'Confirmed Orders',
  key: 'order_request',
  breadcrumb: ['HOSPITAL_ORDERS', 'HOSPITAL_CONFIRMED_ORDER_DETAIL'],
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: false,
  slugOptions: [
    'HOSPITAL_ORDERS_LOAN',
    'HOSPITAL_PENDING_ORDER_LOAN',
    'HOSPITAL_CONFIRMED_ORDER_LOAN',
    'HOSPITAL_DELIVERED_ORDER_LOAN',
    'HOSPITAL_REVERTED_ORDER_LOAN',
    'HOSPITAL_REJECTED_ORDER_LOAN',
    'HOSPITAL_PENDING_ORDER_DETAIL_LOAN',
    'HOSPITAL_CONFIRMED_ORDER_DETAIL_LOAN',
    'HOSPITAL_DELIVERED_ORDER_DETAIL_LOAN',
    'HOSPITAL_REVERTED_ORDER_DETAIL_LOAN',
    'HOSPITAL_REJECTED_ORDER_DETAIL_LOAN',
    'HOSPITAL_ORDER_REQUEST_DETAIL_LOAN',
  ],
  hasChild: false,
}
const HOSPITAL_DELIVERED_ORDER_DETAIL_LOAN: RouterMenuProps = {
  id: 189,
  path: '/medicine-loans/accepted/:id',
  parent_id: 18,
  icon: 'stock',
  label: 'Delivered Orders',
  breadcrumb: ['HOSPITAL_ORDERS', 'HOSPITAL_DELIVERED_ORDER_DETAIL'],
  key: 'order_request',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: false,
  slugOptions: [
    'HOSPITAL_ORDERS_LOAN',
    'HOSPITAL_PENDING_ORDER_LOAN',
    'HOSPITAL_CONFIRMED_ORDER_LOAN',
    'HOSPITAL_DELIVERED_ORDER_LOAN',
    'HOSPITAL_REVERTED_ORDER_LOAN',
    'HOSPITAL_REJECTED_ORDER_LOAN',
    'HOSPITAL_PENDING_ORDER_DETAIL_LOAN',
    'HOSPITAL_CONFIRMED_ORDER_DETAIL_LOAN',
    'HOSPITAL_DELIVERED_ORDER_DETAIL_LOAN',
    'HOSPITAL_REVERTED_ORDER_DETAIL_LOAN',
    'HOSPITAL_REJECTED_ORDER_DETAIL_LOAN',
    'HOSPITAL_ORDER_REQUEST_DETAIL_LOAN',
  ],
  hasChild: false,
}
const HOSPITAL_REVERTED_ORDER_DETAIL_LOAN: RouterMenuProps = {
  id: 188,
  path: '/medicine-loans/delivered/:id',
  parent_id: 17,
  icon: 'stock',
  label: 'Reverted Orders',
  key: 'order_request',
  breadcrumb: ['HOSPITAL_ORDERS', 'HOSPITAL_REVERTED_ORDER_DETAIL'],
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: false,
  slugOptions: [
    'HOSPITAL_ORDERS_LOAN',
    'HOSPITAL_PENDING_ORDER_LOAN',
    'HOSPITAL_CONFIRMED_ORDER_LOAN',
    'HOSPITAL_DELIVERED_ORDER_LOAN',
    'HOSPITAL_REVERTED_ORDER_LOAN',
    'HOSPITAL_REJECTED_ORDER_LOAN',
    'HOSPITAL_PENDING_ORDER_DETAIL_LOAN',
    'HOSPITAL_CONFIRMED_ORDER_DETAIL_LOAN',
    'HOSPITAL_DELIVERED_ORDER_DETAIL_LOAN',
    'HOSPITAL_REVERTED_ORDER_DETAIL_LOAN',
    'HOSPITAL_REJECTED_ORDER_DETAIL_LOAN',
    'HOSPITAL_ORDER_REQUEST_DETAIL_LOAN',
  ],
  hasChild: false,
}
const HOSPITAL_REJECTED_ORDER_DETAIL_LOAN: RouterMenuProps = {
  id: 187,
  path: '/medicine-loans/rejected/:id',
  parent_id: 18,
  icon: 'stock',
  label: 'Rejected Orders',
  key: 'rejected_order',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: false,
  breadcrumb: ['HOSPITAL_ORDERS', 'HOSPITAL_REJECTED_ORDER_DETAIL'],
  slugOptions: [
    'HOSPITAL_ORDERS_LOAN',
    'HOSPITAL_PENDING_ORDER_LOAN',
    'HOSPITAL_CONFIRMED_ORDER_LOAN',
    'HOSPITAL_DELIVERED_ORDER_LOAN',
    'HOSPITAL_REVERTED_ORDER_LOAN',
    'HOSPITAL_REJECTED_ORDER_LOAN',
    'HOSPITAL_PENDING_ORDER_DETAIL_LOAN',
    'HOSPITAL_CONFIRMED_ORDER_DETAIL_LOAN',
    'HOSPITAL_DELIVERED_ORDER_DETAIL_LOAN',
    'HOSPITAL_REVERTED_ORDER_DETAIL_LOAN',
    'HOSPITAL_REJECTED_ORDER_DETAIL_LOAN',
    'HOSPITAL_ORDER_REQUEST_DETAIL_LOAN',
  ],
  hasChild: false,
}
const HOSPITAL_ORDER_REQUEST_DETAIL_LOAN: RouterMenuProps = {
  id: 186,
  // path: '/myorders/:id',
  parent_id: 18,
  icon: 'order',
  label: 'View List',
  key: 'order_request',
  breadcrumb: ['HOSPITAL_ORDERS', 'HOSPITAL_ORDER_REQUEST_DETAIL'],
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: false,
  slugOptions: [
    'HOSPITAL_ORDERS_LOAN',
    'HOSPITAL_PENDING_ORDER_LOAN',
    'HOSPITAL_CONFIRMED_ORDER_LOAN',
    'HOSPITAL_DELIVERED_ORDER_LOAN',
    'HOSPITAL_REVERTED_ORDER_LOAN',
    'HOSPITAL_REJECTED_ORDER_LOAN',
    'HOSPITAL_PENDING_ORDER_DETAIL_LOAN',
    'HOSPITAL_CONFIRMED_ORDER_DETAIL_LOAN',
    'HOSPITAL_DELIVERED_ORDER_DETAIL_LOAN',
    'HOSPITAL_REVERTED_ORDER_DETAIL_LOAN',
    'HOSPITAL_REJECTED_ORDER_DETAIL_LOAN',
    'HOSPITAL_ORDER_REQUEST_DETAIL_LOAN',
  ],
  hasChild: false,
}
const REPORTS: RouterMenuProps = {
  id: 19,
  path: '/reports',
  icon: 'reports',
  parent_id: null,
  label: 'Reports',
  key: 'reports',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: true,
  slugOptions: [
    'REPORTS',
    'REPORTS_PRIMARY',
    'REPORTS_SECONDARY',
    'REPORTS_PRIMARY_LIST',
    'REPORTS_SECONDARY_LIST',
    'REPORTS_MEDICINE_EXPIRE',
  ],
  hasChild: true,
}
const REPORTS_PRIMARY: RouterMenuProps = {
  id: 191,
  path: '/reports/primary',
  icon: 'null',
  parent_id: 19,
  label: 'Primary Stock Report',
  key: 'Primary_stock_report',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: true,
  slugOptions: [
    'REPORTS',
    'REPORTS_PRIMARY',
    'REPORTS_PRIMARY_LIST',
    'REPORTS_PRIMARY_BREADCRUMB',
  ],
  hasChild: false,
}
const REPORTS_PRIMARY_BREADCRUMB: RouterMenuProps = {
  id: 191,
  path: '/reports/primary',
  icon: 'null',
  parent_id: 19,
  label: 'Reports',
  key: 'Primary_stock_report',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: false,
  slugOptions: ['REPORTS', 'REPORTS_PRIMARY', 'REPORTS_PRIMARY_LIST'],
  hasChild: false,
}
const REPORTS_SECONDARY: RouterMenuProps = {
  id: 192,
  path: '/reports/secondary',
  icon: 'null',
  parent_id: 19,
  label: 'Secondary Stock Report',
  key: 'Secondary_stock_report',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: true,
  slugOptions: ['REPORTS', 'REPORTS_SECONDARY', 'REPORTS_SECONDARY_LIST'],
  hasChild: false,
}
const REPORTS_SECONDARY_BREADCRUMB: RouterMenuProps = {
  id: 192,
  path: '/reports/secondary',
  icon: 'null',
  parent_id: 19,
  label: 'Reports',
  key: 'Secondary_stock_report',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: false,
  slugOptions: [
    'REPORTS',
    'REPORTS_SECONDARY',
    'REPORTS_SECONDARY_LIST',
    'REPORTS_SECONDARY_BREADCRUMB',
  ],
  hasChild: false,
}
const REPORTS_FUND: RouterMenuProps = {
  id: 192,
  path: '/reports/fund',
  icon: 'null',
  parent_id: 19,
  label: 'Fund Usage Report',
  key: 'Fund Usage Report',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: true,
  slugOptions: [
    'REPORTS',
    'REPORTS_FUND',
    'REPORTS_FUND_LIST',
    'REPORTS_FUND_BREADCRUMB',
  ],
  hasChild: false,
}
const REPORTS_MEDICINE_INTENT: RouterMenuProps = {
  id: 192,
  path: '/reports/medicine-intent',
  icon: 'null',
  parent_id: 19,
  label: 'Medicine Indent Report',
  key: 'Fund Usage Report',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: true,
  slugOptions: [
    'REPORTS',
    'REPORTS_MEDICINE_INTENT',
    'REPORTS_MEDICINE_INTENT_LIST',
    'REPORTS_MEDICINE_INTENT_DETAIL',
  ],
  hasChild: false,
}
const REPORTS_MEDICINE_INTENT_LIST: RouterMenuProps = {
  id: 192,
  path: '/reports/medicine-intent/list',
  icon: 'null',
  parent_id: 19,
  label: 'Medicine indent report',
  key: 'Fund Usage Report',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: false,
  slugOptions: [
    'REPORTS',
    'REPORTS_MEDICINE_INTENT',
    'REPORTS_MEDICINE_INTENT_LIST',
    'REPORTS_MEDICINE_INTENT_DETAIL',
  ],
  hasChild: false,
}
const REPORTS_MEDICINE_INTENT_DETAIL: RouterMenuProps = {
  id: 192,
  path: '/reports/medicine-intent/list/:id',
  icon: 'null',
  parent_id: 19,
  label: 'Medicine indent report',
  key: 'Fund Usage Report',
  permission_slugs: ['Hospital Incharge', 'Super Admin'],
  isSidebarMenu: false,
  slugOptions: [
    'REPORTS',
    'REPORTS_MEDICINE_INTENT',
    'REPORTS_MEDICINE_INTENT_LIST',
    'REPORTS_MEDICINE_INTENT_DETAIL',
  ],
  hasChild: false,
}
const REPORTS_MEDICINE_LOAN: RouterMenuProps = {
  id: 192,
  path: '/reports/medicine-loan',
  icon: 'null',
  parent_id: 19,
  label: 'Medicine Loan Report',
  key: 'Medicine Loan Report',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: true,
  slugOptions: [
    'REPORTS',
    'REPORTS_MEDICINE_LOAN',
    'REPORTS_MEDICINE_LOAN_LIST',
    'REPORTS_MEDICINE_LOAN_DETAIL',
  ],
  hasChild: false,
}
const REPORTS_MEDICINE_LOAN_LIST: RouterMenuProps = {
  id: 192,
  path: '/reports/medicine-loan/list',
  icon: 'null',
  parent_id: 19,
  label: 'Medicine Loan Report',
  key: 'Medicine Loan Report',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: false,
  slugOptions: [
    'REPORTS',
    'REPORTS_MEDICINE_LOAN',
    'REPORTS_MEDICINE_LOAN_LIST',
    'REPORTS_MEDICINE_LOAN_DETAIL',
  ],
  hasChild: false,
}
const REPORTS_MEDICINE_LOAN_DETAIL: RouterMenuProps = {
  id: 192,
  path: '/reports/medicine-loan/list/:id',
  icon: 'null',
  parent_id: 19,
  label: 'Medicine Loan Report',
  key: 'Medicine Loan Report',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: false,
  slugOptions: [
    'REPORTS',
    'REPORTS_MEDICINE_LOAN',
    'REPORTS_MEDICINE_LOAN_LIST',
    'REPORTS_MEDICINE_LOAN_DETAIL',
  ],
  hasChild: false,
}
const REPORTS_MEDICINE_EXPIRE: RouterMenuProps = {
  id: 192,
  path: '/reports/medicine/expire',
  icon: 'null',
  parent_id: 19,
  label: 'Expiring Medicine',
  key: 'expiring_medicines',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: true,
  slugOptions: ['REPORTS', 'REPORTS_MEDICINE_EXPIRE'],
  hasChild: false,
}
const REPORTS_FUND_BREADCRUMB: RouterMenuProps = {
  id: 192,
  path: '/reports/fund',
  icon: 'null',
  parent_id: 19,
  label: 'Reports',
  key: 'Fund Usage And Balance Report',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: false,
  slugOptions: [
    'REPORTS',
    'REPORTS_FUND',
    'REPORTS_FUND_LIST',
    'REPORTS_FUND_BREADCRUMB',
  ],
  hasChild: false,
}
const REPORTS_PRIMARY_LIST: RouterMenuProps = {
  id: 193,
  path: '/reports/primary/list',
  icon: 'null',
  parent_id: 19,
  breadcrumb: ['REPORTS_PRIMARY_BREADCRUMB', 'REPORTS_PRIMARY_LIST'],
  label: 'Primary Stock Report',
  key: 'Primary_stock_report_list',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: false,
  slugOptions: [
    'REPORTS',
    'REPORTS_PRIMARY',
    'REPORTS_PRIMARY_LIST',
    'REPORTS_PRIMARY_BREADCRUMB',
  ],
  hasChild: false,
}
const REPORTS_SECONDARY_LIST: RouterMenuProps = {
  id: 193,
  path: '/reports/secondary/list',
  icon: 'null',
  parent_id: 19,
  breadcrumb: ['REPORTS_SECONDARY_BREADCRUMB', 'REPORTS_SECONDARY_LIST'],
  label: 'Secondary Stock Report',
  key: 'Primary_stock_report_list',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: false,
  slugOptions: [
    'REPORTS',
    'REPORTS_SECONDARY',
    'REPORTS_SECONDARY_LIST',
    'REPORTS_SECONDARY_BREADCRUMB',
  ],
  hasChild: false,
}
const REPORTS_FUND_LIST: RouterMenuProps = {
  id: 193,
  path: '/reports/funds/list',
  icon: 'null',
  parent_id: 19,
  breadcrumb: ['REPORTS_FUND_BREADCRUMB', 'REPORTS_FUND_LIST'],
  label: 'Fund Usage And  Balance Report',
  key: 'Fund Usage And  Balance Report',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: false,
  slugOptions: [
    'REPORTS',
    'REPORTS_FUND',
    'REPORTS_FUND_LIST',
    'REPORTS_FUND_BREADCRUMB',
  ],
  hasChild: false,
}

const HOSPITAL_MEDICINE_ISSUE: RouterMenuProps = {
  id: 20,
  path: '/medicine',
  parent_id: null,
  icon: 'medicine_issue',
  label: 'Medicine Issue',
  pathOverride: '/medicine/secondary/stock',
  key: 'Medicine_issue',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: true,
  slugOptions: [
    'HOSPITAL_MEDICINE_ISSUE',
    'HOSPITAL_MEDICINE_ISSUE_SECONDARY',
    'HOSPITAL_MEDICINE_REQUEST',
  ],
  hasChild: false,
}
const HOSPITAL_MEDICINE_ISSUE_SECONDARY: RouterMenuProps = {
  id: 201,
  path: 'secondary/stock',
  parent_id: 20,
  icon: 'medicine_issue',
  label: 'Secondary Stock ',
  key: 'Secondary_Stock ',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: false,
  slugOptions: [
    'HOSPITAL_MEDICINE_ISSUE',
    'HOSPITAL_MEDICINE_ISSUE_SECONDARY',
    'HOSPITAL_MEDICINE_REQUEST',
  ],
  hasChild: false,
}
const HOSPITAL_MEDICINE_REQUEST: RouterMenuProps = {
  id: 202,
  path: 'request',
  parent_id: 20,
  icon: 'medicine_issue',
  label: 'Medicine Request From Pharmacy',
  key: 'Medicine Request From Pharmacy',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: false,
  slugOptions: [
    'HOSPITAL_MEDICINE_ISSUE',
    'HOSPITAL_MEDICINE_ISSUE_SECONDARY',
    'HOSPITAL_MEDICINE_REQUEST',
  ],
  hasChild: false,
}

const HOSPITAL_PURCHASE_PRODUCT: RouterMenuProps = {
  id: 21,
  path: '/purchase',
  parent_id: null,
  icon: 'cart-icon',
  label: 'Purchase Product',
  pathOverride: '/purchase/medicine',
  key: 'Purchase_product',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: true,
  slugOptions: [
    'HOSPITAL_PURCHASE_PRODUCT',
    'HOSPITAL_PURCHASE_MEDICINE',
    'HOSPITAL_MEDICINE_LOAN',
  ],
  hasChild: false,
}
const HOSPITAL_PURCHASE_MEDICINE: RouterMenuProps = {
  id: 211,
  path: 'medicine',
  parent_id: 21,
  icon: 'medicine_issue',
  label: 'Purchase Medicine ',
  key: 'Purchase Medicine ',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: false,
  slugOptions: [
    'HOSPITAL_PURCHASE_PRODUCT',
    'HOSPITAL_PURCHASE_MEDICINE',
    'HOSPITAL_MEDICINE_LOAN',
  ],
  hasChild: false,
}
const HOSPITAL_MEDICINE_LOAN: RouterMenuProps = {
  id: 212,
  path: 'loan',
  parent_id: 21,
  icon: 'medicine_issue',
  label: 'Purchase Medicine ',
  key: 'Purchase Medicine ',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: false,
  slugOptions: [
    'HOSPITAL_PURCHASE_PRODUCT',
    'HOSPITAL_PURCHASE_MEDICINE',
    'HOSPITAL_MEDICINE_LOAN',
  ],
  hasChild: false,
}

const OP_PATIENTS: RouterMenuProps = {
  id: 22,
  path: '/patients',
  parent_id: null,
  icon: 'patient',
  label: 'Patients',
  pathOverride: '/patients/all',
  key: 'Patients',
  permission_slugs: ['Op Incharge','Doctor'],
  isSidebarMenu: true,
  slugOptions: ['OP_PATIENTS', 'OP_PATIENTS_ALL', 'OP_PATIENTS_ALL_ADD_NEW'],
  hasChild: false,
}
const OP_PATIENTS_ALL: RouterMenuProps = {
  id: 201,
  path: 'all',
  parent_id: 21,
  icon: 'patient',
  label: 'Patients ',
  key: 'Patients ',
  permission_slugs: ['Op Incharge','Doctor'],
  isSidebarMenu: false,
  slugOptions: [
    'OP_PATIENTS',
    'OP_PATIENTS_ALL',
    'OP_PATIENTS_ALL_ADD_NEW',
    'OP_PATIENTS_WAITING',
    'OP_PATIENTS_EXAMINED',
  ],
  hasChild: false,
}
const OP_PATIENTS_WAITING: RouterMenuProps = {
  id: 202,
  path: 'waiting',
  parent_id: 21,
  icon: 'patient',
  label: 'Patients ',
  key: 'Patients ',
  permission_slugs: ['Op Incharge','Doctor'],
  isSidebarMenu: false,
  slugOptions: [
    'OP_PATIENTS',
    'OP_PATIENTS_ALL',
    'OP_PATIENTS_ALL_ADD_NEW',
    'OP_PATIENTS_WAITING',
    'OP_PATIENTS_EXAMINED',
  ],
  hasChild: false,
}
const OP_PATIENTS_EXAMINED: RouterMenuProps = {
  id: 202,
  path: 'examined',
  parent_id: 21,
  icon: 'patient',
  label: 'Patients ',
  key: 'Patients ',
  permission_slugs: ['Op Incharge','Doctor'],
  isSidebarMenu: false,
  slugOptions: [
    'OP_PATIENTS',
    'OP_PATIENTS_ALL',
    'OP_PATIENTS_ALL_ADD_NEW',
    'OP_PATIENTS_WAITING',
    'OP_PATIENTS_EXAMINED',
  ],
  hasChild: false,
}
const OP_PATIENTS_ALL_ADD_NEW: RouterMenuProps = {
  id: 22,
  path: '/add-new',
  parent_id: 21,
  icon: 'patient',
  label: 'Patients',
  key: 'Patients',
  permission_slugs: ['Op Incharge','Doctor'],
  isSidebarMenu: false,
  slugOptions: [
    'OP_PATIENTS',
    'OP_PATIENTS_ALL',
    'OP_PATIENTS_ALL_ADD_NEW',
    'OP_PATIENTS_WAITING',
    'OP_PATIENTS_EXAMINED',
  ],
  hasChild: false,
}

const DISTRICT_ADMIN: RouterMenuProps = {
  id: 23,
  path: '/district-admin',
  parent_id: null,
  icon: 'district',
  label: 'District Admin',
  key: 'district_admin',
  permission_slugs: ['Super Admin'],
  isSidebarMenu: true,
  slugOptions: [
    'DISTRICT_ADMIN',
    'DISTRICT_ADMIN_CREATE',
    'DISTRICT_ADMIN_VIEW',
  ],
  hasChild: false,
}
const DISTRICT_ADMIN_CREATE: RouterMenuProps = {
  id: 24,
  path: '/district-admin/add',
  parent_id: null,
  icon: 'district',
  label: 'District Admin',
  key: 'district_admin',
  permission_slugs: ['Super Admin'],
  isSidebarMenu: false,
  slugOptions: [
    'DISTRICT_ADMIN',
    'DISTRICT_ADMIN_CREATE',
    'DISTRICT_ADMIN_VIEW',
  ],
  hasChild: false,
}
const DISTRICT_ADMIN_VIEW: RouterMenuProps = {
  id: 10,
  path: '/district-admin/:id',
  parent_id: null,
  icon: 'district',
  label: 'District Admin',
  key: 'district_admin',
  permission_slugs: ['Super Admin'],
  isSidebarMenu: false,
  slugOptions: [
    'DISTRICT_ADMIN',
    'DISTRICT_ADMIN_CREATE',
    'DISTRICT_ADMIN_VIEW',
  ],
  hasChild: false,
}
const DISTRICT_MEDICAL: RouterMenuProps = {
  id: 23,
  path: '/district-medical-store',
  parent_id: null,
  icon: 'pharmacist',
  label: 'District Medical Store',
  key: 'district_admin',
  permission_slugs: ['Super Admin'],
  isSidebarMenu: true,
  slugOptions: [
    'DISTRICT_MEDICAL',
    'DISTRICT_MEDICAL_CREATE',
    'DISTRICT_MEDICAL_VIEW',
  ],
  hasChild: false,
}
const DISTRICT_MEDICAL_CREATE: RouterMenuProps = {
  id: 24,
  path: '/district-medical-store/add',
  parent_id: null,
  icon: 'pharmacist',
  label: 'District Medical Store',
  key: 'district_admin',
  permission_slugs: ['Super Admin'],
  isSidebarMenu: false,
  slugOptions: [
    'DISTRICT_MEDICAL',
    'DISTRICT_MEDICAL_CREATE',
    'DISTRICT_MEDICAL_VIEW',
  ],
  hasChild: false,
}
const DISTRICT_MEDICAL_VIEW: RouterMenuProps = {
  id: 25,
  path: '/district-medical-store/:id',
  parent_id: null,
  icon: 'pharmacist',
  label: 'District medical Store',
  key: 'district_admin',
  permission_slugs: ['Super Admin'],
  isSidebarMenu: false,
  slugOptions: [
    'DISTRICT_MEDICAL',
    'DISTRICT_MEDICAL_CREATE',
    'DISTRICT_MEDICAL_VIEW',
  ],
  hasChild: false,
}
const DOCTOR: RouterMenuProps = {
  id: 26,
  path: '/doctor',
  parent_id: null,
  icon: 'doctor',
  label: 'Doctors',
  key: 'doctors',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: true,
  slugOptions: ['DOCTOR', 'DOCTOR_VIEW'],
  hasChild: false,
}
const DOCTOR_VIEW: RouterMenuProps = {
  id: 27,
  path: '/doctor/:id',
  parent_id: null,
  icon: 'doctor',
  label: 'Details',
  key: 'pharmacist',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: false,
  slugOptions: ['DOCTOR', 'DOCTOR_VIEW'],
  hasChild: false,
}
const OP_INCHARGE: RouterMenuProps = {
  id: 28,
  path: '/op_incharge',
  parent_id: null,
  icon: 'op',
  label: 'OP Incharge',
  key: 'op',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: false,
  slugOptions: ['OP_INCHARGE', 'OP_INCHARGE_VIEW'],
  hasChild: false,
}
const OP_INCHARGE_VIEW: RouterMenuProps = {
  id: 29,
  path: '/op_incharge/:id',
  parent_id: null,
  icon: 'op',
  label: 'OP Incharge',
  key: 'op',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: false,
  slugOptions: ['OP_INCHARGE', 'OP_INCHARGE_VIEW'],
  hasChild: false,
}

const HOSPITAL_LOAN_REQUEST: RouterMenuProps = {
  id: 30,
  path: '/loan/request',
  parent_id: null,
  icon: 'medicine-loan',
  label: 'Medicine Loan Requests',
  key: 'loan_request',
  pathOverride: '/loan/request/all',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: true,
  slugOptions: [
    'HOSPITAL_LOAN_REQUEST',
    'HOSPITAL_LOAN_REQUEST_ALL',
    'HOSPITAL_LOAN_REQUEST_NEW',
    'HOSPITAL_LOAN_REQUEST_ACCEPTED',
    'HOSPITAL_LOAN_REQUEST_REVERTED',
    'HOSPITAL_LOAN_REQUEST_APPROVED',
    'HOSPITAL_LOAN_REQUEST_DETAIL',
  ],
  hasChild: false,
}
const HOSPITAL_LOAN_REQUEST_ALL: RouterMenuProps = {
  id: 301,
  path: 'all',
  parent_id: 17,
  icon: 'medicine-loan',
  label: 'Loan Request',
  key: 'loan_request',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: false,
  slugOptions: [
    'HOSPITAL_LOAN_REQUEST',
    'HOSPITAL_LOAN_REQUEST_ALL',
    'HOSPITAL_LOAN_REQUEST_NEW',
    'HOSPITAL_LOAN_REQUEST_ACCEPTED',
    'HOSPITAL_LOAN_REQUEST_REVERTED',
    'HOSPITAL_LOAN_REQUEST_DETAIL',
  ],
  hasChild: false,
}
const HOSPITAL_LOAN_REQUEST_NEW: RouterMenuProps = {
  id: 302,
  path: 'new',
  parent_id: 17,
  icon: 'medicine-loan',
  label: 'Loan Request',
  key: 'loan_request',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: false,
  slugOptions: [
    'HOSPITAL_LOAN_REQUEST',
    'HOSPITAL_LOAN_REQUEST_ALL',
    'HOSPITAL_LOAN_REQUEST_NEW',
    'HOSPITAL_LOAN_REQUEST_ACCEPTED',
    'HOSPITAL_LOAN_REQUEST_REVERTED',
    'HOSPITAL_LOAN_REQUEST_DETAIL',
  ],
  hasChild: false,
}
const HOSPITAL_LOAN_REQUEST_ACCEPTED: RouterMenuProps = {
  id: 303,
  path: 'accepted',
  parent_id: 17,
  icon: 'medicine-loan',
  label: 'Loan Request',
  key: 'loan_request',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: false,
  slugOptions: [
    'HOSPITAL_LOAN_REQUEST',
    'HOSPITAL_LOAN_REQUEST_ALL',
    'HOSPITAL_LOAN_REQUEST_NEW',
    'HOSPITAL_LOAN_REQUEST_ACCEPTED',
    'HOSPITAL_LOAN_REQUEST_REVERTED',
    'HOSPITAL_LOAN_REQUEST_DETAIL',
  ],
  hasChild: false,
}
const HOSPITAL_LOAN_REQUEST_APPROVED: RouterMenuProps = {
  id: 303,
  path: 'approved',
  parent_id: 17,
  icon: 'medicine-loan',
  label: 'Loan Request',
  key: 'loan_request',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: false,
  slugOptions: [
    'HOSPITAL_LOAN_REQUEST',
    'HOSPITAL_LOAN_REQUEST_ALL',
    'HOSPITAL_LOAN_REQUEST_NEW',
    'HOSPITAL_LOAN_REQUEST_ACCEPTED',
    'HOSPITAL_LOAN_REQUEST_REVERTED',
    'HOSPITAL_LOAN_REQUEST_DETAIL',
    'HOSPITAL_LOAN_REQUEST_APPROVED',
  ],
  hasChild: false,
}
const HOSPITAL_LOAN_REQUEST_REVERTED: RouterMenuProps = {
  id: 304,
  path: 'reverted',
  parent_id: 17,
  icon: 'medicine-loan',
  label: 'Loan Request',
  key: 'loan_request',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: false,
  slugOptions: [
    'HOSPITAL_LOAN_REQUEST',
    'HOSPITAL_LOAN_REQUEST_ALL',
    'HOSPITAL_LOAN_REQUEST_NEW',
    'HOSPITAL_LOAN_REQUEST_ACCEPTED',
    'HOSPITAL_LOAN_REQUEST_REVERTED',
    'HOSPITAL_LOAN_REQUEST_DETAIL',
  ],
  hasChild: false,
}

const HOSPITAL_LOAN_REQUEST_DETAIL: RouterMenuProps = {
  id: 305,
  path: '/loan/request/:id/:id2',
  parent_id: 17,
  icon: 'medicine-loan',
  label: 'Loan Request Detail',
  key: 'loan_request',
  permission_slugs: ['Hospital Incharge'],
  isSidebarMenu: false,
  slugOptions: [
    'HOSPITAL_LOAN_REQUEST',
    'HOSPITAL_LOAN_REQUEST_ALL',
    'HOSPITAL_LOAN_REQUEST_NEW',
    'HOSPITAL_LOAN_REQUEST_ACCEPTED',
    'HOSPITAL_LOAN_REQUEST_REVERTED',
    'HOSPITAL_LOAN_REQUEST_DETAIL',
  ],
  hasChild: false,
}
const ADMIN_REPORTS: RouterMenuProps = {
  id: 19,
  path: '/report',
  icon: 'reports',
  parent_id: null,
  label: 'Reports',
  key: 'reports',
  permission_slugs: ['Super Admin'],
  isSidebarMenu: true,
  slugOptions: [
    'ADMIN_REPORTS',
    'ADMIN_REPORTS_PRIMARY',
    'ADMIN_REPORTS_SECONDARY',
    'ADMIN_REPORTS_PRIMARY_LIST',
    'ADMIN_REPORTS_SECONDARY_LIST',
    'ADMIN_REPORTS_MEDICINE_EXPIRE',
  ],
  hasChild: true,
}
const ADMIN_REPORTS_PRIMARY: RouterMenuProps = {
  id: 191,
  path: '/report/primary',
  icon: 'null',
  parent_id: 19,
  label: 'Primary Stock Report',
  key: 'Primary_stock_report',
  permission_slugs: ['Super Admin'],
  isSidebarMenu: true,
  slugOptions: [
    'ADMIN_REPORTS',
    'ADMIN_REPORTS_PRIMARY',
    'ADMIN_REPORTS_PRIMARY_LIST',
    'ADMIN_REPORTS_PRIMARY_BREADCRUMB',
  ],
  hasChild: false,
}

const ADMIN_REPORTS_SECONDARY: RouterMenuProps = {
  id: 192,
  path: '/report/secondary',
  icon: 'null',
  parent_id: 19,
  label: 'Secondary Stock Report',
  key: 'Secondary_stock_report',
  permission_slugs: ['Super Admin'],
  isSidebarMenu: true,
  slugOptions: [
    'ADMIN_REPORTS',
    'ADMIN_REPORTS_SECONDARY',
    'ADMIN_REPORTS_SECONDARY_LIST',
  ],
  hasChild: false,
}
const ADMIN_REPORTS_FUND: RouterMenuProps = {
  id: 192,
  path: '/report/fund',
  icon: 'null',
  parent_id: 19,
  label: 'Fund Usage Report',
  key: 'Fund Usage Report',
  permission_slugs: ['Super Admin'],
  isSidebarMenu: true,
  slugOptions: [
    'ADMIN_REPORTS',
    'ADMIN_REPORTS_FUND',
    'ADMIN_REPORTS_FUND_LIST',
    'ADMIN_REPORTS_FUND_BREADCRUMB',
  ],
  hasChild: false,
}
const ADMIN_REPORTS_MEDICINE_INTENT: RouterMenuProps = {
  id: 192,
  path: '/report/medicine-intent',
  icon: 'null',
  parent_id: 19,
  label: 'Medicine Indent Report',
  key: 'Fund Usage Report',
  permission_slugs: ['Super Admin'],
  isSidebarMenu: true,
  slugOptions: [
    'ADMIN_REPORTS',
    'ADMIN_REPORTS_MEDICINE_INTENT',
    'ADMIN_REPORTS_MEDICINE_INTENT_LIST',
    'ADMIN_REPORTS_MEDICINE_INTENT_DETAIL',
  ],
  hasChild: false,
}
const ADMIN_REPORTS_MEDICINE_INTENT_LIST: RouterMenuProps = {
  id: 192,
  path: '/report/medicine-intent/list',
  icon: 'null',
  parent_id: 19,
  label: 'Medicine indent report',
  key: 'Fund Usage Report',
  permission_slugs: ['Super Admin'],
  isSidebarMenu: false,
  slugOptions: [
    'ADMIN_REPORTS',
    'ADMIN_REPORTS_MEDICINE_INTENT',
    'ADMIN_REPORTS_MEDICINE_INTENT_LIST',
    'ADMIN_REPORTS_MEDICINE_INTENT_DETAIL',
  ],
  hasChild: false,
}
const ADMIN_REPORTS_MEDICINE_INTENT_DETAIL: RouterMenuProps = {
  id: 192,
  path: '/report/medicine-intent/list/:id',
  icon: 'null',
  parent_id: 19,
  label: 'Medicine indent report',
  key: 'Fund Usage Report',
  permission_slugs: ['Super Admin'],
  isSidebarMenu: false,
  slugOptions: [
    'ADMIN_REPORTS',
    'ADMIN_REPORTS_MEDICINE_INTENT',
    'ADMIN_REPORTS_MEDICINE_INTENT_LIST',
    'ADMIN_REPORTS_MEDICINE_INTENT_DETAIL',
  ],
  hasChild: false,
}
const ADMIN_REPORTS_MEDICINE_LOAN: RouterMenuProps = {
  id: 192,
  path: '/report/medicine-loan',
  icon: 'null',
  parent_id: 19,
  label: 'Medicine Loan Report',
  key: 'Medicine Loan Report',
  permission_slugs: ['Super Admin'],
  isSidebarMenu: true,
  slugOptions: [
    'ADMIN_REPORTS',
    'ADMIN_REPORTS_MEDICINE_LOAN',
    'ADMIN_REPORTS_MEDICINE_LOAN_LIST',
    'ADMIN_REPORTS_MEDICINE_LOAN_DETAIL',
  ],
  hasChild: false,
}
const ADMIN_REPORTS_MEDICINE_LOAN_LIST: RouterMenuProps = {
  id: 192,
  path: '/report/medicine-loan/list',
  icon: 'null',
  parent_id: 19,
  label: 'Medicine Loan Report',
  key: 'Medicine Loan Report',
  permission_slugs: ['Super Admin'],
  isSidebarMenu: false,
  slugOptions: [
    'ADMIN_REPORTS',
    'ADMIN_REPORTS_MEDICINE_LOAN',
    'ADMIN_REPORTS_MEDICINE_LOAN_LIST',
    'ADMIN_REPORTS_MEDICINE_LOAN_DETAIL',
  ],
  hasChild: false,
}
const ADMIN_REPORTS_MEDICINE_LOAN_DETAIL: RouterMenuProps = {
  id: 192,
  path: '/report/medicine-loan/list/:id',
  icon: 'null',
  parent_id: 19,
  label: 'Medicine Loan Report',
  key: 'Medicine Loan Report',
  permission_slugs: ['Super Admin'],
  isSidebarMenu: false,
  slugOptions: [
    'ADMIN_REPORTS',
    'ADMIN_REPORTS_MEDICINE_LOAN',
    'ADMIN_REPORTS_MEDICINE_LOAN_LIST',
    'ADMIN_REPORTS_MEDICINE_LOAN_DETAIL',
  ],
  hasChild: false,
}
const ADMIN_REPORTS_MEDICINE_EXPIRE: RouterMenuProps = {
  id: 192,
  path: '/report/medicine/expire',
  icon: 'null',
  parent_id: 19,
  label: 'Expiring Medicine',
  key: 'expiring_medicines',
  permission_slugs: ['Super Admin'],
  isSidebarMenu: true,
  slugOptions: [
    'ADMIN_REPORTS',
    'ADMIN_REPORTS_MEDICINE_EXPIRE',
    'ADMIN_REPORTS_MEDICINE_EXPIRE_LIST',
  ],
  hasChild: false,
}
const ADMIN_REPORTS_MEDICINE_EXPIRE_LIST: RouterMenuProps = {
  id: 192,
  path: '/report/medicine/expire/list',
  icon: 'null',
  parent_id: 19,
  label: 'Expiring Medicine',
  key: 'expiring_medicines',
  permission_slugs: ['Super Admin'],
  isSidebarMenu: false,
  slugOptions: ['ADMIN_REPORTS', 'ADMIN_REPORTS_MEDICINE_EXPIRE'],
  hasChild: false,
}

const ADMIN_REPORTS_PRIMARY_LIST: RouterMenuProps = {
  id: 193,
  path: '/report/primary/list',
  icon: 'null',
  parent_id: 19,
  label: 'Primary Stock Report',
  key: 'Primary_stock_report_list',
  permission_slugs: ['Super Admin'],
  isSidebarMenu: false,
  slugOptions: [
    'ADMIN_REPORTS',
    'ADMIN_REPORTS_PRIMARY',
    'ADMIN_REPORTS_PRIMARY_LIST',
    'ADMIN_REPORTS_PRIMARY_BREADCRUMB',
  ],
  hasChild: false,
}
const ADMIN_REPORTS_SECONDARY_LIST: RouterMenuProps = {
  id: 193,
  path: '/report/secondary/list',
  icon: 'null',
  parent_id: 19,
  label: 'Secondary Stock Report',
  key: 'Secondary_stock_report_list',
  permission_slugs: ['Super Admin'],
  isSidebarMenu: false,
  slugOptions: [
    'ADMIN_REPORTS',
    'ADMIN_REPORTS_SECONDARY',
    'ADMIN_REPORTS_SECONDARY_LIST',
    'ADMIN_REPORTS_SECONDARY_BREADCRUMB',
  ],
  hasChild: false,
}
const ADMIN_REPORTS_FUND_LIST: RouterMenuProps = {
  id: 193,
  path: '/report/funds/list',
  icon: 'null',
  parent_id: 19,
  label: 'Fund Usage And  Balance Report',
  key: 'Fund Usage And  Balance Report',
  permission_slugs: ['Super Admin'],
  isSidebarMenu: false,
  slugOptions: [
    'ADMIN_REPORTS',
    'ADMIN_REPORTS_FUND',
    'ADMIN_REPORTS_FUND_LIST',
    'ADMIN_REPORTS_FUND_BREADCRUMB',
  ],
  hasChild: false,
}

const PHARMACY_PATIENTS: RouterMenuProps = {
  id: 25,
  path: '/patient',
  parent_id: null,
  icon: 'patient',
  label: 'Patients',
  pathOverride: '/patient/all',
  key: 'patient',
  permission_slugs: ['Pharmacist'],
  isSidebarMenu: true,
  slugOptions: [
    'PHARMACY_PATIENTS',
    'PHARMACY_PATIENTS_ALL',
    'PHARMACY_PATIENTS_DISPENSED',
    'PHARMACY_PATIENTS_WAITING',
    'PHARMACY_PATIENTS_DETAIL',
    'PHARMACY_PATIENTS_ADD',
  ],
  hasChild: false,
}
const PHARMACY_PATIENTS_ALL: RouterMenuProps = {
  id: 251,
  path: 'all',
  parent_id: 13,
  icon: 'medicine',
  label: 'Primary Stock',
  key: 'Primary Stock',
  permission_slugs: ['Pharmacist'],
  isSidebarMenu: false,
  slugOptions: [
    'PHARMACY_PATIENTS',
    'PHARMACY_PATIENTS_ALL',
    'PHARMACY_PATIENTS_DISPENSED',
    'PHARMACY_PATIENTS_WAITING',
  ],
  hasChild: false,
}
const PHARMACY_PATIENTS_DISPENSED: RouterMenuProps = {
  id: 252,
  path: 'dispensed',
  parent_id: 13,
  icon: 'medicine',
  label: 'Secondary Stock',
  key: 'Secondary Stock',
  permission_slugs: ['Pharmacist'],
  isSidebarMenu: false,
  slugOptions: [
    'PHARMACY_PATIENTS',
    'PHARMACY_PATIENTS_ALL',
    'PHARMACY_PATIENTS_DISPENSED',
    'PHARMACY_PATIENTS_WAITING',
  ],
  hasChild: false,
}
const PHARMACY_PATIENTS_WAITING: RouterMenuProps = {
  id: 253,
  path: 'waiting',
  parent_id: 13,
  icon: 'medicine',
  label: 'Secondary Stock',
  key: 'Secondary Stock',
  permission_slugs: ['Pharmacist'],
  isSidebarMenu: false,
  slugOptions: [
    'PHARMACY_PATIENTS',
    'PHARMACY_PATIENTS_ALL',
    'PHARMACY_PATIENTS_DISPENSED',
    'PHARMACY_PATIENTS_WAITING',
  ],
  hasChild: false,
}
const PHARMACY_PATIENTS_DETAIL: RouterMenuProps = {
  id: 253,
  path: '/patient/:id',
  parent_id: 13,
  icon: 'medicine',
  label: 'Secondary Stock',
  key: 'Secondary Stock',
  permission_slugs: ['Pharmacist'],
  isSidebarMenu: false,
  slugOptions: [
    'PHARMACY_PATIENTS',
    'PHARMACY_PATIENTS_ALL',
    'PHARMACY_PATIENTS_DISPENSED',
    'PHARMACY_PATIENTS_WAITING',
    'PHARMACY_PATIENTS_DETAIL',
  ],
  hasChild: false,
}
const PHARMACY_PATIENTS_ADD: RouterMenuProps = {
  id: 253,
  path: '/patient/new/prescription',
  parent_id: 13,
  icon: 'medicine',
  label: 'Secondary Stock',
  key: 'Secondary Stock',
  permission_slugs: ['Pharmacist'],
  isSidebarMenu: false,
  slugOptions: [
    'PHARMACY_PATIENTS',
    'PHARMACY_PATIENTS_ALL',
    'PHARMACY_PATIENTS_DISPENSED',
    'PHARMACY_PATIENTS_WAITING',
    'PHARMACY_PATIENTS_DETAIL',
    'PHARMACY_PATIENTS_ADD',
  ],
  hasChild: false,
}
const PHARMACY_PATIENTS_ADD_MAIN: RouterMenuProps = {
  id: 253,
  path: '/patient/new/prescription/:id2',
  parent_id: 13,
  icon: 'medicine',
  label: 'Secondary Stock',
  key: 'Secondary Stock',
  permission_slugs: ['Pharmacist'],
  isSidebarMenu: false,
  slugOptions: [
    'PHARMACY_PATIENTS',
    'PHARMACY_PATIENTS_ALL',
    'PHARMACY_PATIENTS_DISPENSED',
    'PHARMACY_PATIENTS_WAITING',
    'PHARMACY_PATIENTS_DETAIL',
    'PHARMACY_PATIENTS_ADD',
  ],
  hasChild: false,
}

const DOCTOR_PATIENTS: RouterMenuProps = {
  id: 25,
  path: '/patient/list',
  parent_id: null,
  icon: 'consult',
  label: 'Consultation',
  pathOverride: '/patient/list/all',
  key: 'patient',
  permission_slugs: ['Doctor'],
  isSidebarMenu: true,
  slugOptions: [
    'DOCTOR_PATIENTS',
    'DOCTOR_PATIENTS_ALL',
    'DOCTOR_PATIENTS_DISPENSED',
    'DOCTOR_PATIENTS_WAITING',
    'DOCTOR_PATIENTS_DETAIL',
    'DOCTOR_PATIENTS_ADD',
    'DOCTOR_PATIENTS_GENERAL',
    'DOCTOR_PATIENTS_PREVIOUS',
    'DOCTOR_PATIENTS_PRESCRIPTION',
    'DOCTOR_PATIENTS_PREVIOUS_DETAIL',
    'DOCTOR_PATIENTS_PRESCRIPTION_CURRENT',
    'DOCTOR_PATIENTS_PRESCRIPTION_OUTSIDE',
    'DOCTOR_PATIENTS_DETAIL_LIST',
    'DOCTOR_PATIENTS_PRESCRIPTION_CURRENT',
  ],
  hasChild: false,
}
const DOCTOR_PATIENTS_ALL: RouterMenuProps = {
  id: 251,
  path: 'all',
  parent_id: 13,
  icon: 'medicine',
  label: 'Primary Stock',
  key: 'Primary Stock',
  permission_slugs: ['Doctor'],
  isSidebarMenu: false,
  slugOptions: [
    'DOCTOR_PATIENTS',
    'DOCTOR_PATIENTS_ALL',
    'DOCTOR_PATIENTS_DISPENSED',
    'DOCTOR_PATIENTS_WAITING',
  ],
  hasChild: false,
}
const DOCTOR_PATIENTS_DISPENSED: RouterMenuProps = {
  id: 252,
  path: 'dispensed',
  parent_id: 13,
  icon: 'medicine',
  label: 'Secondary Stock',
  key: 'Secondary Stock',
  permission_slugs: ['Doctor'],
  isSidebarMenu: false,
  slugOptions: [
    'DOCTOR_PATIENTS',
    'DOCTOR_PATIENTS_ALL',
    'DOCTOR_PATIENTS_DISPENSED',
    'DOCTOR_PATIENTS_WAITING',
  ],
  hasChild: false,
}
const DOCTOR_PATIENTS_WAITING: RouterMenuProps = {
  id: 253,
  path: 'waiting',
  parent_id: 13,
  icon: 'medicine',
  label: 'Secondary Stock',
  key: 'Secondary Stock',
  permission_slugs: ['Doctor'],
  isSidebarMenu: false,
  slugOptions: [
    'DOCTOR_PATIENTS',
    'DOCTOR_PATIENTS_ALL',
    'DOCTOR_PATIENTS_DISPENSED',
    'DOCTOR_PATIENTS_WAITING',
  ],
  hasChild: false,
}
const DOCTOR_PATIENTS_DETAIL: RouterMenuProps = {
  id: 254,
  path: '/patient/list/:id',
  parent_id: null,
  icon: 'medicine',
  label: 'Secondary Stock',
  // pathOverride: '/patient/list/general',
  key: 'Secondary Stock',
  permission_slugs: ['Doctor'],
  isSidebarMenu: false,
  slugOptions: [
    'DOCTOR_PATIENTS',
    'DOCTOR_PATIENTS_ALL',
    'DOCTOR_PATIENTS_DISPENSED',
    'DOCTOR_PATIENTS_WAITING',
    'DOCTOR_PATIENTS_DETAIL',
    'DOCTOR_PATIENTS_GENERAL',
    'DOCTOR_PATIENTS_PREVIOUS',
    'DOCTOR_PATIENTS_PRESCRIPTION',
  ],
  hasChild: false,
}
const DOCTOR_PATIENTS_DETAIL_LIST: RouterMenuProps = {
  id: 255,
  path: '/patient/list/:id/:id2',
  parent_id: null,
  icon: 'medicine',
  label: 'Secondary Stock',
  // pathOverride: '/patient/list/general',
  key: 'Secondary Stock',
  permission_slugs: ['Doctor'],
  isSidebarMenu: false,
  slugOptions: [
    'DOCTOR_PATIENTS',
    'DOCTOR_PATIENTS_ALL',
    'DOCTOR_PATIENTS_DISPENSED',
    'DOCTOR_PATIENTS_WAITING',
    'DOCTOR_PATIENTS_DETAIL',
    'DOCTOR_PATIENTS_GENERAL',
    'DOCTOR_PATIENTS_PREVIOUS',
    'DOCTOR_PATIENTS_PRESCRIPTION',
  ],
  hasChild: false,
}
const DOCTOR_PATIENTS_ADD: RouterMenuProps = {
  id: 256,
  path: '/patient/new/prescription',
  parent_id: null,
  icon: 'medicine',
  label: 'Secondary Stock',
  key: 'Secondary Stock',
  permission_slugs: ['Doctor'],
  isSidebarMenu: false,
  slugOptions: [
    'DOCTOR_PATIENTS',
    'DOCTOR_PATIENTS_ALL',
    'DOCTOR_PATIENTS_DISPENSED',
    'DOCTOR_PATIENTS_WAITING',
    'DOCTOR_PATIENTS_DETAIL',
    'DOCTOR_PATIENTS_ADD',
    'DOCTOR_PATIENTS_GENERAL',
    'DOCTOR_PATIENTS_PREVIOUS',
    'DOCTOR_PATIENTS_PRESCRIPTION',
  ],
  hasChild: false,
}
const DOCTOR_PATIENTS_GENERAL: RouterMenuProps = {
  id: 257,
  path: 'general',
  parent_id: 26,
  icon: 'medicine',
  label: 'All Patients',
  key: 'patients',
  permission_slugs: ['Doctor'],
  isSidebarMenu: false,
  slugOptions: [
    'DOCTOR_PATIENTS',
    'DOCTOR_PATIENTS_ALL',
    'DOCTOR_PATIENTS_DISPENSED',
    'DOCTOR_PATIENTS_WAITING',
    'DOCTOR_PATIENTS_DETAIL',
    'DOCTOR_PATIENTS_ADD',
    'DOCTOR_PATIENTS_GENERAL',
    'DOCTOR_PATIENTS_PREVIOUS',
    'DOCTOR_PATIENTS_PRESCRIPTION',
  ],
  hasChild: false,
}
const DOCTOR_PATIENTS_PRESCRIPTION: RouterMenuProps = {
  id: 258,
  path: 'prescription',
  parent_id: 26,
  icon: 'medicine',
  label: 'Prescription',
  // pathOverride: 'prescription/current',
  key: 'patients',
  permission_slugs: ['Doctor'],
  isSidebarMenu: false,
  slugOptions: [
    'DOCTOR_PATIENTS',
    'DOCTOR_PATIENTS_ALL',
    'DOCTOR_PATIENTS_DISPENSED',
    'DOCTOR_PATIENTS_WAITING',
    'DOCTOR_PATIENTS_DETAIL',
    'DOCTOR_PATIENTS_ADD',
    'DOCTOR_PATIENTS_GENERAL',
    'DOCTOR_PATIENTS_PREVIOUS',
    'DOCTOR_PATIENTS_PRESCRIPTION',
    'DOCTOR_PATIENTS_PREVIOUS_DETAIL',
    'DOCTOR_PATIENTS_PRESCRIPTION_OUTSIDE',
    'DOCTOR_PATIENTS_PRESCRIPTION_CURRENT',
  ],
  hasChild: true,
}
const DOCTOR_PATIENTS_PRESCRIPTION_CURRENT: RouterMenuProps = {
  id: 259,
  path: 'current',
  // path: 'current',
  parent_id: 26,
  icon: 'medicine',
  label: 'Prescription',
  key: 'current',
  permission_slugs: ['Doctor'],
  isSidebarMenu: false,
  slugOptions: [
    'DOCTOR_PATIENTS',
    'DOCTOR_PATIENTS_ALL',
    'DOCTOR_PATIENTS_DISPENSED',
    'DOCTOR_PATIENTS_WAITING',
    'DOCTOR_PATIENTS_DETAIL',
    'DOCTOR_PATIENTS_ADD',
    'DOCTOR_PATIENTS_GENERAL',
    'DOCTOR_PATIENTS_PREVIOUS',
    'DOCTOR_PATIENTS_PRESCRIPTION',
    'DOCTOR_PATIENTS_PREVIOUS_DETAIL',
    'DOCTOR_PATIENTS_PRESCRIPTION_OUTSIDE',
    'DOCTOR_PATIENTS_PRESCRIPTION_CURRENT',
  ],
  hasChild: false,
}
const DOCTOR_PATIENTS_PRESCRIPTION_OUTSIDE: RouterMenuProps = {
  id: 2801,
  path: 'outside',
  parent_id: 25,
  icon: 'medicine',
  label: 'Prescription',
  key: 'patients',
  permission_slugs: ['Doctor'],
  isSidebarMenu: false,
  slugOptions: [
    'DOCTOR_PATIENTS',
    'DOCTOR_PATIENTS_ALL',
    'DOCTOR_PATIENTS_DISPENSED',
    'DOCTOR_PATIENTS_WAITING',
    'DOCTOR_PATIENTS_DETAIL',
    'DOCTOR_PATIENTS_ADD',
    'DOCTOR_PATIENTS_GENERAL',
    'DOCTOR_PATIENTS_PREVIOUS',
    'DOCTOR_PATIENTS_PRESCRIPTION',
    'DOCTOR_PATIENTS_PREVIOUS_DETAIL',
    'DOCTOR_PATIENTS_PRESCRIPTION_OUTSIDE',
  ],
  hasChild: false,
}
const DOCTOR_PATIENTS_PREVIOUS: RouterMenuProps = {
  id: 26,
  path: 'previous',
  parent_id: null,
  icon: 'medicine',
  label: 'Previous',
  key: 'Secondary Stock',
  permission_slugs: ['Doctor'],
  isSidebarMenu: false,
  slugOptions: [
    'DOCTOR_PATIENTS',
    'DOCTOR_PATIENTS_ALL',
    'DOCTOR_PATIENTS_DISPENSED',
    'DOCTOR_PATIENTS_WAITING',
    'DOCTOR_PATIENTS_DETAIL',
    'DOCTOR_PATIENTS_ADD',
    'DOCTOR_PATIENTS_GENERAL',
    'DOCTOR_PATIENTS_PREVIOUS',
    'DOCTOR_PATIENTS_PRESCRIPTION',
    'DOCTOR_PATIENTS_PREVIOUS_DETAIL',
  ],
  hasChild: false,
}
const DOCTOR_PATIENTS_PREVIOUS_DETAIL: RouterMenuProps = {
  id: 26,
  path: '/patient/list/:id/:id2/:id3/:id4',
  parent_id: null,
  icon: 'medicine',
  label: 'Secondary Stock',
  // pathOverride: '/patient/list/general',
  key: 'Secondary Stock',
  permission_slugs: ['Doctor'],
  isSidebarMenu: false,
  slugOptions: [
    'DOCTOR_PATIENTS',
    'DOCTOR_PATIENTS_ALL',
    'DOCTOR_PATIENTS_DISPENSED',
    'DOCTOR_PATIENTS_WAITING',
    'DOCTOR_PATIENTS_DETAIL',
    'DOCTOR_PATIENTS_GENERAL',
    'DOCTOR_PATIENTS_PREVIOUS',
    'DOCTOR_PATIENTS_PRESCRIPTION',
    'DOCTOR_PATIENTS_PREVIOUS_DETAIL',
  ],
  hasChild: false,
}
const DISTRICT_ADMIN_LOAN: RouterMenuProps = {
  id: 29,
  path: '/loans',
  icon: 'medicine_issue',
  parent_id: null,
  label: 'Loan',
  key: 'loan',
  permission_slugs: ['District Admin'],
  isSidebarMenu: true,
  slugOptions: [
    'DISTRICT_ADMIN_LOAN',
    'DISTRICT_ADMIN_LOAN_REQUEST',
    'DISTRICT_ADMIN_LOAN_RESPOND',
    'DISTRICT_ADMIN_LOAN_REQUEST_ALL',
    'DISTRICT_ADMIN_LOAN_REQUEST_APPROVED',
    'DISTRICT_ADMIN_LOAN_REQUEST_NEW',
    'DISTRICT_ADMIN_LOAN_REQUEST_FORWARD',
    'DISTRICT_ADMIN_LOAN_REQUEST_ACCEPTED',
    'DISTRICT_ADMIN_LOAN_REQUEST_DETAIL',
    'DISTRICT_ADMIN_LOAN_REQUEST_DETAIL_HOSPITAL',
  ],
  hasChild: true,
}
const DISTRICT_ADMIN_LOAN_REQUEST: RouterMenuProps = {
  id: 291,
  path: '/loans/request',
  icon: 'null',
  parent_id: 29,
  label: 'Request From Hospitals ',
  pathOverride: '/loans/request/all',
  key: 'Request from hospitals ',
  permission_slugs: ['District Admin'],
  isSidebarMenu: true,
  slugOptions: [
    'DISTRICT_ADMIN_LOAN',
    'DISTRICT_ADMIN_LOAN_REQUEST',
    'DISTRICT_ADMIN_LOAN_REQUEST_APPROVED',
    'DISTRICT_ADMIN_LOAN_REQUEST_NEW',
    'DISTRICT_ADMIN_LOAN_REQUEST_FORWARD',
    'DISTRICT_ADMIN_LOAN_REQUEST_ACCEPTED',
    'DISTRICT_ADMIN_LOAN_REQUEST_DETAIL',
    'DISTRICT_ADMIN_LOAN_REQUEST_DETAIL_HOSPITAL',
  ],
  hasChild: false,
}
const DISTRICT_ADMIN_LOAN_REQUEST_ALL: RouterMenuProps = {
  id: 291,
  path: 'all',
  icon: 'null',
  parent_id: null,
  label: 'Request from hospitals ',
  key: 'Request from hospitals ',
  permission_slugs: ['District Admin'],
  isSidebarMenu: false,
  slugOptions: [
    'DISTRICT_ADMIN_LOAN',
    'DISTRICT_ADMIN_LOAN_REQUEST',
    'DISTRICT_ADMIN_LOAN_REQUEST_APPROVED',
    'DISTRICT_ADMIN_LOAN_REQUEST_NEW',
    'DISTRICT_ADMIN_LOAN_REQUEST_FORWARD',
    'DISTRICT_ADMIN_LOAN_REQUEST_ACCEPTED',
    'DISTRICT_ADMIN_LOAN_REQUEST_DETAIL',
    'DISTRICT_ADMIN_LOAN_REQUEST_DETAIL_HOSPITAL',
  ],
  hasChild: false,
}
const DISTRICT_ADMIN_LOAN_REQUEST_NEW: RouterMenuProps = {
  id: 291,
  path: 'new',
  icon: 'null',
  parent_id: null,
  label: 'Request from hospitals ',
  key: 'Request from hospitals ',
  permission_slugs: ['District Admin'],
  isSidebarMenu: false,
  slugOptions: [
    'DISTRICT_ADMIN_LOAN',
    'DISTRICT_ADMIN_LOAN_REQUEST',
    'DISTRICT_ADMIN_LOAN_REQUEST_APPROVED',
    'DISTRICT_ADMIN_LOAN_REQUEST_NEW',
    'DISTRICT_ADMIN_LOAN_REQUEST_FORWARD',
    'DISTRICT_ADMIN_LOAN_REQUEST_ACCEPTED',
    'DISTRICT_ADMIN_LOAN_REQUEST_DETAIL',
    'DISTRICT_ADMIN_LOAN_REQUEST_DETAIL_HOSPITAL',
  ],
  hasChild: false,
}
const DISTRICT_ADMIN_LOAN_REQUEST_FORWARD: RouterMenuProps = {
  id: 291,
  path: 'forwarded',
  icon: 'null',
  parent_id: null,
  label: 'Request from hospitals ',
  key: 'Request from hospitals ',
  permission_slugs: ['District Admin'],
  isSidebarMenu: false,
  slugOptions: [
    'DISTRICT_ADMIN_LOAN',
    'DISTRICT_ADMIN_LOAN_REQUEST',
    'DISTRICT_ADMIN_LOAN_REQUEST_APPROVED',
    'DISTRICT_ADMIN_LOAN_REQUEST_NEW',
    'DISTRICT_ADMIN_LOAN_REQUEST_FORWARD',
    'DISTRICT_ADMIN_LOAN_REQUEST_ACCEPTED',
    'DISTRICT_ADMIN_LOAN_REQUEST_DETAIL',
    'DISTRICT_ADMIN_LOAN_REQUEST_DETAIL_HOSPITAL',
  ],
  hasChild: false,
}
const DISTRICT_ADMIN_LOAN_REQUEST_ACCEPTED: RouterMenuProps = {
  id: 291,
  path: 'delivered',
  icon: 'null',
  parent_id: null,
  label: 'Request from hospitals ',
  key: 'Request from hospitals ',
  permission_slugs: ['District Admin'],
  isSidebarMenu: false,
  slugOptions: [
    'DISTRICT_ADMIN_LOAN',
    'DISTRICT_ADMIN_LOAN_REQUEST',
    'DISTRICT_ADMIN_LOAN_REQUEST_APPROVED',
    'DISTRICT_ADMIN_LOAN_REQUEST_NEW',
    'DISTRICT_ADMIN_LOAN_REQUEST_FORWARD',
    'DISTRICT_ADMIN_LOAN_REQUEST_ACCEPTED',
    'DISTRICT_ADMIN_LOAN_REQUEST_DETAIL',
    'DISTRICT_ADMIN_LOAN_REQUEST_DETAIL_HOSPITAL',
  ],
  hasChild: false,
}
const DISTRICT_ADMIN_LOAN_REQUEST_APPROVED: RouterMenuProps = {
  id: 291,
  path: 'approved',
  icon: 'null',
  parent_id: null,
  label: 'Request from hospitals ',
  key: 'Request from hospitals ',
  permission_slugs: ['District Admin'],
  isSidebarMenu: false,
  slugOptions: [
    'DISTRICT_ADMIN_LOAN',
    'DISTRICT_ADMIN_LOAN_REQUEST',
    'DISTRICT_ADMIN_LOAN_REQUEST_ALL',
    'DISTRICT_ADMIN_LOAN_REQUEST_APPROVED',
    'DISTRICT_ADMIN_LOAN_REQUEST_NEW',
    'DISTRICT_ADMIN_LOAN_REQUEST_FORWARD',
    'DISTRICT_ADMIN_LOAN_REQUEST_ACCEPTED',
    'DISTRICT_ADMIN_LOAN_REQUEST_DETAIL',
    'DISTRICT_ADMIN_LOAN_REQUEST_DETAIL_HOSPITAL',
  ],
  hasChild: false,
}
const DISTRICT_ADMIN_LOAN_REQUEST_DETAIL: RouterMenuProps = {
  id: 175,
  path: '/loans/request/:id/:id2',
  parent_id: 17,
  icon: 'order',
  label: 'View List',
  key: 'order_request',
  permission_slugs: ['District Admin'],
  isSidebarMenu: false,
  slugOptions: [
    'DISTRICT_ADMIN_LOAN',
    'DISTRICT_ADMIN_LOAN_REQUEST',
    'DISTRICT_ADMIN_LOAN_REQUEST_ALL',
    'DISTRICT_ADMIN_LOAN_REQUEST_APPROVED',
    'DISTRICT_ADMIN_LOAN_REQUEST_NEW',
    'DISTRICT_ADMIN_LOAN_REQUEST_FORWARD',
    'DISTRICT_ADMIN_LOAN_REQUEST_ACCEPTED',
    'DISTRICT_ADMIN_LOAN_REQUEST_DETAIL',
    'DISTRICT_ADMIN_LOAN_REQUEST_DETAIL_HOSPITAL',
  ],
  hasChild: false,
}
const DISTRICT_ADMIN_LOAN_REQUEST_DETAIL_HOSPITAL: RouterMenuProps = {
  id: 175,
  path: '/loans/request/:id/:id2/hospitals',
  parent_id: 17,
  icon: 'order',
  label: 'View List',
  key: 'order_request',
  permission_slugs: ['District Admin'],
  isSidebarMenu: false,
  slugOptions: [
    'DISTRICT_ADMIN_LOAN',
    'DISTRICT_ADMIN_LOAN_REQUEST',
    'DISTRICT_ADMIN_LOAN_REQUEST_ALL',
    'DISTRICT_ADMIN_LOAN_REQUEST_APPROVED',
    'DISTRICT_ADMIN_LOAN_REQUEST_NEW',
    'DISTRICT_ADMIN_LOAN_REQUEST_FORWARD',
    'DISTRICT_ADMIN_LOAN_REQUEST_ACCEPTED',
    'DISTRICT_ADMIN_LOAN_REQUEST_DETAIL',
    'DISTRICT_ADMIN_LOAN_REQUEST_DETAIL_HOSPITAL',
  ],
  hasChild: false,
}

const DISTRICT_ADMIN_LOAN_RESPOND: RouterMenuProps = {
  id: 292,
  path: '/loans/response',
  icon: 'null',
  parent_id: 29,
  pathOverride: '/loans/response/all',
  label: 'Respond From Hospitals',
  key: 'Respond From Hospitals ',
  permission_slugs: ['District Admin'],
  isSidebarMenu: true,
  slugOptions: [
    'DISTRICT_ADMIN_LOAN',
    'DISTRICT_ADMIN_LOAN_RESPOND',
    'DISTRICT_ADMIN_LOAN_RESPONSE_ALL',
    'DISTRICT_ADMIN_LOAN_RESPONSE_DETAIL',
    'DISTRICT_ADMIN_LOAN_RESPONSE_ACCEPTED',
    'DISTRICT_ADMIN_LOAN_RESPONSE_REVERTED',
  ],
  hasChild: false,
}
const DISTRICT_ADMIN_LOAN_RESPONSE_ALL: RouterMenuProps = {
  id: 291,
  path: 'all',
  icon: 'null',
  parent_id: null,
  label: 'Request From Hospitals ',
  key: 'Request from hospitals ',
  permission_slugs: ['District Admin'],
  isSidebarMenu: false,
  slugOptions: [
    'DISTRICT_ADMIN_LOAN',
    'DISTRICT_ADMIN_LOAN_RESPOND',
    'DISTRICT_ADMIN_LOAN_RESPONSE_ALL',
    'DISTRICT_ADMIN_LOAN_RESPONSE_DETAIL',
    'DISTRICT_ADMIN_LOAN_RESPONSE_ACCEPTED',
    'DISTRICT_ADMIN_LOAN_RESPONSE_REVERTED',
  ],

  hasChild: false,
}
const DISTRICT_ADMIN_LOAN_RESPONSE_ACCEPTED: RouterMenuProps = {
  id: 291,
  path: 'accepted',
  icon: 'null',
  parent_id: null,
  label: 'Request from hospitals ',
  key: 'Request from hospitals ',
  permission_slugs: ['District Admin'],
  isSidebarMenu: false,
  slugOptions: [
    'DISTRICT_ADMIN_LOAN',
    'DISTRICT_ADMIN_LOAN_RESPOND',
    'DISTRICT_ADMIN_LOAN_RESPONSE_ALL',
    'DISTRICT_ADMIN_LOAN_RESPONSE_DETAIL',
    'DISTRICT_ADMIN_LOAN_RESPONSE_ACCEPTED',
    'DISTRICT_ADMIN_LOAN_RESPONSE_REVERTED',
  ],

  hasChild: false,
}
const DISTRICT_ADMIN_LOAN_RESPONSE_REVERTED: RouterMenuProps = {
  id: 291,
  path: 'reverted',
  icon: 'null',
  parent_id: null,
  label: 'Request from hospitals ',
  key: 'Request from hospitals ',
  permission_slugs: ['District Admin'],
  isSidebarMenu: false,
  slugOptions: [
    'DISTRICT_ADMIN_LOAN',
    'DISTRICT_ADMIN_LOAN_RESPOND',
    'DISTRICT_ADMIN_LOAN_RESPONSE_ALL',
    'DISTRICT_ADMIN_LOAN_RESPONSE_DETAIL',
    'DISTRICT_ADMIN_LOAN_RESPONSE_ACCEPTED',
    'DISTRICT_ADMIN_LOAN_RESPONSE_REVERTED',
  ],

  hasChild: false,
}
const DISTRICT_ADMIN_LOAN_RESPONSE_DETAIL: RouterMenuProps = {
  id: 175,
  path: '/loans/response/:id/:id2/:id3',
  parent_id: 17,
  icon: 'order',
  label: 'View List',
  key: 'order_request',
  permission_slugs: ['District Admin'],
  isSidebarMenu: false,
  slugOptions: [
    'DISTRICT_ADMIN_LOAN',
    'DISTRICT_ADMIN_LOAN_RESPOND',
    'DISTRICT_ADMIN_LOAN_RESPONSE_ALL',
    'DISTRICT_ADMIN_LOAN_RESPONSE_DETAIL',
  ],
  hasChild: false,
}
// const CONTACT_MERGE: RouterMenuProps = {

// Note : The arrangements of below "router_config" object will reflect in Sidebar
export const router_config: { [key: string]: RouterMenuProps } = {
  LOGIN,
  DASHBOARD,
  // CONTACTS,
  DISTRICT_ADMIN,
  DISTRICT_ADMIN_CREATE,
  DISTRICT_ADMIN_VIEW,
  INSTITUTIONS,
  INSTITUTIONS_CREATE,
  INSTITUTIONS_VIEW,
  MANUFACTURER,
  MANUFACTURER_VIEW,
  MANUFACTURER_CREATE,
  DOCTOR,
  DOCTOR_VIEW,
  PHARMACIST,
  PHARMACIST_VIEW,
  OP_INCHARGE,
  OP_INCHARGE_VIEW,
  STOCK,
  STOCK_PRIMARY,
  STOCK_SECONDARY,
  HOSPITAL_LOAN_REQUEST,
  HOSPITAL_LOAN_REQUEST_ALL,
  HOSPITAL_LOAN_REQUEST_NEW,
  HOSPITAL_LOAN_REQUEST_ACCEPTED,
  HOSPITAL_LOAN_REQUEST_REVERTED,
  HOSPITAL_LOAN_REQUEST_DETAIL,
  PHARMACY_PATIENTS,
  PHARMACY_PATIENTS_ALL,
  PHARMACY_PATIENTS_DISPENSED,
  PHARMACY_PATIENTS_WAITING,
  OP_PATIENTS,
  OP_PATIENTS_ALL,
  OP_PATIENTS_ALL_ADD_NEW,
  OP_PATIENTS_WAITING,
  OP_PATIENTS_EXAMINED,
  DOCTOR_PATIENTS,
  DOCTOR_PATIENTS_ALL,
  DOCTOR_PATIENTS_DISPENSED,
  DOCTOR_PATIENTS_WAITING,
  MEDICINE,
  MEDICINE_PRIMARY,
  MEDICINE_SECONDARY,
  MANUFACTURER_STOCK,
  MANUFACTURER_ORDER_REQUEST,
  PENDING_ORDER,
  CONFIRMED_ORDER,
  DELIVERED_ORDER,
  REVERTED_ORDER,
  REJECTED_ORDER,
  MANUFACTURER_ORDER_REQUEST_DETAIL,
  HOSPITAL_ORDERS_MAIN,
  HOSPITAL_PENDING_ORDER,
  HOSPITAL_CONFIRMED_ORDER,
  HOSPITAL_DELIVERED_ORDER,
  HOSPITAL_REVERTED_ORDER,
  HOSPITAL_REJECTED_ORDER,
  HOSPITAL_REPLACED_ORDER,
  HOSPITAL_REPLACED_ORDER_DETAIL,
  HOSPITAL_PENDING_ORDER_DETAIL,
  HOSPITAL_CONFIRMED_ORDER_DETAIL,
  HOSPITAL_DELIVERED_ORDER_DETAIL,
  HOSPITAL_REVERTED_ORDER_DETAIL,
  HOSPITAL_REJECTED_ORDER_DETAIL,
  HOSPITAL_ORDER_REQUEST_DETAIL,
  HOSPITAL_ORDERS,
  HOSPITAL_ORDERS_DMO,
  HOSPITAL_PENDING_ORDER_DMO,
  HOSPITAL_CONFIRMED_ORDER_DMO,
  HOSPITAL_REPLACED_ORDER_DMO,
  HOSPITAL_DELIVERED_ORDER_DMO,
  HOSPITAL_REVERTED_ORDER_DMO,
  HOSPITAL_REJECTED_ORDER_DMO,
  HOSPITAL_PENDING_ORDER_DETAIL_DMO,
  HOSPITAL_CONFIRMED_ORDER_DETAIL_DMO,
  HOSPITAL_REPLACED_ORDER_DETAIL_DMO,
  HOSPITAL_DELIVERED_ORDER_DETAIL_DMO,
  HOSPITAL_REVERTED_ORDER_DETAIL_DMO,
  HOSPITAL_REJECTED_ORDER_DETAIL_DMO,
  HOSPITAL_ORDER_REQUEST_DETAIL_DMO,
  HOSPITAL_ORDERS_LOAN,
  HOSPITAL_PENDING_ORDER_LOAN,
  HOSPITAL_CONFIRMED_ORDER_LOAN,
  HOSPITAL_DELIVERED_ORDER_LOAN,
  HOSPITAL_REVERTED_ORDER_LOAN,
  HOSPITAL_REJECTED_ORDER_LOAN,
  HOSPITAL_PENDING_ORDER_DETAIL_LOAN,
  HOSPITAL_CONFIRMED_ORDER_DETAIL_LOAN,
  HOSPITAL_DELIVERED_ORDER_DETAIL_LOAN,
  HOSPITAL_REVERTED_ORDER_DETAIL_LOAN,
  HOSPITAL_REJECTED_ORDER_DETAIL_LOAN,
  HOSPITAL_ORDER_REQUEST_DETAIL_LOAN,
  HOSPITAL_MEDICINE_ISSUE,
  HOSPITAL_MEDICINE_ISSUE_SECONDARY,
  HOSPITAL_MEDICINE_REQUEST,
  HOSPITAL_PURCHASE_PRODUCT,
  HOSPITAL_PURCHASE_MEDICINE,
  MANUFACTURER_ORDER_REQUEST_DETAIL_REPLACED,
  HOSPITAL_MEDICINE_LOAN,
  DISTRICT_MEDICAL,
  DISTRICT_MEDICAL_CREATE,
  DISTRICT_MEDICAL_VIEW,
  FUND_MAPPING,
  ADMIN_FUND_MAPPING,
  ADMIN_FUND_MAPPING_PREVIOUS,
  REPORTS,
  REPORTS_PRIMARY,
  REPORTS_PRIMARY_BREADCRUMB,
  REPORTS_SECONDARY,
  REPORTS_SECONDARY_BREADCRUMB,
  REPORTS_PRIMARY_LIST,
  REPORTS_SECONDARY_LIST,
  REPORTS_MEDICINE_EXPIRE,
  REPORTS_FUND,
  REPORTS_FUND_BREADCRUMB,
  REPORTS_FUND_LIST,
  REPORTS_MEDICINE_INTENT,
  REPORTS_MEDICINE_INTENT_LIST,
  REPORTS_MEDICINE_INTENT_DETAIL,
  REPORTS_MEDICINE_LOAN,
  REPORTS_MEDICINE_LOAN_LIST,
  REPORTS_MEDICINE_LOAN_DETAIL,
  ADMIN_REPORTS,
  ADMIN_REPORTS_PRIMARY,
  ADMIN_REPORTS_SECONDARY,
  ADMIN_REPORTS_PRIMARY_LIST,
  ADMIN_REPORTS_SECONDARY_LIST,
  ADMIN_REPORTS_MEDICINE_EXPIRE,
  ADMIN_REPORTS_MEDICINE_EXPIRE_LIST,
  ADMIN_REPORTS_FUND,
  ADMIN_REPORTS_FUND_LIST,
  ADMIN_REPORTS_MEDICINE_INTENT,
  ADMIN_REPORTS_MEDICINE_INTENT_LIST,
  ADMIN_REPORTS_MEDICINE_INTENT_DETAIL,
  ADMIN_REPORTS_MEDICINE_LOAN,
  ADMIN_REPORTS_MEDICINE_LOAN_LIST,
  ADMIN_REPORTS_MEDICINE_LOAN_DETAIL,
  PHARMACY_PATIENTS_DETAIL,
  PHARMACY_PATIENTS_ADD,
  DOCTOR_PATIENTS_DETAIL,
  DOCTOR_PATIENTS_ADD,
  DOCTOR_PATIENTS_GENERAL,
  DOCTOR_PATIENTS_PREVIOUS,
  DOCTOR_PATIENTS_PRESCRIPTION,
  DOCTOR_PATIENTS_DETAIL_LIST,
  DOCTOR_PATIENTS_PREVIOUS_DETAIL,
  DOCTOR_PATIENTS_PRESCRIPTION_CURRENT,
  DOCTOR_PATIENTS_PRESCRIPTION_OUTSIDE,
  DISTRICT_ADMIN_LOAN,
  DISTRICT_ADMIN_LOAN_REQUEST,
  DISTRICT_ADMIN_LOAN_RESPOND,
  DISTRICT_ADMIN_LOAN_REQUEST_ALL,
  DISTRICT_ADMIN_LOAN_REQUEST_APPROVED,
  DISTRICT_ADMIN_LOAN_REQUEST_NEW,
  DISTRICT_ADMIN_LOAN_REQUEST_FORWARD,
  DISTRICT_ADMIN_LOAN_REQUEST_ACCEPTED,
  DISTRICT_ADMIN_LOAN_REQUEST_DETAIL,
  DISTRICT_ADMIN_LOAN_REQUEST_DETAIL_HOSPITAL,
  DISTRICT_ADMIN_LOAN_RESPONSE_ALL,
  DISTRICT_ADMIN_LOAN_RESPONSE_REVERTED,
  DISTRICT_ADMIN_LOAN_RESPONSE_ACCEPTED,
  DISTRICT_ADMIN_LOAN_RESPONSE_DETAIL,
  REPLACED_ORDER,
  HOSPITAL_LOAN_REQUEST_APPROVED,
  PHARMACY_PATIENTS_ADD_MAIN,
}
