import { useFormContext } from 'react-hook-form'
import FormBuilder from '../../../components/app/formBuilder'
import { useLocation } from '../create/api'
import {
  institutionTypeData,
  // districtData,
  streamData,
  lsgdTypeData,
  buildingTypeData,
} from '../store'

type Props = {
  edit?: boolean
}
// const CommonForm = (data?: any) => {

const CommonForm = ({ edit }: Props) => {
  const { watch } = useFormContext()
  const { data } = useLocation()
  const fileName = watch()?.image_name
  // // console.log(data)
  const textField = (
    name: string,
    label: string,
    id: string,
    type: string,
    // placeholder: string,
    required = false,
    config: { data?: Array<{ name: string; id: string }> } = {}
  ) => ({
    name,
    label,
    id,
    type,
    // placeholder,
    ...(required ? { required: true } : {}),
    ...config,
    spacing: 4,
  })
  // // console.log(watch()?.image_name)
  const formBuilderProps = [
    {
      ...textField('name', 'Name', 'name', 'text', true),
      disabled: edit ? true : false,
    },
    {
      ...textField('state', 'State', 'state', 'text', false),
      disabled: true,
    },

    {
      ...textField(
        'district_name',
        'District',
        'district_id',
        'custom_select',
        true,
        {
          data: data?.districts,
        }
      ),
      placeholder: 'Choose one',
      disabled: edit ? false : false,
    },
    {
      ...textField('code', 'Facility Id', 'code', 'text', true),
      disabled: edit ? true : false,
    },
    {
      ...textField(
        'institution_type',
        'Institution Type',
        'institution_type',
        'custom_select',
        true,
        {
          data: institutionTypeData,
        }
      ),
      placeholder: 'Choose one',
      disabled: edit ? true : false,
    },
    {
      ...textField('stream', 'Stream', 'stream', 'custom_select', true, {
        data: streamData,
      }),
      placeholder: 'Choose one',
      disabled: edit ? false : false,
    },
    {
      ...textField(
        'lok_sabha_constituency_name',
        'Lok Sabha Constituency',
        'lok_sabha_constituency_id',
        'custom_select',
        true,
        { data: data?.loksabha_constituencies }
      ),
      placeholder: 'Choose one',
      disabled: edit ? false : false,
    },
    {
      ...textField(
        'assembly_constituency_name',
        'Assembly Constituency',
        'assembly_constituency_id',
        'custom_select',
        true,
        { data: data?.assembly_constituencies }
      ),
      placeholder: 'Choose one',
      disabled: edit ? false : false,
    },
    {
      ...textField('block', 'Block', 'block', 'text', true),
      disabled: edit ? false : false,
    },
    {
      ...textField('taluk', 'Taluk', 'taluk', 'text', true),
      disabled: edit ? false : false,
    },
    {
      ...textField('village', 'Village', 'village', 'text', true),
      disabled: edit ? false : false,
    },
    {
      ...textField(
        'lsgd_type',
        'LSGD type',
        'lsgd_type',
        'custom_select',
        true,
        {
          data: lsgdTypeData,
        }
      ),
      placeholder: 'Choose one',
      disabled: edit ? false : false,
    },
    {
      ...textField('lsgd', 'Name of LSGD', 'lsgd', 'text', true),
      disabled: edit ? false : false,
    },
    {
      ...textField(
        'ward_name_and_number',
        'Ward name and number',
        'ward_name_and_number',
        'text',
        true
      ),
      disabled: edit ? false : false,
    },
    {
      ...textField(
        'building_type',
        'Building type',
        'building_type',
        'custom_select',
        true,
        {
          data: buildingTypeData,
        }
      ),
      placeholder: 'Choose one',
      disabled: edit ? false : false,
    },
    {
      ...textField('address', 'Full address', 'address', 'text', true),
      disabled: edit ? false : false,
    },
    {
      ...textField('email', 'Email address', 'address', 'text', true),
      disabled: edit ? true : false,
    },
    {
      ...textField(
        'land_number',
        'Land phone number',
        'land_number',
        'number',
        true
      ),
      disabled: edit ? false : false,
    },
    {
      ...textField(
        'phone_number',
        'Mobile number',
        'phone_number',
        'number',
        true
      ),
      disabled: edit ? false : false,
    },
    {
      ...textField(
        'geographical_info',
        'Geographical Information',
        'geographical_info',
        'text',
        true
      ),
      disabled: edit ? false : false,
    },
    {
      ...textField('image', 'Upload Image', 'image', 'file_upload', true),
      selectedFiles: { name: watch()?.image?.name ?? (fileName as string) },
      disabled: edit ? false : false,
      // acceptedFiles: '',
      // sizeLimit: ' ',
    },
  ]

  // methods.reset({})

  return <FormBuilder data={formBuilderProps} edit={edit} />
}
export default CommonForm
