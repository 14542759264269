import moment from 'moment'
import { QbsTable } from 'qbs-react-grid'
import { useCallback, useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

// import Export from '../../components/app/export'
// import { PrimaryFilterComponent } from '../../components/app/filterComponents/primaryFilter'
// import Button from '../../components/common/buttons/Button'
// import Icons from '../../components/common/icons/index'
import ListingHeader from '../../../components/common/ListingTiles'
import { router_config } from '../../../configs/route.config'
import { useContactFilterStore } from '../../../store/filterSore/contactStore'
import { statusClassGen } from '../../../utilities/generators'
import { getSortedColumnName } from '../../../utilities/parsers'
import { useContact } from './api'
import { getColumns } from './contactColumns'
import { Button, ConfirmModal, DialogModal } from '../../../components/common'
import PharmacistCreate from './create'
import { calcWindowHeight } from '../../../utilities/calcHeight'
// import Icons from '../../../components/common/icons'
// import Create from './create'
// import { GetFilterDetails } from './filterProperties'

const FundMappingMain = () => {
  const navigate = useNavigate()
  // const location = useLocation()
  // const [isDrawerOpen, setDrawerOpen] = useState(false)
  const [mergeIsActive, setMergeIsActve] = useState(false)
  const [dialogModalActive, setDialogModalActive] = useState(false)
  const [successModalActive, setSuccessModalActive] = useState(false)
  const [edit, setEdit] = useState(false)
  const [editData, setEditData] = useState({})
  // const [openPopups, setOpenPopups] = useState(false)
  const { pageParams, setPageParams, selectedRows, setSelectedRows } =
    useContactFilterStore()
  // const { advanceFilter, setAdvanceFilter } = useContactFilterStore()
  // const [showDetail, setShowDetail] = useState(true)
  // const { mutate } = useDeleteFund()
  const { page, page_size, search, ordering, filters, filterProps } = pageParams

  const searchParams = {
    page_number: page,
    page_size: page_size,
    name: search,
    ordering: ordering,
    ...filters,
  }
  const { data, refetch, isFetching } = useContact(searchParams)
  useEffect(() => {
    refetch()
  }, [page, page_size, search, ordering, filters, refetch])

  const onChangeRowsPerPage = (count: any) => {
    setPageParams({
      ...pageParams,
      page_size: count,
      page: 1,
    })
  }
  const onChangePage = (row: any) => {
    setPageParams({
      ...pageParams,
      page: row,
    })
  }

  const onViewAction = (r: any, from: string) => {
    console.log(r, from)
  }
  const handleSort = (orderColumn: any, orderDirection: any) => {
    setPageParams({
      ...pageParams,
      sortColumn: orderColumn,
      sortType: orderDirection,
      ordering: getSortedColumnName(orderColumn, orderDirection),
    })
  }
  const handleSeach = (key?: string) => {
    setPageParams({
      ...pageParams,
      search: key as string,
      page: 1,
    })
  }
  const handleOnSlect = (rows: any) => {
    setSelectedRows([...rows])
  }
  // const handleColumnToggle = (columns: any) => {
  //   // console.log(columns)
  // }
  // const handleTileClick = (id: string) => {
  //   // console.log(id)
  // }
  const basicData = {
    title: 'Fund Mapping',
    icon: 'funds',
  }

  useEffect(() => {
    handleDatepicker()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterProps.endDate])

  const handleDatepicker = useCallback(() => {
    setPageParams({
      ...pageParams,
      filters: {
        ...filters,
        fromDate: filterProps?.startDate
          ? moment(filterProps?.startDate).format('DD-MM-YYYY')
          : '',
        toDate: filterProps?.endDate
          ? moment(filterProps.endDate).format('DD-MM-YYYY')
          : '',
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterProps.endDate])

  // const handleFilterDateChange = (event: any, desc: any, desc2: any) => {
  //   setPageParams({
  //     ...pageParams,
  //     filterProps: {
  //       ...filterProps,
  //       [desc]: event?.value[0],
  //       [desc2]: event?.value[1],
  //     },
  //   })
  // }

  // const handleSHowHide = () => {
  //   return advanceFilter?.filterParams?.find((item) => item.isChecked)
  //     ? true
  //     : false
  // }
  const handleMerge = () => {
    navigate(`${router_config.CONTACT_MERGE.path}`)
    setMergeIsActve(true)
  }
  // const handlePath = () => {
  //   if (location.pathname === router_config.CONTACT_MERGE.path) {
  //     setMergeIsActve(true)
  //   } else {
  //     setMergeIsActve(false)
  //   }
  // }

  // useEffect(() => {
  //   handlePath()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [location.pathname])
  const handleEditAction = () => {
    setEdit(true)
    setDialogModalActive(true)
  }
  const handleDeleteAction = (r: any) => {
    console.log(r)
    // const id = r.id
    // mutate(id)
  }

  const onAction = (r: any, from: string) => {
    console.log(r, from)
    setEditData(r)
    switch (from) {
      case 'edit':
        handleEditAction()
        break
      case 'delete':
        handleDeleteAction(r)
    }
  }

  const [columns, setColumns] = useState<any>(() =>
    getColumns({
      onViewAction: onViewAction,
      statusClassGen: statusClassGen,
      onAction: onAction,
    })
  )

  const handleResetColums = () => {
    setColumns([
      ...getColumns({
        onViewAction: onViewAction,
        statusClassGen: statusClassGen,
      }),
    ])
  }
  if (mergeIsActive) {
    return <Outlet context={{ selectedRows, refetch }} />
  }
  const activity = {
    hideExport: true,
  }
  const handleCreate = () => {
    setEdit(false)
    setDialogModalActive(true)
  }
  const handleClose = () => {
    setDialogModalActive(false)
  }
  const handlSubmit = () => {
    handleClose()
    refetch()
    setSuccessModalActive(true)
  }
  const handleCloseModal = () => {
    navigate(`${router_config.FUND_MAPPING.path}`)
    setSuccessModalActive(false)
    handleClose()
  }
  return (
    <div className="">
      <div className="">
        <ListingHeader
          data={basicData}
          // onActionClick={handleCreate}
          // actionTitle="Add Fund"
          // onHandleExport={openPopup}
          actionProps={activity}
          // setCustomSearch={setCustomSearch}
        />
        {dialogModalActive && (
          <DialogModal
            isOpen={true}
            body={
              <PharmacistCreate
                handlSubmit={handlSubmit}
                edit={edit}
                data={editData}
              />
            }
            onClose={handleClose}
            // title={'Add New Pharmacist'}
          />
        )}
        {successModalActive && (
          <>
            {!edit ? (
              <ConfirmModal
                isOpen={true}
                body={
                  <div className="font-medium text-primary leading-8 text-xl">
                    Fund Added Successfully
                  </div>
                }
                topIcon="check-circle"
                onClose={handleCloseModal}
                // title={'Add New Pharmacist'}
              />
            ) : (
              <ConfirmModal
                isOpen={true}
                body={
                  <div className="font-medium text-primary leading-8 text-xl">
                    Fund Edited Successfully
                  </div>
                }
                topIcon="check-circle"
                onClose={handleCloseModal}
                // title={'Add New Pharmacist'}
              />
            )}
          </>
        )}
        {/* <PageTiles data={dashtiledata} handleTileClick={handleTileClick} /> */}
        <div className="px-[24px] py-[24px]">
          <QbsTable
            columns={columns}
            dataRowKey="id"
            selectedRows={selectedRows}
            // selection={true}
            isLoading={isFetching}
            toolbar
            search
            handleColumnSort={handleSort}
            height={calcWindowHeight(382)}
            data={data?.funds ?? []}
            handleResetColumns={() => handleResetColums()}
            // sortType={sortType}
            // sortColumn={sortColumn}
            pagination
            paginationProps={{
              onPagination: onChangePage,
              total: data?.total_count,
              currentPage: pageParams?.page,
              rowsPerPage: Number(pageParams?.page_size ?? data?.page_size),
              onRowsPerPage: onChangeRowsPerPage,
              dropOptions: [10, 20, 30, 50, 100, 200],
            }}
            tableHeaderActions={
              <>
                <Button onClick={handleCreate} label={'Add Fund'} icon="plus" />
              </>
            }
            actionProps={
              [
                // {
                //   title: 'View',
                //   action: (rowData) => onAction(rowData, 'edit'),
                //   icon: <Icons name="edit" />,
                //   toolTip: 'Edit',
                // },
                // {
                //   title: 'View',
                //   action: (rowData) => onAction(rowData, 'delete'),
                //   icon: <Icons name="delete" />,
                //   toolTip: 'Delete',
                // },
              ]
            }
            selectedRowActions={[
              {
                actionTitle: 'Merge',
                action: () => handleMerge(),
                customHide: '>2',
              },
            ]}
            searchValue={pageParams?.search}
            onSearch={handleSeach}
            asyncSearch
            handleSearchValue={(key?: string) => handleSeach(key)}
            onSelect={handleOnSlect}
            columnToggle
          />
        </div>
        {/* <Create
          isDrawerOpen={isDrawerOpen}
          handleClose={handleClose}
          handleRefresh={handleRefresh}
        />
        <Export
          isOpen={openPopups}
          handleClose={handleClose}
          slug={'EXPORT_CONTACT'}
          pageParams={searchParams}
        /> */}
      </div>
    </div>
  )
}

export default FundMappingMain
