import { getData } from '../apis/api.helpers'
import apiUrl from '../apis/api.url'
import { useQuery } from '@tanstack/react-query'

const fetchData = async () => {
  const url = apiUrl.PROFILE_URL
  const response = await getData(url)
  // console.log(response)
  return response
}

export const useFetch = () => {
  return useQuery(['contact_list'], () => fetchData(), {
    refetchOnWindowFocus: false,
    //staleTime: 50000,
  })
}
