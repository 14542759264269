import moment from 'moment'
import { QbsTable } from 'qbs-react-grid'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { router_config } from '../../../../../../configs/route.config'
import { statusClassGen } from '../../../../../../utilities/generators'
import { usePatientListing, usePreviousPrescription } from '../../api'
import { getColumns } from './contactColumns'
import { ConfirmModal, DialogModal } from '../../../../../../components/common'
import PrimaryStockCreate from '../../create'
import { calcWindowHeight } from '../../../../../../utilities/calcHeight'
import ListingHeader from '../../../../../../components/common/ListingTiles'

const PatientPreviousDiagnosisDetail = () => {
  const navigate = useNavigate()

  const [dialogModalActive, setDialogModalActive] = useState(false)
  const [successModalActive, setSuccessModalActive] = useState(false)
  const [patientdata, setPatientdata] = useState<any>()
  const [data, setData] = useState<any>()
  const [setCustomBack] = useState(true)
  const { id4, id2 } = useParams()
  const { data: patients } = usePreviousPrescription(id4)
  const { data: detail } = usePatientListing()

  useEffect(() => {
    detail?.op_records?.map((item: any) => {
      if (item?.id == id2) {
        setData(item)
      }
    })
  }, [patients, id4, id2])

  useEffect(() => {
    setPatientdata(patients)
  }, [patients, id4])

  const [columns, setColumns] = useState<any>(() =>
    getColumns({
      statusClassGen: statusClassGen,
    })
  )

  const handleResetColums = () => {
    setColumns([
      ...getColumns({
        statusClassGen: statusClassGen,
      }),
    ])
  }
  const handleCreate = () => {
    setDialogModalActive(true)
  }
  const handleClose = () => {
    setDialogModalActive(false)
    handleCreate()
  }
  const handleSubmit = () => {
    setDialogModalActive(false)
    navigate(`${router_config.PHARMACY_PATIENTS_ADD.path}`)
  }
  const handleCloseModal = () => {
    setSuccessModalActive(false)
  }
  const activity = {
    hideExport: true,
  }
  const basicData = {
    title: 'Previous Diagnosis',
    icon: 'institution',
  }
  return (
    <div className="">
      <ListingHeader
        data={basicData}
        actionProps={activity}
        setCustomBack={setCustomBack}
      />
      <div className="w-100 py-[24px] px-[24px]">
        <div className="flex justify-between">
          <div className="flex items-center">
            <div className="sm:text-xs  font-normal leading-normal">
              <div className="text-2xl text-primary font-semibold	">
                {patientdata?.patient_name ?? '---'}
              </div>
              <div className="font-semibold leading-normal">
                Unique ID : {patientdata?.patient_id}
              </div>
            </div>
          </div>
          <div className="flex gap-4 justify-between w-100 items-center">
            <div className=" lg:text-xs text-[10px] px-1 2xl:px-2 font-normal leading-normal align-middle h-[40px]	text-SidenavFontColor">
              <div className="font-bold text-black">Age</div>
              <div className=" text-primary font-medium">
                {patientdata?.patient_age}
              </div>
            </div>

            <div className=" lg:text-xs text-[10px] px-5 2xl:px-8 font-normal leading-normal align-middle h-[40px]	text-SidenavFontColor">
              <div className="font-bold text-black">Gender</div>
              <div className=" text-primary font-medium">{data?.gender}</div>
            </div>
            <div className=" lg:text-xs text-[10px] px-1 2xl:px-2 font-normal leading-normal align-middle h-[40px]	text-SidenavFontColor">
              <div className="font-bold text-black">Date and Time</div>
              <div className=" text-primary font-medium">
                {patientdata?.prescription_created_at
                  ? moment(patientdata?.prescription_created_at).format(
                      'DD-MM-YYYY , hh:mm a'
                    )
                  : '--'}
              </div>
            </div>
            {/* <div className=" lg:text-xs text-[10px]  font-normal leading-normal align-middle h-[40px]	text-SidenavFontColor">
              <div className="font-bold text-black">Time</div>
              <div className=" text-primary font-medium">
                {patientdata?.created_at
                  ? moment(patientdata?.created_at).format('DD-MM-YYYY')
                  : 'NA'}
              </div>
            </div> */}
            <div className=" lg:text-xs text-[10px] px-5 2xl:px-8 font-normal leading-normal align-middle h-[40px]	text-SidenavFontColor">
              <div className="font-bold text-black">Hospital</div>
              <div className=" text-primary font-medium">
                {patientdata?.hospital_name}
              </div>
            </div>
            <div className=" lg:text-xs text-[10px] px-5 2xl:px-8 font-normal leading-normal align-middle h-[40px]	text-SidenavFontColor">
              <div className="font-bold text-black">Doctor</div>
              <div className=" text-primary font-medium">
                {patientdata?.doctor_name}
              </div>
            </div>
            <div className=" lg:text-xs text-[10px] px-5 2xl:px-8 font-normal leading-normal align-middle h-[40px]	text-SidenavFontColor">
              <div className="font-bold text-black">Specialization</div>
              <div className=" text-primary font-medium">
                {patientdata?.specialization}
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />

      {/* <PageTiles data={dashtiledata} handleTileClick={handleTileClick} /> */}
      <div className="px-[24px] py-[24px]">
        <QbsTable
          columns={columns}
          dataRowKey="id"
          toolbar
          height={calcWindowHeight(500)}
          data={patients?.perscription_items ?? []}
          handleResetColumns={() => handleResetColums()}
          pagination
          paginationProps={{
            total: patients?.perscription_items.length,
          }}
        />
      </div>
      {dialogModalActive && (
        <DialogModal
          isOpen={true}
          body={<PrimaryStockCreate handleSubmit={handleSubmit} />}
          onClose={handleClose}
          // title={'Add New Pharmacist'}
        />
      )}
      {successModalActive && (
        <ConfirmModal
          isOpen={true}
          body={
            <div className="font-medium text-primary leading-8 text-xl">
              Stock Added Successfully
            </div>
          }
          topIcon="check-circle"
          onClose={handleCloseModal}
          // title={'Add New Pharmacist'}
        />
      )}
      {/* <Create
          isDrawerOpen={isDrawerOpen}
          handleClose={handleClose}
          handleRefresh={handleRefresh}
        />
        <Export
          isOpen={openPopups}
          handleClose={handleClose}
          slug={'EXPORT_CONTACT'}
          pageParams={searchParams}
        /> */}
    </div>
  )
}

export default PatientPreviousDiagnosisDetail
