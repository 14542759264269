import React from 'react'
import { IconProps } from '../../../common/types'

const Tick: React.FC<IconProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
    >
      <path
        d="M19.6443 1.34766L9.10566 15.242L2.875 9.01582L0 11.8908L9.58184 21.4727L23 4.22266L19.6443 1.34766Z"
        fill="#45B755"
      />
    </svg>
  )
}

export default Tick
