// import { useEffect, useState } from 'react'
// import { generatePath, Outlet, useNavigate, useParams } from 'react-router-dom'

// import { TabItemProps } from '../../../common/types'
// import { TabContainer } from '../../../components/common'
// import DetailHeader from '../../../components/common/DetailTiles'
// import { router_config } from '../../../configs/route.config'
// import { getContactDetails, updateContactCre } from '../api'
// import { useSnackbar } from 'notistack'
// import { getAllEmployeeList } from '../../../apis/common.apis'
// let updateKey = 1
// const ContactDetails = () => {
//   const navigate = useNavigate()
//   const [edit] = useState(false)
//   const [data, setData] = useState<any>()
//   const [drpdownItems, setDrpdownItems] = useState<any>()

//   const params = useParams()
//   const tabData = [
//     {
//       label: router_config.CONTACT_DETAILS_SUMMARY.label,
//       id: 'CONTACT_DETAILS_SUMMARY',
//     },
//     {
//       label: router_config.CONTACT_DETAILS_ACCOUNTS.label,
//       id: 'CONTACT_DETAILS_ACCOUNTS',
//     },
//     {
//       label: router_config.CONTACT_DETAILS_POSSIBLE_DUPLICATES.label,
//       id: 'CONTACT_DETAILS_POSSIBLE_DUPLICATES',
//     },
//   ]
//   const [activeTab, setActiveTab] = useState<string>('CONTACT_DETAILS_SUMMARY')

//   const haandleTabChange = (selected: TabItemProps) => {
//     navigate(
//       generatePath(router_config[selected.id]?.path as string, {
//         id: params.id,
//       })
//     )
//   }
//   const getEmployee = async () => {
//     const data = await getAllEmployeeList()
//     const empDetails = data?.reduce(
//       (acc: any, current: any) => acc.concat(current),
//       []
//     )
//     const drpdownItems = empDetails?.map((item: any) => ({
//       label: item.name,
//       value: item.name,
//       id: item.id,
//     }))
//     setDrpdownItems(drpdownItems)
//   }

//   useEffect(() => {
//     getData()
//     getEmployee()

//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [])

//   const getData = async () => {
//     const { data } = await getContactDetails(params.id)
//     setData(data)
//   }

//   const tiledata = [
//     {
//       id: 1,
//       tiles: [
//         {
//           label: 'CRE',
//           value: data?.cre_agent?.name,
//           id: 1,
//         },
//         {
//           icon: 'phone',
//           value: data?.default_contact?.mobile,
//           id: 2,
//         },
//         {
//           icon: 'email',
//           value: data?.default_contact?.email,
//           id: 3,
//         },
//       ],
//     },
//     {
//       id: 2,
//       tiles: [
//         {
//           id: 1,
//           icon: 'home',
//           value: ` ${data?.addresses[0]?.city ?? ''}, ${data?.addresses[0]
//             ?.state} `,
//         },
//       ],
//     },
//   ]
//   const chipdata = [
//     { id: 1, status: 'Normal' },
//     { id: 2, status: 'Normal' },
//     { id: 3, status: 'Normal' },
//   ]
//   const basicData = {
//     title: `${data?.default_contact?.first_name ?? '--'} ${
//       data?.default_contact?.last_name ?? '--'
//     } (${data?.customer_number ?? '--'})`,
//     subtitle: `${
//       data?.primary_company?.business_name
//         ? `${data?.primary_company?.business_name} (${data?.primary_company?.company_code})`
//         : '--'
//     } + ${data?.no_of_accounts ?? '--'}`,
//     subLink: `${router_config.ACCOUNTS.path}/${data?.primary_company?.id}/summary`,
//     image: data?.profile_image,
//   }

//   const handleCallback = () => {
//     getData()
//   }
//   const contextData = {
//     setActiveTab: (id: string) => setActiveTab(id),
//     edit: edit,
//     handleParentCallback: handleCallback,
//   }
//   const { enqueueSnackbar } = useSnackbar()
//   const updateCRE = (id?: string) => {
//     const details = { assigned_cre_id: id ?? null }
//     updateContactCre(data?.id as string, details).then(() => {
//       enqueueSnackbar('CRE updated successfully', { variant: 'success' })
//       getData()
//       updateKey = updateKey + 1
//     })

//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }
//   const handleUpdateCre = (id?: string) => {
//     updateCRE(id)
//   }
//   return (
//     <div>
//       <div>
//         <DetailHeader
//           tiledata={tiledata}
//           chipdata={chipdata}
//           data={basicData}
//           menuItems={drpdownItems}
//           setUpdateCREId={handleUpdateCre}
//         />
//         {/* <PageTiles data={dashtiledata} handleTileClick={handleTileClick} /> */}
//       </div>
//       <div className="flex gap-4">
//         <TabContainer
//           data={tabData}
//           activeTab={activeTab}
//           onClick={haandleTabChange}
//         >
//           <Outlet context={contextData} />
//         </TabContainer>
//       </div>
//     </div>
//   )
// }

// export default ContactDetails

// import FormBuilder from '../../../components/app/formBuilder/index'

import { Button, ConfirmModal } from '../../../components/common'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { router_config } from '../../../configs/route.config'
import CommonForm from '../manufacturer_form'
import { FormProvider, useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import { useManufacturer, useUpdateManufacturer } from './api'
import { CreateSchema, formSchema } from '../create/schema'
import { zodResolver } from '@hookform/resolvers/zod'
import ListingHeader from '../../../components/common/ListingTiles'

const ManufacturerDetail = () => {
  const navigate = useNavigate()
  const [edit, setEdit] = useState(true)
  const [setCustomBack] = useState(true)
  const location = useLocation()
  const [successModalActive, setSuccessModalActive] = useState(false)
  const param = useParams()
  const manufacturerId = param.id
  const { data, refetch } = useManufacturer(manufacturerId)

  useEffect(() => {
    refetch()
  }, [refetch, manufacturerId])

  const methods = useForm<CreateSchema>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: { state: 'kerala' },
  })
  useEffect(() => {
    if (data) {
      methods.reset({
        ...data,
        district_name: data?.district,
      })
    }
  }, [data, methods])

  const onSuccess = () => {
    setSuccessModalActive(true)
  }
  const { mutate } = useUpdateManufacturer(onSuccess)

  const onSubmit = (payload: CreateSchema) => {
    // const formData = new FormData()
    // formData.append('manufacturer[district_id]', String(payload.district_id))
    // formData.append('manufacturer[address]', String(payload.address))
    // formData.append('manufacturer[land_number]', String(payload.land_number))
    // formData.append(
    //   'manufacturer[user_attributes][email]',
    //   String(payload.email)
    // )
    // formData.append(
    //   'manufacturer[user_attributes][phone_number]',
    //   String(payload.phone_number)
    // )
    // formData.append('manufacturer[name]', String(payload.name))
    const data = {
      manufacturer: {
        ...payload,
        user_attributes: {
          email: payload.email,
          phone_number: payload.phone_number,
        },
      },
    }
    mutate({ data, manufacturerId })
  }

  const handleEdit = () => {
    setEdit(true)
  }
  // succes modal
  // const handleSubmit = () => {
  //   setSuccessModalActive(true)
  // }
  const handleCloseModal = () => {
    setSuccessModalActive(false)
    {
      location.pathname === `/district-medical-store/${param.id}`
        ? navigate(`${router_config.DISTRICT_MEDICAL.path}`)
        : navigate(`${router_config.MANUFACTURER.path}`)
    }
  }
  const basicData = {
    title: 'Edit Manufacturer',
    icon: 'stock',
  }
  const basicDatas = {
    title: 'Edit Medical Store',
    icon: 'stock',
  }
  const activity = {
    hideExport: true,
  }
  return (
    <>
      {location.pathname === `/district-medical-store/${param.id}` ? (
        <ListingHeader
          data={basicDatas}
          actionProps={activity}
          setCustomBack={setCustomBack}
        />
      ) : (
        <ListingHeader
          data={basicData}
          actionProps={activity}
          setCustomBack={setCustomBack}
        />
      )}

      <div className=" px-[24px] py-[24px]">
        <div className="">
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="grid md:grid-cols-3 md:gap-8 sm:grid-cols-1 sm:gap-4">
              <FormProvider {...methods}>
                <CommonForm edit={edit} />
              </FormProvider>
            </div>
            <div className="flex gap-4 justify-center">
              {!edit ? (
                <Button label={'Edit'} onClick={handleEdit} />
              ) : (
                <Button type="submit" label={'Submit'} />
              )}
            </div>
          </form>
          {successModalActive && (
            <ConfirmModal
              isOpen={true}
              body={
                <div className="font-medium text-primary leading-8 text-xl">
                  {location.pathname === `/district-medical-store/${param.id}`
                    ? 'Medical Store Edited Successfully'
                    : 'Manufacturer Edited Successfully'}
                </div>
              }
              topIcon="check-circle"
              onClose={handleCloseModal}
              // title={'Add New Pharmacist'}
            />
          )}
        </div>
      </div>
    </>
  )
}
export default ManufacturerDetail
