export const GetFilterDetails = () => {
  const options: any[] = [
    { value: 'Ayurveda', label: 'Ayurveda' },
    { value: 'Yoga & Naturopathy', label: 'Yoga & Naturopathy' },
    { value: 'Unnani', label: 'Unnani' },
    { value: 'Siddha', label: 'Siddha' },
    { value: 'Homeopathy', label: 'Homeopathy' },
  ]
  // const loadLanguageList = async () => {
  //   const { data } = await getMasterDataList()

  //   return data?.languages?.map((item: any) => ({
  //     ...item,
  //     preferred_language_name: item.language_name,
  //   }))
  // }

  return [
    {
      desc: 'label',
      type: 'custom_search_select',
      descId: 'stream',
      async: true,
      isPrimary: true,
      data: options,
      // getData: getStatus,
      name: 'Select Stream',
      slug: 'stream',
      objectId: 'stream',
    },
  ]
}
