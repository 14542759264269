// import { getNestedProperty, useCurrencyFormat } from '../../utilities/parsers'
// import { useNavigate } from "react-router-dom"

// import { Link } from 'react-router-dom'
// import { router_config } from '../../../configs/route.config'
// import { useNavigate } from "react-router-dom"

// import { Router } from "react-router-dom"

// const defaultColumnProps = {
//   sortable: true,
//   resizable: true,
//   isVisible: true,
// }
const defaultColumnProps1 = {
  sortable: false,
  isVisible: true,
  resizable: true,
}
// const defaultColumnProps2 = {
//   sortable: true,
//   isVisible: false,
//   resizable: true,
// }
// const defaultColumnProps3 = {
//   sortable: false,
//   isVisible: true,
//   resizable: true,
// }

// const navigate = useNavigate()

export const getColumns = ({}: any) => {
  const createRenderCell = (key: string, isCustom?: string) => (row: any) => {
    // const format = useCurrencyFormat

    // if (isCustom === 'amount') {
    //   const propertyValue = getNestedProperty(row, key)
    //   const numbers =
    //     typeof propertyValue === 'string' ? parseFloat(propertyValue) : 0

    //   return {
    //     cell: format(numbers),
    //     toolTip: getNestedProperty(row, key) ?? '',
    //   }
    // } else {
    //   return {
    //     cell: getNestedProperty(row, key),
    //     toolTip: getNestedProperty(row, key) ?? '',
    //   }
    // }

    // if (isCustom === 'action') {
    //   // console.log(row)
    //   return {
    //     cell: (
    //       <Link
    //         to={`${router_config.PHARMACIST.path}/${row.id}`}
    //         className="text-capitalize"
    //       >
    //         {/* <span
    //           className={`statusLabel  ${
    //             row.status?.name
    //               ? statusClassGen(row?.status?.name)
    //               : 'Active'
    //           }`}
    //         > */}
    //         <span className="inline-block align-middle text-AvailabilityMark items-center font-medium leading-normal">
    //           View Details
    //         </span>
    //       </Link>
    //     ),
    //     // toolTip: getNestedProperty(row, key) ?? '',
    //   }
    // }
    if (isCustom === 'status') {
      console.log(row)
      return {
        cell: (
          <>
            {row?.availability === 'Offline' ? (
              <div className="text-red-700">Offline</div>
            ) : (
              <div className="text-blue-500">Online </div>
            )}
          </>
        ),

        // toolTip: getNestedProperty(row, key) ?? '',
      }
    }
  }

  const column = [
    // {
    //   title: 'Id',
    //   field: 'id',
    //   ...defaultColumnProps1,
    //   fixed: true,
    //   sortKey: 'id',
    //   // rowClick: (row: any) => onViewAction(row, 'contactName'),
    //   // link: true,
    // },
    {
      title: 'Name',
      field: 'name',
      fixed: true,
      ...defaultColumnProps1,
      // link: true,
      // sortKey: 'default_contact__first_name',
      // rowClick: (row: any) => onViewAction(row, 'contactName'),
    },
    {
      title: 'Stream',
      // renderCell: createRenderCell('cre_agent.name'),
      field: 'stream',
      ...defaultColumnProps1,
      // customCell: true,
      // sortKey: 'cre_agent_user_first_name',
    },
    {
      title: 'Phone Number',
      field: 'phone_number',
      ...defaultColumnProps1,
      // sortKey: 'company_code',
      // renderCell: createRenderCell('primary_company.company_code'),

      // rowClick: (row: any) => onViewAction(row, 'business_name'),
      // link: true,
    },
    {
      title: 'Email',
      field: 'email',
      ...defaultColumnProps1,
      // link: true,
      sortKey: '',
      // renderCell: createRenderCell('primary_company.business_name'),
      // rowClick: (row: any) => onViewAction(row, 'business_name'),
    },
    {
      title: 'Status',
      renderCell: createRenderCell('default_contact.availability', 'status'),
      field: 'availability',
      ...defaultColumnProps1,
      customCell: true,
      // link: true,
      // getpath: () => '/dashboard',
    },
    // {
    //   title: 'Action',
    //   renderCell: createRenderCell('default_contact.mobile', 'action'),
    //   field: 'mobile',
    //   ...defaultColumnProps1,
    //   customCell: true,
    //   // link: true,
    //   // getpath: () => '/dashboard',
    // },
    // {
    //   title: 'Mobile 2',
    //   renderCell: createRenderCell('default_contact.mobile_2'),
    //   field: 'mobile_2',
    //   ...defaultColumnProps1,
    //   customCell: true,
    // },
    // {
    //   title: 'Email',
    //   renderCell: createRenderCell('default_contact.email'),
    //   field: 'email',
    //   customCell: true,
    //   ...defaultColumnProps1,
    // },
    // {
    //   title: 'Email 2',
    //   renderCell: createRenderCell('default_contact.email_2'),
    //   field: 'email',
    //   customCell: true,
    //   ...defaultColumnProps1,
    // },
    // {
    //   title: 'Referrals',
    //   field: 'customer_referral_id',
    //   ...defaultColumnProps2,
    //   sortKey: 'customer_referral_id',
    // },
    // {
    //   title: 'Accounts Count',
    //   field: 'account_count',
    //   ...defaultColumnProps2,
    //   sortable: false,
    // },
    // {
    //   title: 'Active Orders',
    //   field: 'active_orders',
    //   ...defaultColumnProps,
    //   sortable: false,
    // },
    // {
    //   title: 'Total Orders',
    //   field: 'total_orders',
    //   ...defaultColumnProps,
    //   sortable: false,
    // },
    // {
    //   title: 'Amount Due',
    //   field: 'amount_due',
    //   renderCell: createRenderCell('amount_due', 'amount'),
    //   align: 'right',
    //   customCell: true,
    //   ...defaultColumnProps,
    //   sortable: false,
    // },
    // {
    //   title: 'Life Time Sales',
    //   field: 'life_time_sales',
    //   renderCell: createRenderCell('life_time_sales', 'amount'),
    //   align: 'right',
    //   customCell: true,
    //   ...defaultColumnProps,
    //   sortable: false,
    // },
    // {
    //   title: 'Language Pref',
    //   field: 'preferred_language',
    //   ...defaultColumnProps2,
    //   sortKey: 'preferred_language__language_name',
    // },
    // {
    //   title: 'Vintage',
    //   field: 'vintage',
    //   ...defaultColumnProps2,
    // },
    // {
    //   title: 'Days since Last Order',
    //   field: 'days_since_last_order',
    //   ...defaultColumnProps2,
    // },
    // {
    //   title: 'Contact Tier',
    //   field: 'tier',
    //   ...defaultColumnProps2,
    //   sortKey: 'customer_tier__tier',
    // },
    // {
    //   title: 'Address 1: Line 1',
    //   renderCell: createRenderCell('addresses.0.address_1'),
    //   field: 'address_1',
    //   ...defaultColumnProps1,
    //   customCell: true,
    // },
    // {
    //   title: 'Address 1: line 2',
    //   renderCell: createRenderCell('addresses.0.address_2'),
    //   field: 'address_2',
    //   ...defaultColumnProps1,
    //   customCell: true,
    // },
    // {
    //   title: 'Address 1: Country',
    //   renderCell: createRenderCell('addresses.0.country'),
    //   field: 'country',
    //   ...defaultColumnProps2,
    //   customCell: true,
    //   sortKey: 'customer_address__country',
    // },
    // {
    //   title: 'Address 1: State',
    //   renderCell: createRenderCell('addresses.0.state'),
    //   field: 'state',
    //   ...defaultColumnProps,
    //   customCell: true,
    //   sortKey: 'customer_address__state',
    // },
    // {
    //   title: 'Address 1: City',
    //   renderCell: createRenderCell('addresses.0.city'),
    //   field: 'city',
    //   ...defaultColumnProps3,
    //   customCell: true,
    // },
    // {
    //   title: 'Address 1: Pincode',
    //   renderCell: createRenderCell('addresses.0.pin_code'),
    //   field: 'pin_code',
    //   ...defaultColumnProps1,
    //   customCell: true,
    // },
    // {
    //   title: 'Address 2: Line 1',
    //   renderCell: createRenderCell('addresses.1.address_1'),
    //   field: 'address_1',
    //   ...defaultColumnProps1,
    //   customCell: true,
    // },
    // {
    //   title: 'Address 2: line 2',
    //   renderCell: createRenderCell('addresses.1.address_2'),
    //   field: 'address_2',
    //   ...defaultColumnProps1,
    //   customCell: true,
    // },
    // {
    //   title: 'Adress 2: Country',
    //   renderCell: createRenderCell('addresses.1.country'),
    //   field: 'country',
    //   ...defaultColumnProps2,
    //   customCell: true,
    //   sortKey: 'customer_address__country',
    // },
    // {
    //   title: 'Address 2: State',
    //   renderCell: createRenderCell('addresses.1.state'),
    //   field: 'state',
    //   ...defaultColumnProps2,
    //   customCell: true,
    //   sortKey: 'customer_address__state',
    // },
    // {
    //   title: 'Address 2: City',
    //   renderCell: createRenderCell('addresses.1.city'),
    //   field: 'city',
    //   ...defaultColumnProps1,
    //   customCell: true,
    // },
    // {
    //   title: 'Address 2: Pincode',
    //   renderCell: createRenderCell('addresses.1.pin_code'),
    //   field: 'pin_code',
    //   ...defaultColumnProps1,
    //   customCell: true,
    // },
    // {
    //   title: 'Created Date',
    //   field: 'datetime_created',
    //   type: 'date',
    //   customCell: true,
    //   ...defaultColumnProps,
    // },
    // {
    //   title: 'Created By',
    //   field: 'created_by',
    //   ...defaultColumnProps,
    //   sortKey: 'created_by_user_first_name',
    // },
  ]

  return column
}
