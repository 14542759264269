import { Outlet, useNavigate } from 'react-router-dom'
import { TabContainer } from '../../../components/common'
import { router_config } from '../../../configs/route.config'
import { useState } from 'react'
import ListingHeader from '../../../components/common/ListingTiles'

const tabData: any[] = [
  {
    label: 'Secondary Stock',

    id: 'HOSPITAL_MEDICINE_ISSUE_SECONDARY',
  },
  // {
  //   label: 'Medicine Request From Pharmacy',

  //   id: 'HOSPITAL_MEDICINE_REQUEST',
  // },
]

const MedicineIssueMain: React.FC<any> = () => {
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState(
    'HOSPITAL_MEDICINE_ISSUE_SECONDARY'
  )
  const onTabClick = (selection: any) => {
    setActiveTab(selection.id)
    navigate(`/medicine/${router_config[selection.id]?.path}`)
  }
  const activity = {
    hideExport: true,
  }
  const basicData = {
    title: 'Medicine Issue',
    icon: 'institution',
  }
  return (
    <>
      <ListingHeader data={basicData} actionProps={activity} />
      <TabContainer
        data={tabData}
        activeTab={activeTab}
        // isContainer
        onClick={onTabClick}
        // tabBGClass="bg-tab-detail  px-[15px] py-1.5 rounded-sm"
      >
        <Outlet />
      </TabContainer>
    </>
  )
}

export default MedicineIssueMain
