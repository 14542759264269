// import FormBuilder from '../../../components/app/formBuilder/index'

import { Button, ConfirmModal } from '../../../components/common'
import { useNavigate } from 'react-router-dom'
import { router_config } from '../../../configs/route.config'
import CommonForm from '../institution_form'
import { FormProvider, useForm } from 'react-hook-form'
import { useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { CreateSchema, formSchema } from './schema'
import { useCreateInstitution } from './api'
import ListingHeader from '../../../components/common/ListingTiles'

const ContactCreate = () => {
  const navigate = useNavigate()
  const [setCustomBack] = useState(true)
  // const { data } = useLocation()
  const onSuccess = () => {
    setSuccessModalActive(true)
  }
  const { mutate } = useCreateInstitution(onSuccess)
  const [successModalActive, setSuccessModalActive] = useState(false)
  const methods = useForm<CreateSchema>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: { state: 'Kerala' },
  })

  const onSubmit = (payload: CreateSchema) => {
    // setSuccessModalActive(true)
    // console.log(payload)
    const formdata = new FormData()
    formdata.append('institution[name]', payload.name)
    formdata.append('institution[address]', payload.address)
    if (payload.district_id) {
      formdata.append('institution[district_id]', String(payload.district_id))
    }

    formdata.append('institution[stream]', payload.stream)
    if (payload.lok_sabha_constituency_id) {
      formdata.append(
        'institution[lok_sabha_constituency_id]',
        String(payload.lok_sabha_constituency_id)
      )
    }

    formdata.append('institution[block]', payload.block)
    formdata.append('institution[code]', payload.code)
    formdata.append('institution[taluk]', payload.taluk)
    formdata.append('institution[village]', payload.village)
    formdata.append('institution[lsgd]', payload.lsgd)
    formdata.append('institution[lsgd_type]', payload.lsgd_type)
    formdata.append('institution[building_type]', payload.building_type)
    formdata.append('institution[institution_type]', payload.institution_type)
    if (payload.assembly_constituency_id) {
      formdata.append(
        'institution[assembly_constituency_id]',
        String(payload.assembly_constituency_id)
      )
    }

    formdata.append('institution[geographical_info]', payload.geographical_info)
    formdata.append(
      'institution[ward_name_and_number]',
      payload.ward_name_and_number
    )
    formdata.append(
      'institution[user_attributes][phone_number]',
      payload.phone_number
    )

    formdata.append('institution[land_number]', payload.land_number)

    formdata.append('institution[user_attributes][email]', payload.email)
    if (payload.state) {
      formdata.append('institution[state]', payload.state)
    }
    if (payload.image) {
      formdata.append('institution[image]', payload.image)
    }
    // console.log(formdata.get('institution[image]'))
    mutate(formdata)
  }
  const handleCloseModal = () => {
    navigate(`${router_config.INSTITUTIONS.path}`)
    setSuccessModalActive(false)
  }
  const basicData = {
    title: 'Add Institution',
    icon: 'stock',
  }
  const activity = {
    hideExport: true,
  }
  return (
    <>
      <ListingHeader
        data={basicData}
        actionProps={activity}
        setCustomBack={setCustomBack}
      />
      <div className=" px-[24px] py-[24px]">
        <div className="">
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="grid md:grid-cols-3 md:gap-8 sm:grid-cols-1 sm:gap-4">
              <FormProvider {...methods}>
                <CommonForm edit={false} />
              </FormProvider>
            </div>
            <div className="flex gap-8 mt-3 justify-center">
              <Button
                onClick={() => methods.reset({})}
                label={'Clear'}
                outlined={true}
              />
              <Button type="submit" label={'Submit'} />
            </div>
          </form>

          {successModalActive && (
            <ConfirmModal
              isOpen={true}
              body={
                <div className="font-medium text-primary leading-8 text-xl">
                  Registration Completed Successfully
                </div>
              }
              topIcon="check-circle"
              onClose={handleCloseModal}
              // title={'Add New Pharmacist'}
            />
          )}
        </div>
      </div>
    </>
  )
}
export default ContactCreate
