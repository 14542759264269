import moment from 'moment'
import { QbsTable } from 'qbs-react-grid'
import { useCallback, useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useDetailList } from '../api'

// import Export from '../../components/app/export'
// import { PrimaryFilterComponent } from '../../components/app/filterComponents/primaryFilter'
// import Button from '../../components/common/buttons/Button'
// import Icons from '../../components/common/icons/index'
import { getColumns } from './contactColumns'
import { useContactFilterStore } from '../../../../../store/filterSore/contactStore'
import { getSortedColumnName } from '../../../../../utilities/parsers'
import { router_config } from '../../../../../configs/route.config'
import { statusClassGen } from '../../../../../utilities/generators'
import ListingHeader from '../../../../../components/common/ListingTiles'
import { fetchDownloadList } from '../../primary_stock/api'
import { calcWindowHeight } from '../../../../../utilities/calcHeight'
import { Button } from '../../../../../components/common'
import Papa from 'papaparse'

// import { GetFilterDetails } from './filterProperties'

const ReportListSecondary = () => {
  const navigate = useNavigate()
  const [setCustomBack] = useState(true)

  const [mergeIsActive, setMergeIsActve] = useState(false)

  const { pageParams, setPageParams, selectedRows, setSelectedRows } =
    useContactFilterStore()
  // const { advanceFilter, setAdvanceFilter } = useContactFilterStore()
  // const [showDetail, setShowDetail] = useState(true)
  const [customSearch] = useState('')
  const [customType] = useState('')
  const location = useLocation()
  const { page, page_size, ordering, filters, filterProps } = pageParams

  const searchParams = {
    page_number: page,
    page_size: page_size,
    name: customSearch,
    report_type: 'secondary_stock_report',
    ...(customType === 'select'
      ? { instution_type: '' }
      : { instution_type: customType }),
    start_date: location?.state?.start_date
      ? moment(location?.state?.start_date).format('YYYY-MM-DD')
      : '',
    end_date: location?.state?.end_date
      ? moment(location?.state?.end_date).format('YYYY-MM-DD')
      : '',
    ...(customType === 'select'
      ? { instution_type: '' }
      : { instution_type: customType }),
    ordering: ordering,
    ...filters,
  }
  const { data, refetch, isFetching } = useDetailList(searchParams)
  useEffect(() => {
    refetch()
  }, [page, page_size, ordering, filters, refetch, customSearch, customType])
  // console.log(customSearch)
  const onChangeRowsPerPage = (count: any) => {
    setPageParams({
      ...pageParams,
      page_size: count,
      page: 1,
    })
  }
  const onChangePage = (row: any) => {
    setPageParams({
      ...pageParams,
      page: row,
    })
  }

  const onViewAction = (r: any, from: string) => {
    console.log(r, from)
  }
  const handleSort = (orderColumn: any, orderDirection: any) => {
    setPageParams({
      ...pageParams,
      sortColumn: orderColumn,
      sortType: orderDirection,
      ordering: getSortedColumnName(orderColumn, orderDirection),
    })
  }
  const handleSeach = (key?: string) => {
    setPageParams({
      ...pageParams,
      search: key as string,
      page: 1,
    })
  }
  const handleOnSlect = (rows: any) => {
    setSelectedRows([...rows])
  }

  const basicData = {
    title: 'Secondary Stock Report',
    icon: 'stock',
  }

  useEffect(() => {
    handleDatepicker()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterProps.endDate])

  const handleDatepicker = useCallback(() => {
    setPageParams({
      ...pageParams,
      filters: {
        ...filters,
        fromDate: filterProps?.startDate
          ? moment(filterProps?.startDate).format('DD-MM-YYYY')
          : '',
        toDate: filterProps?.endDate
          ? moment(filterProps.endDate).format('DD-MM-YYYY')
          : '',
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterProps.endDate])

  const handleMerge = () => {
    navigate(`${router_config.CONTACT_MERGE.path}`)
    setMergeIsActve(true)
  }

  const [columns, setColumns] = useState<any>(() =>
    getColumns({
      onViewAction: onViewAction,
      statusClassGen: statusClassGen,
    })
  )

  const handleResetColums = () => {
    setColumns([
      ...getColumns({
        onViewAction: onViewAction,
        statusClassGen: statusClassGen,
      }),
    ])
  }
  if (mergeIsActive) {
    return <Outlet context={{ selectedRows, refetch }} />
  }
  const activity = {
    hideExport: true,
  }
  const handleDownload = () => {
    // useDownloadList(searchParams)
    fetchDownloadList(searchParams).then((a) => {
      const parsedData = Papa.parse(a.data, {
        header: true,
        dynamicTyping: true,
      })

      const csvData = Papa.unparse(parsedData.data)

      // Create a blob from the CSV data
      const blob = new Blob([csvData], { type: 'text/csv' })

      // Create a download link
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `report-secondary-stock-${new Date()}.csv`

      // Append the link to the body
      document.body.appendChild(link)

      // Trigger the download
      link.click()

      // Remove the link from the body
      document.body.removeChild(link)
    })
  }

  return (
    <>
      <ListingHeader
        data={basicData}
        actionProps={activity}
        setCustomBack={setCustomBack}
      />
      <div className=" px-[24px] py-[24px]">
        <div className="">
          <div className="">
            <QbsTable
              columns={columns}
              dataRowKey="id"
              selectedRows={selectedRows}
              // selection={true}
              isLoading={isFetching}
              toolbar
              handleColumnSort={handleSort}
              height={calcWindowHeight(440)}
              data={data?.records ?? []}
              handleResetColumns={() => handleResetColums()}
              // sortType={sortType}
              // sortColumn={sortColumn}
              pagination
              paginationProps={{
                onPagination: onChangePage,
                total: data?.total_count,
                currentPage: pageParams?.page,
                rowsPerPage: Number(pageParams?.page_size ?? data?.page_size),
                onRowsPerPage: onChangeRowsPerPage,
                dropOptions: [10, 20, 30, 50, 100, 200],
              }}
              tableHeaderActions={
                <>
                  <Button
                    onClick={handleDownload}
                    label={'Download'}
                    primary={true}
                  />
                </>
              }
              selectedRowActions={[
                {
                  actionTitle: 'Merge',
                  action: () => handleMerge(),
                  customHide: '>2',
                },
              ]}
              // searchValue={pageParams?.search}
              // onSearch={handleSeach}
              asyncSearch
              handleSearchValue={(key?: string) => handleSeach(key)}
              onSelect={handleOnSlect}
              columnToggle
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default ReportListSecondary
