const defaultColumnProps1 = {
  sortable: false,
  isVisible: true,
  resizable: true,
}

export const getColumns = ({}: any) => {
  const createRenderCell = (key: string, isCustom?: string) => (row: any) => {
    if (isCustom === 'status') {
      console.log(row)
      return {
        cell: (
          <span className="inline-block align-middle text-RevertedStatus items-center font-medium leading-normal">
            Reverted
          </span>
        ),
      }
    }
  }
  const column = [
    // {
    //   title: 'Sl.No',
    //   field: 'id',
    //   ...defaultColumnProps1,
    //   fixed: true,
    //   sortKey: 'id',
    //   // rowClick: (row: any) => onViewAction(row, 'contactName'),
    //   // link: true,
    // },
    {
      title: 'Order Id',
      field: 'id',
      fixed: true,
      ...defaultColumnProps1,
    },
    {
      title: 'Manufacturer Name',
      field: 'name',
      ...defaultColumnProps1,
      // customCell: true,
      // sortKey: 'cre_agent_user_first_name',
    },
    {
      title: 'Phone Number',
      field: 'phone_number',
      ...defaultColumnProps1,
      // sortKey: 'company_code',
    },
    {
      title: 'Email',
      field: 'email',
      ...defaultColumnProps1,

      sortKey: '',
      // renderCell: createRenderCell('primary_company.business_name'),
      // rowClick: (row: any) => onViewAction(row, 'business_name'),
    },
    {
      title: 'Status',
      renderCell: createRenderCell('default_contact.mobile', 'status'),
      field: 'mobile',
      ...defaultColumnProps1,
      customCell: true,
    },
  ]

  return column
}
