import React from 'react'
import { IconProps } from '../../../common/types'

const Replace: React.FC<IconProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 27 27"
      fill="none"
    >
      <path
        d="M5.7375 11.25C5.9715 9.636 6.72825 8.295 8.00775 7.227C9.288 6.159 10.7438 5.625 12.375 5.625C13.914 5.625 15.1301 5.98387 16.0234 6.70162C16.9174 7.41862 17.5763 8.0925 18 8.72325V5.625H19.125V11.25H13.5V10.125H17.4803C17.1097 9.32025 16.5015 8.55638 15.6555 7.83337C14.8095 7.11112 13.716 6.75 12.375 6.75C11.025 6.75 9.83437 7.17675 8.80312 8.03025C7.77187 8.88375 7.125 9.957 6.8625 11.25H5.7375ZM22.05 22.8893L16.7108 17.55C16.0793 18.0848 15.4009 18.4811 14.6756 18.7391C13.9519 18.9964 13.185 19.125 12.375 19.125C11.2688 19.125 10.2004 18.8528 9.16988 18.3083C8.13937 17.7638 7.33275 17.0033 6.75 16.0268V19.125H5.625V13.5H11.25V14.625H7.26975C7.683 15.603 8.349 16.41 9.26775 17.046C10.1858 17.682 11.2215 18 12.375 18C13.725 18 14.9156 17.5736 15.9469 16.7209C16.9781 15.8681 17.625 14.7945 17.8875 13.5H19.0125C18.9187 14.175 18.75 14.7574 18.5063 15.2471C18.2625 15.7369 17.9295 16.2394 17.5072 16.7546L22.8465 22.0928L22.05 22.8893Z"
        fill="black"
      />
    </svg>
  )
}

export default Replace
