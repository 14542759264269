const defaultColumnProps1 = {
  sortable: false,
  isVisible: true,
  resizable: true,
}
// const statusInfo: 1 | 2 = 1

export const getColumns = ({}: any) => {
  const column = [
    {
      title: 'Medicine Name',
      // renderCell: createRenderCell('cre_agent.name'),
      field: 'name',
      ...defaultColumnProps1,
      // customCell: true,
      // sortKey: 'cre_agent_user_first_name',

      customCell: true,
    },
    {
      title: 'Unit of Packing',
      field: 'unit_of_packing',
      ...defaultColumnProps1,

      customCell: true,
    },
    {
      title: 'Price/Unit',
      field: 'price_per_unit',
      ...defaultColumnProps1,
      sortKey: '',

      customCell: true,
    },
    {
      title: 'Requested Quantity',
      field: 'requested_quantity',
      ...defaultColumnProps1,

      sortKey: '',

      customCell: true,
    },
  ]

  return column
}
