import { useMutation, useQuery } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { getErrorMessage } from '../../../utilities/parsers'
import apiUrl from '../../../apis/api.url'
import { getData, updateFromData } from '../../../apis/api.helpers'

const buildUrlWithParams = (baseUrl: string, params: any) => {
  return `${baseUrl}/${params}`
}

const fetchData = async (id: any) => {
  const url = buildUrlWithParams(apiUrl.DISTRICT_ADMIN, id)
  const response = await getData(url)
  // console.log(response)
  return response
}

export const useDistrictAdmin = (id: any) => {
  return useQuery(['user_admin_detail'], () => fetchData(id), {
    refetchOnWindowFocus: false,
  })
}
export const updateDistrictAdmin = (data: {
  data: any
  districtAdminId: string
}) => {
  // console.log(data)
  return updateFromData(
    `${apiUrl.DISTRICT_ADMIN}/${data?.districtAdminId}`,
    data?.data
  )
}

export const useUpdateDistrictAdmin = (
  handleSubmission: (input: any, id: string) => void
) => {
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(updateDistrictAdmin, {
    onSuccess: (res: any) => {
      handleSubmission(res.data, res.id)
    },

    onError: (error: any) => {
      enqueueSnackbar(
        getErrorMessage(
          error?.response?.data?.message || 'Something went wrong'
        ),
        {
          variant: 'error',
        }
      )
    },
  })
}
