// import { getNestedProperty, useCurrencyFormat } from '../../utilities/parsers'
// import { useNavigate } from "react-router-dom"

// import { useNavigate } from "react-router-dom"

// import { Router } from "react-router-dom"

// const defaultColumnProps = {
//   sortable: true,
//   resizable: true,
//   isVisible: true,
// }
const defaultColumnProps1 = {
  sortable: false,
  isVisible: true,
  resizable: true,
}

export const getColumns = ({}: any) => {
  const createRenderCell = (key: string, isCustom?: string) => (row: any) => {
    if (isCustom === 'status') {
      // console.log(row)
      return {
        cell: (
          <>
            {row.status === 'requested' ? (
              // <div className="w-[127px] h-[40px] rounded-[20px] bg-ShortageStatus/15 flex justify-center">
              <span className="inline-block  text-AvailabilityMark font-medium leading-normal">
                Pending
              </span>
            ) : row.status === 'accepted' ? (
              // <div className="w-[127px] h-[40px] rounded-[20px] bg-ShortageStatus/15 flex justify-center">
              <span className="inline-block  text-primary font-medium leading-normal">
                Accepted
              </span>
            ) : row.status === 'approved' ? (
              // <div className="w-[127px] h-[40px] rounded-[20px] bg-ShortageStatus/15 flex justify-center">
              <span className="inline-block  text-primary font-medium leading-normal">
                Approved
              </span>
            ) : (
              // <div className="w-[127px] h-[40px] rounded-[20px] bg-RemoveButton/15 flex justify-center">
              <span className="inline-block  text-RemoveButton font-medium leading-normal">
                Reverted
              </span>
              // </div>
            )}
          </>
        ),
      }
    }
  }

  const column = [
    {
      title: 'Loan Id',
      // renderCell: createRenderCell('cre_agent.name'),
      field: 'loan_id',
      ...defaultColumnProps1,
    },
    {
      title: 'Name',
      // renderCell: createRenderCell('cre_agent.name'),
      field: 'name',
      ...defaultColumnProps1,
    },
    {
      title: 'District',
      field: 'district_name',
      ...defaultColumnProps1,
    },
    {
      title: 'Phone Number',
      field: 'phone_number',
      ...defaultColumnProps1,

      sortKey: '',
    },
    {
      title: 'Email ID',
      field: 'email',
      ...defaultColumnProps1,

      sortKey: '',
    },
    // {
    //   title: 'Status',
    //   renderCell: createRenderCell('default_contact.mobile'),
    //   field: 'mobile',
    //   ...defaultColumnProps1,
    //   customCell: true,
    //   // link: true,
    //   // getpath: () => '/dashboard',
    // },
    {
      title: 'Loan Status',
      renderCell: createRenderCell('default_contact.mobile', 'status'),
      field: 'status',
      ...defaultColumnProps1,
      customCell: true,
    },
  ]

  return column
}
