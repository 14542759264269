import { useQuery } from '@tanstack/react-query'

import { getData } from '../../../apis/api.helpers'
import apiUrl from '../../../apis/api.url'
import { QueryParams } from '../../../common/types'
import { parseQueryParams } from '../../../utilities/parsers'

const buildUrlWithParams = (baseUrl: string, params: any) => {
  return `${baseUrl}${parseQueryParams(params)}`
}

const fetchData = async (input: QueryParams) => {
  console.log(input)
  const url = buildUrlWithParams(apiUrl.DOCTOR, {
    ...input,
    ...(input.stream !== undefined
      ? { stream: input.stream === 0 ? '0' : input.stream }
      : {}),
  })
  const response = await getData(url)
  // console.log(response)
  return response
}

export const useDoctors = (input: QueryParams) => {
  console.log()
  return useQuery(['doctors_list', input], () => fetchData(input), {
    refetchOnWindowFocus: false,
  })
}
