// import { useEffect, useState } from 'react'
// import { generatePath, Outlet, useNavigate, useParams } from 'react-router-dom'

// import { TabItemProps } from '../../../common/types'
// import { TabContainer } from '../../../components/common'
// import DetailHeader from '../../../components/common/DetailTiles'
// import { router_config } from '../../../configs/route.config'
// import { getContactDetails, updateContactCre } from '../api'
// import { useSnackbar } from 'notistack'
// import { getAllEmployeeList } from '../../../apis/common.apis'
// let updateKey = 1
// const ContactDetails = () => {
//   const navigate = useNavigate()
//   const [edit] = useState(false)
//   const [data, setData] = useState<any>()
//   const [drpdownItems, setDrpdownItems] = useState<any>()

//   const params = useParams()
//   const tabData = [
//     {
//       label: router_config.CONTACT_DETAILS_SUMMARY.label,
//       id: 'CONTACT_DETAILS_SUMMARY',
//     },
//     {
//       label: router_config.CONTACT_DETAILS_ACCOUNTS.label,
//       id: 'CONTACT_DETAILS_ACCOUNTS',
//     },
//     {
//       label: router_config.CONTACT_DETAILS_POSSIBLE_DUPLICATES.label,
//       id: 'CONTACT_DETAILS_POSSIBLE_DUPLICATES',
//     },
//   ]
//   const [activeTab, setActiveTab] = useState<string>('CONTACT_DETAILS_SUMMARY')

//   const haandleTabChange = (selected: TabItemProps) => {
//     navigate(
//       generatePath(router_config[selected.id]?.path as string, {
//         id: params.id,
//       })
//     )
//   }
//   const getEmployee = async () => {
//     const data = await getAllEmployeeList()
//     const empDetails = data?.reduce(
//       (acc: any, current: any) => acc.concat(current),
//       []
//     )
//     const drpdownItems = empDetails?.map((item: any) => ({
//       label: item.name,
//       value: item.name,
//       id: item.id,
//     }))
//     setDrpdownItems(drpdownItems)
//   }

//   useEffect(() => {
//     getData()
//     getEmployee()

//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [])

//   const getData = async () => {
//     const { data } = await getContactDetails(params.id)
//     setData(data)
//   }

//   const tiledata = [
//     {
//       id: 1,
//       tiles: [
//         {
//           label: 'CRE',
//           value: data?.cre_agent?.name,
//           id: 1,
//         },
//         {
//           icon: 'phone',
//           value: data?.default_contact?.mobile,
//           id: 2,
//         },
//         {
//           icon: 'email',
//           value: data?.default_contact?.email,
//           id: 3,
//         },
//       ],
//     },
//     {
//       id: 2,
//       tiles: [
//         {
//           id: 1,
//           icon: 'home',
//           value: ` ${data?.addresses[0]?.city ?? ''}, ${data?.addresses[0]
//             ?.state} `,
//         },
//       ],
//     },
//   ]
//   const chipdata = [
//     { id: 1, status: 'Normal' },
//     { id: 2, status: 'Normal' },
//     { id: 3, status: 'Normal' },
//   ]
//   const basicData = {
//     title: `${data?.default_contact?.first_name ?? '--'} ${
//       data?.default_contact?.last_name ?? '--'
//     } (${data?.customer_number ?? '--'})`,
//     subtitle: `${
//       data?.primary_company?.business_name
//         ? `${data?.primary_company?.business_name} (${data?.primary_company?.company_code})`
//         : '--'
//     } + ${data?.no_of_accounts ?? '--'}`,
//     subLink: `${router_config.ACCOUNTS.path}/${data?.primary_company?.id}/summary`,
//     image: data?.profile_image,
//   }

//   const handleCallback = () => {
//     getData()
//   }
//   const contextData = {
//     setActiveTab: (id: string) => setActiveTab(id),
//     edit: edit,
//     handleParentCallback: handleCallback,
//   }
//   const { enqueueSnackbar } = useSnackbar()
//   const updateCRE = (id?: string) => {
//     const details = { assigned_cre_id: id ?? null }
//     updateContactCre(data?.id as string, details).then(() => {
//       enqueueSnackbar('CRE updated successfully', { variant: 'success' })
//       getData()
//       updateKey = updateKey + 1
//     })

//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }
//   const handleUpdateCre = (id?: string) => {
//     updateCRE(id)
//   }
//   return (
//     <div>
//       <div>
//         <DetailHeader
//           tiledata={tiledata}
//           chipdata={chipdata}
//           data={basicData}
//           menuItems={drpdownItems}
//           setUpdateCREId={handleUpdateCre}
//         />
//         {/* <PageTiles data={dashtiledata} handleTileClick={handleTileClick} /> */}
//       </div>
//       <div className="flex gap-4">
//         <TabContainer
//           data={tabData}
//           activeTab={activeTab}
//           onClick={haandleTabChange}
//         >
//           <Outlet context={contextData} />
//         </TabContainer>
//       </div>
//     </div>
//   )
// }

// export default ContactDetails

// import FormBuilder from '../../../components/app/formBuilder/index'

import { useCallback, useEffect, useState } from 'react'
import { Button } from '../../../../../components/common'
// import { useNavigate } from 'react-router-dom'

import { ConfirmModal } from '../../../../../components/common'
import { QbsTable } from 'qbs-react-grid'
import moment from 'moment'
import ListingHeader from '../../../../../components/common/ListingTiles'
import { router_config } from '../../../../../configs/route.config'

import { useContactFilterStore } from '../../../../../store/filterSore/contactStore'
import { statusClassGen } from '../../../../../utilities/generators'
import {
  getErrorMessage,
  getSortedColumnName,
} from '../../../../../utilities/parsers'
import {
  deleteOrderItems,
  useOrderDetailList,
  useOrderDetails,
  useStatusChange,
} from '../../api'
import { getColumns } from './contactColumnsDetail'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import Icons from '../../../../../components/common/icons'
import { useSnackbar } from 'notistack'
import { calcWindowHeight } from '../../../../../utilities/calcHeight'

const OrderRequestDetailHospitalReverted = () => {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const [setCustomBack] = useState(true)

  const [mergeIsActive, setMergeIsActve] = useState(false)

  const { pageParams, setPageParams, selectedRows, setSelectedRows } =
    useContactFilterStore()

  const [customSearch] = useState('')
  const [customType] = useState('')
  const [dialogModalActive, setDialogModalActive] = useState(false)
  const [successModalActive, setSuccessModalActive] = useState(false)
  const [messageModalActive, setMessageModalActive] = useState(false)
  const { page, page_size, ordering, filters, filterProps } = pageParams

  const searchParams = {
    page_number: page,
    page_size: page_size,
    name: customSearch,
    ...(customType === 'select'
      ? { instution_type: '' }
      : { instution_type: customType }),
    ordering: ordering,
    ...filters,
  }
  const param = useParams()
  const onSuccess = () => {
    setSuccessModalActive(true)
    setDialogModalActive(false)
    // refetch()
  }
  const { mutate } = useStatusChange(onSuccess)
  const { data, refetch, isFetching } = useOrderDetails(param.id, searchParams)
  const { data: list, refetch: fetchList } = useOrderDetailList(
    param.id,
    searchParams
  )
  console.log(list)
  useEffect(() => {
    fetchList()
    refetch()
  }, [
    page,
    page_size,
    ordering,
    filters,
    refetch,
    customSearch,
    customType,
    fetchList,
  ])
  const onChangeRowsPerPage = (count: any) => {
    setPageParams({
      ...pageParams,
      page_size: count,
      page: 1,
    })
  }
  const onChangePage = (row: any) => {
    setPageParams({
      ...pageParams,
      page: row,
    })
  }

  const onViewAction = (r: any, from: string) => {
    console.log(r, from)
  }
  const handleSort = (orderColumn: any, orderDirection: any) => {
    setPageParams({
      ...pageParams,
      sortColumn: orderColumn,
      sortType: orderDirection,
      ordering: getSortedColumnName(orderColumn, orderDirection),
    })
  }
  const handleSeach = (key?: string) => {
    setPageParams({
      ...pageParams,
      search: key as string,
      page: 1,
    })
  }
  const handleOnSlect = (rows: any) => {
    setSelectedRows([...rows])
  }

  const basicData = {
    title: 'Order Details',
    icon: 'order',
  }

  useEffect(() => {
    handleDatepicker()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterProps.endDate])

  const handleDatepicker = useCallback(() => {
    setPageParams({
      ...pageParams,
      filters: {
        ...filters,
        fromDate: filterProps?.startDate
          ? moment(filterProps?.startDate).format('DD-MM-YYYY')
          : '',
        toDate: filterProps?.endDate
          ? moment(filterProps.endDate).format('DD-MM-YYYY')
          : '',
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterProps.endDate])

  const handleDelete = (r: any) => {
    console.log(list)
    if (list?.total_count === 1) {
      enqueueSnackbar('Cannot delete the only item'),
        {
          variant: 'error',
        }
    } else if (list?.total_count > 1) {
      deleteOrderItems(param.id, r.id)
        .then(() => {
          // setSuccessModalActive(true)
          refetch()
        })
        .catch((err: any) => {
          console.log(err)
          enqueueSnackbar(getErrorMessage(err?.response?.data?.message), {
            variant: 'error',
          })
        })
    }
  }
  const onAction = (r: any) => {
    handleDelete(r)
  }
  const [columns, setColumns] = useState<any>(() =>
    getColumns({
      onViewAction: onViewAction,
      statusClassGen: statusClassGen,
      onAction: onAction,
      total: list?.total_count,
    })
  )

  const handleResetColums = () => {
    setColumns([
      ...getColumns({
        onViewAction: onViewAction,
        statusClassGen: statusClassGen,
      }),
    ])
  }

  if (mergeIsActive) {
    return <Outlet context={{ selectedRows, refetch }} />
  }
  const activity = {
    hideExport: true,
  }

  const handleCreate = () => {
    setDialogModalActive(true)
  }
  const handleClose = () => {
    setDialogModalActive(false)
  }
  const handleMessageOpen = () => {
    setMessageModalActive(true)
  }
  const handleMessageClose = () => {
    setMessageModalActive(false)
  }
  const handleMerge = () => {
    navigate(`${router_config.CONTACT_MERGE.path}`)
    setMergeIsActve(true)
  }
  const handleSubmit = () => {
    // handleClose()
    const id = param.id
    const input = {
      order: {
        status: 'pending',
      },
    }

    mutate({ id, input })

    setSuccessModalActive(true)
  }
  const handleCloseModal = () => {
    setSuccessModalActive(false)
    navigate('/medicine/reverted')
  }
  return (
    <>
      <ListingHeader
        data={basicData}
        setCustomBack={setCustomBack}
        actionProps={activity}
      />

      <div className=" px-[24px] py-[24px]">
        <div className="">
          <div className="w-100 pb-[24px]">
            <div className="flex gap-4 justify-between w-100">
              <div className=" lg:text-xs text-[10px] px-1 2xl:px-2 font-normal leading-normal align-middle h-[40px]	text-SidenavFontColor">
                <div className="font-bold text-black">Manufacturer</div>
                <div>{data?.manufacturer_name}</div>
              </div>
              <div className=" lg:text-xs text-[10px] px-5 2xl:px-8 font-normal leading-normal align-middle h-[40px]	text-SidenavFontColor">
                <div className="font-bold text-black">Total Items</div>
                <div> {list?.order_items.length} items</div>
              </div>
              <div className=" lg:text-xs text-[10px] px-5 2xl:px-8 font-normal leading-normal align-middle h-[40px]	text-SidenavFontColor">
                <div className="font-bold text-black">Total Amount</div>
                <div>{data?.total_amount}</div>
              </div>
              <div className=" lg:text-xs text-[10px] px-5 2xl:px-8 font-normal leading-normal align-middle h-[40px]	text-SidenavFontColor">
                <div className="font-bold text-black">Purchased On</div>
                <div>
                  {' '}
                  {data?.created_at
                    ? moment(data?.created_at).format('DD-MM-YYYY')
                    : 'Not Available'}
                </div>
              </div>
              <div className=" lg:text-xs text-[10px] px-5 2xl:px-8 font-normal leading-normal align-middle h-[40px]	text-SidenavFontColor">
                <div className="font-bold text-black">Fund Used</div>
                <div>{data?.fund}</div>
              </div>
              <div className=" lg:text-xs text-[10px] px-5 2xl:px-8 font-normal leading-normal align-middle h-[40px]	text-SidenavFontColor">
                <div className="font-bold text-black">Request Status</div>
                <div className="text-RevertedStatus">
                  {data?.status === 'reverted' && <div>Reverted</div>}
                </div>
              </div>
            </div>
          </div>
          <hr />
          {dialogModalActive && (
            <ConfirmModal
              isOpen={true}
              body={
                <>
                  <div className="p-10">
                    <div className="font-medium leading-8 text-xl ">
                      Are you sure you want continue with the order?{' '}
                    </div>
                    <div className="flex justify-center my-5">
                      <Icons name="trash" className="w-[200px] h-[150px]" />
                    </div>
                    <div className="flex justify-center gap-4 mt-10">
                      <Button
                        label={'Yes'}
                        primary={false}
                        type="reset"
                        className="bg-AvailabilityMark/40 text-AvailabilityMark"
                        onClick={handleSubmit}
                      />
                      <Button
                        label={'No'}
                        primary={false}
                        type="reset"
                        className="bg-RemoveButton/40 text-RemoveButton"
                        onClick={handleClose}
                      />
                    </div>
                  </div>
                </>
              }
              onClose={handleClose}
              // title={'Add New Pharmacist'}
            />
          )}
          {messageModalActive && (
            <ConfirmModal
              isOpen={true}
              body={
                <>
                  <div className="2xl:p-5 p-2">
                    <div className="font-medium leading-8 text-xs border rounded-lg border-RemoveButton min-h-40 text-center p-3 ">
                      {data?.message}
                    </div>
                  </div>
                  <div className="flex justify-center 2xl:mt-5 mt-5">
                    <Button
                      label={'OK'}
                      primary={true}
                      type="reset"
                      className=""
                      onClick={handleMessageClose}
                    />
                  </div>
                </>
              }
              onClose={handleMessageClose}
              // title={'Add New Pharmacist'}
            />
          )}

          <div className=" pt-[12px]">
            <QbsTable
              columns={columns}
              dataRowKey="id"
              selectedRows={selectedRows}
              // selection={true}
              isLoading={isFetching}
              toolbar
              handleColumnSort={handleSort}
              height={calcWindowHeight(458)}
              data={list?.order_items ?? []}
              handleResetColumns={() => handleResetColums()}
              // sortType={sortType}
              // sortColumn={sortColumn}
              pagination
              paginationProps={{
                onPagination: onChangePage,
                total: data?.total_count,
                currentPage: pageParams?.page,
                rowsPerPage: Number(pageParams?.page_size ?? data?.page_size),
                onRowsPerPage: onChangeRowsPerPage,
                dropOptions: [10, 20, 30, 50, 100, 200],
              }}
              tableHeaderActions={
                <div className="flex justify-around gap-3 mt-2 me-2.5">
                  <Button
                    label={'Revert Message'}
                    primary={true}
                    outlined
                    type="reset"
                    className="min-w-fit text-AvailabilityMark"
                    onClick={handleMessageOpen}
                  />
                  <Button
                    label={'Continue with Order'}
                    primary={true}
                    type="reset"
                    className="min-w-fit"
                    onClick={handleCreate}
                  />
                </div>
              }
              actionProps={[
                {
                  title: 'Delete',
                  action: (rowData) => onAction(rowData),
                  icon: <Icons name="delete" />,
                  toolTip: 'Delete',
                },
              ]}
              selectedRowActions={[
                {
                  actionTitle: 'Merge',
                  action: () => handleMerge(),
                  customHide: '>2',
                },
              ]}
              // searchValue={pageParams?.search}
              // onSearch={handleSeach}
              asyncSearch
              handleSearchValue={(key?: string) => handleSeach(key)}
              onSelect={handleOnSlect}
              columnToggle
            />
          </div>
          {successModalActive && (
            <ConfirmModal
              isOpen={true}
              body={
                <>
                  <div className="font-medium text-primary leading-8 text-xl">
                    Order has been Successfully Placed{' '}
                  </div>
                  {/* <div className="leading-normal font-normal">
                    {' '}
                    Please check your mail for details
                  </div> */}
                </>
              }
              topIcon="check-circle"
              onClose={handleCloseModal}
              // title={'Add New Pharmacist'}
            />
          )}
        </div>
      </div>
    </>
  )
}
export default OrderRequestDetailHospitalReverted
