// import { getNestedProperty, useCurrencyFormat } from '../../utilities/parsers'
// import { useNavigate } from "react-router-dom"

// import { useNavigate } from "react-router-dom"

// import { Router } from "react-router-dom"

// const defaultColumnProps = {
//   sortable: true,
//   resizable: true,
//   isVisible: true,
// }
const defaultColumnProps1 = {
  sortable: false,
  isVisible: true,
  resizable: true,
}
// const defaultColumnProps2 = {
//   sortable: true,
//   isVisible: false,
//   resizable: true,
// }
// const defaultColumnProps3 = {
//   sortable: false,
//   isVisible: true,
//   resizable: true,
// }

// const navigate = useNavigate()
// const statusInfo: 1 | 2 = 1
export const getColumns = ({}: any) => {
  const column = [
    {
      title: 'Name',
      // renderCell: createRenderCell('cre_agent.name'),
      field: 'name',
      ...defaultColumnProps1,
    },
    {
      title: 'District',
      field: 'district_name',
      ...defaultColumnProps1,
    },
    {
      title: 'Phone Number',
      field: 'phone_number',
      ...defaultColumnProps1,

      sortKey: '',
    },
    {
      title: 'Email ID',
      field: 'email',
      ...defaultColumnProps1,

      sortKey: '',
    },
  ]

  return column
}
