import React from 'react'
import { IconProps } from '../../../common/types'

const MessageBox: React.FC<IconProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
    >
      <path
        d="M3 4C1.344 4 0 5.344 0 7V19C0 20.656 1.344 22 3 22H23C24.656 22 26 20.656 26 19V7C26 5.344 24.656 4 23 4H3ZM3 6H23C23.551 6 24 6.449 24 7V7.5L13 13.438L2 7.5V7C2 6.449 2.449 6 3 6ZM2 7.781L8.531 12.875L2.125 19.438L9.938 13.875L13 15.844L16.063 13.875L23.875 19.438L17.469 12.875L24 7.781V19C23.9945 19.154 23.9516 19.3043 23.875 19.438C23.71 19.763 23.389 20 23 20H3C2.611 20 2.29 19.764 2.125 19.438C2.04859 19.3046 2.00569 19.1536 2 19V7.781Z"
        fill="#4CAF50"
      />
    </svg>
  )
}

export default MessageBox
