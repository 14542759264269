import { useMutation, useQuery } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { getErrorMessage } from '../../../utilities/parsers'
import apiUrl from '../../../apis/api.url'
import { getData, updateFromData } from '../../../apis/api.helpers'

const buildUrlWithParams = (baseUrl: string, params: any) => {
  return `${baseUrl}/${params}`
}

const fetchData = async (id: any) => {
  const url = buildUrlWithParams(apiUrl.CREATE_MANUFACTURER, id)
  const response = await getData(url)
  // console.log(response)
  return response
}

export const useManufacturer = (id: any) => {
  return useQuery(['manufacturer_detail'], () => fetchData(id), {
    refetchOnWindowFocus: false,
  })
}
export const updateManufacturer = (data: {
  data: any
  manufacturerId: string
}) => {
  // console.log(data)
  return updateFromData(
    `${apiUrl.CREATE_MANUFACTURER}/${data?.manufacturerId}`,
    data?.data
  )
}

export const useUpdateManufacturer = (
  handleSubmission: (input: any, id: string) => void
) => {
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(updateManufacturer, {
    onSuccess: (res: any) => {
      handleSubmission(res.data, res.id)
    },

    onError: (error: any) => {
      enqueueSnackbar(
        getErrorMessage(
          error?.response?.data?.message || 'Something went wrong'
        ),
        {
          variant: 'error',
        }
      )
    },
  })
}
