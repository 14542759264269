import { useEffect } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { router_config } from '../../configs/route.config'

import { useAppStore } from '../../store/appStore'
import { useAuthStore } from '../../store/authStore'

type Props = {
  children: React.ReactNode
  slug_key: string
}

const ChildRoute = ({ children, slug_key }: Props) => {
  console.log(children, 'chi')
  const { authenticated, role } = useAuthStore()
  const { setActiveRouteSlug } = useAppStore()
  const location = useLocation()
  // const isAdmin = useAuthStore.getState().userData?.is_admin || false

  useEffect(() => {
    setActiveRouteSlug(slug_key)
    // eslint-disable-next-line
  }, [slug_key, location?.pathname])
  if (!authenticated) return <Navigate to="/login" replace />

  const hasPermission = () => {
    const currentRouteConfig = router_config[slug_key]
    if (currentRouteConfig && currentRouteConfig.permission_slugs.length > 0) {
      return currentRouteConfig.permission_slugs.some((item) => item === role)
    }
    return true
  }
  return <>{hasPermission() ? <>{children}</> : <>No Permission</>}</>
}
export default ChildRoute
