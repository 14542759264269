import { useCallback, useEffect, useState } from 'react'

import { Button, ConfirmModal } from '../../../../../components/common'
import { QbsTable } from 'qbs-react-grid'
import moment from 'moment'
import ListingHeader from '../../../../../components/common/ListingTiles'
// import { router_config } from '../../../../configs/route.config'

import { useContactFilterStore } from '../../../../../store/filterSore/contactStore'
import { statusClassGen } from '../../../../../utilities/generators'
import { getSortedColumnName } from '../../../../../utilities/parsers'
import { getColumns } from './contactColumns'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { calcWindowHeight } from '../../../../../utilities/calcHeight'
import { useHospitallist } from '../../all/api'
import { useForwardRequest } from './api'
import { router_config } from '../../../../../configs/route.config'
import { useSnackbar } from 'notistack'
// import { useOrderStatus } from '../../../Manufacturer admin/order request/api'

const DistrictAdminLoanRequestHospitals = () => {
  const navigate = useNavigate()
  // const param = useSearchParams()
  // console.log(param)
  const [setCustomBack] = useState(true)
  const { id2 } = useParams()
  // console.log(id2)
  const { enqueueSnackbar } = useSnackbar()
  const { pageParams, setPageParams, selectedRows, setSelectedRows } =
    useContactFilterStore()
  // const { advanceFilter, setAdvanceFilter } = useContactFilterStore()
  // const [showDetail, setShowDetail] = useState(true)
  // console.log(id)
  const [customType] = useState('')
  const location = useLocation()

  const [successModalActive, setSuccessModalActive] = useState(false)
  const [rejectModalActive, setRejectModalActive] = useState(false)
  const [revertModalActive, setRevertModalActive] = useState(false)
  const { page, page_size, search, filters, filterProps } = pageParams

  console.log(location)
  const searchParams = {
    page_number: page,
    page_size: page_size,
    name: search,

    ...filters,
  }
  const { data, refetch, isFetching } = useHospitallist(id2, searchParams)
  const onsuccess = () => {
    setSuccessModalActive(true)
  }
  const { mutate } = useForwardRequest(onsuccess)
  useEffect(() => {
    refetch()
  }, [page, page_size, search, filters, customType])
  // console.log(customSearch)
  const onChangeRowsPerPage = (count: any) => {
    setPageParams({
      ...pageParams,
      page_size: count,
      page: 1,
    })
  }
  const onChangePage = (row: any) => {
    setPageParams({
      ...pageParams,
      page: row,
    })
  }

  const onViewAction = (r: any, from: string) => {
    console.log(r, from)
  }
  const handleSort = (orderColumn: any, orderDirection: any) => {
    setPageParams({
      ...pageParams,
      sortColumn: orderColumn,
      sortType: orderDirection,
      ordering: getSortedColumnName(orderColumn, orderDirection),
    })
  }
  const handleSeach = (key?: string) => {
    setPageParams({
      ...pageParams,
      search: key as string,
      page: 1,
    })
  }
  const handleOnSlect = (rows: any) => {
    setSelectedRows([...rows])
  }

  const basicData = {
    title: 'Hospitals',
    icon: 'institution',
  }

  useEffect(() => {
    handleDatepicker()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterProps.endDate])

  const handleDatepicker = useCallback(() => {
    setPageParams({
      ...pageParams,
      filters: {
        ...filters,
        fromDate: filterProps?.startDate
          ? moment(filterProps?.startDate).format('DD-MM-YYYY')
          : '',
        toDate: filterProps?.endDate
          ? moment(filterProps.endDate).format('DD-MM-YYYY')
          : '',
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterProps.endDate])

  const [columns, setColumns] = useState<any>(() =>
    getColumns({
      onViewAction: onViewAction,
      statusClassGen: statusClassGen,
    })
  )

  const handleResetColums = () => {
    setColumns([
      ...getColumns({
        onViewAction: onViewAction,
        statusClassGen: statusClassGen,
      }),
    ])
  }

  const activity = {
    hideExport: true,
  }

  const handleRejectPopUpClose = () => {
    setRejectModalActive(false)
  }

  const handleCloseModal = () => {
    setSuccessModalActive(false)
    setSelectedRows([])
    navigate(`${router_config.DISTRICT_ADMIN_LOAN_REQUEST.path}/all`)
  }
  const handleCreate = () => {
    const newArray: any = []
    selectedRows?.map((item: any) => {
      const n = Number(item)
      newArray.push(n)
    })
    const data = {
      institution_ids: newArray,
    }
    if (newArray.length > 0) {
      mutate({ data, id2 })
    } else {
      enqueueSnackbar('Select at least one hospital', {
        variant: 'error',
      })
    }

    // console.log(selectedRows)
  }

  const handleCloseRevertModal = () => {
    setRevertModalActive(false)
    navigate('/order/pending')
  }

  return (
    <>
      <ListingHeader
        data={basicData}
        actionProps={activity}
        setCustomBack={setCustomBack}
      />
      <div className=" px-[24px] py-[24px]">
        <div className="">
          {/* <PageTiles data={dashtiledata} handleTileClick={handleTileClick} /> */}
          <div className="">
            <QbsTable
              columns={columns}
              dataRowKey="id"
              selectedRows={selectedRows}
              selection={true}
              isLoading={isFetching}
              toolbar
              search
              handleColumnSort={handleSort}
              height={calcWindowHeight(410)}
              data={
                data?.institutions.map((item: any) => ({
                  ...item,
                  id: item.id.toString(),
                })) || []
              }
              handleResetColumns={() => handleResetColums()}
              // sortType={sortType}
              // sortColumn={sortColumn}
              pagination
              paginationProps={{
                onPagination: onChangePage,
                total: data?.institutions?.length || null,
                currentPage: pageParams?.page,
                rowsPerPage: Number(pageParams?.page_size ?? data?.page_size),
                onRowsPerPage: onChangeRowsPerPage,
                dropOptions: [10, 20, 30, 50, 100, 200],
              }}
              tableHeaderActions={
                <div className="flex gap-2">
                  <Button
                    onClick={handleCreate}
                    label={'Forward Request'}
                    className="w-[175px]"
                  />
                </div>
              }
              searchValue={pageParams?.search}
              onSearch={handleSeach}
              asyncSearch
              handleSearchValue={(key?: string) => handleSeach(key)}
              onSelect={handleOnSlect}
              columnToggle
            />
          </div>
          {successModalActive && (
            <ConfirmModal
              isOpen={true}
              body={
                <>
                  <div className="font-medium text-primary leading-8 text-xl">
                    Request Forwarded Successfully{' '}
                  </div>
                  {/* <div className="leading-normal">Order ID 234567</div> */}
                </>
              }
              topIcon="check-circle"
              onClose={handleCloseModal}
              // title={'Add New Pharmacist'}
            />
          )}
          {revertModalActive && (
            <ConfirmModal
              isOpen={true}
              body={
                <>
                  <div className="font-medium text-primary leading-8 text-xl">
                    Order Reverted Successfully
                  </div>
                  {/* <div className="leading-normal">Order ID 234567</div> */}
                </>
              }
              topIcon="check-circle"
              onClose={handleCloseRevertModal}
              // title={'Add New Pharmacist'}
            />
          )}
          {rejectModalActive && (
            <ConfirmModal
              isOpen={true}
              body={
                <>
                  <div className="font-medium text-primary leading-8 text-xl">
                    The Order is Rejected.
                  </div>
                </>
              }
              topIcon="check-circle"
              onClose={handleRejectPopUpClose}
              // title={'Add New Pharmacist'}
            />
          )}
        </div>
      </div>
    </>
  )
}
export default DistrictAdminLoanRequestHospitals
