import { Outlet, useNavigate } from 'react-router-dom'
import { TabContainer } from '../../../components/common'
import { router_config } from '../../../configs/route.config'
import { useState } from 'react'
// import ListingHeader from '../../../components/common/ListingTiles/index-new'
import ListingHeader from '../../../components/common/ListingTiles'

const tabData: any[] = [
  {
    label: 'Pending Orders',

    id: 'PENDING_ORDER',
  },
  {
    label: 'Confirmed Orders',

    id: 'CONFIRMED_ORDER',
  },
  {
    label: 'Delivered Orders',

    id: 'DELIVERED_ORDER',
  },
  {
    label: 'Reverted Orders',

    id: 'REVERTED_ORDER',
  },
  {
    label: 'Rejected Orders',

    id: 'REJECTED_ORDER',
  },
  {
    label: 'Replaced Orders',

    id: 'REPLACED_ORDER',
  },
]

const OrderRequestmain: React.FC<any> = () => {
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState('PENDING_ORDER')
  const onTabClick = (selection: any) => {
    setActiveTab(selection.id)
    navigate(`/order/${router_config[selection.id]?.path}`)
  }
  const context = { setActiveTab }
  const activity = {
    hideExport: true,
  }
  const basicData = {
    title: 'Order Request',
    icon: 'institution',
  }
  return (
    <>
      <ListingHeader
        data={basicData}
        actionTitle="Add Institution"
        actionProps={activity}
      />
      <TabContainer data={tabData} activeTab={activeTab} onClick={onTabClick}>
        <Outlet context={context} />
      </TabContainer>
    </>
  )
}

export default OrderRequestmain
