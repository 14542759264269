import { ButtonProps } from '../../../common/types'

import React from 'react'
import { ButtonSpinner, Icon } from '../index'
import { debounce } from '../../../utilities/debounce'

const Button: React.FC<ButtonProps> = ({
  label,
  icon,
  iconAlignment = 'left',
  type = 'button',
  onClick = undefined,
  size = 'sm',
  className = '',
  outlined = false,
  primary = true,
  fullwidth = false,
  isLoading = false,
  disabled = false,
}) => {
  const generateClassName = () => {
    let genclass = `btn relative`
    if (size === 'sm') {
      genclass += ' h-9 text-sm p-2 min-w-[65px]'
    } else {
      genclass += ' h-6 text-xxs leading-4 px-2 py-0 min-w-[56px] iconWidthSm'
    }
    if (primary) {
      genclass += ` ${outlined ? 'btn-primary-outlined ' : 'btn-primary '}`
    } else {
      genclass += `${outlined ? 'btn-secondary-outlined ' : 'btn-secondary '}`
    }

    if (fullwidth) {
      genclass += ` w-full`
    }
    if (className) {
      genclass += ` ${className}`
    }
    return genclass
  }
  const handleClick = debounce((e: any) => {
    onClick?.(e)
  }, 500)
  return (
    <>
      <button
        type={type}
        disabled={disabled || isLoading}
        onClick={primary && !outlined ? handleClick : onClick}
        className={`relative rounded-[10px] h-[40px] w-[127px] shadow-buttonShadow  ${generateClassName()}`}
      >
        <div className="flex items-center justify-center gap-1  m-auto ">
          {!icon && isLoading && (
            <div className="absolute w-full h-full flex items-center justify-center">
              <ButtonSpinner position="center" />
            </div>
          )}
          {icon && iconAlignment === 'left' && (
            <>
              {isLoading ? (
                <ButtonSpinner />
              ) : (
                <Icon
                  name={icon}
                  className={`flex items-center justify-center ${
                    outlined ? '' : 'iconWhite'
                  }`}
                  data-testid="button-icon-left"
                />
              )}
            </>
          )}
          {label ? (
            <div
              className={`  font-semibold  ${
                outlined ? 'text-primary ' : 'text-bgWhite  '
              }`}
            >
              {label}
            </div>
          ) : (
            ''
          )}
          {icon && iconAlignment === 'right' && (
            <>
              {isLoading ? (
                <ButtonSpinner position="right" />
              ) : (
                <Icon
                  name={icon}
                  className={`flex items-center justify-center ${
                    outlined ? '' : 'text-white'
                  }`}
                  data-testid="button-icon-right"
                />
              )}
            </>
          )}
        </div>
      </button>
    </>
  )
}

export default Button
