const defaultColumnProps1 = {
  sortable: false,
  isVisible: true,
  resizable: true,
}

export const getColumns = ({}: any) => {
  const createRenderCell = (key: string, isCustom?: string) => (row: any) => {
    console.log(key, isCustom)

    console.log(key)
    if (isCustom === 'tax' && isCustom) {
      console.log(row)
      return {
        cell: <div>5%</div>,
        // toolTip: getNestedProperty(row, key) ?? '',
      }
    }
  }

  const column = [
    {
      title: 'Medicine Name ',
      // renderCell: createRenderCell('cre_agent.name'),
      field: 'name',
      ...defaultColumnProps1,
      // customCell: true,
      // sortKey: 'cre_agent_user_first_name',
    },
    {
      title: 'Unit of Packing',
      field: 'unit_of_packing',
      ...defaultColumnProps1,
    },
    {
      title: 'Price/Unit',
      field: 'price',
      ...defaultColumnProps1,
      sortKey: '',
      // renderCell: createRenderCell('primary_company.business_name'),
      // rowClick: (row: any) => onViewAction(row, 'business_name'),
    },
    {
      title: 'Tax',
      // field: 'tax',
      ...defaultColumnProps1,
      customCell: true,
      // sortKey: '',
      renderCell: createRenderCell('primary_company.business_name', 'tax'),
      // rowClick: (row: any) => onViewAction(row, 'business_name'),
    },
    {
      title: 'Requested Quantity',
      field: 'quantity',
      ...defaultColumnProps1,
      sortKey: '',
      // renderCell: createRenderCell('primary_company.business_name'),
      // rowClick: (row: any) => onViewAction(row, 'business_name'),
    },
  ]

  return column
}
