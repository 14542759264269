// import { Link } from 'react-router-dom'
// import { router_config } from '../../../configs/route.config'
// import EditIcon from '../../../components/common/icons/Edit'
// import DeleteIcon from '../../../components/common/icons/Delete'

import moment from 'moment'

// }
const defaultColumnProps1 = {
  sortable: false,
  isVisible: true,
  resizable: true,
}
// const statusInfo: 1 | 2 = 1

export const getColumns = ({}: any) => {
  const createRenderCell = (key: string, isCustom?: string) => (row: any) => {
    // if (isCustom === 'action') {
    //   // console.log(row)
    //   return {
    //     cell: (
    //       <div className="flex justify-start gap-4">
    //         <div
    //           className="cursor-pointer"
    //           onClick={() => onAction(row, 'edit')}
    //         >
    //           {/* <Link
    //             to={`${router_config.PHARMACIST.path}/${row.id}`}
    //             className="text-capitalize"
    //           > */}
    //           <EditIcon />
    //           {/* </Link> */}
    //         </div>
    //         <div>
    //           <Link
    //             to={`${router_config.PHARMACIST.path}/${row.id}`}
    //             className="text-capitalize"
    //           >
    //             <div className="border-2 border-MandatoryRed/15 rounded-[10px] p-1">
    //               <DeleteIcon />
    //             </div>
    //           </Link>
    //         </div>
    //       </div>
    //     ),
    //   }
    // }
    if (isCustom === 'status') {
      // console.log(row)
      return {
        cell: (
          <>
            {row?.status === 'Shortage' ? (
              // <div className="w-[127px] h-[40px] rounded-[20px] bg-ShortageStatus/15 flex justify-center items-center">
              <span className="inline-block align-middle text-ShortageStatus items-center font-medium leading-normal">
                Shortage
              </span>
            ) : // </div>
            row?.status === 'Available' ? (
              // <div className="w-[127px] h-[40px] rounded-[20px] bg-AvailabilityMark/15 flex items-center justify-center">
              <span className="inline-block align-middle text-AvailabilityMark items-center font-medium leading-normal">
                Available
              </span>
            ) : (
              // </div>
              // <div className="w-[127px] h-[40px] rounded-[20px] bg-RemoveButton/15 flex items-center justify-center">
              <span className="inline-block align-middle text-RemoveButton items-center font-medium leading-normal">
                Out of Stock
              </span>
              // </div>
            )}
          </>
        ),
        // toolTip: getNestedProperty(row, key) ?? '',
      }
    } else if (isCustom === 'expDate') {
      return {
        cell: <>{row.exp_date && moment(row.exp_date).format('DD-MM-YYYY')}</>,
      }
    }
  }

  const column = [
    // {
    //   title: 'Sl.No',
    //   field: 'si_no',
    //   ...defaultColumnProps1,
    //   // fixed: true,
    //   sortKey: 'id',
    //   // rowClick: (row: any) => onViewAction(row, 'contactName'),
    //   // link: true,

    //   customCell: true,
    // },
    {
      title: 'Batch.No',
      field: 'batch_number',
      // fixed: true,
      ...defaultColumnProps1,
      // link: true,
      // sortKey: 'default_contact__first_name',
      // rowClick: (row: any) => onViewAction(row, 'contactName'),

      customCell: true,
    },
    {
      title: 'Medicine Name',
      // renderCell: createRenderCell('cre_agent.name'),
      field: 'medicine_name',
      ...defaultColumnProps1,
      // customCell: true,
      // sortKey: 'cre_agent_user_first_name',

      customCell: true,
    },
    {
      title: 'Unit of Packing',
      field: 'unit_of_packing',
      ...defaultColumnProps1,

      customCell: true,
    },
    {
      title: 'Available Stock',
      field: 'primary_stock',
      ...defaultColumnProps1,

      sortKey: '',

      customCell: true,
    },
    // {
    //   title: 'Status',
    //   renderCell: createRenderCell('default_contact.mobile'),
    //   field: 'mobile',
    //   ...defaultColumnProps1,
    //   customCell: true,
    //   // link: true,
    //   // getpath: () => '/dashboard',
    // },
    {
      title: 'Status',
      renderCell: createRenderCell('default_contact.mobile', 'status'),
      // field: 'mobile',
      ...defaultColumnProps1,
      customCell: true,
    },
    {
      title: 'Exp Date',
      // field: 'phone_number',
      ...defaultColumnProps1,
      // sortKey: 'company_code',
      renderCell: createRenderCell('primary_company.company_code', 'expDate'),

      customCell: true,
    },
  ]

  return column
}
