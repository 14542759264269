const defaultColumnProps1 = {
  sortable: false,
  isVisible: true,
  resizable: true,
}

export const getColumns = ({}: any) => {
  const column = [
    {
      title: 'Medicine name',
      field: 'medicine_name',
      fixed: true,
      ...defaultColumnProps1,
    },
    {
      title: 'Unit of Packing',
      field: 'unit_of_packing',
      ...defaultColumnProps1,
    },
    {
      title: 'Price/Unit',
      field: 'price_per_item',
      ...defaultColumnProps1,
    },
    {
      title: 'Quantity',
      field: 'quantity',
      ...defaultColumnProps1,
    },
    {
      title: 'Reason',
      field: 'reason',
      ...defaultColumnProps1,
    },
    {
      title: 'Total Amount',
      field: 'total_amount',
      ...defaultColumnProps1,
    },
  ]

  return column
}
