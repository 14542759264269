import { create } from 'zustand'

export const useAppStore = create<AppStoreType>()((set) => ({
  activeRouteSlug: 'DASHBOARD',
  isLoading: false,
  menuOpened: false,
  setIsLoading: (loaderState) => set(() => ({ isLoading: loaderState })),
  setMenuOpened: (menustate) => set(() => ({ menuOpened: menustate })),
  setActiveRouteSlug: (slug) => set(() => ({ activeRouteSlug: slug })),
}))

type AppStoreType = {
  isLoading: boolean | undefined
  menuOpened: boolean | undefined
  activeRouteSlug: string | undefined
  setIsLoading: (loaderState: boolean | undefined) => void
  setMenuOpened: (loaderState: boolean | undefined) => void
  setActiveRouteSlug: (slug: string) => void
}
