import { FormProvider, useForm } from 'react-hook-form'
import GeneralInfoForm from './form'
import { useOutletContext, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { usePatientListing } from '../api'

function PatientGeneralInfo() {
  const { setActiveTab } = useOutletContext<any>()
  const [data, setData] = useState<any>()
  // const location = useLocation()
  const { id2 } = useParams()
  console.log(id2)

  const { data: patients } = usePatientListing()
  useEffect(
    () => {
      setActiveTab('DOCTOR_PATIENTS_GENERAL')
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  )
  // console.log(data)
  useEffect(() => {
    patients?.op_records?.map((item: any) => {
      if (item?.id == id2) {
        setData(item)
      }
    })
  }, [patients, id2])

  useEffect(() => {
    methods.reset({
      ...data,
      age: data?.age || '0',
      number: data?.phone_number,
      op: data?.id,
      doctor: data?.doctor_name,
      district: data?.district_name,
    })
    console.log(data)
  }, [data])

  const methods = useForm<any>({
    // resolver: zodResolver(createSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  // console.log(methods.watch())
  // methods.reset({})

  return (
    <>
      <div className=" py-[24px]">
        <div className="grid md:grid-cols-3 md:gap-8 sm:grid-cols-1 sm:gap-[24px]">
          <FormProvider {...methods}>
            <GeneralInfoForm edit={true} />
          </FormProvider>
        </div>
      </div>
    </>
  )
}
export default PatientGeneralInfo
