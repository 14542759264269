const defaultColumnProps1 = {
  sortable: false,
  isVisible: true,
  resizable: true,
}

export const getColumns = ({}: any) => {
  const createRenderCell = (key: string, isCustom?: string) => (row: any) => {
    if (isCustom === 'total') {
      return {
        cell: <div>{row?.requested_quantity * row?.price_per_unit}</div>,
      }
    }
  }
  const column = [
    {
      title: 'Medicine name',
      field: 'name',
      fixed: true,
      ...defaultColumnProps1,
    },
    {
      title: 'Unit of Packing',
      field: 'unit_of_packing',
      ...defaultColumnProps1,
    },
    {
      title: 'Price/Unit',
      field: 'price_per_unit',
      ...defaultColumnProps1,
    },
    {
      title: 'Requested Quantity',
      field: 'requested_quantity',
      ...defaultColumnProps1,
    },
    {
      title: 'Total Amount',
      ...defaultColumnProps1,
      customCell: true,
      renderCell: createRenderCell('primary_company.business_name', 'total'),
    },
  ]

  return column
}
