import { useQuery } from '@tanstack/react-query'

import {
  getData,
  postFormData,
  postJsonData,
} from '../../../../apis/api.helpers'
import apiUrl from '../../../../apis/api.url'
import { QueryParams } from '../../../../common/types'
import {
  getErrorMessage,
  parseQueryParams,
} from '../../../../utilities/parsers'
import { useSnackbar } from 'notistack'
import { useMutation } from '@tanstack/react-query'

const buildUrlWithParams = (baseUrl: string, params: any) => {
  return `${baseUrl}${parseQueryParams(params)}`
}

const fetchData = async (input: QueryParams) => {
  const url = buildUrlWithParams(apiUrl.HOSPITAL_INCHARGE_PRIMARY_LIST_URL, {
    ...input,
  })
  const response = await getData(url)
  // console.log(response)
  return response?.stocks
}

export const useContact = (input: QueryParams) => {
  return useQuery(['hospital_primary_list'], () => fetchData(input), {
    refetchOnWindowFocus: false,
    //staleTime: 50000,
  })
}
export const fetchMedicines = async (input: any) => {
  const url = buildUrlWithParams(apiUrl.PURCHASE_MEDICINE_LIST, {
    ...input,
  })
  const response = await getData(url)
  return response
}

export const fetchAutoMedicines = async (input: any) => {
  const url = buildUrlWithParams(apiUrl.HOSPITAL_INCHARGE_PRIMARY_LIST_URL, {
    ...input,
  })
  const response = await getData(url)
  return response
}

export const fetchManufacturers = async () => {
  const url = `${apiUrl.PURCHASE_MANUFACTURER_LIST}`
  const response = await getData(url)
  return response
}

export const fetchFunds = async () => {
  const url = `${apiUrl.PURCHASE_FUND_LIST}`
  const response = await getData(url)
  return response
}

// export const useManufacturerList = () => {
//   fetchManufacturers()
// }

export const createPurchase = (input: any) => {
  return postJsonData(apiUrl.INSTITUTION_ORDER_LIST, input)
}

export const useCreatePurchase = (handleSubmission: (data: any) => void) => {
  const { enqueueSnackbar } = useSnackbar()
  return useMutation(createPurchase, {
    onSuccess: (res: any) => {
      handleSubmission(res.data)
      // enqueueSnackbar('Contact Created successfully', { variant: 'success' })
    },

    onError: (error: any) => {
      {
        error?.response?.data?.message &&
          error?.response?.data?.message.map((item: any) => {
            enqueueSnackbar(getErrorMessage(item), {
              variant: 'error',
            })
          })
      }
    },
  })
}

export const fetchMedicineByExcel = (input: any) => {
  return postFormData(apiUrl.PURCHASE_BY_EXCEL, input)
}

export const useFetchMedicineByExcel = (
  handleSubmission: (data: any) => void
) => {
  const { enqueueSnackbar } = useSnackbar()
  return useMutation(fetchMedicineByExcel, {
    onSuccess: (res: any) => {
      // handleSubmission(res.data)

      console.log(res)
      handleSubmission(res.data)
      // enqueueSnackbar('Contact Created successfully', { variant: 'success' })
    },

    onError: (error: any) => {
      enqueueSnackbar(
        getErrorMessage(
          error?.response?.data?.message || 'Something went wrong'
        ),
        {
          variant: 'error',
        }
      )
    },
  })
}

// export const editContact = (data: { input: any; id: string }) => {
//   return updateData(`${apiUrl.CONTACT_CREATE}${data?.id}/`, data?.input)
// }

// export const useEditContact = (
//   handleSubmission: (input: any, id: string) => void
// ) => {
//   const { enqueueSnackbar } = useSnackbar()

//   return useMutation(editContact, {
//     onSuccess: (res: any) => {
//       handleSubmission(res.data, res.id)
//       enqueueSnackbar('Contact Created successfully', { variant: 'success' })
//     },

//     onError: (error: any) => {
//       enqueueSnackbar(getErrorMessage(error?.response?.data?.message || "Something went wrong"), {
//         variant: 'error',
//       })
//     },
//   })
// }

// export const getContactMergeDetails = (data: any) => {
//   return postData(apiUrl.CONTACT_MULTIPLE_DETAILS, data)
// }

// export const mergeContacts = (data: any, id?: string) => {
//   return updateData(`${apiUrl.CONTACT_LIST_URL}${id}/merge/`, data)
// }
// export const getContactDetails = (id: any) => {
//   return getData(`${apiUrl.CONTACT_LIST_URL}${id}`)
// }

// export const setPrimaryAccount = (id: string, data: any) => {
//   return updateData(
//     `${apiUrl.CONTACT_LIST_URL}${id}/set-primary-account/`,
//     data
//   )
// }

// // export const getContactAccountDetails = (id: any) => {
// //   return getData(`${apiUrl.CONTACT_LIST_URL}${id}/accounts/?page=1`)
// // }

// // const accountsfetchData = async (id: string, input: QueryParams) => {
// //   const url = buildUrlWithParams(`${apiUrl.CONTACT_LIST_URL}${id}/accounts/`, {

// const accountsfetchData = async (id: string, input: QueryParams) => {
//   const url = buildUrlWithParams(`${apiUrl.CONTACT_LIST_URL}${id}/accounts/`, {
//     ...input,
//   })
//   const response = await getData(url)
//   return response?.data
// }

// export const useContactAccountDetails = (id: string, input: QueryParams) => {
//   return useQuery(
//     ['assosiated_accout_list'],
//     () => accountsfetchData(id, input),
//     {
//       refetchOnWindowFocus: false,
//       //staleTime: 50000,
//     }
//   )
// }
// export const getContactDuplicate = (data: any, config: AxiosRequestConfig) =>
//   getConfigData(
//     `${apiUrl.CONTACT_LIST_URL}possible-contact-duplicates/${parseQueryParams(
//       data ?? {}
//     )}`,
//     config
//   )

// export const listContactDuplicate = (id?: string, data?: any) =>
//   getData(
//     `${apiUrl.CONTACT_LIST_URL}${id}/contact-duplicates/${parseQueryParams(
//       data
//     )}`
//   )

// export const updateContactCre = (id: string, data: any) => {
//   return postData(`${apiUrl.CONTACT_LIST_URL}${id}/allocate-cre`, data)
// }
