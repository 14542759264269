import React from 'react'
import { IconProps } from '../../../common/types'

const Trash: React.FC<IconProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="202"
      height="150"
      viewBox="0 0 202 150"
      fill="none"
    >
      <path
        d="M201.653 131.818H0.34375V131.919H201.653V131.818Z"
        fill="#EBEBEB"
      />
      <path
        d="M34.7996 135.65H21.4648V135.751H34.7996V135.65Z"
        fill="#EBEBEB"
      />
      <path
        d="M86.3734 134.559H69.25V134.659H86.3734V134.559Z"
        fill="#EBEBEB"
      />
      <path
        d="M45.7067 137.062H37.9805V137.163H45.7067V137.062Z"
        fill="#EBEBEB"
      />
      <path
        d="M181.483 138.713H175.352V138.814H181.483V138.713Z"
        fill="#EBEBEB"
      />
      <path
        d="M171.763 138.713H157.957V138.814H171.763V138.713Z"
        fill="#EBEBEB"
      />
      <path
        d="M133.942 135.814H124.348V135.915H133.942V135.814Z"
        fill="#EBEBEB"
      />
      <path
        d="M95.7672 113.861H18.0294C17.4197 113.861 16.835 113.619 16.4038 113.187C15.9727 112.756 15.7305 112.172 15.7305 111.562V2.27883C15.7358 1.6726 15.9803 1.09301 16.4109 0.666216C16.8415 0.239423 17.4232 -2.32416e-05 18.0294 1.69203e-09H95.7672C96.3769 1.69203e-09 96.9616 0.242211 97.3928 0.673349C97.8239 1.10449 98.0661 1.68923 98.0661 2.29896V111.562C98.0661 112.172 97.8239 112.756 97.3928 113.187C96.9616 113.619 96.3769 113.861 95.7672 113.861ZM18.0294 0.0805242C17.4467 0.0815885 16.8882 0.313536 16.4762 0.725567C16.0641 1.1376 15.8322 1.69613 15.8311 2.27883V111.562C15.8322 112.144 16.0641 112.703 16.4762 113.115C16.8882 113.527 17.4467 113.759 18.0294 113.76H95.7672C96.3499 113.759 96.9084 113.527 97.3204 113.115C97.7324 112.703 97.9644 112.144 97.9655 111.562V2.27883C97.9644 1.69613 97.7324 1.1376 97.3204 0.725567C96.9084 0.313536 96.3499 0.0815885 95.7672 0.0805242H18.0294Z"
        fill="#EBEBEB"
      />
      <path
        d="M182.853 113.861H105.108C104.498 113.86 103.914 113.617 103.483 113.186C103.052 112.755 102.81 112.171 102.809 111.562V2.27883C102.815 1.67293 103.06 1.09395 103.49 0.667379C103.921 0.24081 104.502 0.00102599 105.108 0H182.853C183.458 0.00209543 184.038 0.242477 184.467 0.669044C184.896 1.09561 185.139 1.67399 185.144 2.27883V111.562C185.144 112.17 184.903 112.754 184.474 113.185C184.044 113.615 183.462 113.859 182.853 113.861ZM105.108 0.0805242C104.525 0.0815885 103.966 0.313536 103.554 0.725567C103.142 1.1376 102.91 1.69613 102.909 2.27883V111.562C102.91 112.144 103.142 112.703 103.554 113.115C103.966 113.527 104.525 113.759 105.108 113.76H182.853C183.436 113.759 183.995 113.527 184.407 113.115C184.819 112.703 185.051 112.144 185.052 111.562V2.27883C185.051 1.69613 184.819 1.1376 184.407 0.725567C183.995 0.313536 183.436 0.0815885 182.853 0.0805242H105.108Z"
        fill="#EBEBEB"
      />
      <path
        d="M124.151 56.916L167.582 56.916V13.3687L124.151 13.3687V56.916Z"
        fill="#F0F0F0"
      />
      <path
        d="M124.151 13.375H123.402V56.9223H124.151V13.375Z"
        fill="#E0E0E0"
      />
      <path
        d="M127.985 54.168L163.746 54.168V16.1204L127.985 16.1204V54.168Z"
        fill="#FAFAFA"
      />
      <path
        d="M142.063 45.8975H153.388C155.844 45.8975 157.487 43.9085 157.056 41.4687L154.854 28.8546C154.609 27.6341 153.959 26.5322 153.009 25.7282C152.058 24.9241 150.864 24.4651 149.62 24.4258H138.322C135.866 24.4258 134.224 26.4147 134.655 28.8546L136.857 41.4687C137.1 42.6849 137.746 43.7837 138.69 44.5874C139.635 45.3911 140.823 45.8526 142.063 45.8975Z"
        fill="#F0F0F0"
      />
      <path
        d="M42.6043 70.1445H32.9414V129.752H42.6043V70.1445Z"
        fill="#F0F0F0"
      />
      <path
        d="M85.006 129.756H33.9297V131.813H85.006V129.756Z"
        fill="#F0F0F0"
      />
      <path
        d="M42.6061 129.756H85.8555L85.8555 70.1481H42.6061L42.6061 129.756Z"
        fill="#F5F5F5"
      />
      <path
        d="M44.3465 111.828H84.1172V101.183H44.3465V111.828Z"
        fill="#F0F0F0"
      />
      <path
        d="M44.3465 125.756H84.1172V115.111H44.3465V125.756Z"
        fill="#F0F0F0"
      />
      <path
        d="M79.4107 100.529H49.0532C48.7908 100.529 48.5781 100.742 48.5781 101.004V101.516C48.5781 101.778 48.7908 101.991 49.0532 101.991H79.4107C79.6731 101.991 79.8858 101.778 79.8858 101.516V101.004C79.8858 100.742 79.6731 100.529 79.4107 100.529Z"
        fill="#F5F5F5"
      />
      <path
        d="M79.4107 114.465H49.0532C48.7908 114.465 48.5781 114.678 48.5781 114.94V115.451C48.5781 115.714 48.7908 115.926 49.0532 115.926H79.4107C79.6731 115.926 79.8858 115.714 79.8858 115.451V114.94C79.8858 114.678 79.6731 114.465 79.4107 114.465Z"
        fill="#F5F5F5"
      />
      <path
        d="M44.3465 84.0742H84.1172V73.429H44.3465V84.0742Z"
        fill="#F0F0F0"
      />
      <path
        d="M44.3465 97.9004H84.1172V87.2551H44.3465V97.9004Z"
        fill="#F0F0F0"
      />
      <path
        d="M79.4107 72.7812H49.0532C48.7908 72.7812 48.5781 72.994 48.5781 73.2563V73.7677C48.5781 74.0301 48.7908 74.2428 49.0532 74.2428H79.4107C79.6731 74.2428 79.8858 74.0301 79.8858 73.7677V73.2563C79.8858 72.994 79.6731 72.7812 79.4107 72.7812Z"
        fill="#F5F5F5"
      />
      <path
        d="M79.4107 86.5977H49.0532C48.7908 86.5977 48.5781 86.8104 48.5781 87.0727V87.5841C48.5781 87.8465 48.7908 88.0592 49.0532 88.0592H79.4107C79.6731 88.0592 79.8858 87.8465 79.8858 87.5841V87.0727C79.8858 86.8104 79.6731 86.5977 79.4107 86.5977Z"
        fill="#F5F5F5"
      />
      <path
        d="M84.2915 10.0898H30.0547V57.7036H84.2915V10.0898Z"
        fill="#E6E6E6"
      />
      <path
        d="M90.1087 8.23633H26.5472C26.4766 8.23633 26.4066 8.25031 26.3414 8.27747C26.2761 8.30463 26.2169 8.34444 26.1671 8.39459C26.1174 8.44474 26.078 8.50425 26.0513 8.56968C26.0247 8.63511 26.0112 8.70518 26.0117 8.77584C26.0117 8.91786 26.0681 9.05406 26.1686 9.15448C26.269 9.25491 26.4052 9.31132 26.5472 9.31132H90.1087C90.2511 9.31132 90.3877 9.25504 90.4888 9.15473C90.5899 9.05442 90.6472 8.91823 90.6482 8.77584C90.6482 8.63275 90.5914 8.49553 90.4902 8.39435C90.389 8.29317 90.2518 8.23633 90.1087 8.23633Z"
        fill="#E6E6E6"
      />
      <path
        d="M86.5898 10.0898H31.125V57.7036H86.5898V10.0898Z"
        fill="#F0F0F0"
      />
      <path
        d="M84.2915 57.7051H30.0547V59.231H85.8778L84.2915 57.7051Z"
        fill="#E6E6E6"
      />
      <path
        d="M90.4257 57.7051H34.9648L36.5471 59.231H92.012L90.4257 57.7051Z"
        fill="#F0F0F0"
      />
      <path
        d="M83.375 54.4844V13.3125L34.3561 13.3125V54.4844H83.375Z"
        fill="#FAFAFA"
      />
      <path
        d="M45.4805 54.4844L64.3875 13.3125H73.3176L54.4065 54.4844H45.4805Z"
        fill="white"
      />
      <path
        d="M56.3281 54.4844L75.2392 13.3125H78.7178L59.8108 54.4844H56.3281Z"
        fill="white"
      />
      <path
        d="M83.375 54.4843V13.3125H82.9643V54.4843H83.375Z"
        fill="#E6E6E6"
      />
      <g opacity="0.4">
        <path
          d="M34.3917 7.27734H28.8477V46.569H34.3917V7.27734Z"
          fill="#F0F0F0"
        />
        <path
          d="M36.5545 7.27734H32.2344V46.569H36.5545V7.27734Z"
          fill="#E6E6E6"
        />
        <path
          d="M39.9386 7.27734H34.3945V46.569H39.9386V7.27734Z"
          fill="#F0F0F0"
        />
        <path
          d="M42.0974 7.27734H37.7773V46.569H42.0974V7.27734Z"
          fill="#E6E6E6"
        />
        <path
          d="M45.4777 7.27734H39.9336V46.569H45.4777V7.27734Z"
          fill="#F0F0F0"
        />
      </g>
      <g opacity="0.4">
        <path
          d="M81.3101 7.27734H78.1133V46.569H81.3101V7.27734Z"
          fill="#F0F0F0"
        />
        <path
          d="M82.5546 7.27734H80.0664V46.569H82.5546V7.27734Z"
          fill="#E6E6E6"
        />
        <path
          d="M84.5015 7.27734H81.3047V46.569H84.5015V7.27734Z"
          fill="#F0F0F0"
        />
        <path
          d="M85.7499 7.27734H83.2617V46.569H85.7499V7.27734Z"
          fill="#E6E6E6"
        />
        <path
          d="M87.6968 7.27734H84.5V46.569H87.6968V7.27734Z"
          fill="#F0F0F0"
        />
      </g>
      <path
        d="M144.018 86.2446C143.97 86.827 144.097 87.4104 144.381 87.921C144.665 88.4316 145.094 88.8466 145.614 89.1134C146.134 89.3802 146.721 89.4869 147.301 89.42C147.882 89.3531 148.429 89.1157 148.875 88.7376C149.321 88.3596 149.644 87.8579 149.805 87.2961C149.965 86.7342 149.955 86.1374 149.777 85.581C149.598 85.0246 149.259 84.5337 148.801 84.1702C148.344 83.8068 147.789 83.5871 147.206 83.539C146.425 83.4755 145.65 83.7248 145.052 84.2321C144.454 84.7394 144.082 85.4632 144.018 86.2446Z"
        fill="#E0E0E0"
      />
      <path
        d="M152.235 90.954C153.852 90.7161 154.969 89.2124 154.732 87.5955C154.494 85.9786 152.99 84.8606 151.373 85.0985C149.756 85.3364 148.638 86.8401 148.876 88.457C149.114 90.074 150.618 91.1919 152.235 90.954Z"
        fill="#E0E0E0"
      />
      <path
        d="M154.029 88.3956C153.982 88.978 154.108 89.5614 154.392 90.072C154.676 90.5827 155.106 90.9976 155.625 91.2644C156.145 91.5312 156.733 91.6379 157.313 91.571C157.894 91.5042 158.441 91.2667 158.887 90.8886C159.332 90.5106 159.656 90.0089 159.816 89.4471C159.977 88.8852 159.967 88.2884 159.789 87.732C159.61 87.1756 159.27 86.6847 158.813 86.3213C158.355 85.9578 157.8 85.7381 157.218 85.69C156.831 85.6575 156.441 85.7018 156.071 85.8203C155.701 85.9388 155.358 86.1292 155.062 86.3806C154.765 86.632 154.522 86.9394 154.345 87.2852C154.167 87.631 154.06 88.0083 154.029 88.3956Z"
        fill="#E0E0E0"
      />
      <path
        d="M138.525 87.7934C138.478 88.3759 138.604 88.9592 138.888 89.4699C139.173 89.9805 139.602 90.3954 140.121 90.6622C140.641 90.929 141.229 91.0357 141.809 90.9688C142.39 90.902 142.937 90.6645 143.383 90.2864C143.828 89.9084 144.152 89.4067 144.312 88.8449C144.473 88.283 144.463 87.6862 144.285 87.1298C144.106 86.5735 143.767 86.0825 143.309 85.7191C142.851 85.3556 142.296 85.1359 141.714 85.0878C140.933 85.0244 140.158 85.2736 139.56 85.7809C138.962 86.2882 138.59 87.012 138.525 87.7934Z"
        fill="#E0E0E0"
      />
      <path
        d="M161.684 90.9501C163.301 90.7122 164.419 89.2085 164.181 87.5916C163.943 85.9747 162.439 84.8567 160.822 85.0946C159.205 85.3325 158.087 86.8362 158.325 88.4531C158.563 90.0701 160.067 91.188 161.684 90.9501Z"
        fill="#E0E0E0"
      />
      <path
        d="M139.405 87.7835C140.647 86.7212 140.792 84.8531 139.73 83.611C138.668 82.3689 136.8 82.2231 135.558 83.2855C134.316 84.3478 134.17 86.2159 135.232 87.458C136.294 88.7001 138.163 88.8459 139.405 87.7835Z"
        fill="#E0E0E0"
      />
      <path
        d="M130.357 87.1216C130.31 87.704 130.436 88.2874 130.72 88.798C131.005 89.3086 131.434 89.7235 131.954 89.9903C132.473 90.2571 133.061 90.3639 133.641 90.297C134.222 90.2301 134.769 89.9926 135.215 89.6145C135.66 89.2365 135.984 88.7348 136.144 88.173C136.305 87.6111 136.295 87.0143 136.117 86.4579C135.938 85.9016 135.599 85.4106 135.141 85.0472C134.683 84.6837 134.128 84.4641 133.546 84.416C132.765 84.3525 131.99 84.6018 131.392 85.1091C130.794 85.6163 130.422 86.3402 130.357 87.1216Z"
        fill="#E0E0E0"
      />
      <path
        d="M126.98 87.9199C126.98 87.9199 127.488 100.027 146.942 100.027C166.397 100.027 166.904 87.9199 166.904 87.9199H126.98Z"
        fill="#F0F0F0"
      />
      <path
        d="M178.005 100.027H174.305V131.814H178.005V100.027Z"
        fill="#E6E6E6"
      />
      <path
        d="M178.987 100.027H176.809V131.814H178.987V100.027Z"
        fill="#F0F0F0"
      />
      <path
        d="M162.263 100.027H158.562V131.814H162.263V100.027Z"
        fill="#E6E6E6"
      />
      <path
        d="M162.952 100.027H160.773V131.814H162.952V100.027Z"
        fill="#F0F0F0"
      />
      <path
        d="M127.142 103.883H179.668V100.03H127.142V103.883Z"
        fill="#F0F0F0"
      />
      <path
        d="M111.087 100.027H107.387V131.814H111.087V100.027Z"
        fill="#E6E6E6"
      />
      <path
        d="M111.78 100.027H109.602V131.814H111.78V100.027Z"
        fill="#F0F0F0"
      />
      <path
        d="M106.724 103.883H125.555V100.03H106.724V103.883Z"
        fill="#E6E6E6"
      />
      <path
        d="M126.825 100.027H123.125V131.814H126.825V100.027Z"
        fill="#E6E6E6"
      />
      <path
        d="M127.819 100.027H125.641V131.814H127.819V100.027Z"
        fill="#F0F0F0"
      />
      <path
        d="M100.997 149.998C144.111 149.998 179.061 147.958 179.061 145.44C179.061 142.923 144.111 140.883 100.997 140.883C57.884 140.883 22.9336 142.923 22.9336 145.44C22.9336 147.958 57.884 149.998 100.997 149.998Z"
        fill="#F5F5F5"
      />
      <path
        d="M143.47 141.444C143.47 142.249 143.185 143.027 142.665 143.642C142.146 144.256 141.425 144.666 140.631 144.798C130.863 146.446 120.887 146.446 111.119 144.798C110.329 144.662 109.612 144.251 109.096 143.637C108.581 143.023 108.299 142.246 108.301 141.444V108.832H143.486L143.47 141.444Z"
        fill="#45B755"
      />
      <path
        opacity="0.3"
        d="M108.277 109.42V113.998C110.395 114.783 117.348 115.359 125.872 115.359C134.395 115.359 141.361 114.783 143.466 113.998V109.42H108.277Z"
        fill="black"
      />
      <path
        d="M144.129 112.91C144.129 113.945 136.141 114.782 125.874 114.782C115.607 114.782 107.619 113.945 107.619 112.91L107.285 108.844H144.483L144.129 112.91Z"
        fill="#45B755"
      />
      <path
        d="M125.874 110.713C136.14 110.713 144.463 109.876 144.463 108.845C144.463 107.813 136.14 106.977 125.874 106.977C115.608 106.977 107.285 107.813 107.285 108.845C107.285 109.876 115.608 110.713 125.874 110.713Z"
        fill="#45B755"
      />
      <path
        opacity="0.3"
        d="M125.874 110.713C136.14 110.713 144.463 109.876 144.463 108.845C144.463 107.813 136.14 106.977 125.874 106.977C115.608 106.977 107.285 107.813 107.285 108.845C107.285 109.876 115.608 110.713 125.874 110.713Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M112.998 139.15C112.636 139.149 112.289 139.005 112.034 138.749C111.778 138.493 111.634 138.147 111.633 137.785V119.088C111.634 118.726 111.778 118.379 112.034 118.124C112.289 117.868 112.636 117.724 112.998 117.723C113.359 117.725 113.705 117.869 113.96 118.125C114.216 118.38 114.36 118.726 114.363 119.088V137.785C114.36 138.147 114.216 138.492 113.96 138.748C113.705 139.003 113.359 139.148 112.998 139.15Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M138.752 139.15C138.39 139.149 138.043 139.005 137.788 138.749C137.532 138.493 137.388 138.147 137.387 137.785V119.088C137.388 118.726 137.532 118.379 137.788 118.124C138.043 117.868 138.39 117.724 138.752 117.723C139.113 117.725 139.459 117.869 139.714 118.125C139.97 118.38 140.114 118.726 140.116 119.088V137.785C140.114 138.147 139.97 138.492 139.714 138.748C139.459 139.003 139.113 139.148 138.752 139.15Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M130.166 139.15C129.804 139.148 129.458 139.003 129.203 138.748C128.947 138.492 128.803 138.147 128.801 137.785V119.088C128.803 118.726 128.947 118.38 129.203 118.125C129.458 117.869 129.804 117.725 130.166 117.723C130.527 117.724 130.874 117.868 131.13 118.124C131.385 118.379 131.529 118.726 131.531 119.088V137.785C131.529 138.147 131.385 138.493 131.13 138.749C130.874 139.005 130.527 139.149 130.166 139.15Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M121.584 139.15C121.222 139.148 120.876 139.003 120.621 138.748C120.365 138.492 120.221 138.147 120.219 137.785V119.088C120.221 118.726 120.365 118.38 120.621 118.125C120.876 117.869 121.222 117.725 121.584 117.723C121.945 117.724 122.292 117.868 122.548 118.124C122.803 118.379 122.947 118.726 122.949 119.088V137.785C122.947 138.147 122.803 138.493 122.548 138.749C122.292 139.005 121.945 139.149 121.584 139.15Z"
        fill="black"
      />
      <path
        d="M77.998 33.8838C77.998 34.2533 77.8885 34.6144 77.6832 34.9217C77.4779 35.2289 77.1861 35.4683 76.8448 35.6097C76.5034 35.7511 76.1278 35.7881 75.7654 35.716C75.403 35.644 75.0702 35.466 74.8089 35.2048C74.5476 34.9435 74.3697 34.6106 74.2976 34.2482C74.2255 33.8858 74.2625 33.5102 74.4039 33.1689C74.5453 32.8275 74.7848 32.5357 75.092 32.3305C75.3992 32.1252 75.7604 32.0156 76.1299 32.0156C76.6253 32.0156 77.1005 32.2124 77.4509 32.5628C77.8012 32.9131 77.998 33.3883 77.998 33.8838Z"
        fill="#263238"
      />
      <path
        d="M74.6325 34.1523C74.6143 34.1528 74.5964 34.1482 74.5807 34.1389C74.565 34.1297 74.5523 34.1162 74.544 34.1C74.4205 33.8732 74.3429 33.6242 74.3157 33.3674C74.2884 33.1106 74.312 32.851 74.3851 32.6033C74.4582 32.3556 74.5793 32.1247 74.7417 31.9239C74.904 31.723 75.1043 31.5561 75.3311 31.4326C75.5579 31.3092 75.8068 31.2316 76.0636 31.2043C76.3204 31.1771 76.5801 31.2007 76.8278 31.2738C77.0755 31.3469 77.3063 31.468 77.5072 31.6303C77.7081 31.7926 77.875 31.9929 77.9984 32.2198C78.0103 32.2434 78.0127 32.2707 78.0052 32.2961C77.9978 32.3215 77.981 32.3431 77.9582 32.3566C77.9466 32.363 77.9338 32.367 77.9206 32.3685C77.9074 32.3699 77.8941 32.3687 77.8814 32.365C77.8686 32.3612 77.8568 32.355 77.8465 32.3467C77.8362 32.3383 77.8276 32.328 77.8213 32.3164C77.5976 31.9053 77.2197 31.5999 76.7708 31.4674C76.322 31.3348 75.8388 31.3861 75.4277 31.6098C75.0166 31.8335 74.7112 32.2113 74.5787 32.6602C74.4462 33.1091 74.4974 33.5923 74.7211 34.0034C74.7275 34.015 74.7315 34.0278 74.7329 34.0409C74.7344 34.0541 74.7332 34.0675 74.7294 34.0802C74.7257 34.0929 74.7195 34.1047 74.7111 34.1151C74.7028 34.1254 74.6925 34.1339 74.6809 34.1402C74.6659 34.148 74.6494 34.1521 74.6325 34.1523Z"
        fill="#263238"
      />
      <path
        d="M72.8202 42.4187C72.4563 42.4172 72.0999 42.3149 71.7906 42.1231C71.4813 41.9313 71.2312 41.6575 71.0681 41.3322C70.905 41.0068 70.8353 40.6427 70.8667 40.2801C70.8981 39.9175 71.0294 39.5707 71.2459 39.2783C71.4003 39.0715 71.5939 38.8972 71.8157 38.7652C72.0374 38.6333 72.283 38.5463 72.5384 38.5093C72.5514 38.507 72.5647 38.5074 72.5775 38.5105C72.5904 38.5135 72.6025 38.5192 72.613 38.5271C72.6236 38.5351 72.6324 38.5451 72.639 38.5565C72.6455 38.568 72.6496 38.5807 72.6511 38.5938C72.6546 38.6199 72.6476 38.6464 72.6318 38.6674C72.616 38.6885 72.5926 38.7026 72.5665 38.7065C72.1036 38.7744 71.6866 39.0233 71.4073 39.3985C71.1279 39.7738 71.009 40.2447 71.0769 40.7076C71.1447 41.1705 71.3936 41.5875 71.7688 41.8668C72.1441 42.1462 72.615 42.2651 73.0779 42.1973C73.1046 42.193 73.1319 42.1995 73.1538 42.2154C73.1757 42.2312 73.1904 42.2551 73.1946 42.2818C73.1989 42.3085 73.1924 42.3358 73.1765 42.3577C73.1607 42.3796 73.1368 42.3943 73.1101 42.3986C73.014 42.4113 72.9171 42.418 72.8202 42.4187Z"
        fill="#263238"
      />
      <path
        d="M82.9654 45.4615C82.5877 45.461 82.2183 45.3505 81.9024 45.1435C81.88 45.1286 81.8641 45.1057 81.8581 45.0795C81.8521 45.0532 81.8564 45.0257 81.8702 45.0026C81.8857 44.9808 81.9089 44.9657 81.9351 44.9605C81.9613 44.9552 81.9885 44.9602 82.0111 44.9744C82.4036 45.229 82.8811 45.3174 83.3386 45.22C83.7962 45.1226 84.1963 44.8474 84.451 44.455C84.7057 44.0626 84.794 43.5851 84.6966 43.1275C84.5992 42.6699 84.3241 42.2698 83.9316 42.0151C83.9092 42.0003 83.8933 41.9774 83.8873 41.9511C83.8813 41.9249 83.8856 41.8973 83.8994 41.8742C83.9149 41.8524 83.9381 41.8374 83.9643 41.8321C83.9905 41.8269 84.0177 41.8318 84.0403 41.846C84.4768 42.1302 84.7828 42.5758 84.8915 43.0851C85.0002 43.5945 84.9026 44.1261 84.6201 44.5637C84.4807 44.7814 84.2992 44.969 84.0862 45.1156C83.8733 45.2622 83.6332 45.3647 83.38 45.4173C83.2437 45.4459 83.1047 45.4608 82.9654 45.4615Z"
        fill="#263238"
      />
      <path
        d="M75.0826 39.7101C76.5413 39.7101 77.7238 38.5276 77.7238 37.0689C77.7238 35.6102 76.5413 34.4277 75.0826 34.4277C73.6239 34.4277 72.4414 35.6102 72.4414 37.0689C72.4414 38.5276 73.6239 39.7101 75.0826 39.7101Z"
        fill="#263238"
      />
      <path
        d="M77.5673 43.0999C78.9352 41.732 78.9352 39.5141 77.5673 38.1462C76.1993 36.7783 73.9815 36.7783 72.6136 38.1462C71.2456 39.5141 71.2456 41.732 72.6136 43.0999C73.9815 44.4678 76.1993 44.4678 77.5673 43.0999Z"
        fill="#263238"
      />
      <path
        d="M75.4591 48.9476C77.5982 48.9476 79.3323 47.2135 79.3323 45.0744C79.3323 42.9353 77.5982 41.2012 75.4591 41.2012C73.32 41.2012 71.5859 42.9353 71.5859 45.0744C71.5859 47.2135 73.32 48.9476 75.4591 48.9476Z"
        fill="#263238"
      />
      <path
        d="M83.1008 46.2266C83.5922 44.1447 82.3028 42.0586 80.2209 41.5673C78.139 41.0759 76.053 42.3653 75.5616 44.4472C75.0702 46.5291 76.3596 48.6151 78.4415 49.1065C80.5234 49.5979 82.6095 48.3085 83.1008 46.2266Z"
        fill="#263238"
      />
      <path
        d="M92.1762 53.331C92.1762 53.645 92.1762 54.0436 92.2044 54.406C92.2325 54.7684 92.2325 55.1549 92.2567 55.5293C92.309 56.2782 92.3614 57.0351 92.4419 57.788C92.5868 59.2992 92.7895 60.8036 93.0499 62.3014C93.1183 62.6718 93.1787 63.0502 93.2552 63.4206L93.4324 64.3346C93.4766 64.4795 93.4324 64.4514 93.376 64.4473C93.3196 64.4433 93.376 64.5198 93.6135 64.5198C94.2216 64.4588 94.8136 64.2883 95.3609 64.0165C95.683 63.8716 96.0131 63.7146 96.3433 63.5455C96.6734 63.3764 97.0076 63.1831 97.3096 63.014L98.4852 64.3708C98.2047 64.6921 97.9063 64.9972 97.5914 65.2848C97.2814 65.58 96.9533 65.8556 96.609 66.1101C96.2483 66.3816 95.8674 66.6252 95.4696 66.8389C95.0171 67.0859 94.5318 67.2676 94.0282 67.3784C93.7177 67.4461 93.3996 67.4731 93.0821 67.4589C92.6945 67.4364 92.3148 67.3408 91.9628 67.1771C91.5884 67.0013 91.2551 66.7489 90.9844 66.4363C90.7519 66.1644 90.5619 65.859 90.4208 65.5304C90.3114 65.2789 90.2225 65.0191 90.155 64.7533L89.998 64.1655C89.8853 63.7629 89.7927 63.3804 89.696 62.9858C89.3194 61.403 89.0291 59.8009 88.8264 58.1866C88.7257 57.3814 88.6452 56.5761 88.5969 55.7507C88.5727 55.3481 88.5526 54.9455 88.5405 54.5187C88.5284 54.092 88.5405 53.7135 88.5405 53.2344L92.1762 53.331Z"
        fill="#FFB573"
      />
      <path
        d="M90.2267 48.9888C93.661 51.6179 93.5241 56.5741 93.5241 56.5741L87.3801 58.0437C87.3801 58.0437 85.4959 53.0593 86.2609 51.2354C87.058 49.335 88.2941 47.5072 90.2267 48.9888Z"
        fill="#45B755"
      />
      <path
        opacity="0.7"
        d="M90.2267 48.9888C93.661 51.6179 93.5241 56.5741 93.5241 56.5741L87.3801 58.0437C87.3801 58.0437 85.4959 53.0593 86.2609 51.2354C87.058 49.335 88.2941 47.5072 90.2267 48.9888Z"
        fill="white"
      />
      <path
        d="M97.0828 63.4766L98.0974 61.7695L100.642 64.4912C100.642 64.4912 98.8181 65.6588 96.9258 64.8254L97.0828 63.4766Z"
        fill="#FFB573"
      />
      <path
        d="M99.3459 60.9512L102.611 62.8757L100.638 64.4902L98.0938 61.7685L99.3459 60.9512Z"
        fill="#FFB573"
      />
      <path
        d="M71 142.468L74.5108 142.806L76.693 134.58L73.1782 134.242L71 142.468Z"
        fill="#FFB573"
      />
      <path
        d="M53.0039 135.557L56.1564 137.208L59.9048 129.776L56.7523 128.125L53.0039 135.557Z"
        fill="#FFB573"
      />
      <path
        d="M56.9963 135.759L54.178 132.897C54.1311 132.847 54.0674 132.816 53.999 132.81C53.9306 132.804 53.8624 132.823 53.8076 132.865L51.2187 134.769C51.1559 134.82 51.1045 134.883 51.0676 134.954C51.0308 135.026 51.0094 135.105 51.0048 135.185C51.0003 135.266 51.0127 135.346 51.0412 135.421C51.0697 135.497 51.1137 135.565 51.1704 135.623C52.1689 136.605 52.7004 137.028 53.9404 138.288C54.7054 139.061 56.2756 140.885 57.3305 141.956C58.3854 143.027 59.4966 142.04 59.1544 141.501C57.6164 139.085 57.3587 137.567 57.262 136.339C57.2446 136.121 57.1505 135.915 56.9963 135.759Z"
        fill="#263238"
      />
      <path
        d="M75.0592 141.741H70.8035C70.7274 141.741 70.6536 141.767 70.5933 141.813C70.533 141.86 70.4895 141.925 70.4693 141.998L69.5594 145.344C69.5367 145.424 69.5329 145.507 69.5485 145.588C69.5641 145.67 69.5985 145.746 69.6491 145.812C69.6996 145.877 69.7649 145.93 69.8395 145.965C69.9142 146.001 69.9961 146.018 70.0788 146.016C71.5725 146.016 72.2811 146.016 74.1452 146.016H79.8504C81.4004 146.016 81.7266 144.45 81.0824 144.305C78.2077 143.669 76.5489 142.791 75.6631 141.954C75.4953 141.81 75.28 141.734 75.0592 141.741Z"
        fill="#263238"
      />
      <path
        opacity="0.2"
        d="M76.6877 134.586L75.5644 138.826H71.9609L73.1728 134.248L76.6877 134.586Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M56.7451 128.125L59.9016 129.78L57.969 133.609L54.8125 131.954L56.7451 128.125Z"
        fill="black"
      />
      <path
        d="M89.1896 48.4839C89.1896 48.4839 90.7437 49.1643 85.5339 69.7784L71.0637 68.5263C71.8166 62.6601 72.1347 59.0285 70.3672 46.6962C72.43 46.4547 74.5075 46.3618 76.5836 46.4184C78.7846 46.4517 80.9803 46.6414 83.1544 46.9861C85.1901 47.3831 87.2045 47.883 89.1896 48.4839Z"
        fill="#45B755"
      />
      <path
        opacity="0.7"
        d="M89.1896 48.4839C89.1896 48.4839 90.7437 49.1643 85.5339 69.7784L71.0637 68.5263C71.8166 62.6601 72.1347 59.0285 70.3672 46.6962C72.43 46.4547 74.5075 46.3618 76.5836 46.4184C78.7846 46.4517 80.9803 46.6414 83.1544 46.9861C85.1901 47.3831 87.2045 47.883 89.1896 48.4839Z"
        fill="white"
      />
      <path
        d="M76.5809 46.3008C76.5809 46.3008 75.7756 46.3008 74.7892 46.3008C74.2054 47.1986 76.6694 49.8197 79.8018 52.3964C80.5587 50.0492 81.7505 50.0572 81.7505 50.0572L76.5809 46.3008Z"
        fill="white"
      />
      <path
        d="M83.147 46.9297C83.147 46.9297 83.5134 46.982 84.3911 47.1833C84.9708 48.0812 85.297 50.3278 83.5858 52.2845C82.9135 49.8688 81.7539 50.0862 81.7539 50.0862L83.147 46.9297Z"
        fill="white"
      />
      <path
        d="M77.8704 39.0723C78.1039 41.2223 78.1804 45.1116 76.582 46.416C76.582 46.416 78.2891 48.1634 81.7517 50.0879C84.264 48.3486 83.1528 46.9837 83.1528 46.9837C80.8417 46.2188 81.0551 44.4754 81.6067 42.8649L77.8704 39.0723Z"
        fill="#FFB573"
      />
      <path
        opacity="0.2"
        d="M79.3952 40.623L81.6056 42.8656C81.47 43.2384 81.3676 43.6223 81.2996 44.0131C80.4139 43.8078 79.1255 42.8817 79.1094 41.9316C79.1248 41.4817 79.2217 41.0384 79.3952 40.623Z"
        fill="black"
      />
      <path
        d="M86.1701 32.0586C87.6517 33.3711 88.0866 36.5679 86.6774 38.738C86.2543 36.5382 86.0841 34.2971 86.1701 32.0586Z"
        fill="#263238"
      />
      <path
        d="M77.4185 34.5601C77.1608 38.0991 76.9796 39.5929 78.5096 41.6341C80.8126 44.7061 85.2172 44.2431 86.5257 40.8531C87.7054 37.7972 87.9268 32.5229 84.6777 30.6628C83.9637 30.246 83.1548 30.0194 82.3282 30.0047C81.5016 29.9899 80.6851 30.1875 79.9567 30.5786C79.2283 30.9696 78.6125 31.5411 78.1681 32.2382C77.7237 32.9353 77.4656 33.7347 77.4185 34.5601Z"
        fill="#FFB573"
      />
      <path
        d="M76.9449 37.4097C79.5619 37.3091 81.0113 33.8868 80.3551 32.4414C83.3224 33.9875 87.8156 35.2034 87.3164 33.2145C86.644 30.529 83.3948 28.5602 79.3968 29.9935C77.0818 31 75.1009 33.2145 76.9449 37.4097Z"
        fill="#263238"
      />
      <path
        d="M76.1505 37.4039C76.2623 38.0806 76.6353 38.6864 77.1892 39.0909C77.9301 39.6183 78.6065 39.0305 78.6588 38.1729C78.7071 37.3958 78.381 36.188 77.5113 36.0028C77.3209 35.9649 77.124 35.9754 76.9386 36.0331C76.7533 36.0909 76.5853 36.1941 76.45 36.3334C76.3147 36.4726 76.2164 36.6436 76.1641 36.8305C76.1118 37.0175 76.1071 37.2146 76.1505 37.4039Z"
        fill="#FFB573"
      />
      <path
        d="M85.5334 69.7769C85.5334 69.7769 88.8067 91.1358 86.9828 104.857C85.6019 115.225 76.6919 137.594 76.6919 137.594L70.9062 137.437C70.9062 137.437 76.8811 115.756 78.1172 107.241C80.2309 92.6577 74.9365 68.875 74.9365 68.875L85.5334 69.7769Z"
        fill="#263238"
      />
      <path
        opacity="0.2"
        d="M79.885 74.1523L76.7969 79.02C77.7268 84.7915 78.3169 90.6127 78.5644 96.4534C82.9046 88.6829 80.8674 78.2631 79.885 74.1523Z"
        fill="black"
      />
      <path
        d="M77.5916 137.917L70.3203 137.72L70.7632 135.381L78.2761 135.425L77.5916 137.917Z"
        fill="#45B755"
      />
      <path
        d="M82.3679 69.5456C82.3679 69.5456 79.9522 93.1955 74.4323 106.063C69.9672 116.451 59.322 133.067 59.322 133.067L55.0703 130.579C55.0703 130.579 61.9148 112.102 65.2928 105.177C73.5344 88.199 65.8686 74.5784 71.0624 68.5391L82.3679 69.5456Z"
        fill="#263238"
      />
      <path
        d="M59.7788 133.431L54.3555 130.456L55.2372 128.137L61.0148 131.398L59.7788 133.431Z"
        fill="#45B755"
      />
      <path
        d="M70.9167 67.6581L70.1517 68.8982C70.0954 68.9908 70.2041 69.1075 70.3732 69.1196L85.5479 70.4321C85.6767 70.4321 85.7935 70.3879 85.8056 70.3073L86.0512 69.023C86.0512 68.9344 85.9586 68.8498 85.8176 68.8378L71.1623 67.5695C71.1172 67.5627 71.0711 67.5672 71.0283 67.5826C70.9854 67.5981 70.947 67.624 70.9167 67.6581Z"
        fill="#45B755"
      />
      <path
        d="M72.7391 69.4673L72.3365 69.4351C72.26 69.4351 72.2037 69.3827 72.2117 69.3344L72.5419 67.6676C72.5419 67.6152 72.6264 67.583 72.7029 67.5911L73.1055 67.6233C73.186 67.6233 73.2424 67.6756 73.2303 67.7239L72.9042 69.3908C72.8881 69.4391 72.8116 69.4753 72.7391 69.4673Z"
        fill="#263238"
      />
      <path
        d="M84.4275 70.4769L84.0249 70.4447C83.9444 70.4447 83.888 70.3924 83.9001 70.3441L84.2302 68.6772C84.2302 68.6289 84.3107 68.5927 84.3872 68.6007L84.7899 68.6329C84.8664 68.6329 84.9227 68.6853 84.9147 68.7336L84.5845 70.4004C84.5765 70.4568 84.4879 70.485 84.4275 70.4769Z"
        fill="#263238"
      />
      <path
        d="M78.5829 69.9732L78.1803 69.9369C78.1038 69.9369 78.0474 69.8846 78.0555 69.8363L78.3856 68.1694C78.3856 68.1211 78.4661 68.0849 78.5466 68.0929L78.9493 68.1292C79.0258 68.1292 79.0821 68.1815 79.0741 68.2298L78.7439 69.8967C78.7359 69.945 78.6634 69.9772 78.5829 69.9732Z"
        fill="#263238"
      />
      <path
        d="M82.9609 36.3167C82.9206 36.6026 83.0414 36.8562 83.2266 36.8804C83.4118 36.9045 83.597 36.6952 83.6292 36.4093C83.6614 36.1235 83.5447 35.8698 83.3595 35.8456C83.1743 35.8215 82.9971 36.0309 82.9609 36.3167Z"
        fill="#263238"
      />
      <path
        d="M86.1939 36.7542C86.1577 37.0401 86.2785 37.2937 86.4637 37.3179C86.6489 37.342 86.8341 37.1327 86.8663 36.8468C86.8985 36.561 86.7858 36.3073 86.6006 36.2831C86.4154 36.259 86.2342 36.4684 86.1939 36.7542Z"
        fill="#263238"
      />
      <path
        d="M86.5039 36.2863L87.2206 36.1816C87.2206 36.1816 86.8018 36.6688 86.5039 36.2863Z"
        fill="#263238"
      />
      <path
        d="M84.9221 37.0664C85.0899 37.7519 85.35 38.4115 85.6951 39.0272C85.5359 39.1118 85.3601 39.1607 85.18 39.1705C84.9999 39.1802 84.8198 39.1505 84.6523 39.0835L84.9221 37.0664Z"
        fill="#ED893E"
      />
      <path
        d="M82.9791 39.9375C82.4128 39.7529 81.9379 39.36 81.6505 38.8383C81.6433 38.8217 81.6426 38.8029 81.6486 38.7858C81.6545 38.7686 81.6667 38.7543 81.6827 38.7457C81.6998 38.7373 81.7195 38.736 81.7376 38.742C81.7557 38.748 81.7707 38.7609 81.7793 38.7779C81.9582 39.0916 82.2086 39.3587 82.5101 39.5573C82.8117 39.756 83.1558 39.8808 83.5146 39.9214C83.5328 39.9214 83.5502 39.9286 83.563 39.9414C83.5758 39.9543 83.5831 39.9717 83.5831 39.9898C83.5831 40.008 83.5758 40.0254 83.563 40.0382C83.5502 40.0511 83.5328 40.0583 83.5146 40.0583C83.3322 40.0381 83.1526 39.9976 82.9791 39.9375Z"
        fill="#263238"
      />
      <path
        d="M81.493 34.6636C81.4696 34.6539 81.4489 34.6387 81.4326 34.6193C81.4193 34.6065 81.4088 34.5912 81.4016 34.5742C81.3943 34.5573 81.3906 34.5391 81.3906 34.5206C81.3906 34.5022 81.3943 34.484 81.4016 34.467C81.4088 34.4501 81.4193 34.4348 81.4326 34.422C81.6299 34.2456 81.8671 34.1197 82.1237 34.0552C82.3804 33.9907 82.6489 33.9894 82.9062 34.0516C82.9238 34.0567 82.9402 34.0652 82.9544 34.0767C82.9687 34.0882 82.9804 34.1024 82.9891 34.1186C82.9977 34.1347 83.003 34.1524 83.0047 34.1707C83.0064 34.1889 83.0043 34.2073 82.9988 34.2247C82.9885 34.2604 82.9645 34.2905 82.9321 34.3086C82.8997 34.3267 82.8614 34.3313 82.8257 34.3213C82.6139 34.2742 82.3939 34.2781 82.1839 34.3327C81.9739 34.3873 81.7798 34.4911 81.6178 34.6354C81.6003 34.6488 81.58 34.6583 81.5585 34.6632C81.5369 34.668 81.5146 34.6682 81.493 34.6636Z"
        fill="#263238"
      />
      <path
        d="M87.5504 35.5206C87.5294 35.5124 87.5102 35.5001 87.4941 35.4844C87.353 35.3188 87.1748 35.1887 86.974 35.1048C86.7733 35.0208 86.5556 34.9854 86.3385 35.0013C86.3202 35.0053 86.3012 35.0055 86.2828 35.0017C86.2644 34.9978 86.247 34.9902 86.2317 34.9791C86.2165 34.9681 86.2038 34.954 86.1945 34.9376C86.1851 34.9213 86.1793 34.9032 86.1775 34.8845C86.1717 34.8477 86.1807 34.81 86.2026 34.7799C86.2245 34.7497 86.2575 34.7294 86.2943 34.7235C86.5571 34.6998 86.8218 34.7394 87.0662 34.8388C87.3106 34.9383 87.5277 35.0947 87.6994 35.2952C87.719 35.3184 87.7305 35.3474 87.7322 35.3777C87.734 35.4081 87.7259 35.4382 87.7091 35.4635C87.6924 35.4889 87.6679 35.5082 87.6393 35.5184C87.6107 35.5287 87.5795 35.5295 87.5504 35.5206Z"
        fill="#263238"
      />
      <path
        d="M90.7309 65.8987C90.9915 65.0866 91.4096 64.3338 91.9612 63.6832C92.5128 63.0327 93.1871 62.4971 93.9457 62.1072C94.7043 61.7172 95.5322 61.4805 96.3823 61.4106C97.2324 61.3406 98.0879 61.4387 98.9 61.6994C99.7121 61.9601 100.465 62.3781 101.116 62.9297C101.766 63.4814 102.302 64.1557 102.692 64.9143C103.081 65.6729 103.318 66.5008 103.388 67.3509C103.458 68.2009 103.36 69.0564 103.099 69.8686L100.595 77.7035L88.2266 73.7297L90.7309 65.8987Z"
        fill="#45B755"
      />
      <path
        opacity="0.3"
        d="M90.7309 65.8987C90.9915 65.0866 91.4096 64.3338 91.9612 63.6832C92.5128 63.0327 93.1871 62.4971 93.9457 62.1072C94.7043 61.7172 95.5322 61.4805 96.3823 61.4106C97.2324 61.3406 98.0879 61.4387 98.9 61.6994C99.7121 61.9601 100.465 62.3781 101.116 62.9297C101.766 63.4814 102.302 64.1557 102.692 64.9143C103.081 65.6729 103.318 66.5008 103.388 67.3509C103.458 68.2009 103.36 69.0564 103.099 69.8686L100.595 77.7035L88.2266 73.7297L90.7309 65.8987Z"
        fill="black"
      />
      <path
        d="M78.5293 61.9769L76.2665 69.0228C76.2334 69.124 76.2207 69.2308 76.2291 69.337C76.2375 69.4432 76.2669 69.5466 76.3156 69.6414C76.3643 69.7361 76.4313 69.8202 76.5127 69.8889C76.5941 69.9576 76.6883 70.0094 76.7899 70.0414L88.2243 73.7133L90.7367 65.8944C91.2638 64.2558 92.4199 62.8935 93.951 62.1069C95.482 61.3202 97.2627 61.1736 98.9018 61.6991L86.6984 57.7776C85.0582 57.2532 83.277 57.4008 81.7455 58.1881C80.214 58.9753 79.0574 60.3379 78.5293 61.9769Z"
        fill="#45B755"
      />
      <path
        d="M84.6661 67.3202L87.8186 57.5084C87.8294 57.4731 87.8326 57.436 87.8279 57.3995C87.8231 57.3629 87.8106 57.3278 87.7911 57.2965C87.7717 57.2652 87.7457 57.2385 87.715 57.2181C87.6844 57.1977 87.6497 57.1841 87.6133 57.1782L84.3923 56.7031L83.9897 57.9754L86.337 58.7283L83.6797 66.9981L84.6661 67.3202Z"
        fill="#263238"
      />
      <path
        d="M82.9911 66.781C82.9183 67.0084 82.9145 67.2523 82.9803 67.4818C83.046 67.7113 83.1783 67.9162 83.3604 68.0706C83.5425 68.225 83.7663 68.3219 84.0034 68.3492C84.2406 68.3765 84.4806 68.3329 84.693 68.2239C84.9054 68.1148 85.0807 67.9453 85.1968 67.7367C85.3129 67.5281 85.3646 67.2898 85.3454 67.0518C85.3261 66.8139 85.2368 66.5869 85.0886 66.3997C84.9405 66.2125 84.7402 66.0734 84.513 66C84.3618 65.9511 84.2024 65.9325 84.044 65.9453C83.8856 65.9582 83.7313 66.0021 83.5899 66.0747C83.4486 66.1472 83.3229 66.247 83.2201 66.3682C83.1174 66.4894 83.0395 66.6297 82.9911 66.781Z"
        fill="#263238"
      />
      <path
        d="M88.8197 74.8861L104.683 79.9792C104.74 79.998 104.801 80.0052 104.862 80.0004C104.922 79.9955 104.981 79.9788 105.035 79.9511C105.089 79.9234 105.137 79.8853 105.176 79.839C105.215 79.7927 105.244 79.7391 105.263 79.6813C105.282 79.6237 105.29 79.5628 105.285 79.5022C105.28 79.4417 105.264 79.3826 105.236 79.3286C105.208 79.2745 105.17 79.2266 105.123 79.1876C105.077 79.1486 105.023 79.1193 104.965 79.1015L88.2238 73.7266C88.1491 73.9593 88.1699 74.2123 88.2816 74.4297C88.3934 74.6472 88.5869 74.8113 88.8197 74.8861Z"
        fill="#45B755"
      />
      <path
        opacity="0.2"
        d="M88.8197 74.8861L95.3018 76.9676C95.3597 76.9865 95.4208 76.9937 95.4815 76.9889C95.5421 76.9841 95.6013 76.9674 95.6555 76.9398C95.7097 76.9121 95.758 76.874 95.7975 76.8276C95.837 76.7813 95.8669 76.7276 95.8856 76.6697C95.9224 76.5533 95.9118 76.4271 95.856 76.3185C95.8002 76.2099 95.7038 76.1278 95.5877 76.0899L88.2238 73.7266C88.1491 73.9593 88.1699 74.2123 88.2816 74.4297C88.3934 74.6472 88.5869 74.8113 88.8197 74.8861Z"
        fill="black"
      />
      <path
        d="M72.0682 52.3795L71.8467 52.6251L71.5971 52.911C71.428 53.1042 71.2589 53.3136 71.0978 53.5109C70.7677 53.9135 70.4537 54.3443 70.1597 54.7791C68.9432 56.5011 67.9856 58.392 67.3173 60.3916C67.1562 60.8909 67.0072 61.3901 66.8905 61.9014C66.8502 62.0504 66.8462 62.1108 66.8261 62.1793C66.823 62.2383 66.823 62.2974 66.8261 62.3564C66.8196 62.4193 66.8196 62.4827 66.8261 62.5456C66.8261 62.6181 66.9629 62.7872 67.2931 62.9483C67.69 63.1268 68.1081 63.254 68.5372 63.3267C69.5728 63.4914 70.6213 63.5601 71.6696 63.5321C72.7647 63.5321 73.8759 63.4354 74.9872 63.3187C76.0984 63.2019 77.2257 63.057 78.3168 62.8919L78.7557 64.6352C76.5085 65.3666 74.1962 65.8803 71.8507 66.1692C70.6158 66.3299 69.3672 66.3569 68.1265 66.2497C67.4172 66.1832 66.7206 66.0178 66.0571 65.7585C65.659 65.5989 65.285 65.3848 64.9458 65.1224C64.5463 64.8092 64.2171 64.4155 63.9795 63.9669C63.5705 63.1254 63.4612 62.1694 63.6695 61.2573C63.7662 60.6412 63.9071 60.0494 64.0721 59.4294C64.3881 58.2392 64.8085 57.0791 65.3283 55.9628C65.8495 54.8439 66.4566 53.767 67.1441 52.7419C67.4904 52.2305 67.8608 51.7353 68.2473 51.2481C68.4446 51.0066 68.6499 50.769 68.8512 50.5315L69.1774 50.1812C69.2941 50.0564 69.3867 49.9557 69.5478 49.8027L72.0682 52.3795Z"
        fill="#FFB573"
      />
      <path
        d="M77.4363 63.2633L80.2546 61.7695L80.3794 64.7972C80.3794 64.7972 78.3663 65.6306 77.2148 64.7127L77.4363 63.2633Z"
        fill="#FFB573"
      />
      <path
        d="M82.5769 61.5391L82.1541 64.3292L80.3826 64.7963L80.2578 61.7686L82.5769 61.5391Z"
        fill="#FFB573"
      />
      <path
        d="M70.3613 46.7025C68.5857 47.0165 66.1016 52.1057 66.1016 52.1057L70.5747 56.1318C70.5747 56.1318 74.1298 52.2506 73.9406 49.9396C73.7795 47.9869 72.62 46.2999 70.3613 46.7025Z"
        fill="#45B755"
      />
      <path
        opacity="0.7"
        d="M70.3613 46.7025C68.5857 47.0165 66.1016 52.1057 66.1016 52.1057L70.5747 56.1318C70.5747 56.1318 74.1298 52.2506 73.9406 49.9396C73.7795 47.9869 72.62 46.2999 70.3613 46.7025Z"
        fill="white"
      />
      <path
        d="M58.3827 137.316C58.3359 137.322 58.2886 137.322 58.2418 137.316C57.7949 137.292 57.3198 136.607 57.0742 136.205C57.0666 136.193 57.0625 136.18 57.0625 136.167C57.0625 136.153 57.0666 136.14 57.0742 136.128C57.0815 136.116 57.0926 136.107 57.1057 136.102C57.1188 136.097 57.1332 136.096 57.1467 136.1C57.2916 136.128 58.5437 136.41 58.7008 136.833C58.7171 136.879 58.7212 136.928 58.7127 136.976C58.7043 137.024 58.6835 137.069 58.6525 137.107C58.588 137.204 58.4932 137.278 58.3827 137.316ZM57.2835 136.293C57.6419 136.845 57.9962 137.175 58.2498 137.191C58.3076 137.192 58.3647 137.178 58.4155 137.151C58.4663 137.123 58.5091 137.083 58.5397 137.034C58.5571 137.016 58.569 136.994 58.574 136.97C58.579 136.946 58.5769 136.92 58.5679 136.897C58.4713 136.64 57.7184 136.398 57.2835 136.293Z"
        fill="#45B755"
      />
      <path
        d="M58.2549 136.204C57.8837 136.306 57.4941 136.323 57.1155 136.253C57.1036 136.249 57.0929 136.242 57.0845 136.233C57.076 136.224 57.07 136.212 57.0672 136.2C57.0615 136.19 57.0586 136.178 57.0586 136.166C57.0586 136.154 57.0615 136.142 57.0672 136.132C57.0994 136.104 57.8322 135.387 58.3435 135.423C58.4067 135.424 58.4688 135.44 58.5247 135.469C58.5807 135.499 58.6289 135.541 58.6656 135.592C58.7096 135.639 58.7366 135.7 58.7424 135.764C58.7483 135.828 58.7326 135.892 58.6978 135.947C58.5822 136.078 58.4267 136.169 58.2549 136.204ZM57.2846 136.14C57.6872 136.196 58.4401 136.096 58.5971 135.866C58.6213 135.83 58.6414 135.773 58.569 135.681C58.5446 135.646 58.5122 135.616 58.4744 135.596C58.4366 135.576 58.3945 135.565 58.3515 135.564C58.0013 135.54 57.494 135.951 57.2846 136.14Z"
        fill="#45B755"
      />
      <path
        d="M76.5563 142.281C76.167 142.272 75.7797 142.222 75.4008 142.132C75.3875 142.127 75.376 142.119 75.3679 142.107C75.3598 142.096 75.3555 142.082 75.3555 142.068C75.3555 142.054 75.3598 142.04 75.3679 142.028C75.376 142.017 75.3875 142.008 75.4008 142.003C75.5739 141.919 77.1159 141.198 77.5025 141.472C77.5373 141.496 77.5651 141.528 77.5828 141.567C77.6005 141.605 77.6075 141.647 77.6031 141.689C77.6013 141.768 77.5814 141.845 77.5451 141.915C77.5088 141.985 77.4571 142.045 77.3938 142.092C77.1412 142.241 76.8484 142.307 76.5563 142.281ZM75.6424 142.04C76.0973 142.124 76.9428 142.237 77.2931 141.975C77.338 141.94 77.3749 141.895 77.4012 141.845C77.4276 141.794 77.4429 141.738 77.4461 141.681C77.4496 141.664 77.4476 141.645 77.4404 141.629C77.4333 141.612 77.4212 141.598 77.4058 141.589C77.1884 141.44 76.2785 141.758 75.6303 142.04H75.6424Z"
        fill="#45B755"
      />
      <path
        d="M75.4173 142.123C75.4028 142.127 75.3875 142.127 75.373 142.123C75.3633 142.114 75.356 142.103 75.3518 142.09C75.3476 142.077 75.3466 142.064 75.3488 142.051C75.3488 141.986 75.7152 140.468 76.5567 140.565C76.7661 140.593 76.8224 140.694 76.8345 140.778C76.8869 141.181 75.9165 141.942 75.4535 142.135L75.4173 142.123ZM76.4722 140.686C75.9407 140.686 75.6307 141.572 75.526 141.926C76.0293 141.66 76.7339 141.028 76.6896 140.782C76.6896 140.762 76.6896 140.706 76.5326 140.69L76.4722 140.686Z"
        fill="#45B755"
      />
      <path
        d="M83.2812 35.8527L83.9939 35.748C83.9939 35.748 83.5711 36.2352 83.2812 35.8527Z"
        fill="#263238"
      />
      <path
        d="M108.049 69.5403L92.46 72.7612C92.1934 72.8113 91.9178 72.7551 91.6921 72.6046C91.4664 72.4541 91.3085 72.2213 91.2522 71.956L90.5234 68.574L90.2617 67.3259L90.7207 65.9006C90.7846 65.696 90.8612 65.4956 90.9502 65.3007C90.9602 65.2697 90.9737 65.24 90.9905 65.2121C91.2476 64.6256 91.5939 64.0823 92.0171 63.6016L92.8224 63.4325L106.189 60.6585C106.456 60.6084 106.732 60.6646 106.957 60.8151C107.183 60.9656 107.341 61.1984 107.397 61.4637L108.818 68.3082C108.877 68.5734 108.829 68.8507 108.685 69.081C108.541 69.3112 108.313 69.4761 108.049 69.5403Z"
        fill="#45B755"
      />
      <path
        opacity="0.3"
        d="M107.278 61.1002L99.757 68.7499L98.1465 68.0977L91.8817 65.5572L91 65.1988C91.2572 64.6123 91.6035 64.069 92.0267 63.5883L92.8319 63.4192L106.199 60.6452C106.404 60.6043 106.616 60.6258 106.808 60.7069C107.001 60.788 107.164 60.925 107.278 61.1002Z"
        fill="black"
      />
      <path
        d="M107.275 61.1012L98.1438 68.1108L97.5479 68.5698L91.8106 65.7233L90.957 65.3207C90.9671 65.2897 90.9806 65.26 90.9973 65.2321C91.2545 64.6456 91.6008 64.1023 92.024 63.6216L92.8292 63.4525L106.196 60.6785C106.398 60.6343 106.608 60.6509 106.8 60.7262C106.992 60.8014 107.157 60.9319 107.275 61.1012Z"
        fill="#45B755"
      />
      <path
        opacity="0.2"
        d="M107.275 61.1012L98.1438 68.1108L97.5479 68.5698L91.8106 65.7233L90.957 65.3207C90.9671 65.2897 90.9806 65.26 90.9973 65.2321C91.2545 64.6456 91.6008 64.1023 92.024 63.6216L92.8292 63.4525L106.196 60.6785C106.398 60.6343 106.608 60.6509 106.8 60.7262C106.992 60.8014 107.157 60.9319 107.275 61.1012Z"
        fill="white"
      />
      <path
        d="M127.08 83.6591L122.329 88.7804C122.142 88.9801 121.883 89.0976 121.609 89.1074C121.336 89.1172 121.069 89.0185 120.868 88.8328L109.216 77.9822C109.015 77.7952 108.897 77.5363 108.887 77.2623C108.878 76.9882 108.977 76.7215 109.164 76.5207L113.931 71.3994C114.023 71.299 114.134 71.2179 114.258 71.1609C114.382 71.1038 114.516 71.0719 114.652 71.0671C114.789 71.0622 114.925 71.0844 115.052 71.1325C115.18 71.1805 115.297 71.2535 115.396 71.347L127.044 82.1976C127.195 82.3381 127.301 82.5204 127.347 82.7213C127.394 82.9221 127.379 83.1324 127.306 83.325C127.255 83.4512 127.178 83.5651 127.08 83.6591Z"
        fill="#45B755"
      />
      <path
        opacity="0.3"
        d="M127.291 83.346L116.694 81.6188L114.234 71.1507C114.422 71.0642 114.631 71.0353 114.836 71.0675C115.04 71.0997 115.23 71.1917 115.382 71.3319L127.03 82.1824C127.188 82.3257 127.298 82.5144 127.345 82.7227C127.392 82.9311 127.373 83.1488 127.291 83.346Z"
        fill="black"
      />
      <path
        d="M127.291 83.343L116.972 80.267L114.234 71.1839C114.422 71.0974 114.631 71.0685 114.836 71.1007C115.04 71.1329 115.23 71.2249 115.382 71.3651L127.03 82.2157C127.181 82.3561 127.286 82.5385 127.333 82.7393C127.379 82.9402 127.365 83.1504 127.291 83.343Z"
        fill="#45B755"
      />
      <path
        opacity="0.2"
        d="M127.291 83.343L116.972 80.267L114.234 71.1839C114.422 71.0974 114.631 71.0685 114.836 71.1007C115.04 71.1329 115.23 71.2249 115.382 71.3651L127.03 82.2157C127.181 82.3561 127.286 82.5385 127.333 82.7393C127.379 82.9402 127.365 83.1504 127.291 83.343Z"
        fill="white"
      />
      <path
        d="M131.228 92.0937L136.462 98.2054C136.562 98.3223 136.637 98.4584 136.682 98.6054C136.728 98.7523 136.743 98.907 136.727 99.06C136.711 99.213 136.664 99.3611 136.588 99.4952C136.513 99.6294 136.411 99.7467 136.289 99.8401L124.919 108.698C124.727 108.813 124.5 108.853 124.282 108.809C124.063 108.766 123.868 108.643 123.735 108.464L119.64 103.794C119.563 103.704 119.5 103.604 119.451 103.496C119.375 103.348 119.343 103.181 119.36 103.015C119.376 102.849 119.44 102.692 119.544 102.562L129.609 92.0494C129.766 91.8792 129.974 91.7639 130.201 91.7204C130.429 91.6769 130.664 91.7076 130.873 91.8078C131.008 91.8798 131.129 91.9768 131.228 92.0937Z"
        fill="#45B755"
      />
      <path
        opacity="0.3"
        d="M130.873 91.8201L128.373 102.94L119.467 103.508C119.391 103.36 119.359 103.193 119.375 103.027C119.392 102.862 119.456 102.704 119.559 102.574L129.625 92.0617C129.781 91.895 129.985 91.7819 130.21 91.7385C130.434 91.6951 130.666 91.7237 130.873 91.8201Z"
        fill="black"
      />
      <path
        d="M130.869 91.8208L126.678 103.622L119.455 103.509C119.379 103.361 119.347 103.194 119.364 103.028C119.38 102.862 119.444 102.705 119.547 102.575L129.613 92.0623C129.77 91.8939 129.976 91.7797 130.202 91.7363C130.427 91.6929 130.661 91.7224 130.869 91.8208Z"
        fill="#45B755"
      />
      <path
        opacity="0.2"
        d="M130.869 91.8208L126.678 103.622L119.455 103.509C119.379 103.361 119.347 103.194 119.364 103.028C119.38 102.862 119.444 102.705 119.547 102.575L129.613 92.0623C129.77 91.8939 129.976 91.7797 130.202 91.7363C130.427 91.6929 130.661 91.7224 130.869 91.8208Z"
        fill="white"
      />
    </svg>
  )
}

export default Trash
