import { useQuery } from '@tanstack/react-query'

import {
  deleteData,
  getData,
  postJsonData,
  updateJsonData,
} from '../../../apis/api.helpers'
import apiUrl from '../../../apis/api.url'
import { QueryParams } from '../../../common/types'
import { getErrorMessage, parseQueryParams } from '../../../utilities/parsers'
import { useSnackbar } from 'notistack'
import { useMutation } from '@tanstack/react-query'
import { useAuthStore } from '../../../store/authStore'

const buildUrlWithParams = (baseUrl: string, params: any) => {
  return `${baseUrl}${parseQueryParams(params)}`
}

const fetchData = async (input: QueryParams) => {
  const url = buildUrlWithParams(
    `${apiUrl.INSTITUTION_ORDER_LIST}?category=non-dms&`,
    {
      ...input,
    }
  )
  const response = await getData(url)
  console.log(response)
  return response
}

export const useOrderList = (input: QueryParams) => {
  return useQuery(['institution_order_list'], () => fetchData(input), {
    refetchOnWindowFocus: false,
    //staleTime: 50000,
  })
}
const fetchDataReplace = async (input: QueryParams) => {
  const url = buildUrlWithParams(`${apiUrl.REPLACEMENTS}?category=non-dms&`, {
    ...input,
  })
  const response = await getData(url)
  console.log(response)
  return response
}

export const useOrderListReplace = (input: QueryParams) => {
  return useQuery(['institution_order_list'], () => fetchDataReplace(input), {
    refetchOnWindowFocus: false,
    //staleTime: 50000,
  })
}

const fetchDetailsList = async (id: any, input: QueryParams) => {
  let url
  const role = useAuthStore.getState().userData.role
  if (role === 'Super Admin') {
    url = buildUrlWithParams(
      `${apiUrl.INSTITUTION_ORDER_LIST}/${id}/medicine_indent_details`,
      {
        ...input,
      }
    )
  } else {
    url = buildUrlWithParams(
      `${apiUrl.INSTITUTION_ORDER_LIST}/${id}/fetch_order_items?category=non-dms&`,
      {
        ...input,
      }
    )
  }

  const response = await getData(url)
  // // console.log(response)
  return response
}

export const useOrderDetailList = (id: any, input: QueryParams) => {
  return useQuery(
    ['institution_Orders_Detail_list'],
    () => fetchDetailsList(id, input),
    {
      refetchOnWindowFocus: false,
      //staleTime: 50000,
    }
  )
}

const fetchDetails = async (id: any, input: QueryParams) => {
  const url = buildUrlWithParams(`${apiUrl.INSTITUTION_ORDER_LIST}/${id}`, {
    ...input,
  })
  const response = await getData(url)
  // // console.log(response)
  return response
}

export const useOrderDetails = (id: any, input: QueryParams) => {
  return useQuery(['iorder_detail'], () => fetchDetails(id, input), {
    refetchOnWindowFocus: false,
    //staleTime: 50000,
  })
}
const fetchDetailsReplace = async (id: any, input: QueryParams) => {
  const url = buildUrlWithParams(`${apiUrl.REPLACEMENTS}/${id}`, {
    ...input,
  })
  const response = await getData(url)
  // // console.log(response)
  return response
}

export const useOrderDetailListReplaced = (id: any, input: QueryParams) => {
  return useQuery(
    ['iorder_detail_replace'],
    () => fetchDetailsReplace(id, input),
    {
      refetchOnWindowFocus: false,
      //staleTime: 50000,
    }
  )
}

export const deleteOrder = (id: any) => {
  return deleteData(`${apiUrl.INSTITUTION_ORDER_LIST}/${id}`)
}
export const deleteOrderItems = (id: any, item_id: any) => {
  return deleteData(
    `${apiUrl.INSTITUTION_ORDER_LIST}/${id}/destroy_item/${item_id}`
  )
}

export const createReplacement = (input: any) => {
  return postJsonData(apiUrl.REPLACEMENTS, input)
}

export const useReplacement = (handleSubmission: (data: any) => void) => {
  const { enqueueSnackbar } = useSnackbar()
  return useMutation(createReplacement, {
    onSuccess: (res: any) => {
      handleSubmission(res.data)
      // enqueueSnackbar('Institution Created successfully', { variant: 'success' })
    },

    onError: (error: any) => {
      enqueueSnackbar(
        getErrorMessage(
          error?.response?.data?.message || 'Something went wrong'
        ),
        {
          variant: 'error',
        }
      )
    },
  })
}

// export const createContact = (input: any) => {
//   return postData(apiUrl.CONTACT_CREATE, input)
// }

// export const useCreateContact = (handleSubmission: (data: any) => void) => {
//   const { enqueueSnackbar } = useSnackbar()
//   return useMutation(createContact, {
//     onSuccess: (res: any) => {
//       handleSubmission(res.data)
//       enqueueSnackbar('Contact Created successfully', { variant: 'success' })
//     },

//     onError: (error: any) => {
//       enqueueSnackbar(getErrorMessage(error?.response?.data?.message || "Something went wrong"), {
//         variant: 'error',
//       })
//     },
//   })
// }

export const MarkDelivered = (data: { input: any; id: string }) => {
  return updateJsonData(
    `${apiUrl.INSTITUTION_ORDER_LIST}/${data?.id}`,
    data?.input
  )
}

export const useStatusChange = (
  handleSubmission: (input: any, id: string) => void
) => {
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(MarkDelivered, {
    onSuccess: (res: any) => {
      handleSubmission(res.data, res.id)
    },

    onError: (error: any) => {
      enqueueSnackbar(getErrorMessage(error.response.data.message), {
        variant: 'error',
      })
    },
  })
}
export const MarkDeliveredLoan = (data: { input: any; id: string }) => {
  return postJsonData(
    `${apiUrl.HOSPITAL_MY_LOANS}/${data?.id}/mark_as_delivered`,
    data?.input
  )
}

export const useStatusChangeLoan = (
  handleSubmission: (input: any, id: string) => void
) => {
  const { enqueueSnackbar } = useSnackbar()

  return useMutation(MarkDeliveredLoan, {
    onSuccess: (res: any) => {
      handleSubmission(res.data, res.id)
    },

    onError: (error: any) => {
      enqueueSnackbar(getErrorMessage(error.response.data.message), {
        variant: 'error',
      })
    },
  })
}

// export const getContactMergeDetails = (data: any) => {
//   return postData(apiUrl.CONTACT_MULTIPLE_DETAILS, data)
// }

// export const mergeContacts = (data: any, id?: string) => {
//   return updateData(`${apiUrl.CONTACT_LIST_URL}${id}/merge/`, data)
// }
// export const getContactDetails = (id: any) => {
//   return getData(`${apiUrl.CONTACT_LIST_URL}${id}`)
// }

// export const setPrimaryAccount = (id: string, data: any) => {
//   return updateData(
//     `${apiUrl.CONTACT_LIST_URL}${id}/set-primary-account/`,
//     data
//   )
// }

// // export const getContactAccountDetails = (id: any) => {
// //   return getData(`${apiUrl.CONTACT_LIST_URL}${id}/accounts/?page=1`)
// // }

// // const accountsfetchData = async (id: string, input: QueryParams) => {
// //   const url = buildUrlWithParams(`${apiUrl.CONTACT_LIST_URL}${id}/accounts/`, {

// const accountsfetchData = async (id: string, input: QueryParams) => {
//   const url = buildUrlWithParams(`${apiUrl.CONTACT_LIST_URL}${id}/accounts/`, {
//     ...input,
//   })
//   const response = await getData(url)
//   return response?.data
// }

// export const useContactAccountDetails = (id: string, input: QueryParams) => {
//   return useQuery(
//     ['assosiated_accout_list'],
//     () => accountsfetchData(id, input),
//     {
//       refetchOnWindowFocus: false,
//       //staleTime: 50000,
//     }
//   )
// }
// export const getContactDuplicate = (data: any, config: AxiosRequestConfig) =>
//   getConfigData(
//     `${apiUrl.CONTACT_LIST_URL}possible-contact-duplicates/${parseQueryParams(
//       data ?? {}
//     )}`,
//     config
//   )

// export const listContactDuplicate = (id?: string, data?: any) =>
//   getData(
//     `${apiUrl.CONTACT_LIST_URL}${id}/contact-duplicates/${parseQueryParams(
//       data
//     )}`
//   )

// export const updateContactCre = (id: string, data: any) => {
//   return postData(`${apiUrl.CONTACT_LIST_URL}${id}/allocate-cre`, data)
// }
