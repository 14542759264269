import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { TabContainer } from '../../../../../components/common'
import { router_config } from '../../../../../configs/route.config'
import { useEffect, useState } from 'react'
import ListingHeader from '../../../../../components/common/ListingTiles'
import { usePatientListing } from '../api'

const DoctorPatientListMain: React.FC<any> = () => {
  const navigate = useNavigate()
  const { id, id2 } = useParams()
  const { data: patients } = usePatientListing()
  const [examined, setExamined] = useState<any>(false)
  useEffect(() => {
    patients?.op_records?.map((item: any) => {
      if (item?.id == id2) {
        if (item?.status === 'Examined' || item?.status === 'Dispensed') {
          setExamined(true)
        }
      }
    })
  }, [patients, id2])

  const obj2 = {
    DOCTOR_PATIENTS_GENERAL: 'general',
    DOCTOR_PATIENTS_PRESCRIPTION: 'prescription',
    DOCTOR_PATIENTS_PREVIOUS: 'previous',
  }
  const [activeTab, setActiveTab] = useState('DOCTOR_PATIENTS_GENERAL')
  const onTabClick = (selection: any) => {
    setActiveTab(selection.id)
    console.log(activeTab)
    navigate(`/patient/list/${id}/${id2}/${router_config[selection.id]?.path}`)
  }

  const context = { setActiveTab, id, id2, activeTab, obj2 }
  const activity = {
    hideExport: true,
  }
  const basicData = {
    title: 'Patient Detail',
    icon: 'institution',
  }
  const tabData: any[] = [
    {
      label: 'General Info',

      id: 'DOCTOR_PATIENTS_GENERAL',
    },
    ...(examined === false
      ? [
          {
            label: 'Prescription',

            id: 'DOCTOR_PATIENTS_PRESCRIPTION',
          },
        ]
      : []),
    {
      label: 'Previous Diagnosis',

      id: 'DOCTOR_PATIENTS_PREVIOUS',
    },
  ]
  return (
    <>
      <ListingHeader
        data={basicData}
        actionProps={activity}
        setCustomBack={true}
      />
      <TabContainer data={tabData} activeTab={activeTab} onClick={onTabClick}>
        <Outlet context={context} />
      </TabContainer>
    </>
  )
}

export default DoctorPatientListMain
