// import { useEffect, useState } from 'react'
// import { generatePath, Outlet, useNavigate, useParams } from 'react-router-dom'

// import { TabItemProps } from '../../../common/types'
// import { TabContainer } from '../../../components/common'
// import DetailHeader from '../../../components/common/DetailTiles'
// import { router_config } from '../../../configs/route.config'
// import { getContactDetails, updateContactCre } from '../api'
// import { useSnackbar } from 'notistack'
// import { getAllEmployeeList } from '../../../apis/common.apis'
// let updateKey = 1
// const ContactDetails = () => {
//   const navigate = useNavigate()
//   const [edit] = useState(false)
//   const [data, setData] = useState<any>()
//   const [drpdownItems, setDrpdownItems] = useState<any>()

//   const params = useParams()
//   const tabData = [
//     {
//       label: router_config.CONTACT_DETAILS_SUMMARY.label,
//       id: 'CONTACT_DETAILS_SUMMARY',
//     },
//     {
//       label: router_config.CONTACT_DETAILS_ACCOUNTS.label,
//       id: 'CONTACT_DETAILS_ACCOUNTS',
//     },
//     {
//       label: router_config.CONTACT_DETAILS_POSSIBLE_DUPLICATES.label,
//       id: 'CONTACT_DETAILS_POSSIBLE_DUPLICATES',
//     },
//   ]
//   const [activeTab, setActiveTab] = useState<string>('CONTACT_DETAILS_SUMMARY')

//   const haandleTabChange = (selected: TabItemProps) => {
//     navigate(
//       generatePath(router_config[selected.id]?.path as string, {
//         id: params.id,
//       })
//     )
//   }
//   const getEmployee = async () => {
//     const data = await getAllEmployeeList()
//     const empDetails = data?.reduce(
//       (acc: any, current: any) => acc.concat(current),
//       []
//     )
//     const drpdownItems = empDetails?.map((item: any) => ({
//       label: item.name,
//       value: item.name,
//       id: item.id,
//     }))
//     setDrpdownItems(drpdownItems)
//   }

//   useEffect(() => {
//     getData()
//     getEmployee()

//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [])

//   const getData = async () => {
//     const { data } = await getContactDetails(params.id)
//     setData(data)
//   }

//   const tiledata = [
//     {
//       id: 1,
//       tiles: [
//         {
//           label: 'CRE',
//           value: data?.cre_agent?.name,
//           id: 1,
//         },
//         {
//           icon: 'phone',
//           value: data?.default_contact?.mobile,
//           id: 2,
//         },
//         {
//           icon: 'email',
//           value: data?.default_contact?.email,
//           id: 3,
//         },
//       ],
//     },
//     {
//       id: 2,
//       tiles: [
//         {
//           id: 1,
//           icon: 'home',
//           value: ` ${data?.addresses[0]?.city ?? ''}, ${data?.addresses[0]
//             ?.state} `,
//         },
//       ],
//     },
//   ]
//   const chipdata = [
//     { id: 1, status: 'Normal' },
//     { id: 2, status: 'Normal' },
//     { id: 3, status: 'Normal' },
//   ]
//   const basicData = {
//     title: `${data?.default_contact?.first_name ?? '--'} ${
//       data?.default_contact?.last_name ?? '--'
//     } (${data?.customer_number ?? '--'})`,
//     subtitle: `${
//       data?.primary_company?.business_name
//         ? `${data?.primary_company?.business_name} (${data?.primary_company?.company_code})`
//         : '--'
//     } + ${data?.no_of_accounts ?? '--'}`,
//     subLink: `${router_config.ACCOUNTS.path}/${data?.primary_company?.id}/summary`,
//     image: data?.profile_image,
//   }

//   const handleCallback = () => {
//     getData()
//   }
//   const contextData = {
//     setActiveTab: (id: string) => setActiveTab(id),
//     edit: edit,
//     handleParentCallback: handleCallback,
//   }
//   const { enqueueSnackbar } = useSnackbar()
//   const updateCRE = (id?: string) => {
//     const details = { assigned_cre_id: id ?? null }
//     updateContactCre(data?.id as string, details).then(() => {
//       enqueueSnackbar('CRE updated successfully', { variant: 'success' })
//       getData()
//       updateKey = updateKey + 1
//     })

//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }
//   const handleUpdateCre = (id?: string) => {
//     updateCRE(id)
//   }
//   return (
//     <div>
//       <div>
//         <DetailHeader
//           tiledata={tiledata}
//           chipdata={chipdata}
//           data={basicData}
//           menuItems={drpdownItems}
//           setUpdateCREId={handleUpdateCre}
//         />
//         {/* <PageTiles data={dashtiledata} handleTileClick={handleTileClick} /> */}
//       </div>
//       <div className="flex gap-4">
//         <TabContainer
//           data={tabData}
//           activeTab={activeTab}
//           onClick={haandleTabChange}
//         >
//           <Outlet context={contextData} />
//         </TabContainer>
//       </div>
//     </div>
//   )
// }

// export default ContactDetails

// import FormBuilder from '../../../components/app/formBuilder/index'

import { Button } from '../../../../../components/common'
import { FormProvider, useForm } from 'react-hook-form'
import EditForm from './edit_form'
import { CreateSchema, formSchema } from './schema'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  useCreatePatient,
  // useDoctors,
  useUpdateOpPatient,
  useUpdatePatient,
} from './api'
import { useEffect } from 'react'
// import moment from 'moment'
import { useLocation } from '../../../../contacts/create/api'
import { useNavigate } from 'react-router-dom'
type ManageStockProps = {
  handlSubmitEdit: () => void
  data: any
  edit: boolean
  update?: boolean
  create?: boolean
}
const PatientsForm: React.FC<ManageStockProps> = ({
  handlSubmitEdit,
  data,
  edit,
  update,
  create,
}) => {
  const id = data.id
  const pat_id = data?.patient_id ? data?.patient_id : null
  const navigate = useNavigate()
  // const { data: doctors } = useDoctors()
  const { data: location } = useLocation()
  const onSuccess = () => {
    navigate('/patients/all')
    handlSubmitEdit()
  }
  console.log(id)

  const { mutate } = useCreatePatient(onSuccess)
  const { mutate: EditData } = useUpdatePatient(onSuccess)
  const { mutate: AddOpPatient } = useUpdateOpPatient(onSuccess)
  const methods = useForm<CreateSchema>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  //   location?.districts.map((item:any) => (
  //     if (data?.district_name === item?.name){
  // dis.push(item)
  //   ))
  //   })
  useEffect(() => {
    const dis: any = []
    if (data) {
      location?.districts.map((item: any) => {
        if (data?.district_name === item?.name) {
          dis.push(item)
        }
      })
      console.log(update)
      if (update || create) {
        methods.reset({
          ...data,
          id: data?.id,
          district_name: data?.district_name || data?.district,
          district_id: dis[0]?.id || data?.district_id,
          phone: data?.phone_number,
          age: data?.age,
          // doctor: data?.doctor_name,
          // date_of_birth: moment(data?.dob).toDate(),
          update: update,

          // id: data?.id
        })
      } else {
        methods.reset({ update: update })
      }
    }
  }, [data, methods, update, location?.districts])
  console.log(methods.formState.errors)
  // methods.reset({})
  const onSubmit = (payload: CreateSchema) => {
    console.log(payload)
    const docData: any = []
    // doctors?.doctors.map((item: any) => {
    //   if (payload?.doctor_id === item?.id) {
    //     docData.push(item)
    //   }
    // })
    console.log(docData)
    const data = {
      patient: {
        ...payload,
        // op_records_attributes: {
        //   doctor_id: payload.doctor_id,
        //   specialization: payload.specialization,
        //   remarks:payload.remarks
        // },
        phone: undefined,
        district_name: payload?.district_name,
        // doctor: undefined,
        remarks: undefined,
        // date_of_birth: undefined,
        age: payload?.age,
        aadhar_number: payload?.aadhar_number,
        phone_number: payload?.phone,
        // dob: payload?.date_of_birth
        //   ? moment(payload?.date_of_birth).format('YYYY-MM-DD')
        //   : undefined,
        op_records_attributes: [
          {
            // doctor_id: payload?.doctor_id,
            // specialization: docData[0]?.specialization,
            remarks: payload?.remarks,
          },
        ],
      },
    }

    // console.log(data)
    if (edit === true) {
      const data = {
        patient: {
          ...payload,
          // op_records_attributes: {
          //   doctor_id: payload.doctor_id,
          //   specialization: payload.specialization,
          //   remarks:payload.remarks
          // },
          phone: undefined,
          district_name: undefined,
          // doctor: undefined,
          remarks: undefined,
          // date_of_birth: undefined,
          // doctor_id: undefined,
          phone_number: payload?.phone,
          age: payload?.age,
          aadhar_number: payload?.aadhar_number,
          // dob: payload?.date_of_birth
          //   ? moment(payload?.date_of_birth).format('YYYY-MM-DD')
          //   : undefined,
          op_records_attributes: [],
        },
      }
      EditData({ data, pat_id })
    } else {
      if (!update) {
        mutate(data)
      } else {
        const data = {
          op_records_attributes: {
            // doctor_id: payload?.doctor_id,
            // specialization: docData[0]?.specialization,
            remarks: payload?.remarks,
          },
        }
        AddOpPatient({ data, id })
      }
    }
  }
  console.log(methods.formState.errors, methods.watch())
  return (
    <>
      <div className="my-5 mx-8">
        <h2 className="flex text-center w-full mx-auto justify-center text-lime-500 text-2xl font-medium">
          {edit ? <u>Edit OP</u> : <u>Create New OP</u>}
        </h2>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="grid md:grid-cols-2 md:gap-8 sm:grid-cols-1 p-4">
            <FormProvider {...methods}>
              <EditForm edit={true} update={update} create={create} />
            </FormProvider>
          </div>
          <div className="flex gap-8 justify-center my-3">
            {/* <Button onClick={methods.reset} label={'Clear'} outlined /> */}
            <Button
              onClick={methods.handleSubmit}
              label={'Submit'}
              type="submit"
            />
          </div>
        </form>
      </div>
    </>
  )
}
export default PatientsForm
