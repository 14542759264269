import { z } from 'zod'

import noLeadingSpaces from '../../../../utilities/noLeadingSpaces'
const isAdult = (value: Date) => {
  const eighteenYearsAgo = new Date()
  eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18)
  return value <= eighteenYearsAgo
}
export const formSchema = z.object({
  name: z
    .string({ invalid_type_error: 'Name is required.' })
    .min(1, { message: 'Name is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),

  gender: z
    .string({ invalid_type_error: 'Gender is required.' })
    .min(1, { message: 'Gender is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  address: z
    .string({ invalid_type_error: 'Address is required.' })
    .min(1, { message: 'Address is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  stream: z
    .string({ invalid_type_error: 'Stream is required.' })
    .min(1, { message: 'Stream is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  specialization: z
    .string({ invalid_type_error: 'Specialization is required.' })
    .min(1, { message: 'Specialization is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  availability: z
    .string({ invalid_type_error: 'Availability is required.' })
    .min(1, { message: 'Availability is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  dob: z
    .date({ required_error: 'Date of Birth is Required' })

    .refine(isAdult, {
      message: 'You must be at least 18 years old.',
    }),

  // date_of_birth: z
  //   .union([z.date(), z.string()])
  //   .refine(isAdult, {
  //         message: 'You must be at least 18 years old.',
  //       })
  //   .refine((date) => date !== undefined && date !== null && date !== '', {
  //     message: 'Date of Birth is required.',
  //   })
  //  ,

  joining_date: z.date({ required_error: 'Joining Date is Required' }),
  // .min(1, { message: 'Date of birth is required.' })

  phone_number: z
    .string()
    .nullable()
    .transform((value) => (value === null ? '' : value))
    .refine(
      (value) => {
        return (
          (/^\d+(?:\.\d{0,10})?$/.test(value) &&
            value.length === 10 &&
            parseFloat(value) !== 0) ||
          value === ''
        )
      },
      {
        message: 'Enter a valid Mobile number',
      }
    ),

  email: z
    .string({ required_error: 'Enter Email' })
    .min(5, 'Enter a valid Email')
    .superRefine((value: any, ctx: any) => {
      if (value && value !== '') {
        if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Invalid email format.',
          })
        }
      }
    })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
})

export type CreateSchema = z.infer<typeof formSchema>
