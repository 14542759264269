// import { getNestedProperty, useCurrencyFormat } from '../../utilities/parsers'
// import { useNavigate } from "react-router-dom"

// import { useNavigate } from "react-router-dom"

// import { Router } from "react-router-dom"

// const defaultColumnProps = {
//   sortable: true,
//   resizable: true,
//   isVisible: true,
// }
const defaultColumnProps1 = {
  sortable: false,
  isVisible: true,
  resizable: true,
}

export const getColumns = ({}: any) => {
  const createRenderCell = (key: string, isCustom?: string) => (row: any) => {
    if (isCustom === 'name') {
      // console.log(row)
      return {
        cell: (
          <>
            {row.dispensable === true ? (
              // <div className="w-[127px] h-[40px] rounded-[20px] bg-ShortageStatus/15 flex justify-center">
              <div>{row?.pharmacy_medicine_name}</div>
            ) : (
              <div>{row?.out_side_medicine_name}</div>
            )}
          </>
        ),
      }
    }
  }
  const column = [
    {
      title: 'Medicine Name',
      field: 'id',
      ...defaultColumnProps1,
      fixed: true,
      sortKey: 'id',
      customCell: true,
      renderCell: createRenderCell('default_contact.mobile', 'name'),
      // rowClick: (row: any) => onViewAction(row, 'contactName'),
      // link: true,
    },
    {
      title: 'Quantity',
      field: 'quantity',
      fixed: true,
      colWidth: 150,
      ...defaultColumnProps1,
      // link: true,
      // sortKey: 'default_contact__first_name',
      // rowClick: (row: any) => onViewAction(row, 'contactName'),
    },
    {
      title: 'Dose',
      field: 'dosage',
      ...defaultColumnProps1,
      colWidth: 150,
    },
    {
      title: 'Mode of Administration',
      field: 'mode',
      ...defaultColumnProps1,
    },
    {
      title: 'Remarks',
      field: 'remarks',
      ...defaultColumnProps1,
    },
  ]

  return column
}
