// import FormBuilder from '../../../components/app/formBuilder/index'

import FormBuilder from '../../components/app/formBuilder'
type Props = {
  edit?: boolean
}
// const CommonForm = (data?: any) => {
const GeneralInfoForm = ({ edit }: Props) => {
  const textField = (
    name: string,
    label: string,
    id: string,
    type: string,
    // placeholder: string,
    required = false,
    config: { data?: Array<{ name: string; id: string }> } = {}
  ) => ({
    name,
    label,
    id,
    type,
    // placeholder,
    ...(required ? { required: true } : {}),
    ...config,
    spacing: 4,
  })
  const formBuilderProps = [
    {
      ...textField('name', 'User Name', 'name', 'text', false),
      disabled: true,
    },
    {
      ...textField('role', 'User Role', 'role', 'text', false),
      disabled: true,
    },
    {
      ...textField('email', 'Email Id', 'email', 'text', false),
      disabled: true,
    },
    {
      ...textField(
        'phone_number',
        'Registered Phone Number',
        'phone_number',
        'text',
        false
      ),
      disabled: true,
    },
    // {
    //   ...textField('district', 'District', 'district', 'text', false),
    //   disabled: true,
    // },
  ]

  // methods.reset({})

  return <FormBuilder data={formBuilderProps} edit={edit} />
}
export default GeneralInfoForm
